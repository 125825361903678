import Vue from 'vue';
import {EV_INTO_TEL} from '@/core/const/global-event';
import {Toolkit} from '@/util';
/**
 * 电话号码带入特定输入框
 */
Vue.directive('clickTel', {
  bind: function (el, binding, vnode) {
    el.binding = binding;
    el.style.cursor = 'pointer';
    el.addEventListener('click', function () {
      let tel = this.binding.value;
      if (tel) {
        if (tel.startsWith("0")) {
          tel = tel.substr(1);
        }
        vnode.context.$bus.$emit(EV_INTO_TEL, tel);
        // 复制号码到剪切板
        Toolkit.copyContentH5(tel);

      }
    });
  },
  update: function (el, binding) {
    el.binding = binding;
  },
  unbind: function () {

  }
});
