<template>
  <div>
    <el-dialog
      :visible="visible"
      width="1400px"
      :show-close="false"
      :close-on-click-modal="false"
      @closed="resetData"
      :append-to-body="true"
      custom-class="diag-change-order"
    >
      <div class="main-box">
        <div class="left-box">
          <el-divider content-position="left">车票列表</el-divider>

          <el-table
            :data="ticketList"
            border
            size="medium"
            max-height="600"
            :row-class-name="tableRowClassName"
            style="width: 100%; height: 600px"
          >
            <el-table-column prop="showTime" width="120" label="排班时间"></el-table-column>
            <el-table-column prop="usedNum" label="已订"></el-table-column>
            <el-table-column prop="num" label="余票">
              <template slot-scope="scope">{{ scope.row.num }}</template>
            </el-table-column>
          </el-table>
        </div>

        <div class="right-box">
          <div>
            <div>
              <el-divider content-position="left">修改订单</el-divider>

              <div>
                <el-radio-group v-model="changeType" size="small">
                  <el-radio :label="1" border>修改时间</el-radio>
                  <el-radio :label="4" border>修改起点位置</el-radio>
                  <el-radio :label="5" border>修改终点位置</el-radio>
                  <el-radio :label="6" border>修改乘车人电话</el-radio>
                  <el-radio :label="3" border>退票</el-radio>
                </el-radio-group>
              </div>

              <!-- <el-divider content-position="left">补差价</el-divider> -->

              <div style="margin-top: 20px">
                <el-radio-group v-model="changeType" size="small">
                  <el-radio :label="11" :disabled="form.orderType === 3" border
                    >补购儿童票</el-radio
                  >
                  <el-radio
                    :label="12"
                    :disabled="form.orderType === 3 || order.childNumber < 1"
                    border
                    >儿童票补全票</el-radio
                  >
                  <el-radio :label="2" :disabled="form.orderType === 3" border
                    >增加人数补票</el-radio
                  >
                  <el-radio :label="13" :disabled="form.orderType === 3" border
                    >合乘改包车补票</el-radio
                  >
                  <el-radio
                    :label="7"
                    :disabled="
                      form.orderType === 3 || !form.routeId || form.orderStatus === 'PRE_DISPATCH'
                    "
                    border
                    ><span v-if="form.chosenSeats">取消选座</span
                    ><span v-else>指定座位</span></el-radio
                  >
                  <el-radio :label="100" border>其他</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="order-form">
              <el-divider content-position="left">订单详情</el-divider>
              <el-form
                ref="form"
                :model="form"
                size="small"
                inline
                label-width="80px"
                :rules="rules"
              >
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="路线" prop="pathId">
                      <el-select
                        v-model="form.pathId"
                        disabled
                        style="width: 160px"
                        placeholder="请选择路线"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in pathOpts"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="日期" prop="date">
                      <el-date-picker
                        style="width: 160px"
                        v-model="form.date"
                        type="date"
                        :disabled="changeType !== 1"
                        :clearable="false"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="时间" prop="ticketId">
                      <el-select
                        v-model="form.ticketId"
                        style="width: 160px"
                        :disabled="changeType !== 1"
                        placeholder="请选择时间"
                      >
                        <el-option
                          :label="item.showTime"
                          :value="item.id"
                          v-for="item in ticketList"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="成人">
                      <el-input-number
                        v-model="form.adultNumber"
                        disabled
                        style="width: 160px"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="儿童">
                      <el-input-number
                        v-model="form.childNumber"
                        disabled
                        style="width: 160px"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="代下单">
                      <el-select v-model="form.isAppointOrder" disabled style="width: 160px">
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="16">
                    <el-row :gutter="20">
                      <el-col :span="form.isAppointOrder ? 12 : 24">
                        <el-form-item
                          :label="form.isAppointOrder ? '下单人' : '乘车人'"
                          prop="telephone"
                        >
                          <el-input v-model="form.telephone" disabled style="width: 160px" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" v-if="form.isAppointOrder">
                        <el-form-item label="乘车人" prop="appointTelephone">
                          <el-input
                            v-model="form.appointTelephone"
                            :disabled="changeType !== 6"
                            style="width: 160px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="起点" prop="start">
                      <poi-selector
                        ref="startPoi"
                        v-model="form.start"
                        :suggest="[]"
                        :pathId="form.pathId"
                        :ticketId="form.ticketId"
                        :isStart="true"
                        :modifyOrder="form"
                        :disabled="changeType !== 4"
                        style="width: 600px"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="终点" prop="end">
                      <poi-selector
                        v-model="form.end"
                        :suggest="[]"
                        :pathId="form.pathId"
                        :ticketId="form.ticketId"
                        :modifyOrder="form"
                        :isStart="false"
                        :disabled="changeType !== 5"
                        style="width: 600px"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row v-if="form.chosenSeats">
                  <el-col :span="24">
                    <el-form-item label="已订座位">
                      <el-select :value="form.chosenSeats" disabled multiple style="width: 600px">
                        <el-option label="副驾" :value="1"> </el-option>
                        <el-option label="中排左" :value="2"> </el-option>
                        <el-option label="中排右" :value="3"> </el-option>
                        <el-option label="后排左" :value="4"> </el-option>
                        <el-option label="后排中" :value="5"> </el-option>
                        <el-option label="后排右" :value="6"> </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div>
                <el-divider content-position="left"></el-divider>

                <el-form inline size="small">
                  <div v-if="changeType === 3">
                    <el-row :gutter="20" v-if="form.number > 1">
                      <el-col :span="6">
                        <el-form-item label="成人退票">
                          <el-input-number
                            v-model="changeForm.refundAdultNumber"
                            :min="0"
                            :max="form.adultNumber"
                            style="width: 120px"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="儿童退票" v-if="form.childNumber">
                          <el-input-number
                            v-model="changeForm.refundChildNumber"
                            :min="0"
                            :max="1"
                            style="width: 120px"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>

                      <el-col :span="12">
                        <el-form-item label="退订座位" v-if="form.chosenSeats">
                          <el-select
                            v-model="changeForm.seats"
                            multiple
                            style="width: 260px"
                            placeholder="请选择"
                          >
                            <el-option
                              label="副驾"
                              :disabled="!form.chosenSeats.includes(1)"
                              :value="1"
                            >
                            </el-option>

                            <el-option
                              label="中排左"
                              :disabled="!form.chosenSeats.includes(2)"
                              :value="2"
                            >
                            </el-option>
                            <el-option
                              label="中排右"
                              :disabled="!form.chosenSeats.includes(3)"
                              :value="3"
                            >
                            </el-option>
                            <el-option
                              label="后排左"
                              :disabled="!form.chosenSeats.includes(4)"
                              :value="4"
                            >
                            </el-option>
                            <el-option
                              label="后排中"
                              :disabled="!form.chosenSeats.includes(5)"
                              :value="5"
                            >
                            </el-option>
                            <el-option
                              label="后排右"
                              :disabled="!form.chosenSeats.includes(6)"
                              :value="6"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <!-- <el-row v-if="isDispatched">
                      <el-col :span="12">
                        <el-form-item label="退票责任">
                          <el-select
                            v-model="changeForm.shouldPayDriver"
                            style="width: 260px"
                            placeholder="请选择"
                          >
                            <el-option label="司机" :value="false"></el-option>
                            <el-option label="乘客" :value="true"></el-option>
                          </el-select>
                        </el-form-item>

                        <div class="red">注意：司机责任将不会支付给司机车费</div>
                      </el-col>
                    </el-row> -->
                  </div>

                  <div v-if="changeType === 100">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="补差价金额">
                          <el-input-number
                            v-model="changeForm.makeUpAmount"
                            :min="0"
                            style="width: 160px"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>

                      <el-col :span="16">
                        <el-form-item label="说明">
                          <el-input v-model="changeForm.remark" style="width: 320px"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-if="changeType === 2">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="增加人数">
                          <el-input-number
                            v-model="changeForm.addNum"
                            :min="1"
                            :max="6"
                            style="width: 160px"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-if="changeType === 13">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="包车类型">
                          <el-radio-group
                            v-model="changeForm.addNum"
                            style="width: 160px"
                            size="small"
                          >
                            <el-radio :label="4">4座</el-radio>
                            <el-radio :label="6">6座</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-if="changeType === 7 && !form.chosenSeats">
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="选择座位">
                          <el-select
                            v-model="changeForm.seats"
                            multiple
                            style="width: 460px"
                            placeholder="请选择"
                          >
                            <el-option
                              :label="item.desc"
                              v-for="item in seatList"
                              :key="item.seat"
                              :disabled="item.status !== 1"
                              :value="item.seat"
                            >
                              <span style="float: left"
                                >{{ item.desc }}
                                <span v-if="item.price"
                                  >{{ item.price | moneyFormat(0) }}元</span
                                ></span
                              >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-if="changeType === 4 || changeType === 5">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="是否校验顺路">
                          <el-radio-group
                            v-model="changeForm.notCheckOnWay"
                            style="width: 160px"
                            size="small"
                          >
                            <el-radio :label="false">校验</el-radio>
                            <el-radio :label="true">不校验</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </el-form>
              </div>
            </div>
          </div>

          <div class="btn-box">
            <el-checkbox
              v-if="changeType === 1 || changeType === 7"
              v-model="voluntarilyChange"
              style="margin-right: 6px; color: red"
              >不计费</el-checkbox
            >

            <el-checkbox
              v-if="changeType === 3"
              v-model="useExemption"
              style="margin-right: 6px; color: red"
              >免责退款</el-checkbox
            >

            <el-button type="primary" @click="submitChange(false)" size="small"> 下一步 </el-button>
            <el-button type="info" @click="visible = false" size="small"> 关闭 </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible="billVisible"
      width="800px"
      :show-close="false"
      custom-class="diag-change-order"
      :close-on-click-modal="false"
    >
      <el-form v-if="submitResult.preOrder">
        <el-row v-if="submitResult.hasUnConfirmed">
          <div class="important">当前已存在改签，请确认是否要删除当前改签？</div>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="支付状态：">
              {{ submitResult.preOrder.payStatus | enumTransfer(payStatusOpts) }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="行程状态：">
              {{ submitResult.preOrder.routeStatus | enumTransfer(routeDetailOpts) }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="订单渠道：">
              <div v-order-source="submitResult.preOrder.orderSource"></div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div v-if="submitResult.changeType === 3">
        <RefundDetail :data="submitResult" />
      </div>
      <div v-if="submitResult.changeType !== 3 && submitResult.billDetail">
        <ChangeBillDetail :data="submitResult" />
      </div>

      <span slot="footer">
        <el-button size="small" @click="confirmChange(false)">取 消</el-button>
        <el-button
          type="primary"
          v-if="submitResult.hasUnConfirmed"
          @click="submitChange(true)"
          size="small"
          >删除已存在改签</el-button
        >
        <el-button v-else type="primary" @click="confirmChange(true)" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getTicketList,
  changeOrderSubmit,
  changeOrderConfirm,
  getSeatsInfo,
  cancelSeats,
} from "@/api";
import moment from "moment";
import PoiSelector from "@/components/PoiSelector";
import _ from "lodash";
import { ORDER_CHANGE } from "@/core/const/global-event";
import RefundDetail from "./RefundDetail.vue";
import ChangeBillDetail from "./ChangeBillDetail";

const changeFormInitData = {
  refundAdultNumber: 1,
  refundChildNumber: 0,
  seats: [],
  addNum: 0,
  makeUpAmount: 0,
  notCheckOnWay: false,
  remark: "",
};

export default {
  components: {
    PoiSelector,
    RefundDetail,
    ChangeBillDetail,
  },

  watch: {
    date(newVal, oldVal) {
      if (newVal) {
        this.queryTickets();
        // 开始赋值触发的改变不用清空ticketId
        if (oldVal) {
          this.$set(this.form, "ticketId", null);
        }
      }
    },

    changeType(val) {
      if (val) {
        this.handleChange(val);
      }
    },
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
      pathOpts: (state) => state.pathList,
      pasMsgs: (state) => state.pasMsgs,
      seatOpts: (state) => state.enumMap["SEAT_ENUM"],
    }),

    date() {
      // 做中间层监听date变化
      return this.form.date;
    },

    isDispatched() {
      return (
        this.form &&
        (this.form.orderStatus === "MATCHED" || this.form.orderStatus === "PRE_DISPATCH")
      );
    },
  },

  data() {
    const validatePoi = (rule, value, callback) => {
      if (!value || !value.jd || !value.wd) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      rules: {
        pathId: [
          {
            required: true,
            message: "请选择路线",
            trigger: "change",
          },
        ],
        ticketId: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入人数",
            trigger: "change",
          },
        ],
        start: [
          {
            required: true,
            message: "请输入起点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
        end: [
          {
            required: true,
            message: "请输入终点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
      },
      billVisible: false,
      visible: false,
      order: {},
      form: {},
      ticketList: [],
      changeType: 0,
      changeForm: {},
      submitResult: {},
      seatList: [],
      voluntarilyChange: false,
      useExemption: false,
    };
  },

  methods: {
    show(order) {
      this.form = _.cloneDeep(order);
      const start = {
        jd: order.depLongitude,
        wd: order.depLatitude,
        name: order.departure,
      };

      const end = {
        jd: order.destLongitude,
        wd: order.destLatitude,
        name: order.destination,
      };

      this.$set(this.form, "start", start);
      this.$set(this.form, "end", end);
      // 记录原始数据
      this.order = this.form;

      console.log(this.form);
      this.visible = true;
    },

    showRefundConfim(order) {
      this.form = _.cloneDeep(order);
      const start = {
        jd: order.depLongitude,
        wd: order.depLatitude,
        name: order.departure,
      };
      const end = {
        jd: order.destLongitude,
        wd: order.destLatitude,
        name: order.destination,
      };
      this.$set(this.form, "start", start);
      this.$set(this.form, "end", end);
      this.order = this.form;
      this.changeType = 3;

      // 等待 changeType 改变事件完成
      setTimeout(() => {
        this.changeForm = {
          refundChildNumber: order.childNumber,
          refundAdultNumber: order.adultNumber,
        };
        this.submitChange();
      }, 0);
    },

    getSeatInfo() {
      getSeatsInfo(this.order.id).then((res) => {
        const info = res.data || {};
        this.seatList = info.list || [];
      });
    },

    resetData() {
      this.form = {};
      this.changeForm = {};
      this.changeType = 0;
      this.voluntarilyChange = false;
      this.useExemption = false;
    },

    handleChange(changeType) {
      const { childNumber, adultNumber } = this.order;
      this.form = _.cloneDeep(this.order);
      this.changeForm = _.cloneDeep(changeFormInitData);
      // 儿童票补全票
      if (changeType === 12) {
        if (childNumber !== 1) {
          this.$message.error("当前没有购买儿童票");
          this.changeType = 0;
          return;
        }
        this.$set(this.form, "adultNumber", adultNumber + 1);
        this.$set(this.form, "childNumber", 0);
      }
      // 补购儿童票
      else if (changeType === 11) {
        if (childNumber !== 0) {
          this.$message.error("当前已经有1张儿童票了");
          this.changeType = 0;
          return;
        }
        this.$set(this.form, "childNumber", 1);
      }
      // 修改乘车人电话
      else if (changeType === 6) {
        this.$set(this.form, "isAppointOrder", true);
      }
      // 选座
      else if (changeType === 7 && !this.form.chosenSeats) {
        this.getSeatInfo();
      }
    },

    queryTickets() {
      const { date, pathId } = this.form || {};
      const param = {
        date,
        pathId,
      };
      getTicketList(param).then((res) => {
        const { list = [] } = res.data || {};
        // 只显示当前时间以后的车票
        const now = moment().subtract(1, "hours").format("YYYY-MM-DD HH:00:00");
        this.ticketList = list.filter((item) => item.dateTime >= now);
      });
    },

    submitChange(removePreChange) {
      const changeType = this.changeType;
      if (!changeType) {
        this.$message.error("请选择改签类型");
        return;
      }

      const { id, pathId, routeId, appointTelephone, ticketId, start, end, chosenSeats } =
        this.form;

      const {
        refundChildNumber,
        refundAdultNumber,
        remark,
        seats = [],
        makeUpAmount,
        addNum,
        notCheckOnWay,
        shouldPayDriver,
      } = this.changeForm;
      const isStart = changeType === 4;
      const param = {
        changeType,
        orderId: id,
        pathId,
        routeId,
        isStart,
        lat: isStart ? start.wd : end.wd,
        lng: isStart ? start.jd : end.jd,
        address: isStart ? start.name : end.name,
        appointTelephone,
        ticketId,
        refundChildNumber,
        refundAdultNumber,
        remark,
        seats,
        makeUpAmount,
        addNum,
        notCheckOnWay,
        removePreChange,
        shouldPayDriver,
        voluntarilyChange: this.voluntarilyChange,
        useExemption: this.useExemption,
      };

      // 修改手机号
      if (changeType === 6) {
        changeOrderSubmit(param).then(() => {
          this.$bus.$emit(ORDER_CHANGE);
          this.$message.success("操作成功");
          this.visible = false;
        });
      }
      // 退订座位
      else if (changeType === 7 && chosenSeats) {
        cancelSeats(id).then(() => {
          this.$bus.$emit(ORDER_CHANGE);
          this.$message.success("操作成功");
          this.visible = false;
        });
      } else {
        changeOrderSubmit(param).then((res) => {
          this.submitResult = res.data || {};
          this.isAdminChange = false;
          this.billVisible = true;
        });
      }
    },

    confirmChange(confirmed) {
      const { id } = this.form;
      const param = {
        orderId: id,
        confirmed,
      };
      changeOrderConfirm(param).then((res) => {
        if (res.data) {
          this.$message.success(res.data);
        }
        this.$bus.$emit(ORDER_CHANGE);
        this.billVisible = false;
        if (confirmed) {
          this.visible = false;
        }
      });
    },

    tableRowClassName({ row }) {
      const now = moment().format("YYYY-MM-DD HH:00:00");
      const num = row.num;
      // 如果num < 0 ，则按照num的大小分级
      if (num < 0) {
        return "warn1";
      } else if (num < -5) {
        return "warn2";
      } else if (num < -10) {
        return "warn3";
      }

      if (row.showTicket && row.dateTime >= now) {
        return "";
      }
      return "hide-row";
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .diag-change-order {
  .el-dialog__body {
    padding-top: 0;
  }
}

.important {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.main-box {
  display: flex;

  .left-box {
    flex: 1;
    margin-right: 20px;
  }

  .right-box {
    width: 900px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .order-form {
      margin-top: 40px;
    }

    .btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
