<template>
  <div class="root-box">
    <QueryCondition ref="param" :queryFun="query" :showType="true" />

    <div class="table">
      <div class="item">
        <chart-view
          ref="chart1"
          valueField="num"
          nameField="name"
          title="路线分布占比"
          initChartType="pie"
        />
      </div>

      <div class="item">
        <chart-view
          ref="chart2"
          valueField="num"
          nameField="name"
          title="注册渠道分布占比"
          initChartType="pie"
        />
      </div>

      <div class="item">
        <chart-view
          ref="chart3"
          valueField="num"
          nameField="name"
          title="客户端占比"
          initChartType="pie"
        />
      </div>

      <div class="item">
        <chart-view
          ref="chart4"
          valueField="num"
          nameField="name"
          title="VIP占比"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {specAnalysis} from '@/api';
import ChartView from '../../ChartView.vue';

import QueryCondition from '../QueryCondition.vue';
export default {
  components: {
    QueryCondition,
    ChartView
  },
  props: {},
  created () {

  },
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      data: {},
      chartOpt: {
        title: {
          text: "优惠均值趋势图",
        },
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,

        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      }
    };
  },
  methods: {
    query () {
      const param = this.$refs.param.getParam();
      specAnalysis(param).then(res=>{
        const {d1, d2, d3, d4} = res.data || {};
        this.$refs.chart1.setData(d1);
        this.$refs.chart2.setData(d2);
        this.$refs.chart3.setData(d3);
        this.$refs.chart4.setData(d4);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.head-box {
  display: flex;
  margin: 40px 0;
  justify-content: space-around;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amount {
      margin-top: 10px;

      .value {
        font-size: 20px;
      }

      .unit {
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(2, 300px);
  grid-row-gap: 40px;
  grid-column-gap: 4%;
  padding: 20px;
  margin-top: 60px;

  > div {
    background: #fff;
    border: 1px solid #ddd;
    position: relative;
  }
}
</style>