<template>
    <span>
        {{ isOverTime ? overTimeText : timeStr }}
    </span>
</template>
<script>


import moment from 'moment';
export default {
  components: {

  },

  props: {
    startTime: String,
    countDownTime: Number,
    overTimeText: String
  },

  watch: {
    startTime: {
      handler (val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },

  created () {

  },

  mounted () {
  },

  beforeDestroy () {
    this.clearTimer();
  },

  computed: {

  },

  data () {
    return {

      isOverTime: false,
      timeStr: '00:00:00',
      timer: null,
      timeLeft: 0
    };
  },

  methods: {
    init () {
      const startTime = moment(this.startTime);
      const endTime = startTime.add(this.countDownTime, 's');
      const nowTime = moment();
      // 相差秒数
      const diff = nowTime.diff(endTime, 's');
      if (diff > 0) {
        this.isOverTime = true;
      } else {
        this.isOverTime = false;
        this.timeLeft = Math.abs(diff);
        this.clearTimer();
        this.timer = setInterval(() => {
          this.countDown();
        }, 1000);
      }
    },

    countDown () {
      this.timeLeft--;

      if (this.timeLeft <= 0) {
        this.isOverTime = true;
        this.clearTimer();
      } else {
        // 将timeLeft转换为时分秒
        const h = Math.floor(this.timeLeft / 3600);
        const m = Math.floor((this.timeLeft - h * 3600) / 60);
        const s = this.timeLeft - h * 3600 - m * 60;
        this.timeStr = `${this.formatTime(h)}:${this.formatTime(m)}:${this.formatTime(s)}`;
      }

    },

    formatTime (time) {
      return time < 10 ? `0${time}` : time;
    },

    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
    }
  },

};
</script>
<style scoped lang="less"></style>
