<template>
    <div class="root-box">
      <el-table :data="list" border stripe max-height="700">
        <el-table-column prop="sort" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="上报接口"></el-table-column>

        <el-table-column prop="url" label="URL"></el-table-column>

        <el-table-column prop="ipcType" label="ipcType"></el-table-column>

        <el-table-column prop="num" label="最低数据量"></el-table-column>

        <el-table-column prop="page" label="页码"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button
              :disabled="scope.row.disable"
              type="text"
              size="small"
              @click="report(scope.row, 1)"
            >上报</el-button>-->
            <el-button
              type="text"
              size="small"
              @click="reportData(scope.row)"
            >推送接口数据</el-button>

            <!-- <el-button
              v-if="scope.row.twice || scope.row.aa"
              type="text"
              size="small"
              :disabled="scope.row.dyna"
              @click="reportData(scope.row, true)"
            >{{!scope.row.aa ? '（推第二次）': '（最后补推）'}}</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-button @click="reportAll()" class="core">全部上报</el-button>
    </div>
  </template>

<script>
export default {
  name: "index",
  data () {
    return {
      list: [{
        name: '公司信息',
        url: '/company',
        page: 25,
        ipcType: 'baseInfoCompany',
        num: 1,
        sort: 1,
      }, {
        name: '公司支付信息',
        url: '/companyPay',
        page: 28,
        ipcType: 'baseInfoCompanyPay',
        num: 1,
        sort: 2,
      }, {
        name: '公司经营许可信息',
        url: '/CompanyPermit',
        page: 31,
        ipcType: 'baseInfoCompanyPermit',
        num: 1,
        sort: 3,
      }, {
        name: '服务机构信息',
        url: '/companyService',
        page: 29,
        ipcType: 'baseInfoCompanyService',
        num: 1,
        sort: 4,
      }, {
        name: '公司运营规模信息',
        url: '/companyStat',
        page: 27,
        ipcType: 'baseInfoCompanyStat',
        num: 1,
        sort: 5,
      }, {
        name: '合乘公司服务平台基本信息',
        url: '/shareCompany',
        page: 68,
        ipcType: 'shareCompany',
        num: 1,
        sort: 6,
      }, {
        name: '运价',
        page: 32,
        ipcType: 'baseInfoCompanyFare',
        disable: true,
        twice: true,
        num: 1,
        sort: 7,
      }, {
        name: '驾驶员基本信息',
        page: 40,
        ipcType: 'baseInfoDriver',
        disable: true,
        twice: true,
        num: 200,
        sort: 8,
      }, {
        name: '驾驶员培训信息',
        page: 43,
        ipcType: 'baseInfoDriverEducate',
        disable: true,
        twice: true,
        num: 200,
        sort: 9,
      }, {
        name: '驾驶员APP信息',
        page: 45,
        ipcType: 'baseInfoDriverApp',
        disable: true,
        twice: true,
        num: 200,
        sort: 10,
      }, {
        name: '驾驶员统计信息',
        page: 46,
        ipcType: 'baseInfoDriverStat',
        disable: true,
        twice: true,
        num: 200,
        sort: 11,
      }, {
        name: '车辆基本信息',
        page: 34,
        ipcType: 'baseInfoVehicle',
        disable: true,
        twice: true,
        num: 200,
        sort: 12,
      }, {
        name: '车辆保险信息',
        page: 39,
        ipcType: 'baseInfoVehicleInsurance',
        disable: true,
        twice: true,
        num: 200,
        sort: 13,
      }, {
        name: '车辆里程信息',
        page: 39,
        ipcType: 'baseInfoVehicleTotalMile',
        disable: true,
        twice: true,
        num: 200,
        sort: 14,
      }, {
        name: '乘客信息',
        page: 47,
        ipcType: 'baseInfoPassenger',
        disable: true,
        num: 600,
        sort: 15,
      }, {
        name: '驾驶员信誉信息',
        page: 67,
        ipcType: 'ratedDriver',
        disable: true,
        twice: true,
        aa: true,
        num: 200,
        sort: 30,
      }, {
        name: '驾驶员处罚信息',
        page: 66,
        ipcType: 'ratedDriverPunish',
        disable: true,
        twice: true,
        aa: true,
        num: 100,
        sort: 29,
      }, {
        name: '车辆经营上线',
        page: 53,
        ipcType: 'operateLogin',
        disable: true,
        dyna: true,
        num: 1000,
        sort: 16,
      }, {
        name: '车辆经营下线',
        page: 54,
        ipcType: 'operateLogout',
        disable: true,
        dyna: true,
        num: 1000,
        sort: 17,
      }, {
        name: '私人小客车合乘行程',
        page: 70,
        ipcType: 'shareRoute',
        disable: true,
        dyna: true,
        num: 50,
        sort: 18,
      }, {
        name: '订单发起',
        page: 49,
        ipcType: 'orderCreate',
        disable: true,
        dyna: true,
        num: 600,
        sort: 19,
      }, {
        name: '私人小客车合乘订单',
        page: 71,
        ipcType: 'shareOrder',
        disable: true,
        dyna: true,
        num: 50,
        sort: 20,
      }, {
        name: '订单成功',
        page: 50,
        ipcType: 'orderMatch',
        disable: true,
        dyna: true,
        num: 500,
        sort: 21,
      }, {
        name: '订单取消',
        page: 52,
        ipcType: 'orderCancel',
        disable: true,
        dyna: true,
        num: 100,
        sort: 22,
      }, {
        name: '经营出发',
        page: 55,
        ipcType: 'operateDepart',
        disable: true,
        dyna: true,
        num: 500,
        sort: 23,
      }, {
        name: '经营到达',
        page: 56,
        ipcType: 'operateArrive',
        disable: true,
        dyna: true,
        num: 500,
        sort: 24,
      }, {
        name: '经营支付',
        page: 58,
        ipcType: 'operatePay',
        disable: true,
        dyna: true,
        num: 500,
        sort: 25,
      }, {
        name: '私人小客车合乘订单支付信息',
        page: 73,
        ipcType: 'sharePay',
        disable: true,
        dyna: true,
        num: 50,
        sort: 26,
      }, {
        name: '乘客评价',
        page: 64,
        ipcType: 'ratedPassenger',
        disable: true,
        dyna: true,
        num: 500,
        sort: 27,
      }, {
        name: '乘客投诉',
        page: 65,
        ipcType: 'ratedPassengerComplaint',
        disable: true,
        dyna: true,
        num: 100,
        sort: 28,
      }, {
        name: '司机定位信息',
        page: 60,
        ipcType: 'positionDriver',
        disable: true,
        dyna: true,
        num: 6000,
        sort: 31,
      }, {
        name: '车辆定位信息',
        page: 62,
        ipcType: 'positionVehicle',
        disable: true,
        dyna: true,
        num: 6000,
        sort: 32,
      }],
    };
  },
  methods: {
    report (item) {
      const url = `/admin/report${item.url}`;
      this.$http.get(url).then(() => {
        this.$message({
          message: "数据上报成功",
          type: 'success'
        });
      });
    },

    reportData (item, reset = false) {
      const url = `/admin/report/pushTask?reset=${reset}&ipcType=${item.ipcType}`;
      this.$http.get(url).then(() => {
        this.$message({
          message: "数据上报成功",
          type: 'success'
        });
      });
    },

    reportAll () {
      this.$confirm('确认上报吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const url = `/admin/report/pushTask`;
        this.$http.get(url).then(() => {
          this.$message({
            message: "数据上报成功",
            type: 'success'
          });
        });
        // this.$confirm('确认数据都准备好了吗?', '提示', {
        //     confirmButtonText : '确认',
        //     cancelButtonText : '取消',
        //     type : 'warning'
        // }).then(() => {
        //     const url = `/admin/report/pushTask/flowType`;
        //     this.$http.get(url, {flowType}).then(() => {
        //         this.$message({
        //             message : "数据上报中，可以去上报日志中查看上报详情",
        //             type : 'success'
        //         });
        //     });
        // });
      });
    }
  }
};
</script>

  <style scoped lang="less">
  .core {
    margin: 20px 0;
    width: 120px;
  }
  </style>
