<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="用户" v-if="user">
          <el-input v-model="user.telephone" disabled></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            @change="filterData"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="param.startTime"
            type="datetime"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="结束日期">
          <el-date-picker
            @change="filterData"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="param.endTime"
            type="datetime"
            placeholder="选择结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="会话">
          <el-select
            v-model="param.session"
            @change="filterData"
            placeholder="会话"
            filterable
            clearable
          >
            <el-option
              v-for="item in sessionOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端" prop="agent">
          <el-select v-model="param.agent" @change="filterData" placeholder="请选择" clearable>
            <el-option
              v-for="item in agentOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="资源类型">
          <el-select v-model="param.rsType" @change="filterData" placeholder="资源类型" clearable>
            <el-option
              v-for="item in rsTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-slider
        v-model="value"
        :format-tooltip="formatTooltip"
        range
        show-tooltip
        :max="max"
        :min="min"
        :step="10"
        @change="silderChange"
      >
      </el-slider>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column label="时间" prop="optTime" width="160"></el-table-column>

        <el-table-column label="资源" width="200">
          <template slot-scope="scope">
            <span @click="openModel(scope.row)"> {{ scope.row | apmRsName(apmRs) }}</span>
          </template>
        </el-table-column>

        <el-table-column label="停留时长" prop="duration" width="120"></el-table-column>

        <el-table-column label="类型" width="80">
          <template slot-scope="scope">
            {{ scope.row.rsType | enumTransfer(rsTypeOpts) }}
          </template>
        </el-table-column>

        <el-table-column label="客户端" prop="agent">
          <template slot-scope="scope">{{ scope.row.agent | enumTransfer(agentOpts) }}</template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :append-to-body="true"
      title="资源信息"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :size="size" label-width="120px">
        <el-form-item label="资源id" prop="rsId">
          <el-input v-model="form.rsId"></el-input>
        </el-form-item>

        <el-form-item label="资源名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="资源类型：" prop="rsType">
          <el-select v-model="form.rsType" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in rsTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端：" prop="agent">
          <el-select v-model="form.agent" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in agentOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { optTrackMetas, saveUpdateRsidInfo } from "@/api";
import { DateUtil } from "@/util";
import { mapState } from "vuex";
import store from "@/store";
import { ADD_GLOBAL_DATA } from "@/store/mutation-types";

export default {
  components: {},
  props: {},
  created() {
    this.list = this.detailList;
  },
  mounted() {},

  computed: {
    ...mapState({
      apmRs: (state) => state.apmRs,
      size: (state) => state.size,
      agentOpts: (state) => state.enumMap["APM_AGENT"],
    }),
  },
  data() {
    return {
      rsTypeOpts: [
        { label: "按钮", value: "button" },
        { label: "页面", value: "page" },
      ],
      param: {},
      list: [],
      rsNameMap: {},
      user: null,
      min: 0,
      max: 0,
      value: [],
      visible: false,
      form: {},
      sessionOpts: [],
      rules: {
        rsId: [{ required: true, message: "请输入资源id", trigger: "change" }],
        name: [{ required: true, message: "请输入资源名称", trigger: "change" }],
        rsType: [{ required: true, message: "请选择资源类型", trigger: "change" }],
        agent: [{ required: true, message: "请选择客户端类型", trigger: "change" }],
      },
    };
  },
  methods: {
    show(list, user) {
      this.user = user;
      this._oriData = list;
      const sessionOpts = [];
      const sessionIds = [];
      list.forEach((item) => {
        if (!sessionIds.includes(item.sessionId)) {
          sessionOpts.push({ label: item.optTime, value: item.sessionId });
          sessionIds.push(item.sessionId);
        }
      });
      this.sessionOpts = sessionOpts;
      if (list.length > 1) {
        this.param = {
          startTime: list[0].optTime,
          endTime: list[list.length - 1].optTime,
          rsType: null,
        };

        this.min = DateUtil.str2Date(this.param.startTime).getTime() / 1000;
        this.max = DateUtil.str2Date(this.param.endTime).getTime() / 1000;
        this.value = [this.min, this.max];
      }
      this.filterData();
    },

    silderChange(val) {
      this.$set(
        this.param,
        "startTime",
        DateUtil.format(new Date(val[0] * 1000), "yyyy-MM-dd hh:mm:ss")
      );
      this.$set(
        this.param,
        "endTime",
        DateUtil.format(new Date(val[1] * 1000), "yyyy-MM-dd hh:mm:ss")
      );
      this.filterData();
    },

    filterData() {
      if (this._oriData.length > 1) {
        const list = this._oriData.filter((t) => {
          const timeFilter = t.optTime >= this.param.startTime && t.optTime <= this.param.endTime;
          const rsTypeFilter = this.param.rsType ? t.rsType === this.param.rsType : true;
          const sessionFilter = this.param.session ? t.sessionId === this.param.session : true;
          const agentFilter = this.param.agent ? t.agent === this.param.agent : true;
          return timeFilter && rsTypeFilter && sessionFilter && agentFilter;
        });
        this.list = list;
      } else {
        this.list = this._oriData;
      }
    },

    formatTooltip(val) {
      return DateUtil.format(new Date(val * 1000), "MM-dd hh:mm:ss");
    },

    openModel(item) {
      this.form = { ...item };
      this.visible = true;
    },

    saveOrUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const param = { ...this.form };
          delete param.id;
          saveUpdateRsidInfo(param).then(() => {
            this.visible = false;
            this.$message.success("操作成功");
            this.getRs();
          });
        }
      });
    },

    getRs() {
      optTrackMetas().then((res) => {
        const list = res.data || [];
        store.commit(ADD_GLOBAL_DATA, {
          key: "apmRs",
          value: list,
        });
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
