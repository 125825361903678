<template>
  <div>
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="统计时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车队：" prop="teamId">
            <el-select v-model="param.teamId" clearable placeholder="全部" style="width: 100%">
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="container">
      <div class="top">
        <div class="box" :class="{ active: type === 1 }" @click="chooseType(1)">
          <div class="b1">{{ data.evaScore }}分</div>
          <div class="b2">乘客评价</div>
          <div class="b3">
            <div class="left">五星 {{ data.star5 }}</div>
            <div class="right">一星/不平稳 {{ data.star1 }}/{{ data.driveStar1 }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 2 }" @click="chooseType(2)">
          <div class="b1">{{ data.complaintScore }}分</div>
          <div class="b2">投诉拉黑</div>
          <div class="b3">
            <div class="left">投诉 {{ data.complaint }}</div>
            <div class="right">拉黑 {{ data.blacklist }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 3 }" @click="chooseType(3)">
          <div class="b1">{{ data.operateScore }}分</div>
          <div class="b2">订单操作</div>
          <div class="b3">
            <div class="left">合规 {{ data.goodOperate }}</div>
            <div class="right">违规 {{ data.badOperate }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 7 }" @click="chooseType(7)">
          <div class="b1">{{ data.ontimeScore }}分</div>
          <div class="b2">准点率</div>
          <div class="b3">
            <div class="left">优秀 {{ data.goodOntime }}</div>
            <div class="right">不合格 {{ data.badOntime }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 4 }" @click="chooseType(4)">
          <div class="b1">{{ data.durationScore }}分</div>
          <div class="b2">路程时长</div>
          <div class="b3">
            <div class="left">五座 {{ (data.fiveSeatsDuration / 3600) | numFormat(1) }}</div>
            <div class="right">七座 {{ (data.sevenSeatsDuration / 3600) | numFormat(1) }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 5 }" @click="chooseType(5)">
          <div class="b1">{{ data.commScore }}分</div>
          <div class="b2">沟通规范</div>
          <div class="b3">
            <div class="left">合规 {{ data.goodComm }}</div>
            <div class="right">违规 {{ data.badComm }}</div>
          </div>
        </div>

        <div class="box" :class="{ active: type === 6 }" @click="chooseType(6)">
          <div class="b1">{{ data.safetyScore }}分</div>
          <div class="b2">安全规范</div>
          <div class="b3">
            <div class="left">违规{{ data.safetyNums }}人</div>
            <div class="right">合计{{ data.safetyTimes }}次</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <el-table :data="data.list" stripe max-height="480" v-if="type === 1">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>
          <el-table-column prop="totalEvaluated" label="总评价数量"></el-table-column>

          <el-table-column prop="pathId" label="评价率">
            <template slot-scope="scope">
              <div v-if="scope.row.totalOrders != 0">
                {{ ((scope.row.totalEvaluated / scope.row.totalOrders / 2) * 100) | numFormat(0) }}
                %
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="star5" label="5星个数"></el-table-column>
          <el-table-column prop="star3" label="3星个数"></el-table-column>
          <el-table-column prop="star2" label="2星个数"></el-table-column>
          <el-table-column prop="star1" label="1星个数"></el-table-column>
          <el-table-column prop="driverStar1" label="驾驶不平稳"></el-table-column>
          <el-table-column prop="scoreEvaluation" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 2">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>

          <el-table-column prop="complaintTimes" label="投诉次数"></el-table-column>
          <el-table-column prop="verifiedComplaintTimes" label="核准投诉次数"></el-table-column>
          <el-table-column prop="verifiedComplaintTimes" label="投诉率">
            <template slot-scope="scope">
              <div v-if="scope.row.totalOrders != 0">
                {{
                  ((scope.row.verifiedComplaintTimes / scope.row.totalOrders) * 100) | numFormat(0)
                }}
                %
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="blacklistTimes" label="拉黑次数"></el-table-column>
          <el-table-column prop="scoreComplaint" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 3">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>

          <el-table-column prop="totalOperate" label="全部操作"></el-table-column>
          <el-table-column prop="totalBadOperate" label="不合规操作"></el-table-column>
          <el-table-column prop="scoreComplaint" label="出发">
            <template slot-scope="scope">
              {{ scope.row.goodStep1 }}/{{ scope.row.badStep1 }}
            </template>
          </el-table-column>
          <el-table-column prop="scoreComplaint" label="到达">
            <template slot-scope="scope">
              {{ scope.row.goodStep2 }}/{{ scope.row.badStep2 }}
            </template>
          </el-table-column>
          <el-table-column prop="scoreComplaint" label="上车">
            <template slot-scope="scope">
              {{ scope.row.goodStep3 }}/{{ scope.row.badStep3 }}
            </template>
          </el-table-column>
          <el-table-column prop="scoreComplaint" label="下车">
            <template slot-scope="scope">
              {{ scope.row.goodStep4 }}/{{ scope.row.badStep4 }}
            </template>
          </el-table-column>
          <el-table-column prop="scoreComplaint" label="完成">
            <template slot-scope="scope">
              {{ scope.row.goodStep5 }}/{{ scope.row.badStep5 }}
            </template>
          </el-table-column>
          <el-table-column prop="verifiedComplaintTimes" label="不合规率">
            <template slot-scope="scope">
              <div v-if="scope.row.totalOperate != 0">
                {{ ((scope.row.totalBadOperate / scope.row.totalOperate) * 100) | numFormat(0) }}
                %
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="scoreComplaint" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 4">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>
          <el-table-column prop="totalOrders" label="车型">
            <template slot-scope="scope">
              {{ scope.row.vehicleType | enumTransfer(vehicleTypeOpts, "id", "content") }}
            </template>
          </el-table-column>
          <el-table-column prop="goodDuration" label="优秀路程时长" width="140">
            <template slot-scope="scope"> {{ scope.row.goodDuration }}次 </template>
          </el-table-column>
          <el-table-column prop="commonDuration" label="合格路程时长" width="140">
            <template slot-scope="scope"> {{ scope.row.commonDuration }}次 </template>
          </el-table-column>
          <el-table-column prop="badDuration" label="不合格路程时长" width="140">
            <template slot-scope="scope"> {{ scope.row.badDuration }}次 </template>
          </el-table-column>

          <el-table-column prop="accuratelyDurationNum" label="有效考核次数"></el-table-column>

          <el-table-column prop="duration" label="平均接单时长">
            <template slot-scope="scope">
              {{
                (scope.row.totalDuration / scope.row.accuratelyDurationNum / 3600) | numFormat(1)
              }}
            </template>
          </el-table-column>
          <el-table-column prop="scoreDuration" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 5">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>
          <el-table-column prop="totalCommTimes" label="抽查次数"></el-table-column>

          <el-table-column prop="goodCommTimes" label="合格次数"></el-table-column>

          <el-table-column prop="badCommTimes" label="不合格次数"></el-table-column>

          <el-table-column prop="totalOrders" label="不合格率">
            <template slot-scope="scope">
              <div v-if="scope.row.totalCommTimes != 0">
                {{ ((scope.row.badCommTimes / scope.row.totalCommTimes) * 100) | numFormat(0) }}
                %
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="scoreDuration" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 6">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>
          <el-table-column prop="overSpeed" label="超速"></el-table-column>
          <el-table-column prop="overLoad" label="超载"></el-table-column>
          <el-table-column prop="playPhone" label="玩手机"></el-table-column>
          <el-table-column prop="noHeadphones" label="不带耳机"></el-table-column>
          <el-table-column prop="tiredness" label="疲劳驾驶"></el-table-column>
          <el-table-column prop="others" label="其他危险驾驶"></el-table-column>

          <el-table-column prop="scoreSafety" label="合计服务分"></el-table-column>
        </el-table>

        <el-table :data="data.list" stripe max-height="480" v-if="type === 7">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="totalOrders" label="总接单数"></el-table-column>

          <el-table-column prop="totalOntime" label="有效考核"></el-table-column>
          <el-table-column prop="badOntime" label="不合格"></el-table-column>
          <el-table-column prop="commonOntime" label="合格"></el-table-column>
          <el-table-column prop="goodOntime" label="优秀"></el-table-column>

          <el-table-column prop="verifiedComplaintTimes" label="不合格率">
            <template slot-scope="scope">
              <div v-if="scope.row.totalOntime != 0">
                {{ ((scope.row.badOntime / scope.row.totalOntime) * 100) | numFormat(0) }}
                %
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="scoreOntime" label="合计服务分"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getServiceScoreSummary } from "@/api";
import config from "@/config/config";
import { DateUtil } from "@/util";

export default {
  components: {},
  props: {
    value: Object,
  },
  created() {
    this.query();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      size: (state) => state.btnSize,
      teamOpts: (state) => state.teamOpts,
    }),
  },
  data() {
    return {
      type: 1,
      items: [
        { name: "路程时长", field: "durationScore" },
        { name: "超载", field: "overLoad" },
        { name: "玩手机", field: "playPhone" },
        { name: "不带耳机通话", field: "noHeadphones" },
        { name: "疲劳驾驶", field: "tiredness" },
        { name: "其他", field: "others" },
      ],
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
        teamId: config.initTeam,
        period: "day",
      },
      data: {},
    };
  },
  methods: {
    query() {
      getServiceScoreSummary(this.param).then((res) => {
        this.data = res.data;
      });
    },

    chooseType(type) {
      this.type = type;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 10px;

  .top {
    display: flex;
    justify-content: space-around;
    .box {
      width: 200px;
      height: 100px;
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      color: #333;
      cursor: pointer;

      &.active {
        background-color: #4894efc7;
        color: white;
      }

      .b1 {
        font-size: 18px;
      }

      .b2 {
        font-weight: bold;
        font-size: 18px;
      }
      .b3 {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .bottom {
    padding: 20px;
    margin-top: 20px;
  }
}
</style>
