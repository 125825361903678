import {Http, ParamUtil} from '@/util';

//查询操作资源集合
export function optTrackMetas (param) {
  return Http.post('/admin/analysis/track/gettracklist', ParamUtil.filterEmptyData(param));
}

//查询用户操作流水
export function userActionDetail (param) {
  return Http.post('/admin/analysis/track/listuseraction', ParamUtil.filterEmptyData(param));
}

//按照资源汇总查询每日操作
export function userOptTrackDailySum (param) {
  return Http.post('/admin/analysis/track/list', ParamUtil.filterEmptyData(param));
}

//按照资源查询每日操作流水记录
export function userOptTrackDetail (param) {
  return Http.post('/admin/analysis/track/detail', ParamUtil.filterEmptyData(param));
}



export function saveUpdateRsidInfo (param) {
  return Http.post('/admin/apm/save_update/rsid', ParamUtil.filterEmptyData(param));
}


export function deleteRsidInfo (param) {
  return Http.post('/admin/apm/del/rsid', ParamUtil.filterEmptyData(param));
}







