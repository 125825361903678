module.exports = {
  publicPath: '/yxmgt/',
  isDev: process.env.NODE_ENV === 'development',
  initPath: 6,
  initTeam: 4,
  manageId: 3,
  kefuRoleId: 4,
  publicKey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCItZpYvGVMZZ1Es4zXb9wegHNZtpKcxGv9c5hxdr/CvgRRb3dGF88eHXqWdJaIoy5Grjl/OzZgDj2WRkqrqea/MsSKzWUZaZnU+gukzpZMzxfdNjTiL0hS1NFZFpqNSlhB0Ymob9y929N48htZOG/ixrTMdn5oGh57F25PRFHTdwIDAQAB',
  whiteList: ['routeDetail', 'driverDetail', 'orderList'],
};
