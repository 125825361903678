import { Message } from "element-ui";
import { Observable } from "rxjs";
import StompClient from "./stomp-client";
import { LocalStorage } from "@/util";
import { TOKEN, USER_INFO } from "@/core/const/global-const";

const errHandle = function (err) {
  console.error(err);
  Message.error("客服消息出现问，请联系管理员处理");
};
export class KefuService {
  static getConnectClient() {
    return new Promise((resolve) => {
      if (KefuService.client && KefuService.client.active) {
        resolve(KefuService.client);
      } else {
        const token = LocalStorage.get(TOKEN);
        if (!token) {
          return;
        }
        const url = `/notification?${TOKEN}=${token}`;
        const user = LocalStorage.get(USER_INFO);
        KefuService.userId = user.id;
        const stompClient = new StompClient(url, { name: KefuService.userId });
        stompClient.connect(
          () => {
            KefuService.client = stompClient;
            Message.success("客服推送消息连接成功");
            resolve(stompClient);
          },
          (error) => {
            console.error("Error connecting to STOMP:", error);
            Message.error("客服推送消息连接失败");
          }
        );
      }
    });
  }

  // 获取用户列表
  static async getUserList() {
    const client = await KefuService.getConnectClient();
    const url = "/admin/kf/list";
    return new Promise((resolve) => {
      client.subscribe(url, (res) => {
        resolve(JSON.parse(res.body));
      });
    }, errHandle);
  }

  // 订阅用户信息
  static async subscribeUserInfo() {
    const client = await KefuService.getConnectClient();
    const url = `/user/${KefuService.userId}/kf/msgSenderInfo`;
    return new Observable((subscriber) => {
      client.subscribe(url, (res) => {
        subscriber.next(JSON.parse(res.body));
      });
    });
  }

  // 获取用户信息
  static async getUserInfo(msgSender) {
    const client = await KefuService.getConnectClient();
    const url = "/admin/kf/getMsgSenderInfo";
    client.send(url, {}, JSON.stringify({ msgSender }));
  }

  // 订阅完整消息
  static async subscribeAllMessage() {
    const client = await KefuService.getConnectClient();
    const url = `/user/${KefuService.userId}/kf/msgInfo`;

    return new Observable((subscriber) => {
      client.subscribe(url, (res) => {
        console.log(JSON.parse(res.body));
        subscriber.next(JSON.parse(res.body));
      });
    });
  }

  // 获取用户完整消息
  static async getUserAllMsg(msgSender) {
    const client = await KefuService.getConnectClient();
    const url = "/admin/kf/msgInfo";
    client.send(url, {}, JSON.stringify({ msgSender }));
  }

  // 发送消息
  static async sendMsg(info) {
    const client = await KefuService.getConnectClient();
    var url = "/admin/kf/sendtxtmsg";
    var msg = {
      content: info.content,
      msgType: info.msgType,
      msgSender: info.msgSender,
      msgSrc: info.msgSrc,
    };
    client.send(url, {}, JSON.stringify(msg));
  }

  // 标记已读
  static async markRead(msgSender) {
    const client = await KefuService.getConnectClient();
    var url = "/admin/kf/marksender";
    client.send(url, {}, msgSender);
  }

  // 订阅发送消息回执
  static async sendMsgCallback() {
    const client = await KefuService.getConnectClient();
    const url = `/user/${KefuService.userId}/kf/sendtxtmsg`;
    return new Observable((subscriber) => {
      client.subscribe(url, (res) => {
        subscriber.next(JSON.parse(res.body));
      });
    });
  }

  // 订阅单条消息
  static async subscribeMessage() {
    const client = await KefuService.getConnectClient();
    const url = `/user/${KefuService.userId}/kfmsg`;
    return new Observable((subscriber) => {
      client.subscribe(url, (res) => {
        subscriber.next(JSON.parse(res.body));
      });
    });
  }

  // 获取用户历史消息
  static async getUserHisMsg(msgSender, sendTime) {
    const client = await KefuService.getConnectClient();
    const url = "/admin/kf/msgInfoHist";
    return client.send(url, {}, JSON.stringify({ msgSender, sendTime }));
  }

  // 订阅历史消息
  static async subscribeHisMessage() {
    const client = await KefuService.getConnectClient();
    const url = `/user/${KefuService.userId}/kf/msgInfoHist`;
    return new Observable((subscriber) => {
      client.subscribe(url, (res) => {
        subscriber.next(JSON.parse(res.body));
      });
    });
  }

  // 客服上线
  static async online() {
    const client = await KefuService.getConnectClient();
    client.send("/admin/kf/online", {}, KefuService.userId);
    return "ok";
  }

  // 客服下线
  static async offline() {
    const client = await KefuService.getConnectClient();
    client.send("/admin/kf/offline", {}, KefuService.userId);
    return "ok";
  }

  static disconnect() {
    if (KefuService.client) {
      return new Promise((resolve) => {
        KefuService.client.disconnect(() => {
          KefuService.client = null;
          resolve();
        });
      });
    } else {
      return Promise.resolve();
    }
  }
}
