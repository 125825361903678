<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="乘客手机号：">
          <el-input clearable v-model="param.passengerTel" />
        </el-form-item>

        <el-form-item label="状态：">
          <el-select
            @change="query()"
            clearable
            v-model="param.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="司机手机号：">
          <el-input clearable v-model="param.driverTel" />
        </el-form-item>

        <el-form-item label="投诉时间：">
          <el-date-picker
            placeholder="选择时间"
            v-model="param.startTime"
            value-format="yyyy-MM-dd"
          ></el-date-picker>

          至
          <el-date-picker
            placeholder="选择时间"
            v-model="param.endTime"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="vehicleName" label="投诉乘客" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.passengerName }}</div>
            <div>
              <span v-click-tel="scope.row.passengerTel">
                {{ scope.row.passengerTel | encryptTel }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="complaintTime" label="投诉时间" width="150">
        </el-table-column>

        <el-table-column prop="detail" label="投诉内容" width="200">
        </el-table-column>

        <el-table-column prop="ownerTelephone" label="投诉级别">
          <template slot-scope="scope">
            {{ scope.row.complaintLevel | enumTransfer(complaintLevelOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="ownerTelephone" label="司机/电话">
          <template slot-scope="scope">
            <div>
              {{ scope.row.driverName }}
            </div>
            <div>
              <span v-click-tel="scope.row.driverTel">
                {{ scope.row.driverTel | encryptTel }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="ownerTelephone" label="投诉渠道">
          <template slot-scope="scope">
            {{
              scope.row.complaintChannel | enumTransfer(complaintChannelOpts)
            }}
          </template>
        </el-table-column>

        <el-table-column prop="ownerTelephone" label="责任方">
          <template slot-scope="scope">
            {{ scope.row.responsible | enumTransfer(responsibleOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="annex1" label="附件">
          <template slot-scope="scope" v-if="scope.row.annexes">
            <a
              v-for="(item, index) in scope.row.annexes"
              :key="item"
              class="annex"
              :href="item"
              target="_blank"
              >附件{{ index + 1 }}</a
            >
          </template>
        </el-table-column>

        <el-table-column prop="ownerTelephone" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status | enumTransfer(statusOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="result" label="处理结果"> </el-table-column>

        <el-table-column prop="address" fixed="right" label="操作" width="140">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.status === 1"
              type="text"
              size="small"
              @click="deal(scope.row)"
              >处理</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="goDetail(scope.row.routeId)"
              >行程</el-button
            >

            <el-button
              type="text"
              size="small"
              v-goRouteDetail="scope.row.orderId"
              >订单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="dealVisible"
      width="680px"
      max-height="600"
      :size="size"
      title="乘客投诉"
      :close-on-click-modal="false"
    >
      <el-row style="margin: 0 0 20px">
        <el-col :span="7"> 投诉对象： {{ complaintForm.driverName }}</el-col>
        <el-col :span="10"> 时间： {{ complaintForm.complaintTime }}</el-col>
      </el-row>
      <el-form
        :model="complaintForm"
        :size="size"
        ref="complaintForm"
        label-width="100"
      >
        <el-form-item prop="detail" label="投诉内容：">
          <el-input
            type="textarea"
            v-model="complaintForm.detail"
            :rows="5"
            style="width: 500px"
            placeholder="投诉内容"
          ></el-input>
        </el-form-item>

        <el-form-item label="附件：" style="margin: 10px 0 20px">
          <div style="padding-left: 66px">
            <multiple-file-upload
              v-model="complaintForm.fileList"
              filePath="/eva_item"
            />
          </div>
          <div
            class="annex-box"
            v-if="complaintForm.fileList && complaintForm.fileList.length"
          >
            <span>附件下载：</span>
            <span
              v-for="item in complaintForm.fileList"
              :key="item.url"
              style="margin-right: 24px"
            >
              <a style="color: #409eff" :href="item.url" target="_blank">{{
                item.name
              }}</a>
            </span>
          </div>
        </el-form-item>

        <el-form-item label="投诉等级：" prop="complaintLevel">
          <el-radio-group v-model="complaintForm.complaintLevel">
            <el-radio
              :label="item.value"
              v-for="item in complaintLevelOpts"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="投诉渠道：" prop="complaintChannel">
          <el-select v-model="complaintForm.complaintChannel">
            <el-option
              v-for="item in complaintChannelOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="可否定责：" prop="canDetermineResp">
              <el-select v-model="complaintForm.canDetermineResp">
                <el-option
                  v-for="item in yesNoOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="投诉定责："
              prop="responsible"
              v-if="complaintForm.canDetermineResp"
            >
              <el-select v-model="complaintForm.responsible">
                <el-option
                  v-for="item in responsibleOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="处理结果：" prop="result">
          <el-input
            type="textarea"
            v-model="complaintForm.result"
            :rows="5"
            style="width: 500px"
            placeholder="处理结果"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="备注说明："
          prop="remark"
          v-if="complaintForm.canDetermineResp === false"
        >
          <el-input
            type="textarea"
            v-model="complaintForm.remark"
            :rows="3"
            style="width: 500px"
            placeholder="备注"
          ></el-input>
        </el-form-item>

        <el-form-item label="处理进度：" prop="status">
          <el-select v-model="complaintForm.status">
            <el-option
              v-for="item in complaintStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button @click.stop="saveDeal" type="primary" size="small"
          >保 存</el-button
        >

        <el-button @click.stop="dealVisible = false" size="small"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {getComplaintList, saveOrUpdateComplaint } from '@/api';
import {ParamUtil} from '@/util';
import {mapState} from 'vuex';
import Router from '@/route';
import {BaseDataService} from '@/service';

export default {
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      statusOpts: state => state.enumMap['COMPLAINT_STATUS'],
      responsibleOpts: state => state.enumMap['COMPLAINT_RESPONSIBLE_ENUM'],
      complaintLevelOpts: state => state.enumMap['COMPLAINT_LEVEL'],
      complaintChannelOpts: state => state.enumMap['EVA_SOURCE_CHANNEL'],
      complaintStatusOpts: state => state.enumMap['COMPLAINT_STATUS'],

    }),
  },
  data () {
    return {
      yesNoOpts: BaseDataService.yesNoOpts(),
      param: {
        name: ''
      },

      complaintForm: {
        result: '短期封号，长期封号，停运'
      },
      dealVisible: false,
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      dealRules: {
        status: [
          { required: true, message: '请选择处理状态', trigger: 'blur' },
        ],
        result: [
          { required: true, message: '请录入处理结果', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {

    query (page = 1) {

      const param = {
        page: {
          current: page,
          size: this.pageSize
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: ParamUtil.filterEmptyData(this.param)
      };
      getComplaintList(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    deal (item) {
      this.current = {...item};
      const complaintForm = {...item} ;
      let fileList = complaintForm.annexes || [];
      // 过滤空和生成文件名
      fileList = fileList.filter(t=> t).map((t, index)=>{
        const fileType = t.substr(t.lastIndexOf('.'));
        return {
          name: `文件${index + 1}${fileType}`,
          url: t
        };
      });
      complaintForm.fileList = fileList;
      if (!complaintForm.status) {
        complaintForm.status = 0;
      }
      this.complaintForm = complaintForm;
      this.dealVisible = true;
    },
    saveDeal () {
      const param = this.complaintForm;
      if (param.fileList && param.fileList.length > 0) {
        param.annexes = param.fileList.map(t=> t.url);
      }
      saveOrUpdateComplaint(param).then(() => {
        this.$message.success('保存成功');
        this.cancelDeal();
        this.query();
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },
    cancelDeal () {
      this.dealVisible = false;
    },

    handleCommand (command, item) {
      command = parseInt(command);
      switch (command) {
        case 1: this.goDetail(item.routeId); break;

      }
    },

    goDetail (routeId) {
      const {href} = Router.resolve({
        path: '/route/list', query: {routeId }
      });
      window.open(href, '_blank');
    }

  }
};
</script>

<style scoped lang="less">
.photo {
  width: 100px;
  height: 100px;
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.annex-box {
  border-top: 1px solid #eee;
  font-size: 14px;
  padding: 10px 0 0;
  margin: 10px 0 0;
}

.annex {
  color: #409eff;
  margin-right: 4px;
}
</style>
