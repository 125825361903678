<template>
  <div id="app-login">
    <div class="input-box center">
      <h1 class="title">优 选 顺 风 管 理 后 台</h1>

      <el-form
        :model="form"
        label-position="top"
        :rules="rules"
        ref="form"
        label-width="90px"
        class="form"
      >
        <el-form-item label="用户名：" prop="name">
          <el-input type="username" v-model="form.username" autofocus @keyup.enter.native="login">
            <template slot="prepend">
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input type="password" @keyup.enter.native="login" v-model="form.password">
            <template slot="prepend">
              <i class="el-icon-unlock"></i>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="login" style="width: 100%; margin-top: 20px"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="beian">
      <div>
        Copyright© 武汉优选顺风科技有限公司 2016-2022 |
        <a style="color: white" href="https://beian.miit.gov.cn" target="_blank"
          >鄂ICP备16018225号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Toolkit, LocalStorage } from "@/util";
import {
  USER_INFO,
  PERMISSION,
  TOKEN,
  PERMISSION_ARR,
  MVB2000WEB_KEY,
} from "@/core/const/global-const";
import config from "@/config/config";
import { CommonService } from "@/service";

const getPermission = (tree = []) => {
  const result = [];
  const each = (data = []) => {
    data.forEach((item) => {
      result.push(item.id);
      if (item.children) {
        each(item.children);
      }
    });
  };
  each(tree);
  return result;
};

export default {
  mounted() {
    // this.initVaild();
  },
  data() {
    return {
      form: {
        cid: "JL",
      },
      token: "token",
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    initVaild() {
      vaptcha({
        //配置参数
        vid: "5dd1270c27d451dade2f342c", // 验证单元id
        type: "click", // 展现类型 点击式
        container: "#vaptchaContainer", // 按钮容器，可为Element 或者 selector
        scene: 0,
        offline_server: "https://www.woyaozuoche.com/common/offline/vercode",
        // mode: 'offline'
      }).then((vaptchaObj) => {
        vaptchaObj.render();
        vaptchaObj.listen("pass", () => {
          this.token = vaptchaObj.getToken();
        });
      });
    },
    login() {
      const url = "/admin/login";
      this.$refs.form.validate((valid) => {
        if (valid) {
          const param = {
            cid: this.form.cid,
            username: Toolkit.encrypt(this.form.username),
            password: Toolkit.encrypt(this.form.password),
            token: this.token,
          };
          this.$http
            .post(url, param)
            .then((res) => {
              const { user, token, indexPage, permissions, mvb2000webkey } = res.data || {};
              LocalStorage.set(USER_INFO, user);
              LocalStorage.set(TOKEN, token);
              LocalStorage.set(PERMISSION, permissions);
              LocalStorage.set(MVB2000WEB_KEY, mvb2000webkey);
              // 提取所有权限id到数组
              const perArr = getPermission(permissions);
              LocalStorage.set(PERMISSION_ARR, perArr);
              CommonService.login();
              this.$router.push({
                path: indexPage ? indexPage : "/work_common/dispatch",
              });
            })
            .catch(() => {
              if (!config.isDev) {
                this.token = null;
                this.initVaild();
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" type="text/less">
#app-login {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: #e6f7ff;
  background-image: url("./assets/image/login.jpg");
  background-size: cover;

  .input-box {
    width: 400px;
    margin-top: 6%;

    .title {
      text-align: center;
      margin-bottom: 100px;
      color: #f5f5f5;
    }
  }

  .form {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 50px 20px 30px;

    label {
      color: white;
      padding: 0;
    }

    .el-form-item:first-child {
      margin-bottom: 10px;
    }
  }

  .vaptcha-init-main {
    display: flex;
    background-color: #eeeeee;
    padding: 0 20px;

    span {
      margin-left: 20px;
    }
  }

  .beian {
    color: white;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
