import {Http} from '@/util';

export function predictSmsSendUser (param) {
  return Http.post('/admin/sms/query/user', param);
}


export function delSmsTemplate (param) {
  return Http.post('/admin/sms/template/del', param);
}

export function getSmsTemplateList (param) {
  return Http.post('/admin/sms/template/list', param);
}

export function saveOrUpdateSmsTemplate (param) {
  return Http.post('/admin/sms/template/saveorupdate', param);
}

export function auditTemplate (param) {
  return Http.post('/admin/sms/template/audit', param);
}


export function delSmsCoupon (param) {
  return Http.post('/admin/sms/coupon/del', param);
}

export function getSmsCouponList (param) {
  return Http.post('/admin/sms/coupon/list', param);
}

export function saveOrUpdateSmsCoupon (param) {
  return Http.post('/admin/sms/coupon/saveorupdate', param);
}


export function delUserGroup (param) {
  return Http.post('/admin/sms/del/user_group', param);
}

export function getUserGroup (param) {
  return Http.post('/admin/sms/get/user_group', param);
}

export function saveUserGroup (param) {
  return Http.post('/admin/sms/save/user_group', param);
}

export function getPlanUsers (param) {
  return Http.post('/admin/sms/get/plan/users', param);
}


export function listPlan (param) {
  return Http.post('/admin/sms/plan/list', param);
}

export function delPlan (param) {
  return Http.post('/admin/sms/plan/del', param);
}

export function savePlan (param) {
  return Http.post('/admin/sms/plan/save', param);
}


export function getYunpianAccountInfo () {
  return Http.get('/admin/sms/account/info');
}


export function sendMsgByPlan (param) {
  return Http.post('/admin/sms/plan/send_msg', param);
}

export function assessSendPlan () {
  return Http.post('/admin/sms/plan/assess');
}


export function getSummary (param) {
  return Http.post('/admin/sms/summary', param);
}

