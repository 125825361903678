<template>
  <transition name="bottom-modal">
    <div v-if="visible" class="alarm-box">
      <div>
        <div class="info">{{ driverNo }}行程告警</div>

        <el-carousel height="200px" :autoplay="false" trigger="click" ref="carousel">
          <el-carousel-item v-for="record in list" :key="record.id">
            <AlarmItem :data="record" :imgList="list" />
          </el-carousel-item>
        </el-carousel>

        <div class="btns">
          <el-button type="text" :disabled="index == 0" @click="pre">上一个司机报警</el-button>
          <span>
            <el-button type="text" @click="telTip()">电话提醒</el-button>
            <span style="margin: 0 4px">/</span>
            <el-button type="text" @click="ignore">忽略</el-button>
          </span>
          <el-button type="text" :disabled="index >= size - 1" @click="next"
            >下一个司机报警</el-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import AlarmItem from "./AlarmItem.vue";
import { tipDriverHaveRest } from "@/api";

export default {
  components: {
    AlarmItem,
  },

  watch: {},

  created() {},

  mounted() {},

  computed: {},

  data() {
    return {
      data: {},
      visible: false,
      list: [],
      keyList: [],
      size: 0,
      index: 0,
      driverNo: "",
    };
  },

  methods: {
    show(data) {
      this.data = data;
      this.keyList = Object.keys(data);
      this.size = this.keyList.length;
      this.initData(0);
    },

    initData(index) {
      this.index = index;
      this.driverNo = this.keyList[index];
      this.list = this.data[this.driverNo];
      this.visible = true;
    },

    pre() {
      this.initData(this.index - 1);
    },

    next() {
      this.initData(this.index + 1);
    },

    ignore() {
      this.keyList.splice(this.index, 1);
      this.size = this.keyList.length;
      if (this.size == 0) {
        this.visible = false;
      } else {
        this.initData(0);
        this.$refs.carousel.setActiveItem(0);
      }
    },

    telTip() {
      this.$confirm(`是否确认电话提醒`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const driverId = this.list[0].driverId;
          tipDriverHaveRest(driverId);
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="less">
.alarm-box {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 400px;
  min-height: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
  z-index: 999;
  font-size: 14px;

  .info {
    margin-bottom: 10px;
  }

  .btns {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}

/* 过渡效果 */
.bottom-modal-enter-active,
.bottom-modal-leave-active {
  transition: transform 0.3s;
}

.bottom-modal-enter,
.bottom-modal-leave-to {
  transform: translateY(100%);
}
</style>
