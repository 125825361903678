import Vue from 'vue';
import { UnitUtil } from "@/util";


Vue.filter('timeFilter', function (value) {
  return UnitUtil.timeFilter(value);
});

Vue.filter('mileFilter', function (value = 0) {
  return `约${UnitUtil.mileFilter(value)}`;
});

Vue.filter('percentFilter', function (value = 0) {
  const persent = (Math.min(1, value) * 100).toFixed(2);
  return `${persent}%`;
});
