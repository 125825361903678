<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="整体" name="ref1">
        <DriverServiceScoreSummary />
      </el-tab-pane>
      <el-tab-pane label="明细" name="ref2">
        <DriverServiceScoreDetail />
      </el-tab-pane>
      <el-tab-pane label="服务分设置" name="ref3">
        <DriverServiceScoreSetting />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import DriverServiceScoreSetting from './Setting';
import DriverServiceScoreSummary from './Summary';
import DriverServiceScoreDetail from './Detail';

export default {
  components: {
    DriverServiceScoreSetting,
    DriverServiceScoreSummary,
    DriverServiceScoreDetail
  },
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>