<template>
  <div class="root-box">
    <el-row :gutter="20">
      <el-col :span="3">
        <el-select
          @change="query()"
          size="small"
          v-model="param.direct"
          clearable
          placeholder="呼叫方向"
        >
          <el-option
            v-for="item in directs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          @change="query()"
          size="small"
          clearable
          v-model="param.status"
          placeholder="应答状态"
        >
          <el-option
            v-for="item in dispositions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          @change="filter"
          size="small"
          clearable
          v-model="param.agent"
          placeholder="客服"
        >
          <el-option
            v-for="item in agents"
            :key="item.agentNo"
            :label="item.name"
            :value="item.agentNo"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="5">
        <el-date-picker
          @change="query()"
          v-model="param.date"
          size="small"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="3">
        <el-input
          @change="filter"
          clearable
          style="width: 200px"
          size="small"
          v-model="param.tel"
          placeholder="电话号码"
        >
        </el-input>
      </el-col>

      <el-col :span="2">
        <el-button
          type="primary"
          style="width: 80px; margin-left: 20px"
          size="small"
          @click="query()"
          >查询</el-button
        >
      </el-col>
    </el-row>

    <div class="table">
      <el-table :data="tableData" border stripe max-height="700">
        <el-table-column prop="FDATE" label="电话" style="width: 1650px">
          <template slot-scope="scope">
            <span
              v-click-tel="scope.row.caller"
              v-if="scope.row.direct === 'IN'"
              >{{ scope.row.caller }}</span
            >
            <span
              v-click-tel="scope.row.callee"
              v-else-if="scope.row.direct === 'OUT'"
              >{{ scope.row.callee }}</span
            >
            <span v-click-tel="scope.row.callee" v-else
              >{{ scope.row.callee }}{{ scope.row.caller }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="calldate" label="时间"> </el-table-column>
        <el-table-column prop="direct" label="呼叫方向">
          <template slot-scope="scope">
            <span v-if="scope.row.direct === 'IN'">呼入</span>
            <span v-else-if="scope.row.direct === 'OUT'">呼出</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="disposition" label="应答状态">
          <template slot-scope="scope">
            <span v-if="scope.row.disposition === 'ANSWERED'">有应答</span>
            <span v-else-if="scope.row.disposition === 'NO ANSWER'"
              >无人应答</span
            >
            <span v-else-if="scope.row.disposition === 'FAILED'">呼叫失败</span>
            <span v-else-if="scope.row.disposition === 'BUSY'">线路忙</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column label="客服">
          <template slot-scope="scope">
            <span>{{
              scope.row.agent | enumTransfer(agents, "agentNo", "name")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="通话时长">
          <template slot-scope="scope">
            <span>{{ scope.row.duration | transTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="通话录音" width="400">
          <template slot-scope="scope">
            <div v-if="scope.row.recordUrl" class="record">
              <audio
                class="record-audio"
                autoplay="autoplay"
                :src="scope.row.recordUrl"
                controls="controls"
              ></audio>
              <a target="_blank" class="download" :href="scope.row.recordUrl"
                >下载链接</a
              >
            </div>
            <a v-else @click="downRecord(scope.row, scope.$index)">下载</a>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <span class="total"> 共 {{ tableData.length }} 条记录</span>

        <el-pagination
          @current-change="pageChange"
          background
          :current-page.sync="currentPage"
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="10"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {DateUtil} from '@/util';
import _ from 'lodash';
import {getRoleAdminList} from '@/api';
import config from '@/config/config';

const today = new Date();
export default {
  name: "CallRecord",
  filters: {
    transTime: function (value) {
      let min = parseInt(value / 60) + '';
      if (min.length < 2) {
        min = '0' + min;
      }
      let sec = value % 60 + '';
      if (sec.length < 2) {
        sec = '0' + sec;
      }
      return min + ':' + sec;
    },

  },
  created () {
    this.getKefuList();
  },
  data () {
    return {
      directs: [{
        label: "呼入",
        value: 'IN'
      }, {
        label: "呼出",
        value: 'OUT'
      }, {
        label: "未知",
        value: ' '
      }],
      dispositions: [{
        label: "有应答",
        value: 'ANSWERED'
      }, {
        label: "无人应答",
        value: 'NO ANSWER'
      }, {
        label: "呼叫失败",
        value: 'FAILED'
      }, {
        label: "线路忙",
        value: 'BUSY'
      }],
      agents: [],
      param: {
        date: [today, today],
        direct: null,
        status: null,
        agent: null,
      },
      tableData: [],
      currentPage: 1
    };
  },
  methods: {
    query (page = 1) {
      if (page === 1) {
        this.currentPage = 1;
      }

      const p = this.param;
      const param = {
        offset: 150 * (page - 1),
        calldate1: DateUtil.format(p.date[0], 'yyyy-MM-dd 00:00:00'),
        calldate2: DateUtil.format(p.date[1], 'yyyy-MM-dd 23:59:59'),
      };
      if (p.direct) {
        param.direct = p.direct;
      }
      if (p.status) {
        param.disposition = p.status;
      }

      jMVBCTI.CallWebCTI('getcdr.php', param, (r1) =>{
        const data = _.get(r1, 'root.row') || [];
        this.oriData = data;
        this.filter();
      });

    },
    getKefuList () {
      const kefuRoleId = config.kefuRoleId;
      getRoleAdminList(kefuRoleId).then(res=>{
        const list = res.data || [];
        this.agents = list.filter(item=> !!item.agentNo);
      });
    },
    pageChange (page) {
      this.query(page);
    },
    filter () {
      const data = this.oriData || [];
      // 根据电话和客服过滤
      const tel = this.param.tel;
      const agent = this.param.agent;
      const result = [];
      data.every(item=>{
        // 根据字符串长度判断哪个是客服编号
        if (item.callee.length > 5) {
          item.agent = item.caller;
        } else {
          item.agent = item.callee;
        }

        if (tel) {
          if (item.callee.indexOf(tel) < 0 && item.caller.indexOf(tel) < 0) {
            return true;
          }
        }
        if (agent) {
          if (item.agent.indexOf(agent) < 0) {
            return true;
          }
        }
        result.push(item);
        return true;

      });
      this.tableData = result;
    },
    downRecord (item, index) {
      jMVBCTI.GetMonitorURL({uniqueid: item.uniqueid}, (resdata)=> {
        try {
          if (resdata.errcode === '0') {
            if (resdata.data.length > 0) {
              const videoFile = jMVBCTI.GetMVBServerURL() + resdata.data[0] + '&mp3=true';
              item.recordUrl = videoFile;
              this.$set(this.tableData, index, item);
              this.tableData = _.cloneDeep(this.tableData);
            } else {
              this.$message({
                message: '没有录音',
                type: 'warning'
              });
            }
          }
        } catch (e) {
          this.$message({
            message: '下载录音错误',
            type: 'error'
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.table {
  margin: 20px 0;
}
audio {
  width: 250px;
  height: 30px;
}

.download {
  color: #2aaaff;
}

.page {
  float: right;
  display: flex;
  margin-top: 10px;
  .total {
    position: relative;
    top: 4px;
    color: #222222;
  }
}

.record {
  display: flex;
  align-items: center;
  a {
    margin-left: 10px;
  }
}
</style>

