<template>
  <div class="root-box">
    <div>
      <div class="table">
        <el-table
          :data="list"
          stripe
          :row-class-name="tableRowClassName"
          :span-method="objectSpanMethod"
          max-height="650"
        >
          <el-table-column
            label="序号"
            type="index"
            show-overflow-tooltip
            width="50"
          ></el-table-column>

          <el-table-column prop="date" width="120" label="日期"></el-table-column>
          <el-table-column prop="showTime" width="120" label="时间"></el-table-column>
          <el-table-column prop="orderSource" width="100" label="渠道">
            <template slot-scope="scope">
              <div v-order-source="scope.row.orderSource"></div>
              <vip-label :vip="scope.row.vip" />
            </template>
          </el-table-column>

          <el-table-column prop="number" width="60" label="订票">
            <template slot-scope="scope">
              {{ scope.row | orderTypeTransfer }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="总价" width="100">
            <template slot-scope="scope">
              <span v-operate-order:bill="scope.row.id">{{ scope.row.price | moneyFormat }}元</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="vip"
                           width="60"
                           label="VIP">
            <template slot-scope="scope">
              <vip-label :vip="scope.row.vip" />
            </template>
          </el-table-column> -->

          <el-table-column prop="telephone" width="130" label="手机">
            <template slot-scope="scope">
              <tel-cell :order="scope.row" />
            </template>
          </el-table-column>

          <!-- <el-table-column prop="calledStatus"
                           width="70">
            <template slot-scope="scope">
              <call-status :type="scope.row.calledStatus"
                           :order-id="scope.row.id" />
            </template>
          </el-table-column> -->

          <el-table-column prop="departure" label="起点">
            <template slot-scope="scope">
              <span class="pointer" @click="showPassengetMap(scope.row, true)">{{
                scope.row.departure
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="destination" label="终点">
            <template slot-scope="scope">
              <span class="pointer" @click="showPassengetMap(scope.row, false)">{{
                scope.row.destination
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="psgMessage" label="乘客留言">
            <template slot-scope="scope">
              <limit-text :width="150" :text="scope.row.psgMessage"></limit-text>
            </template>
          </el-table-column>

          <el-table-column prop="remark" label="司机备注">
            <template slot-scope="scope">
              <limit-text :width="130" :text="scope.row.remark"></limit-text>
            </template>
          </el-table-column>

          <el-table-column width="130" prop="adminMessage" label="客服备注">
            <template slot-scope="scope">
              <limit-text :width="130" :text="scope.row.adminMessage"></limit-text>
            </template>
          </el-table-column>

          <el-table-column width="100" prop="createTime" label="下单时间"></el-table-column>

          <el-table-column prop="createTime" width="120" label="支付倒计时">
            <template slot-scope="scope">
              <count-down
                :key="scope.row.id"
                :startTime="scope.row.createTime"
                :countDownTime="600"
                overTimeText="已超时"
              />
            </template>
          </el-table-column>

          <el-table-column prop="id" label="操作" align="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                :disabled="scope.row.orderSource === 3 || scope.row.orderSource === 4"
                size="small"
                @click="openUpdateModal(scope.row)"
                >修改</el-button
              >

              <el-dropdown @command="(command) => handleCommand(command, scope.row)">
                <span class="el-dropdown-link">
                  其他
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">取消</el-dropdown-item>
                  <!-- <el-dropdown-item command="3">发送短信</el-dropdown-item> -->
                  <el-dropdown-item command="4">操作日志</el-dropdown-item>
                  <el-dropdown-item command="5">交易明细</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <order-cancel ref="cancelModal" :onCancel="handleCurrentChange" />
    <tel-order-modify-modal ref="modifyModal" />
  </div>
</template>

<script>
import { getOrderList, listSpecArea } from "@/api";
import TelOrderModifyModal from "@/components/TelOrder/TelOrderModifyModal";
import TelCell from "@/components/TelCell";
import { mapState } from "vuex";
import _ from "lodash";
import { SHOW_PASSENGER_MAP } from "@/core/const/global-event";

export default {
  components: {
    TelOrderModifyModal,
    TelCell,
  },
  filters: {},
  props: {
    dispatchOrder: Function,
    sendSms: Function,
    showTransactionDetail: Function,
  },
  computed: {
    ...mapState({
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      size: (state) => state.btnSize,
    }),
  },
  data() {
    return {
      ticketDiagVisible: false,
      list: [],
      pageSize: 100,
      currentPage: 1,
      total: 0,
      currentOrderId: null,
      ticketList: [],
      loading: false,
      startAreaSettings: [],
      endAreaSettings: [],
    };
  },
  methods: {
    query(page = 1, param = this.param || {}) {
      this.param = param;
      param.orderStatus = "NOT_CONFIRMED";
      const query = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param,
      };
      getOrderList(query).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        // 给每一行标色
        const map = {};
        records.forEach((item) => {
          const ticketId = item.ticketId;
          if (map[ticketId]) {
            map[ticketId].push(item);
          } else {
            map[ticketId] = [item];
          }
        });

        let index = 0;
        _.forEach(map, (values) => {
          // 区分背景色
          values.forEach((item) => {
            item.className = index % 2 === 0 ? "row1" : "row2";
          });
          values[0].rowspan = values.length;
          index++;
        });
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showPassengetMap(item, isStart) {
      const param = {
        type: 1,
        order: item,
        isStart,
      };
      this.$bus.$emit(SHOW_PASSENGER_MAP, param);
    },

    querySpecArea(pathId) {
      listSpecArea(pathId).then((res) => {
        const { startList = [], endList = [] } = res.data || {};
        this.startAreaSettings = startList;
        this.endAreaSettings = endList;
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    openCancelModal(item) {
      this.$refs.cancelModal.open(item.id);
    },

    openUpdateModal(item) {
      this.$refs.modifyModal.open(item);
    },

    openDriverModal(item) {
      this.dispatchOrder([item.id], item.pathId);
    },

    handleCommand(command, item) {
      command = parseInt(command);
      switch (command) {
        case 1:
          this.openCancelModal(item);
          break;
        case 2:
          this.openUpdateModal(item);
          break;
        case 3:
          this.sendSms(item.id);
          break;
        case 4:
          this.$orderLog().show(item.id);
          break;
        case 5:
          this.showTransactionDetail(item);
          break;
      }
    },

    objectSpanMethod({ row, column }) {
      const fieldArr = ["date", "showTime"];
      if (fieldArr.indexOf(column.property) > -1) {
        return {
          rowspan: row.rowspan || 0,
          colspan: 1,
        };
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    tableRowClassName({ row }) {
      return row.className;
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-link {
  margin-left: 10px;
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}
</style>
