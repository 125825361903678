<template>
  <div class="tel-order">
    <el-button size="small" @click="open()" type="success" class="btn"
      >电话下单</el-button
    >
    <tel-order-modal ref="modal" />
  </div>
</template>

<script>
import TelOrderModal from "./TelOrderModal";

export default {
  name: "TelOrder",
  components: {
    TelOrderModal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    tel: String
  },

  data () {
    return {
      show: false
    };
  },
  methods: {
    open () {
      let tel = this.tel;
      if (tel) {
        // 电话弹窗带入电话号码并去0
        if (tel.startsWith("0")) {
          tel = tel.substr(1);
        }
        this.$refs.modal.open(tel);
      } else {
        this.$refs.modal.open();
      }
    },
    close () {
      this.$refs.modal.close();
    }
  }
};
</script>

<style scoped lang="less">
</style>
