<template>
  <el-drawer
    :visible.sync="visible"
    direction="rtl"
    :with-header="false"
    custom-class="drawer-modal"
    title="订单交易信息"
  >
    <div class="box" v-if="bill">
      <el-button type="primary" size="small" class="refresh" @click="query">刷新</el-button>

      <el-collapse v-model="activeNames">
        <el-collapse-item class="col-item" title="订单信息" name="1">
          <div class="flex">
            <div>{{ order.telephone }}</div>
            <div>{{ order.pathId | pathNameTransfer }}</div>
            <div>{{ order.date }} &nbsp;{{ order.showTime }}</div>
            <div>{{ order.number }}{{ order.orderType === 2 ? "货" : "人" }}</div>
            <div>{{ order.price | moneyFormat }}元</div>
            <div>{{ order.orderStatus | enumTransfer(orderStatusOpts) }}</div>
          </div>
        </el-collapse-item>
        <el-collapse-item class="col-item" title="价格信息" name="2">
          <el-form :model="bill" :inline="false" size="small">
            <template v-if="bill.orderType === 1">
              <el-form-item v-if="bill.adultNumber" label="购票："
                >{{ bill.adultNumber }}成人

                <span v-if="bill.childNumber">+ {{ bill.childNumber }}儿童</span>
              </el-form-item>

              <el-form-item label="基础价格：" prop="cash"
                >{{ bill.basePrice | moneyFormat }}元</el-form-item
              >

              <el-form-item
                v-if="bill.childrenPrice && bill.childNumber"
                label="儿童价格："
                prop="cash"
                >{{ bill.childrenPrice | moneyFormat }}元</el-form-item
              >
            </template>

            <template v-else>
              <template v-if="bill.orderType === 2">
                <el-form-item>带货</el-form-item>
              </template>
              <template v-else>
                <el-form-item> {{ bill.number }}人包车</el-form-item>
              </template>
            </template>

            <el-form-item v-if="bill.startAreaUpPrice" label="起点加价：" prop="cash"
              >{{ bill.startAreaUpPrice | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.endAreaUpPrice" label="终点加价：" prop="cash"
              >{{ bill.endAreaUpPrice | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.otherUpPrice" label="其他加价：" prop="cash"
              >{{ bill.otherUpPrice | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.discountWordAmount" label="优惠口令：" prop="cash"
              >{{ bill.discountWordAmount | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.couponAmount" label="优惠券：" prop="cash"
              >{{ bill.couponAmount | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.aheadAmount" label="早价优惠：" prop="cash"
              >{{ bill.aheadAmount | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.otherDiscount" label="其他折扣：" prop="cash"
              >{{ bill.otherDiscount | moneyFormat }}元</el-form-item
            >
            <el-form-item v-if="bill.price" label="实付金额：" prop="price"
              >{{ bill.price | moneyFormat }}元</el-form-item
            >
          </el-form>
        </el-collapse-item>
        <el-collapse-item class="col-item" title="交易明细" name="3">
          <div>
            <el-table :data="detailList" stripe @row-dblclick="queryDetail" size="small">
              <!-- <el-table-column prop="transactionId" label="微信交易id">
                <template slot-scope="scope">{{
                  scope.row.transactionId || scope.row.refundId
                }}</template>
              </el-table-column>

              <el-table-column prop="tradeNo" label="TradeNo"></el-table-column> -->

              <el-table-column prop="transactionType" label="交易类型">
                <template slot-scope="scope">{{
                  scope.row.transactionType | enumTransfer(transactionTypeOpts)
                }}</template>
              </el-table-column>

              <el-table-column prop="remark" label="交易说明"> </el-table-column>

              <el-table-column prop="amount" label="交易金额">
                <template slot-scope="scope">
                  <span v-operate-order:billDetail="scope.row.transactionBillId"
                    >{{ scope.row.isRefund ? "-" : "" }}
                    {{ scope.row.amount | moneyFormat }}元</span
                  >
                </template>
              </el-table-column>

              <el-table-column prop="confirmAmount" label="确认金额">
                <template slot-scope="scope">{{ scope.row.confirmAmount | moneyFormat }}</template>
              </el-table-column>

              <el-table-column prop="isDeal" label="是否确认">
                <template slot-scope="scope">{{ scope.row.confirmed ? "是" : "否" }}</template>
              </el-table-column>

              <!-- <el-table-column prop="payType" label="支付类型">
                <template slot-scope="scope">{{
                  scope.row.payType | enumTransfer(payTypeOpts)
                }}</template></el-table-column
              > -->

              <el-table-column prop="payState" label="支付\退款状态">
                <template slot-scope="scope">{{
                  scope.row.payState || scope.row.refundStatus
                }}</template>
              </el-table-column>

              <!-- <el-table-column prop="payState" label="完成时间">
                <template slot-scope="scope">{{
                  scope.row.paySuccessTime || scope.row.refundSuccessTime
                }}</template></el-table-column
              > -->

              <el-table-column prop="refundRecvAccount" label="退款入账账户"></el-table-column>

              <el-table-column prop="errMsg" label="错误信息"></el-table-column>

              <el-table-column prop="createTime" label="创建时间"></el-table-column>

              <el-table-column prop="id" fixed="right" width="100" label="操作" align="right">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.refundRelTransactionId && scope.row.refundStatus !== 'SUCCESS'"
                    type="text"
                    @click="retryRefundCall(scope.row)"
                    >重试退款</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div class="refund-btn">
              <div class="text">剩余最大可退金额： {{ availableAmount | moneyFormat }} 元</div>
              <el-button
                type="warning"
                :disabled="!canRefund"
                @click="showManualRefund"
                size="small"
                >退款</el-button
              >
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item class="col-item" title="操作日志" name="4">
          <div class="log-box">
            <el-timeline>
              <el-timeline-item
                v-for="item in logList"
                :key="item.id"
                :type="item.opLevel"
                :timestamp="item.createTime"
                >{{ item.userType | enumTransfer(logUserTypeOpts) }} {{ item.userName }}
                {{ item.operate }}</el-timeline-item
              >
            </el-timeline>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <el-dialog :visible="refundVisible" title="人工退款" width="500px" :append-to-body="true">
      <div>
        <el-form ref="refundForm" :model="form" label-width="140px" size="small" :rules="rule">
          <el-form-item label="退款原因：" prop="remark">
            <el-input
              type="textarea"
              :rows="8"
              maxlength="10"
              v-model="form.remark"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="退款金额（元）" prop="amount">
            <el-input-number
              v-model="form.amount"
              style="width: 300px"
              placeholder="输入退款金额"
              :max="availableAmount / 100"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="hideManualRefund">取 消</el-button>
        <el-button type="primary" @click="handleRefund" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { orderTransactionDetail, getTransactionDetail, manualRefund, retryRefund } from "@/api";
import store from "@/store";
export default {
  components: {},
  props: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {
    canRefund() {
      return this.availableAmount > 0;
    },
  },
  data() {
    return {
      activeNames: ["1", "2", "3"],
      visible: false,
      refundVisible: false,
      order: null,
      bill: null,
      logList: [],
      detailList: [],
      availableList: [],
      logUserTypeOpts: [],
      payTypeOpts: [],
      transactionTypeOpts: [],
      orderStatusOpts: [],
      availableAmount: 0,
      form: {},
      orderId: 0,
      rule: {
        remark: [
          {
            required: true,
            message: "请输入退款原因",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入退款金额",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    show(orderId) {
      this.visible = true;
      this.activeNames = ["1", "2", "3"];
      this.logUserTypeOpts = store.state.enumMap["LOG_USER_TYPE"];
      this.payTypeOpts = store.state.enumMap["PAY_TYPE"];
      this.transactionTypeOpts = store.state.enumMap["TRANSACTION_TYPE"];
      this.orderStatusOpts = store.state.enumMap["ORDER_STATUS"];
      this.orderId = orderId;
      this.query();
    },

    query() {
      orderTransactionDetail(this.orderId).then((res) => {
        const { logList, detailList, availableList, bill, order } = res.data || {};
        this.bill = bill;
        this.order = order;
        this.logList = logList;
        this.detailList = detailList;
        const availableAmount = availableList.reduce((pre, current) => {
          return pre + current.availAmount;
        }, 0);
        this.availableAmount = availableAmount;
        this.availableList = availableList;
      });
    },

    queryDetail(item) {
      getTransactionDetail(item).then((res) => {
        const { errorCode, errCodeDes, resultCode, tradeStateDesc, tradeState } = res.data || {};

        if (resultCode === "SUCCESS") {
          if (item.refundRelTransactionId) {
            this.$notify.success({
              message: "系统退款成功",
              title: "实时查询结果",
            });
          } else {
            if (tradeState === "SUCCESS") {
              this.$notify.success({
                message: tradeStateDesc,
                title: "实时查询结果",
              });
            } else {
              this.$notify.error({
                message: tradeStateDesc,
                title: "实时查询结果",
              });
            }
          }
        } else {
          this.$notify({
            title: "实时查询结果",
            dangerouslyUseHTMLString: true,
            message: `<div><div>错误码：${errorCode}</div><div>错误详情：${errCodeDes}</div></div>`,
            duration: 5000,
          });
        }
      });
    },

    showManualRefund() {
      this.refundVisible = true;
      this.$set(this.form, "amount", this.availableAmount / 100);
    },

    hideManualRefund() {
      this.refundVisible = false;
      this.form = {};
    },

    handleRefund() {
      this.$refs.refundForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = { ...this.form, orderId: this.order.id, amount: this.form.amount * 100 };
        manualRefund(param).then((res) => {
          this.hideManualRefund();
          this.query();
          const { success, errMsg } = res.data || {};
          if (success) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(errMsg);
          }
        });
      });
    },

    retryRefundCall(item) {
      this.$confirm(`确认重试退款吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        retryRefund(item).then((res) => {
          const { status, userReceivedAccount, successTime } = res.data || {};
          if (status === "SUCCESS") {
            if (successTime) {
              this.$notify.success({
                message: `退款已入账：${userReceivedAccount} ${successTime}`,
                title: "实时操作结果",
              });
            } else {
              this.$notify.success({
                message: "退款状态：" + status,
                title: "实时操作结果",
              });
            }
          } else {
            this.$notify.error({
              message: status,
              title: "实时操作结果",
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .drawer-modal {
  width: 1200px !important;
}

.box {
  margin: 20px;
  overflow: auto;
  height: 95vh;
  position: relative;

  .refresh {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 2;
  }

  .col-item {
    /deep/ .el-collapse-item__header {
      color: #4894ef;
      font-size: 16px;
      font-weight: bold;
    }

    /deep/ .el-form-item {
      margin-bottom: 0;
    }

    .log-box {
      padding: 10px 0;
    }

    .refund-btn {
      display: flex;
      margin: 10px 0;
      justify-content: space-between;
      align-items: center;

      .text {
        color: red;
        font-size: 14px;
      }
    }

    .flex {
      display: flex;

      > div {
        margin: 0 10px;
      }
    }
  }
}
</style>
