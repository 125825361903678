<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="消息类型：" prop="path">
          <el-select
            v-model="param.type"
            placeholder="请选择"
            @change="query"
            clearable
          >
            <el-option
              v-for="item in pushMessageOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >新增推送消息</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="type" label="消息类型">
          <template slot-scope="scope">
            {{ scope.row.type | enumTransfer(pushMessageOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="title" label="主标题"></el-table-column>

        <el-table-column prop="subTitle" label="副标题"></el-table-column>

        <el-table-column
          prop="importantContent"
          label="重点内容"
        ></el-table-column>
        <el-table-column
          prop="detailContent"
          label="详细内容"
        ></el-table-column>

        <el-table-column prop="createTime" label="创建日期"></el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="pushMessage(scope.row)"
              >推送</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="优惠口令"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="消息类型：" prop="path">
          <el-select v-model="form.type" style="width: 100%">
            <el-option
              v-for="item in pushMessageOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="主标题：" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="副标题：" prop="subTitle">
          <el-input v-model="form.subTitle"></el-input>
        </el-form-item>

        <el-form-item label="重点内容：" prop="importantContent">
          <el-input
            type="textarea"
            v-model="form.importantContent"
            placeholder=""
          ></el-input>
        </el-form-item>

        <el-form-item label="详细内容：" prop="detailContent">
          <el-input type="textarea" v-model="form.detailContent"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveMessage"
          >保存并推送</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPushMessageList, sendMessage } from "@/api";
import { mapState } from "vuex";
import config from "@/config/config";

export default {
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pushMessageOpts: (state) => state.enumMap["PUSH_MESSAGE_TYPE"],
    }),
  },
  data () {
    return {
      visible: false,
      param: {
        type: 1,
      },
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      rules: {
        title: [{ required: true, message: "主标题不能为空", trigger: "blur" }],
        detailContent: [
          { required: true, message: "详细内容不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          pageNo: page,
          pageSize: config.pageSize,
        },
        param: this.param,
      };
      getPushMessageList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange (page) {
      this.query(page);
    },

    openForm () {
      this.visible = true;
      this.form = { type: 1 };
    },
    saveMessage () {
      sendMessage(this.form).then(() => {
        this.$message.success("操作成功");
        this.visible = false;
        this.query();
      });
    },
    pushMessage (item) {
      sendMessage(item).then(() => {
        this.$message.success("操作成功");
        this.visible = false;
        this.query();
      });
    },
  },
};
</script>

<style scoped>
</style>
