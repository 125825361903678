<template>
<div class="root">
  <div class="query-container">
    <el-radio-group v-model="auditType" style="margin-right: 10px;">
      <el-radio-button label="vehicle">车辆</el-radio-button>
      <el-radio-button label="driver">司机</el-radio-button>
    </el-radio-group>

    <el-date-picker
            v-model="startDate"
            type="date"
            format="yyyy-MM-dd"
            placeholder="选择起始日期">
    </el-date-picker>
    至
    <el-date-picker
            v-model="endDate"
            type="date"
            format="yyyy-MM-dd"
            placeholder="选择截至日期">
    </el-date-picker>

    <el-button @click="query">查询</el-button>
  </div>

  <div v-if="auditType == 'vehicle'">
    <el-table :data="vehicleList" style="width: 100%">
      <el-table-column
              prop="vehicleNo"
              label="车牌号">
      </el-table-column>
      <el-table-column
              prop="brand"
              label="车辆型号">
      </el-table-column>
      <el-table-column
              prop="driverNo"
              label="司机编号">
      </el-table-column>
      <el-table-column
              prop="billDate"
              label="时间">
      </el-table-column>
      <el-table-column
              prop="createType"
              label="维修来源">
      </el-table-column>
      <el-table-column
              prop="cbyName"
              label="发起人">
      </el-table-column>
      <el-table-column
              prop="providerName"
              label="服务商">
      </el-table-column>
      <el-table-column
              prop="staffName"
              label="检查人">
      </el-table-column>
      <el-table-column
              prop="amount"
              label="预计费用">
      </el-table-column>
      <el-table-column
              prop="address"
              label="明细">
      </el-table-column>
      <el-table-column
              prop="status"
              label="状态">
      </el-table-column>
      <el-table-column
              prop="miles"
              label="里程数">
      </el-table-column>
      <el-table-column
              prop="address"
              label="审核">
      </el-table-column>
      <el-table-column
              label="查看">
      </el-table-column>
    </el-table>
  </div>

  <div v-if="auditType == 'driver'">
    <el-table
            :data="driverList"
            style="width: 100%">
      <el-table-column
              prop="driverNo"
              label="司机">
      </el-table-column>
      <el-table-column
              prop="ctime"
              label="申请时间">
      </el-table-column>
      <el-table-column
              prop="leaveType"
              label="收车类型">
      </el-table-column>
      <el-table-column
              prop="leaveCause"
              label="原因">
      </el-table-column>
      <el-table-column
              prop="days"
              label="收车时长">
      </el-table-column>
      <el-table-column
              prop="monthTimes"
              label="当月临时收车次数">
      </el-table-column>
      <el-table-column
              prop="yearTimes"
              label="本年临时收车次数">
      </el-table-column>
      <el-table-column
              prop="times"
              label="累计临时收车次数">
      </el-table-column>
      <el-table-column
              prop="imgs"
              label="附件">
      </el-table-column>
      <el-table-column
              prop="agree"
              label="审核状态">
      </el-table-column>
      <el-table-column
              prop="adminTime"
              label="审核时间">
      </el-table-column>
      <el-table-column
              prop="adminBz"
              label="审核原因">
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
export default {
    name: "auditManage",
    props: {
        driverAdmin: {
            type: Object,
        },
    },
    data() {
        return {
            auditType: "vehicle",
            startDate: "",
            endDate: "",
            driverList: [],
            vehicleList: []
        };
    },
    methods: {
        query() {},

    }
};
</script>

<style scoped>

</style>