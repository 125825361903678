<template>
  <div class="root-box">
    <div>
      <el-button @click="add" size="small" style="width: 80px" type="primary"
        >新增</el-button
      >
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="role" label="角色名称"></el-table-column>
        <el-table-column prop="description" label="角色描述"></el-table-column>

        <el-table-column prop="indexPage" label="首页路由"></el-table-column>

        <el-table-column
          prop="specPermissions"
          label="额外权限key值"
        ></el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">{{
            scope.row.status ? "启用" : "禁用"
          }}</template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <span>
              <el-button type="text" size="small" @click="edit(scope.row)"
                >修改角色</el-button
              >
              <el-button
                v-if="scope.row.status"
                type="text"
                size="small"
                @click="enableChange(scope.row, 0)"
                >禁用</el-button
              >
              <el-button
                v-else
                type="text"
                size="small"
                @click="enableChange(scope.row, 1)"
                >启用</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible="dialogVisible"
      :before-close="close"
      width="600px"
      max-height="600"
      :title="mode === MODE.ADD ? '新增角色' : '修改角色'"
      @opened="renderTree"
      :close-on-click-modal="false"
    >
      <el-form
        ref="roleForm"
        :model="form"
        label-width="130px"
        size="small"
        :rules="rules"
        class="role-form"
        label-position="right"
      >
        <el-form-item label="角色名称：" prop="role">
          <el-input v-model="form.role"></el-input>
        </el-form-item>

        <el-form-item label="角色描述：" prop="description">
          <el-input v-model="form.description"></el-input>
        </el-form-item>

        <el-form-item label="首页路由：" prop="indexPage">
          <el-input v-model="form.indexPage"></el-input>
        </el-form-item>

        <el-form-item label="额外权限key值：" prop="specPermissions">
          <el-input v-model="form.specPermissions"></el-input>
        </el-form-item>
      </el-form>

      <div class="tree">
        <el-tree
          ref="tree"
          :highlight-current="true"
          :check-on-click-node="false"
          :props="props"
          node-key="id"
          :check-strictly="true"
          :auto-expand-parent="true"
          :data="treeList"
          show-checkbox
        ></el-tree>
      </div>

      <span slot="footer">
        <el-button type="warning" size="small" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" @click="close" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getPermissionTree} from '@/api';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.getTree();
    this.queryList();
  },
  data () {
    return {
      MODE,
      dialogVisible: false,
      treeList: [],
      list: [],
      rules: {
        role: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
        ],
      },
      props: {
        label: 'text',
        children: 'children'
      },
      form: {},
      mode: MODE.ADD,
      selectKeys: []
    };
  },
  methods: {
    add () {
      this.form = {};
      this.mode = MODE.ADD;
      this.dialogVisible = true;
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    close () {
      this.dialogVisible = false;
    },
    renderTree () {
      this.$refs.tree.setCheckedKeys(this.selectKeys);
    },
    getTree () {
      getPermissionTree().then((res) => {
        this.treeList = res.data || [];
      });
    },
    queryList () {
      const url = '/admin/permission/role/list';
      const param = {
        page: {
          size: 100
        },
        param: {}
      };
      this.$http.post(url, param).then((res) => {
        this.list = res.data.records || [];
      });
    },
    saveOrUpdate () {
      const mode = this.mode;
      let url = '/admin/permission/role/modify';
      if (mode === MODE.ADD) {
        url = '/admin/permission/role/add';
      }
      this.$refs.roleForm.validate((valid) => {
        if (!valid) {
          return;
        }

        const pers = this.$refs.tree.getCheckedKeys();

        if (pers.length < 1) {
          this.$message.error('请至少勾选一个权限菜单');
          return;
        }
        const param = {
          ...this.form,
          pers,
        };
        this.$http.post(url, param).then(() => {
          this.close();
          this.queryList();
        });
      });
    },
    edit (item) {
      this.mode = MODE.EDIT;
      const url = '/admin/permission/role/info';
      const param = {
        id: item.id,
      };
      this.$http.post(url, param).then((res) => {
        const {data = {}} = res || {};
        this.form = data;
        this.dialogVisible = true;
        this.selectKeys = data.permissions.map(item=> item.id);
      });
    },

    enableChange (item, status) {
      const url = '/admin/permission/role/modify';
      item.status = status;
      this.$http.post(url, item).then(() => {
        this.$message.success('操作成功');
        this.queryList();
      });
    },
  }
};
</script>

<style scoped lang="less">
.table {
  margin-top: 10px;
}

.tree {
  max-height: 300px;
  overflow: auto;
  border: 1px solid #dddd;
}

.role-form {
  .el-input {
    width: 100%;
  }
}
</style>
