import BillDetail from '@/components/BillDetail';

export default {

  install (Vue) {
    const BillDetailClass = Vue.extend(BillDetail);

    let isMounted = false;
    let instance;

    function $billDetal () {
      if (!isMounted) {
        instance = new BillDetailClass();
        // 手动创建一个未挂载的实例
        instance.$mount();
        // 挂载
        document.querySelector('body').appendChild(instance.$el);
        isMounted = true;
      }

      return instance;
    }

    Vue.$billDetal = Vue.prototype.$billDetal = $billDetal;
  }
};
