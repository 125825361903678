<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="客户端类型：" prop="clientType">
            <el-select
              v-model="param.clientType"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in clientTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button
        style="margin: 0 20px 20px 0"
        type="primary"
        size="small"
        @click="addRuleDesc"
        >新增规则说明</el-button
      >
    </div>

    <el-table :data="list" stripe @row-dblclick="selectData" max-height="280">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="type" label="规则类型">
        <template slot-scope="scope">
          <div>{{ scope.row.type | enumTransfer(ruleDescTypeOpts) }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="type" label="客户端类型">
        <template slot-scope="scope">
          <div>{{ scope.row.clientType | enumTransfer(clientTypeOpts) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="version" label="版本"> </el-table-column>
      <el-table-column prop="state" label="状态"> </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            :disabled="form.id !== scope.row.id"
            size="small"
            @click="saveOrUpdate()"
            >保存</el-button
          >
          <el-button
            type="text"
            size="small"
            :disabled="form.id !== scope.row.id"
            @click="update(scope.row)"
            >修改</el-button
          >

          <el-button
            type="text"
            size="small"
            :disabled="form.id !== scope.row.id"
            @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="editor">
      <vue-tinymce
        v-model="form.content"
        :setup="setup"
        :setting="setting"
        :disabled="editDisabled"
        ref="editor"
      />
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :title="title"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleDescform"
        :model="form"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="客户端类型：" prop="clientType">
          <el-select
            v-model="form.clientType"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in clientTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="规则类型：" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in editRuleDescTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标题：" prop="title">
          <el-input
            style="width: 100%"
            v-model="form.title"
            placeholder
          ></el-input>
        </el-form-item>

        <el-form-item label="版本：" prop="version">
          <el-input
            style="width: 100%"
            v-model="form.version"
            placeholder
          ></el-input>
        </el-form-item>

        <el-form-item label="是否发布：" prop="state">
          <el-select
            v-model="form.state"
            placeholder="请选择"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';


import {delRuleDescList, saveOrUpdateRuleDescList, getRuleDescList} from '@/api';
import { Toolkit } from '@/util';
export default {
  components: {},
  props: {},
  created () {
    this.query();

  },
  mounted () {
  },
  watch: {
    'form.clientType': {
      handler (val) {
        // 如果是司机端，则编辑框背景色需要设置为黑丝
        if (val === 3) {
          document.getElementsByTagName('iframe')[0].style.backgroundColor = '#1D1F20';

        } else {
          document.getElementsByTagName('iframe')[0].style.backgroundColor = '';
        }
      }
    }
  },
  computed: {
    title () {
      return !this.form.id ? '新增规则描述' : '修改规则描述';
    },
    ...mapState({
      size: state => state.btnSize,
      ruleDescTypeOpts: state => state.enumMap['RULE_DESC_TYPE'],
    }),

    editRuleDescTypeOpts () {
      if (!this.ruleDescTypeOpts) {
        return [];
      }
      if (this.form.clientType === 3) {
        return this.ruleDescTypeOpts.filter(item=> item.value > 1000);
      }
      return this.ruleDescTypeOpts.filter(item=> item.value < 1000);
    }
  },
  data () {
    return {
      rules: {
        title: [
          {required: true, message: '标题不能为空', trigger: 'blur'},
        ],
        version: [
          {required: true, message: '版本不能为空', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '类型不能为空', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '状态不能为空', trigger: 'blur'},
        ],
      },
      list: [],
      form: {},
      param: {},
      visible: false,
      yesNoOpts: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 0
      }],

      clientTypeOpts: [{
        label: '乘客端APP',
        value: 1
      }, {
        label: '乘客端小程序',
        value: 2,
      }, {
        label: '司机APP',
        value: 3,
      }],

      setting: {
        height: 380,
        menubar: false,
        toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen quickbars",
        language: 'zh_CN',
        fontsize_formats: "12px 14px 16px 18px 24px 36px",
      },
      editDisabled: false
    };
  },
  methods: {
    setup (editor) {
      this.editor = editor;
    },

    query () {
      getRuleDescList(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
        this.editDisabled = true;
      });
    },

    addRuleDesc () {
      this.form = {};
      this.visible = true;
    },

    update (item) {
      this.form = _.cloneDeep(item);
      this.visible = true;
    },

    del (item) {
      this.$confirm(`确认删除吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRuleDescList(item).then(()=>{
          this.$message.success("操作成功");
          this.query();
        });
      });
    },



    selectData (data) {
      this.form = _.cloneDeep(data);
      if (data.content) {
        this.$set(this.form, 'content', data.content);
      } else {
        this.$set(this.form, 'content', '');

      }
      this.editDisabled = false;
    },

    saveOrUpdate () {
      // 新增或修改规则
      if (this.$refs.ruleDescform) {
        this.$refs.ruleDescform.validate((valid) => {
          if (!valid) {
            return;
          }
          saveOrUpdateRuleDescList(this.form).then(() => {
            this.$message.success("操作成功");
            this.visible = false;
            this.query();
          });
        });
      } else {
        // 保存规则内容
        saveOrUpdateRuleDescList(this.form).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.editor {
  margin: 10px;
  width: 90%;
  margin-top: 30px;
}
</style>