import JSEncrypt from 'jsencrypt';
import config from '@/config/config';
import { Message } from 'element-ui';
import _ from 'lodash';

export default class Toolkit {
  static transRed (data, keywords) {
    const reg = new RegExp(keywords, 'g');
    if (data) {
      const msg = data.replace(reg, `<strong style="color:red">${keywords}</strong>`);
      return msg;
    } else {
      return null;
    }
  }

  static encrypt (data) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(config.publicKey);
    return encrypt.encrypt(data);
  }

  static decrypt (data, privateKey) {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);
    return decrypt.decrypt(data);
  }

  static success (promise, msg) {
    promise.then(() => {
      Message.success(msg || '操作成功');
    });
  }

  static filterEmpty (data) {
    const result = _.pickBy(data, (item) => (!!item || item === 0 || item === false));
    return result;
  }



  static hash (input) {
    const I64BIT_TABLE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-'.split('');

    let hash = 5381;
    let i = input.length - 1;

    if (typeof input == 'string') {
      for (; i > -1; i--)
        hash += (hash << 5) + input.charCodeAt(i);
    }
    else {
      for (; i > -1; i--)
        hash += (hash << 5) + input[i];
    }
    let value = hash & 0x7FFFFFFF;

    let retValue = '';
    do {
      retValue += I64BIT_TABLE[value & 0x3F];
    }
    // eslint-disable-next-line no-cond-assign
    while (value >>= 6);

    return retValue;
  }


  static toHump (name) {
    return name.replace(/_(\w)/g, function (all, letter) {
      return letter.toUpperCase();
    });
  }
  // 驼峰转换下划线
  static toLine (name) {
    return name.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  static getLimitStr (val = '', maxLength = 16) {
    let addressInfo = val;
    if (addressInfo && addressInfo.length > maxLength) {
      addressInfo = '...' + val.substr(val.length - maxLength);
    }
    return addressInfo;
  }


    static uuid = () => {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i ++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';

      var uuid = s.join('');
      return uuid;
    }


    //h5复制文本到剪切板
    static copyContentH5 = (content) => {
      var copyDom = document.createElement('div');
      copyDom.innerText = content;
      copyDom.style.position = 'absolute';
      copyDom.style.top = '0px';
      copyDom.style.right = '-9999px';
      document.body.appendChild(copyDom);
      //创建选中范围
      var range = document.createRange();
      range.selectNode(copyDom);
      //移除剪切板中内容
      window.getSelection().removeAllRanges();
      //添加新的内容到剪切板
      window.getSelection().addRange(range);
      //复制
      var successful = document.execCommand('copy');
      copyDom.parentNode.removeChild(copyDom);
      try {
        var msg = successful ? "successful" : "failed";
        console.log('Copy command was : ' + msg);
      } catch (err) {
        console.log('Oops , unable to copy!');
      }
    }


    static getFileType = (fileName) => {
      let fileType = '';
      if (fileName) {
        fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
      }
      return fileType;
    }


    static findLeafNodes(treeData = [], childrenKey = 'children') {
      let leafNodes = [];
      function traverse(node) {
          if (node[childrenKey] && node[childrenKey].length > 0) {
              node[childrenKey].forEach(child => traverse(child));
          } else {
              leafNodes.push(node);
          }
      }
      treeData.forEach(rootNode => traverse(rootNode));
      return leafNodes;
  }
}
