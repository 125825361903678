<template>
  <div class="">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="统计时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="司机编号：" prop="driverNo">
            <el-input v-model="param.driverNo"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div>
      <el-tabs v-model="tab">
        <el-tab-pane label="数据概况" name="ref1">
          <SafetyDetail :value="detailData" />
        </el-tab-pane>
        <el-tab-pane label="变化趋势" name="ref2"> </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {getSafetyScoreDetail} from '@/api';
import {DateUtil} from '@/util';
import SafetyDetail from './detail.vue';


export default {
  components: {
    SafetyDetail
  },
  props: {},
  created () {},
  mounted () {
  },
  watch: {},

  data () {
    return {
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
        driverNo: '6011'
      },
      tab: 'ref1',
      detailData: null,
      items: [
        {name: '超速', field: 'overSpeed'},
        {name: '超载', field: 'overLoad'},
        {name: '玩手机', field: 'playPhone'},
        {name: '不带耳机通话', field: 'noHeadphones'},
        {name: '疲劳驾驶', field: 'tiredness'},
        {name: '其他', field: 'others'}
      ]
    };
  },
  methods: {
    query () {
      if (!this.param.driverNo) {
        this.$message.error('请输入司机编号再查询');
        return;
      }
      getSafetyScoreDetail(this.param).then(res=>{
        this.detailData = res.data || {};
      });
    },

  },
};
</script>
<style lang="less" scoped>
.info1 {
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.info2 {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.info3 {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  .box {
    width: 200px;
    height: 100px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #333;

    &.active {
      background-color: #4894efc7;
      color: white;
    }

    .b1 {
      font-size: 18px;
    }

    .b2 {
      font-weight: bold;
      font-size: 18px;
    }
    .b3 {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      width: 100%;
    }
  }
}

.info4 {
  display: flex;
  flex-wrap: wrap;

  .box {
    box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
    border-radius: 10px;
    border: 1px solid #eee;
    margin: 0 0 20px;
    padding: 10px;
    margin: 40px;
    flex: 1;
    .title {
      text-align: center;
      border-bottom: 1px solid #eee;
      padding: 10px;
    }
  }
}
</style>