import Login from "@/Login";
import Index from "@/Index";
import DriverReview from "@/pages/Driver/DriverReview";
import VehicleType from "@/pages/Setting/VehicleType";
import Fee from "@/pages/Setting/Fee";
import RouteList from "@/pages/Order/RouteList";
import OrderList from "@/pages/Order/OrderList";
import OrderDetail from "@/pages/Order/Detail/OrderDetail";
import RouteReplay from "@/pages/Order/Detail/RouteReplay";
import RouteQc from "@/pages/Order/Detail/RouteQc";
import DriverList from "@/pages/Driver/DriverList";
import Upload from "@/pages/Report/Upload";
import ReportLog from "@/pages/Report/Log";
import ReportData from "@/pages/Report/Data";
import Complaint from "@/pages/Order/Complaint";
import Alarm from "@/pages/Order/Alarm";
import PassengerList from "@/pages/Passenger/Info";
import DriverMonitor from "@/pages/Monitor/Driver";
import LoginLog from "@/pages/Log/Login";
import OperateLog from "@/pages/Log/Operate";
import ClientErrorLog from "@/pages/Log/ClientError";
import IndexCount from "@/pages/Index/Count";
import Assessment from "@/pages/Driver/Assessment";
import PushMessage from "@/pages/Driver/PushMessage";
import User from "@/pages/Permission/User";
import Role from "@/pages/Permission/Role";
import SystemSetting from "@/pages/Setting/System";
import Permission from "@/pages/Permission/Perm";
import Area from "@/pages/Setting/Area";
import RoutePath from "@/pages/Setting/RoutePath";
import TicketTemplate from "@/pages/Setting/TicketTemplate";
import UpPrice from "@/pages/Setting/UpPrice";
import Word from "@/pages/Marketing/Word";
import Coupon from "@/pages/Marketing/Coupon";
import Advertisement from "@/pages/Marketing/Advertisement";
import Sms from "@/pages/Marketing/Sms";
import AutoDispatch from "@/pages/Beta/AutoDispatch";
import DriverDetail from "@/pages/Driver/Detail";
import TicketList from "@/pages/WorkCommon/TicketList";
import Scheduling from "@/pages/WorkCommon/Scheduling";
import CallRecored from "@/pages/WorkCommon/CallRecored";
import RecommedAddress from "@/pages/Setting/RecommendAddress";
import Dispatch from "@/pages/WorkCommon/Dispatch";
import Prinum from "@/pages/Setting/Prinum";
import Receipt from "@/pages/WorkCommon/Receipt";
import StatsMoney from "@/pages/Data/StatsMoney";
import DriverTeam from "@/pages/Setting/DriverTeam";
import WxSetting from "@/pages/Setting/WxSetting";
import AppUpgrade from "@/pages/Setting/AppUpgrade";
import UserAnalysis from "@/pages/Data/UserAnalysis";
import OrderAnalysis from "@/pages/Data/OrderAnalysis";
import TransactionList from "@/pages/Financial/TransactionList";
import FailOrderList from "@/pages/Financial/FailOrderList";
import CommanAddress from "@/pages/Data/CommanAddress";
import AutoAreaControlSetting from "@/pages/Beta/AutoAreaControl/Setting";
import AutoAreaControlDemo from "@/pages/Beta/AutoAreaControl/Demo";
import TrackAnalysis from "@/pages/Data/TrackAnalysis";
import CustomKnowledge from "@/pages/WorkCommon/CustomKnowledge";
import JspritTest from "@/pages/Beta/AutoDispatch/JspritTest";
import RuleManagement from "@/pages/Setting/Rule";
import DiscountAnalysis from "@/pages/Data/DiscountAnalysis";
import InvirteAnalysis from "@/pages/Data/inviteAnalysis";
import DriverServiceScore from "@/pages/DriverScore/ServiceScore";
import DriverSafetyScore from "@/pages/DriverScore/SafetyScore";
import DriverAppeal from "@/pages/DriverScore/DriverAppeal";
import DriverFeedback from "@/pages/DriverScore/DriverFeedback";
import PassengerFeedback from "@/pages/Passenger/PassengerFeedback";
import DriverReward from "@/pages/DriverScore/Reward";
import VideoMonitor from "@/pages/Monitor/VideoMonitor";
import VideoQuery from "@/pages/Monitor/VideoQuery";
import VehicleList from "@/pages/Vehicle/VehicleList";
import QcSetting from "@/pages/Setting/Qc";
import BadEvaluation from "@/pages/DriverScore/BadEvaluation";
import PartnerSchedule from "@/pages/Partner/Admin/schedule";

import BankList from "@/pages/Exam/admin/bank/BankList";
import BankInfo from "@/pages/Exam/admin/bank/BankInfo";
import TrainVedio from "@/pages/Training/admin/TrainVedio";
import TrainFileList from "@/pages/Training/admin/TrainFileList";
import TrainFile from "@/pages/Training/admin/TrainFile";
import TrainExamStat from "@/pages/Training/statistic/TrainExamStat";
import PunishRelation from "@/pages/DriverScore/DriverPunish/Relation";
import DriverLeaveStat from "@/pages/Driver/Leave/DriverLeaveStat";
import Notice from "@/pages/DriverScore/DriverNotice/index";
import PunishBill from "@/pages/DriverScore/DriverPunish/index";
import DriverEvaluation from "@/pages/DriverScore/DriverEvaluation/index";
import PartnerProvider from "@/pages/Partner/Admin/provider/provider";
import DriverAdmin from "@/pages/Partner/Admin/da/driverAdmin";
import Maintenance from "@/pages/Partner/Admin/mtc/bill";
import PartnerBillList from "@/pages/Partner/Admin/bill/serviceList";

export default [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
    children: [
      {
        path: "/",
        component: IndexCount,
      },
      {
        path: "/setting/vehicle",
        component: VehicleType,
      },
      {
        path: "/setting/fee",
        component: Fee,
      },
      {
        path: "/route/list",
        name: "routeList",
        component: RouteList,
      },
      {
        path: "/order/list",
        name: "orderList",
        component: OrderList,
      },
      {
        path: "/order/detail",
        name: "routeDetail",
        component: OrderDetail,
      },
      {
        path: "/route/replay",
        name: "routeReplay",
        component: RouteReplay,
      },
      {
        path: "/route/qc",
        name: "RouteQc",
        component: RouteQc,
      },
      {
        path: "/driver/detail",
        name: "driverDetail",
        component: DriverDetail,
      },
      {
        path: "/driver/push_message",
        component: PushMessage,
      },
      {
        path: "/driver/review",
        component: DriverReview,
      },
      {
        path: "/driver/manage",
        name: "driverList",
        component: DriverList,
      },
      {
        path: "/report/list",
        component: Upload,
      },
      {
        path: "/report/log",
        component: ReportLog,
      },
      {
        path: "/report/data",
        component: ReportData,
      },
      {
        path: "/passenger/list",
        name: "passengerList",
        component: PassengerList,
      },
      {
        path: "/audit/log",
        component: ReportLog,
      },
      {
        path: "/order/complaint",
        component: Complaint,
      },
      {
        path: "/order/alarm",
        component: Alarm,
      },
      {
        path: "/monitor/driver",
        component: DriverMonitor,
      },
      {
        path: "/log/login",
        component: LoginLog,
      },
      {
        path: "/log/operate",
        component: OperateLog,
      },
      {
        path: "/log/client_error",
        component: ClientErrorLog,
      },
      {
        path: "/driver/assessment",
        component: Assessment,
      },
      {
        path: "/permission/user",
        component: User,
      },
      {
        path: "/permission/role",
        component: Role,
      },
      {
        path: "/permission/permission",
        component: Permission,
      },
      {
        path: "/vehicle/list",
        component: VehicleList,
      },
      {
        path: "/setting/system",
        component: SystemSetting,
      },
      {
        path: "/setting/area",
        component: Area,
      },
      {
        path: "/setting/route",
        component: RoutePath,
      },
      {
        path: "/setting/ticket",
        component: TicketTemplate,
      },
      {
        path: "/setting/up_price",
        component: UpPrice,
      },
      {
        path: "/setting/rule_management",
        component: RuleManagement,
      },
      {
        path: "/marketing/discount/word",
        component: Word,
      },
      {
        path: "/marketing/discount/coupon",
        component: Coupon,
      },
      {
        path: "/marketing/advertisement",
        component: Advertisement,
      },
      {
        path: "/marketing/sms",
        component: Sms,
      },
      {
        path: "/beta/auto_dispatch",
        component: AutoDispatch,
      },
      {
        path: "/beta/auto_area_control/demo",
        component: AutoAreaControlDemo,
      },
      {
        path: "/beta/auto_area_control/setting",
        component: AutoAreaControlSetting,
      },
      {
        path: "/ticket/list",
        component: TicketList,
      },
      {
        path: "/ticket/scheduling",
        component: Scheduling,
      },
      {
        path: "/setting/recommed_address",
        component: RecommedAddress,
      },
      {
        path: "/work_common/dispatch",
        component: Dispatch,
      },
      {
        path: "/work_common/tel_record",
        component: CallRecored,
      },
      {
        path: "/setting/prinum",
        component: Prinum,
      },
      {
        path: "/work_common/receipt",
        component: Receipt,
      },
      {
        path: "/data/stats/money",
        component: StatsMoney,
      },
      {
        path: "/setting/driver_team",
        component: DriverTeam,
      },
      {
        path: "/setting/wx",
        component: WxSetting,
      },
      {
        path: "/setting/app_upgrade",
        component: AppUpgrade,
      },
      {
        path: "/data/orders/analysis",
        component: OrderAnalysis,
      },
      {
        path: "/data/user/analysis",
        component: UserAnalysis,
      },
      {
        path: "/financial/transaction/list",
        component: TransactionList,
      },
      {
        path: "/financial/fail_order_list/list",
        component: FailOrderList,
      },
      {
        path: "/data/comman_address",
        component: CommanAddress,
      },
      {
        path: "/driver/train_exam/bank_list",
        name: "BankList",
        component: BankList,
      },
      {
        path: "/driver/train_exam/bankinfo",
        name: "BankInfo",
        component: BankInfo,
      },
      {
        path: "/driver/train_exam/trainfilelist",
        name: "TrainFileList",
        component: TrainFileList,
      },
      {
        path: "/driver/train_exam/trainfile",
        name: "TrainFile",
        component: TrainFile,
      },
      {
        path: "/driver/train_exam/trainvedio",
        name: "TrainVedio",
        component: TrainVedio,
      },
      {
        path: "/driver/train_exam/train_exam_stat",
        name: "TrainExamStat",
        component: TrainExamStat,
      },
      {
        path: "/driver/train_relation/list",
        component: PunishRelation,
      },
      {
        path: "/driver/leave/leave_stat",
        name: "DriverLeaveStat",
        component: DriverLeaveStat,
      },
      {
        path: "/data/track/analysis",
        component: TrackAnalysis,
      },
      {
        path: "/work_common/custom_knowledge",
        component: CustomKnowledge,
      },
      {
        path: "/beta/jsprit_test",
        component: JspritTest,
      },
      {
        path: "/data/discount_analysis",
        component: DiscountAnalysis,
      },
      {
        path: "/data/invite_analysis",
        component: InvirteAnalysis,
      },
      {
        path: "/driver_score/notice",
        component: Notice,
      },
      {
        path: "/driver_score/service",
        component: DriverServiceScore,
      },
      {
        path: "/driver_score/safety",
        component: DriverSafetyScore,
      },
      {
        path: "/driver_score/appeal",
        component: DriverAppeal,
      },
      {
        path: "/notice",
        component: Notice,
      },
      {
        path: "/driver_score/reward",
        component: DriverReward,
      },
      {
        path: "/driver/punish_bill",
        name: "PunishBill",
        component: PunishBill,
      },
      {
        path: "/driver/evaluation",
        component: DriverEvaluation,
      },
      {
        path: "/driver/bad/evaluation",
        component: BadEvaluation,
      },
      {
        path: "/video/monitor",
        component: VideoMonitor,
        name: "VideoMonitor",
      },
      {
        path: "/video/query",
        component: VideoQuery,
        name: "VideoQuery",
      },
      {
        path: "/driver/feedback",
        component: DriverFeedback,
      },
      {
        path: "/setting/qc",
        component: QcSetting,
      },
      {
        path: "/passenger/feedback",
        component: PassengerFeedback,
      },
      {
        path: "/admin/partner/provider",
        name: "PartnerProvider",
        component: PartnerProvider,
      },
      {
        path: "/admin/partner/driveradmin",
        name: "DriverAdmin",
        component: DriverAdmin,
      },
      {
        path: "/admin/partner/maintenance",
        name: "Maintenance",
        component: Maintenance,
      },
      {
        path: "/admin/partner/list",
        name: "PartnerBillList",
        component: PartnerBillList,
      },
      {
        path: "/admin/partner/schedule/list",
        component: PartnerSchedule,
      },



      // {
      //   path: "/rtc",
      //   component: Rtc,
      // },
    ],
  },
];
