<template>
  <div v-if="dataList.length">
    <div class="slider">
      <el-slider
        v-model="value"
        :format-tooltip="formatTooltip"
        show-stops
        :marks="marks"
        :step="1"
        :min="min"
        :max="max"
        @change="changeTime"
      >
      </el-slider>
    </div>

    <div class="row-item">
      <ResourceRow
        :time="indexMap[timePoint]"
        :list="dataMap[indexMap[timePoint]]"
        :route="route"
        :rcMap="rcMap"
        :updateRcMap="updateRcMap"
        :showAllChannel="showAllChannel"
      ></ResourceRow>
    </div>
  </div>
</template>
<script>
import ResourceRow from "./ResourceRow";
import _ from "lodash";

export default {
  components: {
    ResourceRow,
  },

  props: {
    dataList: {
      type: Array,
      default: () => [],
    },

    showAllChannel: Boolean,

    showMarker: Function,

    route: {
      type: Object,
      default: () => ({}),
    },

    rcMap: {
      type: Object,
      default: () => ({}),
    },

    updateRcMap: Function,
  },

  watch: {
    dataList: {
      handler() {
        this.dealData();
      },
      immediate: true,
    },
  },

  data() {
    return {
      dataMap: {},
      indexMap: {},
      timePoint: 0,
      value: 0,
      min: 0,
      max: 0,
      marks: {},
      allMarks: {},
    };
  },

  methods: {
    dealData() {
      this.value = 0;
      this.timePoint = 0;
      const timeGroup = _.groupBy(this.dataList, (item) => item.createTime);
      const dataMap = {};
      const indexMap = {};
      let i = 0;
      const marks = {};
      const allMarks = {};
      const totalSize = _.keys(timeGroup).length;
      // 最多展示8个点
      const step = totalSize < 10 ? 1 : Math.floor(totalSize / 8);
      for (const key in timeGroup) {
        indexMap[i] = key;
        if (i % step === 0) {
          marks[i] = key.split(" ")[1];
        }
        allMarks[i] = key.split(" ")[1];
        dataMap[key] = timeGroup[key];
        i++;
      }
      this.max = --i;
      this.dataMap = dataMap;
      this.marks = marks;
      this.indexMap = indexMap;
      this.allMarks = allMarks;
    },

    changeTime(val) {
      if (!isNaN(val)) {
        this.timePoint = val;
        // 获取当前时间点
        const key = this.indexMap[val];
        const dataList = this.dataMap[key];
        // 获取第一条数据的定位
        if (dataList && dataList.length && dataList[0].lng && dataList[0].lat) {
          const location = [dataList[0].lng, dataList[0].lat];
          this.showMarker(location);
        }
      }
    },

    transToSencond(dateTime) {
      const time = dateTime.split(" ")[1];
      const [hour, minute, second] = time.split(":");
      return hour * 3600 + minute * 60 + second * 1;
    },

    formatTooltip(val) {
      return this.allMarks[val];
    },
  },
};
</script>
<style scoped lang="less">
.row-item {
  margin-bottom: 20px;
  padding: 0 30px;
}

.slider {
  box-sizing: border-box;
  padding: 0 40px 20px;
  width: 98%;
}
</style>
