<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="标签">
          <el-input
            style="width: 200px"
            v-model="param.label"
            @change="query()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" plain @click="countPlan()"
            >立即获取结果</el-button
          >

          <el-button type="primary" @click="openForm()">新建计划</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column prop="label" label="标签"> </el-table-column>

        <el-table-column
          prop="smsContent"
          label="短信内容"
          show-overflow-tooltip
          width="160"
        ></el-table-column>

        <el-table-column prop="words" label="字数" width="100">
          <template slot-scope="scope">
            {{ scope.row.smsWords }}字/{{
              scope.row.smsWords > 70 ? "67" : "70"
            }}字
          </template>
        </el-table-column>

        <el-table-column prop="smsCouponRemark" label="优惠券">
        </el-table-column>

        <el-table-column prop="groupRemark" label="发送对象">
          <template slot-scope="scope">
            {{
              scope.row.groupRemark
                ? scope.row.groupRemark
                : scope.row.sendUserRemark
            }}
          </template>
        </el-table-column>

        <el-table-column prop="targetNum" label="目标人数"> </el-table-column>
        <el-table-column prop="sendSuccessNum" label="发送成功">
        </el-table-column>
        <el-table-column prop="receiveSuccessNum" label="接收成功">
        </el-table-column>

        <el-table-column prop="totalFee" label="总费用"> </el-table-column>

        <el-table-column prop="sendTime" label="发送时间"> </el-table-column>

        <el-table-column prop="sendTime" label="发送1天内">
          <template slot-scope="scope">
            <div>
              <div>登陆{{ scope.row.day1LoginNum }}人</div>
              <div>下单{{ scope.row.day1OrderNum }}人</div>
              <div>成交{{ scope.row.day1DealNum }}人</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="1天登录率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day1LoginNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="1天成交率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day1DealNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="发送3天内">
          <template slot-scope="scope">
            <div>
              <div>登陆{{ scope.row.day3LoginNum }}人</div>
              <div>下单{{ scope.row.day3OrderNum }}人</div>
              <div>成交{{ scope.row.day3DealNum }}人</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="3天登录率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day3LoginNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="3天成交率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day3DealNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="发送7天内">
          <template slot-scope="scope">
            <div>
              <div>登陆{{ scope.row.day7LoginNum }}人</div>
              <div>下单{{ scope.row.day7OrderNum }}人</div>
              <div>成交{{ scope.row.day7DealNum }}人</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="7天登录率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day7LoginNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="7天成交率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day7DealNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="发送30天内">
          <template slot-scope="scope">
            <div>
              <div>登陆{{ scope.row.day30LoginNum }}人</div>
              <div>下单{{ scope.row.day30OrderNum }}人</div>
              <div>成交{{ scope.row.day30DealNum }}人</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="30天登录率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day30LoginNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="30天成交率">
          <template slot-scope="scope">
            <div>
              {{ scope.row.day30DealNum / scope.row.targetNum }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="day30LoginAvgCost" label="30天人均登录成本">
          <template slot-scope="scope">
            <div>{{ scope.row.day30LoginAvgCost }}元</div>
          </template>
        </el-table-column>

        <el-table-column prop="day30DealAvgCost" label="30天人均成交成本">
          <template slot-scope="scope">
            <div>{{ scope.row.day30DealAvgCost }}元</div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="scope.row.isSend"
              size="small"
              @click="send(scope.row)"
              >发送短信</el-button
            >

            <el-button type="text" size="small" @click="delGroup(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="创建计划"
      :visible.sync="visible"
      direction="ltr"
      :with-header="false"
      :append-to-body="true"
      size="70%"
    >
      <div class="box">
        <el-form ref="form" :model="form" :size="size" :rules="rules">
          <el-form-item label="标签：" prop="label">
            <el-input
              v-model="form.label"
              style="width: 600px; margin-left: 30px"
            ></el-input>
          </el-form-item>

          <el-form-item label="选择短信模板：" prop="smsTemplateId">
            <el-table
              :data="smsTempList"
              stripe
              max-height="300"
              size="small"
              highlight-current-row
              @current-change="
                (row) => handleCurrentChange(row, 'smsTemplateId')
              "
            >
              <el-table-column prop="smsIndex" label="序号" width="80">
                <template slot-scope="scope">
                  <div class="sms-index">{{ scope.row.smsIndex }}</div>
                </template>
              </el-table-column>

              <el-table-column prop="title" label="标题"> </el-table-column>

              <el-table-column
                prop="content"
                label="短信内容"
              ></el-table-column>

              <el-table-column prop="words" label="字数" width="100">
                <template slot-scope="scope">
                  {{ scope.row.words }}字/{{
                    scope.row.words > 70 ? "67" : "70"
                  }}字
                </template>
              </el-table-column>

              <el-table-column
                prop="auditState"
                label="审核状态"
                width="100"
              ></el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="选择优惠券模板：" prop="smsCouponId">
            <el-table
              :data="smsCouponList"
              stripe
              max-height="300"
              size="small"
              highlight-current-row
              @current-change="(row) => handleCurrentChange(row, 'smsCouponId')"
            >
              <el-table-column prop="label" label="标签"></el-table-column>

              <el-table-column prop="amount" label="金额"></el-table-column>

              <el-table-column prop="pathIds" label="路线">
                <template slot-scope="scope">
                  <div v-if="!scope.row.pathIds">全线路通用</div>
                  <div v-else v-for="item in scope.row.pathIds" :key="item">
                    {{ item | pathNameTransfer }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="有效时间">
                <template slot-scope="scope">
                  <div>{{ scope.row.validOn }}</div>
                  <div>{{ scope.row.validOff }}</div>
                </template>
              </el-table-column>

              <el-table-column prop="validOff" label="是否过期" sortable>
                <template slot-scope="scope">
                  <div>{{ scope.row.validOff | expired }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="选择用户分组：" prop="userGroupId">
            <el-table
              :data="smsGroupList"
              stripe
              max-height="300"
              size="small"
              highlight-current-row
              @current-change="(row) => handleCurrentChange(row, 'userGroupId')"
            >
              <el-table-column prop="label" label="标签"></el-table-column>

              <el-table-column prop="totalNum" label="目标人数">
                <template slot-scope="scope">
                  <div>{{ scope.row.totalNum }}人</div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="分组数：" prop="groupNum">
            <el-input-number
              style="width: 300px; margin-left: 30px"
              v-model="form.groupNum"
              placeholder=""
            ></el-input-number>
          </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <div class="btn">
          <el-button type="info" :size="size" @click="visible = false"
            >取 消</el-button
          >

          <el-button type="primary" :size="size" @click="save"
            >保存计划</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {listPlan, delPlan, savePlan, getSmsTemplateList, getSmsCouponList,
  getUserGroup, sendMsgByPlan, getYunpianAccountInfo, assessSendPlan} from '@/api';
import {mapState} from 'vuex';
import _ from 'lodash';
import {DateUtil} from '@/util';

export default {
  filters: {
    expired (val) {
      const today = DateUtil.getDayStr(0);
      if (val < today) {
        return '已过期';
      } else {
        return '未过期';
      }
    }
  },
  components: {},
  props: {},
  created () {
    this.initData();
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  data () {
    return {
      visible: false,
      rules: {
        label: [
          {required: true, message: '请输入标签', trigger: 'blur'},
        ],
        smsTemplateId: [
          {required: true, message: '请选择短信模板', trigger: 'blur'},
        ],
        userGroupId: [
          {required: true, message: '请选择用户分组', trigger: 'blur'},
        ],
      },
      param: {},
      form: {},
      list: [],
      smsTempList: [],
      smsCouponList: [],
      smsGroupList: []
    };
  },
  methods: {
    query () {
      listPlan({}).then(res=>{
        this.list = res.data || [];
      });
    },


    countPlan () {
      assessSendPlan().then(()=>{
        this.$message.success("操作完成");
        this.query();
      });
    },

    send (item) {
      this.$confirm('确认执行发送计划吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getYunpianAccountInfo().then(res=>{
          const info = JSON.parse(res.data);
          if (info.balance < +item.totalFee) {
            this.$message.warning("云片余额不足");
          } else {
            sendMsgByPlan(item).then(()=>{
              this.$message.success('发送计划执行成功');
              this.query();
            });
          }
        });
      });
    },

    openForm (item) {
      this.form = _.cloneDeep(item) || {groupNum: 1};
      this.visible = true;
    },

    initData () {
      getSmsTemplateList({}).then(res=>{
        this.smsTempList = res.data || [];
      });

      getSmsCouponList({}).then(res=>{
        this.smsCouponList = res.data || [];
      });

      getUserGroup({}).then(res=>{
        this.smsGroupList = res.data || [];
      });
    },

    handleCurrentChange (data, field) {
      this.$set(this.form, field, data.id);
    },

    save () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (!this.form.smsCouponId) {
          this.$confirm('当前未选择优惠券模板，确认创建吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            savePlan(this.form).then(()=>{
              this.visible = false;
              this.$message.success('保存成功');
              this.query();
            });

          });
        } else {
          savePlan(this.form).then(()=>{
            this.visible = false;
            this.$message.success('保存成功');
            this.query();
          });

        }

      });

    },


    delGroup (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPlan(item).then(()=>{
          this.$message.success('删除成功');
          this.query();
        });
      });
    }

  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 20px;
  /deep/.el-table__body tr.current-row > td {
    background-color: #d1e5fa !important;
  }

  /deep/.el-form-item__label {
    padding: 0 12px 0;
    background-color: #ebeef5;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
  }
}

.sms-index {
  width: 26px;
  height: 26px;
  background-color: #409eff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
</style>