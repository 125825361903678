<template>
  <div class="root-box">
    <div class="query-container space-between">

      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="路线：" prop="pathId">
            <el-select v-model="param.pathId" placeholder="请选择" @change="query">
              <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考评大类：" prop="evaType">
            <el-select v-model="param.evaType" placeholder="请选择" @change="evaTypeChange">
              <el-option v-for="item in driverEvaTypeOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考评小类：" prop="operateType">
            <el-select v-model="param.operateType" placeholder="请选择" @change="query">
              <el-option v-for="item in paramEvaOptOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车型：" prop="vehicleType">
            <el-select v-model="param.vehicleType" placeholder="请选择" @change="query" clearable="">
              <el-option v-for="item in vehicleTypeOpts" :key="item.id" :label="item.content"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary" style="width: 100px" :size="size" @click="openAdd">新增设置</el-button>
      </div>
    </div>

    <el-table :data="list" stripe max-height="600">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="pathIds" label="路线名称">
        <template slot-scope="scope">
          <div v-if="!scope.row.pathIds">全线路</div>

          <div v-else>
            <div v-for="item in scope.row.pathIds" :key="item">
              {{ item | pathNameTransfer }}
            </div>
          </div>

        </template>
      </el-table-column>

      <el-table-column prop="vehicleTypes" label="车型">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.vehicleTypes
              | enumTransfer(vehicleTypeOpts, "id", "content")
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="state" label="考核大类">
        <template slot-scope="scope">{{
          scope.row.evaType | enumTransfer(driverEvaTypeOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="state" label="考核小类">
        <template slot-scope="scope">{{
          scope.row.operateType | enumTransfer(driverEvaOptTypeOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="resultTypeName" label="结果类型名称">
        <template slot-scope="scope">{{ scope.row.resultTypeName }}({{ scope.row.resultType }})</template>
      </el-table-column>

      <el-table-column prop="score" label="影响服务分"> </el-table-column>

      <el-table-column prop="standardLine" label="考核线"> </el-table-column>

      <el-table-column prop="state" label="匹配排序">
        <template slot-scope="scope">{{
          scope.row.sortAsc | enumTransfer(yesNoOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="state" label="处罚类型">
        <template slot-scope="scope">{{
          scope.row.punishType | twoLevelCascaderTransfer(driverPunishTypeOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="rewardPunishAmount" label="奖惩金额">
      </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>

      <el-table-column prop="updateTime" label="更新时间" width="150">
      </el-table-column>

      <el-table-column prop="address" label="操作" width="160" fixed="right">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row, false)">修改</el-button>

            <el-button type="text" size="small" @click="openEdit(scope.row, true)">复制</el-button>

            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="visible" width="600px" max-height="600" :size="size" :title="form.id ? '新增设置' : '修改设置'"
      :close-on-click-modal="false">
      <el-form ref="form" :model="form" :size="size" label-width="120px" :rules="rules">
        <el-form-item label="路线：" prop="pathIds">
          <el-select v-model="form.pathIds" multiple style="width: 100%">
            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车型：" prop="vehicleTypes">
          <el-select v-model="form.vehicleTypes" multiple style="width: 100%">
            <el-option v-for="item in vehicleTypeOpts" :key="item.id" :label="item.content" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考评大类：" prop="evaType">
          <el-select v-model="form.evaType" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in driverEvaTypeOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考评小类：" prop="operateType">
          <el-select v-model="form.operateType" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in formEvaOptOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处罚类型：" prop="punishType">
          <el-cascader v-model="form.punishType" :options="driverPunishTypeOpts" style="width: 100%" clearable></el-cascader>
        </el-form-item>

        <el-form-item label="结果类型：" prop="resultType">
          <el-input-number style="width: 100%" v-model="form.resultType" placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="结果类型名称：" prop="resultTypeName">
          <el-input style="width: 100%" v-model="form.resultTypeName" placeholder></el-input>
        </el-form-item>

        <el-form-item label="影响服务分：" prop="score">
          <el-input-number style="width: 100%" v-model="form.score" placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="奖惩金额：" prop="rewardPunishAmount">
          <el-input-number style="width: 100%" v-model="form.rewardPunishAmount" placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="达标线：" prop="standardLine">
          <el-input-number style="width: 100%" v-model="form.standardLine" placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="达标排序：" prop="sortAsc">
          <el-select v-model="form.sortAsc" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注：" prop="remark">
          <el-input style="width: 100%" v-model="form.remark" placeholder></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDriverEvaluationSettingList, saveOrUpdateDriverEvaluationSetting, removeDriverEvaluationSetting } from '@/api';
import { Toolkit } from '@/util';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.query();
  },
  computed: {

    ...mapState({
      size: state => state.btnSize,
      driverEvaOptTypeOpts: state => state.enumMap['DRIVER_EVA_OPT_TYPE'],
      driverEvaTypeOpts: state => state.enumMap['DRIVER_EVA_TYPE'],
      violateClassify1Opts: state => state.enumMap['DRIVER_VIOLATE_TYPE'],
      violateClassify2Opts: state => state.enumMap['DRIVER_VIOLATE_OPT_TYPE'],
      vehicleTypeOpts: state => state.vehicleTypeOpts,
      pathOpts: state => state.pathList,
    }),

    formEvaOptOpts () {
      return this.getEvaOptTypes(this.form.evaType);
    },

    paramEvaOptOpts () {
      return this.getEvaOptTypes(this.param.evaType);
    },

    driverPunishTypeOpts () {
      // 构造cascader options 结构
      if (this.violateClassify1Opts && this.violateClassify2Opts) {
        const opt = [];
        this.violateClassify1Opts.forEach(c1 => {
          const item = {
            value: c1.value,
            label: c1.label,
            children: []
          };
          this.violateClassify2Opts.forEach(c2 => {
            if (item.value == c2.value.split('-')[0]) {
              item.children.push(c2);
            }
          });
          opt.push(item);
        });

        return opt;
      }

      return [];
    }
  },
  data () {
    return {
      MODE,
      visible: false,
      param: {
        pathId: 6,
        evaType: 3,
        operateType: '3-1'
      },
      form: {
        pathIds: [],
        vehicleTypes: []
      },
      list: [],
      yesNoOpts: [{
        label: '越小越好',
        value: true
      }, {
        label: '越大越好',
        value: false
      }],
      rules: {

        evaType: [
          { required: true, message: '考核大类不能为空', trigger: 'blur' },
        ],
        operateType: [
          { required: true, message: '考核小类不能为空', trigger: 'blur' },
        ],
        resultType: [
          { required: true, message: '结果类型不能为空', trigger: 'blur' },
        ],
        resultTypeName: [
          { required: true, message: '结果类型名称不能为空', trigger: 'blur' },
        ],
        score: [
          { required: true, message: '影响服务分不能为空', trigger: 'blur' },
        ],
        standardLine: [
          { required: true, message: '考核线不能为空', trigger: 'blur' },
        ],
        sortAsc: [
          { required: true, message: '匹配排序不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },
    openEdit (item, isCopy) {
      this.visible = true;
      this.form = isCopy ? { ...item, id: null } : { ...item };
    },
    openAdd () {
      this.visible = true;
      this.form = {
        state: true,
        evaType: this.param.evaType,
        operateType: this.param.operateType,
      };
    },

    evaTypeChange (val) {
      const list = this.getEvaOptTypes(val);
      this.$set(this.param, 'operateType', list[0].value);
      this.query();
    },

    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const data = this.form;
        // 则只取cascader的最后一级
        if (data.punishType && data.punishType instanceof Array) {
          data.punishType = data.punishType[data.punishType.length - 1];
        }
        saveOrUpdateDriverEvaluationSetting(data).then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.query();
        });
      });

    },


    del (item) {
      this.$confirm(`确认删除吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeDriverEvaluationSetting(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    query () {
      getDriverEvaluationSettingList(Toolkit.filterEmpty(this.param)).then(res => {
        this.list = res.data || [];
      });
    },

    getEvaOptTypes (evaType) {
      let list;
      if (evaType && this.driverEvaOptTypeOpts) {
        list = this.driverEvaOptTypeOpts.filter(t => t.value.split('-')[0] == evaType);
      } else {
        list = this.driverEvaOptTypeOpts;
      }
      return list;
    }

  }
};
</script>

<style scoped lang="less"></style>
