import { MENU_EX, ADD_GLOBAL_DATA, ROUTE_CHECK_INFO } from "./mutation-types";

export default {
  [MENU_EX](state) {
    state.menuCollapse = !state.menuCollapse;
  },

  [ADD_GLOBAL_DATA](state, payload) {
    state[payload.key] = payload.value;
  },

  [ROUTE_CHECK_INFO](state, data) {
    state.routeCheckInfo = data;
  },
};
