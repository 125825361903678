<template>
  <div class="root-box">
    <div class="operate-container">
      <el-button type="primary" :size="size" @click="openEdit()">新增质检项</el-button>
    </div>

    <el-table :data="list" stripe max-height="600">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="name" label="质检项"></el-table-column>

      <el-table-column prop="description" label="描述"></el-table-column>

      <el-table-column prop="checkTarget" label="检查对象">
        <template slot-scope="scope">{{
          scope.row.checkTarget | enumTransfer(checkTargetOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="violateClassify2" label="所属违规项">
        <template slot-scope="scope">{{
          scope.row.violateClassify2 | enumTransfer(violateClassify2Opts)
        }}</template>
      </el-table-column>

      <el-table-column prop="createTime" label="创建日期"></el-table-column>

      <el-table-column prop="state" label="是否启用">
        <template slot-scope="scope">{{
          scope.row.state | enumTransfer(yesNoOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="deleteItem(scope.row)">删除</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="visible" width="600px" max-height="600" :size="size" :title="title"
      :close-on-click-modal="false">
      <el-form ref="userForm" :model="form" :size="size" label-width="120px" :rules="rules">
        <el-form-item label="质检项：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="描述：" prop="description">
          <el-input type="textarea" :rows="3" v-model="form.description"></el-input>
        </el-form-item>

        <el-form-item label="质检对象：" prop="checkTarget">
          <el-select v-model="form.checkTarget" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in checkTargetOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="对应违规项：" prop="violateClassify2">
          <el-cascader v-model="form.violateClassify2" :options="driverPunishTypeOpts" style="width: 100%" clearable></el-cascader>
        </el-form-item>

        <el-form-item label="是否启用：" prop="state">
          <el-select v-model="form.state" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { delQcSetting, saveOrUpdateQcSetting, listQcSetting } from '@/api';
import _ from 'lodash';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? '新增区域' : '修改区域';
    },

    ...mapState({
      size: state => state.btnSize,
      yesNoOpts: state => state.enumMap["YES_NO_ENUM"],
      violateClassify1Opts: state => state.enumMap['DRIVER_VIOLATE_TYPE'],
      violateClassify2Opts: state => state.enumMap['DRIVER_VIOLATE_OPT_TYPE'],
    }),

    driverPunishTypeOpts () {
      // 构造cascader options 结构
      if (this.violateClassify1Opts && this.violateClassify2Opts) {
        const opt = [];
        this.violateClassify1Opts.forEach(c1 => {
          const item = {
            value: c1.value,
            label: c1.label,
            children: []
          };
          this.violateClassify2Opts.forEach(c2 => {
            if (item.value == c2.value.split('-')[0]) {
              item.children.push(c2);
            }
          });
          opt.push(item);
        });

        return opt;
      }

      return [];
    }
  },
  data () {
    return {
      MODE,
      visible: false,
      form: {},
      list: [],
      mode: MODE.EDIT,
      checkTargetOpts: [
        { label: '行程', value: 'ROUTE' },
        { label: '订单', value: 'ORDER' },
      ],
      rules: {
        name: [
          { required: true, message: '请输入质检名称', trigger: 'blur' },
        ],
        description: [
          { required: true, message: '请输入质检描述', trigger: 'blur' },
        ],
        checkTarget: [
          { required: true, message: '请选择质检对象', trigger: 'blur' },
        ],
        state: [
          { required: true, message: '请选择启用状态', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },

    openEdit (item) {
      this.visible = true;
      if (item) {
        this.form = _.cloneDeep(item);
      } else {
        this.form = {};
      }
    },


    saveOrUpdate () {
      const param = {...this.form};
      // 则只取cascader的最后一级
      if (param.violateClassify2 && param.violateClassify2 instanceof Array) {
        param.violateClassify2 = param.violateClassify2[param.violateClassify2.length - 1];
      }
      saveOrUpdateQcSetting(param).then(() => {
        this.$message.success("操作成功");
        this.visible = false;
        this.query();
      });

    },

    query () {
      listQcSetting({}).then(res => {
        this.list = res.data || [];
      });
    },

    deleteItem (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delQcSetting(item).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.query();
        });
      }).catch(() => {

      });
    }

  }
};
</script>

<style scoped lang="less">
.table {
  margin-top: 10px;
}

.operate-div {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.menu {
  color: #2aaaff;
}

.button {
  color: #37c604;
}
</style>
