import { Loading, Message } from "element-ui";
import { Toolkit } from "@/util";
import router from "@/route";

const fly = require("flyio");

let loadingInstance = null;
const options = {
  lock: true,
  text: "正在处理，请稍后",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.7)",
};

const genKey = (request) => {
  const param = JSON.stringify(request.body) || "";
  const paramKey = Toolkit.hash(param);
  return `${request.method}-${request.url}-${paramKey}`;
};

// 结果缓存
const cacheMap = {};

fly.config.headers["X-Requested-With"] = "XMLHttpRequest";

// 自定义请求拦截
fly.interceptors.request.use((request) => {
  let _hideLoading = request.body && request.body._hideLoading;

  // 开启缓存，则从缓存中获取数据
  if (request.body && request.body._useCache) {
    const key = genKey(request);
    if (cacheMap[key] !== null && cacheMap[key] !== undefined) {
      return Promise.resolve(cacheMap[key]);
    } else {
      if (!_hideLoading) {
        loadingInstance = Loading.service(options);
      }
      return request;
    }
  }

  if (!_hideLoading) {
    loadingInstance = Loading.service(options);
  }

  return request;
});

// 正常返回响应拦截
fly.interceptors.response.use(
  (request) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    const response = request.data;

    if (response.code === 200) {
      // 如果是需要缓存的接口，将结果放入缓存
      const _request = request.request || {};
      if (_request.body && _request.body._useCache) {
        const key = genKey(_request);
        cacheMap[key] = response;
      }
      return response;
    } else if (response.code === 100) {
      router.push("/");
      window.location.reload();
    } else if (response.code === 301 || response.code === 300) {
      // 301 业务异常直接进行提示
      Message.error(response.message);
    } else if (response.code === 403) {
      // 403 权限不足
      Message.error("抱歉，您的权限不足");
    } else if (response.code === 500) {
      console.log("返回结果异常", response);
      Message.error("服务异常，请联系管理员处理...");
    } else {
      return request;
    }

    return Promise.reject(response);
  },
  (err) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    console.log("网络异常", err);
    Message.error("网络异常，请检查网络...");
  }
);

export function downloadFile(url, param, fileName) {
  fly
    .post(url, param, {
      responseType: "blob",
    })
    .then((res) => {
      // 创建一个 Blob 对象，使用从服务器接收到的数据
      const file = new Blob([res.data], { type: "application/octet-stream" });
      let _filename = fileName;
      // 从响应头中获取文件名
      const contentDisposition = res.headers["content-disposition"];
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          _filename = matches[1].replace(/['"]/g, "");
        }
      }

      // 创建一个指向该 Blob 的 URL
      const fileURL = URL.createObjectURL(file);

      // 创建一个临时的 a 标签来模拟点击行为
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", _filename); // 设置文件名和扩展名
      document.body.appendChild(link);
      link.click();

      // 清理并移除临时链接
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}

export function uploadFiles(url, formData) {
  return fly.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export default fly;
