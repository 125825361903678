<template>
  <div class="bill-detail">
    <div class="row main">
      <label>创建人：</label>
      <div class="value">{{ billInfo.cbyName ?? '系统' }}</div>
    </div>
    <div class="row main">
      <label>创建原因：</label>
      <div class="value">{{ billInfo.createReason }}</div>
    </div>
    <div class="row main">
      <label>服务时间：</label>
      <div class="value">{{ billInfo.serviceDate }}</div>
    </div>
    <div class="row main">
      <label>服务单位：</label>
      <div class="value">{{ billInfo.providerName }}</div>
    </div>
    <div class="row main">
      <label>服务人员：</label>
      <div class="value">{{ billInfo.staffName }}</div>
    </div>
    <div class="row main">
      <label>服务时长（分）：</label>
      <div class="value">{{ billInfo.timeout && billInfo.timeout > 0 ? billInfo.timeout : "-" }}</div>
    </div>
    <div class="row main">
      <label>车型：</label>
      <div class="value">{{ billInfo.brand + " " + billInfo.color }}</div>
    </div>
    <div class="row main">
      <label>车牌号：</label>
      <div class="value">{{ billInfo.vehicleNo }}</div>
    </div>
    <div class="row main">
      <label>驾驶员：</label>
      <div class="value">{{ billInfo.driverName }}</div>
    </div>
    <div class="row sub" v-for="item in billInfo.items" :key="item.itemName">
      <label>{{ item.itemName }} : </label>
      <div class="value">
        <div v-for="photoUrl in item.photoes" :key="photoUrl" class="sub-itm">
          <el-image style="width: 100px;" :src="photoUrl" fit="contain" :preview-src-list="item.photoes"></el-image>
        </div>
        <div v-if="item.desc" class="sub-itm">{{ item.desc }}</div>
        <div v-if="item.amount" class="sub-itm">合计费用{{ item.amount }}元</div>
      </div>
    </div>
  </div>
</template>

<script>
import { viewServiceBillInfo } from "@/api/partner_admin";
import { DateUtil } from "@/util";
export default {
  name: "bill_detail",
  props: {
    billId: {
      type: Number
    }
  },

  watch: {
    billId: function () {
      this.initPage();
    }
  },

  data() {
    return {
      billInfo: {}
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      viewServiceBillInfo(this.billId).then(res => {
        let data = res.data || {};
        this.billInfo = data.mainBill || {};
        this.billInfo.items = data.items || [];
        if (this.billInfo.serviceStartTime && this.billInfo.serviceEndTime) {
          let timeout = DateUtil.str2Timestamp(this.billInfo.serviceEndTime) - DateUtil.str2Timestamp(this.billInfo.serviceStartTime);
          this.billInfo.timeout = parseInt(timeout / 1000 / 60);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.bill-detail {
  .row {
    margin-bottom: 6px;
    margin-top: 20px;
    border-bottom: 1px solid #eee;
    display: flex;

    label {
      width: 160px;
    }

    div.value {
      flex: 1;
      text-align: right;
    }
  }
}
</style>