<template>
  <div>
    <div class="info1" v-if="driver && serviceScore">
      <el-form size="small" :inline="true">
        <el-row>
          <el-col :span="4">
            <el-form-item label="司机：">
              {{ driver.name }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="车型品牌：">
              {{ driver.vehicleColor }}·{{ driver.brand }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="初始提点："> </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="累计投诉："
              >{{ fullServiceScore.complaintTimes }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="5星："
              >{{ fullServiceScore.star5 }}次
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="班号：">
              {{ driver.no }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="服务车型：">
              {{
                driver.vehicleTypeId
                  | enumTransfer(vehicleTypeOpts, "id", "content")
              }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="当前提点：">
              {{ driver.commission }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="累计核准投诉："
              >{{ fullServiceScore.verifiedComplaintTimes }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="1星："
              >{{ fullServiceScore.star1 }}次
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="车队：">
              {{ driver.teamId | enumTransfer(teamOpts, "id", "name") }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="注册日期：">
              {{ driver.registerTime }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="服务分大于平均：">
              {{ data.higherNum }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="拉黑："
              >{{ fullServiceScore.blacklistTimes }}次
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="沟通抽查不合规率：">
              <span v-if="fullServiceScore.totalCommTimes">
                {{
                  ((fullServiceScore.badCommTimes /
                    fullServiceScore.totalCommTimes) *
                    100)
                    | numFormat(0)
                }}
                %
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="手机号：">
              {{ driver.telephone }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="总接单数：">
              {{ fullServiceScore.totalOrders }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="服务分小于平均：">
              {{ data.lowerNum }}</el-form-item
            >
          </el-col>

          <el-col :span="4">
            <el-form-item label="安全违规："
              >{{ fullServiceScore.totalSafetyNum }}次
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单操作不合规率：">
              <span v-if="fullServiceScore.totalOperate">
                {{
                  ((fullServiceScore.totalBadOperate /
                    fullServiceScore.totalOperate) *
                    100)
                    | numFormat(0)
                }}
                %
              </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="info2" v-if="driver && evaluationSummary">
      <div>
        {{ driver.no }}当前服务分为{{ evaluationSummary.total }}分,加分{{
          evaluationSummary.increase
        }}，扣分{{ evaluationSummary.reduce }}
      </div>
    </div>

    <div class="info3" v-if="serviceScore">
      <div class="box">
        <div class="b1">{{ serviceScore.scoreEvaluation }}分</div>
        <div class="b2">乘客评价</div>
        <div class="b3">
          <div class="left">五星 {{ serviceScore.star5 }}</div>
          <div class="right">一星 {{ serviceScore.star1 }}</div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreComplaint }}分</div>
        <div class="b2">投诉拉黑</div>
        <div class="b3">
          <div class="left">
            投诉 {{ serviceScore.verifiedComplaintTimes }}（{{
              serviceScore.complaintTimes
            }}）
          </div>
          <div class="right">拉黑 {{ serviceScore.blacklistTimes }}</div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreOperate }}分</div>
        <div class="b2">订单操作</div>
        <div class="b3">
          <div class="left">
            合规 {{ serviceScore.totalOperate - serviceScore.totalBadOperate }}
          </div>
          <div class="right">违规 {{ serviceScore.totalBadOperate }}</div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreDuration }}分</div>
        <div class="b2">路程时长</div>
        <div class="b3">
          <div class="left">
            <span v-if="serviceScore.accuratelyDurationNum">
              有效考核{{ serviceScore.accuratelyDurationNum }}次
            </span>
            <span v-else>暂无</span>
          </div>
          <div class="right">
            <span v-if="serviceScore.accuratelyDurationNum">
              {{
                (serviceScore.totalDuration /
                  serviceScore.accuratelyDurationNum /
                  3600)
                  | numFormat(1)
              }}小时
            </span>
            <span v-else>暂无</span>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreOntime }}分</div>
        <div class="b2">准点率</div>
        <div class="b3">
          <div class="left">
            合格 {{ serviceScore.goodOntime + serviceScore.commonOntime }}
          </div>
          <div class="right">不合格 {{ serviceScore.badOntime }}</div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreComm }}分</div>
        <div class="b2">沟通规范</div>
        <div class="b3">
          <div class="left">合规 {{ serviceScore.goodCommTimes }}</div>
          <div class="right">违规 {{ serviceScore.badCommTimes }}</div>
        </div>
      </div>

      <div class="box">
        <div class="b1">{{ serviceScore.scoreSafety }}分</div>
        <div class="b2">安全规范</div>
        <div class="b3">
          <div class="right">合计{{ serviceScore.totalSafetyNum }}次</div>
        </div>
      </div>
    </div>

    <div class="info4" v-if="driver">
      <div class="box">
        <div class="title">乘客评价</div>
        <div>
          <el-table :data="starList" stripe max-height="480">
            <el-table-column label="1星" prop="star1"></el-table-column>
            <el-table-column label="2星" prop="star2"></el-table-column>
            <el-table-column label="3星" prop="star3"></el-table-column>
            <el-table-column label="4星" prop="star4"></el-table-column>
            <el-table-column label="5星" prop="star5"></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="box">
        <div class="title">操作规范</div>
        <div>
          <el-table :data="operateList" stripe max-height="480">
            <el-table-column label="考评结果" prop="label"></el-table-column>
            <el-table-column label="出发" prop="step1"></el-table-column>
            <el-table-column label="到达" prop="step2"></el-table-column>
            <el-table-column label="上车" prop="step3"></el-table-column>
            <el-table-column label="下车" prop="step4"></el-table-column>
            <el-table-column label="完成" prop="step5"></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="box">
        <div class="title">准点率</div>
        <div>
          <el-table :data="ontimeList" stripe max-height="480">
            <el-table-column label="优秀" prop="goodOntime"></el-table-column>
            <el-table-column label="不合格" prop="badOntime"></el-table-column>
            <el-table-column label="合格" prop="commonOntime"></el-table-column>
            <el-table-column prop="verifiedComplaintTimes" label="不合格率">
              <template slot-scope="scope">
                <div v-if="scope.row.totalOntime != 0">
                  {{
                    ((scope.row.badOntime / scope.row.totalOntime) * 100)
                      | numFormat(0)
                  }}
                  %
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="box">
        <div class="title">路程时长</div>
        <div>
          <el-table :data="durationList" stripe max-height="480">
            <el-table-column label="优秀" prop="goodDuration"></el-table-column>
            <el-table-column
              label="不合格"
              prop="badDuration"
            ></el-table-column>
            <el-table-column
              label="合格"
              prop="commonDuration"
            ></el-table-column>
            <el-table-column prop="verifiedComplaintTimes" label="不合格率">
              <template slot-scope="scope">
                <div v-if="scope.row.totalDurationTimes != 0">
                  {{
                    ((scope.row.badDuration / scope.row.totalDurationTimes) *
                      100)
                      | numFormat(0)
                  }}
                  %
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="box">
        <div class="title">行车安全</div>
        <div>
          <el-table :data="safetyList" stripe max-height="480">
            <el-table-column label="超速" prop="overSpeed"></el-table-column>
            <el-table-column label="超载" prop="overLoad"></el-table-column>
            <el-table-column label="玩手机" prop="playPhone"></el-table-column>
            <el-table-column
              label="打电话不戴耳机"
              prop="noHeadphones"
            ></el-table-column>
            <el-table-column
              label="疲劳驾驶"
              prop="tiredness"
            ></el-table-column>
            <el-table-column label="其他问题" prop="others"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Object
  },
  created () {},
  mounted () {},
  watch: {
    value (val) {
      if (val) {
        this.dealData(val);
      }
    }
  },
  computed: {},
  data () {
    return {
      data: null,
      fullServiceScore: null,
      serviceScore: null,
      evaluationSummary: null,
      driver: null,
      starList: [],
      operateList: [],
      complaintList: [],
      commList: [],
      durationList: [],
      safetyList: [],
      ontimeList: [],
    };
  },
  methods: {

    dealData (data) {
      this.data = data || {};
      const {serviceScore, evaluationSummary, driver, fullServiceScore} = this.data;
      this.driver = driver;
      this.fullServiceScore = fullServiceScore;
      this.serviceScore = serviceScore;
      this.evaluationSummary = evaluationSummary;
      this.dealTableData();
    },

    dealTableData () {
      const {star1, star2, star3, star4, star5, goodStep1, goodStep2, goodStep3, goodStep4, goodStep5,
        badStep1, badStep2, badStep3, badStep4, badStep5, complaintTimes, verifiedComplaintTimes, blacklistTimes,
        goodDuration, commonDuration, badDuration, overSpeed, overLoad, playPhone, totalDurationTimes,
        noHeadphones, tiredness, others, totalOntime, goodOntime, badOntime, commonOntime} = this.serviceScore;
      this.starList = [
        {star1, star2, star3, star4, star5},
      ];
      this.operateList = [
        {label: '合规', step1: goodStep1, step2: goodStep2, step3: goodStep3, step4: goodStep4, step5: goodStep5},
        {label: '不合规', step1: badStep1, step2: badStep2, step3: badStep3, step4: badStep4, step5: badStep5},
      ];
      this.complaintList = [
        {complaintTimes, verifiedComplaintTimes, blacklistTimes},
      ];

      this.durationList = [
        { goodDuration, commonDuration, badDuration, totalDurationTimes},
      ];

      this.ontimeList = [{totalOntime, goodOntime, badOntime, commonOntime}];

      this.safetyList = [
        {overSpeed, overLoad, playPhone, noHeadphones, tiredness, others},
      ];
    }
  },
};
</script>
<style lang="less" scoped>
.info1 {
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.info2 {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.info3 {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  .box {
    width: 200px;
    height: 100px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #333;

    &.active {
      background-color: #4894efc7;
      color: white;
    }

    .b1 {
      font-size: 18px;
    }

    .b2 {
      font-weight: bold;
      font-size: 18px;
    }
    .b3 {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      width: 100%;
    }
  }
}

.info4 {
  display: flex;
  flex-wrap: wrap;

  .box {
    box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
    border-radius: 10px;
    border: 1px solid #eee;
    margin: 0 0 20px;
    padding: 10px;
    margin: 40px;
    flex: 1;
    .title {
      text-align: center;
      border-bottom: 1px solid #eee;
      padding: 10px;
    }
  }
}
</style>