import Vue from 'vue';

Vue.filter('encryptName', function (value, str = '**') {
  if (value && value.length) {
    const item = value.substr(0, 1) + str;
    return item;
  }
  return '';

});
