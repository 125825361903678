<template>
  <div class="root-box">
    <div class="query-container space-between">
      <el-form size="small" :inline="true">
        <el-form-item label="查询时间：">
          <el-date-picker value-format="yyyy-MM-dd 00:00:00" v-model="param.startTime" type="date"
            placeholder="选择日期"></el-date-picker></el-form-item>

        <el-form-item>
          <el-date-picker value-format="yyyy-MM-dd 23:59:59" v-model="param.endTime" type="date"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="650" size="small">

        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

        <el-table-column prop="clientType" label="客户端类型">

        </el-table-column>

        <el-table-column prop="suggestType" label="建议类型">

        </el-table-column>

        <el-table-column prop="userTel" label="用户手机号">

        </el-table-column>

        <el-table-column prop="suggestContent" label="反馈内容" width="300">

        </el-table-column>

        <el-table-column prop="annexes" label="附件" width="300">
          <template slot-scope="scope" v-if="scope.row.annexes">
            <a v-for="(item, index) in scope.row.annexes" :key="item" class="annex" :href="item"
              target="_blank">{{ item | fileNameTransfer(index + 1) }}</a>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="提交时间" width="160">

        </el-table-column>


      </el-table>
    </div>

    <div class="page">
      <el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { getPassengerFeedbackList } from '@/api';
import { DateUtil, ParamUtil } from '@/util';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () { },
  computed: {

    ...mapState({
      size: state => state.btnSize,
    })
  },


  data () {
    return {
      param: {
        startTime: DateUtil.getDayStr(-30),
        endTime: DateUtil.getDayStr(0),
      },
      list: [],
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: 10,
          orders: [{ column: 'id', asc: false }]
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: ParamUtil.filterEmptyData(this.param)
      };
      getPassengerFeedbackList(ParamUtil.filterEmptyData(param)).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },


    handleCurrentChange (page) {
      this.query(page);
    },

    tableRowClassName ({ row }) {
      return row.talkDirect ? 'driver' : 'admin';
    },

  },
};
</script>
<style lang="less" scoped>
/deep/ .driver td {
  background: #fafbff !important;
}

/deep/ .admin td {
  background: #e0eff1 !important;
}


.spe-box {
  .el-button--small {
    padding: 0px 15px;
  }
}


.content {
  display: flex;

  .user {
    width: 160px;
  }

  .text {
    flex: 1;
  }
}
</style>