<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="统计时间：">
          <el-date-picker
            @change="query()"
            value-format="yyyy-MM-dd 00:00:00"
            v-model="param.startTime"
            type="date"
            placeholder="选择日期"
          ></el-date-picker
        ></el-form-item>

        <el-form-item>
          <el-date-picker
            @change="query()"
            value-format="yyyy-MM-dd 23:59:59"
            v-model="param.endTime"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="orderDate" label="订单日期" width="120"></el-table-column>
        <el-table-column prop="complaint" label="吐槽" width="500"></el-table-column>

        <el-table-column prop="driverName" label="司机" width="100"></el-table-column>
        <el-table-column prop="driverNo" label="司机编号" width="100"></el-table-column>
        <el-table-column prop="driverScore" label="司机驾驶分" width="100"></el-table-column>

        <el-table-column prop="serviceScore" label="司机服务分" width="100"></el-table-column>

        <el-table-column prop="serviceEvaluation" label="服务评价"></el-table-column>

        <el-table-column prop="drivingEvaluation" label="驾驶评价"></el-table-column>
        <el-table-column prop="evaluateTime" label="评价时间" width="140"></el-table-column>

        <el-table-column prop="detail" label="行程详情" width="120">
          <template slot-scope="scope">
            <div class="spe-box">
              <div>
                <el-button type="text" size="small"
                  ><span v-goRouteDetail="scope.row.id">订单详情</span></el-button
                >
              </div>

              <div>
                <el-button type="text" size="small" @click="showRoutePlan(scope.row.routeId)"
                  >行程规划</el-button
                >
              </div>

              <div>
                <el-button type="text" size="small" @click="showRouteList(scope.row.routeId)"
                  >行程详情</el-button
                >
              </div>

              <div>
                <el-button type="text" size="small" @click="go2QcPage(scope.row.routeId)"
                  ><span>质检详情</span></el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getBadEvaluationList } from "@/api";
import { mapState } from "vuex";
import { BaseDataService } from "@/service";
import config from "@/config/config";
import { SHOW_ROUTE_PLAN } from "@/core/const/global-event";
import Router from "@/route";

export default {
  filters: {},
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  data() {
    return {
      visible: false,
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      yesNoOpts: BaseDataService.yesNoOpts(),
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: config.pageSize,
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: this.param,
      };
      getBadEvaluationList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    showRoutePlan(routeId) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, { id: routeId }, false);
    },

    go2QcPage(routeId) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId },
      });
      window.open(href, "_blank");
    },

    showRouteList(item) {
      const { href } = Router.resolve({
        path: "/route/list",
        query: { routeId: item.routeId },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped></style>
