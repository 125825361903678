import Vue from 'vue';
import _ from 'lodash';

Vue.filter('apmRsName', function (value, enumOpts) {
  const item = _.find(enumOpts, item=> item.agent === value.agent && item.rsId === value.rsId) || {};
  if (item.name) {
    return item.name;
  }
  return value.rsId;
});
