<template>
  <el-dialog
    title="取消订单"
    :visible.sync="dialogVisible"
    width="25%"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="clear"
  >
    <el-form
      ref="form"
      :model="form"
      :size="size"
      label-position="top"
      label-width="90px"
      :rules="rules"
    >
      <el-form-item label="取消类型" prop="cancelDesc">
        <el-select
          v-model="form.cancelDesc"
          placeholder="请选择取消原因"
          style="width: 100%"
        >
          <el-option
            v-for="item in cancelReason"
            :label="item"
            :value="item"
            :key="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="取消说明"
        v-if="form.cancelDesc === '其他'"
        prop="other"
      >
        <el-input v-model="form.other"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button :size="size" @click="dialogVisible = false">取 消</el-button>
      <el-button :size="size" type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { cancelOrder } from "@/api";

export default {
  props: {
    onCancel: Function,
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
    }),
  },
  data () {
    return {
      dialogVisible: false,
      form: {},
      cancelReason: ["乘客行程有变", "卖出", "换出", "乘客违约取消", "其他"],
      rules: {
        cancelDesc: [{ required: true, message: "请选择取消原因", trigger: "blur" }],
        other: [{ required: true, message: "请输入取消说明", trigger: "blur" }],
      },
    };
  },
  methods: {
    open (orderId) {
      this.orderId = orderId;
      this.dialogVisible = true;
    },
    clear () {
      this.form = {};
    },
    handleOk () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const cancelDesc = this.form.other || this.form.cancelDesc;
          const param = {
            orderId: this.orderId,
            cancelDesc: cancelDesc,
          };

          cancelOrder(param).then(() => {
            this.$message.success("订单取消成功");
            if (this.onCancel) {
              this.onCancel();
            }
          });

          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
