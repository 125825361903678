import { Http } from "@/util";

export function getUserAddress(param) {
  return Http.get("/admin/passenger/address", param);
}

export function createTelOrder(param) {
  return Http.post("/admin/order/tel/create", param);
}

export function createEmptyOrder(param) {
  return Http.post("/admin/order/create_empty_order", param);
}

export function getOrderList(param) {
  return Http.post("/admin/order/list", param);
}

export function getOrder(param) {
  return Http.post("/admin/order/get", param);
}

export function hasBackupTicket(param) {
  return Http.post("/admin/order/backup", param);
}

export function getOrderBill(param) {
  return Http.get("/admin/order/order_bill", param);
}

export function cancelOrder(param) {
  return Http.get("/admin/order/cancel", param);
}

export function bindTicket(orderId) {
  return Http.get(`/admin/order/bind/ticket`, { orderId });
}

// 发送支付短信
export function adminSendPayLink(orderId) {
  return Http.get(`/admin/order/send/pay_link`, { orderId });
}

export function changeCallStatus(param) {
  return Http.get("/admin/order/change/called/status", param);
}

export function recall(orderId, tipDriver = true) {
  return Http.get("/admin/order/recall", { orderId, tipDriver });
}

export function changeRouteDriver(routeId, driverId) {
  return Http.get("/admin/order/change_route_driver", { routeId, driverId });
}

export function getOrderLogs(orderId) {
  return Http.get("/admin/order/operate/log", { orderId, _hideLoading: true });
}

export function modifyOrder(param) {
  return Http.post("/admin/order/modify", param);
}

export function modifyUnimportantOrderInfo(param) {
  return Http.post("/admin/order/modify/unimportant", param);
}

// 查询订单详情列表
export function getOrderDetailList(param) {
  return Http.post("/admin/order/detail/list", param);
}

// 查询订单行程详情
export function getOrderRouteDetail(orderId) {
  return Http.get("/admin/order/route_detail", { orderId });
}

// 查询客服用户信息
export function queryKfUserData(id, role) {
  return Http.get("/admin/order/kf/user/data", { id, role, _hideLoading: true });
}

// 查询订单详情
export function getOrderDetail(orderId) {
  return Http.get("/admin/order/detail", { orderId });
}

// 查询话务来电订单信息
export function getHuawuData(tel) {
  return Http.get("/admin/order/huawu/info", { tel });
}

// 修改客服留言
export function modifyAdminMessage(param) {
  return Http.post("/admin/order/modify/admin_message", param);
}

// 查询号码归属地
export function getTelInfo(mobile) {
  const secretId = "AKIDBCu60RhMDDEE87OF226E1y9HT9XGm5XiB7Li";
  const secretKey = "L1hu98Gdirla6Ei2sv5ga5ztxj6L8z9l0otR9igk";
  const source = "market";
  const CryptoJS = require("crypto-js");
  // 签名
  const datetime = new Date().toGMTString();
  const signStr = "x-date: " + datetime + "\n" + "x-source: " + source;
  const sign = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(signStr, secretKey));
  const auth =
    'hmac id="' +
    secretId +
    '", algorithm="hmac-sha1", headers="x-date x-source", signature="' +
    sign +
    '"';
  const headers = {
    "X-Source": source,
    "X-Date": datetime,
    Authorization: auth,
  };
  return Http.get(
    "https://service-av27cw4h-1257598706.ap-shanghai.apigateway.myqcloud.com/release/mobile",
    { mobile },
    { headers, timeout: 5000 }
  );
}

// 查询用户留言
export function getPassengerRemark() {
  return Http.get("/admin/order/passenger/remarks", { _useCache: true });
}

// 行李审核
export function auditBaggage(param) {
  return Http.post("/admin/order/audit/baggage", param);
}

// 删除行李照片
export function deleteBaggage(orderId, type) {
  return Http.get("/admin/order/delete/baggage", { orderId, type });
}

// 查询下单地址
export function getBookAddress(orderId) {
  return Http.get("/admin/order/get/book/address", { orderId });
}

// 查询实时位置
export function getOrderRealTimePositioning(orderId) {
  return Http.get("/admin/order/real_time_position", { orderId });
}

// 提交改签
export function changeOrderSubmit(param) {
  return Http.post("/admin/order/change/submit", param);
}

// 确认改签
export function changeOrderConfirm(param) {
  return Http.get("/admin/order/change/confirm", param);
}

// 查询可售座位
export function getSeatsInfo(orderId) {
  return Http.get("/admin/order/get/seat_info", { orderId });
}

// 发送改签、退票自助操作短信
export function sendSms(orderId, type) {
  return Http.get("/admin/order/send_sms", { orderId, type });
}

// 查询改签日志
export function getOrderChangeLogs(orderId) {
  return Http.get("/admin/order/change_logs", { orderId });
}

// 退订座位
export function cancelSeats(orderId) {
  return Http.get("/admin/order/cancel_seats", { orderId });
}
