import { Http } from "@/util";

export function getPermissionTree() {
  return Http.get("/admin/permission/gettreeperm");
}

export function changePermission(data) {
  return Http.post("/admin/permission/saveorupdate", data);
}

export function deletePermission(data) {
  return Http.post("/admin/permission/delete", data);
}
