<template>
  <el-drawer
    :visible.sync="visible"
    direction="ttb"
    :with-header="false"
    custom-class="drawer-modal"
    @opened="showMap"
    :show-close="false"
    :before-close="beforeClose"
    :withHeader="false"
  >
    <div class="header">
      <span class="info" v-if="routeInfo"
        >{{ routeInfo.distance | mileFilter }}，{{ routeInfo.time | timeFilter }}

        <span class="arrive-time">预计{{ routeInfo.time | dateTime }}到达</span>
      </span>

      <span class="info" style="margin-left: 10px" v-if="sortMode">{{ orderSortStr }}</span>
    </div>

    <div id="routeMap" class="map-b"></div>

    <div class="result setting right">
      <el-form>
        <el-form-item>
          <el-switch
            style="display: block"
            v-model="isStart"
            @change="resetAndShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="订单起点"
            inactive-text="订单终点"
          >
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-switch
            style="display: block"
            v-model="showDetail"
            @change="resetAndShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="显示地址"
            inactive-text="隐藏地址"
          >
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" style="width: 100%" size="small" plain @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
import { DateUtil } from "@/util";
import { getDriverLocation } from "@/api";
const markB = require("../assets/image/mark_b.png");
const carPng = require("../assets/image/car_blue.png");

export default {
  props: {},
  filters: {
    dateTime(val = 0) {
      const now = new Date().getTime();
      const thatTime = new Date(now + val * 1000);
      return DateUtil.format(thatTime, "yyyy-MM-dd hh:mm:ss");
    },
  },
  computed: {
    orderSortStr() {
      const arr = this.orderIndex.map((item) => {
        return `${item + 1}号单`;
      });
      // 正在接单中的排序需要去掉第一个点，第一个是司机
      if (this.isOngoing) {
        arr.splice(0, 1);
      }
      return arr.join(" --> ");
    },
  },
  data() {
    return {
      visible: false,
      isStart: true,
      showDetail: true,
      orders: [],
      selectPassengers: [],
      routeInfo: {},
      orderIndex: [],
      isOngoing: false,
      sortMode: false,
    };
  },
  methods: {
    show(route, orders, isStart) {
      this.route = route;
      this.isStart = isStart;
      this.orders = orders;
      this.visible = true;
    },

    reset() {
      this.sortMode = false;
      this.beforeClose();
      this.showMap();
    },

    resetAndShow() {
      if (this.map) {
        this.map.clearMap();
      }
      this.clearDrivingRoute();
      this.showMap();
    },

    showMap() {
      const map = new AMap.Map("routeMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map = map;
      this.showDriverLocation();

      const orders = this.orders;
      const markers = [];
      orders.forEach((item, index) => {
        const marker = this.createPassengerBaseInfoMarker(item, index);
        marker.on("click", () => {
          const index = marker.getExtData();
          // 规划路线
          if (this.selectCar) {
            this.drawRoute(index);
          }
        });

        markers.push(marker);
      });

      this.markers = markers;
      map.add(markers);
      map.setFitView();
    },

    showDriverLocation() {
      const map = this.map;
      const driverId = this.route.driverId;
      if (!driverId) {
        return;
      }
      getDriverLocation(driverId).then((res) => {
        const { driver, location = {} } = res.data || {};
        const { lat, lng, gpsTime = "", vendor } = location || {};
        const time = gpsTime.substr(5);
        const locationSource = vendor === "jimi" ? "GPS" : "手机";
        if (lat && lng) {
          const position = [lng, lat];
          const carMarker = new AMap.Marker({
            position,
            draggable: true,
            raiseOnDrag: false,
            label: {
              //修改label相对于maker的位置
              direction: "top",
              content:
                "<div class='info'>" + `${driver.no} | ${time} | ${locationSource}` + "</div>",
            },
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), //图标大小
              image: carPng,
              imageSize: new AMap.Size(40, 40),
            }),
          });
          map.add(carMarker);
          map.setFitView();
          this.selectCar = carMarker;
        }
      });
    },

    drawRoute(choosePassenger) {
      // 重置路程信息
      if (this.driving) {
        this.driving.clear();
      }
      this.routeInfo = {};
      const selectPassengers = this.selectPassengers;
      const findIndex = selectPassengers.indexOf(choosePassenger);

      // 移除该点
      if (findIndex > -1) {
        selectPassengers.splice(findIndex, 1);
      } else {
        //加入该点
        selectPassengers.push(choosePassenger);
      }

      // 找到所有选择的marker
      const selectMarkers = selectPassengers.map((item) => this.markers[item]);

      let waypoints = [];

      const total = selectMarkers.length;
      if (total > 1) {
        waypoints = selectMarkers.slice(0, total - 1).map((item) => item.getPosition());
      } else if (total < 1) {
        return;
      }

      const driverLocation = this.selectCar.getPosition();
      const destLocation = selectMarkers[total - 1].getPosition();

      AMap.plugin("AMap.Driving", () => {
        const driving = new AMap.Driving({
          // 驾车路线规划策略，考虑实时路况
          policy: AMap.DrivingPolicy.REAL_TRAFFIC,
          autoFitView: false,
          hideMarkers: true,
          map: this.map,
        });
        driving.search(driverLocation, destLocation, { waypoints }, (status, result) => {
          if (status === "complete") {
            this.routeInfo = result.routes[0];
          }
        });
        this.driving = driving;
      });
    },

    createPassengerBaseInfoMarker(order, index) {
      const {
        depLongitude,
        depLatitude,
        departure,
        destination,
        destLongitude,
        destLatitude,
        showTime,
        telephone,
      } = order || {};
      const address = this.isStart ? departure : destination;
      const position = this.isStart ? [depLongitude, depLatitude] : [destLongitude, destLatitude];

      const subTel = "尾号" + telephone.substr(7);
      const clazz = "info  pointer";

      // 获取行程状态
      const routeStatus = order.routeStatus;
      const routeDetailOpts = this.$store.state.enumMap["ROUTE_DETAIL_STATUS"];
      const routeStr = this.$options.filters.enumTransfer(routeStatus, routeDetailOpts);

      const content = this.showDetail
        ? `<div class="${clazz}"><div>${order.orderIndex + 1}、 ${showTime} （${
            order.number
          }人） ${subTel}</div><div>${address} （${routeStr}）</div></div>`
        : `<div class="${clazz}">${index + 1}、 ${showTime} （${order.number}人） ${subTel}</div>`;
      const marker = new AMap.Marker({
        label: {
          content,
          direction: "top",
        },
        icon: new AMap.Icon({
          image: markB,
        }),
        position,
        clickable: true,
        draggable: true,
        raiseOnDrag: false,
        extData: index,
      });

      return marker;
    },

    clearDrivingRoute() {
      this.selectPassengers = [];
      this.routeInfo = {};
      if (this.driving) {
        this.driving.clear();
      }
    },

    beforeClose(done) {
      if (this.map) {
        this.map.clearMap();
      }
      this.isStart = true;
      this.showDetail = true;
      this.showDriver = true;
      this.selectCar = null;
      this.clearDrivingRoute();
      done && done();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90vh !important;
}

.info {
  font-size: 18px;
  font-weight: bold;
  color: #ff5722;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  min-height: 200px;
  max-height: 90%;
  overflow-y: auto;
  top: 100px;
  right: 0;
  width: 350px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  &.setting {
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    min-height: auto;
  }

  &.right {
    right: 0;
    left: auto;
  }

  &.left {
    left: 0;
    right: auto;
  }

  .el-checkbox {
    color: #fff;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

#routeMap {
  width: 100%;
  height: 85vh;
}

.header {
  margin: 0 10px;
  margin: 0 20px;
  height: 5vh;
  display: block;
  line-height: 5vh;
  text-align: center;

  .arrive-time {
    color: black;
    margin-left: 10px;
  }

  .route-info {
    color: #ff5722;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
