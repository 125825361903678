<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" label-width="80px" :model="form" inline>
        <el-row>
          <el-col>
            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.startDate"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.endDate"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="路线" prop="pathId">
              <el-select
                v-model="form.pathId"
                style="width: 200px"
                @change="query()"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in pathOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="手机号码" prop="telephone">
              <el-input style="width: 200px" v-model="form.telephone" clearable />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="19">
            <el-form-item label="行程状态" prop="orderStatus">
              <el-select
                style="width: 200px"
                v-model="form.orderStatus"
                clearable
                @change="query()"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in orderStatusOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="订单渠道">
              <el-select
                v-model="form.orderSource"
                placeholder="请选择渠道"
                style="width: 200px"
                @change="query()"
                clearable
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item in orderSourceOpts"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="支付状态" @change="query()" prop="payStatus">
              <el-select
                style="width: 200px"
                clearable
                v-model="form.payStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in payStatusOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" style="width: 80px" @click="query()">搜索</el-button>
              <el-button type="primary" style="width: 80px" @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column prop="id" width="120" label="订单编号"></el-table-column>

        <el-table-column prop="pathId" width="100" label="路线">
          <template slot-scope="scope">
            <span>{{ scope.row.pathId | pathNameTransfer }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="orderSource" width="100" label="渠道">
          <template slot-scope="scope">
            <span v-order-source="scope.row.orderSource"></span>
          </template>
        </el-table-column>
        <el-table-column prop="dateTime" width="185" label="用车时间">
          <template slot-scope="scope">
            <span>{{ scope.row.date }} &nbsp;{{ scope.row.showTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" width="80" label="订票数量">
          <template slot-scope="scope">
            <span>{{ scope.row | orderTypeTransfer }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="乘车人电话" width="160">
          <template slot-scope="scope">
            <tel-cell :order="scope.row" />
          </template>
        </el-table-column>
        <el-table-column prop="price" label="总价" width="100">
          <template slot-scope="scope">
            <span v-operate-order:bill="scope.row.id">{{ scope.row.price | moneyFormat }}元</span>
          </template>
        </el-table-column>
        <el-table-column label="司机" prop="driverNo">
          <template slot-scope="scope">
            <div>{{ scope.row.driverNo }}</div>
            <div>{{ scope.row.driverName | encryptName("师傅") }}</div>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" width="90">
          <template slot-scope="scope" v-if="scope.row.vehicleNo">
            <div>{{ scope.row.seats }}座</div>
            <div>{{ scope.row.vehicleNo }}</div>
          </template>
        </el-table-column>
        <el-table-column width="300" label="出发地/目的地">
          <template slot-scope="scope">
            <div>
              <div class="start cuIcon-title text-blue" @click="showPassengetMap(scope.row, true)">
                {{ scope.row.departure }}
              </div>
              <div class="end cuIcon-title text-orange" @click="showPassengetMap(scope.row, false)">
                {{ scope.row.destination }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态">
          <template slot-scope="scope">{{
            scope.row.orderStatus | enumTransfer(orderStatusOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="evaluationScore" label="订单评价">
          <template slot-scope="scope">
            <span v-if="scope.row.evaluationScore">{{ scope.row.evaluationScore }}星</span>
          </template>
        </el-table-column>

        <el-table-column prop="psgMessage" label="乘客留言" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="adminMessage" width="150" label="客服留言" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态">
          <template slot-scope="scope">
            <span>{{ scope.row.payStatus | enumTransfer(payStatusOpts) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="id" fixed="right" width="150" label="操作" align="right">
          <template slot-scope="scope">
            <span v-goRouteDetail="scope.row.id">详情</span>

            <el-dropdown
              @command="(command) => handleCommand(command, scope.row)"
              style="margin-left: 10px"
              class="dropdown-meun"
            >
              <span class="el-dropdown-link">
                其他操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="2"
                  :disabled="
                    scope.row.orderStatus === 'CANCEL' || scope.row.orderStatus === 'FINISH'
                  "
                >
                  <span>取消订单</span>
                </el-dropdown-item>

                <el-dropdown-item command="6">显示下单地址</el-dropdown-item>
                <el-dropdown-item command="4">红包已发</el-dropdown-item>
                <el-dropdown-item command="5">交易明细</el-dropdown-item>
                <el-dropdown-item command="1">操作日志</el-dropdown-item>
                <el-dropdown-item command="7">拉黑司机</el-dropdown-item>
                <el-dropdown-item command="8">行程质检</el-dropdown-item>
                <el-dropdown-item>
                  <span
                    v-goRouteReplay="{
                      id: scope.row.routeId,
                      orderId: scope.row.id,
                    }"
                    >行程回放</span
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ParamUtil } from "@/util";
import { getOrderDetailList, cancelOrder, modifyAdminMessage, saveBlackDriver } from "@/api";
import { mapState } from "vuex";
import { SHOW_BOOK_ADDRESS_MAP, SHOW_PASSENGER_MAP } from "@/core/const/global-event";
import Router from "@/route";

export default {
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      orderStatusOpts: (state) => state.enumMap["ORDER_STATUS"],
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      orderSourceOpts: (state) => state.enumMap["ORDER_SOURCE"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  created() {
    const telephone = this.$route.query.tel;
    if (telephone) {
      this.$set(this.form, "telephone", telephone);
    }
    this.query();
  },
  data() {
    return {
      form: {},
      pageSize: 10,
      list: [],
      total: 0,
      currentPage: 1,
    };
  },

  methods: {
    reset() {
      this.$refs.form.resetFields();
    },

    query(page = 1) {
      const formParam = ParamUtil.filterEmptyData(this.form);
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: formParam,
      };
      getOrderDetailList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showPassengetMap(item, isStart) {
      const param = {
        type: 1,
        order: item,
        isStart,
      };
      this.$bus.$emit(SHOW_PASSENGER_MAP, param);
    },

    saveBlack(order) {
      if (!order.driverId) {
        this.$message.error("该订单没有指派司机，无法拉黑");
        return;
      }
      this.$prompt("请输入拉黑原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\r\w\W\S?!]{5,}$/,
        inputErrorMessage: "请输入拉黑原因(最少5个字)",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          const param = {
            driverId: order.driverId,
            orderId: order.id,
            passengerId: order.passengerId,
            remark: value,
          };
          saveBlackDriver(param).then(() => {
            this.$message.success("操作成功");
          });
        })
        .catch(() => {});
    },

    handleCurrentChange(page) {
      this.query(page);
    },
    handleCommand(command, item) {
      command = parseInt(command);
      switch (command) {
        case 1:
          this.$orderLog().show(item.id);
          break;
        case 2:
          this.cancel(item);
          break;
        case 4:
          this.clearRedpack(item);
          break;
        case 5:
          this.$orderTransaction().show(item.id);
          break;
        case 6:
          this.showBookAddress(item);
          break;
        case 7:
          this.saveBlack(item);
          break;
        case 8:
          this.go2QcPage(item.routeId);
          break;
      }
    },

    go2QcPage(routeId) {
      if (!routeId) {
        this.$message.error("没有行程");
        return;
      }
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId },
      });
      window.open(href, "_blank");
    },

    showBookAddress(item) {
      this.$bus.$emit(SHOW_BOOK_ADDRESS_MAP, item);
    },

    clearRedpack(item) {
      this.$confirm("确认吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        item.adminMessage = item.adminMessage.replace("红包未发", "红包已发");
        modifyAdminMessage(item).then(() => {
          this.query();
        });
      });
    },

    cancel(item) {
      this.$prompt("请输入取消原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\r\w\W\S?!]{5,}$/,
        inputErrorMessage: "请输入取消原因(最少5个字)",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          const param = {
            orderId: item.id,
            cancelDesc: value,
          };
          cancelOrder(param).then(() => {
            this.$message.success("订单取消成功");
            if (this.onCancel) {
              this.onCancel();
            }

            this.query();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.cond {
  width: 100%;
  padding: 10px 0;
  border-bottom: 6px solid #adc6ff;
  box-sizing: border-box;
}

.table {
  margin: 20px 0;
  padding-right: 20px;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
