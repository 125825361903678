<template>
  <div class="root-box">
    <div>
      <div class="table">
        <el-table
          :data="list"
          stripe
          max-height="650"
          ref="multipleTable"
          :row-class-name="tableRowClassName"
          :span-method="objectSpanMethod"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            show-overflow-tooltip
            width="50"
          ></el-table-column>

          <el-table-column prop="date" width="100" label="日期"></el-table-column>
          <el-table-column prop="showTime" width="120" label="时间"></el-table-column>
          <el-table-column prop="orderSource" width="100" label="渠道">
            <template slot-scope="scope">
              <div v-order-source="scope.row.orderSource"></div>
              <vip-label :vip="scope.row.vip" />
            </template>
          </el-table-column>

          <el-table-column prop="number" width="60" label="订票">
            <template slot-scope="scope">
              <div>
                {{ scope.row | orderTypeTransfer }}
              </div>
              <div v-if="scope.row.togetherPwd" class="together">
                {{ scope.row.togetherPwd }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="总价" width="100">
            <template slot-scope="scope">
              <span v-operate-order:bill="scope.row.id">{{ scope.row.price | moneyFormat }}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="telephone" width="130" label="手机">
            <template slot-scope="scope">
              <tel-cell :order="scope.row" />
            </template>
          </el-table-column>

          <el-table-column prop="telephone" width="70">
            <template slot-scope="scope">
              <call-status :type="scope.row.calledStatus" :order-id="scope.row.id" />
            </template>
          </el-table-column>

          <el-table-column prop="departure" label="起点">
            <template slot-scope="scope">
              <span class="pointer" @click="showPassengetMap(scope.row, true)">{{
                scope.row.departure
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="destination" label="终点">
            <template slot-scope="scope">
              <span class="pointer" @click="showPassengetMap(scope.row, false)">{{
                scope.row.destination
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="psgMessage" width="130" label="乘客留言">
            <template slot-scope="scope">
              <limit-text :width="150" :text="scope.row.psgMessage"></limit-text>

              <limit-text
                :width="150"
                :text="scope.row.baggageRemark | baggagesRemarkFilter"
              ></limit-text>

              <el-button
                v-if="scope.row.baggageAudit === 1"
                type="text"
                size="small"
                @click="aduitBag(scope.row, false)"
                >审核行李</el-button
              >
              <el-button
                v-if="scope.row.baggageAudit === 2"
                type="text"
                size="small"
                @click="aduitBag(scope.row, true)"
                >查看行李</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="司机备注">
            <template slot-scope="scope">
              <limit-text :width="130" :text="scope.row.remark"></limit-text>
            </template>
          </el-table-column>
          <el-table-column prop="adminMessage" label="客服备注">
            <template slot-scope="scope">
              <limit-text :width="130" :text="scope.row.adminMessage"></limit-text>
              <span v-if="scope.row.sysTipMessage">{{ scope.row.sysTipMessage }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="payStatus" width="80" label="支付状态">
            <template slot-scope="scope">
              <span :class="{ red: scope.row.payStatus === 0 }">{{
                scope.row.payStatus | enumTransfer(payStatusOpts)
              }}</span>
            </template>
          </el-table-column>

          <el-table-column width="100" prop="createTime" label="下单时间"> </el-table-column>

          <el-table-column prop="id" label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openDriverModal(scope.row)"
                >分派</el-button
              >

              <el-dropdown @command="(command) => handleCommand(command, scope.row)">
                <span class="el-dropdown-link">
                  其他
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.orderType === 4" command="1"
                    >取消订单</el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="2"
                    >改签/退票</el-dropdown-item
                  >
                  <el-dropdown-item command="6">留言</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="3"
                    >撤回</el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="4"
                    >发送短信</el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="9"
                    >显示下单地址</el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="5"
                    >操作日志</el-dropdown-item
                  >

                  <el-dropdown-item v-if="scope.row.orderType !== 4" command="10"
                    >交易明细</el-dropdown-item
                  >
                  <!-- <el-dropdown-item v-if="scope.row.orderType !== 4" command="11">开罚单</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="slot, prev, pager, next, jumper"
          :total="total"
        >
          <span class="pagination-sum">共{{ list.length }}单， {{ totalPerson }}人 </span>
        </el-pagination>
      </div>
    </div>

    <order-cancel ref="cancelModal" :onCancel="handleCurrentChange" />
    <tel-order-modify-modal ref="modifyModal" :simpleMode="simpleMode" :disableMsg="disableMsg" />
  </div>
</template>

<script>
import { getOrderList } from "@/api";
import TelCell from "@/components/TelCell";
import { mapState } from "vuex";
import _ from "lodash";
import {
  SHOW_PASSENGER_MAP,
  SHOW_DISPATCH_MAP,
  SHOW_BOOK_ADDRESS_MAP,
  SHOW_CHANGE_ORDER_MODAL,
  SHOW_PUNISH_DIAG,
} from "@/core/const/global-event";
import CallStatus from "@/components/CallStatus";

export default {
  filters: {
    baggagesRemarkFilter(val) {
      if (val) {
        return val.substr(1);
      }
      return val;
    },
  },
  components: {
    TelCell,
    CallStatus,
  },
  props: {
    recallOrder: Function,
    dispatchOrder: Function,
    auditBaggage: Function,
    sendSms: Function,
    showTransactionDetail: Function,
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
    }),
  },
  data() {
    return {
      list: [],
      pageSize: 100,
      currentPage: 1,
      total: 0,
      currentOrder: null,
      routeLoading: false,
      selectOrderIds: [],
      selectOrders: [],
      selectRoute: null,
      totalPerson: 0,
      simpleMode: false,
      disableMsg: false,
      baggageVisible: false,
      bagImgs: [],
      viewMode: true,
      warnReasonOpt: ["恶意订单", "风险订单"],
    };
  },
  methods: {
    query(page = 1, param = this.param || {}) {
      this.param = param;
      param.orderStatus = "ACTIVE";
      const query = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param,
      };
      this.$bus.$emit(SHOW_DISPATCH_MAP, false);
      getOrderList(query).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};

        // 给每一行标色
        const map = {};

        // 总人数
        let totalPerson = 0;
        records.forEach((item) => {
          const ticketId = item.ticketId;
          totalPerson += item.number;
          if (map[ticketId]) {
            map[ticketId].push(item);
          } else {
            map[ticketId] = [item];
          }
        });

        this.totalPerson = totalPerson;

        let index = 0;
        _.forEach(map, (values) => {
          // 区分背景色
          values.forEach((item) => {
            item.className = index % 2 === 0 ? "row1" : "row2";
          });
          values[0].rowspan = values.length;
          index++;
        });

        this.list = records;

        this.currentPage = current;
        this.total = total;
      });
    },

    showBookAddress(item) {
      this.$bus.$emit(SHOW_BOOK_ADDRESS_MAP, item);
    },

    aduitBag(item, viewMode) {
      this.auditBaggage(item, viewMode);
    },

    showPassengetMap(item, isStart) {
      const param = {
        type: 1,
        order: item,
        isStart,
      };
      this.$bus.$emit(SHOW_PASSENGER_MAP, param);
    },

    showDispatchMap() {
      if (!this.param.pathId) {
        this.$message.error("请先选择路线");
        return;
      }
      const orders = this.selectOrders.length > 0 ? this.selectOrders : this.list;
      this.$bus.$emit(SHOW_DISPATCH_MAP, true, orders, this.dispatchOrders);
    },

    openDriverModal(item) {
      if (this.selectOrderIds.indexOf(item.id) < 0) {
        this.$refs.multipleTable.toggleRowSelection(item);
      }

      // 校验是否选择的是相同路线的行程
      const demo = this.selectOrders[0];
      const others = this.selectOrders.filter((item) => item.pathId != demo.pathId);
      if (others.length > 0) {
        this.$message.error("所选订单存在行程不一致的情况，无法同时分派");
        return;
      }

      this.dispatchOrder(this.selectOrderIds, demo.pathId);
    },

    dispatchOrders(orders = []) {
      if (orders.length < 1) {
        return;
      }
      const demo = orders[0];
      const others = orders.filter((item) => item.pathId != demo.pathId);
      if (others.length > 0) {
        this.$message.error("所选订单存在行程不一致的情况，无法同时分派");
        return;
      }

      const ids = orders.map((item) => item.id);
      this.dispatchOrder(ids, demo.pathId);
    },
    handleCurrentChange(page) {
      this.query(page);
    },
    openCancelModal(item) {
      this.$refs.cancelModal.open(item.id);
    },
    openUpdateModal(item) {
      // this.simpleMode = false;
      // this.disableMsg = true;
      // this.$refs.modifyModal.open(item);
      this.$bus.$emit(SHOW_CHANGE_ORDER_MODAL, item);
    },
    openMsgModal(item) {
      this.simpleMode = true;
      this.disableMsg = false;
      this.$refs.modifyModal.open(item);
    },
    handleCommand(command, item) {
      command = parseInt(command);
      switch (command) {
        case 1:
          this.openCancelModal(item);
          break;
        case 2:
          this.openUpdateModal(item);
          break;
        case 3:
          this.recallOrder(item);
          break;
        case 4:
          this.sendSms(item.id);
          break;
        case 5:
          this.$orderLog().show(item.id);
          break;
        case 6:
          this.openMsgModal(item);
          break;
        case 9:
          this.showBookAddress(item);
          break;
        case 10:
          this.showTransactionDetail(item);
          break;
        case 11:
          this.createPunishBill(item);
          break;
      }
    },

    createPunishBill(order) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, { order });
    },

    handleSelectionChange(val) {
      this.selectOrders = val;
      this.selectOrderIds = val.map((item) => item.id);
    },

    getSelectedOrders() {
      return this.selectOrders;
    },

    objectSpanMethod({ row, column }) {
      const fieldArr = ["date", "showTime"];
      if (fieldArr.indexOf(column.property) > -1) {
        return {
          rowspan: row.rowspan || 0,
          colspan: 1,
        };
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },

    tableRowClassName({ row }) {
      return row.className;
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-link {
  margin-left: 10px;
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}

.pagination-sum {
  color: #f56c6c;
  margin: 0 10px;
}

.together {
  font-size: 12px;
  color: red;
}
</style>
