<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="金额：">
          <el-input-number
            style="width: 200px"
            v-model="param.amount"
            @change="query()"
            clearable
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否兑换：">
          <el-select
            style="width: 200px"
            v-model="param.state"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in useOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >生成兑换码</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="exchangeCode" label="兑换码"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>

        <el-table-column prop="pathIds" label="路线">
          <template slot-scope="scope">
            <div v-if="!scope.row.pathIds">全线路通用</div>
            <div v-else v-for="item in scope.row.pathIds" :key="item">
              {{ item | pathNameTransfer }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="validDays" label="有效期"></el-table-column>
        <el-table-column prop="validOff" label="是否兑换" sortable>
          <template slot-scope="scope">
            <div>{{ scope.row.state ? "未兑换" : "已兑换" }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="closeTime"
          label="兑换截止日期"
        ></el-table-column>

        <el-table-column prop="exchangeTime" label="兑换日期"></el-table-column>
        <el-table-column prop="createTime" label="创建日期"></el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="创建兑换码"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="生成个数：" prop="createNum">
          <el-input-number
            v-model="form.createNum"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="优惠路线：">
          <el-select multiple v-model="form.pathIds" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="优惠券有效期：" prop="validDays">
          <el-input-number
            v-model="form.validDays"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="兑换截止日期：" prop="validOff">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.closeTime"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="优惠金额：" prop="amount">
          <el-input-number
            v-model="form.amount"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <span class="red">注意：路线不选择则为通用优惠券</span>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="genCodes"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {queryExchangeCode, genExchangeCode} from '@/api';
import {Toolkit, DateUtil} from '@/util';
import {mapState} from 'vuex';

export default {
  filters: {

    expired (val) {
      const today = DateUtil.getDayStr(0, 'yyyy-MM-dd hh:mm:ss');
      if (val < today) {
        return '已过期';
      } else {
        return '未过期';
      }
    }
  },
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },

  data () {
    return {
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      useOpts: [{
        label: '未兑换',
        value: true
      }, {
        label: '已兑换',
        value: false
      }],
      visible: false,
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: Toolkit.filterEmpty(this.param)
      };
      queryExchangeCode(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },

    openForm () {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }

      this.form = {};
      this.visible = true;
    },

    genCodes () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = Toolkit.filterEmpty({...this.form});
        genExchangeCode(param).then(() => {
          this.$message.success('生成兑换码成功');
          this.visible = false;
          this.query();
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
</style>