<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="手机号码：">
          <el-input v-model="param.telephone" />
        </el-form-item>

        <el-form-item label="状态：">
          <el-select
            @change="query()"
            clearable
            v-model="param.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报警时间：">
          <el-date-picker
            placeholder="选择时间"
            v-model="param.startTime"
            value-format="yyyy-MM-dd"
          ></el-date-picker>

          至
          <el-date-picker
            placeholder="选择时间"
            v-model="param.endTime"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="callTime" label="报警时间" width="160px">
        </el-table-column>

        <el-table-column prop="vehicleNo" label="车牌号" width="100px">
        </el-table-column>

        <el-table-column prop="driverName" label="司机姓名" width="80px">
        </el-table-column>

        <el-table-column prop="driverTel" label="司机手机号" width="120px">
          <template slot-scope="scope">
            <span v-click-tel="scope.row.driverTel">
              {{ scope.row.driverTel | encryptTel }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="passengerName" label="乘客姓名" width="80px">
        </el-table-column>
        <el-table-column prop="passengerTel" label="乘客手机号" width="120px">
          <template slot-scope="scope">
            <span v-click-tel="scope.row.passengerTel">
              {{ scope.row.passengerTel | encryptTel }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="报警地点"> </el-table-column>
        <el-table-column prop="departure" label="行程起点"> </el-table-column>
        <el-table-column prop="destination" label="行程终点"> </el-table-column>
        <el-table-column prop="status" label="处理状态">
          <template slot-scope="scope">
            {{ scope.row.status | enumTransfer(statusOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="处理结果"> </el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="scope.row.status === 1"
              size="small"
              @click="deal(scope.row)"
              >处理</el-button
            >
            <el-button
              type="text"
              size="small"
              v-goRouteDetail="scope.row.orderId"
              >订单详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="报警处理"
      :visible="dealVisible"
      width="600px"
      :before-close="cancelDeal"
    >
      <el-form
        ref="dealForm"
        :model="dealForm"
        label-width="80px"
        size="small"
        :rules="dealRules"
        label-position="top"
      >
        <el-form-item label="处理状态：" prop="status">
          <el-select v-model="dealForm.status" placeholder="请选择">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处理结果：" prop="remark">
          <el-input v-model="dealForm.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" size="small" @click="saveDeal"
          >保存</el-button
        >
        <el-button type="info" @click="cancelDeal" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {getAlarmList, dealAlarm} from '@/api';
import {ParamUtil} from '@/util';
import {mapState} from 'vuex';

export default {
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      statusOpts: state => state.enumMap['COMPLAINT_STATUS'],
    }),
  },
  data () {
    return {
      param: {
        name: ''
      },
      dealForm: {},
      dealVisible: false,
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      dealRules: {
        status: [
          { required: true, message: '请选择处理状态', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请录入处理结果', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {

    query (page = 1) {

      const param = {
        page: {
          current: page,
          size: this.pageSize
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: ParamUtil.filterEmptyData(this.param)
      };
      getAlarmList(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    deal (item) {
      this.current = item;
      this.dealVisible = true;
    },
    saveDeal () {
      const param = {
        ...this.dealForm,
        id: this.current.id
      };
      dealAlarm(param).then(() => {
        this.$message.success('处理成功');
        this.dealVisible = false;
        this.query();
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },
    cancelDeal () {
      this.dealVisible = false;
    }

  }
};
</script>

<style scoped lang="less">
.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
