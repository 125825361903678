<template>
  <div v-if="qcTask" class="root-box">
    <div class="tab-box">
      <div
        class="tab"
        @click="chooseItem(item)"
        :class="getClass(item)"
        v-for="item in qcItemList"
        :key="item.setting.id"
      >
        <span slot="label"> {{ item.setting.name }}</span>
      </div>
      <div class="rule-box">
        <div class="rule">质检原则： {{ checkItem.setting.description }}</div>

        <div>
          <el-button type="primary" size="small" @click="submitPunishBill"> 开罚单 </el-button>
        </div>
      </div>
    </div>

    <div class="content1" v-if="checkItem">
      <div>
        <div class="check-list">
          <div
            class="item"
            :class="{ active: !item.result }"
            v-for="(item, index) in checkItem.qcDetailList"
            :key="item.id"
          >
            <span style="margin-right: 10px">
              <span v-if="checkItem.setting.checkTarget === 'ORDER'">{{
                item.orderId | orderMark(orderMap, true)
              }}</span>
              <span v-if="checkItem.setting.checkTarget === 'ROUTE'">行程：</span>
            </span>
            <el-radio
              @input="check(true, item, index)"
              :value="item.result"
              :disabled="item.result !== undefined"
              :label="true"
              >合格</el-radio
            >
            <el-radio
              @input="check(false, item, index)"
              :value="item.result"
              :disabled="item.result !== undefined"
              :label="false"
              >不合格</el-radio
            >
          </div>
        </div>
      </div>
      <div class="order-box">
        <div v-for="item in sortedOrderList" :key="item.id">
          <el-tag
            :type="item.orderStatus === 'CANCEL' ? 'info' : 'success'"
            @click="sortData(item.id)"
            :effect="selected.includes(item.id) ? 'dark' : 'plain'"
          >
            {{ item.id | orderMark(orderMap, true) }}
          </el-tag>
        </div>
        <div class="route-info">
          <span>
            行程详情： 司机 {{ route.driverNo }} &nbsp;&nbsp;{{
              route.pathId | pathNameTransfer
            }}&nbsp;&nbsp; {{ route.date | date }}&nbsp;&nbsp;{{ route.showTime }}&nbsp;&nbsp;
            {{ route.used }}/{{ route.number }}人
          </span>

          <span class="hide-map" @click="showMap = !showMap">{{
            showMap ? "收起地图" : "展开地图"
          }}</span>
        </div>
      </div>
    </div>

    <div class="content2">
      <div class="left-box">
        <el-timeline>
          <el-timeline-item
            placement="top"
            :color="item.color || '#0bbd87'"
            :timestamp="item.time"
            v-for="item in timeLine"
            :key="item.index"
            :class="{ active: item.index === selectedIndex }"
          >
            <template slot="dot">
              <div
                class="dot"
                :class="{ big: item.index === selectedIndex }"
                :style="{ backgroundColor: item.color || '#0bbd87' }"
              >
                {{ item.index + 1 }}
              </div>
            </template>
            <div class="time-clicker pointer" @click="selectTimeLine(item)"></div>

            <div class="box">
              <div class="id" @click="sortData(item.orderId)">
                {{ item.orderId | orderMark(orderMap, false) }}
              </div>
              <div>
                <div v-if="item.type === typeEnum.cancelOrder">
                  {{ item.content }}
                </div>
                <div v-else-if="item.type === typeEnum.tel" class="flex">
                  <div>{{ item.content }}</div>
                  <div v-if="item.recordUrl">
                    <el-button type="text" v-if="!item.recordUrl2" @click="downloadRecord(item)"
                      >下载录音</el-button
                    >
                    <div v-else>
                      <audio
                        class="record-audio"
                        :src="item.recordUrl2"
                        controls="controls"
                      ></audio>
                      <div>
                        <div
                          class="btn"
                          :class="{ disabled: rcMap[item.data.icid] }"
                          @click="
                            saveResource(
                              'CALL_RECORDING',
                              item.recordUrl,
                              item.data.icid,
                              item.orderId
                            )
                          "
                        >
                          {{ rcMap[`CALL_RECORDING_${item.data.icid}`] ? "已标记" : "标记" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="item.type === typeEnum.message">
                  <div>{{ item.content }} : {{ item.data.smsContent }}</div>
                </div>
                <div v-else-if="item.type === typeEnum.dispatchOrder" class="flex">
                  <span> {{ item.content }} </span>
                  <span class="btn">
                    <el-button type="text" @click="showLog(item.orderId)">操作日志</el-button>
                  </span>
                </div>
                <div v-else>
                  {{ item.content }}
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>

      <div class="right-box">
        <div id="map" v-show="showMap"></div>

        <div class="monitor-box">
          <div class="group">
            <el-radio-group v-model="monitorType" size="small">
              <el-radio-button :label="1">定时抓拍</el-radio-button>
              <el-radio-button :label="2">服务节点抓拍</el-radio-button>
              <el-radio-button :label="3">报警抓拍</el-radio-button>
              <el-radio-button :label="5">接打电话</el-radio-button>
              <el-radio-button :label="4">人工抓拍</el-radio-button>
            </el-radio-group>
          </div>

          <div>
            <ResourceList
              :dataList="monitorShowList"
              :showMarker="showMarker"
              :route="route"
              :rcMap="rcMap"
              :updateRcMap="updateRcMap"
              :showAllChannel="monitorType === 1 || monitorType === 2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDriverTrack,
  getQcResult,
  saveQcResource,
  checkQcItem,
  getRoutePlan,
  getCarStopList,
} from "@/api";
import _ from "lodash";
import moment from "moment";
import { mapState } from "vuex";
import { DateUtil, OssUtil, Http, Toolkit } from "@/util";
import ResourceList from "./ResourceList";
import { SHOW_PUNISH_DIAG } from "@/core/const/global-event";

const sfz = require("@/assets/image/sfz.png");
const redFlag = require("@/assets/image/redFlag.png");
const carPng = require("@/assets/image/car_blue.png");
// slider分段数
const num = 8;
const typeEnum = {
  message: 1,
  tel: 2,
  detail: 3,
  dispatchOrder: 4,
  cancelOrder: 5,
  alarm: 6,
};

export default {
  components: {
    ResourceList,
  },
  props: {},
  filters: {
    orderMark(value, orderMap, showTel) {
      if (value) {
        const order = orderMap[value];
        return showTel
          ? `【${order.orderIndex + 1}号单-${order.telephone.substr(7)}】`
          : `【${order.orderIndex + 1}号单】`;
      }
      return "";
    },
    date(value) {
      if (value) {
        const date = moment(value);
        return date.format("MM月DD日");
      }
      return "";
    },
  },
  created() {
    this.routeId = this.$route.query.routeId;
    this.init();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      cancelTypeOpts: (state) => state.enumMap["ORDER_CANCEL_TYPE"],
      wrongStartPositionOpts: (state) => state.enumMap["WRONG_POSITION_START"],
      wrongEndPositionOpts: (state) => state.enumMap["WRONG_POSITION_END"],
    }),

    sortedOrderList() {
      // 订单按照itemList的顺序排序
      if (this.orderMap && this.checkItem && this.checkItem.setting.checkTarget === "ORDER") {
        const list = [];
        this.checkItem.qcDetailList.forEach((item) => {
          const orderId = item.orderId;
          list.push(this.orderMap[orderId]);
        });
        return list;
      }
      return this.orderList || [];
    },

    monitorShowList() {
      return this.monitorRecordList.filter((item) => item.type === this.monitorType);
    },
  },
  data() {
    return {
      typeEnum,
      route: {},
      timeLine: [],
      orderList: [],
      checkItem: null,
      qcItemList: [],
      qcTask: null,
      selected: [],
      monitorRecordList: [],
      rcMap: {},
      monitorType: 3,
      showMap: true,
    };
  },
  methods: {
    init() {
      getQcResult(this.routeId).then((res) => {
        const {
          itemList,
          qcTask,
          orderList,
          route,
          logList,
          routeDetailList,
          messageList,
          qcResourceList,
          monitorRecordList,
        } = res.data || {};
        this.qcItemList = itemList;
        this.qcTask = qcTask;
        this.route = route;
        this.logList = logList;
        this.routeDetailList = routeDetailList;
        this.messageList = messageList;
        this.checkItem = itemList[0];

        monitorRecordList.forEach((item) => {
          if (item.isAlarm) {
            // 报警抓拍
            item.type = 3;
          } else if (item.routeStatus === 11) {
            // 接打电话抓拍
            item.type = 5;
          } else if (item.routeStatus) {
            // 服务节点抓拍
            item.type = 2;
          } else if (item.isCapture) {
            // 人工抓拍
            item.type = 4;
          } else {
            // 定时抓拍
            item.type = 1;
          }
        });
        this.monitorRecordList = monitorRecordList;

        const orderMap = {};
        orderList.forEach((order) => {
          const orderId = order.id;
          orderMap[orderId] = order;
        });

        const rcMap = {};
        qcResourceList.forEach((rc) => {
          const oriResourceId = rc.oriResourceId;
          rcMap[`${rc.bizType}_${oriResourceId}`] = rc;
        });

        // 从行程详情中找到出发时间和到达时间
        const timeList = [];
        routeDetailList.forEach((item) => {
          if (item.startTime) {
            timeList.push(item.startTime);
          }
          if (item.finishTime) {
            timeList.push(item.finishTime);
          }
        });
        // 时间排序
        timeList.sort((a, b) => a.localeCompare(b));

        // 行程开始时间提前30分钟
        this.routeStartTime = moment(timeList[0])
          .subtract(30, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        const routeEndTime = timeList[timeList.length - 1];
        // 结束时间推后30分钟
        this.routeEndTime = moment(routeEndTime).add(30, "minutes").format("YYYY-MM-DD HH:mm:ss");

        this.orderMap = orderMap;
        this.rcMap = rcMap;
        this.orderList = orderList;
        this.genTimeLine(res.data);
        setTimeout(() => {
          this.initMap();
        }, 0);
      });
    },

    showMarker(point) {
      if (this.carMarker && this.instance) {
        this.instance.remove(this.carMarker);
      }
      this.carMarker = new AMap.Marker({
        position: point,
        icon: new AMap.Icon({
          size: new AMap.Size(40, 40), //图标大小
          image: carPng,
          imageSize: new AMap.Size(40, 40),
        }),
        offset: new AMap.Pixel(-20, -20),
        zIndex: 101,
        map: this.instance,
      });

      this.instance.setCenter(point);
    },

    check(result, item, index) {
      if (result) {
        this.$confirm("确认质检合格吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          item.result = true;
          checkQcItem(item).then((res) => {
            const list = this.checkItem.qcDetailList;
            list.splice(index, 1, item);
            this.$set(this.checkItem, "qcDetailList", list);
            const { itemList } = res.data || {};
            this.qcItemList = itemList;
            this.$message.success("操作成功");
          });
        });
      } else {
        // 根据orderId 找到对应的 order
        const order = this.orderMap[item.orderId];
        const param = {
          route: this.route,
          order,
          qc: {
            qcResources: _.values(this.rcMap),
            qcSetting: this.checkItem.setting,
          },
          callback: (punishBillId) => {
            item.result = false;
            item.punishBillId = punishBillId;
            checkQcItem(item).then((res) => {
              const list = this.checkItem.qcDetailList;
              list.splice(index, 1, item);
              this.$set(this.checkItem, "qcDetailList", list);
              const { itemList } = res.data || {};
              this.qcItemList = itemList;
            });
          },
        };
        this.$bus.$emit(SHOW_PUNISH_DIAG, param);
      }
    },

    submitPunishBill() {
      const param = {
        route: this.route,
        orderList: this.orderList,
        qc: {
          qcResources: _.values(this.rcMap),
        },
      };
      this.$bus.$emit(SHOW_PUNISH_DIAG, param);
    },

    async saveResource(bizType, url, oriResourceId, orderId) {
      if (this.rcMap[`${bizType}_${oriResourceId}`]) {
        this.$message.error("已标记");
        return;
      }
      if (bizType === "CALL_RECORDING") {
        this.$prompt("备注，可不填", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            this.saveTelRecord(bizType, url, oriResourceId, orderId, value);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    async saveTelRecord(bizType, url, oriResourceId, orderId, remark) {
      const { id: routeId, driverId } = this.route;
      const fileType = Toolkit.getFileType(url);
      const res = await Http.get(url, null, { responseType: "blob" });
      const blob = res.data;
      // 获取当前日期时间
      const now = DateUtil.format(new Date(), "yyyyMMddhhmmss");
      const fileName = `qc_tel_${driverId}_${now}` + "." + fileType;
      const file = new File([blob], fileName, { type: blob.type });

      const ossClient = await OssUtil.getOssClient("yxmgt-private");
      const fullName = `qc/tel_record/${fileName}`;
      ossClient
        .put(fullName, file)
        .then((res) => {
          const param = {
            fileType,
            url: res.url,
            name: fileName,
            bizType,
            remark,
            oriResourceId,
            oriUrl: url,
            routeId,
            orderId,
            driverId,
          };

          saveQcResource(param).then((res) => {
            if (res.data) {
              this.updateRcMap(`${bizType}_${oriResourceId}`, res.data);
            }
            this.$message.success("保存成功");
          });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },

    updateRcMap(key, value) {
      this.$set(this.rcMap, key, value);
    },

    genTimeLine(data) {
      let timeLine = [];
      const orderMap = this.orderMap;
      const {
        messageList,
        routeDetailList,
        telList,
        evaluationList,
        alarmList,
        complaintList,
        blacklistList,
      } = data;
      const transfer = this.$options.filters.enumTransfer;

      routeDetailList.forEach((detail) => {
        const order = orderMap[detail.id];
        order.routeDetail = detail;
        const {
          startTime,
          startPosition,
          startLongitude,
          startLatitude,
          arriveTime,
          arrivePosition,
          arriveLongitude,
          arriveLatitude,
          depTime,
          depLongitude,
          depLatitude,
          departure,
          destTime,
          destination,
          destLongitude,
          destLatitude,
          finishTime,
          depWrongReason,
          destWrongReason,
        } = detail || {};
        if (startTime) {
          timeLine.push({
            orderId: detail.id,
            type: typeEnum.detail,
            data: order,
            time: startTime,
            content: "出发去接乘客",
            keyPoint: true,
            lat: startLatitude,
            lng: startLongitude,
            addressName: startPosition,
          });
        }
        if (arriveTime) {
          timeLine.push({
            orderId: detail.id,
            type: typeEnum.detail,
            data: order,
            time: arriveTime,
            content: "到达乘客上车点",
            keyPoint: true,
            lat: arriveLatitude,
            lng: arriveLongitude,
            addressName: arrivePosition,
          });
        }
        if (depTime) {
          const content = depWrongReason
            ? `乘客上车，位置偏离：${transfer(depWrongReason, this.wrongStartPositionOpts)}`
            : "乘客上车";
          timeLine.push({
            orderId: detail.id,
            type: typeEnum.detail,
            data: order,
            time: depTime,
            content,
            keyPoint: true,
            lat: depLatitude,
            lng: depLongitude,
            addressName: departure,
            depWrongReason,
            color: depWrongReason ? "orange" : "",
          });
        }
        if (destTime) {
          const content = destWrongReason
            ? `乘客下车，位置偏离：${transfer(destWrongReason, this.wrongEndPositionOpts)}`
            : "乘客下车";
          timeLine.push({
            orderId: detail.id,
            type: typeEnum.detail,
            data: order,
            time: destTime,
            content,
            keyPoint: true,
            lat: destLatitude,
            lng: destLongitude,
            addressName: destination,
            destWrongReason,
            color: destWrongReason ? "orange" : "",
          });
        }
        if (finishTime) {
          timeLine.push({
            orderId: detail.id,
            type: typeEnum.detail,
            data: order,
            time: finishTime,
            content: "完成订单",
          });
        }
      });

      messageList.forEach((message) => {
        const order = orderMap[message.orderId];
        const userTel = message.type === 1 ? order.telephone : order.appointTelephone;
        const content =
          message.calling && message.calling.indexOf(userTel) > -1
            ? "乘客发送短信"
            : "司机发送短信";
        timeLine.push({
          orderId: message.orderId,
          type: typeEnum.message,
          data: message,
          time: message.timeStamp,
          content,
          keyPoint: true,
          color: "#9e9e9e",
          lat: message.lat,
          lng: message.lng,
          addressName: message.address,
        });
      });

      telList.forEach((tel) => {
        let content = tel.direction ? "司机拨打电话" : "乘客拨打电话";
        if (tel.ulFailReason !== 0) {
          content += tel.direction ? "，乘客未接听" : "，司机未接听";
        }
        timeLine.push({
          orderId: tel.orderId,
          type: typeEnum.tel,
          data: tel,
          recordUrl: tel.recordUrl,
          time: tel.callInTime,
          content,
          keyPoint: true,
          color: "#9e9e9e",
          lat: tel.lat,
          lng: tel.lng,
          addressName: tel.address,
        });
      });

      evaluationList.forEach((evaluation) => {
        if (evaluation.evaluateTime) {
          timeLine.push({
            orderId: evaluation.id,
            time: evaluation.evaluateTime,
            content: `乘客评价司机：${evaluation.serviceScore}星  ${
              evaluation.detail ? evaluation.detail : ""
            }`,
            color: evaluation.serviceScore < 4 ? " red" : "",
          });
        }
        if (evaluation.driverEvaluateTime) {
          timeLine.push({
            orderId: evaluation.id,
            time: evaluation.driverEvaluateTime,
            content: `司机评价乘客： ${evaluation.driverEvaluateScore}星  ${
              evaluation.detail ? evaluation.detail : ""
            }`,
            color: evaluation.driverEvaluateScore < 4 ? " red" : "",
          });
        }
      });
      complaintList.forEach((complaint) => {
        timeLine.push({
          orderId: complaint.orderId,
          time: complaint.complaintTime,
          content: `乘客投诉：${complaint.detail ? complaint.detail : ""}`,
          color: "red",
        });
      });
      alarmList.forEach((alarm) => {
        timeLine.push({
          orderId: alarm.orderId,
          time: alarm.callTime,
          type: typeEnum.alarm,
          content: `乘客报警：${alarm.detail ? alarm.detail : ""}`,
          color: "red",
          keyPoint: true,
          lat: alarm.latitude,
          lng: alarm.longitude,
          addressName: alarm.name,
        });
      });

      blacklistList.forEach((blacklist) => {
        timeLine.push({
          orderId: blacklist.orderId,
          time: blacklist.createTime,
          content: `乘客拉黑司机`,
          color: "red",
        });
      });

      timeLine = timeLine.sort((a, b) => a.time.localeCompare(b.time));
      this.oriData = timeLine;
      this.sortData();
    },

    downloadRecord(item) {
      // 设置audio下载地址，避免一上来就全部下载
      item.recordUrl2 = item.recordUrl;
      this.$set(this.timeLine, item.index, item);
    },

    getClass(item) {
      const s1 = `process${item.process}`;
      return {
        [s1]: true,
        active: this.checkItem && this.checkItem.setting.id === item.setting.id,
      };
    },

    chooseItem(item) {
      this.checkItem = item;
    },

    initMap() {
      const map = new AMap.Map("map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.instance = map;
      AMap.plugin(["AMap.Scale", "AMap.Driving", "AMap.MoveAnimation", "AMap.Geocoder"], () => {
        var toolbar = new AMap.Scale();
        this.geocoder = new AMap.Geocoder();
        map.addControl(toolbar);
        this.driving = new AMap.Driving({
          policy: AMap.DrivingPolicy.LEAST_TIME,
          autoFitView: true,
          hideMarkers: true,
          map,
        });
        this.renderOrderMarker();
      });
    },

    sortData(orderId) {
      this.selectedIndex = -1;
      if (orderId) {
        const includes = this.selected.includes(orderId);
        if (includes) {
          this.selected = [];
        } else {
          this.selected = [orderId];
        }
      }

      const timeLine =
        this.selected.length > 0
          ? this.oriData.filter((t) => this.selected.includes(t.orderId))
          : this.oriData;
      timeLine.forEach((t, index) => (t.index = index));
      this.timeLine = timeLine;
    },

    showLog(orderId) {
      this.$orderLog().show(orderId);
    },

    getEndTime(timeLine) {
      const point = timeLine[timeLine.length - 1];
      const date = moment(point.time).add(10, "m");
      return date.format("YYYY-MM-DD HH:mm:ss");
    },

    async renderOrderMarker() {
      const map = this.instance;
      const planRes = await getRoutePlan(this.route.id);

      const queryParam = {
        driverId: this.route.driverId,
        startTime: this.routeStartTime,
        endTime: this.routeEndTime,
      };

      // 查询停车时长
      const stopResp = await getCarStopList(queryParam);

      const { routePlan } = planRes.data;
      const {
        pickOrderList,
        sendOrderList,
        expresswayEntranceLng,
        expresswayEntranceLat,
        expresswayExitLng,
        expresswayExitLat,
        expresswayEntranceName,
        expresswayExitName,
      } = routePlan;

      const carStopList = stopResp.data || [];
      const markers = [];

      const entranceMarker = new AMap.Marker({
        icon: new AMap.Icon({
          image: sfz,
        }),
        position: [expresswayEntranceLng, expresswayEntranceLat],
        label: {
          content: "<div class='info'>" + expresswayEntranceName + "</div>",
          direction: "top",
        },
        anchor: "bottom-center",
      });

      const exitMarker = new AMap.Marker({
        icon: new AMap.Icon({
          image: sfz,
        }),
        position: [expresswayExitLng, expresswayExitLat],
        label: {
          content: "<div class='info'>" + expresswayExitName + "</div>",
          direction: "top",
        },
        anchor: "bottom-center",
      });

      markers.push(entranceMarker);
      markers.push(exitMarker);

      const icon = new AMap.Icon({
        size: new AMap.Size(32, 32), //图标大小
        image: redFlag,
        imageSize: new AMap.Size(32, 32),
      });
      carStopList.forEach((item) => {
        const msg1 = `${item.address}，停车时长${(item.duration / 60).toFixed(0)}分钟`;
        let msg2;
        if (item.endTime) {
          msg2 = `${DateUtil.dateStrFormat(
            item.startTime,
            "hh:mm:ss"
          )}开始停车，${DateUtil.dateStrFormat(item.endTime, "hh:mm:ss")}结束停车`;
        } else {
          msg2 = `${DateUtil.dateStrFormat(item.startTime, "hh:mm:ss")}开始停车，尚未结束停车`;
        }

        const marker = new AMap.Marker({
          draggable: false,
          raiseOnDrag: false,
          position: new AMap.LngLat(item.lng, item.lat),
          label: {
            direction: "top",
            content: `<div class='info stop'><div>${msg1}</div><div>${msg2}</div></div>`,
          },
          anchor: "bottom-center",
          icon,
        });
        markers.push(marker);
      });

      pickOrderList.forEach((item) => {
        const shunt = item.isShunt ? "分流点" : "";
        const marker = new AMap.Marker({
          draggable: false,
          raiseOnDrag: false,
          position: new AMap.LngLat(item.lng, item.lat),
          label: {
            direction: "top",
            content: `<div class='info order${item.orderIndex}'>${item.orderIndex + 1}-${
              item.address
            }-${shunt} </div>`,
          },
        });
        markers.push(marker);
      });

      sendOrderList.forEach((item) => {
        const shunt = item.isShunt ? "分流点" : "";
        const marker = new AMap.Marker({
          draggable: false,
          raiseOnDrag: false,
          position: new AMap.LngLat(item.lng, item.lat),
          label: {
            direction: "top",
            content: `<div class='info order${item.orderIndex}'>${item.orderIndex + 1}-${
              item.address
            }-${shunt} </div>`,
          },
        });
        markers.push(marker);
      });

      map.add(markers);
      map.setFitView();
      this.routeTrack();
    },

    createSilder(startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      const startDate = DateUtil.str2Date(startTime);
      const endDate = DateUtil.str2Date(endTime);
      const startTimeStamp = startDate.getTime();
      const endTimeStamp = endDate.getTime();
      const diff = endTimeStamp - startTimeStamp;

      const range = diff / num;
      const startMoment = moment(startTime);
      const style = {
        color: "#ffffff",
      };
      const marks = {
        0: {
          label: startMoment.format("HH:mm"),
          style,
        },
      };
      for (let index = 1; index <= num; index++) {
        const next = startMoment.add(range, "ms");
        const value = (100 / num) * index;
        marks[value] = {
          label: next.format("HH:mm"),
          style,
        };
      }

      this.marks = marks;
    },

    sliderChange(val) {
      const specPosition = parseInt((val / 100) * this.totalPoint);
      this.specPosition = specPosition;
      const lineArr = this.lineArr.slice(specPosition);
      const map = this.instance;
      map.setCenter(lineArr[0]);
      this.carMarker.moveAlong(lineArr, {
        // 每一段的时长
        duration: 120,
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
      });
      this.resetData(false);
      this.pauseCarMarker();
    },

    selectTimeLine(item) {
      const map = this.instance;

      // 展示坐标点
      if (item.keyPoint && item.lat) {
        const carMarker = new AMap.Marker({
          draggable: true,
          raiseOnDrag: false,
          position: new AMap.LngLat(item.lng, item.lat),
          label: {
            direction: "top",
            content: `<div class='info }'>${item.time}</div>`,
          },
          icon: new AMap.Icon({
            size: new AMap.Size(20, 40), //图标大小
            image: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            imageSize: new AMap.Size(20, 40),
          }),
          offset: new AMap.Pixel(-10, -20),
        });

        if (this.carMarker) {
          map.remove(this.carMarker);
        }
        this.carMarker = carMarker;
        map.add(carMarker);
        map.setFitView([carMarker]);
      }
    },

    routeTrack() {
      const map = this.instance;
      const queryParam = {
        driverId: this.route.driverId,
        startTime: this.routeStartTime,
        endTime: this.routeEndTime,
        vendor: "jimi",
      };

      getDriverTrack(queryParam).then((res) => {
        const {
          locations = [],
          // locationStartTime,
          // locationEndTime,
        } = res.data || {};
        this.locations = locations;
        const lineArr = locations.map((item) => [+item[0], +item[1]]);

        this.driving && this.driving.clear();
        this.polyLine && map.remove(this.polyLine);
        this.passedPolyline && map.remove(this.passedPolyline);
        // 绘制轨迹
        const polyLine = new AMap.Polyline({
          map: map,
          path: lineArr,
          showDir: true,
          strokeColor: "#28F", //线颜色
          strokeOpacity: 0.5, //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });
        this.polyLine = polyLine;
        this.lineArr = lineArr;
      });
    },

    resetData(allReset = true) {
      this.address = null;
      if (allReset) {
        this.totalPoint = 0;
        this.lineArr = [];
        this.specPosition = 0;
        this.process = 0;
      }
    },

    renderMarkers(markers) {
      const map = this.instance;
      if (this.markers) {
        map.remove(this.markers);
      }
      this.markers = markers;
      map.add(markers);
    },

    pauseCarMarker() {
      this.moving = false;
      this.carMarker && this.carMarker.pauseMove();
      this.getAddress();
    },

    resumeCarMarker() {
      this.moving = true;
      this.carMarker && this.carMarker.resumeMove();
    },

    getAddress() {
      if (this.currentPoint) {
        this.geocoder.getAddress([this.currentPoint[0], this.currentPoint[1]], (status, result) => {
          if (status === "complete" && result.regeocode) {
            const address = result.regeocode.formattedAddress;
            this.address = address;
          } else {
            console.error("根据经纬度查询地址失败");
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@mapWidth: 800px;

.root-box {
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  .tab-box {
    display: flex;
    align-items: center;
    display: flex;

    .tab {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .rule-box {
      margin-left: 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex: 1;
      .rule {
        font-weight: bold;
        flex: 1;
      }
    }

    .tab {
      border: 1px solid #ccc;
      color: #fff;
      padding: 5px 20px;
      cursor: pointer;
      font-size: 13px;
      line-height: 26px;

      &.active {
        font-size: 16px;
        font-weight: bold;
        border: 2px solid #409eff;
      }

      &.process0 {
        background-color: white;
        color: #000;
      }

      &.process1 {
        background-color: orange;
      }

      &.process2 {
        background-color: rgb(117, 190, 117);
      }

      &.process3 {
        background-color: #f56c6c;
      }
    }
  }

  .content1 {
    margin-top: 10px;

    .check-list {
      display: flex;
      flex-direction: row;

      .item {
        margin-right: 20px;
        padding: 8px 6px;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ccc;

        > span {
          font-size: 14px;
        }

        .el-radio {
          color: #000;
        }

        &.active {
          color: #000;
          border: 1px solid #ccc;
        }
      }
    }

    .order-box {
      margin: 10px 0 0;
      display: flex;
      align-items: center;

      > div {
        margin-right: 10px;
      }

      .route-info {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .hide-map {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
  }

  .content2 {
    flex: 1;
    margin-top: 10px;
    display: flex;
    height: calc(100% - 200px);

    .left-box {
      width: 400px;
      overflow: auto;
    }

    .right-box {
      flex: 1;
      height: 100%;
      width: 100%;
      font-size: 16px;
      color: #999;
      margin-left: 10px;

      #map {
        height: 400px;
        width: 100%;
      }

      .monitor-box {
        max-height: 700px;
        max-width: 1300px;
        overflow: auto;

        .group {
          margin: 10px 0;
        }
      }
    }

    .dot {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      left: -6px;

      &.big {
        width: 26px;
        height: 26px;
        left: -8px;
      }
    }

    .time-clicker {
      position: absolute;
      height: 24px;
      width: 200px;
      top: 0;
    }

    .el-timeline {
      padding: 10px 0 10px 10px;

      .box {
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;

        .id {
          margin-right: 10px;
          cursor: pointer;
        }

        .btn {
          cursor: pointer;
          color: #409eff;

          &.disabled {
            color: red;
          }
        }
      }

      .el-timeline-item {
        padding-bottom: 10px;
      }

      /deep/ .active {
        .el-timeline-item__timestamp,
        .el-timeline-item__content {
          color: red !important;
        }
      }
    }
  }
}

.record-audio {
  height: 25px;
  width: 240px;
  margin: 4px 0;
}
</style>
