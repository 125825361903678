<template>
<div>
  <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
  >
    <el-menu-item index="1">消息模板</el-menu-item>
    <el-menu-item index="2">待发送通知</el-menu-item>
    <el-menu-item index="3">已发送通知</el-menu-item>
    <el-menu-item index="4">发送统计</el-menu-item>
  </el-menu>

  <NoticeTemplate v-if="activeIndex=='1'"></NoticeTemplate>
  <NoticeRecord v-if="activeIndex=='2'"></NoticeRecord>
  <NoticeSendRecord v-if="activeIndex=='3'"></NoticeSendRecord>
  <NoticeState v-if="activeIndex=='4'"></NoticeState>
</div>
</template>

<script>
import NoticeTemplate from "./NoticeTemplate";
import NoticeRecord from "./NoticeRecord";
import NoticeSendRecord from "./NoticeSendRecord";
import NoticeState from "./NoticeState";
export default {
  name: "index",
  components: {
    NoticeState, NoticeTemplate, NoticeRecord, NoticeSendRecord
  },
  data () {
    return {
      activeIndex: "1"
    };
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key;
    }
  }

};
</script>

<style scoped>
</style>