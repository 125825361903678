<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="路线：">
          <el-select
            style="width: 200px"
            v-model="param.pathId"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="金额：">
          <el-input-number
            style="width: 200px"
            v-model="param.amount"
            @change="query()"
            clearable
          ></el-input-number>
        </el-form-item>

        <el-form-item label="手机">
          <el-input
            style="width: 200px"
            v-model="param.passengerTel"
            @change="query()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="使用状态：">
          <el-select
            style="width: 200px"
            v-model="param.state"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in useOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建日期:">
          <el-date-picker
            style="width: 200px"
            placeholder="选择时间"
            v-model="param.createDate"
            value-format="yyyy-MM-dd"
            clearable
            @change="query()"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >赠送优惠券</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="passengerTel" label="手机">
          <template slot-scope="scope">
            <span v-click-tel>{{ scope.row.passengerTel }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="amount" label="金额"></el-table-column>

        <el-table-column prop="pathIds" label="路线">
          <template slot-scope="scope">
            <div v-if="!scope.row.pathIds">全线路通用</div>
            <div v-else v-for="item in scope.row.pathIds" :key="item">
              {{ item | pathNameTransfer }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="有效时间">
          <template slot-scope="scope">
            <div>{{ scope.row.validOn }}</div>
            <div>{{ scope.row.validOff }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="validOff" label="是否过期" sortable>
          <template slot-scope="scope">
            <div>{{ scope.row.validOff | expired }}</div>
          </template>
        </el-table-column>

        <el-table-column label="使用详情">
          <template slot-scope="scope">{{
            scope.row.state | enumTransfer(useOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建日期"></el-table-column>

        <el-table-column prop="remark" label="备注"></el-table-column>

        <el-table-column prop="exchangeCode" label="兑换码"></el-table-column>

        <el-table-column prop="orderId" label="使用订单">
          <template slot-scope="scope">
            <span v-if="scope.row.orderId" v-goRouteDetail="scope.row.orderId"
              >订单详情</span
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              :disabled="!scope.row.state"
              type="text"
              size="small"
              @click="openForm(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="优惠券"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="乘客手机：" prop="passengerTel">
          <el-input v-model="form.passengerTel" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item label="优惠路线：">
          <el-select multiple v-model="form.pathIds" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间：" prop="validOn">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            value-format="yyyy-MM-dd"
            v-model="form.validOn"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="截止日期：" prop="validOff">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            value-format="yyyy-MM-dd"
            v-model="form.validOff"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="优惠金额：" prop="amount">
          <el-input-number
            v-model="form.amount"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <span class="red">注意：路线不选择则为通用优惠券</span>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listCoupon, addCoupon, modifyCoupon} from '@/api';
import {mapState} from 'vuex';
import {DateUtil, Toolkit} from '@/util';

export default {
  filters: {
    bizStatus: function (value, item) {
      const now = new Date();
      const nowStr = DateUtil.format(now, 'yyyy-MM-dd hh:mm:ss');
      if (item.used >= item.count) {
        return '使用次数用完';
      } else if (item.validOn > nowStr) {
        return '未开始';
      } else if (item.validOff < nowStr) {
        return '已结束';
      } else {
        return '正在使用';
      }
    },
    expired (val) {
      const today = DateUtil.getDayStr(0);
      if (val < today) {
        return '已过期';
      } else {
        return '未过期';
      }
    }
  },
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },


  data () {
    return {
      visible: false,
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      useOpts: [{
        label: '未使用',
        value: true
      }, {
        label: '已使用',
        value: false
      }],
      rules: {

        amount: [
          {required: true, message: '请输入优惠金额', trigger: 'blur'},
        ],
        validOn: [
          {required: true, message: '请选择开始时间', trigger: 'blur'},
        ],
        validOff: [
          {required: true, message: '请选择截止时间', trigger: 'blur'},
        ],

        passengerTel: [
          {required: true, message: '请输入乘客手机号', trigger: 'blur'},
          {pattern: /^1\d{10}$/, message: '请输入正确的手机号'}
        ],
      },
    };
  },
  methods: {

    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: Toolkit.filterEmpty(this.param)
      };
      listCoupon(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange (page) {
      this.query(page);
    },

    openForm (item) {
      this.visible = true;

      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }

      if (item) {
        const form = {...item};
        this.form = form;
      } else {
        this.form = {};
      }

    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const param = Toolkit.filterEmpty({...this.form});

        if (param.validOn > param.validOff) {
          this.$message.error("有效期不正确");
          return;
        }
        if (param.id) {
          modifyCoupon(param).then(() => {
            this.$message.success('优惠券修改成功');
            this.visible = false;
            this.query();
          });
        } else {
          addCoupon(param).then(() => {
            this.$message.success('优惠券新增成功');
            this.visible = false;
            this.query();
          });
        }
      });

    },

  }
};
</script>

<style scoped>
</style>
