<template>
  <div>
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item>
            <el-input v-model="param.name" clearable />
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query(1)"
              >查询</el-button
            >
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="openAdd()"
              >创建模板</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="table">
      <el-table :data="list" stripe size="small" max-height="600" ref="table">
        <el-table-column prop="code" label="模板编号"></el-table-column>
        <el-table-column prop="name" label="模板标题"></el-table-column>
        <el-table-column prop="flCode" label="分类编号">
          <template slot-scope="scope">
            {{ scope.row.flCode | enumTransfer(flCodeOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="mainTask1" label="主要标准1">
          <template slot-scope="scope">
            {{ scope.row.mainTask1.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="mainTask2" label="主要标准2">
          <template slot-scope="scope" v-if="scope.row.mainTask2">
            {{ scope.row.mainTask2.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="任务描述" width="360"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="award" label="奖金"></el-table-column>
        <el-table-column prop="awardNum" label="限制人数"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="id" fixed="right" width="200" label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="copyEdit(scope.row)">复制</el-button>
            <el-button type="text" size="small" @click="createTask(scope.row)">创建任务</el-button>

            <el-button type="text" size="small" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="1200px"
      max-height="600"
      :size="size"
      :destroy-on-close="true"
      :title="form.id ? '修改模板' : '新增模板'"
      :close-on-click-modal="false"
    >
      <el-form ref="form" v-if="visible" :model="form" :size="size" inline>
        <div class="label">主考评项：</div>
        <div class="flex-between">
          <EvaItem v-model="form.mainTask1" :isMainItem="true" />
          <div class="icon" @click="triggerItem2(true)">
            <i class="el-icon-circle-plus"></i>
          </div>
        </div>

        <div class="flex-between" v-if="form.mainTask2">
          <EvaItem v-model="form.mainTask2" :isMainItem="true" />
          <div class="icon" @click="triggerItem2(false)">
            <i class="el-icon-remove"></i>
          </div>
        </div>

        <el-divider></el-divider>
        <div class="label">
          辅考评项：
          <span class="icon-plus" @click="triggerList(index, true)">
            <i class="el-icon-circle-plus"></i>
          </span>
        </div>
        <div
          class="flex-between"
          v-for="(item, index) in form.assistTask"
          :key="item.id || item._id"
        >
          <EvaItem v-model="form.assistTask[index]" :isMainItem="false" />
          <div class="icon" @click="triggerList(index, false)">
            <i class="el-icon-remove"></i>
          </div>
        </div>

        <el-divider></el-divider>
        <div class="label">其他设置：</div>
        <el-form-item prop="pathIds">
          <el-select v-model="form.flCode" placeholder="任务分类">
            <el-option
              v-for="item in flCodeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="模板标题"></el-input>
        </el-form-item>

        <el-form-item prop="award">
          <el-input-number
            style="width: 200px"
            v-model="form.award"
            placeholder="奖金输入"
          ></el-input-number>
        </el-form-item>

        <el-form-item prop="awardNum">
          <el-input-number
            style="width: 200px"
            v-model="form.awardNum"
            placeholder="获奖人数限制"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="pathIds"> 任务总奖金： </el-form-item>

        <el-divider></el-divider>

        <el-form-item label="任务说明：" prop="description">
          <el-input
            type="textarea"
            :rows="4"
            v-model="form.description"
            style="width: 1100px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="remark" label="备注说明：">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="1"
            style="width: 1100px"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="taskVisible"
      width="1200px"
      max-height="600"
      title="发送设置"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="taskFrom" v-if="taskVisible" size="small" inline>
        <RewardTemplateDetail :data="tempForm" />

        <el-form-item label="司机选择：">
          <driver-selector v-model="taskFrom.driverIds" />
        </el-form-item>

        <el-form-item prop="joinAfterStart">
          <el-switch
            v-model="taskFrom.joinAfterStart"
            active-color="#13ce66"
            active-text="开始后仍可参加"
          ></el-switch>
        </el-form-item>

        <el-row>
          <el-form-item label="任务时长：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="taskFrom.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="taskFrom.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="发送时间：" style="margin-left: 60px">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="taskFrom.publishTime"
              type="date"
              placeholder="发送时间："
              @change="publishTimChange"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-radio
              v-model="taskFrom.publishRightnow"
              :label="true"
              @click.native.stop="changeRightNow"
              >立即发送</el-radio
            >
          </el-form-item>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="warning" :size="size" @click="sendTask">确 定</el-button>

        <el-button type="info" :size="size" @click="taskVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EvaItem from "@/pages/DriverScore/Reward/Templete/EvaItem";
import { Toolkit } from "@/util";
import _ from "lodash";
import RewardTemplateDetail from "../RewardTemplateDetail";
import {
  saveAwardTaskTemplate,
  listAwardTaskTemplate,
  deleteAwardTaskTemplate,
  saveAwardTask,
} from "@/api";

const defaultVal = {
  mainTask1: {},
  mainTask2: null,
  assistTask: [{ _id: Toolkit.uuid() }],
};
export default {
  components: {
    EvaItem,
    RewardTemplateDetail,
  },
  props: {
    changeTab: {
      type: Function,
      default: () => {},
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      taskOpts: (state) => state.reward.initData.tasks || [],
      sortOpts: (state) => state.reward.initData.sorts || [],
      assessOpts: (state) => state.reward.initData.assesses || [],
      flCodeOpts: (state) => state.reward.flCodeOpts,
    }),
  },
  data() {
    return {
      visible: false,
      taskVisible: false,
      taskFrom: {},
      form: {},
      rules: {},
      list: [],
      tempForm: null,
      pageSize: 20,
      currentPage: 1,
      total: 0,
      param: {},
    };
  },
  methods: {
    query(pageNo = 1) {
      const param = {
        param: Toolkit.filterEmpty(this.param),
        page: {
          current: pageNo,
          size: this.pageSize,
          orders: [{ column: "create_time", asc: false }],
        },
      };
      listAwardTaskTemplate(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    openAdd() {
      this.form = _.cloneDeep(defaultVal);
      this.visible = true;
    },

    openEdit(item) {
      this.form = _.cloneDeep(item);
      this.visible = true;
    },

    copyEdit(item) {
      this.form = _.cloneDeep(item);
      this.form.id = null;
      this.visible = true;
    },

    createTask(item) {
      this.tempForm = item;
      this.taskVisible = true;
    },

    deleteItem(item) {
      this.$confirm("确定删除该模板吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAwardTaskTemplate(item.id).then(() => {
            this.query();
          });
        })
        .catch(() => {});
    },

    triggerItem2(show) {
      if (show) {
        this.$set(this.form, "mainTask2", {});
      } else {
        this.$set(this.form, "mainTask2", null);
      }
    },

    triggerList(index, isAdd) {
      if (isAdd) {
        if (this.form.assistTask.length > 5) {
          this.$message.error("最多只能添加6项辅考评对象");
          return;
        }
        this.form.assistTask.push({ _id: Toolkit.uuid() });
      } else {
        this.form.assistTask.splice(index, 1);
      }
    },

    saveOrUpdate() {
      saveAwardTaskTemplate(this.form).then(() => {
        this.visible = false;
        this.query();
      });
    },

    publishTimChange() {
      if (this.taskFrom.publishTime) {
        this.$set(this.taskFrom, "publishRightnow", false);
      }
    },

    changeRightNow() {
      this.$set(this.taskFrom, "publishTime", null);
    },

    sendTask() {
      this.$set(this.taskFrom, "templateId", this.tempForm.id);
      saveAwardTask(this.taskFrom).then(() => {
        this.taskVisible = false;
        this.$message.success("操作成功");
        this.changeTab("ref2");
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },
  },
};
</script>
<style lang="less" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;

  .icon {
    font-size: 30px;
    cursor: pointer;
  }
}

.icon-plus {
  font-size: 28px;
  cursor: pointer;
}

.label {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
</style>
