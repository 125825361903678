<template>
  <div class="">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="统计时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="司机编号：" prop="driverNo">
            <el-input v-model="param.driverNo"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <ServiceDetail :value="data" />
  </div>
</template>

<script>
import {getServiceScoreDetail} from '@/api';
import {DateUtil} from '@/util';
import { mapState } from 'vuex';
import ServiceDetail from './detail.vue';

export default {
  components: {
    ServiceDetail
  },
  props: {},
  created () {},
  mounted () {
  },
  watch: {},
  computed: {
    ...mapState({
      vehicleTypeOpts: state => state.vehicleTypeOpts,
      teamOpts: state => state.teamOpts
    })
  },
  data () {
    return {
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
        driverNo: '6011'
      },

      data: null,

    };
  },
  methods: {
    query () {
      if (!this.param.driverNo) {
        this.$message.error('请输入司机编号再查询');
        return;
      }
      getServiceScoreDetail(this.param).then(res=>{
        this.data = res.data || {};
      });
    },

  },
};
</script>
<style lang="less" scoped>
</style>