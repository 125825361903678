<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="号码：" @change="query" prop="teleNo">
            <el-input v-model="param.teleNo" clearable></el-input>
          </el-form-item>

          <el-form-item label="类型：">
            <el-select
              v-model="param.type"
              placeholder="请选择类型："
              @change="query"
              clearable
            >
              <el-option
                v-for="item in prinumTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="行程序号：">
            <el-select v-model="param.routeIndex" clearable @change="query">
              <el-option
                v-for="item in routeIndexOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button
          type="primary"
          :size="size"
          @click="openAdd"
          style="width: 80px"
          >新增</el-button
        >
      </div>
    </div>

    <!-- 修改数据对话框  @close="addDataClosed -->
    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :title="title"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="号码：" prop="teleNo">
          <el-input v-model="form.teleNo"></el-input>
        </el-form-item>
        <el-form-item label="隐号类型：" prop="type">
          <el-select v-model="form.type" style="width: 100%">
            <el-option
              v-for="item in prinumTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单序号：" prop="orderIndex">
          <el-select v-model="form.orderIndex" style="width: 100%">
            <el-option
              v-for="item in 6"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行程序号：" prop="routeIndex">
          <el-select v-model="form.routeIndex" style="width: 100%">
            <el-option
              v-for="item in routeIndexOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>

    <div class="table">
      <el-table :data="pageList" stripe max-height="650">
        <el-table-column
          type="index"
          label="序号"
          show-overflow-tooltip
          width="100"
        ></el-table-column>

        <el-table-column prop="teleNo" label="隐号号码">
          <template slot-scope="scope">
            <span>{{ scope.row.teleNo }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="type" label="隐号类型">
          <template slot-scope="scope">
            <span>{{ scope.row.type | enumTransfer(prinumTypeOpts) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="orderIndex" label="订单序号">
          <template slot-scope="scope">
            <span>{{ scope.row.orderIndex + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="routeIndex" label="行程序号">
          <template slot-scope="scope">
            <span>{{
              scope.row.routeIndex | enumTransfer(routeIndexOpts)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="useNum" label="当前使用总数"></el-table-column>

        <el-table-column prop="opts" label="操作">
          <template slot-scope="scope">
            <span>
              <el-button type="text" size="small" @click="openEdit(scope.row)"
                >修改</el-button
              >
              <el-button type="text" size="small" @click="delItem(scope.row)"
                >删除</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- page-count 跳转几页 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        @size-change="handleSizeChange"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="list.length"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {addPrinum, modifyPrinum, delPrinum, listPrinum} from '@/api';
import _ from 'lodash';
import {Toolkit} from '@/util';
import { mapState } from 'vuex';
const MODE = {
  EDIT: 1,
  ADD: 2
};
const telReg = /^1\d{10}$/;
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? '新增' : '修改';
    },
    ...mapState({
      size: state => state.btnSize,
      prinumTypeOpts: state => state.enumMap['PRINUM_TYPE'],
    }),
    pageList () {
      return this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }
  },
  data () {
    return {
      MODE,
      visible: false,
      delVisible: false,
      mode: MODE.EDIT,
      param: {},
      form: {},
      list: [],
      // 分页
      total: 0,
      currentPage: 1, //默认第一页
      pageSize: 10, //展示10条数据
      routeIndexOpts: [{
        value: 0,
        label: '行程1'
      }, {
        value: 1,
        label: '行程2'
      }],
      rules: {
        teleNo: [
          {required: true, message: '号码不能为空', trigger: 'blur'},
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change"
          }
        ],
        type: [
          {required: true, message: '类型不能为空', trigger: 'blur'},
        ],
        orderIndex: [
          {required: true, message: '订单序号不能为空', trigger: 'blur'},
        ],
        routeIndex: [
          {required: true, message: '行程序号不能为空', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {

    query () {
      listPrinum(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
    },
    closeDiag () {
      this.visible = false;
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.visible = true;
      const _item = _.cloneDeep(item);
      _item.orderIndex = _item.orderIndex + 1;
      this.form = _item;
    },

    delItem (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPrinum ({prinumId: item.id}).then(() => {
          this.$message.success('删除成功');
          this.query();
        });
      });
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {
        state: 1
      };
    },
    addDataClosed () {
      this.$refs.ruleFormRef.resetFields();
    },
    saveOrUpdate () {
      //表单校验结果为false 直接返回
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const data = _.cloneDeep(this.form);
        // orderIndex 实际值从0开始
        data.orderIndex = data.orderIndex - 1;
        if (data.relPathIds) {
          data.relPathIds = data.relPathIds.join(",");
        }
        if (this.mode === MODE.EDIT) {
          modifyPrinum (data).then(() => {
            this.$message.success("修改成功");
            this.visible = false;
            this.query();
          });
        } else {
          addPrinum (data).then(() => {
            this.$message.success("新增成功");
            this.visible = false;
            this.query();
          });
        }

      });

    },
    //每页显示的条数
    handleSizeChange (val) {
      //改变每页显示的条数
      this.pageSize = val;

    },
    // 显示第几页
    handleCurrentChange (val) {
      //改变默认的页数
      this.currentPage = val;

    }

  }
  //methods
};
</script>

<style scoped lang="less">
.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
