import { TOKEN } from "@/core/const/global-const";
import { LocalStorage } from "@/util";
import { Message } from "element-ui";
import StompClient from "./stomp-client";

export class SocketService {
  static getConnection() {
    return new Promise((resolve) => {
      if (SocketService.client && SocketService.client.active) {
        resolve(SocketService.client);
      } else {
        const token = LocalStorage.get(TOKEN);
        if (!token) {
          return;
        }
        const url = `/notification?${TOKEN}=${token}`;
        const stompClient = new StompClient(url);
        stompClient.connect(
          () => {
            Message.success("推送消息连接成功");
            SocketService.client = stompClient;
            resolve(stompClient);
          },
          (error) => {
            console.error("Error connecting to STOMP:", error);
            Message.error("推送消息连接失败");
          }
        );
      }
    });
  }

  static async subscribeTopic(topic, callback) {
    const stompClient = await SocketService.getConnection();
    // eslint-disable-next-line no-unused-vars
    const subscription = stompClient.subscribe(topic, (message) => {
      // console.log("Received message:", JSON.parse(message.body));
      callback(JSON.parse(message.body));
    });

    // Use `subscription` to unsubscribe if necessary
  }

  static disconnect() {
    if (SocketService.client) {
      SocketService.client.disconnect();
    }
  }
}
