<template>
  <div class="root-box">
    <div class="condition-box">
      <div class="c1">
        <el-radio-group v-model="queryType" @change="query" size="medium">
          <el-radio-button v-for="item in queryTypeOpts" :label="item.value" :key="item.value">{{
            item.label
          }}</el-radio-button>
        </el-radio-group>

        <span v-if="queryType === 1 || queryType === 2" class="title">按人数统计</span>
      </div>

      <div class="c2">
        <span v-if="queryType === 1" class="item">
          <el-radio-group fill="#67c23a" v-model="time" @change="query" size="medium">
            <el-radio-button v-for="item in timeOpts" :label="item.value" :key="item.value">{{
              item.label
            }}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="orderStatus" fill="#67c23a" @change="query" size="medium">
            <el-radio-button
              v-for="item in orderStatusOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>

          <el-select
            v-model="pathId"
            style="width: 150px"
            @change="query"
            placeholder="请选择"
            :size="size"
          >
            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </span>

        <span v-if="queryType === 2" class="item">
          <el-radio-group fill="#67c23a" v-model="time" @change="query" size="medium">
            <el-radio-button v-for="item in timeOpts" :label="item.value" :key="item.value">{{
              item.label
            }}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="orderStatus" fill="#67c23a" @change="query" size="medium">
            <el-radio-button
              v-for="item in orderStatusOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>

          <el-select
            v-model="orderSource"
            @change="query"
            style="width: 150px"
            placeholder="请选择"
            :size="size"
          >
            <el-option
              v-for="item in orderSourceOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>

        <span v-if="queryType === 3">
          <span>
            <el-radio-group v-model="isStatic" size="medium" @change="query">
              <el-radio-button v-for="item in staticOpts" :label="item.value" :key="item.value">{{
                item.label
              }}</el-radio-button>
            </el-radio-group>
          </span>

          <span>
            <el-radio-group v-model="isMonth" size="medium" @change="query">
              <el-radio-button v-for="item in time2Opts" :label="item.value" :key="item.value">{{
                item.label
              }}</el-radio-button>
            </el-radio-group>
          </span>

          <span style="margin: 0 20px">
            <el-radio-group v-model="isHalfYear" size="medium" @change="query">
              <el-radio-button v-for="item in retainOpts" :label="item.value" :key="item.value">{{
                item.label
              }}</el-radio-button>
            </el-radio-group>
          </span>

          <el-radio-group v-model="orderStatus" fill="#67c23a" @change="query" size="medium">
            <el-radio-button
              v-for="item in orderStatusOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </span>

        <span v-if="queryType === 4" class="item">
          <el-radio-group fill="#67c23a" v-model="time" @change="query" size="medium">
            <el-radio-button v-for="item in timeOpts" :label="item.value" :key="item.value">{{
              item.label
            }}</el-radio-button>
          </el-radio-group>

          <el-select
            v-model="pathId"
            style="width: 150px"
            @change="query"
            placeholder="请选择"
            :size="size"
          >
            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-select
            key="driverIds"
            style="width: 300px"
            v-model="driverIds"
            multiple
            @change="driverIdsChange"
            placeholder="请选择司机"
            clearable
            collapse-tags
            :size="size"
          >
            <el-option
              v-for="item in driverOptions"
              :key="item.id"
              :label="`${item.no} - ${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="durationType"
            @change="query"
            style="width: 150px"
            placeholder="请选择指标"
            :size="size"
          >
            <el-option
              v-for="item in typeOpts"
              :key="item.key"
              :label="item.name"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </span>
      </div>
    </div>

    <div class="content">
      <chart ref="chart" :option="option" :height="600" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getRouteDuration, getOrderAnalysis, getPassengerRetain, getDriverList } from "@/api";
import _ from "lodash";

export default {
  components: {},
  props: {},
  created() {
    this.query();
    this.getNormalDriver();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      orderSourceOpts: (state) => {
        const data = state.enumMap["ORDER_SOURCE"];
        return [{ label: "全部渠道", value: "" }, ...data];
      },
      pathOpts: (state) => {
        const data = state.pathList;
        return [{ name: "全部路线", id: "" }, ...data];
      },
    }),
  },
  data() {
    return {
      size: "medium",
      queryTypeOpts: [
        {
          label: "渠道统计",
          value: 1,
        },
        {
          label: "路线统计",
          value: 2,
        },
        {
          label: "注册留存统计",
          value: 3,
        },
        {
          label: "路程时长统计",
          value: 4,
        },
      ],
      orderStatusOpts: [
        {
          label: "下单",
          value: "",
        },
        {
          label: "成交",
          value: "FINISH",
        },
      ],
      timeOpts: [
        {
          label: "最近7天",
          value: "1w",
        },
        {
          label: "最近30天",
          value: "1m",
        },
        {
          label: "最近60天",
          value: "2m",
        },
        {
          label: "最近1年",
          value: "1y",
        },
        {
          label: "最近2年",
          value: "2y",
        },
        {
          label: "最近3年",
          value: "3y",
        },
        {
          label: "最近6年",
          value: "6y",
        },
        {
          label: "最近8年",
          value: "8y",
        },
        {
          label: "最近10年",
          value: "10y",
        },
      ],
      time2Opts: [
        {
          label: "按月统计",
          value: true,
        },
        {
          label: "按年统计",
          value: false,
        },
      ],
      staticOpts: [
        {
          label: "静态留存率",
          value: true,
        },
        {
          label: "动态留存率",
          value: false,
        },
      ],
      retainOpts: [
        {
          label: "半年留存率",
          value: true,
        },
        {
          label: "1年留存率",
          value: false,
        },
      ],
      isHalfYear: true,
      isMonth: true,
      isStatic: true,
      queryType: 1,
      time: "1w",
      orderSource: "",
      pathId: "",
      orderStatus: "",
      driverIds: [],
      option: {
        legend: {
          show: true,
          bottom: 0,
        },
        xAxis: { type: "category" },
        yAxis: {},
        tooltip: {
          show: true,
          trigger: "axis",
        },
      },
      driverOptions: [],
      typeOpts: [
        { key: "avgPlanDuration", name: "规划时长" },
        { key: "avgTotalDuration", name: "实际时长" },
        { key: "avgWaitDuration", name: "等待时长" },
        { key: "diffNavDuration", name: "直达时长差值" },
        { key: "diffPlanDuration", name: "规划时长差值" },
        { key: "diffStandardDuration", name: "标准时长差值" },
      ],
      durationType: null,
    };
  },
  methods: {
    query() {
      const type = this.queryType;
      if (type === 1 || type === 2) {
        this.queryOrderAnalysis();
      } else if (type === 3) {
        this.queryRetain();
      } else if (type === 4) {
        this.queryRouteDuration();
      }
    },

    driverIdsChange() {
      if (!this.durationType && this.driverIds.length > 1) {
        this.durationType = "avgTotalDuration";
      }
      this.query();
    },

    getNormalDriver() {
      const _param = {
        param: { status: 1 },
        page: {
          current: 1,
          size: 100,
        },
      };
      getDriverList(_param).then((res) => {
        const { records = [] } = res.data || {};
        // 按照no字段排序
        records.sort((a, b) => {
          return a.no - b.no;
        });
        this.driverOptions = records;
      });
    },

    queryRetain() {
      const param = {
        isMonth: this.isMonth,
        isDeal: this.orderStatus === "FINISH",
        isHalfYear: this.isHalfYear,
        isStatic: this.isStatic,
      };
      getPassengerRetain(param).then((res) => {
        this.renderRetainChart(res.data);
      });
    },

    queryRouteDuration() {
      const param = {
        timeRange: this.time,
        pathId: this.pathId,
        driverIds: this.driverIds,
        type: this.durationType,
      };
      getRouteDuration(param).then((res) => {
        if (this.driverIds && this.driverIds.length > 1) {
          this.renderRouteDurationCompareChart(res.data);
        } else {
          this.renderRouteDurationChart(res.data);
        }
      });
    },

    queryOrderAnalysis() {
      const param = {
        timeRange: this.time,
        src: this.orderSource,
        pathId: this.pathId,
        orderStatus: this.orderStatus,
      };

      if (this.queryType === 1) {
        param.src = "all";
      } else if (this.queryType === 2) {
        param.pathId = "all";
      }

      getOrderAnalysis(param).then((res) => {
        this.renderOrderChart(res.data);
      });
    },

    renderRetainChart(data) {
      const typeList = [
        { key: "userCount", name: "注册人数" },
        { key: "orderCount", name: "留存人数" },
        { key: "rate", name: "留存率" },
      ];
      const dimensions = ["regTime"];
      const series = [];
      typeList.forEach((item) => {
        dimensions.push(item.key);
        series.push({
          type: "line",
          smooth: true,
          name: item.name,
          encode: {
            x: "date",
            y: item.key,
          },
          label: {
            show: data.length < 30,
          },
        });
      });

      const option = {
        ...this.option,
        series,
        dataset: {
          dimensions,
          source: data,
        },
        legend: {
          show: true,
          bottom: 0,
          formatter: function (name) {
            return name;
          },
        },
      };
      this.$refs.chart.setOption(option, true);
    },

    renderRouteDurationChart(data) {
      const dimensions = ["date"];
      const series = [];
      this.typeOpts.forEach((item) => {
        dimensions.push(item.key);
        series.push({
          type: "line",
          smooth: true,
          name: item.name,
          encode: {
            x: "date",
            y: item.key,
          },
          label: {
            show: data.length < 30,
          },
        });
      });

      const option = {
        ...this.option,
        series,
        dataset: {
          dimensions,
          source: data,
        },
        legend: {
          show: true,
          bottom: 0,
          formatter: function (name) {
            return name;
          },
        },
      };
      this.$refs.chart.setOption(option, true);
    },

    renderRouteDurationCompareChart(data) {
      const driverMap = _.keyBy(this.driverOptions, "id");

      const dimensions = ["date"];
      const series = [];

      this.driverIds.forEach((id) => {
        const driver = driverMap[id];
        const key = `${driver.id}`;
        dimensions.push(key);
        series.push({
          type: "line",
          smooth: true,
          name: `${driver.no} - ${driver.name}`,
          encode: {
            x: "date",
            y: key,
          },
          label: {
            show: data.length < 30,
          },
        });
      });

      const option = {
        ...this.option,
        series,
        dataset: {
          dimensions,
          source: data,
        },
        legend: {
          show: true,
          bottom: 0,
          formatter: function (name) {
            return name;
          },
        },
      };
      this.$refs.chart.setOption(option, true);
    },

    renderOrderChart(data) {
      const series = [
        {
          type: "line",
          smooth: true,
          name: "所有",
          encode: {
            x: "order_time",
            y: "all",
          },
        },
      ];

      let dimensions = ["order_time", "all"];

      const queryType = this.queryType;

      if (queryType === 1) {
        this.orderSourceOpts.forEach((item) => {
          const key = `s${item.value}`;
          if (item.value) {
            series.push({
              type: "line",
              smooth: true,
              name: item.label,
              encode: {
                x: "order_time",
                y: key,
              },
            });
            dimensions.push(key);
          }
        });
      }
      if (queryType === 2) {
        this.pathOpts.forEach((item) => {
          const key = `s${item.id}`;
          if (item.id) {
            series.push({
              type: "line",
              smooth: true,
              name: item.name,
              encode: {
                x: "order_time",
                y: key,
              },
            });
            dimensions.push(key);
          }
        });
      }
      const option = {
        ...this.option,
        series,
        dataset: {
          dimensions,
          source: data,
        },
        legend: {
          show: true,
          bottom: 0,
          formatter: function (name) {
            return name;
          },
        },
      };
      this.$refs.chart.setOption(option, true);
    },

    renderRegisterChart(data = []) {
      const dimensions = ["reg_time"];
      const series = [
        {
          type: "line",
          smooth: true,
          name: "邀请",
          encode: {
            x: "reg_time",
            y: "num",
          },
        },
      ];
      const option = {
        series,
        dataset: {
          dimensions,
          source: data,
        },
        ...this.option,
      };
      this.$refs.chart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.root-box {
  min-width: 1000px;
  .condition-box {
    .c1 {
      .title {
        margin: 0 20px;
        font-size: 15px;
        color: rgb(233, 64, 64);
      }
    }

    .c2 {
      margin: 20px 0 0 0;
      .item {
        display: flex;
        > * {
          margin-right: 26px;
        }
      }
    }
  }

  .content {
    margin-top: 50px;
    height: 650px;
    width: 100%;
  }
}
</style>
