<template>
  <div class="root-box">
    <div class="tab">
      <el-tabs v-model="type" :stretch="true" @tab-click="queryFare">
        <el-tab-pane label="专车" name="1">
          <el-table :data="list">
            <el-table-column
              :prop="item"
              width="300"
              v-for="item in columns"
              :key="item"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index === 1 && item !== 'f0'">
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="edit(item)"
                    >编辑</el-button
                  >
                </div>
                <div v-else>
                  {{ scope.row[item] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="拼车" name="2">
          <el-table :data="list">
            <el-table-column
              :prop="item"
              width="300"
              v-for="item in columns"
              :key="item"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index === 1 && item !== 'f0'">
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="edit(item)"
                    >编辑</el-button
                  >
                </div>
                <div v-else>
                  {{ scope.row[item] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      :visible="show"
      width="600px"
      title="调整价格"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="180px" size="medium">
        <el-form-item label="运价编码">
          <el-input v-model="form.fareType"></el-input>
        </el-form-item>
        <el-form-item label="起步费（元）">
          <el-input-number v-model="form.startFare"></el-input-number>
        </el-form-item>
        <el-form-item label="起步里程（公里）">
          <el-input-number v-model="form.startMile"></el-input-number>
        </el-form-item>
        <el-form-item label="里程单价（按公里）">
          <el-input-number v-model="form.unitPricePerMile"></el-input-number>
        </el-form-item>
        <el-form-item label="计时单价（按分钟）">
          <el-input-number v-model="form.unitPricePerMinute"></el-input-number>
        </el-form-item>
        <el-form-item label="单程加价（元）">
          <el-input-number v-model="form.upPrice"></el-input-number>
        </el-form-item>
        <el-form-item label="单程加价公里">
          <el-input-number v-model="form.upPriceStartMile"></el-input-number>
        </el-form-item>
        <el-form-item label="营运早髙峰时间段">
          <el-time-select
            v-model="form.morningPeakTimeOn"
            value-format="HHmm"
            :picker-options="{
              start: '05:00',
              step: '00:10',
              end: '12:00',
            }"
            style="width: 45%"
          >
          </el-time-select>

          <span style="margin: 0 10px">至</span>

          <el-time-select
            v-model="form.morningPeakTimeOff"
            :picker-options="{
              minTime: form.morningPeakTimeOn,
              start: '05:00',
              step: '00:10',
              end: '12:00',
            }"
            style="width: 45%"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="营运晚高峰时间段">
          <el-time-select
            v-model="form.eveningPeakTimeOn"
            :picker-options="{
              start: '16:00',
              step: '00:10',
              end: '23:00',
            }"
            style="width: 45%"
          >
          </el-time-select>

          <span style="margin: 0 10px">至</span>
          <el-time-select
            v-model="form.eveningPeakTimeOff"
            value-format="HHmm"
            :picker-options="{
              minTime: form.eveningPeakTimeOn,
              start: '16:00',
              step: '00:10',
              end: '23:00',
            }"
            style="width: 45%"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label=" 髙峰时间单程加价（元）">
          <el-input-number v-model="form.peakUnitPrice"></el-input-number>
        </el-form-item>

        <el-form-item label="低速计时加价（按分钟）">
          <el-input-number
            v-model="form.lowSpeedPricePerMinute"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button type="primary" @click="save" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import _ from 'lodash';
export default {
  data () {
    return {
      type: '1',
      vehicleType: [],
      list: [],
      columns: [],
      form: {},
      show: false,
      fieldList: [{
        name: '',
        field: 'vehicleName'
      }, {
        name: '操作',
        field: 'operator'
      }, {
        name: '运价类型编码',
        field: 'fareType'
      }, {
        name: '起步费（元）',
        field: 'startFare'
      }, {
        name: '起步里程（公里）',
        field: 'startMile'
      }, {
        name: '计时单价（按公里）',
        field: 'unitPricePerMile'
      }, {
        name: '计时单价（按分钟）',
        field: 'unitPricePerMinute'
      }, {
        name: '单程加价（元）',
        field: 'upPrice'
      }, {
        name: '单程加价公里',
        field: 'upPriceStartMile'
      }, {
        name: '营运早髙峰时间起',
        field: 'morningPeakTimeOn'
      }, {
        name: '营运早高峰时间止',
        field: 'morningPeakTimeOff'
      }, {
        name: '营运晚髙峰时间起',
        field: 'eveningPeakTimeOn'
      }, {
        name: '营运晚高峰时间止',
        field: 'eveningPeakTimeOff'
      }, {
        name: '髙峰时间单程加价单价（元）',
        field: 'peakUnitPrice'
      }, {
        name: '低速计时加价（按分钟）',
        field: 'lowSpeedPricePerMinute'
      }]

    };
  },

  created () {
    this.queryFare();
  },
  methods: {
    queryFare () {
      const url = '/admin/setting/fare/list';
      this.$http.post(url, {
        orderType: this.type
      }).then((res) => {
        this.oriData = res.data || [];
        this.list = this.handleData(res.data);
      });
    },
    handleData (data = []) {
      const copy = _.cloneDeep(data);
      const result = [];
      const columns = ['f0'];
      for (let i = 1; i <= copy.length ; i ++) {
        columns.push(`f${i}`);
      }

      // 行转列
      this.fieldList.forEach(field=>{
        const row = {f0: field.name};
        result.push(row);
        for (let i = 1; i <= copy.length ; i ++) {
          const dataIndex = i - 1;
          const item = copy[dataIndex];
          row[`f${i}`] = item[field.field] || 0;
        }
      });

      this.columns = columns;

      setTimeout(()=>{
        this.list = result;
      }, 500);

    },
    edit (column) {
      const reg = /\D/g;
      const dataIndex = +column.replace(reg, '') - 1;
      const data = this.oriData[dataIndex];
      if (data) {
        this.form = data;
        this.show = true;
      }
    },
    save () {
      const url = '/admin/setting/fare/modify';
      const param = _.cloneDeep(this.form);
      this.$http.post(url, param).then(() => {
        this.show = false;
        this.queryFare();
      });
    },
    close () {
      this.form = {};
      this.show = false;
    },
    format (value) {
      if (value) {
        return value.replace(':', '');
      }
      return null;
    }

  }
};
</script>

<style scoped lang="less">
.tab {
  width: 1200px;
}

.el-input-number {
  width: 100%;
}
</style>
