import {Http} from '@/util';

//
export function calcDispatch1 (ids, pathId) {
  return Http.post('/beta/auto_dispatch/order_list/v1', {ids, pathId});
}

export function calcDispatch2 (ids, pathId) {
  return Http.post('/beta/auto_dispatch/order_list/v2', {ids, pathId});
}


export function calcDispatch3 (ids, pathId) {
  return Http.post('/beta/auto_dispatch/order_list/v3', {ids, pathId});
}