<template>
  <div v-if="!!show">
    <div class="flex"
         :class="{level1: level ===1,level2: level ===2}">
      <span>{{ name }}</span>
      <span class="spe"> </span>
      <span v-if="isDiscount"
            class="green1">- ¥{{ price  | moneyFormat(0) }}</span>
      <span v-else
            class="red1">¥{{ price  | moneyFormat(0) }}</span>
    </div>
    <div v-if="desc"
         class="desc">
      {{ desc }}</div>
  </div>
</template>
<script>
export default {
  components: {},

  props: {
    desc: String,
    name: String,
    price: Number,
    isDiscount: Boolean,
    show: {
      type: [Number, Boolean, Object],
      default: false,
    },
    level: {
      type: Number,
      default: 2,
    },
  },

  watch: {},

  cteated () {},

  mounted () {},

  computed: {},

  data () {
    return {};
  },

  methods: {},
};
</script>
<style scoped lang="less">
.flex {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  .spe {
    margin: 0 10px;
    flex: 1;
    position: relative;
    top: 8px;
    display: inline-block;
    border-top: 1px dashed #333;
  }

  font-size: 14px;
  margin-top: 16px;

  &.level1 {
    font-size: 14px;
    margin-top: 16px;
    .red1 {
      color: red;
    }

    .green1 {
      color: green;
    }
  }
  &.level2 {
    margin-top: 6px;
    font-size: 12px;
    color: grey;
    padding-left: 12px;

    .green1 {
      color: green;
    }
  }
}

.desc {
  margin-top: 6px;
  font-size: 12px;
  color: grey;
  padding-left: 12px;
}
</style>
