import Vue from 'vue';
import _ from 'lodash';

Vue.filter('enumTransfer', function (value, enumOpts, valueField = "value", labelField = "label") {
  if (enumOpts && enumOpts.length) {
    if (value !== undefined && value !== null) {
      // 数组
      if (value instanceof Array) {
        const labels = enumOpts.filter(item=> value.includes(item[valueField]));
        return labels.map(t=> t[labelField]).join(",");
      } else {
        const item = _.find(enumOpts, item=> item[valueField] === value) || {};
        if (item[labelField]) {
          return item[labelField];
        }
        return value;
      }
    }


  }
  return value;
});
