<template>
  <div class="root-box">
    <div class="top">
      <div>
        <el-button size="small" type="primary" @click="add">新增车型</el-button>
      </div>

      <div>
        <el-checkbox v-model="onlyEnable" @change="queryList" style="margin-right: 20px" size="small">只看启用</el-checkbox>
        <el-button type="primary" size="small" @click="queryList">刷新</el-button>
      </div>
    </div>
    <el-table :data="list" border>
      <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
      <el-table-column width="250" label="车标图片">
        <template slot-scope="scope">
          <el-image class="photo" :src="scope.row.vehicleImg" :preview-src-list="[scope.row.vehicleImg]">
          </el-image>
        </template>
      </el-table-column>

      <el-table-column prop="content" label="车型名称"> </el-table-column>

      <el-table-column prop="seats" label="座位数">
        <template slot-scope="scope"> {{ scope.row.seats }} 座 </template>
      </el-table-column>

      <el-table-column prop="seats" label="保底上座率（‰）">
        <template slot-scope="scope"> {{ scope.row.attendance }} ‰ </template>
      </el-table-column>

      <el-table-column label="启用状态">
        <template slot-scope="scope">
          {{ scope.row.delFlag ? "启用" : "停用" }}
        </template>
      </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          <el-button v-if="!scope.row.delFlag" type="text" size="small" @click="change(scope.row, 1)">启用</el-button>
          <el-button v-if="scope.row.delFlag" type="text" size="small" @click="change(scope.row, 0)">停用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible="show" width="600px" title="编辑车型" :show-close="false" :close-on-click-modal="false">
      <el-form ref="form" label-width="150px" size="medium">
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="车型名称">
              <el-input v-model="form.content" style="width: 360px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col>
            <el-form-item label="车坐数">
              <el-select v-model="form.seats" style="width: 360px">
                <el-option v-for="item in seatsOpts" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col>
            <el-form-item label="保底上座率（‰）">
              <el-input-number v-model="form.attendance" style="width: 360px"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col>
            <el-form-item label="车型图标">
              <image-upload v-model="form.vehicleImg" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button type="primary" @click="saveOrUpdate" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listVehicleType, addVehicleType, modifyVehicleType } from "@/api";

export default {
  mounted() {
    this.queryList();
  },
  data() {
    return {
      list: [],
      show: false,
      onlyEnable: true,
      form: {},
      seatsOpts: [
        { value: 3, label: "3座" },
        { value: 4, label: "4座" },
        { value: 6, label: "6座" },
      ],
    };
  },
  methods: {
    edit(item) {
      this.show = true;
      this.form = { ...item };
    },

    close() {
      this.show = false;
    },

    saveOrUpdate() {
      // 新增
      if (this.form.id) {
        modifyVehicleType(this.form).then(() => {
          this.show = false;
          this.queryList();
        });
      } else {
        // 修改
        addVehicleType(this.form).then(() => {
          this.show = false;
          this.queryList();
        });
      }
    },

    change(item, type) {
      modifyVehicleType({
        id: item.id,
        delFlag: type,
      }).then(() => {
        this.queryList();
      });
    },

    add() {
      this.show = true;
      this.content = "";
      this.vehicleImg = null;
    },

    queryList() {
      listVehicleType(false).then((res) => {
        let list = res.data || [];
        if (this.onlyEnable) {
          list = list.filter((item) => item.delFlag);
        }
        this.list = list;
      });
    },
  },
};
</script>

<style scoped lang="less">
.uploader {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
}

.top {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
