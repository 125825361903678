<template>
  <div class="root-box" v-loading.fullscreen.lock="uploading">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="App类型：" prop="type">
            <el-select
              v-model="param.type"
              placeholder="请选择"
              @change="getList"
              clearable
            >
              <el-option
                v-for="item in appTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="客户端类型：" prop="clientType">
            <el-select
              v-model="param.clientType"
              placeholder="请选择"
              @change="getList"
              clearable
            >
              <el-option
                v-for="item in clientTypeOpts"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" :size="size" @click="getList"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-button
        type="primary"
        style="margin: 0 20px 20px 0"
        :size="size"
        @click="openAdd"
        >新版发布</el-button
      >
    </div>

    <div>
      <el-table :data="list" stripe max-height="600">
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column
          prop="version"
          width="80"
          label="版本号"
        ></el-table-column>

        <el-table-column prop="packageName" label="包名"></el-table-column>

        <el-table-column prop="title" label="标题"></el-table-column>

        <el-table-column prop="contents" label="新版本说明"></el-table-column>

        <el-table-column prop="type" label="App类型" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.type | enumTransfer(appTypeOpts) }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="clientType"
          label="客户端类型"
          width="120"
        ></el-table-column>

        <el-table-column prop="apkDownloadUrl" label="链接地址">
          <template slot-scope="scope">
            <span> {{ scope.row.apkDownloadUrl }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column prop="state" label="是否强制更新" width="80">
          <template slot-scope="scope">{{
            scope.row.forceUpgrade | enumTransfer(yesNoOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="state" label="是否发布" width="80">
          <template slot-scope="scope">{{
            scope.row.state | enumTransfer(yesNoOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="id" label="操作" width="150">
          <template slot-scope="scope">
            <span>
              <el-upload
                style="display: inline"
                :data="{ type: 3 }"
                action="/admin/upload/file"
                :show-file-list="false"
                :on-progress="onUploadProcess"
                :on-success="(response) => onUploadSuccess(response, scope.row)"
                :on-error="onUploadError"
              >
                <el-button
                  size="small"
                  type="text"
                  :disabled="scope.row.clientType === 'IOS'"
                  >上传APK</el-button
                >
              </el-upload>
              <el-dropdown
                @command="(command) => handleCommand(command, scope.row)"
                style="margin-left: 10px"
                class="dropdown-meun"
              >
                <span class="el-dropdown-link">
                  操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">
                    <span>修改</span>
                  </el-dropdown-item>

                  <el-dropdown-item command="2">
                    <span>删除</span>
                  </el-dropdown-item>

                  <el-dropdown-item command="4">
                    <span>{{ scope.row.state ? "撤销发布" : "发布" }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      title="APP发版信息"
      :close-on-click-modal="false"
    >
      <el-form ref="userForm" :model="form" :size="size" label-width="120px">
        <el-form-item label="版本号" prop="name">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="font-size: 13px; color: red">
            注意：中间版本号和大版本号增加为强制更新，小版本号增加为可选更新
          </div>
        </el-form-item>

        <el-form-item label="App类型：" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width: 100%"
            @change="handleAppTypeChange"
          >
            <el-option
              v-for="item in appTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端类型：" prop="clientType">
          <el-select
            v-model="form.clientType"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in clientTypeOpts"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="包名：" prop="packageName">
          <el-select
            v-model="form.packageName"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in packageOpts"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否强制更新：" prop="type">
          <el-select
            v-model="form.forceUpgrade"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="新版本说明" prop="contents">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.contents"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {getAppVersionList, addAppVersion, modifyAppVersion, delAppVersion} from '@/api';
import { mapState } from 'vuex';
import {BaseDataService} from '@/service';
import _ from 'lodash';
import { Toolkit } from '@/util';

export default {
  components: {},
  props: {},
  created () {
    this.getList();
  },
  mounted () {},
  watch: {},
  computed: {

    ...mapState({
      size: state => state.btnSize
    })
  },
  data () {
    return {
      appTypeOpts: [{
        label: '乘客端',
        value: 'passenger'
      }, {
        label: '司机端',
        value: 'driver',
      }],
      visible: false,
      list: [],
      yesNoOpts: BaseDataService.yesNoOpts(),
      packageOpts: ['com.chengxiaoban.driver', 'com.chengxiaoban.passenger'],
      clientTypeOpts: ['ANDROID', 'IOS'],
      form: {},
      param: {},
      uploading: false,
    };
  },
  methods: {
    getList () {
      getAppVersionList(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
      this.clearFiles();
    },
    openAdd () {
      this.visible = true;
      this.form = { forceUpgrade: false, title: '发现新版本'};
    },
    closeDiag () {
      this.visible = false;
    },
    openEdit (item) {
      this.visible = true;
      this.form = _.cloneDeep(item);
    },

    handleAppTypeChange (val) {
      if (val === 'passenger') {
        this.$set(this.form, 'packageName', 'com.chengxiaoban.passenger');
      } else if (val === 'driver') {
        this.$set(this.form, 'packageName', 'com.chengxiaoban.driver');

      }
    },

    changeState (item) {
      item.state = !item.state;

      if (item.state && !item.apkDownloadUrl && item.clientType === 'ANDROID') {
        this.$confirm('还没有上传APK包，确认发布吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          modifyAppVersion(item).then(() => {
            this.$message.success("操作成功");
            this.closeDiag();
            this.getList();
          });
        });
      } else {
        modifyAppVersion(item).then(() => {
          this.$message.success("操作成功");
          this.closeDiag();
          this.getList();
        });
      }

    },
    saveOrUpdate () {

      if (this.form.id) {
        modifyAppVersion(this.form).then(() => {
          this.$message.success("操作成功");
          this.closeDiag();
          this.getList();
        });
      } else {
        addAppVersion(this.form).then(() => {
          this.$message.success("操作成功");
          this.closeDiag();
          this.getList();
        });
      }

    },
    del (item) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAppVersion(item.id).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      });
    },
    handleCommand (command, item) {
      command = parseInt(command);
      switch (command) {
        case 1: this.openEdit(item); break;
        case 2: this.del(item); break;
        case 4: this.changeState(item); break;
      }
    },
    onUploadProcess () {
      this.uploading = true;
    },
    onUploadSuccess (response, item) {
      this.uploading = false;
      if (response.code === 200) {
        const param = {...item};
        param.apkDownloadUrl = response.data;
        modifyAppVersion(param).then(() => {
          this.getList();
        });

      } else {
        this.$message.error('上传失败');
        this.clearFiles();
      }
    },
    onUploadError (err) {
      this.uploading = false;
      this.$message.error("文件上传失败");
      console.log(err);
    },
    clearFiles () {
      if (this.$refs.uploader) {
        this.$refs.uploader.clearFiles();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.operate-container {
  button {
    margin-left: 20px;
  }
}
</style>