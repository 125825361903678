import {Http} from '@/util';

// 查询上报数据
export function getReportData (param) {
  return Http.post('/admin/report/get_report_data', param);
}


export function reportSingleData (param) {
  return Http.get('/admin/report/single', param);
}



export function reportAgain (param) {
  return Http.get('/admin/report/again', param);
}



export function pushTask (param) {
  return Http.get('/admin/report/push_task', param);
}


export function saveOrUpdateReportData (ipcType, param) {
  return Http.post(`/admin/report/save_or_update/${ipcType}`, param);
}

export function deleteReportData (ipcType, id) {
  return Http.get(`/admin/report/delete/${ipcType}/${id}`);
}

