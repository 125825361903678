<template>
  <div>
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="司机编号：">
          <el-input v-model="param.no" clearable />
        </el-form-item>

        <el-form-item label="通道：">
          <el-select v-model="param.chn" placeholder="请选择" clearable>
            <el-option
              v-for="item in chnOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间：">
          <el-date-picker
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="param.startTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间：">
          <el-date-picker
            placeholder="选择时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            v-model="param.endTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="queryVideo()"
            >查询录像</el-button
          >

          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="queryTask()"
            >查询下载任务</el-button
          >

          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="batchDownload()"
            >批量下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table
        v-if="!showTask"
        :data="files"
        stripe
        max-height="680"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="chn" label="通道" width="120">
          <template slot-scope="scope"> CH{{ scope.row.chn + 1 }} </template>
        </el-table-column>

        <el-table-column prop="time" label="时间" width="260">
          <template slot-scope="scope">
            {{ scope.row | timeFilter }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="文件大小">
          <template slot-scope="scope">
            {{ scope.row.len | fileSizeFilter }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="下载状态">
          <template slot-scope="scope">
            {{ scope.row.stu | enumTransfer(dowloadStatus) }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="文件大小">
          <template slot-scope="scope">
            <el-link type="primary" @click="downloadVideo(scope.row.DownTaskUrl)"
              >上传服务器</el-link
            >
          </template>
        </el-table-column>
      </el-table>

      <el-table
        v-else
        :data="files"
        stripe
        max-height="680"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="chn" label="通道" width="100">
          <template slot-scope="scope"> CH{{ scope.row.chn + 1 }} </template>
        </el-table-column>

        <el-table-column prop="time" label="时间" width="340">
          <template slot-scope="scope">
            {{ scope.row.fbtm | timeFilter2 }} - {{ scope.row.fetm | timeFilter2 }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="文件大小">
          <template slot-scope="scope">
            {{ scope.row.len | fileSizeFilter }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="下载状态">
          <template slot-scope="scope">
            {{ scope.row.stu | enumTransfer(dowloadStatus) }}
          </template>
        </el-table-column>

        <el-table-column prop="size" label="文件大小">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :disabled="scope.row.stu !== 4"
              di
              :href="scope.row.downUrl"
              target="_blank"
              >直接下载</el-link
            >
          </template>
        </el-table-column>

        <el-table-column prop="chn" label="下载失败原因">
          <template slot-scope="scope"> {{ scope.row.err ? scope.row.err : "成功" }} </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {
  getDriverDetail,
  videoMonitorLogin,
  isDeviceOnline,
  getHisVideo,
  getDownloadTaskList,
  addDownloadTask,
} from "@/api";
import { DateUtil, ParamUtil } from "@/util";
import { Loading } from "element-ui";

const zero = (num) => (num < 10 ? `0${num}` : num);
const sencondsToTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  return `${zero(h)}:${zero(m)}:${zero(s)}`;
};

const options = {
  lock: true,
  text: "正在处理，请稍后",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.7)",
};

export default {
  components: {},

  filters: {
    timeFilter(data) {
      const { year, mon, day, beg, end } = data;
      // 将一天的秒数转化成时间格式
      const stime = sencondsToTime(beg);
      const etime = sencondsToTime(end);
      return `${year}-${mon}-${day} ${stime} - ${etime}`;
    },

    timeFilter2(utcTime) {
      const date = new Date(utcTime);
      return DateUtil.format(date, "yyyy-MM-dd hh:mm:ss");
    },

    fileSizeFilter(size) {
      if (size < 1024) {
        return size + "B";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + "KB";
      } else if (size < 1024 * 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + "MB";
      } else {
        return (size / 1024 / 1024 / 1024).toFixed(2) + "GB";
      }
    },
  },

  props: {},

  watch: {},

  created() {},

  mounted() {
    this.login();
  },

  computed: {},

  data() {
    return {
      param: {
        no: "",
        startTime: DateUtil.format(new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: DateUtil.format(new Date(), "yyyy-MM-dd 23:59:59"),
      },
      token: null,
      carStatus: null,
      online: false,
      files: [],
      dowloadStatus: [
        { value: 1, label: "未下载" },
        { value: 2, label: "下载中" },
        { value: 3, label: "下载失败" },
        { value: 4, label: "已下载" },
      ],
      chnOptions: [
        { value: 0, label: "通道1" },
        { value: 1, label: "通道2" },
        { value: 2, label: "通道3" },
        { value: 3, label: "通道4" },
      ],
      showTask: false,
    };
  },

  methods: {
    login() {
      videoMonitorLogin()
        .then((res) => {
          this.token = res.jsession;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    handleSelectionChange(val) {
      this.selectItems = val;
    },

    async queryTask() {
      this.selectItems = [];
      const { no, startTime, endTime } = this.param;
      if (!no) {
        this.$message.error("请输入司机编号");
        return;
      }

      this.showTask = true;
      const param = { no: this.param.no, status: 1 };

      const driverResp = await getDriverDetail(param);
      const { data } = driverResp || {};
      const { driver } = data || {};
      if (!driver) {
        this.$message.error("司机不存在");
        return;
      }

      const { videoDeviceId } = driver;
      const loadingInstance = Loading.service(options);

      getDownloadTaskList(this.token, videoDeviceId, startTime, endTime).then((res) => {
        loadingInstance.close();
        this.files = res.infos;
      });
    },

    async queryVideo() {
      this.selectItems = [];
      const { no, startTime, endTime, chn = -1 } = this.param;
      if (!no) {
        this.$message.error("请输入司机编号");
        return;
      }
      this.showTask = false;

      const param = { no, status: 1 };

      const driverResp = await getDriverDetail(param);
      const { data } = driverResp || {};
      const { driver } = data || {};
      if (!driver) {
        this.$message.error("司机不存在");
        return;
      }

      const { videoDeviceId } = driver;

      const loadingInstance = Loading.service(options);

      const onlineResp = await isDeviceOnline(this.token, videoDeviceId);

      if (onlineResp.onlines[0].online) {
        const fileResp = await getHisVideo(this.token, videoDeviceId, chn, startTime, endTime);
        loadingInstance.close();
        this.files = fileResp.files;
      } else {
        loadingInstance.close();
        this.$message.error("设备不在线");
      }
    },

    downloadVideo(oriUrl) {
      const url = oriUrl.split("?")[0];
      const params = ParamUtil.parseQueryString(oriUrl);
      params.jsession = this.token;
      const wholeUrl = `${url}?${ParamUtil.obj2Str(params)}`;
      addDownloadTask(wholeUrl).then((res) => {
        if (res.result === 0) {
          this.$message.success("下载成功");
        } else {
          this.$message.error(res.message || "下载失败");
        }
      });
    },

    batchDownload() {
      if (this.selectItems && this.selectItems.length) {
        if (this.showTask) {
          this.selectItems.forEach((item) => {
            if (item.stu === 4) {
              window.open(item.downUrl);
            }
          });
        } else {
          this.selectItems.forEach((item) => {
            this.downloadVideo(item.DownTaskUrl);
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="less"></style>
