<template>
  <div>
    <el-dialog width="1100px" @opened="loadImg" title="图片打码" :visible.sync="visible">
      <canvas id="canvas-box"></canvas>

      <el-radio :value="flag" :label="true">鼠标按下开启打码</el-radio>
      <span slot="footer" class="footer">
        <el-button @click="saveImg" size="small">保 存</el-button>
        <el-button @click="visible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},

  props: {},

  watch: {},

  created() {},

  mounted() {},

  computed: {},

  data() {
    return {
      visible: false,
      imgUrl: "",
      flag: false,
    };
  },

  methods: {
    show(url, watermark = "仅限内部使用", callback) {
      this.flag = false;
      this.imgUrl = url;
      this.visible = true;
      this.watermark = watermark;
      this.callback = callback;
    },

    loadImg() {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = this.imgUrl;
      let canvas = document.getElementById("canvas-box");
      let ctx = canvas.getContext("2d");
      const watermark = this.watermark;
      // 待图片加载完成
      img.onload = function () {
        let imgWidth = this.width;
        let imgHeight = this.height;
        canvas.setAttribute("width", imgWidth); // 画布的宽高为图片的宽高
        canvas.setAttribute("height", imgHeight);
        // 高度按照560px等比缩放
        canvas.height = 560;
        canvas.width = (imgWidth * 560) / imgHeight;
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

        // 添加水印文字
        ctx.font = "30px Arial";
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)"; // 设置文字颜色和透明度
        ctx.fillText(watermark, 50, 90);
      };

      canvas.onmousedown = () => {
        this.flag = true;
      };

      canvas.onmouseup = () => {
        this.flag = false;
      };

      canvas.onmousemove = (e) => {
        if (this.flag) {
          const blockSize = 20;
          const x = e.offsetX;
          const y = e.offsetY;
          const imgData = ctx.getImageData(x, y, blockSize, blockSize); // 像素数据

          const pixels = imgData.data;

          // 计算当前马赛克块的平均颜色
          let totalR = 0;
          let totalG = 0;
          let totalB = 0;
          for (let i = 0; i < pixels.length; i += 4) {
            totalR += pixels[i];
            totalG += pixels[i + 1];
            totalB += pixels[i + 2];
          }
          const avgR = totalR / (blockSize * blockSize);
          const avgG = totalG / (blockSize * blockSize);
          const avgB = totalB / (blockSize * blockSize);

          // 填充当前马赛克块
          ctx.fillStyle = `rgb(${avgR},${avgG},${avgB})`;
          ctx.fillRect(x, y, blockSize, blockSize);
        }
      };
    },

    saveImg() {
      let canvas = document.getElementById("canvas-box");
      canvas.toBlob((blob) => {
        const file = new File([blob], "canvas_image.jpg", { type: "image/jpeg" });
        this.callback(file);
        this.visible = false;
      }, "image/jpg");
    },
  },
};
</script>
<style scoped lang="less"></style>
