import OSS from "ali-oss";
import { getOssUploadRole } from "@/api";

const clientMap = {};

export default {
  async getOssClient (bucket) {
    if (clientMap[bucket]) {
      const {
        expiration,
      } = clientMap[bucket].stsCredentials ;
      const expireDate = new Date(expiration);
      // 如果过期时间大于3分钟，则无需替换凭证
      if (
        expireDate.getTime() - new Date().getTime() >
                3 * 60 * 1000
      ) {
        return clientMap[bucket].client;
      }
    }

    const res = await getOssUploadRole();
    const stsCredentials = res.data.credentials;
    const { accessKeyId, accessKeySecret, securityToken } = stsCredentials;

    const client = new OSS({
      accessKeyId,
      accessKeySecret,
      stsToken: securityToken,
      bucket,
    });

    clientMap[bucket] = {
      stsCredentials,
      client
    };

    return client;
  },
};