<template>
  <div>
    <el-upload
      :http-request="uploadHandle"
      :limit="limit"
      action=""
      :on-remove="clear"
      :show-file-list="true"
      :file-list="fileList"
      ref="uploader"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <span slot="tip" class="el-upload__tip"> 文件大小不超过20MB </span>
    </el-upload>
  </div>
</template>

<script>
import OSS from "ali-oss";
import { getOssUploadRole } from "@/api";
import { Toolkit } from "@/util";

export default {
  components: {},
  props: {
    onUploadSuccess: Function,
    value: Array,
    bucket: {
      type: String,
      default: "yxmgt-private",
    },
    filePath: {
      type: String,
      default: "/driver/contract",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  watch: {
    value: {
      handler(val = []) {
        this.fileList = val;
      },
      immediate: true,
    },
  },
  computed: {},
  data() {
    return {
      stsCredentials: "",
      fileList: [],
    };
  },
  methods: {
    clear(file) {
      const values = this.fileList.filter((t) => t.url !== file.url);
      this.$emit("input", values);
    },

    async uploadHandle(option) {
      const client = await this.getOssClient();
      const dotIndex = option.file.name.lastIndexOf(".");
      const fileType = option.file.name.substr(dotIndex);
      const fullName = `${this.filePath}/${Toolkit.uuid()}${fileType}`;
      client
        .put(fullName, option.file)
        .then((res) => {
          const values = [...this.fileList];
          values.push({ name: option.file.name, url: res.url });
          this.$emit("input", values);
          if (this.onUploadSuccess) {
            this.onUploadSuccess(values);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },
    async getOssClient() {
      if (this.stsCredentials) {
        const { accessKeyId, accessKeySecret, expiration, securityToken } =
          this.stsCredentials || {};
        const expireDate = new Date(expiration);
        // 如果过期时间大于3分钟，则无需替换凭证
        if (expireDate.getTime() - new Date().getTime() > 3 * 60 * 1000) {
          return new OSS({
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
            bucket: this.bucket,
          });
        }
      }

      const res = await getOssUploadRole();
      this.stsCredentials = res.data.credentials;
      const { accessKeyId, accessKeySecret, securityToken } = this.stsCredentials;
      return new OSS({
        accessKeyId,
        accessKeySecret,
        secure: true,
        stsToken: securityToken,
        bucket: this.bucket,
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
