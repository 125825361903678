<template>
  <el-dialog
    :visible="visible"
    width="900px"
    :show-close="false"
    :close-on-click-modal="false"
    @opened="getLocation"
  >
    <div>{{ hasAddress ? "获取到下单点" : "未获取到下单点" }}</div>
    <div id="bookAddressMap" class="map"></div>
    <span slot="footer" class="footer">
      <el-button @click.stop="close" size="small">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
const markRed = require("../assets/image/mark_r.png");
const markblue = require("../assets/image/mark_b.png");
const markYellow = require("../assets/image/mark_y.png");
import { getBookAddress } from "@/api";
export default {
  name: "DriverLocation",
  props: {},
  created () {},
  data () {
    return {
      visible: false,
      hasAddress: false,
    };
  },
  methods: {
    close () {
      this.visible = false;
    },

    show (order) {
      this.order = order;
      this.visible = true;
    },

    getLocation () {
      const map = new AMap.Map("bookAddressMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      const {
        depLongitude,
        depLatitude,
        departure,
        destination,
        destLongitude,
        destLatitude,
        id,
      } = this.order || {};

      getBookAddress(id).then((res) => {
        const markers = [];
        const list = res.data || [];
        list.forEach((item) => {
          const isOrderItem = item.orderId === id;
          if (!this.hasAddress && isOrderItem) {
            this.hasAddress = true;
          }
          const marker = new AMap.Marker({
            position: [item.lng, item.lat],
            draggable: true,
            raiseOnDrag: false,
            map: map,
            label: {
              //修改label相对于maker的位置
              direction: "top",
              content: isOrderItem
                ? "<div class='info'>下单点</div>"
                : "<div class='info'>风险点</div>",
            },
            icon: new AMap.Icon({
              image: isOrderItem ? markYellow : markRed,
            }),
          });

          markers.push(marker);
        });

        const smarker = new AMap.Marker({
          position: [depLongitude, depLatitude],
          draggable: true,
          raiseOnDrag: false,
          map: map,
          label: {
            direction: "top",
            content: "<div class='info'>" + departure + "</div>",
          },
          icon: new AMap.Icon({
            image: markblue,
          }),
        });

        const emarker = new AMap.Marker({
          position: [destLongitude, destLatitude],
          draggable: true,
          raiseOnDrag: false,
          map: map,
          label: {
            direction: "top",
            content: "<div class='info'>" + destination + "</div>",
          },
          icon: new AMap.Icon({
            image: markblue,
          }),
        });

        markers.push(smarker);
        markers.push(emarker);

        map.add(markers);
        map.setFitView();
      });
    },
  },
};
</script>

<style scoped lang="less">
.slot {
  font-size: 1.5em;
  cursor: pointer;
}

.map {
  width: 850px;
  height: 600px;
}
</style>
