<template>
  <div class="root-box">
    <el-tabs value="first">
      <el-tab-pane label="阈值参数设置" name="first">
        <div>
          <el-form ref="form" class="condition-box" :inline="true" size="small">
            <div>
              <el-form-item label="路线：">
                <el-select v-model="param.pathId" @change="queryThreshold" clearable>
                  <el-option
                    v-for="item in pathOpts"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="车型：">
                <el-select v-model="param.vehicleTypeId" @change="queryThreshold" clearable>
                  <el-option
                    v-for="item in vehicleTypeOpts"
                    :key="item.id"
                    :label="item.content"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  style="margin-left: 10px; width: 100px"
                  @click="queryThreshold"
                  >查询</el-button
                >
              </el-form-item>
            </div>

            <el-form-item style="float: right">
              <el-button type="primary" :size="size" @click="openAdd">新增阈值参数</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table :data="list" stripe max-height="600">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="pathId" label="路线">
            <template slot-scope="scope">
              <span>{{ scope.row.pathId | pathNameTransfer }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="vehicleTypeId" label="车型">
            <template slot-scope="scope">
              <span>{{
                scope.row.vehicleTypeId | enumTransfer(vehicleTypeOpts, "id", "content")
              }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="thresholdOk" label="完全顺路阈值"></el-table-column>

          <el-table-column prop="thresholdMoreCost" label="平均绕路阈值"></el-table-column>

          <el-table-column
            prop="thresholdMoreCostOnStart"
            label="开始行程后最远绕路阈值"
          ></el-table-column> -->

          <el-table-column prop="depThreshold" label="接人超时阈值"></el-table-column>
          <el-table-column prop="destThreshold" label="送人超时阈值"></el-table-column>
          <el-table-column prop="routeThreshold" label="全程超时阈值"></el-table-column>
          <el-table-column prop="depLimit" label="接人限制时间"></el-table-column>
          <el-table-column prop="destLimit" label="送人限制时间"></el-table-column>

          <el-table-column prop="id" label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="delThreshold(scope.row)">删除</el-button>
              <el-button type="text" @click="copyThreshold(scope.row)">复制</el-button>
              <el-button type="text" @click="updateThreshold(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="关键点设置" name="second">
        <el-form ref="form" class="condition-box" :inline="true" size="small">
          <div>
            <el-form-item label="路线：">
              <el-select v-model="param2.pathId" @change="queryPoints" clearable>
                <el-option
                  v-for="item in pathOpts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="接人/送人：">
              <el-select
                v-model="param2.isStart"
                @change="queryPoints"
                clearable
              >
                <el-option
                  v-for="item in isStartOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item> -->

            <el-form-item label="类型：">
              <el-select v-model="param2.type" @change="queryPoints" clearable>
                <el-option
                  v-for="item in onwayPointTypeOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" style="margin-left: 10px; width: 100px" @click="queryPoints"
                >查询</el-button
              >
            </el-form-item>

            <el-form-item label="地址搜索">
              <input id="tipinput" type="text" />
            </el-form-item>
          </div>
          <div></div>
        </el-form>

        <div class="map-box">
          <div id="key_point_map"></div>

          <div class="box">
            <el-table :data="pointsList" stripe max-height="600" @row-dblclick="showPoint">
              <el-table-column label="序号" type="index" width="50"></el-table-column>

              <el-table-column prop="pathId" label="路线">
                <template slot-scope="scope">{{
                  scope.row.pathId | enumTransfer(pathOpts, "id", "name")
                }}</template>
              </el-table-column>

              <el-table-column prop="pointName" label="名称"></el-table-column>

              <el-table-column prop="type" label="类型">
                <template slot-scope="scope">
                  <span>{{ scope.row.type | enumTransfer(onwayPointTypeOpts) }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="isStart" label="接送：">
                <template slot-scope="scope">
                  <span>{{ scope.row.isStart ? "接人" : "送人" }}</span>
                </template>
              </el-table-column>

              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="openDiag(scope.row)">修改</el-button>
                  <el-button type="text" size="small" @click="syncPosition(scope.$index)"
                    >同步位置</el-button
                  >
                  <el-button type="text" size="small" @click="delPoint(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :visible.sync="visible"
      width="450px"
      max-height="600"
      title="新增路线阈值"
      :close-on-click-modal="false"
    >
      <el-form
        ref="thresholdForm"
        :model="thresholdForm"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="路线：" prop="pathId">
          <el-select v-model="thresholdForm.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车型：" prop="vehicleTypeId">
          <el-select v-model="thresholdForm.vehicleTypeId" style="width: 100%">
            <el-option
              v-for="item in vehicleTypeOpts"
              :key="item.id"
              :label="item.content"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="完全顺路阈值："
                      prop="thresholdOk">
          <el-input-number style="width: 100%"
                           v-model="thresholdForm.thresholdOk"></el-input-number>
        </el-form-item>

        <el-form-item label="平均绕路阈值："
                      prop="thresholdMoreCost">
          <el-input-number style="width: 100%"
                           v-model="thresholdForm.thresholdMoreCost"></el-input-number>
        </el-form-item>

        <el-form-item label="开始行程后最远绕路阈值："
                      prop="thresholdMoreCostOnStart">
          <el-input-number style="width: 100%"
                           v-model="thresholdForm.thresholdMoreCostOnStart"></el-input-number>
        </el-form-item> -->

        <el-form-item label="接人超时阈值：" prop="depThreshold">
          <el-input-number
            style="width: 100%"
            v-model="thresholdForm.depThreshold"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="送人超时阈值：" prop="destThreshold">
          <el-input-number
            style="width: 100%"
            v-model="thresholdForm.destThreshold"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="全程超时阈值：" prop="routeThreshold">
          <el-input-number
            style="width: 100%"
            v-model="thresholdForm.routeThreshold"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="接人限制时间：" prop="depLimit">
          <el-input-number style="width: 100%" v-model="thresholdForm.depLimit"></el-input-number>
        </el-form-item>

        <el-form-item label="送人限制时间：" prop="destLimit">
          <el-input-number style="width: 100%" v-model="thresholdForm.destLimit"></el-input-number>
        </el-form-item>
      </el-form>

      <div class="tip red">阈值换算关系： 1公里 = 60000 1分钟 = 60000</div>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">确定</el-button>
        <el-button type="info" :size="size" @click="visible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="visible2"
      width="450px"
      max-height="600"
      title="新增关键点"
      :close-on-click-modal="false"
    >
      <el-form ref="pointForm" :model="pointForm" :size="size" label-width="120px" :rules="rules2">
        <el-form-item label="路线：" prop="pathId">
          <el-select v-model="pointForm.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="接人/送人：" prop="isStart">
          <el-select v-model="pointForm.isStart" style="width: 100%">
            <el-option
              v-for="item in isStartOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="名称：" prop="thresholdOk">
          <el-input style="width: 100%" v-model="pointForm.pointName"></el-input>
        </el-form-item>

        <el-form-item label="类型：" prop="type">
          <el-select v-model="pointForm.type" style="width: 100%">
            <el-option
              v-for="item in onwayPointTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdatePoint">确定</el-button>
        <el-button type="info" :size="size" @click="visible2 = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveOnWayThreshold,
  updateOnWayThreshold,
  getOnwayThresholdList,
  delOnWayThreshold,
  getOnWayPoints,
  delOnWayPoint,
  saveOnWayPoint,
  updateOnWayPoint,
  listUpriceArea,
} from "@/api";
import { mapState } from "vuex";
import { Toolkit, MapUtil } from "@/util";
const markY = require("../../../../assets/image/mark_y.png");

export default {
  components: {},
  props: {},
  created() {
    this.queryThreshold();
    this.queryPoints();
  },
  mounted() {
    this.initMap();
  },
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
      onwayPointTypeOpts: (state) => state.enumMap["ONWAY_POINT_TYPE"],
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
    }),

    canEdit() {
      return !this.form.id;
    },
  },
  data() {
    return {
      visible: false,
      visible2: false,
      rules: {
        thresholdOk: [
          {
            required: true,
            message: "请输入完全顺路阈值",
            trigger: "blur",
          },
        ],
        thresholdMoreCost: [
          {
            required: true,
            message: "请输入开始行程后最远绕路阈值",
            trigger: "blur",
          },
        ],
        thresholdMoreCostOnStart: [
          {
            required: true,
            message: "请输入最远绕路阈值",
            trigger: "blur",
          },
        ],
        maxLimitMoreDuration: [
          {
            required: true,
            message: "请输入最大可超时间",
            trigger: "blur",
          },
        ],

        vehicleTypeId: [{ required: true, message: "请选择车型", trigger: "blur" }],
        pathId: [{ required: true, message: "请选择路线", trigger: "blur" }],
        isStart: [
          {
            required: true,
            message: "请选择接人送人",
            trigger: "blur",
          },
        ],
      },

      rules2: {
        type: [
          {
            required: true,
            message: "请选择关键点类型",
            trigger: "blur",
          },
        ],
        pointName: [
          {
            required: true,
            message: "请输入关键点名称",
            trigger: "blur",
          },
        ],
        pathId: [{ required: true, message: "请选择路线", trigger: "blur" }],
        isStart: [
          {
            required: true,
            message: "请选择接人送人",
            trigger: "blur",
          },
        ],
      },
      param: {},
      param2: {},
      thresholdForm: {},
      pointForm: {},
      isStartOpts: [
        { label: "接人", value: true },
        { label: "送人", value: false },
      ],
      list: [],
      pointsList: [],
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("key_point_map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map.on("rightclick", this.clickHandler);
      AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "tipinput",
          city: "全国",
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.on("select", this.select);
      });

      //通过content自定义右键菜单内容
      const contextMenu = new AMap.ContextMenu();

      contextMenu.addItem(
        "加入该点",
        () => {
          this.addPoint();
        },
        0
      );

      this.contextMenu = contextMenu;
    },

    openAdd() {
      this.thresholdForm = {};
      this.visible = true;
    },

    queryThreshold() {
      getOnwayThresholdList(this.param).then((res) => {
        const list = res.data || [];
        this.list = list.filter((item) => item.isStart === undefined);
      });
    },

    queryPoints() {
      getOnWayPoints(this.param2).then((res) => {
        const list = res.data || [];
        this.pointsList = list;
        this.showPoints(list);
      });
    },

    showPoint(item) {
      this.showPoints([item]);
    },

    showCityScope() {
      const { pathId } = this.param2;
      if (!pathId) {
        return;
      }
      // 城市范围
      const pp = { pathId, isStart: true, areaType: 2, _useCache: true };
      listUpriceArea(pp).then((res) => {
        const scopes = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#000",
            fillColor: "transparent",
            strokeWeight: 2,
          })
        );
        this.map.add(scopes);
      });
    },

    showPoints(list) {
      const map = this.map;
      const points = [];
      list.forEach((item) => {
        const { pointName, pointLat, pointLng } = item;
        const marker = new AMap.Marker({
          position: [+pointLng, +pointLat],
          title: pointName,
          draggable: true,
          extData: item,
          label: {
            //修改label相对于maker的位置
            direction: "top",
            content: "<div class='info active'>" + pointName + "</div>",
          },
        });

        marker.on("rightclick", (e) => {
          this.selectMarker = e.target;
          this.contextMenu.open(this.map, e.lnglat);
        });
        points.push(marker);
      });

      this.markers = points;
      map.clearMap();
      map.add(points);
      map.setFitView();
      this.showCityScope();
    },

    updateThreshold(item) {
      this.thresholdForm = { ...item };
      this.visible = true;
    },

    copyThreshold(item) {
      const thresholdForm = { ...item };
      delete thresholdForm.id;
      this.thresholdForm = thresholdForm;
      this.visible = true;
    },

    delThreshold(item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delOnWayThreshold(item).then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.queryThreshold();
        });
      });
    },

    delPoint(item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delOnWayPoint(item).then(() => {
          this.$message.success("操作成功");
          this.queryPoints();
        });
      });
    },

    syncPosition(index) {
      const marker = this.markers[index];
      const data = marker.getExtData();
      const position = marker.getPosition();
      data.pointLat = position.lat;
      data.pointLng = position.lng;
      // 同步位置
      updateOnWayPoint(data).then(() => {
        this.$message.success("操作成功");
        this.queryPoints();
      });
    },

    saveOrUpdate() {
      this.$refs.thresholdForm.validate((valid) => {
        if (!valid) {
          return;
        }

        const param = Toolkit.filterEmpty({ ...this.thresholdForm });

        if (param.id) {
          updateOnWayThreshold(param).then(() => {
            this.$message.success("操作成功");
            this.visible = false;
            this.queryThreshold();
          });
        } else {
          saveOnWayThreshold(param).then(() => {
            this.$message.success("操作成功");
            this.visible = false;
            this.queryThreshold();
          });
        }
      });
    },

    saveOrUpdatePoint() {
      this.$refs.pointForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = Toolkit.filterEmpty({ ...this.pointForm });
        if (param.id) {
          updateOnWayPoint(param).then(() => {
            this.$message.success("操作成功");
            this.visible2 = false;
            this.queryPoints();
          });
        } else {
          saveOnWayPoint(param).then(() => {
            this.$message.success("操作成功");
            this.visible2 = false;
            this.queryPoints();
          });
        }
      });
    },

    select(info) {
      this.info = info;
      this.addMarker(info.poi.location, info.poi.name);
    },

    addMarker(position, title, setFit = true) {
      if (this.marker) {
        this.map.remove(this.marker);
      }

      const marker = new AMap.Marker({
        position,
        title,
        icon: new AMap.Icon({
          image: markY,
        }),
        draggable: true,
        anchor: "bottom-center",
      });

      if (title) {
        marker.setLabel({
          //修改label相对于maker的位置
          direction: "top",
          content: "<div class='info active'>" + title + "</div>",
        });
      }

      marker.on("rightclick", (e) => {
        this.selectMarker = e.target;
        this.contextMenu.open(this.map, e.lnglat);
      });

      this.marker = marker;
      this.map.add(marker);
      if (setFit) {
        this.map.setFitView();
      }
    },

    clickHandler(ev) {
      var lnglat = ev.lnglat;
      this.addMarker(lnglat, "", false);
      const { lat, lng } = lnglat;
      this.pointForm = {
        pointName: "",
        pointLat: lat,
        pointLng: lng,
      };

      setTimeout(() => {
        this.visible2 = true;
      }, 100);
    },

    addPoint() {
      this.contextMenu.close();
      const marker = this.selectMarker;
      if (marker) {
        const latlng = marker.getPosition();
        const { lat, lng } = latlng;
        this.pointForm = {
          pointName: "",
          pointLat: lat,
          pointLng: lng,
        };
        this.visible2 = true;
      }
    },

    openDiag(item) {
      this.pointForm = { ...item };
      this.visible2 = true;
    },
  },
};
</script>
<style lang="less" scoped>
.condition-box {
  display: flex;
  justify-content: space-between;
}

.map-box {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;
  display: flex;

  .box {
    flex: 1;
    margin-left: 10px;
  }

  #key_point_map {
    height: 100%;
    width: 60vw;
  }
}

#tipinput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.tip {
  text-align: right;
}
</style>
