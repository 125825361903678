import OrderBill from '@/components/OrderBill';

export default {

  install (Vue) {
    const OrderBillClass = Vue.extend(OrderBill);

    let isMounted = false;
    let instance;

    function $orderBill () {
      if (!isMounted) {
        instance = new OrderBillClass();
        // 手动创建一个未挂载的实例
        instance.$mount();
        // 挂载
        document.querySelector('body').appendChild(instance.$el);
        isMounted = true;
      }

      return instance;
    }

    Vue.$orderBill = Vue.prototype.$orderBill = $orderBill;
  }
};
