<template>
  <div>
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="统计时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车队：" prop="teamId">
            <el-select
              v-model="param.teamId"
              clearable
              placeholder="全部"
              style="width: 100%"
            >
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div>
      <el-tabs v-model="tab">
        <el-tab-pane label="数据概况" name="ref1">
          <Summary :value="summaryData" />
        </el-tab-pane>
        <el-tab-pane label="变化趋势" name="ref2"> </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {getSafetyScoreList} from '@/api';
import {DateUtil} from '@/util';
import {mapState} from 'vuex';
import Summary from './summary';

export default {
  components: {
    Summary
  },
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      teamOpts: state => state.teamOpts
    }),
  },
  data () {
    return {
      tab: 'ref1',
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
        teamId: 1,
        period: 'day'
      },
      summaryData: {}
    };
  },
  methods: {

    query () {
      getSafetyScoreList(this.param).then(res=>{
        this.summaryData = res.data || {};
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>