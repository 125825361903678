
export const IMG_TYPE = {
  CARD_POS: 'cardPosUrl',
  CARD_NEG: 'cardNegUrl',
  CARD_PERSON: 'cardUrl',
  DRIVER_LICENCE_ID: 'licensePhotoId',
  PHOTO_ID: 'photoId',
  CAR_LICENCE_ID: 'carLicenseUrl',
  CONTRACT_URL: 'contractUrl',
  CAR_PHOTO_URL: 'photoId',
  ADVERTISEMENT: 'url',
};


export const MODE = {
  ADD: '1',
  EDIT: '2',
  VIEW: '3'
};

