import OrderLog from '@/components/OrderLog';


export default {

  install (Vue) {
    const OrderLogClass = Vue.extend(OrderLog);

    let isMounted = false;
    let instance;

    function $orderLog () {
      if (!isMounted) {
        instance = new OrderLogClass();
        // 手动创建一个未挂载的实例
        instance.$mount();

        // 挂载
        document.querySelector('body').appendChild(instance.$el);
        isMounted = true;
      }

      return instance;
    }

    Vue.$orderLog = Vue.prototype.$orderLog = $orderLog;
  }
};
