<template>
  <div class="root-box">
    <div class="box" v-if="data">
      <div class="item" v-for="item in items" :key="item.field">
        <div class="title">{{ item.name }}</div>
        <div>{{ data[item.field] }}次</div>
      </div>
    </div>
    <el-table :data="list" stripe max-height="560">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="driverNo" label="司机编号"></el-table-column>
      <el-table-column prop="driverName" label="司机姓名"></el-table-column>
      <el-table-column prop="totalOrders" label="总行程数"></el-table-column>
      <el-table-column prop="overSpeed" label="超速"></el-table-column>
      <el-table-column prop="overLoad" label="超载"></el-table-column>
      <el-table-column prop="playPhone" label="玩手机"></el-table-column>
      <el-table-column prop="noHeadphones" label="不带耳机"></el-table-column>
      <el-table-column prop="tiredness" label="疲劳驾驶"></el-table-column>
      <el-table-column prop="others" label="其他危险驾驶"></el-table-column>
      <el-table-column prop="totalSafetyNum" label="合计次数"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Object
  },
  created () {
  },
  mounted () {},
  watch: {
    value (val) {
      if (val) {
        this.list = val.list;
        this.data = val.totalServiceScore;
      } else {
        this.data = null;
        this.list = [];
      }
    }
  },
  computed: {},
  data () {
    return {
      list: [],
      data: null,
      items: [
        {name: '超速', field: 'overSpeed'},
        {name: '超载', field: 'overLoad'},
        {name: '玩手机', field: 'playPhone'},
        {name: '不带耳机通话', field: 'noHeadphones'},
        {name: '疲劳驾驶', field: 'tiredness'},
        {name: '其他', field: 'others'}
      ]
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.box {
  display: flex;
  justify-content: space-around;
  padding: 10px 0 40px;
  .item {
    width: 200px;
    height: 80px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(201, 200, 197, 0.85);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #333;

    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>