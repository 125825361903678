<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="兑换类型：" prop="state">
            <el-select
              v-model="param.ruleType"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in exchangeRuleOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>


           <el-form-item label="兑换渠道：" prop="channel">
            <el-select
              v-model="param.channel"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in channelOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="路线：" prop="pathId">
            <el-select
              v-model="param.pathId"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in pathOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="启用状态：" prop="state">
            <el-select
              v-model="param.state"
              placeholder="请选择"
              @change="query"
              clearable
            >
              <el-option
                v-for="item in yesNoOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary" :size="size" @click="openAdd"
          >新增积分兑换规则</el-button
        >
      </div>
    </div>

    <el-table :data="list" stripe max-height="650">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="ruleType" label="兑换类型">
        <template slot-scope="scope">
          <span>{{ scope.row.ruleType | enumTransfer(exchangeRuleOpts) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="pathId" label="兑换路线">
        <template slot-scope="scope">
          <div>{{ scope.row.pathId | pathNameTransfer }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="vipLevel" label="最低VIP要求">
        <template slot-scope="scope"
          ><span v-if="scope.row.vipLevel">VIP</span
          >{{ scope.row.vipLevel }}</template
        >
      </el-table-column>

      <el-table-column prop="cash" label="优惠金额"></el-table-column>

      <el-table-column prop="points" label="消耗"></el-table-column>

      <el-table-column prop="expire" label="有效期（天）"></el-table-column>

      <el-table-column prop="channel" label="兑换渠道">
        <template slot-scope="scope">
          <span>{{ scope.row.channel | enumTransfer(channelOpts) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="expireTipTypes" label="过期提醒方式">
        <template slot-scope="scope">
          <span>{{
            scope.row.expireTipTypes | enumTransfer(expireTipOpts)
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="state" label="是否启用">
        <template slot-scope="scope">{{
          scope.row.state | enumTransfer(yesNoOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)"
              >修改</el-button
            >

            <el-button type="text" size="small" @click="changeState(scope.row)">
              <span v-if="scope.row.state">停用</span>
              <span v-else class="red">启用</span>
            </el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :title="title"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="兑换类型" prop="ruleType">
          <el-select v-model="form.ruleType" style="width: 100%">
            <el-option
              v-for="item in exchangeRuleOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="兑换路线：" prop="pathId">
          <el-select v-model="form.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="最低VIP要求：" prop="vipLevel">
          <el-select v-model="form.vipLevel" clearable style="width: 100%">
            <el-option
              v-for="item in vipOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="优惠券金额：" prop="cash">
          <el-input-number
            style="width: 100%"
            v-model="form.cash"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="有效期(天)：" prop="expire">
          <el-input-number
            style="width: 100%"
            v-model="form.expire"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="消耗：" prop="points">
          <el-input-number
            style="width: 100%"
            v-model="form.points"
            placeholder
          ></el-input-number>
        </el-form-item>

        <el-form-item label="兑换渠道：" prop="channel">
          <el-select
            style="width: 100%"
            v-model="form.channel"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in channelOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="过期提醒方式" prop="expireTipTypes">
          <el-select multiple v-model="form.expireTipTypes" style="width: 100%">
            <el-option
              v-for="item in expireTipOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否启用：" prop="state">
          <el-select
            v-model="form.state"
            placeholder="请选择"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addPointsRule, modifyPointsRule, listPointsRule} from '@/api';
import _ from 'lodash';
import {Toolkit} from '@/util';
import {BaseDataService} from '@/service';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? '新增送券规则' : '修改送券规则';
    },
    ...mapState({
      size: state => state.btnSize,
      exchangeRuleOpts: state => state.enumMap['EXCHANGE_RULE_TYPE'],
      expireTipOpts: state => state.enumMap['COUPON_TIP_TYPE'],
      channelOpts: state => state.enumMap['EXCHANGE_CHANNEL'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  data () {
    return {
      MODE,
      visible: false,
      param: {},
      form: {},
      list: [],
      mode: MODE.EDIT,
      yesNoOpts: BaseDataService.yesNoOpts(),
      vipOpts: [{label: 'VIP1', value: 1}, {label: 'VIP2', value: 2}, {label: 'VIP3', value: 3}, {label: 'VIP4', value: 4}],
      rules: {

        pathId: [
          {required: true, message: '路线不能为空', trigger: 'blur'},
        ],
        points: [
          {required: true, message: '消耗积分不能为空', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '是否启用不能为空', trigger: 'blur'},
        ],
        cash: [
          {required: true, message: '送券金额不能为空', trigger: 'blur'},
        ],
        expire: [
          {required: true, message: '有效期不能为空', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {
    closeDiag () {
      this.visible = false;
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.visible = true;
      this.form = _.cloneDeep(item);
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {
        state: true
      };
    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const data = _.cloneDeep(this.form);

        if (this.mode === MODE.EDIT) {
          modifyPointsRule(data).then(() => {
            this.$message.success("送券规则修改成功");
            this.visible = false;
            this.query();
          });
        } else {
          addPointsRule(data).then(() => {
            this.$message.success("送券规则新增成功");
            this.visible = false;
            this.query();
          });
        }

      });

    },

    changeState (item) {
      const data = {
        id: item.id,
        state: item.state ? 0 : 1
      };
      modifyPointsRule(data).then(() => {
        this.$message.success("状态修改成功");
        this.query();
      });
    },
    query () {
      listPointsRule(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
    }

  }
};
</script>

<style scoped lang="less">
</style>
