import {Http} from '@/util';

// 保存关键点
export function saveOnWayPoint (param) {
  return Http.post('/admin/onway/point/save', param);
}

// 删除关键点
export function delOnWayPoint (param) {
  return Http.post('/admin/onway/point/del', param);
}

// 更新关键点
export function updateOnWayPoint (param) {
  return Http.post('/admin/onway/point/update', param);
}

// 查询关键点
export function getOnWayPoints (param) {
  return Http.post('/admin/onway/point/list', param);
}

// 保存阈值
export function saveOnWayThreshold (param) {
  return Http.post('/admin/onway/threshold/save', param);
}

// 删除阈值
export function delOnWayThreshold (param) {
  return Http.post('/admin/onway/threshold/del', param);
}

// 更新阈值
export function updateOnWayThreshold (param) {
  return Http.post('/admin/onway/threshold/update', param);
}

// 查询阈值
export function getOnwayThresholdList (param) {
  return Http.post('/admin/onway/threshold/list', param);
}