<template>
  <div class="incr-box">
    <div class="head">
      <div class="item">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <el-select
          class="span"
          clearable
          v-model="param.registerSrc"
          placeholder="全部（客户端）"
        >
          <el-option
            v-for="item in registerSrcOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>

        <el-select
          class="span"
          clearable
          v-model="param.days"
          placeholder="全部（行为）"
        >
          <el-option
            v-for="item in orderTimeOpt"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>

        <el-button
          type="primary"
          style="width: 120px; float: right"
          class="span"
          @click="query"
          >查询</el-button
        >
      </div>
    </div>

    <div class="trans-box">
      <div class="left">
        <div>
          <span class="label">【1】 线上下单用户：</span>
          <span>{{ passengerTransferData.totalOrderPerson }}人</span>
        </div>
        <div>
          <span class="label">【2】 参与邀请：</span>
          <span>{{ passengerTransferData.totalJoinPerson }}人</span>
        </div>
        <div>
          <span class="label"
            >【3】 {{ passengerTransferData.totalJoinPerson }}人累计成交：</span
          >
          <span> {{ passengerTransferData.joinTotalFinishNum }}单</span>
        </div>
        <div>
          <span class="label"
            >【4】 {{ passengerTransferData.totalJoinPerson }}人邀请了：</span
          >
          <span>{{ passengerTransferData.totalInvitedPerson }}人</span>
        </div>
        <div>
          <span class="label"
            >【5】
            {{ passengerTransferData.totalInvitedPerson }}人中下单：</span
          >
          <span>{{ passengerTransferData.totalPersonNum }}人</span>
        </div>

        <div>
          <span class="label"
            >【6】 {{ passengerTransferData.totalPersonNum }}人下单：</span
          >
          <span>{{ passengerTransferData.totalOrderNum }}单</span>
        </div>

        <div>
          <span class="label"
            >【7】 {{ passengerTransferData.totalPersonNum }}人成交：</span
          >
          <span>{{ passengerTransferData.totalFinishPersonNum }}人</span>
        </div>

        <div>
          <span class="label"
            >【8】成交{{
              passengerTransferData.totalFinishPersonNum
            }}人产生：</span
          >
          <span>{{ passengerTransferData.finishOrderNum }}单</span>
        </div>

        <div>
          <span class="label"
            >【9】成交{{
              passengerTransferData.totalFinishPersonNum
            }}人产生：</span
          >
          <span>{{ passengerTransferData.finishOrderTicketNum }}人次</span>
        </div>
      </div>
      <div class="right">
        <div>
          <span class="label imp">参与率：</span>
          <span>{{
            (passengerTransferData.totalJoinPerson /
              passengerTransferData.totalOrderPerson)
              | precision(2)
          }}</span>
        </div>

        <div>
          <span class="label">人均邀请人数：</span>
          <span
            >{{
              (passengerTransferData.totalInvitedPerson /
                passengerTransferData.totalJoinPerson)
                | precision(2)
            }}人</span
          >
        </div>

        <div>
          <span class="label">有效转化率A：</span>
          <span
            >{{
              (passengerTransferData.totalPersonNum /
                passengerTransferData.totalInvitedPerson)
                | percent
            }}
            【5/4】</span
          >
        </div>

        <div>
          <span class="label imp">有效转化率B：</span>
          <span
            >{{
              (passengerTransferData.totalFinishPersonNum /
                passengerTransferData.totalInvitedPerson)
                | percent
            }}
            【7/4】</span
          >
        </div>

        <div>
          <span class="label">人均转化邀请A：</span>
          <span
            >{{
              (passengerTransferData.totalPersonNum /
                passengerTransferData.totalJoinPerson)
                | precision(2)
            }}人 【5/2】</span
          >
        </div>

        <div>
          <span class="label imp">人均转化邀请B：</span>
          <span
            >{{
              (passengerTransferData.totalFinishPersonNum /
                passengerTransferData.totalJoinPerson)
                | precision(2)
            }}
            【7/2】</span
          >
        </div>

        <div>
          <span class="label">人均转化订单：</span>
          <span
            >{{
              (passengerTransferData.totalOrderNum /
                passengerTransferData.totalJoinPerson)
                | precision(2)
            }}
            【8/6】</span
          >
        </div>

        <div>
          <span class="label impl">人均转化人次：</span>
          <span
            >{{
              (passengerTransferData.finishOrderTicketNum /
                passengerTransferData.totalJoinPerson)
                | precision(2)
            }}
            【9/2】</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ParamUtil, DateUtil} from '@/util';
import {passengerTransAnalysis} from '@/api';


const registerOptions = [{label: "自注册", value: 3}, {label: "分享注册", value: 4}, {label: "助力注册", value: 5}, {label: "广告注册", value: 0}];
export default {
  components: {
  },


  filters: {
    percent (val) {
      return Number(val * 100).toFixed(2) + '%';
    },
    precision (val, precision) {
      return Number(val).toFixed(precision) ;
    }
  },

  data () {
    return {
      param: {
        startDate: "",
        endDate: "",
        registerSrc: "",
        days: ""
      },
      registerOptions,
      registerSrcOptions: [{label: "APP注册", value: "app"}, {label: "微信注册", value: "wx"}],
      orderTimeOpt: [{label: "1个月内", value: 30}, {label: "3个月内", value: 90}, {label: "6个月内", value: 180}, {label: "不限时间", value: ""}],
      passengerTransferData: {}
    };
  },
  mounted () {
    //默认一个月的时间跨度
    //注册增长
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime(1));
  },

  methods: {

    query () {
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("请选择起止日期。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截至日期。");
        return;
      }

      passengerTransAnalysis(ParamUtil.filterEmptyData(this.param)).then(res=>{
        this.passengerTransferData = res.data || {};
      });
    },




  }
};
</script>

<style lang="less" scoped>
.incr-box {
  min-width: 1200px;
  padding: 10px;
  background-color: #edf1f5;

  .el-date-editor--date {
    width: 150px !important;
  }
  .el-select {
    width: 150px !important;
  }

  .head,
  .sub-head {
    margin-bottom: 10px;
    .span {
      margin-left: 8px;
    }
  }

  .table {
    position: relative;

    .right {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .total {
      padding: 16px 20px;
      background-color: white;
      display: flex;
      justify-content: space-between;

      .item {
        margin: 0 20px;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
      }
    }

    &.main {
      border: 1px solid #ddd;
      padding: 10px;
    }

    &.pie {
      display: grid;
      grid-template-columns: repeat(2, 49%);
      grid-template-rows: repeat(2, 368px);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      padding-top: 10px;

      > div.item {
        background: #fff;
        border: 1px solid #ddd;
        position: relative;
      }

      .sub-head {
        padding: 10px;
      }

      .sub-item {
        height: 300px;
      }
    }
  }

  .trans-box {
    display: flex;
    .left {
      margin-right: 60px;
      > div {
        margin: 10px 0;
      }
    }

    .right {
      .imp {
        font-weight: bold;
        color: orange;
      }
      > div {
        margin: 10px 0;
      }
    }
  }
}
</style>