import { Http } from "@/util";

// 处理告警
export function handleWarning(check) {
  return Http.post("/admin/route/check/handle_waring", check);
}

// 获取告警
export function getProblem() {
  return Http.get("/admin/route/check/get_problem");
}
