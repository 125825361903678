<template>
  <div>
    <el-divider content-position="left">改签项目</el-divider>

    <div>

      <div class="box1"
           v-if="data.changeType === 1">
        <div>
          <span>原时间：</span>
          <span>{{ data.preOrder.date | dateFormat("MM月dd日") }} {{ data.preOrder.showTime }}</span>
        </div>
        <div>
          <span>现时间：</span>
          <span>{{ data.order.date | dateFormat("MM月dd日") }} {{ data.order.showTime }}</span>
        </div>
      </div>

      <div class="box1"
           v-if="data.changeType === 4">
        <div>
          <span>原起点：</span>
          <span>{{ data.preOrder.departure }} </span>
        </div>
        <div>
          <span>现起点：</span>
          <span>{{ data.order.departure }} </span>
        </div>
      </div>

      <div class="box1"
           v-if="data.changeType === 5">
        <div>
          <span>原终点：</span>
          <span>{{ data.preOrder.destination }} </span>
        </div>
        <div>
          <span>现终点：</span>
          <span>{{ data.order.destination }} </span>
        </div>
      </div>

      <div v-if="data.changeType === 2">
        增加{{ data.order.adultNumber - data.preOrder.adultNumber}}张成人票
      </div>

      <div v-if="data.changeType === 3">
        <div v-if="data.preOrder.adultNumber - data.order.adultNumber === 0"> 取消订单，全部退票</div>
        <div v-if="data.preOrder.adultNumber - data.order.adultNumber > 0"> 退订{{ data.preOrder.adultNumber - data.order.adultNumber }}张成人票</div>
        <div v-if="data.preOrder.childNumber - data.order.childNumber > 0"> 退订{{ data.preOrder.childNumber - data.order.childNumber }}张儿童票</div>
      </div>

      <div v-if="data.changeType === 7">
        选座</div>

      <div v-if="data.changeType === 11">
        补购儿童票</div>

      <div v-if="data.changeType === 12">
        儿童票补全票</div>

      <div v-if="data.changeType === 13">
        拼车改{{data.order.adultNumber}}座包车</div>

      <div v-if="data.changeType === 100">
        其他补票</div>

    </div>

    <div class="main-box">

      <div class="price-box">
        <el-divider content-position="left">改签费用明细</el-divider>

        <div class="billItem"
             v-for="item in data.billDetail.billBlock.billItems"
             :key="item.title">

          <DetailItem :name="item.title"
                      :price="item.amount"
                      :level="1"
                      :show="true"
                      :desc="item.detailDesc"
                      :isDiscount="item.discount" />
        </div>

        <div v-if="data.transactionBill.price >= 0"
             class="sum">
          <span>合计应支付</span>
          <span class="price">{{ data.transactionBill.price| moneyFormat(0) }}</span>
          <span>元</span>
        </div>
        <div v-else-if="data.transactionBill.price < 0"
             class="sum">
          <span>合计应退款</span>
          <span class="price refund">{{ -data.transactionBill.price| moneyFormat(0) }}</span>
          <span>元</span>
        </div>
      </div>

      <div class="spe"></div>

      <div v-if="data.fullBillDetail"
           class="price-box">
        <el-divider content-position="right">订单费用明细</el-divider>

        <div class="billItem"
             v-for="item in data.fullBillDetail.billBlock.billItems"
             :key="item.title">

          <DetailItem :name="item.title"
                      :price="item.amount"
                      :level="1"
                      :show="true"
                      :desc="item.detailDesc"
                      :isDiscount="item.discount" />
        </div>

        <div class="sum">
          <span>合计应支付</span>
          <span class="price">{{ data.fullBillDetail.billBlock.price| moneyFormat(0) }}</span>
          <span>元</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import DetailItem from "./DetailItem";

export default {
  components: {
    DetailItem,
  },

  props: {
    data: Object,
  },

  watch: {},

  cteated () {},

  mounted () {},

  computed: {},

  data () {
    return {};
  },

  methods: {},
};
</script>
<style scoped lang="less">
.box1 {
  div:first-child {
    margin-bottom: 6px;
  }
}
.main-box {
  display: flex;

  .spe {
    width: 80px;
  }
  .price-box {
    flex: 1;
    .price {
      font-size: 18px;
      font-weight: bold;
      color: red;
      position: relative;
      top: -3px;
      &.refund {
        color: green;
      }
    }

    .sum {
      margin: 10px 0 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
