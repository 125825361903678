<template>
  <div class="box">
    <div class="head">
      <div class="item">本月流水总计 {{ summary.totalPrice }}</div>
      <div class="item">本月补贴总计 {{ summary.totalDiscount }}</div>
      <div class="item">本月实收流水 {{ summary.price }}</div>
      <div class="item">本月收益 {{ summary.totalBaseIncome }}</div>
    </div>

    <div class="table">
      <div class="item">
        <chart-view
          ref="chart1"
          valueField="totalPrice"
          nameField="name"
          title="本月车队流水占比"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart2"
          valueField="totalPrice"
          nameField="name"
          title="本月渠道流水占比"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart3"
          valueField="totalBaseIncome"
          nameField="name"
          title="本月车队收益占比"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart4"
          valueField="totalDiscount"
          nameField="name"
          title="本月车队补贴占比"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>


import {querySettlementOverview} from '@/api';
import ChartView from '../../ChartView';
import {mapState} from 'vuex';

export default {
  components: {
    ChartView
  },
  props: {

  },
  created () {
    this.query();
  },
  mounted () {

  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderSourceMap: state => {
        const arr = state.enumMap['ORDER_SOURCE'];
        const map = {};
        if (arr) {
          arr.forEach(item=> map[item.value] = item.label);
        }
        return map;

      },
      teamOpts: state => state.teamOpts

    }),
    teamMap () {
      const map = {};
      const arr = this.teamOpts || [];
      arr.forEach(item=> map[item.id] = item.name);
      return map;
    },
  },
  data () {
    return {
      summary: {},

    };
  },
  methods: {


    query () {
      querySettlementOverview().then(res=>{
        const {summary = {}, teamTotalPrice = [], teamTotalDiscount = [],
          sourceTotalPrice = [], teamCommission = []} = res.data || {};
        this.summary = summary;

        const teamMap = this.teamMap;
        const data1 = teamTotalPrice.map(item=> {
          return {
            name: teamMap[item.teamId],
            ...item
          };
        });
        this.$refs.chart1.setData(data1);

        const orderSourceMap = this.orderSourceMap;

        const data2 = sourceTotalPrice.map(item=> {
          return {
            name: orderSourceMap[item.orderSource],
            ...item
          };
        });

        this.$refs.chart2.setData(data2);


        const data3 = teamCommission.map(item=> {
          return {
            name: teamMap[item.teamId],
            ...item
          };
        });

        this.$refs.chart3.setData(data3);

        const data4 = teamTotalDiscount.map(item=> {
          return {
            name: teamMap[item.teamId],
            ...item
          };
        });

        this.$refs.chart4.setData(data4);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  background-color: #edf1f5;
  .head {
    padding: 10px 20px;
    display: flex;
    height: 80px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    .item {
      flex: 1;
      text-align: center;
    }
  }

  .table {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-template-rows: repeat(2, 300px);
    grid-row-gap: 40px;
    grid-column-gap: 4%;
    padding: 20px;

    > div {
      background: #fff;
      border: 1px solid #ddd;
      position: relative;
    }
  }
}
</style>