const NAME_SPACE = "YX_30_";
export default class SessionStorage {
  static get(key) {
    const realKey = SessionStorage.getKey(key);
    const value = sessionStorage[realKey];
    try {
      return JSON.parse(value);
    } catch (error) {
      return undefined;
    }
  }

  static set(key, value) {
    const realKey = SessionStorage.getKey(key);
    const val = JSON.stringify(value);
    sessionStorage[realKey] = val;
  }

  static clear(key) {
    const realKey = SessionStorage.getKey(key);
    sessionStorage.removeItem(realKey);
  }

  // 清空本地缓存
  static clearAll() {
    const length = sessionStorage.length;
    let removeItem = [];
    for (let index = 0; index < length; index++) {
      const key = sessionStorage.key(index);
      if (key.startsWith(NAME_SPACE)) {
        removeItem.push(key);
      }
    }

    removeItem.forEach((item) => {
      sessionStorage.removeItem(item);
    });
  }

  static getKey(key) {
    return `${NAME_SPACE}${key}`;
  }
}
