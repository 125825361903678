<template>
  <div class="box">
    <div class="head">
      <div class="item">
        {{ summary.onlineNum }}人
        <div>线上总注册量</div>
      </div>
      <div class="item">
        {{ summary.appInstall }}人
        <div>app下载量</div>
      </div>
      <div class="item">
        {{ summary.appNum }}人（{{ summary.appPureNum }}人）
        <div>app注册量</div>
      </div>
      <div class="item">
        {{ summary.minappNum }}人
        <div>小程序使用数</div>
      </div>
      <div class="item">
        {{ summary.h5FilteredNum }}人
        <div>H5使用数</div>
      </div>
      <div class="item">
        {{ summary.totalNum }}人
        <div>总注册用户数</div>
      </div>
    </div>

    <div class="table">
      <div class="item">
        <chart-view
          ref="chart1"
          valueField="value"
          nameField="name"
          title="用户分布"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart2"
          valueField="value"
          nameField="name"
          title="用户去重分布"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart3"
          valueField="value"
          nameField="name"
          title="VIP用户占比分析"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart4"
          valueField="value"
          nameField="name"
          title="线上用户下单占比分析"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart5"
          valueField="value"
          nameField="name"
          title="成交用户占比分析"
          initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
          ref="chart6"
          valueField="value"
          nameField="name"
          title="成交次数占比分析"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../ChartView';
export default {
  components: {
    ChartView
  },
  props: {
  },
  data () {
    return {
      summary: {
        wxNum: 0,
        appNum: 0,
        minappNum: 0,
        totalNum: 0
      },
    };
  },
  created () {
    this.query();
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    query () {
      this.$http.post("/admin/analysis/passenger/overview", {}).then(res=>{
        this.summary = res.data || {};
        let data1 = [
          {name: "电话", value: this.summary.telNum},
          {name: "微信", value: this.summary.wxNum},
          {name: "APP", value: this.summary.appNum}];
        let data2 = [{name: "电话", value: this.summary.telNum},
          {name: "公众号", value: this.summary.h5FilteredNum},
          {name: "APP", value: this.summary.appFilteredNum},
          {name: "小程序", value: this.summary.minappFiltereNum}];
        let data3 = [{name: "普通用户", value: this.summary.totalNum - this.summary.vip1 - this.summary.vip2 - this.summary.vip3 - this.summary.vip4},
          {name: "vip1", value: this.summary.vip1},
          {name: "vip2", value: this.summary.vip2},
          {name: "vip3", value: this.summary.vip3},
          {name: "vip4", value: this.summary.vip4}];
        let data4 = [{name: "未下单用户", value: this.summary.onlineNum - this.summary.orderNum},
          {name: "已下单用户", value: this.summary.orderNum}];
        let data5 = [{name: "未成交", value: this.summary.totalNum - this.summary.orderFinishNum},
          {name: "已成交", value: this.summary.orderFinishNum}];
        let data6 = [{name: "成交1次", value: this.summary.finishNum1},
          {name: "成交2-5次", value: this.summary.finishNum2},
          {name: "成交6-15次", value: this.summary.finishNum3},
          {name: "成交16-30次", value: this.summary.finishNum4},
          {name: "成交31-60次", value: this.summary.finishNum5},
          {name: "成交60次以上", value: this.summary.finishNum6}];
        this.$refs.chart1.setData(data1);
        this.$refs.chart2.setData(data2);
        this.$refs.chart3.setData(data3);
        this.$refs.chart4.setData(data4);
        this.$refs.chart5.setData(data5);
        this.$refs.chart6.setData(data6);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  background-color: #edf1f5;
  .head {
    padding: 10px 20px;
    display: flex;
    height: 80px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    .item {
      flex: 1;
      text-align: center;
    }
  }

  .table {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-template-rows: repeat(2, 300px);
    grid-row-gap: 40px;
    grid-column-gap: 4%;
    padding: 20px;

    > div {
      background: #fff;
      border: 1px solid #ddd;
      position: relative;
    }
  }
}
</style>