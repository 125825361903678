<template>
  <div>
    <el-dialog
      :visible="visible"
      width="600px"
      :show-close="false"
      :close-on-click-modal="false"
      @open="getList"
      title="在线客服"
    >
      <el-table :data="list" border size="medium">
        <el-table-column prop="name" label="客服"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="kickout(scope.row)"
              >强制下线</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="footer">
        <el-button @click.stop="close" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {getOnlineKefuList, kickoffKefu} from '@/api';
export default {
  components: {},
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      list: [],
      visible: false
    };
  },
  methods: {
    show () {
      this.visible = true;
    },
    close () {
      this.visible = false;
    },
    getList () {
      getOnlineKefuList().then(res=>{
        this.list = res.data || [];
      });
    },

    kickout (item) {
      kickoffKefu(item.id).then(()=>{
        this.$message.success('操作成功');
        this.getList();
      });
    }
  },
};
</script>
<style lang="less" scoped>
</style>