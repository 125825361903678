
// initial state
const state = {
  initData: {},
  flCodeOpts: [{label: '日常奖励', value: '1'}, {label: '油车奖励', value: '4'}, {label: '新手奖励', value: '2'}, {label: '巨额奖励', value: '3'}],
};

// getters
const getters = {};

// actions
const actions = {

};

// mutations
const mutations = {
  setInitData (state, data) {
    state.initData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};