<template>
  <div>
    <div style="margin: 0 10px 20px">
      <el-switch
        v-model="enablePreConfition"
        active-text="前置条件"
        active-color="#13ce66"
      >
      </el-switch>
    </div>

    <UserFilter
      :isSubFilter="false"
      :isDisable="!enablePreConfition"
      :isOnline="true"
      v-model="f1"
    />
    <el-divider></el-divider>
    <UserFilter :isSubFilter="true" :isOnline="true" v-model="f2" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import UserFilter from './UserFilter.vue';
import {DateUtil, Toolkit} from '@/util';
import _ from 'lodash';

const defaultData = {
  startDate: DateUtil.getDayStr(-90),
  endDate: DateUtil.getDayStr(0),
  regType: [],
  couponType: [],
  pathIds: []
};

export default {
  components: {
    UserFilter,

  },
  computed: {
    ...mapState({
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  props: {
    value: Object
  },
  created () {},
  mounted () {},
  watch: {},
  data () {
    return {
      enablePreConfition: true,
      f1: _.cloneDeep(defaultData),
      f2: _.cloneDeep(defaultData),
      list: []

    };
  },
  methods: {

    getParam () {
      let param ;
      if (this.enablePreConfition) {
        param = {
          filter1: Toolkit.filterEmpty(this.f1),
          filter2: Toolkit.filterEmpty(this.f2),
          isOnline: true,
        };
      } else {
        param = {
          filter2: Toolkit.filterEmpty(this.f2),
          isOnline: true,
        };
      }
      return param;
    },

    setParam (f1, f2) {
      this.filter1 = f1;
      this.filter2 = f2;
    },

    initData () {
      this.f1 = _.cloneDeep(defaultData);
      this.f2 = _.cloneDeep(defaultData);
    }

  },
};
</script>
<style lang="less" scoped>
</style>