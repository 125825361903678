<template>
    <div class="root-box">
        <div class="btn-box">
            <el-button type="primary" size="small" plain @click="query">刷新</el-button>
        </div>

        <div class="box">

            <div class="item" v-for="(list, areaId) in schedulingMap" :key="areaId">
                <DriverList :data="list" :areaId="parseInt(areaId)" :onSchedulingChange="query"
                    :onModifyProportion="onModifyProportion" />
            </div>
        </div>

        <el-dialog :visible.sync="visible" width="500px" max-height="600" :size="size" title="设置发班比例"
            :close-on-click-modal="false">

            <el-table :data="areaList" stripe max-height="680">

                <el-table-column label="序号" type="index" width="50">
                </el-table-column>

                <el-table-column label="路线">
                    <template slot-scope="scope">{{
                        scope.row.pathId | enumTransfer(pathOpts, "id", "name")
                    }}</template>
                </el-table-column>

                <el-table-column label="发班比例（%）">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.proportion" style="width: 180px"></el-input-number>
                    </template>
                </el-table-column>
            </el-table>


            <span slot="footer">
                <el-button size="small" @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="saveProportion" size="small">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import DriverList from "./DriverList";
import { getSchedulingMap, saveAreaSchedulingProportion, getAreaSchedulingProportion } from "@/api";
import {mapState} from "vuex";

export default {
  components: {
    DriverList,
  },

  created () {
    this.query();
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
    }),

  },

  data () {
    return {
      visible: false,
      schedulingMap: {},
      proportionList: [],
      areaList: [],
    };
  },

  methods: {
    async query () {
      const resp = await getSchedulingMap();
      this.schedulingMap = resp.data;
      const proportionResp = await getAreaSchedulingProportion();
      this.proportionList = proportionResp.data;
    },

    onModifyProportion (areaId) {
      // 根据areaId过滤出路线列表
      this.areaList = this.proportionList.filter(item => item.areaId === areaId).map(t => {
        return { ...t };
      });

      if (this.areaList.length < 2) {
        this.$message.warning("单一路线不需要设置发班比例");
        return;
      }
      this.visible = true;
    },

    saveProportion () {
      const areaId = this.areaList[0].areaId;
      const otherList = this.proportionList.filter(item => item.areaId !== areaId);
      const param = [...otherList, ...this.areaList];
      saveAreaSchedulingProportion(param, areaId).then(() => {
        this.proportionList = param;
        this.$message.success("保存成功");
        this.visible = false;
      });
    }
  }
};
</script>

<style scoped lang="less">
.root-box {
    display: flex;
    flex-direction: column;

    .btn-box {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .box {
        overflow: auto;
        height: 750px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* 创建两列，每列的宽度自动平分 */
        grid-gap: 10px;
    }
}
</style>
