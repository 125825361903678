<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="概况" name="ref1">
        <Summary ref="ref1" />
      </el-tab-pane>

      <el-tab-pane label="常规" name="ref2">
        <DiscountType ref="ref2" />
      </el-tab-pane>

      <el-tab-pane label="定项" name="ref3">
        <Spec ref="ref3" />
      </el-tab-pane>

      <el-tab-pane label="发券" name="ref4">
        <SendCoupon ref="ref4" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Summary from './Summary';
import DiscountType from './DiscountType';
import Spec from './Spec';
import SendCoupon from './SendCoupon';

export default {
  components: {
    Summary,
    DiscountType,
    Spec,
    SendCoupon
  },
  props: {},
  created () {

  },
  mounted () {
    this.query();
  },
  watch: {},
  computed: { },
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {
    query () {
      this.$refs[this.tab].query();
    }
  },
};
</script>
<style lang="less" scoped>
</style>