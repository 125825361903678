<template>
  <span>
    <span v-if="!$slots.default">
      <i
        @click="showTelRecord"
        class="el-icon-phone pointer"
        :class="{
          'bind-status-1': status === 1,
          'bind-status-2': status === 2,
        }"
      ></i>

      <span v-if="userType === 2">(乘)</span>
    </span>

    <span @click="showTelRecord">
      <slot></slot>
    </span>

    <el-dialog
      :visible="visible"
      width="1200px"
      :before-close="closeWin"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <div
        style="
          text-align: center;
          margin-bottom: 10px;
          font-size: 16px;
          background-color: rgba(0, 0, 0, 0.3);
          padding: 10px;
          color: white;
        "
      >
        <div class="title">
          虚拟号码 {{ prinum }}
          <span v-if="prinum">（{{ status | desc }}）</span>
        </div>
      </div>

      <div>
        <div>
          <el-table
            :v-loading="loading"
            :data="telRecordList"
            border
            max-height="250"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>
            <el-table-column prop="directionDesc" label="方向" width="180">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="点击查看司机位置" placement="top">
                  <el-button @click="showDriverLocation(scope.row)" type="text">{{
                    scope.row.directionDesc
                  }}</el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="callInTime" label="时间" width="160"></el-table-column>
            <el-table-column prop="senonds" label="通话时长" width="80"></el-table-column>
            <el-table-column prop="status" label="通话状态" width="80"></el-table-column>
            <el-table-column prop="driverNo" label="司机编号" width="100"></el-table-column>
            <el-table-column label="录音">
              <template slot-scope="scope">
                <div v-if="scope.row.recordUrl">
                  <audio
                    class="record-audio"
                    :src="scope.row.recordUrl"
                    controls="controls"
                  ></audio>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="type !== 4" label="录音抽检" width="200">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.checkStatus"
                  size="small"
                  placeholder="标记"
                  style="width: 150px"
                  clearable
                  @change="(val) => changeCheckStatus(scope.row)"
                >
                  <el-option
                    v-for="item in checkOpts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin: 10px 0">
          <el-table
            :v-loading="loading"
            :data="telMsgList"
            border
            max-height="250"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>
            <el-table-column prop="directionDesc" label="方向"></el-table-column>
            <el-table-column prop="smsContent" label="短信内容"></el-table-column>
            <el-table-column prop="timeStamp" label="时间"></el-table-column>

            <el-table-column prop="driverNo" label="司机编号"></el-table-column>
          </el-table>
        </div>
      </div>

      <div style="margin: 20px 0 0; text-align: center">
        <el-button
          v-if="type !== 4"
          type="danger"
          @click="unBind"
          :size="size"
          :disabled="status !== 1 && status !== 2"
          >取消绑定</el-button
        >
        <el-button type="info" :size="size" @click="closeWin">关闭</el-button>
        <el-button
          v-if="type !== 4"
          type="info"
          :size="size"
          @click="driverCalled"
          :disabled="isContacted"
          >司机已联系</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      :visible="checkModalVisible"
      width="500px"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <div class="header">不合格原因</div>

      <el-input
        type="textarea"
        v-model="remark"
        :rows="3"
        style="width: 400px"
        placeholder="20个字以内"
      ></el-input>

      <div class="tags">
        <el-tag :key="item" size="small" v-for="item in msgs" @click="remark = item">{{
          item
        }}</el-tag>
      </div>

      <div style="margin: 20px 0 0; text-align: center">
        <el-button :size="size" @click="submitCheck(false)">取消</el-button>
        <el-button type="primary" :size="size" @click="submitCheck(true)">确认</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import {
  getPrinumRecords,
  unbindAxb,
  downloadRecord,
  setDriverCalled,
  saveQcResource,
} from "@/api";
import { DateUtil, OssUtil, Http, Toolkit } from "@/util";
import { SHOW_DRIVER_SPEC_LOCATION } from "@/core/const/global-event";
import _ from "lodash";
export default {
  props: {
    type: Number,
    userTel: String,
    bindId: String,
    prinum: String,
    queryKey: String,
    status: Number,
    orderId: Number,
    showModel: String,
    userType: Number,
    isContacted: Boolean,
    isShuntStart: Boolean,
  },

  filters: {
    desc(val) {
      let str = "";
      switch (val) {
        case 1:
          str = "已绑定，未联系";
          break;
        case 2:
          str = "已绑定，已联系";
          break;
        case 3:
          str = "解绑";
          break;
      }
      return str;
    },
  },
  data() {
    return {
      checkModalVisible: false,
      visible: false,
      telRecordList: [],
      telMsgList: [],
      loading: false,
      size: "small",
      checkOpts: [
        { label: "合格", value: 0 },
        { label: "不合格", value: 1 },
      ],
      remark: "",
      msgs: ["没有表述身份", "表述身份不规范"],
    };
  },
  methods: {
    showTelRecord() {
      this.query();
      this.visible = true;
    },
    closeWin() {
      this.telRecordList = [];
      this.visible = false;
    },
    driverCalled() {
      this.$confirm("确认司机已联系乘客吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        setDriverCalled(this.orderId).then(() => {
          this.$message.success("操作成功");
        });
      });
    },

    // submitCheck(submit) {
    //   const item = this.selectItem;
    //   if (submit) {
    //     if (!this.remark) {
    //       this.$message.error("请输入不合格原因");
    //       return;
    //     }

    //     const param = {
    //       type: "6-1",
    //       orderId: this.orderId,
    //       routeId: item.routeId,
    //       driverId: item.driverId,
    //       remark: this.remark,
    //       recordUrl: item.recordUrl,
    //       icid: item.icid,
    //       checkStatus: 1,
    //     };
    //     addCommunicationCheckRecord(param).then(() => {
    //       item.checkStatus = 1;
    //       this.$set(this.telRecordList, this.selectItemIndex, item);
    //       this.telRecordList = _.cloneDeep(this.telRecordList);
    //       this.$message.success("操作成功");
    //     });
    //   } else {
    //     item.checkStatus = 0;
    //     this.$set(this.telRecordList, this.selectItemIndex, item);
    //     this.telRecordList = _.cloneDeep(this.telRecordList);
    //   }
    //   this.checkModalVisible = false;
    // },

    changeCheckStatus(item) {
      this.$prompt("备注，可不填", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.saveTelRecord(
            item.recordUrl,
            item.icid,
            item.orderId,
            item.routeId,
            item.driverId,
            value
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async saveTelRecord(url, oriResourceId, orderId, routeId, driverId, remark) {
      const fileType = Toolkit.getFileType(url);
      const res = await Http.get(url, null, { responseType: "blob" });
      const blob = res.data;
      // 获取当前日期时间
      const now = DateUtil.format(new Date(), "yyyyMMddhhmmss");
      const fileName = `qc_tel_${driverId}_${now}` + "." + fileType;
      const file = new File([blob], fileName, { type: blob.type });

      const ossClient = await OssUtil.getOssClient("yxmgt-private");
      const fullName = `qc/tel_record/${fileName}`;
      ossClient
        .put(fullName, file)
        .then((res) => {
          const param = {
            fileType,
            url: res.url,
            name: fileName,
            bizType: "CALL_RECORDING",
            remark,
            oriResourceId,
            oriUrl: url,
            routeId,
            orderId,
            driverId,
          };

          saveQcResource(param).then(() => {
            this.$message.success("保存成功");
          });
        })
        .catch((err) => {
          console.error(err);
          this.$message.error("上传失败");
        });
    },

    showDriverLocation(item) {
      this.$bus.$emit(SHOW_DRIVER_SPEC_LOCATION, item);
    },
    downloadTelRecord(item, index) {
      const data = {
        domainMain: item.recordDomain,
        objectName: item.recordObjectName,
      };
      downloadRecord(data).then((response) => {
        if (response.data) {
          item.recordUrl = "/download/record?url=" + response.data;
          this.$set(this.telRecordList, index, item);
          this.telRecordList = _.cloneDeep(this.telRecordList);
        } else {
          this.$message({
            message: "没有录音",
            type: "warning",
          });
        }
      });
    },
    unBind() {
      this.$confirm("确认取消绑定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const data = {
          bindId: this.bindId,
          orderId: this.orderId,
        };
        unbindAxb(data).then(() => {
          this.$message({
            message: "解除绑定成功",
            type: "success",
          });
          this.visible = false;
          this.query();
        });
      });
    },
    query() {
      this.loading = true;
      getPrinumRecords({
        type: this.type,
        orderId: this.orderId,
        queryKey: this.queryKey,
      }).then((response) => {
        const list = response.data.records || [];
        const messageList = response.data.messages || [];
        const res = list.map((item) => {
          // 分流AXB
          if (item.type === 4) {
            if (this.isShuntStart) {
              if (item.direction) {
                item.directionDesc = "分流司机 -> 乘客";
              } else {
                item.directionDesc = "乘客 -> 分流司机";
              }
            } else {
              if (item.direction) {
                item.directionDesc = "分流司机 -> 城小班司机";
              } else {
                item.directionDesc = "城小班司机 -> 分流司机";
              }
            }
          } else {
            if (item.direction) {
              item.directionDesc = "司机 -> 乘客";
            } else {
              item.directionDesc = "乘客 -> 司机";
            }
          }

          if (item.callInTime && item.callEndTime) {
            const time = new Date(item.callEndTime).getTime() - new Date(item.callInTime).getTime();
            item.senonds = parseInt(time / 1000);
          }

          if (item.ulFailReason === 0) {
            item.status = "接听";
          } else {
            item.status = "未接听";
          }

          return item;
        });

        const res2 = messageList.map((item) => {
          if (item.calling && item.calling.indexOf(this.userTel) > -1) {
            item.directionDesc = "乘客 -> 司机";
          } else {
            item.directionDesc = "司机 -> 乘客";
          }
          return item;
        });

        this.telRecordList = res;
        this.telMsgList = res2;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.el-icon-phone {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  &.bind-status-1 {
    color: red;
  }
  &.bind-status-2 {
    color: green;
  }
}

.record-audio {
  height: 25px;
}

.header {
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
}

.tags {
  margin: 12px 0;
  position: relative;
  top: 2px;
  .el-tag {
    cursor: pointer;
    margin-right: 4px;
  }
}
</style>
