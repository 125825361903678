import Vue from "vue";
import Router from "@/route";

/**
 * 查询行程详情
 */
Vue.directive("goRouteDetail", {
  bind: function (el, binding) {
    el.binding = binding;
    el.style.cursor = "pointer";
    el.addEventListener("click", function () {
      const orderId = this.binding.value;
      if (orderId) {
        const { href } = Router.resolve({
          path: "/order/detail",
          query: { id: orderId },
        });
        window.open(href, "_blank");
      }
    });
  },
  update: function (el, binding) {
    el.binding = binding;
  },
  unbind: function () {},
});
