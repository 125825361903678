<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="路线：" prop="path">
          <el-select
            v-model="param.pathId"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开启状态：" prop="path">
          <el-select
            v-model="param.state"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >添加优惠口令</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table
        :data="list"
        stripe
        :row-class-name="tableRowClassName"
        max-height="600"
      >
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="content" label="口令内容"></el-table-column>

        <el-table-column prop="amount" label="金额">
          <template slot-scope="scope">
            <div>{{ scope.row.amount }}元</div>
          </template></el-table-column
        >

        <el-table-column prop="count" label="最大使用次数">
          <template slot-scope="scope">
            <div>{{ scope.row.count }}次</div>
          </template>
        </el-table-column>

        <el-table-column prop="pathIds" label="路线">
          <template slot-scope="scope">
            <div v-if="!scope.row.pathIds">全线路通用</div>
            <div v-else v-for="item in scope.row.pathIds" :key="item">
              {{ item | pathNameTransfer }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="有效时间">
          <template slot-scope="scope">
            <div>{{ scope.row.validOn }}</div>
            <div>{{ scope.row.validOff }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="使用状态">
          <template slot-scope="scope">{{
            scope.row.state | bizStatus(scope.row)
          }}</template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建日期"></el-table-column>

        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="changeState(scope.row)">
              {{ scope.row.state ? "停用" : "启用" }}
            </el-button>
            <el-button type="text" size="small" @click="openForm(scope.row)"
              >修改</el-button
            >
            <el-button type="text" size="small" @click="delWord(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="优惠口令"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="口令路线：" prop="path">
          <el-select multiple v-model="form.pathIds" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="口令内容：" prop="content">
          <el-input v-model="form.content"></el-input>
        </el-form-item>

        <el-form-item label="提示信息：" prop="wordTip">
          <el-input v-model="form.wordTip"></el-input>
        </el-form-item>

        <el-form-item label="开始时间：" prop="validOn">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.validOn"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="截止日期：" prop="validOff">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.validOff"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="口令金额：" prop="amount">
          <el-input-number
            v-model="form.amount"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="使用次数：" prop="count">
          <el-input-number
            v-model="form.count"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <span class="red">注意：路线不选择则为通用优惠口令</span>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {listDiscountWord, addDiscountWord, deleteWord, modifyDiscountWord} from '@/api';
import {mapState} from 'vuex';
import {DateUtil, Toolkit} from '@/util';
import {BaseDataService} from '@/service';

export default {
  filters: {
    bizStatus: function (value, item) {

      if (!item.state) {
        return '未启用';
      }
      const now = new Date();
      const nowStr = DateUtil.format(now, 'yyyy-MM-dd hh:mm:ss');
      if (item.used >= item.count) {
        return `使用次数用完(${item.used}/${item.count})`;
      } else if (item.validOn > nowStr) {
        return '未开始';
      } else if (item.validOff < nowStr) {
        return `已结束(${item.used}/${item.count})`;
      } else {
        return `正在使用(${item.used}/${item.count})`;
      }
    }
  },
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },


  data () {
    return {
      visible: false,
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      yesNoOpts: BaseDataService.yesNoOpts(),
      rules: {
        content: [
          {required: true, message: '请输入优惠口令内容', trigger: 'blur'},
        ],
        amount: [
          {required: true, message: '请输入优惠口令金额', trigger: 'blur'},
        ],
        wordTip: [
          {required: true, message: '请输入口令提示', trigger: 'blur'},
        ],
        validOn: [
          {required: true, message: '请选择开始时间', trigger: 'blur'},
        ],
        validOff: [
          {required: true, message: '请选择截止时间', trigger: 'blur'},
        ],
        count: [
          {required: true, message: '请输入使用次数', trigger: 'blur'},
        ],
      },
    };
  },
  methods: {

    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: Toolkit.filterEmpty(this.param)
      };
      listDiscountWord(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange (page) {
      this.query(page);
    },
    delWord (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteWord({id: item.id}).then(() => {
          this.$message.success('优惠口令删除成功');
          this.query();
        });
      });
    },
    openForm (item) {
      this.visible = true;

      if (item) {
        const form = {...item};

        this.form = form;
      } else {
        this.form = {};
      }

    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const param = Toolkit.filterEmpty({...this.form});

        if (param.id) {
          modifyDiscountWord(param).then(() => {
            this.$message.success('优惠口令修改成功');
            this.visible = false;
            this.query();
          });
        } else {
          addDiscountWord(param).then(() => {
            this.$message.success('优惠口令新增成功');
            this.visible = false;
            this.query();
          });
        }
      });

    },
    changeState (item) {
      const param = {
        ...item,
        state: item.state ? 0 : 1
      };
      modifyDiscountWord(param).then(() => {
        this.$message.success('状态修改成功');
        this.query();
      });
    },
    tableRowClassName ({row}) {
      const now = new Date();
      const nowStr = DateUtil.format(now, 'yyyy-MM-dd hh:mm:ss');
      const canUse = row.state && row.used < row.count && row.validOn <= nowStr && row.validOff >= nowStr;
      return canUse ? 'highlight' : '';
    },
  }
};
</script>

<style scoped>
</style>
