<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      direction="ttb"
      :with-header="false"
      custom-class="drawer-modal"
      :title="title"
      :before-close="handleClose"
    >
      <div class="root" v-loading="loading">
        <div class="box">
          <div class="left">
            <div class="role">
              <div
                v-if="driver"
                :class="{ active: userType === USER_TYPE.DRIVER }"
                @click="changeRole(USER_TYPE.DRIVER)"
              >
                司机{{ driver.no }}
              </div>
              <div
                v-if="passenger"
                :class="{ active: userType === USER_TYPE.PASSENGER }"
                @click="changeRole(USER_TYPE.PASSENGER)"
              >
                <span>乘客</span>
              </div>
            </div>

            <div class="info">
              <div v-if="!selectedRole" class="no-msg">首次呼入用户</div>
              <div v-else>
                <el-form
                  ref="form"
                  :model="passenger"
                  label-width="120px"
                  class="psg-form"
                  size="small"
                >
                  <template v-if="userType === USER_TYPE.PASSENGER">
                    <el-form-item label="姓名：">
                      <el-input v-model="passenger.name" :disabled="disable"></el-input>
                    </el-form-item>

                    <el-form-item label="性别：">
                      <el-radio-group v-model="passenger.gender" :disabled="disable">
                        <el-radio :label="true">男</el-radio>
                        <el-radio :label="false">女</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item label="黑名单用户：">
                      <el-radio-group v-model="passenger.hmd" :disabled="disable">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item label="备注：">
                      <el-input
                        type="textarea"
                        :rows="3"
                        v-model="passenger.remark"
                        :disabled="disable"
                        resize="none"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="VIP级别：">
                      <span v-if="passenger.vipLevel">{{ passenger.vipLevel }}级</span>
                    </el-form-item>

                    <el-form-item label="注册邀请码：">
                      <span>{{ passenger.inviteCode }}</span>
                    </el-form-item>

                    <el-form-item label="可用积分：">
                      <span>{{ passenger.totalPoints }}</span>
                    </el-form-item>

                    <el-form-item label="可用优惠券：">
                      <span>{{ passenger.discount }}</span>
                    </el-form-item>

                    <el-form-item label="电话注册时间：">
                      <span>{{ passenger.telRegisterTime }}</span>
                    </el-form-item>

                    <el-form-item label="线上注册时间：">
                      <span v-if="!passenger.onlineRegTime" style="color: red"
                        >新用户，必得优惠券</span
                      >
                      <span v-else-if="passenger.loginCoupon" style="color: red">登录可送券</span>
                      <span v-else>{{ passenger.onlineRegTime }}</span>
                    </el-form-item>

                    <el-form-item>
                      <el-button
                        type="primary"
                        v-if="disable"
                        @click="disable = false"
                        style="width: 150px"
                        >编辑</el-button
                      >
                      <el-button type="primary" style="width: 150px" v-else @click="saveUser"
                        >保存</el-button
                      >
                    </el-form-item>
                  </template>

                  <template v-if="userType === USER_TYPE.DRIVER">
                    <el-form-item label="姓名：">
                      <span>{{ driver.name }}</span>
                    </el-form-item>
                    <el-form-item label="车型：">
                      <span>{{ driver.vehicleColor }}-{{ driver.brand }} </span>
                    </el-form-item>
                    <el-form-item label="座位数：">
                      <span>{{ driver.seats }}坐</span>
                    </el-form-item>

                    <el-form-item label="车牌号：">
                      <span>{{ driver.vehicleNo }}</span>
                    </el-form-item>

                    <el-form-item label="黑名单用户：">
                      <span>{{ driver.status === 2 ? "是" : "否" }}</span>
                    </el-form-item>

                    <el-form-item label="备注：">
                      <span>{{ driver.remark }}</span>
                    </el-form-item>

                    <el-form-item label="注册时间：">
                      <span>{{ driver.registerTime }}</span>
                    </el-form-item>
                  </template>
                </el-form>
              </div>
            </div>

            <div class="tel">
              <div class="c1">
                <el-popover placement="right" width="400" size="small" trigger="click">
                  <el-button type="primary" plain size="small" @click="sendMsg(1)"
                    >平台介绍与优惠</el-button
                  >
                  <el-button type="primary" plain size="small" @click="sendMsg(2)"
                    >特优活动介绍</el-button
                  >
                  <el-button type="primary" plain size="small" @click="sendMsg(3)"
                    >开具发票方法</el-button
                  >

                  <el-button type="text" slot="reference">发送短信</el-button>
                </el-popover>

                <span>
                  <strong v-if="popupcalls.direct === 'IN'"
                    >呼入：{{ popupcalls.callerid }}
                  </strong>
                  <strong v-if="popupcalls.direct === 'OUT'"
                    >呼出：{{ popupcalls.callerid }}</strong
                  >
                </span>
              </div>

              <div class="c1">
                <el-button type="primary" @click="openTrans()" size="small">转接</el-button>

                <tel-order :tel="popupcalls.callerid" title="电话下单" />
              </div>
            </div>
          </div>
          <div class="right">
            <div class="gen-info">
              <div>总单:&nbsp;&nbsp;{{ sumInfo.total }}</div>
              <div>成行:&nbsp;&nbsp;{{ sumInfo.finish }}</div>
              <div>取消:&nbsp;&nbsp;{{ sumInfo.cancel }}</div>
            </div>

            <div class="table">
              <el-table :data="list" stripe max-height="600">
                <el-table-column prop="orderStatus" label="订单状态">
                  <template slot-scope="scope">{{
                    scope.row.orderStatus | enumTransfer(orderStatusOpts)
                  }}</template>
                </el-table-column>
                <el-table-column prop="orderSource" width="80" label="渠道">
                  <template slot-scope="scope">
                    <span v-order-source="scope.row.orderSource"></span>
                  </template>
                </el-table-column>

                <el-table-column prop="pathId" label="路线">
                  <template slot-scope="scope">
                    <span>{{ scope.row.pathId | pathNameTransfer }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="date" width="120" label="日期">
                  <template slot-scope="scope">
                    <div>{{ scope.row.date }}</div>
                    <div>{{ scope.row.showTime }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="number" width="60" label="订票">
                  <template slot-scope="scope">
                    <span>{{ scope.row.number }}人</span>
                  </template>
                </el-table-column>
                <el-table-column prop="telephone" label="乘车人电话" width="160">
                  <template slot-scope="scope">
                    <tel-cell :order="scope.row" />
                  </template>
                </el-table-column>

                <el-table-column prop="price" label="总价" width="100">
                  <template slot-scope="scope">
                    <span v-operate-order:bill="scope.row.id"
                      >{{ scope.row.price | moneyFormat }}元</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="司机" prop="driverNo">
                  <template slot-scope="scope">
                    <div>{{ scope.row.driverNo }}</div>
                    <div>{{ scope.row.driverName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="车牌号" width="90">
                  <template slot-scope="scope">
                    <div>{{ scope.row.vehicleNo }}</div>
                    <div v-if="scope.row.seats">{{ scope.row.seats }}座</div>
                  </template>
                </el-table-column>
                <el-table-column width="300" label="出发地/目的地">
                  <template slot-scope="scope">
                    <div>
                      <div class="start cuIcon-title text-blue">
                        {{ scope.row.departure }}
                      </div>
                      <div class="end cuIcon-title text-orange">
                        {{ scope.row.destination }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="payStatus" label="支付状态">
                  <template slot-scope="scope">
                    <span>{{ scope.row.payStatus | enumTransfer(payStatusOpts) }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="id" label="操作" align="right">
                  <template slot-scope="scope">
                    <el-dropdown
                      @command="(command) => handleCommand(command, scope.row, scope.$index)"
                      style="margin-left: 10px"
                      class="dropdown-meun"
                    >
                      <span class="el-dropdown-link">
                        操作
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="1"
                          :disabled="
                            scope.row.orderStatus === 'CANCEL' || scope.row.orderStatus === 'FINISH'
                          "
                        >
                          <span>取消订单</span>
                        </el-dropdown-item>

                        <el-dropdown-item
                          command="2"
                          :disabled="
                            scope.row.orderStatus === 'CANCEL' ||
                            scope.row.orderStatus === 'FINISH' ||
                            scope.row.orderStatus === 'MATCHED' ||
                            scope.row.orderSource === 1
                          "
                        >
                          <span>修改订单</span>
                        </el-dropdown-item>

                        <el-dropdown-item command="3">操作日志</el-dropdown-item>
                        <el-dropdown-item command="0">
                          <span v-goRouteDetail="scope.row.id">订单详情</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      :visible.sync="transTelDialog"
      width="400px"
      title="请选择要转接的分机"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="tel-content">
        <el-radio-group v-model="transTel">
          <el-radio :label="item.agentNo" v-for="item in phoneLines" :key="item.agentNo">{{
            item.name
          }}</el-radio>
        </el-radio-group>
      </div>

      <span slot="footer" class="footer">
        <el-button @click="transTelDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="trans" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <order-cancel ref="cancelModal" :onCancel="handleCurrentChange" />
    <tel-order-modify-modal ref="modifyModal" :onUpdated="handleCurrentChange" />
  </div>
</template>

<script>
import {
  getHuawuData,
  getOrderDetail,
  updatePassenger,
  getRoleAdminList,
  sendRecommendMpTip,
} from "@/api";
import config from "@/config/config";
import { mapState } from "vuex";

const USER_TYPE = {
  PASSENGER: 1,
  DRIVER: 2,
};
export default {
  components: {},
  props: {},
  created() {
    this.getKefuList();
  },
  mounted() {},
  watch: {},

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      orderStatusOpts: (state) => state.enumMap["ORDER_STATUS"],
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
    }),
    isPassenger() {
      return this.userType === USER_TYPE.PASSENGER;
    },

    sumInfo() {
      return this.isPassenger ? this.passengerOrderCount : this.driverOrderCount;
    },

    list() {
      return this.isPassenger ? this.passengerOrderList : this.driverOrderList;
    },

    title() {
      if (this.popupcalls.callerid) {
        return `${this.popupcalls.callerid} 来电`;
      }
      return "";
    },
  },
  data() {
    return {
      drawer: false,
      USER_TYPE,
      userType: USER_TYPE.PASSENGER,
      driver: {},
      passenger: {},
      partner: {},
      passengerOrderList: [],
      driverOrderList: [],
      passengerOrderCount: {},
      driverOrderCount: {},
      selectedRole: null,
      loading: false,
      disable: true,
      transTelDialog: false,
      transTel: "",
      phoneLines: [],
      popupcalls: {},
    };
  },
  methods: {
    sendMsg(type) {
      this.$confirm("确认发送短信吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.realTelephone) {
          sendRecommendMpTip(this.realTelephone, type).then(() => {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          });
        }
      });
    },

    show(popupcalls) {
      this.drawer = true;
      this.loading = true;
      this.popupcalls = popupcalls;
      let tel = popupcalls.callerid;
      if (tel.startsWith(0)) {
        tel = tel.substr(1);
      }
      this.realTelephone = tel;
      getHuawuData(tel)
        .then((res) => {
          const {
            driver = {},
            partner = {},
            passenger = {},
            passengerOrderList = [],
            driverOrderList = [],
            passengerOrderCount = {},
            driverOrderCount = {},
            totalPoints = 0,
            usableCoupons = {},
            loginCoupon,
          } = res.data || {};

          passenger.totalPoints = totalPoints;

          //统计优惠券张数
          const discountArr = [];
          for (let amount in usableCoupons) {
            const num = usableCoupons[amount].length;
            discountArr.push(`${amount}元${num}张`);
          }
          passenger.discount = discountArr.join("，");
          passenger.loginCoupon = loginCoupon;
          this.driver = driver;
          this.passenger = passenger || {};

          this.partner = partner;
          this.passengerOrderList = passengerOrderList;
          this.driverOrderList = driverOrderList;
          this.driverOrderCount = driverOrderCount;
          this.passengerOrderCount = passengerOrderCount;

          if (passenger) {
            this.changeRole(USER_TYPE.PASSENGER);
          } else if (driver) {
            this.changeRole(USER_TYPE.DRIVER);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    showLast() {
      this.drawer = true;
    },

    getKefuList() {
      const kefuRoleId = config.kefuRoleId;
      getRoleAdminList(kefuRoleId).then((res) => {
        const list = res.data || [];
        this.phoneLines = list.filter((item) => !!item.agentNo);
      });
    },
    handleCommand(command, item, index) {
      this.currentSelect = index;
      command = parseInt(command);
      switch (command) {
        case 1:
          this.openCancelModal(item);
          break;
        case 2:
          this.openUpdateModal(item);
          break;
        case 3:
          this.$orderLog().show(item.id);
          break;
      }
    },
    handleCurrentChange() {
      const index = this.currentSelect;
      getOrderDetail(this.list[index].id).then((res) => {
        this.$set(this.list, index, res.data);
      });
    },
    trans() {
      this.transTelDialog = false;
      this.$message({
        message: "转接中...",
        type: "success",
      });
      jMVBCTI.TransferToPhone({ phoneno: this.transTel }, () => {
        this.transTelDialog = false;
      });
    },

    saveUser() {
      updatePassenger(this.passenger).then(() => {
        this.$message.success("修改成功");
      });
    },
    changeRole(userType) {
      this.userType = userType;
      this.selectedRole = userType === USER_TYPE.PASSENGER ? this.passenger : this.driver;
    },
    openTrans() {
      this.transTelDialog = true;
    },
    handleClose(done) {
      done();
    },
    openCancelModal(item) {
      this.$refs.cancelModal.open(item.id);
    },
    openUpdateModal(item) {
      this.$refs.modifyModal.open(item);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90% !important;
  background: #e1e1e1;
}

.root {
  padding: 20px;
  .box {
    display: flex;
    justify-content: center;
    .left {
      background: #fff;
      width: 325px;
      padding: 10px;
      .role {
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        height: 50px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        margin-bottom: 5px;

        > div {
          box-sizing: border-box;
          width: 100%;
          font-size: 12px;
          line-height: 50px;
          flex: 1;
          transition: 0.3s all;
          text-align: center;
        }

        .active {
          background-color: #364150;
          color: white;
          flex: 3;
          font-size: 24px;
        }
      }

      .info {
        border: 1px solid #ddd;
        margin: 5px 0;
        padding: 20px 10px 0;
        height: 520px;

        .no-msg {
          text-align: center;
          font-size: 16px;
          margin-top: 80px;
          color: #999;
        }

        .el-form-item {
          margin-bottom: 10px;
        }
      }

      .tel {
        border: 1px solid #ddd;
        padding: 15px 20px;
        box-sizing: border-box;
        width: 100%;
        height: 110px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .c1 {
          display: flex;
          justify-content: space-between;
          strong {
            color: darkred;
            font-size: 16px;
          }

          /deep/ button {
            width: 80px;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      margin: 0 10px 0 30px;
      width: 1350px;
      margin-left: 20px;
      background: #fff;
      padding: 10px;
      .gen-info {
        display: flex;
        height: 50px;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        > div {
          flex: 1;
          text-align: left;
          font-size: 2em;
          padding: 5px;
          margin: 0 20px;
        }
      }

      .detail {
        flex: 1;
      }
    }
  }
}

.tel-content {
  display: flex;
  flex-direction: column;

  .el-radio {
    display: block;
    margin: 20px;
    width: 200px;
  }
}
</style>
