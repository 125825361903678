<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" :size="size" :model="param" inline>
        <el-row>
          <el-col :span="22">
            <el-form-item label="区域" prop="areaId">
              <el-select v-model="param.areaId" disabled placeholder="请选择">
                <el-option
                  v-for="item in areaOpts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="query()"
                >查询</el-button
              >
            </el-form-item>
          </el-col>

          <el-col :span="2">
            <el-button
              type="primary"
              :size="size"
              style="width: 80px"
              @click="openEdit()"
              >新增问题</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div>
      <el-table :data="list" stripe max-height="560" size="small">
        <el-table-column label="序号" type="index"></el-table-column>

        <!-- <el-table-column prop="id" label="ID"></el-table-column> -->
        <el-table-column prop="question" label="问题"></el-table-column>
        <el-table-column prop="areaId" label="区域">
          <template slot-scope="scope">
            {{ scope.row.areaId | enumTransfer(areaOpts, "id", "name") }}
          </template>
        </el-table-column>
        <el-table-column prop="options" label="选项">
          <template slot-scope="scope">
            <div v-for="item in scope.row.options" :key="item">
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="答案"></el-table-column>
        <el-table-column label="操作" type="id">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEdit(scope.row)"
              >修改</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="500px"
      title="邀请问题"
      :show-close="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :size="size"
        label-width="120px"
      >
        <el-form-item label="问题所属区域" prop="areaId">
          <el-select
            v-model="form.areaId"
            disabled
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in areaOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="问题" prop="question">
          <el-input v-model="form.question"></el-input>
        </el-form-item>

        <el-form-item label="选项" prop="options">
          <el-input v-model="form.options[0]"></el-input>
          <el-input v-model="form.options[1]"></el-input>
          <el-input v-model="form.options[2]"></el-input>
          <el-input v-model="form.options[3]"></el-input>
        </el-form-item>

        <el-form-item label="答案" prop="answer">
          <el-input v-model="form.answer"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {saveOrUpdatePassengerInviteQuestion, getPassengerInviteQuestion, delPassengerInviteQuestion} from '@/api';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      areaOpts: state => state.areaOpts
    })
  },
  data () {
    return {

      param: {
        areaId: 2
      },
      list: [],
      visible: false,
      form: {
        options: ["", "", "", ""],
        areaId: 2
      },
      rules: {
        areaId: [
          {required: true, message: '问题所属区域不能为空', trigger: 'blur'},
        ],
        question: [
          {required: true, message: '问题不能为空', trigger: 'blur'},
        ],
        answer: [
          {required: true, message: '问题答案不能为空', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {

    query () {
      getPassengerInviteQuestion(this.param).then(res=>{
        this.list = res.data;
      });
    },


    openEdit (item) {
      if (item) {
        const options = item.options ? [...item.options] : ["", "", "", ""];
        this.form = {...item, options};
      } else {
        this.form = {
          options: ["", "", "", ""],
          areaId: 2,
          question: '以下哪个地方属于监利？'
        };
      }
      this.visible = true;
    },

    saveOrUpdate () {
      saveOrUpdatePassengerInviteQuestion(this.form).then(()=>{
        this.$message.success('操作成功');
        this.query();
        this.visible = false;
      });
    },

    del (item) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPassengerInviteQuestion(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
</style>