<template>
  <div class="root-box">
    <div class="top">
      <div class="card">
        <div class="header">
          <span class="type">今日订单量</span>
          <span class="tip">（实时统计数量）</span>
        </div>
        <div class="footer">
          <div class="blue">
            <i class="el-icon-document"></i>
          </div>
          <div>
            <span class="num blue">{{ data.orderCount }}</span> 单
          </div>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <span class="type">今日新注册用户量</span>
          <span class="tip">（实时统计数量）</span>
        </div>
        <div class="footer">
          <div class="orange">
            <i class="el-icon-user"></i>
          </div>
          <div>
            <span class="num orange">{{ data.userCount }}</span> 人
          </div>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <span class="type">今日总金额</span>
          <span class="tip">（实时统计数量）</span>
        </div>
        <div class="footer">
          <div class="green">
            <i class="el-icon-money"></i>
          </div>
          <div>
            <span class="num green">{{ data.price }}</span> 元
          </div>
        </div>
      </div>
    </div>

    <div class="charts">
      <div>
        <div class="header light-green">每日订单量</div>
        <div ref="chart1" class="chart"></div>
      </div>
      <div>
        <div class="header light-red">每日订单金额</div>
        <div ref="chart2" class="chart"></div>
      </div>
      <div>
        <div class="header light-orange">每日新注册用户量</div>
        <div ref="chart3" class="chart"></div>
      </div>
      <div>
        <div class="header light-blue">每日活跃度</div>
        <div ref="chart4" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  mounted () {
    this.query();
  },

  data () {
    return {

      data: {}
    };
  },
  methods: {

    query () {

    },
    initChart1 (data) {
      const date = [];
      const success = [];
      const cancel = [];
      data.forEach(item => {
        date.push(item.days);
        success.push(item.success);
        cancel.push(item.cancel);
      });
      const option = {
        xAxis: {
          type: 'category',
          data: date
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: ['成功订单', '失败订单']
        },
        tooltip: {
          trigger: 'axis'
        },
        barWidth: 40,
        textStyle: {
          color: '#999999'
        },
        series: [
          {
            name: '成功订单',
            type: 'bar',
            itemStyle: {
              color: '#00bfc8'
            },
            stack: '订单',
            data: success
          },
          {
            name: '失败订单',
            type: 'bar',
            itemStyle: {
              color: '#c8cfd3'
            },
            stack: '订单',
            data: cancel
          }],

      };
      const chart = echarts.init(this.$refs.chart1);
      chart.setOption(option);


    },
    initChart2 (data) {
      const date = [];
      const sumPrice = [];
      const payPrice = [];
      const noPayPrice = [];
      data.forEach(item => {
        date.push(item.days);
        sumPrice.push(item.sumPrice);
        payPrice.push(item.payPrice);
        noPayPrice.push(item.noPayPrice);
      });
      const option = {
        xAxis: {
          type: 'category',
          data: date
        },
        yAxis: {
          type: 'value'
        },
        textStyle: {
          color: '#999999'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['总金额', '已支付', '待支付']
        },
        series: [{
          name: '总金额',
          data: sumPrice,
          type: 'line',
          itemStyle: {
            color: '#ffbda1'
          },
          smooth: true
        }, {
          name: '已支付',
          data: payPrice,
          type: 'line',
          itemStyle: {
            color: '#00b4ff'
          },
          smooth: true
        }, {
          name: '待支付',
          data: noPayPrice,
          type: 'line',
          itemStyle: {
            color: '#fbbd08'
          },
          smooth: true
        }]
      };

      const chart = echarts.init(this.$refs.chart2);
      chart.setOption(option);
    },
    initChart3 (data) {
      const date = [];
      const sum = [];
      data.forEach(item => {
        date.push(item.days);
        sum.push(item.counts);
      });
      const option = {
        xAxis: {
          type: 'category',
          data: date
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'value'
        },
        textStyle: {
          color: '#999999'
        },
        legend: {
          data: ['新增注册数']
        },
        series: [{
          name: '新增注册数',
          data: sum,
          type: 'line',
          itemStyle: {
            color: '#f7a12e'
          },
          smooth: true
        }]
      };

      const chart = echarts.init(this.$refs.chart3);
      chart.setOption(option);
    },
    initChart4 (data) {
      const date = [];
      const sum = [];
      data.forEach(item => {
        date.push(item.days);
        sum.push(item.activeCount);
      });
      const option = {
        xAxis: {
          type: 'category',
          data: date
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        textStyle: {
          color: '#999999'
        },
        legend: {
          data: ['日活跃度']
        },
        series: [{
          name: '日活跃度',
          data: sum,
          type: 'line',
          itemStyle: {
            color: '#00b4ff'
          },
          smooth: true
        }]
      };

      const chart = echarts.init(this.$refs.chart4);
      chart.setOption(option);
    }

  }
};
</script>

<style scoped lang="less">
.root-box {
  background-color: #edf1f5;
  min-height: 100%;

  .top {
    height: 120px;
    background-color: white;
    display: flex;
    padding: 10px;

    .card {
      flex: 1;
      border-right: 1px #ddd dashed;
      padding: 20px 40px;

      &:last-child {
        border-right: none;
      }

      .header {
        .type {
          font-size: 18px;
          color: #6d7f8e;
        }

        .tip {
          font-size: 12px;
          color: #9ea7b1;
        }

        margin-bottom: 20px;
      }

      .footer {
        display: flex;
        justify-content: space-between;

        .num {
          font-size: 3em;
          margin-right: 15px;
        }

        i {
          font-size: 3em;
        }
      }
    }
  }

  .charts {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 350px 350px;
    grid-column-gap: 10px;
    grid-row-gap: 15px;

    > div {
      background-color: white;
    }

    .header {
      height: 25px;
      padding-left: 30px;
      line-height: 25px;
      margin-top: 10px;
      font-size: 14px;
    }

    .light-green {
      color: #00bfc8;
      border-left: 3px solid #00bfc8;
    }

    .light-blue {
      color: #2ba6ef;
      border-left: 3px solid #2ba6ef;
    }

    .light-orange {
      color: #f7a12e;
      border-left: 3px solid #f7a12e;
    }

    .light-red {
      border-left: #fd9674 3px solid;
      color: #fd9674;
    }

    .chart {
      height: 300px;
      width: 820px;
    }
  }
}

.blue {
  color: #2aaaff;
}

.orange {
  color: orange;
}

.green {
  color: #00bfc8;
}
</style>
