<template>
    <div>
        <div> <el-input v-model="tip" placeholder="引导提示文字"></el-input> </div>
        <div class="imgs">
            <file-upload upload-type="button" bucket="yxmgt-public" v-model="imgs" :multiple="true"
                file-path="/common/recPoint" btnText="点击上传引导图片" />
        </div>

    </div>
</template>
<script>
export default {
    components: {

    },

    props: {
        value: Object,
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.imgs = val.imgList;
                    this.tip = val.tip;
                } else {
                    this.imgs = [];
                    this.tip = '';
                }
            },
            immediate: true,
        },

        tip(val) {
            this.$emit('input', {
                imgList: this.imgs,
                tip: val,
            });
        },

        imgs(val) {
            this.$emit('input', {
                imgList: val,
                tip: this.tip,
            });
        },
    },

    created() {

    },

    mounted() {

    },

    computed: {


    },

    data() {
        return {
            imgs: [],
            tip: '',
        };
    },

    methods: {

    },

};
</script>
<style scoped lang="less">
.imgs {
    margin-top: 10px;
}
</style>
