<template>
  <div>
    <el-row class="itm-row">
      <span class="itm-sno" v-if="sno">{{ sno + "." }}</span>
      <span class="itm-title" v-html="paperItm.title"></span>
      <span class="itm-score">{{ paperItm.score ? "[" + paperItm.score + "分]" : "" }}</span>
      <span class="itm-media" v-if="hasMedia">
        <img v-if="isImage" :src="paperItm.media" class="itm-image" />
        <video v-if="isVedio" :src="paperItm.media" />
        <audio v-if="isAudio" controls></audio>
        <i class="el-icon-delete" @click="deleteMedia" v-if="!preview">删除</i>
      </span>
    </el-row>
    <el-row class="itm-row" :key="opt.id" v-for="(opt, idx) in paperItm.itms">
      <el-col @click.native="chooseOpt(opt)">
        <span v-if="isAnswerItm(opt)" class="opt-sno answer"><i class="el-icon-check"></i></span>
        <span v-else class="opt-sno">{{ getCharIdx(idx) }}</span>
        <span>{{ opt.val }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { FileUtil } from "@/util";
export default {
  name: "ItemView",
  props: {
    sno: {
      type: Number,
    },
    paperItm: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInfo: false,
    };
  },
  computed: {
    hasMedia() {
      return this.paperItm && this.paperItm.media;
    },
    isImage() {
      return this.hasMedia && FileUtil.isImage(this.paperItm.media);
    },
    isVedio() {
      return this.hasMedia && FileUtil.isVedio(this.paperItm.media);
    },
    isAudio() {
      return this.hasMedia && FileUtil.isAudio(this.paperItm.media);
    },
  },
  methods: {
    isAnswerItm(itm) {
      return (
        this.paperItm.answer === itm.id ||
        (Array.isArray(this.paperItm.answer) && this.paperItm.answer.indexOf(itm.id) >= 0)
      );
    },
    getCharIdx(idx) {
      return String.fromCharCode(65 + idx);
    },
    toggerInfo() {
      this.showInfo = !this.showInfo;
    },
    deleteMedia() {
      this.$confirm("删除图片/音频资源不可恢复，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("deleteMedia");
      });
    },
    chooseOpt(opt) {
      this.$emit("answer", opt);
    },
  },
};
</script>

<style lang="less">
.itm-row {
  line-height: 32px;
  .itm-sno {
    width: 30px;
    vertical-align: top;
  }
  .itm-title {
    font-weight: bold;
    word-wrap: break-word;
    width: calc(100% - 90px);
  }
  .itm-title p {
    margin: 0 !important;
  }
  .itm-image {
    max-height: 254px;
  }
  .itm-score {
    text-align: right;
    width: 60px;
    vertical-align: top;
  }
  .opt-sno {
    border: 1px solid #72767b;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
    margin-right: 8px;
  }
  .opt-sno.answer {
    background-color: #409eff;
    color: #fff;
    border-color: #409eff;
  }
}

.itm-row > span {
  display: inline-block;
}
</style>
