<template>
<div class="admin-root">
  <div class="admin-area">
    <el-card
        shadow="always"
        class="admin-card"
        :class="driveradmin.id == currentAdmin.id ? 'active' : ''"
        v-for="driveradmin in driverAdmins"
        :key="driveradmin.id"
        @click.native="chooseDriverAdmin(driveradmin)"
    >
      <i class="el-icon-edit edit" @click="editDriverAdmin(driveradmin)"></i>
      <div class="status" :class="driveradmin.status == 1 ? 'normal' : 'forbidden'">
        {{ driveradmin.status == 1 ? "正常" : "封禁" }}
      </div>
      <div class="name" style="margin-top: 20px;">{{ driveradmin.name }}</div>
    </el-card>
    <el-card class="admin-card" @click.native="addDriverAdmin">
      <i class="el-icon-plus" style="margin-top: 20px; cursor: pointer"></i>
    </el-card>
  </div>

  <div class="menu">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="账户" name="first"></el-tab-pane>
      <el-tab-pane label="明细" name="second"></el-tab-pane>
    </el-tabs>
  </div>

  <div v-if="refresh && activeName == 'first'">
    <admin-account :driverAdmin="currentAdmin"></admin-account>
  </div>

  <div v-if="refresh && activeName == 'second'">
    <detail-page :driverAdmin="currentAdmin"></detail-page>
  </div>

  <el-dialog :visible.sync="showDialog" v-if="refresh" :title="currentAdmin.id ? '修改司管信息' : '新增司管信息'">
    <el-form ref="form" :rules="rules" :model="currentAdmin" label-width="100px">
      <el-form-item label="姓名:" prop="name">
        <el-input v-model="currentAdmin.name" placeholder="请输入员工姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号码:" prop="telephone">
        <el-input v-model="currentAdmin.telephone" placeholder="请输入员工手机号码"></el-input>
      </el-form-item>
      <el-form-item label="账户权限:" prop="svcScopes">
        <el-checkbox-group v-model="currentAdmin.svcScopes">
          <el-checkbox :label="p.value" :key="p.value" v-for="p in serviceScopeObjs">{{
            p.label
            }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="头像:" prop="avatar">
        <image-upload
                v-model="currentAdmin.avatar"
                filePath="/partner"
                bucket="yxmgt-public"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAdmin">提交</el-button>
        <el-button @click="showDialog=false;">取消</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import { getDriverAdminList, saveDriverAdmin, getServiceScopes } from '@/api/partner_admin';
import adminAccount from './adminAccount';
import detailPage from './detailPage';

export default {
  name: "driverAdmin",
  data () {
    return {
      rules: {
        name: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
      },
      driverAdmins: [],
      currentAdmin: {},
      showDialog: false,
      refresh: true,
      activeName: 'first',
      serviceScopeObjs: [],
    };
  },
  components: {
      adminAccount,
      detailPage
  },
  mounted () {
    this.initPage();
  },
  methods: {
    initPage () {
      getServiceScopes().then(res => {
          this.serviceScopeObjs = res.data || [];
          window.serviceScopeObjs = this.serviceScopeObjs;

          getDriverAdminList({}).then(res => {
              let driverAdmins = res.data || [];
              this.driverAdmins = driverAdmins;
              if (this.driverAdmins.length) {
                  this.chooseDriverAdmin(this.driverAdmins[0]);
              }
          });
      });
    },
    handleClick(tab) {
        this.activeName = tab.name;
    },
    saveAdmin () {
      if (!this.currentAdmin.name || !this.currentAdmin.telephone) {
        return;
      }

      saveDriverAdmin(this.currentAdmin).then(res => {
        let admin = res.data;
        let exists = false;
        for (var i = 0; i < this.driverAdmins.length; i ++) {
          if (this.driverAdmins[i].id == admin.id) {
            exists = true;
            this.driverAdmins.splice(i, 1, admin);
            break;
          }
        }
        if (!exists) {
          this.driverAdmins.push(admin);
        }
        this.chooseDriverAdmin(admin);
        this.$message.success("保存成功。");
        this.showDialog = false;
      });
    },
    editDriverAdmin (admin) {
      let copy = JSON.parse(JSON.stringify(admin));
      this.chooseDriverAdmin(copy);

      this.openDialog();
    },
    addDriverAdmin() {
      let admin = {
          svcScopes: [],
      };
      this.chooseDriverAdmin(admin);
      this.openDialog();
    },
    chooseDriverAdmin(admin) {
      this.currentAdmin = admin;
      if (!this.currentAdmin.svcScopes) {
          this.currentAdmin.svcScopes = [];
      }
      this.refresh = false;
      this.$nextTick(()=> {
          this.refresh = true;
      });
    },
    openDialog () {
        this.showDialog = true;
    }
  }
};
</script>

<style scoped lang="less">
.admin-root {
  margin-left: 10px;

  .admin-area {
    .admin-card {
      margin: 10px;
      margin-left: 0;
      height: 106px;
      width: 160px;
      text-align: center;
      display: inline-block;
      position: relative;

      &.active {
         background: #409eff;
         color: #fff;
       }

      .status {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 12px;

        &.normal {
         color: #67c23a;
        }

        &.forbidden {
         color: #f56c6c;
        }
      }
      .edit {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        cursor: pointer;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>