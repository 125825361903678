<template>
<div class="award-detail">
  <div class="query-container">
    <div>司机编号：</div>
    <div><el-input v-model="queryVo.driverNo"></el-input></div>
    <div>任务结束时间段：</div>
    <div><el-date-picker
            v-model="queryVo.startDate"
            type="date"
            placeholder="选择日期">
    </el-date-picker> 至 <el-date-picker
            v-model="queryVo.endDate"
            type="date"
            placeholder="选择日期">
    </el-date-picker></div>
    <div style="margin: 0 8px;">
      <el-select v-model="queryVo.flCode" placeholder="请选择">
        <el-option
                v-for="item in flCodeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div>
      <el-button type="primary" @click.native="query">查询</el-button>
    </div>
  </div>
  <div class="data-container" v-if="!loading">
    <div class="summary">
      <div class="summary-item">
        <div class="num">{{awardInfo.num}}次</div>
        <div class="desc">可参与任务</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.participateNum}}次</div>
        <div class="desc">报名参与</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.totalAward}}元</div>
        <div class="desc">报名总奖池</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.exchargeAward}}元</div>
        <div class="desc">已领奖金</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.reachNum}}次</div>
        <div class="desc">总获奖次数</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.participateRate}}%</div>
        <div class="desc">任务参与率</div>
      </div>
      <div class="summary-item">
        <div class="num">{{awardInfo.awardRate}}%</div>
        <div class="desc">任务完成率</div>
      </div>
    </div>
    <div style="margin-left: 10px; margin-top: 10px;">
      <el-radio-group v-model="chartMode">
        <el-radio-button label="奖金"></el-radio-button>
        <el-radio-button label="次数"></el-radio-button>
      </el-radio-group>
    </div>
    <div ref="chart" class="chart"></div>

    <!-- 明细表格 -->
    <el-table
            :data="tasks"
            style="width: 100%">
      <el-table-column
              label="任务编号">
        <template slot-scope="scope">
          <el-link type="primary" @click="viewTaskInfo(scope.$index, scope.row)">{{scope.row.taskNo}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
              prop="flCode"
              label="任务类型">
      </el-table-column>
      <el-table-column
              label="主考评项1">
        <template slot-scope="scope">
          <div :class="scope.row.status == '2' && !scope.row.main1Reached ? 'red' : ''">{{scope.row.mainTask1}}</div>
        </template>
      </el-table-column>
      <el-table-column
              prop="mainTask1Sort"
              label="考评逻辑">
      </el-table-column>
      <el-table-column
              label="主考评项2">
        <template slot-scope="scope">
          <div :class="scope.row.status == '2' && !scope.row.main2Reached ? 'red' : ''">{{scope.row.mainTask2}}</div>
        </template>
      </el-table-column>
      <el-table-column
              prop="mainTask2Sort"
              label="考评逻辑">
      </el-table-column>
      <el-table-column
              label="辅考评项">
        <template slot-scope="scope">
          <el-link type="primary" @click="viewAssistantInfo(scope.$index, scope.row)">
            <div :class="scope.row.status == '2' && !scope.row.assistantReached ? 'red' : ''">{{scope.row.assistant}}项</div>
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
              prop="award"
              label="奖金">
      </el-table-column>
      <el-table-column
              prop="participate"
              label="适用司机">
      </el-table-column>
      <el-table-column
              prop="isRead"
              label="是否查看">
      </el-table-column>
      <el-table-column
              prop="isParticipate"
              label="是否报名">
      </el-table-column>
      <el-table-column
              prop="isReach"
              label="是否获奖">
      </el-table-column>
      <el-table-column
              prop="startDate"
              label="开始时间">
      </el-table-column>
      <el-table-column
              prop="endDate"
              label="结束时间">
      </el-table-column>
      <el-table-column
              prop="status"
              label="任务状态">
      </el-table-column>
      <el-table-column
              label="详情">
        <template slot-scope="scope">
          <el-button
                  size="mini"
                  @click="viewTaskFinishInfo(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-dialog
          :visible.sync="showTemplate"
          width="1200px"
          max-height="600"
          title="任务详情"
          :close-on-click-modal="false"
  >
    <el-form ref="form" size="small" inline>
      <RewardTemplateDetail :data="currentRow.template" />

      <div class="footer">
        <el-button type="info" :size="size" @click="showTemplate = false">取 消</el-button>
      </div>
    </el-form>
  </el-dialog>

  <el-dialog
          :visible.sync="showTaskFinish"
          width="400px"
          max-height="600"
          :close-on-click-modal="false"
  >
    <el-form ref="form" size="small" inline>
      <div class="detail-title">司机{{queryVo.driverNo}}任务执行情况</div>
      <div v-for="task in currentRow.details" :key="task.id" class="task-row">
        <div style="width: 200px;">{{task.taskName + task.assessValue + (task.unit || "")}}</div>
        <div :class="task.reach && currentRow.status=='2' ? '' : 'red'">{{"实际" +  task.taskValue}}</div>
      </div>
      <div class="footer">
        <el-button type="info" :size="size" @click="showTaskFinish = false">取 消</el-button>
      </div>
    </el-form>
  </el-dialog>

  <el-dialog
          :visible.sync="showAssistant"
          width="400px"
          max-height="600"
          :close-on-click-modal="false"
  >
    <el-form ref="form" size="small" inline>
      <div class="detail-title">任务{{currentRow.taskNo}}的辅助测评</div>

      <div v-if="task.assistant">
        <div v-for="task in currentRow.details" :key="task.id"  class="task-row">
          <div style="width: 200px;">{{task.taskName + task.assessValue + (task.unit || "")}}</div>
          <div :class="task.reach && currentRow.status=='2' ? '' : 'red'">{{"实际" +  task.taskValue}}</div>
        </div>
      </div>

      <div class="footer">
        <el-button type="info" :size="size" @click="showAssistant = false">取 消</el-button>
      </div>
    </el-form>
  </el-dialog>
</div>
</template>

<script>
import RewardTemplateDetail from '../RewardTemplateDetail';
import {mapState} from 'vuex';
export default {
  name: "awardDetailInfo",
  components: {
    RewardTemplateDetail
  },
  computed: {
    ...mapState({
      size: state => state.btnSize
    }),
  },
  data () {
    return {
      loading: true,
      chartMode: "奖金",
      queryVo: {
        startDate: "",
        endDate: "",
        flCode: "",
        driverNo: ""
      },
      flCodeOpts: [{
        label: "01",
        value: "1"
      }, {
        label: "02",
        value: "2"
      }, {
        label: "03",
        value: "3"
      }],
      awardInfo: {},
      tasks: [],

      showTaskFinish: false,
      showAssistant: false,
      showTemplate: false,
      currentRow: {}
    };
  },
  mounted () {
  },
  watch: {
    chartMode () {
      this.initChart(this.awardInfo.details);
    }
  },
  methods: {
    checkParam () {
      if (!this.queryVo.driverNo) {
        this.$message.error("司机编号必填。");
        return false;
      }

      return true;
    },
    query () {
      if (!this.checkParam()) {
        return ;
      }
      this.$http.post("/admin/awardtask/driver/overview", this.queryVo).then(res => {
        this.loading = false;
        this.awardInfo = res.data || {};
        this.tasks = this.awardInfo.tasks || [];
        this.initChart(this.awardInfo.details);
      });
    },
    initChart (datas) {
      datas = datas || [];

      const labels = [];
      const series = [];
      datas.forEach(data => {
        for (var p in data) {
          if (p == 'flCode') {
            labels.push(p);
          } else if (this.chartMode == '奖金' && p == "totalAward") {
            series.push(data[p]);
          } else if (this.chartMode == '次数' && p == "totalReachNum") {
            series.push(data[p]);
          }
        }
      });
      const option = {
        xAxis: {
          data: labels
        },
        yAxis: {
        },
        series: [
          {
            data: series,
            type: "bar"
          }],

      };
      this.$nextTick(() => {
        const chart = echarts.init(this.$refs.chart);
        chart.setOption(option);
      });
    },
    viewTaskInfo (index, data) {
      console.log("view assistant index ", index);
      this.showTemplate = true;
      this.currentRow = data;
    },
    viewTaskFinishInfo (index, data) {
      console.log("view table index ", index);
      this.showTaskFinish = true;
      this.currentRow = data;
    },
    viewAssistantInfo (index, data) {
      console.log("view assistant index ", index);
      this.showAssistant = true;
      this.currentRow = data;
    }
  }
};
</script>

<style lang="less" scoped>
  .query-container {
      padding: 10px 0 10px 10px;
      div {
        display: inline-block;
      }
  }

  .summary {
    .summary-item {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px solid #dcdfe5;
        margin: 10px;
        border-radius: 2px;
        text-align: center;
        .num {
          margin-top: 20px;
          margin-bottom: 10px;
        }
    }
  }

  .detail-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .task-row {
    div {
      display: inline-block;
    }
    margin-bottom: 10px;
  }

  .red {
    color: red;
  }
  .chart {
    height: 300px;
    width: 820px;
  }

</style>