<template>
  <div>
    <div class="record" :class="{ answer: record.direct === -1 }">
      <img
        :src="dialogMember.headUrl"
        class="photo"
        v-if="record.direct === 1"
      />
      <img src="../../assets/image/kefu.png" class="photo" v-else />
      <div class="content">
        <div class="time">
          {{ record.sendTime | dateFormat("yyyy-MM-dd hh:mm:ss") }}
        </div>
        <div
          class="text"
          v-if="record.msgType == 'text'"
          :class="{ right: record.direct === -1, left: record.direct === 1 }"
          v-html="getTxtMsgContent(record.content)"
        ></div>

        <el-image
          v-else-if="record.msgType === 'image'"
          style="width: 100px; height: 100px"
          :src="record.media"
          fit="contain"
          :preview-src-list="[record.media]"
          :z-index="9999"
        ></el-image>

        <div
          class="media text"
          :class="{ right: record.direct === -1, left: record.direct === 1 }"
          v-else-if="record.msgType === 'voice'"
        >
          <span @click="playVoice">
            <i class="el-icon-video-play"></i> 语音消息
          </span>
          <a :href="record.media" target="_blank">
            <i class="el-icon-download"></i>
          </a>
        </div>

        <div
          class="media text"
          :class="{ right: record.direct === -1, left: record.direct === 1 }"
          v-else-if="record.msgType == 'video'"
        >
          <span @click="playVideo">
            <i class="el-icon-video-camera"></i> 视频消息
          </span>
          <a :href="record.media" target="_blank">
            <i class="el-icon-download"></i>
          </a>
        </div>

        <div
          class="location text"
          :class="{ right: record.direct === -1, left: record.direct === 1 }"
          v-else-if="record.msgType == 'location'"
        >
          {{ record.content }}
          <a
            v-if="!detail"
            style="
              color: #2aaaff;
              cursor: pointer;
              font-size: 14px;
              margin-left: 10px;
            "
            @click="getDetailAddress(record)"
            >详细地址</a
          >
          <div v-if="detail">{{ detail }}</div>
        </div>

        <div
          class="text warn"
          :class="{ right: record.direct === -1, left: record.direct === 1 }"
          v-else
        >
          不支持的消息类型
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" :before-close="closeVideo">
      <div style="max-height: 650px; overflow: auto">
        <video id="wxVideo" :src="vdsrc" autoplay />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import WECHAT_EMOJI from "./wechat.js";
export default {
  data () {
    return {
      vdsrc: "",
      detail: '',
      dialogVisible: false
    };
  },
  props: {
    record: {
      type: Object
    },
    dialogMember: {
      type: Object
    }
  },
  created () {
    window.AMap.plugin('AMap.Geocoder', () => {
      this.geocoder = new window.AMap.Geocoder();
    });
  },
  methods: {
    playVoice () {
      const url = this.record.media;
      const player = new AmrPlayer(url);
      // 等待下载完成
      setTimeout(() => {
        player.play();
      }, 1000);

    },
    playVideo () {
      this.dialogVisible = true;
      this.vdsrc = this.record.media;
    },
    closeVideo () {
      this.vdsrc = "";
      this.dialogVisible = false;
    },
    getDetailAddress (record) {
      this.geocoder.getAddress([record.lo, record.la], (status, result) => {
        if (status === 'complete' && result.regeocode) {
          const address = result.regeocode.formattedAddress;
          this.detail = address;
        } else {
          console.error('根据经纬度查询地址失败');
        }
      });
    },
    getTxtMsgContent (content) {
      var txt = content;
      var regex = /\/:/;
      while (regex.test(txt)) {
        for (var emoji in WECHAT_EMOJI) {
          txt = txt.replace(emoji, WECHAT_EMOJI[emoji]);
        }
      }
      return txt;
    }
  },

};
</script>

<style lang="less" type="text/less" scoped>
.record {
  display: flex;
  margin-bottom: 10px;
  &.answer {
    flex-direction: row-reverse;
    text-align: right;
  }

  margin-bottom: 30px;
}

.photo {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.content {
  margin: 0 10px;
  flex: 1;

  .time {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    position: relative;
    display: block;
    min-height: 18px;
    line-height: 1.4;
    background-color: #f3f3f3;
    border-radius: 4px;
    padding: 12px 9px;
    word-break: break-all;
    float: left;
    margin-left: 5px;
    padding-left: 17px;
    text-align: justify;
    word-break: break-all;
    max-width: 80%;

    &:after {
      position: absolute;
      content: "";
      width: 23px;
      height: 42px;
      top: 0;
    }

    &.left {
      &:after {
        left: -6px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAqCAYAAABYzsDTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzI0QTVCMjIxNjBBMTFFODlGQzlDRDUzQTg4RDcxQzMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzI0QTVCMjMxNjBBMTFFODlGQzlDRDUzQTg4RDcxQzMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMjRBNUIyMDE2MEExMUU4OUZDOUNENTNBODhENzFDMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMjRBNUIyMTE2MEExMUU4OUZDOUNENTNBODhENzFDMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsDqUnkAAADjSURBVHjaYvz8+TMDHiADxF1A7ADEkgyEwXMgPgDEZTw8PE8Y8RgOMvgiEAsxkA7eAbE+Ex4FXWQazADV14XP5c+IDAqcQYTP5ZQYDNbPxEBDMGr44DFci1aGJwDxaWoYzoLE5gLiaUAcT61gYUEKhtXUCg7kYImGBoMWtSMUVLY8o0JWx+nyQiD+RivDVwKxKRBfo1U6vwa1YAGtMhEoaBKh6fwbtSIUVw69Sqvsf42WZctokTtq+Kjho4aPGj58DH9Oodl4uy0HKDT8wIB1FZ+AFADxchKC6DlUvT6okwsQYAC/AjSzkv2m0gAAAABJRU5ErkJggg==);
      }
    }

    &.right {
      float: right;
      margin-right: 5px;
      padding-right: 17px;
      background-color: #d0e9ff;
      &:after {
        right: -6px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAqCAYAAABYzsDTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzI0QTVCMUUxNjBBMTFFODlGQzlDRDUzQTg4RDcxQzMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzI0QTVCMUYxNjBBMTFFODlGQzlDRDUzQTg4RDcxQzMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMjRBNUIxQzE2MEExMUU4OUZDOUNENTNBODhENzFDMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMjRBNUIxRDE2MEExMUU4OUZDOUNENTNBODhENzFDMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqBcZigAAAD0SURBVHjaYrzw8j8DEMgAcRcQOwCxJANh8ByIDwBxmb4YwxNcihiBhoMMvgjEQgykg3dArI/LAiaoi8kxmAGqrwuXJBM0KCgBDvgMl6TQcEl8htMMjBo+RA2/+IpBi5YuPw20IIFWhnMB8XygBQuAmItWYR4P9YUWrOD6T4O4/AbEabRKLaCg6aaV4SCXF7LQwOBrQBwKLOOvUdvlC4DYFGQwiMNCxWDIAhq6EFmQWobDXUv1TITN4NFScdTwUcNHDR81fHgZ/pxCM57jM/wAhYYfwGd4GbTLRw54B9WP03BQH1IfiJeTEETPoer18XVyAQIMACbMN3JUxFKPAAAAAElFTkSuQmCC);
      }
    }
  }

  .media {
    > span {
      cursor: pointer;
    }

    a {
      margin-left: 20px;
    }
  }

  .warn {
    color: salmon;
  }
}
</style>