<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" :model="param" inline>
        <el-row>
          <el-col :span="22">
            <el-form-item label="时间">
              <el-date-picker
                style="width: 160px"
                value-format="yyyy-MM-dd 00:00:00"
                v-model="param.startTime"
                type="date"
                placeholder="选择开始日期"
                clearable
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-date-picker
                style="width: 160px"
                value-format="yyyy-MM-dd 23:59:59"
                v-model="param.endTime"
                type="date"
                placeholder="选择结束日期"
                clearable
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="手机号码" prop="invitePassengerTel">
              <el-input
                v-model="param.invitePassengerTel"
                clearable
                style="width: 160px"
              />
            </el-form-item>
          </el-col>

          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="query()"
                >搜索</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div>
      <el-table :data="list" stripe max-height="560" size="small">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column
          prop="passengerId"
          label="邀请人ID"
          width="120"
        ></el-table-column>

        <el-table-column
          prop="invitePassengerTel"
          label="被邀请人电话"
          width="120"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="邀请/兑换过期时间"
          width="160"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.createTime }}</div>
            <div>{{ scope.row.expiredTime }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="邀请渠道">
          <template slot-scope="scope">{{
            scope.row.appRegisterTime ? "APP" : "小程序"
          }}</template>
        </el-table-column>

        <el-table-column prop="isInvalid" label="有效邀请">
          <template slot-scope="scope">
            <span :class="{ red: scope.row.isInvalid }">
              {{ scope.row.isInvalid ? "否" : "是" }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="wxExchanged" label="是否已兑换">
          <template slot-scope="scope">{{
            scope.row.wxExchanged || scope.row.appExchanged ? "是" : "否"
          }}</template>
        </el-table-column>

        <el-table-column prop="question1" label="问题1">
          <template slot-scope="scope">
            <el-button type="text" @click="showQuestion(scope.row.question1)">
              问题{{ scope.row.question1 }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column prop="question1Answer" label="问题1答案" />

        <el-table-column prop="wxExchanged" label="1问答时间" width="160">
          <template slot-scope="scope">
            <div>{{ scope.row.question1Time }}</div>
            <div :class="{ red: scope.row.isQuestion1Overtime }">
              {{ scope.row.question1AnswerTime }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="question2" label="问题2">
          <template slot-scope="scope">
            <el-button type="text" @click="showQuestion(scope.row.question2)">
              问题{{ scope.row.question2 }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="question2Answer" label="问题2答案" />

        <el-table-column prop="wxExchanged" label="2问答时间" width="160">
          <template slot-scope="scope">
            <div>{{ scope.row.question2Time }}</div>
            <div :class="{ red: scope.row.isQuestion2Overtime }">
              {{ scope.row.question2AnswerTime }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="lastOrderTime"
          label="最近下单时间"
          width="140"
        />

        <el-table-column prop="lastDealTime" label="最近成交时间" width="140" />

        <el-table-column label="操作" type="id" fixed="right" width="140">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="go2User(scope.row.passengerId, 1)"
              >邀请人</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              size="small"
              @click="go2User(scope.row.invitePassengerTel, 2)"
              >被邀请人</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="500px"
      title="邀请问题"
      :show-close="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div class="header">问题：{{ question.question }}</div>
      <div class="content">
        <div>选项：</div>
        <div>
          <div v-for="item in question.options" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getPageInviteList, getPassengerInviteQuestion} from '@/api';
import {Toolkit, ParamUtil} from '@/util';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
    })
  },
  data () {
    return {
      param: {},
      total: 0,
      currentPage: 1,
      asc: false,
      column: '',
      list: [],
      pageSize: 10,
      visible: false,
      question: {}
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize
        },
        param: ParamUtil.filterEmptyData(this.param),
        startTime: this.param.startTime,
        endTime: this.param.endTime,
      };
      if (this.column) {
        param.page.orders = [{
          column: this.column,
          asc: this.asc,
        }];
      }
      getPageInviteList(param).then(res=>{
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },


    showQuestion (id) {
      this.question = {};
      this.visible = true;
      getPassengerInviteQuestion({id}).then(res=>{
        this.question = res.data[0] || {};
      });
    },


    go2OrderList (telephone) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: {tel: telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    go2User (key, type) {
      let routeUrl;
      if (type == 1) {
        routeUrl = this.$router.resolve({
          path: `/passenger/list`,
          query: { id: key}
        });
      } else {
        routeUrl = this.$router.resolve({
          path: `/passenger/list`,
          query: {tel: key}
        });
      }

      window.open(routeUrl.href, '_blank');
    },

    handleSort (item) {
      if (item.order) {
        this.asc = item.order.indexOf('asc') > -1;
        this.column = Toolkit.toLine(item.prop);
        this.query();
      } else {
        this.column = null;
      }

    },

    handleCurrentChange (page) {
      this.query(page);
    },
    handleSizeChange (pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.query(1);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
}
</style>