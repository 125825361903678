<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="开始日期">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="param.startTime"
              style="width: 200px"
              type="datetime"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="param.endTime"
              style="width: 200px"
              type="datetime"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="路线：">
            <el-select v-model="param.pathId" placeholder="请选择" multiple clearable>
              <el-option
                v-for="item in pathOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="失败节点：">
            <el-select v-model="param.failTypes" placeholder="请选择" multiple clearable>
              <el-option
                v-for="item in confirmTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column prop="order_id" label="订单ID" width="150">
          <template slot-scope="scope">
            <el-link v-operate-order:transaction="scope.row.order_id" type="primary">{{
              scope.row.order_id
            }}</el-link></template
          >
        </el-table-column>

        <el-table-column prop="path_id" width="100" label="路线">
          <template slot-scope="scope">
            <span>{{ scope.row.path_id | pathNameTransfer }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dateTime" width="185" label="用车时间">
          <template slot-scope="scope">
            <span>{{ scope.row.date }} &nbsp;{{ scope.row.show_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" width="80" label="订票数量">
          <template slot-scope="scope">
            <span>{{ scope.row.number }}{{ scope.row.orderType === 2 ? "货" : "人" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="乘车人电话" width="160">
          <template slot-scope="scope">
            <tel-cell :order="scope.row" />
          </template>
        </el-table-column>
        <el-table-column prop="price" label="总价" width="100">
          <template slot-scope="scope">
            <span v-operate-order:bill="scope.row.order_id"
              >{{ scope.row.price | moneyFormat }}元</span
            >
          </template>
        </el-table-column>
        <el-table-column width="300" label="出发地/目的地">
          <template slot-scope="scope">
            <div>
              <div class="start cuIcon-title text-blue">
                {{ scope.row.departure }}
              </div>
              <div class="end cuIcon-title text-orange">
                {{ scope.row.destination }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="err_msg" label="异常问题" width="200"></el-table-column>

        <el-table-column prop="is_deal" label="是否处理">
          <template slot-scope="scope">{{ scope.row.is_deal ? "是" : "否" }}</template>
        </el-table-column>

        <el-table-column prop="fail_type" label="失败节点">
          <template slot-scope="scope">{{
            scope.row.fail_type | enumTransfer(confirmTypeOpts)
          }}</template></el-table-column
        >

        <el-table-column prop="remark" label="说明"></el-table-column>

        <el-table-column prop="create_time" label="创建时间" width="150"></el-table-column>

        <el-table-column prop="create_time" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              :disabled="scope.row.is_deal"
              @click="dealOrder(scope.row)"
              >处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BaseDataService } from "@/service";
import { queryFailOrderList, dealFailOrder } from "@/api";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
      confirmTypeOpts: (state) => state.enumMap["CONFIRM_TYPE"],
    }),
  },
  data() {
    return {
      yesNoOpts: BaseDataService.yesNoOpts(),
      param: {
        isDeal: true,
      },
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 1000,
      summary: [],
      availSummary: {},
      billSummary: {},
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: this.param,
      };
      queryFailOrderList(param).then((res) => {
        this.list = res.data || [];
        // const { pageNum = 1, total = 0, list = [], } = res.data || {};
        // this.currentPage = pageNum;
        // this.total = total;
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    dealOrder(item) {
      this.$confirm("确定处理该订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        dealFailOrder(item.id).then(() => {
          this.$message.success("处理成功");
          this.query();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.summary {
  margin: 10px 0;
  display: flex;
  .item {
    background: rgb(64, 158, 255);
    color: #fff;
    padding: 20px;
    margin: 0 40px;
    flex: 1;
    border-radius: 5px;
    text-align: center;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
