<template>
  <div class="remain-box">
    <el-radio-group v-model="remainType">
      <el-radio-button label="复购"></el-radio-button>
      <el-radio-button label="留存"></el-radio-button>
    </el-radio-group>

    <span v-show="remainType == '留存'" style="margin-left: 150px">
      <el-radio-group v-model="interval">
        <el-radio-button :label="1">1个月</el-radio-button>
        <el-radio-button :label="3">3个月</el-radio-button>
        <el-radio-button :label="6">6个月</el-radio-button>
        <el-radio-button :label="12">1年</el-radio-button>
      </el-radio-group>
    </span>

    <div v-show="remainType == '复购'">
      <div class="head">
        <div class="item">
          <label>开始时间：</label>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <label class="label">结束时间：</label>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <label class="label">订单路线：</label>
          <el-select
            multiple
            v-model="param.routes"
            clearable
            placeholder="请选项"
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <label class="label">订单渠道：</label>
          <el-select
            multiple
            v-model="param.orderSrcs"
            clearable
            placeholder="全部"
          >
            <el-option
              v-for="item in orderSourceOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <label class="label">订单类型：</label>
          <el-select v-model="param.orderStatus" clearable placeholder="全部">
            <el-option
              v-for="item in orderStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <label class="label">复购次数：</label>
          <el-input v-model="param.orderNum"></el-input>

          <el-button
            class="query-btn"
            style="margin-left: 20px"
            type="primary"
            @click="query"
            >查询</el-button
          >
        </div>
      </div>

      <div class="table">
        <div class="item">
          <chart-view
            ref="remain_chart1"
            valueField="value"
            nameField="name"
            :title="'总人数:' + passengerTotal"
            initChartType="pie"
            :height="300"
          />
        </div>

        <div class="item">
          <chart-view
            ref="remain_chart2"
            valueField="value"
            nameField="name"
            :title="'总单数:' + orderTotal"
            initChartType="pie"
            :height="300"
          />
        </div>

        <div class="item3">
          <chart-view
            ref="remain_chart3"
            valueField="value"
            nameField="name"
            :title="'总票数:' + ticketTotal"
            initChartType="pie"
            :height="300"
          />
        </div>
      </div>
    </div>

    <div v-show="remainType == '留存'">
      <div class="rem-item">
        <remain-view :interval="interval" />
      </div>
      <div class="rem-item">
        <remain-view :interval="interval" />
      </div>
      <div class="rem-item">
        <remain-view :interval="interval" />
      </div>
      <div class="rem-item">
        <remain-view :interval="interval" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../ChartView';
import RemainView from './remain.vue';
import {mapState} from 'vuex';
import { ParamUtil, DateUtil } from '@/util';

export default {
  components: {
    ChartView,
    RemainView
  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderSourceOpts: state => state.enumMap['ORDER_SOURCE'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      }
    }),
  },

  data () {
    return {
      remainType: '复购',
      param: {
        remainType: "",
        startDate: DateUtil.format(DateUtil.getDateTime(-30)),
        endDate: DateUtil.format(DateUtil.getDateTime()),
        route: "",
        orderSrc: "",
        orderType: "",
        orderNum: "12",
      },
      interval: 6,
      registerChanOpts: [{label: "自注册", value: 1}, {label: "分享注册", value: 2}, {label: "助力注册", value: 3}, {label: "广告注册", value: 4}],
      orderStatusOpts: [{label: "下单", value: ''}, {label: "成交", value: 'FINISH'}],
      summary: [],
      passengerTotal: 0,
      orderTotal: 0,
      ticketTotal: 0
    };
  },


  methods: {

    renderRepurchaseChart () {
      var data_passenger = [];
      var data_order = [];
      var data_ticket = [];
      this.passengerTotal = 0;
      this.orderTotal = 0;
      this.ticketTotal = 0;
      for (var i = 0; i < this.summary.length; i ++) {
        let name = "购" + this.summary[i].orderNum + "次";
        if (i == this.summary.length - 1) {
          name = "购" + this.summary[i].orderNum + "次及以上";
        }
        data_passenger.push({name, value: this.summary[i].passengerTotal});
        data_order.push({name, value: this.summary[i].orderTotal});
        data_ticket.push({name, value: this.summary[i].ticketTotal});
        this.passengerTotal += this.summary[i].passengerTotal;
        this.orderTotal += this.summary[i].orderTotal;
        this.ticketTotal += this.summary[i].ticketTotal;
      }
      this.$refs.remain_chart1.setData(data_passenger);
      this.$refs.remain_chart2.setData(data_order);
      this.$refs.remain_chart3.setData(data_ticket);
    },

    query () {
      this.param.remainType = "复购";
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }

      this.$http.post("/admin/analysis/passenger/remain", ParamUtil.filterEmptyData(this.param)).then(res => {
        this.summary = res.data || [];
        this.renderRepurchaseChart();
      });
    }
  }
};
</script>

<style scoped lang="less">
.remain-box {
  min-width: 1200px;
  min-height: 780px;
  padding: 10px;
  background-color: #edf1f5;

  .el-date-editor--date {
    width: 150px !important;
  }
  .el-select {
    width: 150px !important;
  }

  .rem-item {
    margin-bottom: 50px;
  }

  .head {
    margin-top: 20px;

    .item {
      margin-bottom: 10px;
    }
    .label {
      margin-left: 5px;
    }
    .el-input {
      width: 80px;
    }
    .query-btn {
      margin-left: 704px;
    }
  }
  .table {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-template-rows: repeat(1, 400px);
    grid-row-gap: 10px;
    grid-column-gap: 1%;
    padding-top: 20px;

    > div {
      background: #fff;
      border: 1px solid #ddd;
      position: relative;
    }
  }
}
</style>