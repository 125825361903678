<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item>
          <el-select v-model="param.rsType" placeholder="请选择资源类型" @change="query" clearable>
            <el-option
              v-for="item in rsTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端：" prop="agent">
          <el-select v-model="param.agent" placeholder="请选择" clearable @change="query">
            <el-option
              v-for="item in agentOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="query()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="openModel()">资源命名</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column label="资源id" prop="rsId"></el-table-column>
        <el-table-column label="资源名称" prop="name"> </el-table-column>
        <el-table-column label="资源类型" prop="rsType">
          <template slot-scope="scope">{{ scope.row.rsType | enumTransfer(rsTypeOpts) }}</template>
        </el-table-column>
        <el-table-column label="客户端" prop="agent">
          <template slot-scope="scope">{{ scope.row.agent | enumTransfer(agentOpts) }}</template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <span>
              <el-button type="text" size="small" @click="openModel(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      title="资源信息"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :size="size" label-width="120px">
        <el-form-item label="资源id" prop="rsId">
          <el-input v-model="form.rsId"></el-input>
        </el-form-item>

        <el-form-item label="资源名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="资源类型：" prop="rsType">
          <el-select v-model="form.rsType" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in rsTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端：" prop="agent">
          <el-select v-model="form.agent" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in agentOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { optTrackMetas, saveUpdateRsidInfo, deleteRsidInfo } from "@/api";
import { mapState } from "vuex";
import store from "@/store";
import { ADD_GLOBAL_DATA } from "@/store/mutation-types";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.size,
      agentOpts: (state) => state.enumMap["APM_AGENT"],
    }),
  },
  data() {
    return {
      rsTypeOpts: [
        { label: "按钮", value: "button" },
        { label: "页面", value: "page" },
      ],
      param: {},
      form: {},
      visible: false,
      list: [],
      rules: {
        rsId: [{ required: true, message: "请输入资源id", trigger: "change" }],
        name: [{ required: true, message: "请输入资源名称", trigger: "change" }],
        rsType: [{ required: true, message: "请选择资源类型", trigger: "change" }],
        agent: [{ required: true, message: "请选择客户端类型", trigger: "change" }],
      },
    };
  },
  methods: {
    query() {
      optTrackMetas().then((res) => {
        const list = res.data || [];
        store.commit(ADD_GLOBAL_DATA, {
          key: "apmRs",
          value: list,
        });
        this.list = list.filter((t) => {
          const agentFilter = this.param.agent ? t.agent === this.param.agent : true;
          const rsTypeFilter = this.param.rsType ? t.rsType === this.param.rsType : true;
          return rsTypeFilter && agentFilter;
        });
      });
    },

    openModel(item) {
      if (item) {
        this.form = { ...item };
      } else {
        this.form = {};
      }
      this.visible = true;
    },

    saveOrUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveUpdateRsidInfo(this.form).then(() => {
            this.visible = false;
            this.query();
            this.$message.success("操作成功");
          });
        }
      });
    },

    del(item) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRsidInfo(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
