<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="操作人：">
          <el-input v-model="form.operName" clearable style="width: 150px" />
        </el-form-item>

        <el-form-item label="操作动作：">
          <el-input v-model="form.title" clearable style="width: 150px" />
        </el-form-item>

        <el-form-item label="操作内容：">
          <el-input v-model="form.operParam" clearable style="width: 150px" />
        </el-form-item>

        <el-form-item label="操作开始时间：">
          <el-date-picker
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.startTime"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="操作结束时间：">
          <el-date-picker
            placeholder="选择时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            v-model="form.endTime"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="operName" label="操作人"></el-table-column>

        <el-table-column prop="title" label="操作动作"></el-table-column>

        <el-table-column prop="operParam" width="350px" label="操作参数">
          <template slot-scope="scope">
            <span class="text-json" v-if="scope.row.operParam">
              <span class="long-text">{{ scope.row.operParam }}</span>
              <span class="text-blue pointer" @click="showJson(scope.row.operParam)">查看详情</span>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="returnVal" width="350px" label="操作结果">
          <template slot-scope="scope">
            <span class="text-json" v-if="scope.row.returnVal">
              <span class="long-text">{{ scope.row.returnVal }}</span>
              <span class="text-blue pointer" @click="showJson(scope.row.returnVal)">查看详情</span>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="operTime" label="操作时间"></el-table-column>

        <el-table-column prop="action" label="操作类型">
          <template slot-scope="scope">{{
            scope.row.action | enumTransfer(operaTypeOpts)
          }}</template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="查看数据"
      :visible="visible"
      width="600px"
      @opened="opened"
      :before-close="handleClose"
    >
      <div id="json"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getOperateinLog } from "@/api";
import { ParamUtil, DateUtil } from "@/util";

export default {
  created() {
    this.query();
  },
  data() {
    return {
      form: {
        startTime: DateUtil.getDateTimeStr(0, "yyyy-MM-dd 00:00:00"),
        endTime: null,
      },
      list: [],
      total: 0,
      currentPage: 1,
      operaTypeOpts: [
        {
          label: "查询",
          value: 1,
        },
        {
          label: "新增",
          value: 2,
        },
        {
          label: "修改",
          value: 3,
        },
        {
          label: "删除",
          value: 4,
        },
      ],
      visible: false,
      operParam: null,
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
        },
        param: this.form,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
      };
      getOperateinLog(ParamUtil.filterEmptyData(param)).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange(page) {
      this.query(page);
    },
    showJson(json) {
      this.visible = true;
      this.operParam = json;
    },
    opened() {
      try {
        JSON.parse(this.operParam);
        $("#json").JSONView(this.operParam);
      } catch (e) {
        this.$message.error("JSON数据不正确");
      }
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="less">
.text-json {
  display: flex;
  .long-text {
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
  }

  .pointer {
    flex: 1;
    line-height: 30px;
  }
}

.head-box {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.1);

  .btn-add {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.condition {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
