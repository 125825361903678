<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="任务模板" name="ref1">
        <Templete ref="ref1" :changeTab="changeTab" />
      </el-tab-pane>
      <el-tab-pane label="待发任务" name="ref2">
        <ReadyRecordList ref="ref2" />
      </el-tab-pane>
      <el-tab-pane label="已发布任务" name="ref3">
        <SendRecordList ref="ref3" />
      </el-tab-pane>
      <el-tab-pane label="奖励统计" name="ref4">
        <Statistic ref="ref4" />
      </el-tab-pane>
      <el-tab-pane label="好评奖励" name="ref5">
        <WeekAward ref="ref5" />
      </el-tab-pane>
    </el-tabs>

    <div v-if="tab === 'ref3'">
      <el-button
        class="re-calc-rank-1"
        size="small"
        plain
        type="primary"
        @click="reCalcRank(false)"
        style="margin-right: 8px"
        >重新计算排名</el-button
      >
      <el-button class="re-calc-rank-2" size="small" plain type="primary" @click="reCalcRank(true)"
        >结算奖励任务</el-button
      >
    </div>
  </div>
</template>

<script>
import Templete from "./Templete";
import SendRecordList from "./SendRecordList";
import ReadyRecordList from "./ReadyRecordList";
import Statistic from "./Statistic";
import WeekAward from "./WeekAward";
// import ParticipateDetail from './ParticipateDetail';
import { initCreatePage, calcAwardRank } from "@/api";

export default {
  components: {
    ReadyRecordList,
    SendRecordList,
    Templete,
    Statistic,
    WeekAward,
  },
  props: {},
  created() {
    initCreatePage().then((res) => {
      this.$store.commit("reward/setInitData", res.data || {});
    });
  },
  mounted() {
    this.query();
  },
  watch: {},
  computed: {},
  data() {
    return {
      tab: "ref1",
    };
  },
  methods: {
    query() {
      this.$refs[this.tab].query && this.$refs[this.tab].query(1);
    },

    changeTab(tab) {
      this.tab = tab;
      this.query();
    },

    reCalcRank(isFinishTask) {
      this.$confirm(isFinishTask ? "确定要结束任务吗？" : "确定要重新计算排名吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          calcAwardRank(isFinishTask).then(() => {
            this.$message.success("重新计算排名成功");
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.root-box {
  position: relative;
  .re-calc-rank-1 {
    position: absolute;
    top: 10px;
    right: 120px;
  }

  .re-calc-rank-2 {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
</style>
