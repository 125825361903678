import Vue from 'vue';

/**
 * 订单操作
 */

const clickHandle = function () {
  // 注意这里一定要用this.binding
  const arg = this.binding.arg;
  const id = this.binding.value;
  if (arg === 'bill') {
    Vue.$orderBill().show(id);
  } else if (arg === 'log') {
    Vue.$orderLog().show(id);
  } else if (arg === 'transaction') {
    Vue.$orderTransaction().show(id);
  } else if (arg === 'billDetail') {
    Vue.$billDetal().show(id);
  }
};
Vue.directive('operateOrder', {
  bind: function (el, binding) {
    el.binding = binding;
    el.classList.add('dir-blue-pointer');
    el.addEventListener('click', clickHandle);
  },

  update: function (el, binding) {
    el.binding = binding;
    el.classList.add('dir-blue-pointer');
  },
  unbind: function (el) {
    el.classList.remove('dir-blue-pointer');
    el.removeEventListener('click', clickHandle);
  }
});



