<template>
  <div class="root">
    <div class="title" v-if="serviceType == 'repair'">维修订单</div>
    <div class="title" v-else-if="serviceType == 'maintenance'">保养订单</div>
    <div class="title" v-else-if="serviceType == 'inspection'">检查订单</div>
    <div class="title" v-else-if="serviceType == 'wash'">洗车订单</div>
    <div class="title" v-else-if="serviceType == 'vme'">车载维护订单</div>
    <div class="title" v-else-if="serviceType == 'assess'">定损订单</div>

    <div class="row">
      <div class="item">车牌号：{{ bill.vehicleNo }}</div>
      <div class="item">车型：{{ bill.brand }}</div>
      <div class="item">驾驶员：{{ bill.driverNo }}</div>
    </div>
    <div class="row">
      <div class="item">
        服务商：<el-select v-model="bill.providerId" placeholder="请选择">
          <el-option v-for="item in providers" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="item">经手人：{{ bill.staffName }}</div>
      <div class="item">
        截至时间：<el-date-picker v-model="bill.billDate" style="display: inline-block; width: 140px" type="date"
          value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
      </div>
    </div>
    <div class="row" v-if="serviceType == 'vme'">
      <div class="item">
        车载类目：<el-select v-model="bill.serviceProjs" multiple placeholder="请选择">
          <el-option v-for="item in vmeProjs" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row" v-if="serviceType == 'repair'">
      <div class="item">
        维修类目：<el-select v-model="bill.serviceProjs" multiple placeholder="请选择">
          <el-option v-for="item in repairProjs" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div>备注：<el-input v-model="bill.remark" /></div>
    </div>
    <div class="row">
      <div>创建原因说明：<el-input v-model="bill.createReason" /></div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveBill">确定</el-button>
      <el-button @click="cancelBill">取消</el-button>
    </span>
  </div>
</template>

<script>
import { getPartnerProviderList, getServiceProjs } from "@/api/partner_admin.js";
export default {
  name: "create-bill",
  props: {
    vehicleInfo: {
      type: Object,
    },
    serviceType: {
      type: String,
    },
  },
  data() {
    return {
      bill: {
        vehicleNo: null,
        brand: null,
        color: null,
        driverNo: null,
        driverName: null,
        privoderId: null,
        privoderName: null,
        staffName: null,
        billDate: null,
        serviceType: null,
        serviceProjs: [],
        remark: "",
      },
      providers: [],
      repairProjs: [],
      vmeProjs: [],
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.bill.vehicleNo = this.vehicleInfo.vehicleNo;
      this.bill.brand = this.vehicleInfo.brand;
      this.bill.color = this.vehicleInfo.color;
      this.bill.driverNo = this.vehicleInfo.driverNo;
      this.bill.driverName = this.vehicleInfo.driverName;
      let serviceType = this.serviceType;
      getPartnerProviderList({}).then((rs) => {
        let data = rs.data || {};
        let providers = data.providers || [];
        this.providers = providers.filter((p) => p.svcScopes.indexOf(serviceType) >= 0);
        if (this.providers.length == 1) {
          this.bill.providerId = this.providers[0].id;
          this.bill.providerName = this.providers[0].name;
        }
      });
      getServiceProjs().then((rs) => {
        let data = rs.data || {};
        this.repairProjs = data.repair || [];
        this.vmeProjs = data.vme || [];
      });
    },
    saveBill() {
      this.bill.serviceType = this.serviceType;
      if (!this.bill.providerId) {
        this.$message.error("请选择服务商。");
        return;
      }
      if (!this.bill.billDate) {
        this.$message.error("维修截至时间必填。");
        return;
      }
      this.$emit("billChange", { bill: this.bill, action: "save" });
    },
    cancelBill() {
      this.$emit("billChange", { bill: this.bill, action: "cancel" });
    },
  },
};
</script>

<style scoped lang="less">
.root {
  .title {
    font-size: 18px;
    text-align: center;
  }

  .row {
    margin: 20px 0;

    .item {
      display: inline-block;
      width: 33%;
    }
  }
}
</style>
