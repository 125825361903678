import { render, staticRenderFns } from "./TrainStat.vue?vue&type=template&id=616ab7ce&scoped=true"
import script from "./TrainStat.vue?vue&type=script&lang=js"
export * from "./TrainStat.vue?vue&type=script&lang=js"
import style0 from "./TrainStat.vue?vue&type=style&index=0&id=616ab7ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616ab7ce",
  null
  
)

export default component.exports