<template>
  <div v-if="show">
    <div
      v-drag
      v-show="!visible"
      class="client animated"
      :class="{ flash: shake }"
      @mouseenter="active = false"
      @mouseout="active = true"
      @click.stop="showDiag"
    ></div>

    <div ref="diag" v-show="visible" class="kefu" v-drag :class="{ active: active }" @click.stop>
      <div class="header" @dblclick="hideDiag">
        <div @dblclick.stop style="font-size: 16px" v-if="currentUser.nickName">
          正在和{{ msgSrcStr }} {{ currentUser.nickName }} 聊天...
        </div>
        <div class="hide"></div>
      </div>
      <div class="main" @mousedown.stop>
        <div class="user-box">
          <div class="user" v-for="(user, index) in userList" :key="user.id">
            <div class="box" @click="changeUser(user, index)">
              <el-badge :is-dot="!user.status">
                <div
                  :class="{ active: currentUser.msgSender === user.msgSender }"
                  class="avatar-box"
                >
                  <div class="avatar" :class="user.msgSrcFrom">
                    {{
                      user.msgSrcFrom === "JIM"
                        ? "APP"
                        : user.msgSrcFrom === "WX_MP"
                        ? "小程序"
                        : "微信"
                    }}
                  </div>
                </div>
              </el-badge>
              <div class="name">{{ user.nickName }}</div>
            </div>
          </div>
        </div>
        <div class="message">
          <div class="content" ref="textContainer">
            <div
              class="his-message"
              v-if="hasMore && currentUser.msgSender"
              v-loading="getHisMessage"
              element-loading-spinner="el-icon-loading"
              @click="getUserHisMessage"
            >
              {{ hasMore ? "获取历史消息" : "没有更多消息了" }}
            </div>
            <message
              v-for="item in message"
              :key="item.id"
              :record="item"
              :dialogMember="currentUser"
            ></message>
          </div>
          <div class="input-box">
            <div class="op-icon none">
              <el-upload
                class="upload-demo"
                action="/admin/kf/sendimgmsg"
                :show-file-list="false"
                :on-success="onUploadSuccess"
                :data="imgData"
              >
                <i class="el-icon-picture-outline"></i>
              </el-upload>

              <span class="zhi" @click="openZhi">
                知
                <div class="dia" v-show="tip2">
                  <div class="search" @click.stop>
                    <input @click.stop placeholder="输入关键字搜索问题" v-model="key" />
                  </div>
                  <div class="content">
                    <div
                      @click.stop="addWords(item.ans)"
                      class="box"
                      v-for="item in quesFiltered"
                      :key="item.que"
                    >
                      <div class="que">{{ item.que }}</div>
                      <div class="ans">{{ item.ans }}</div>
                    </div>
                  </div>
                </div>
              </span>

              <span class="zhi" @click="openChang">
                常
                <div v-show="tip1" class="dia common">
                  <div v-for="item in commonWords" :key="item" @click.stop="addWords(item)">
                    {{ item }}
                  </div>
                </div>
              </span>
            </div>

            <div class="input-area">
              <textarea
                ref="textarea"
                @focus="clear"
                @keydown.ctrl.enter="send"
                v-model="content"
                cols="30"
                rows="10"
              ></textarea>
              <el-button size="small" style="width: 80px" class="send" @click="send"
                >发送</el-button
              >
            </div>
          </div>
        </div>

        <div class="info" v-loading="loading">
          <div class="title">用户信息</div>
          <div class="user">
            <el-form class="psg-form" inline size="small" v-if="userInfo">
              <el-form-item label="称呼：">
                <span>
                  {{ userInfo.name }}
                  {{ userInfo.vipLevel ? `（VIP${userInfo.vipLevel}）` : "" }}</span
                >
              </el-form-item>

              <el-form-item label="邀请码：">
                <div class="text">{{ userInfo.inviteCode }}</div>
              </el-form-item>

              <el-form-item label="手机号：">
                <div class="text">{{ userInfo.telephone }}</div>
              </el-form-item>

              <el-form-item label="积分：">
                <div class="text">{{ userInfo.totalPoints }}</div>
              </el-form-item>

              <el-form-item label="优惠券：">
                <div class="text">{{ userInfo.discount }}</div>
              </el-form-item>

              <el-form-item label="注册日期：">
                <div class="text">
                  {{ userInfo.telRegisterTime || userInfo.wxRegisterTime }}
                </div>
              </el-form-item>

              <el-form-item label="备注：">
                <div class="text">{{ userInfo.remark }}</div>
              </el-form-item>
            </el-form>
          </div>
          <div class="orders">
            <div class="title">订单记录</div>
            <div class="table" style="max-height: 400px">
              <el-table
                :show-header="false"
                :max-height="400"
                :height="400"
                :data="orderList"
                :stripe="true"
                style="width: 100%"
              >
                <el-table-column prop="date" label="路线" width="100px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.pathId | pathNameTransfer }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="date" label="日期" width="110px">
                  <template slot-scope="scope">
                    <div>{{ scope.row.date }}</div>
                    <div>{{ scope.row.showTime }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="num" label="人数" width="100px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.number }}人 {{ scope.row.price | moneyFormat }}元</span>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="120px">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.orderStatus | enumTransfer(orderStatusOpts) }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="司机" prop="driverNo" width="90">
                  <template slot-scope="scope">
                    <div>{{ scope.row.driverNo }}</div>
                    <div>{{ scope.row.driverName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="车牌号" width="90">
                  <template slot-scope="scope">
                    <div>{{ scope.row.vehicleNo }}</div>
                    <div v-if="scope.row.seats">{{ scope.row.seats }}座</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <video style="display: none" controls="controls" id="qq">
      <source src="../../../static/video/qq.mp3" />
      浏览器不支持html5视频功能
    </video>
  </div>
</template>

<script>
import { KefuService } from "@/service";
import Message from "./Message";
import { questions, commonWords } from "./question";
import { queryKfUserData } from "@/api";
import { mapState } from "vuex";
import { DateUtil } from "@/util";

let msgMap = {};
export default {
  components: {
    Message,
  },
  mounted() {
    document.body.addEventListener("click", (e) => {
      // 如果点击的是el-image相关的元素，则不关闭，其他都关闭
      if (
        e.target.className &&
        e.target.className.indexOf("el-image") === -1 &&
        e.target.parentNode.className.indexOf("el-image") === -1
      ) {
        this.visible = false;
      }
    });
  },
  watch: {
    message(val) {
      if (val && val.length) {
        const lastMsg = val[val.length - 1];
        // 设置当前的
        this.imgData = {
          msgSender: lastMsg.msgSender,
          msgTo: lastMsg.msgTo,
          msgSrc: lastMsg.msgSrc,
        };
      }
    },
    show(val) {
      if (val) {
        this.$nextTick(() => {
          this.initPosition();
        });
      }
    },
  },
  computed: {
    ...mapState({
      orderStatusOpts: (state) => state.enumMap["ORDER_STATUS"],
    }),
    quesFiltered() {
      if (this.key) {
        const result = this.questions.filter(
          (item) => item.que.indexOf(this.key) > -1 || item.ans.indexOf(this.key) > -1
        );
        return result;
      }
      return this.questions;
    },
    shake() {
      const unReadUser = this.userList.filter((item) => !item.status);
      return unReadUser.length > 0 && this.active;
    },
    msgSrc() {
      if (this.currentUser && this.currentUser.msgSrcFrom) {
        return this.currentUser.msgSrcFrom;
      }
      return "WX_H5";
    },
    msgSrcStr() {
      if (this.msgSrc.indexOf("WX_H5") > -1) {
        return "来自 微信 的";
      } else if (this.msgSrc.indexOf("WX_MP") > -1) {
        return "来自 小程序 的";
      } else if (this.msgSrc.indexOf("JIM") > -1) {
        return "来自 APP 的";
      }
      return "";
    },
  },
  data() {
    return {
      visible: false,
      show: false,
      active: true,
      size: 55,
      commonWords,
      questions,
      content: "",
      tip1: false,
      tip2: false,
      key: "",
      orderList: [],
      userInfo: null,
      userList: [],
      message: [],
      currentUser: {},
      imgData: {},
      getHisMessage: false,
      hasMore: true,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.show = true;
      const result = await KefuService.online();
      if (result === "ok") {
        KefuService.getUserList().then((res) => {
          this.userList = res.data || [];
        });

        // 订阅单条消息
        KefuService.subscribeMessage().then((topic) => {
          topic.subscribe((msg) => {
            this.handleMessage(msg);
          });
        });

        // 订阅用户整个会话信息消息
        KefuService.subscribeAllMessage().then((topic) => {
          topic.subscribe((res) => {
            this.handleAllMessage(res.data);
          });
        });

        // 订阅获取用户信息消息
        KefuService.subscribeUserInfo().then((topic) => {
          topic.subscribe((res) => {
            this.handleGetUserInfo(res.data);
          });
        });

        // 订阅用户历史消息
        KefuService.subscribeHisMessage().then((topic) => {
          topic.subscribe((res) => {
            if (res.data) {
              const { msgSender, msgs = [] } = res.data || {};
              this.handleUserHisInfo(msgs, msgSender);
            }
          });
        });

        // 订阅发送消息回执
        KefuService.sendMsgCallback().then((topic) => {
          topic.subscribe((res) => {
            if (res.code === 200) {
              // 发送成功才清除对话框内容
              this.content = "";
              this.message.push(res.data);
              this.scrollBottom();
            } else {
              this.$message.error(res.message || "消息发送失败");
            }
          });
        });
      }
    },
    async logout() {
      await KefuService.offline();
      KefuService.disconnect().then(() => {
        this.$message.success("退出微信客服成功");
        this.show = false;
        this.visible = false;
        this.message = [];
        msgMap = {};
        this.currentUser = {};
        this.orderList = [];
        this.userInfo = null;
        this.userList = [];
      });
    },
    openChang() {
      this.tip1 = !this.tip1;
      if (this.tip1) {
        this.tip2 = false;
      }
    },

    handleGetUserInfo(userInfo) {
      const findItem = this.userList.find((item) => item.msgSender === userInfo.msgSender);
      if (!findItem) {
        this.userList.unshift(userInfo);
      }
    },

    getUserHisMessage() {
      if (this.hasMore) {
        this.getHisMessage = true;
        if (this.currentUser) {
          const msgSender = this.currentUser.msgSender;
          let lastTime = new Date().getTime();
          if (msgMap[msgSender] && msgMap[msgSender][0]) {
            const sendTime = msgMap[msgSender][0].sendTime;
            if (sendTime) {
              lastTime = new Date(sendTime).getTime();
            }
          }
          console.log(
            "获取历史消息时间",
            DateUtil.format(new Date(lastTime), "yyyy-MM-dd hh:mm:ss")
          );
          KefuService.getUserHisMsg(msgSender, lastTime).catch(() => {
            this.getHisMessage = false;
          });
        }
      }
    },

    handleMessage(msg) {
      const msgSender = msg.msgSender;
      if (this.currentUser && msgSender === this.currentUser.msgSender && this.visible) {
        msgMap[msgSender].push(msg);
        this.message = msgMap[msgSender];
        this.scrollBottom();
        // 标记已读
        KefuService.markRead(msgSender);
      } else {
        $("#qq").trigger("play");
        // 不是当前用户的消息，切换用户
        if (!msgMap[msgSender]) {
          msgMap[msgSender] = [msg];
        } else {
          msgMap[msgSender].push(msg);
        }

        // 找到当前用户
        const index = this.userList.findIndex((item) => item.msgSender === msgSender);
        if (index > -1) {
          const user = this.userList[index];
          user.status = 0;
          // 把当前用户放到最前面
          this.userList.splice(index, 1);
          this.userList.unshift(user);
        } else {
          // 获取用户信息，增加到用户列表
          KefuService.getUserInfo(msgSender);
        }
      }
    },

    handleUserHisInfo(msgs, msgSender) {
      if (this.hasMore) {
        this.getHisMessage = false;
        if (msgs && msgs.length > 0) {
          const currentMsgs = msgMap[msgSender] || [];
          const messages = msgs.reverse();
          currentMsgs.splice(0, 0, ...messages);
          this.message = currentMsgs;
          if (msgs.length < 20) {
            this.hasMore = false;
          }
        } else {
          this.hasMore = false;
        }
      }
    },
    handleAllMessage(data) {
      this.hasMore = true;
      // 切换用户的时候获取用户之前的聊天记录
      const msgSender = this.currentUser.msgSender;
      const messages = msgMap[msgSender] || data.msgs || [];
      msgMap[msgSender] = messages;
      this.message = messages;
      this.scrollBottom();
      // 标记已读
      KefuService.markRead(msgSender);
    },
    send() {
      if (this.currentUser && this.currentUser.msgSender) {
        const msg = {
          content: this.content,
          msgType: "text",
          sendTime: new Date(),
          direct: -1,
          msgSender: this.currentUser.msgSender,
          msgSrc: this.msgSrc,
        };
        KefuService.sendMsg(msg);
      }
    },
    openZhi() {
      this.tip2 = !this.tip2;
      if (this.tip2) {
        this.key = "";
        this.tip1 = false;
      }
    },
    addWords(word = "") {
      this.content += `${word} `;
      this.tip1 = false;
      this.tip2 = false;
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 0);
    },
    clear() {
      this.tip1 = false;
      this.tip2 = false;
    },
    showDiag() {
      this.visible = true;
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 0);

      this.active = false;
    },

    onUploadSuccess(res) {
      if (res.data && res.code === 200) {
        this.message.push(res.data);
      } else {
        this.$message.error(res.message);
      }
    },

    hideDiag() {
      this.visible = false;
    },

    changeUser(user, index) {
      if (this.currentUser && this.currentUser.msgSender === user.msgSender) {
        if (!user.status) {
          user.status = 1;
          this.$set(this.userList, index, user);
          this.scrollBottom();
        }
        return;
      }
      user.status = 1;
      this.$set(this.userList, index, user);
      this.currentUser = user;
      this.queryUserData(user.userId, user.role);
      KefuService.getUserAllMsg(user.msgSender);
      this.message = [];
    },

    queryUserData(id, role) {
      // 如果不存在id，则说明是未注册的新用户，则不需要查询用户信息
      if (id) {
        this.loading = true;
        queryKfUserData(id, role)
          .then((res) => {
            const {
              passenger = {},
              passengerOrderList = [],
              totalPoints = 0,
              usableCoupons = {},
            } = res.data || {};
            // 积分
            passenger.totalPoints = totalPoints;
            //统计优惠券张数
            const discountArr = [];
            for (let amount in usableCoupons) {
              const num = usableCoupons[amount].length;
              discountArr.push(`${amount}元${num}张`);
            }
            passenger.discount = discountArr.join("，");
            this.orderList = passengerOrderList;
            this.userInfo = passenger;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.userInfo = null;
        this.orderList = [];
      }
    },
    scrollBottom() {
      this.$nextTick(() => {
        const textContainer = this.$refs.textContainer;
        textContainer.scrollTop = textContainer.scrollHeight;
      });
    },
    initPosition() {
      // 计算窗口居中位置
      const width = document.body.clientWidth;
      const height = document.body.clientHeight;
      const top = (height - 675) / 2;
      const left = (width - 1100) / 2;
      this.$refs.diag.style.left = left + "px";
      this.$refs.diag.style.top = top + "px";
    },
  },
};
</script>
<style lang="less" scoped>
.client {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  right: 50px;
  bottom: 100px;
  background-image: url("https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg");
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 9999;

  &.flash {
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
}

.zhi {
  position: relative;

  .dia {
    position: absolute;
    background-color: white;
    width: 600px;
    height: 350px;
    overflow: auto;
    bottom: 35px;
    background-color: rgb(49, 49, 49);
    border-radius: 3px;

    left: 0;
    text-align: left;
    cursor: default;
    box-shadow: 3px 3px 8px #b5b0b0;

    .search {
      input {
        position: relative;
        width: 100%;
        border: none;
        outline: none;
        line-height: 30px;
        padding-left: 5px;
        box-sizing: border-box;
        background-color: #908d8d;
        color: white;
      }
    }

    .content {
      height: 280px;
      overflow: auto;
    }

    div {
      display: block;
      color: #cec8c8;
      font-size: 14px;
      padding: 5px;
      cursor: pointer;
    }

    &.common {
      height: 300px;
      width: 500px;
    }

    .box {
      height: auto;
      .que {
        padding-left: 5px;
        font-weight: bold;
      }

      .ans {
        padding-left: 20px;
      }
    }
  }
}

.kefu {
  position: fixed;
  width: 1200px;
  height: 680px;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9999;

  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  box-shadow: 3px 3px 8px #b5b0b0;

  .el-avatar {
    border: 1px solid #f5efef;
  }

  .his-message {
    text-align: center;
    color: #409eff;
    font-size: 12px;
    cursor: pointer;
    margin: 5px 0;
    height: 40px;
  }

  .header {
    background: rgb(0, 122, 255);
    height: 50px;
    border-radius: 5px 5px 0 0;

    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #fff;
    align-items: center;
    padding: 10px;

    .hide {
      i {
        font-size: 24px;
      }
    }
  }

  .main {
    height: 610px;
    display: flex;
    .user-box {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
      border-right: 1px solid #e7e7e7;
      width: 160px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .user {
        margin: 3px 0;
        cursor: pointer;

        .box {
          display: flex;
          align-items: center;
          .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 5px;
            font-size: 14px;
          }

          .avatar-box {
            opacity: 0.4;

            &.active {
              opacity: 1;
            }

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 4px;
              color: white;
              font-size: 13px;
              line-height: 40px;
              padding: 4px;
              text-align: center;

              &.WX_H5 {
                background-color: #15ad31;
              }

              &.WX_MP {
                background-color: #409eff;
              }

              &.JIM {
                background-color: #cbd057;
              }
            }
          }
        }
      }
    }

    .message {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        padding: 10px;
        overflow: auto;
      }

      .input-box {
        height: 150px;
        border-top: 1px solid #e7e7e7;

        .op-icon {
          padding: 5px 10px;
          display: flex;
          i {
            font-size: 24px;
            cursor: pointer;
          }

          .zhi {
            margin-left: 15px;
            cursor: pointer;
          }
        }

        .input-area {
          overflow: hidden;
          box-sizing: border-box;
          position: relative;

          textarea {
            width: 98%;
            border: none;
            height: 100px;
            outline: none;
            padding: 5px 10px;
          }

          .send {
            position: absolute;
            right: 10px;
            bottom: 10px;
          }
        }
      }
    }

    .info {
      width: 380px;
      border-left: 1px solid #e7e7e7;
      font-size: 14px;

      .user {
        padding: 5px 10px;
        border-bottom: 1px solid #e7e7e7;
        height: 120px;
        overflow: auto;
        .psg-form {
          .el-form-item--mini.el-form-item,
          .el-form-item--small.el-form-item {
            margin-bottom: 8px;
          }
        }
      }

      .title {
        background-color: #f3f3f3;
        border-bottom: 1px solid #e7e7e7;
        padding: 5px 10px;
        color: #3a3939;
      }

      .orders {
        .table {
          padding: 3px;
        }

        .item {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
