import { Http } from "@/util";

export function getTollFeeSettlementList(param) {
  return Http.post("/admin/settlement/toll_fee/list", param);
}

export function getEtcTripList(routeId) {
  return Http.get("/admin/settlement/etc_trip/list", { routeId });
}

export function toolFeeConfirm(param) {
  return Http.post("/admin/settlement/fee_confirm", param);
}
