<template>
  <div class="not-found">
    <div class="not">404</div>
    <div class="word">瞎晃悠啥呢，<a href="/">点击</a>此处回首页干活</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  mounted () {

  }
};
</script>

<style scoped lang="less" type="text/less">
.not-found {
  .not {
    text-align: center;
    padding-top: 200px;
    font-size: 6em;
    color: #ddd;
  }

  .word {
    text-align: center;
    padding-top: 50px;
    font-size: 2em;
    color: #999;
    a {
      color: #2aaaff;
      text-decoration: none;
    }
  }
}
</style>
