<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div></div>
      <div>
        <el-button type="primary" :size="size" @click="openEdit()">新增配置</el-button>
      </div>
    </div>
    <el-table :data="list" stripe max-height="600">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="pathId" label="路线">
        <template slot-scope="scope">
          <span>{{ scope.row.pathId | pathNameTransfer }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="vehicleType" label="车型">
        <template slot-scope="scope">
          <span>{{ scope.row.vehicleType | enumTransfer(vehicleTypeOpts, "id", "content") }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="routeTime" label="平均行程时间（分钟）"></el-table-column>
      <el-table-column prop="chargingTime" label="补电时间（分钟）"></el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="delConfig(scope.row)">删除</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      title="车型相关配置"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :size="size" label-width="120px" :rules="rules">
        <el-form-item label="路线：" prop="pathId">
          <el-select v-model="form.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车型：" prop="vehicleTypeId">
          <el-select v-model="form.vehicleType" style="width: 100%">
            <el-option
              v-for="item in vehicleTypeOpts"
              :key="item.id"
              :label="item.content"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="平均行程时间：" prop="routeTime">
          <el-input-number style="width: 100%" v-model="form.routeTime"></el-input-number>
        </el-form-item>

        <el-form-item label="补电时间：" prop="chargingTime">
          <el-input-number style="width: 100%" v-model="form.chargingTime"></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listVehicleTypeConfig,
  deleteVehicleTypeConfig,
  saveOrUpdateVehicleTypeConfig,
} from "@/api";
import { mapState } from "vuex";
import { Toolkit } from "@/util";
export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
    }),
  },
  data() {
    return {
      visible: false,
      form: {},
      list: [],
      rules: {
        pathId: [{ required: true, message: "请选择路线", trigger: "blur" }],
        vehicleType: [{ required: true, message: "请选择车型", trigger: "blur" }],
        routeTime: [{ required: true, message: "请输入平均路程时间", trigger: "blur" }],
      },
    };
  },
  methods: {
    query() {
      listVehicleTypeConfig().then((res) => {
        this.list = res.data;
      });
    },

    openEdit(item) {
      if (item) {
        this.form = { ...item };
      } else {
        this.form = {};
      }
      this.visible = true;
    },

    saveOrUpdate() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = Toolkit.filterEmpty({ ...this.form });
        saveOrUpdateVehicleTypeConfig(param).then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.query();
        });
      });
    },

    delConfig(item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVehicleTypeConfig(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.query-container {
  padding: 10px 20px !important;
}
</style>
