import { Http } from "@/util";

// 获取司机轨迹
export function getDriverTrack(param) {
  return Http.get("/admin/location/gettrack", { ...param, _hideLoading: true });
}

// 按条件查询司机的定位
export function getAllDriverLocation(param) {
  return Http.get("/admin/location/many/drivers", param);
}

// 查询多个司机定位
export function getDriversLocation(driverIds) {
  return Http.post("/admin/location/drivers", { driverIds });
}

// 查询单个司机定位
export function getDriverLocation(driverId) {
  return Http.get("/admin/location/driver", { driverId, _hideLoading: true });
}

// 查询司机位置和行程信息
export function getDriverLocationAndRoute(param) {
  return Http.get("/admin/location/drivers/route", param);
}
