<template>
  <div class="root-box">
    <div v-for="item in num" :key="item">
      <data-view />

      <div v-if="item > 1">
        <el-button type="primart" :size="size" @click="delGroup">
          删除对比组</el-button
        >
      </div>
    </div>

    <div class="btn-add">
      <el-button type="primary" :size="size" @click="addGroup">
        添加对比组</el-button
      >
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import DataView from './DataView';


export default {
  components: {
    DataView
  },
  props: {},
  created () {

  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderSourceOpts: state => state.enumMap['ORDER_SOURCE'],
    }),
  },
  data () {
    return {

      typeOpts: [{
        label: '订单渠道',
        value: 1,
      }, {
        label: '车队',
        value: 2,
      }],
      param: {
        type: 1
      },
      num: 1,
      list: []
    };
  },
  methods: {
    query () {},
    addGroup () {
      this.num = this.num + 1;
    },
    delGroup () {
      if (this.num > 1) {
        this.num = this.num - 1;
      }

    }

  },
};
</script>
<style lang="less" scoped>
.btn-add {
  position: fixed;
  right: 50px;
  top: 60px;
}
</style>