<template>
  <div class="root-box">
    <el-form ref="form" size="small" inline>
      <el-row>
        <el-col :span="8" v-if="data.mainTask1">
          <el-form-item label="主考核项1：">
            <span> {{ data.mainTask1.task | enumTransfer(taskOpts) }}</span>
            <span class="assess-item-small">
              {{ data.mainTask1.assessReach | enumTransfer(assessOpts) }}</span
            >
            <span class="assess-item-small">
              {{ data.mainTask1.assessValue }}</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="考评逻辑：">
            <span>
              {{ data.mainTask1.assessSort | enumTransfer(sortOpts) }}</span
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="data.mainTask2">
        <el-col :span="8">
          <el-form-item label="主考核项2：">
            <span> {{ data.mainTask2.task | enumTransfer(taskOpts) }}</span>
            <span class="assess-item-small">
              {{ data.mainTask2.assessReach | enumTransfer(assessOpts) }}</span
            >
            <span class="assess-item-small">
              {{ data.mainTask2.assessValue }}</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="考评逻辑：">
            <span>
              {{ data.mainTask2.assessSort | enumTransfer(sortOpts) }}</span
            >
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-for="(item, index) in data.assistTask" :key="item.id">
        <el-form-item :label="`辅考评项${index + 1}：`">
          <span> {{ item.task | enumTransfer(taskOpts) }}</span>
          <span class="assess-item-small">
            {{ item.assessReach | enumTransfer(assessOpts) }}</span
          >
          <span class="assess-item-small"> {{ item.assessValue }}</span>
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item label="模板标题：">
          <span class="form-item"> {{ data.name }}</span>
        </el-form-item>

        <el-form-item label="奖金额度：">
          <span class="form-item"> {{ data.award }}</span>
        </el-form-item>

        <el-form-item label="人数限制：">
          <span class="form-item"> {{ data.awardNum }}</span>
        </el-form-item>

        <el-form-item label="备注：">
          <span class="form-item"> {{ data.remark }}</span>
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item label="任务说明：">
          <textarea
            style="padding: 10px"
            class="form-item"
            cols="100"
            rows="6"
            disabled
            :value="data.description"
          ></textarea>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  components: {},
  props: {
    data: Object
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      taskOpts: state => state.reward.initData.tasks || [],
      sortOpts: state => state.reward.initData.sorts || [],
      assessOpts: state => state.reward.initData.assesses || [],
    }),
  },
  data () {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.form-item {
  margin-right: 40px;
}

.assess-item {
  margin-left: 20px;
}

.assess-item-small {
  margin-left: 8px;
}
</style>