export default class FileUtil {
  static isImage(src) {
    return /(.jpg|.jfif|.jpeg|.png|.bmp|.gif)$/i.test(src);
  }

  static isVedio(src) {
    return /(.mp4|.m3u8|.3gp)$/i.test(src);
  }
  static isAudio(src) {
    return /(.mp3|.wav)$/i.test(src);
  }

  static getFileName(url) {
    return url.split('/').pop();
  }
}
