<template>
  <div class="pj-root">
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="账期" width="200">
        <template slot-scope="scope">
          {{ scope.row.startDate + "至" + scope.row.endDate }}
        </template>
      </el-table-column>
      <el-table-column prop="providerName" label="服务商" width="200"> </el-table-column>
      <el-table-column prop="serviceCnt" label="服务次数" width="120"> </el-table-column>
      <el-table-column label="合计费用（元）" width="120">
        <template slot-scope="scope">
          {{ scope.row.amount ? scope.row.amount / 100 : 0 }}
        </template>
      </el-table-column>
      <el-table-column label="是否结算" width="120">
        <template slot-scope="scope">
          {{ scope.row.status == 0 ? "未结算" : "已结算" }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="结算凭证" width="120">
        <template slot-scope="scope">
          <el-link type="primary" v-if="scope.row.status == 1" @click="showPj(scope.row)">附件</el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-link type="primary" v-if="scope.row.status == 0" @click="uploadPj(scope.row)">去结算</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="预览" :visible.sync="showPjDialog">
      <el-image style="width: 500px; height: 500px" :src="currentSettle.photo" v-if="!!currentSettle"
        fit="contain"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(showPjDialog = false), (currentSettle = null)">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="上传附件" :visible.sync="uploadPjDialog">
      <image-upload v-model="photoUrl" filePath="/partner" bucket="yxmgt-public" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="(uploadPjDialog = false), (currentSettle = null)">取消</el-button>
        <el-button type="primary" @click="savePj">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listBillSettle, uploadSettleAttach } from "@/api/partner_admin";
export default {
  name: "billList",
  props: {
    provider: {
      type: Number,
    },
  },
  data() {
    return {
      list: [],
      photoUrl: null,
      showPjDialog: false,
      uploadPjDialog: false,
      currentSettle: null,
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      listBillSettle(this.provider).then((res) => {
        this.list = res.data || [];
      });
    },
    showPj(settle) {
      this.currentSettle = settle;
      this.showPjDialog = true;
    },
    uploadPj(settle) {
      this.currentSettle = settle;
      this.uploadPjDialog = true;
    },
    savePj() {
      if (!this.photoUrl) {
        this.$message.error("请先上传凭据");
        return;
      }
      uploadSettleAttach(this.currentSettle.id, this.photoUrl).then(() => {
        this.currentSettle.status = 1;
        this.currentSettle.photo = this.photoUrl;
        this.uploadPjDialog = false;
        this.currentSettle = null;
      });
    },
  },
};
</script>

<style scoped></style>
