import { Http } from "@/util";

export function getActiveRoute(driverId) {
  return Http.get("/admin/route/active/list", { driverId });
}

export function getActiveRouteList(param) {
  return Http.post("/admin/route/active/list/with/orders", param);
}

export function getRouteListWithOrders(param) {
  return Http.post("/admin/route/list/with/orders", param);
}

export function getRouteList(param) {
  return Http.post("/admin/route/list", param);
}

export function getRouteReplay(routeId) {
  return Http.get("/admin/route/route_detail", { routeId });
}

// 查询分流
export function getRouteShunt(routeId) {
  return Http.get("/admin/route/shunt/list", { routeId });
}

// 保存分流信息
export function saveRouteShunt(param) {
  // 过滤掉空id
  if (param.orderIds) {
    param.orderIds = param.orderIds.filter((item) => item);
  }
  return Http.post("/admin/route/save/shunt_plan", param);
}

// 分流订单排序
export function sortShuntOrder(param) {
  param._hideLoading = true;
  // 过滤掉空id
  if (param.orderIds) {
    param.orderIds = param.orderIds.filter((item) => item);
  }
  return Http.post("/admin/route/shunt/sort_order", param);
}

// 分流订单排序
export function delShuntOrder(param) {
  param._hideLoading = true;
  return Http.post("/admin/route/del/shunt", param);
}

// 发送流信息给乘客
export function sendShuntMsg(param) {
  return Http.get("/admin/route/send/shunt_msg", param);
}

// 查询接送排序详情
export function getRoutePlan(routeId) {
  return Http.get("/admin/route/query/route_plan", { routeId });
}

// 创建系统排序
export function createSysRoutePlan(param) {
  return Http.get("/admin/route/test/sequence", param);
}

// 手动指定接送顺序
export function manualSpecPlan(param) {
  return Http.post("/admin/route/manual_spec/route_plan", param);
}

// 模拟指定接送顺序
export function simulateRoutePlan(param) {
  return Http.post("/admin/route/simulate/route_plan", param);
}

// 确认接送顺序
export function confirmRoutePlan(routeId) {
  return Http.get("/admin/route/confirm/route_plan", { routeId });
}

export function changeRouteAreaControl(param) {
  return Http.post("/admin/route//change/area_control", param);
}
