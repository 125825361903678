<template>
<div class="root">
  <el-table :data="vehicleList" style="width: 100%">
    <el-table-column
            prop="vehicleNo"
            label="车牌号">
    </el-table-column>
    <el-table-column
            prop="brand"
            label="车辆型号">
    </el-table-column>
    <el-table-column
            prop="driverNo"
            label="司机编号">
    </el-table-column>
    <el-table-column
            prop="billDate"
            label="时间">
    </el-table-column>
    <el-table-column
            prop="createType"
            label="维修来源">
    </el-table-column>
    <el-table-column
            prop="cbyName"
            label="发起人">
    </el-table-column>
    <el-table-column
            prop="providerName"
            label="服务商">
    </el-table-column>
    <el-table-column
            prop="staffName"
            label="检查人">
    </el-table-column>
    <el-table-column
            prop="amount"
            label="预计费用">
    </el-table-column>
    <el-table-column
            prop="address"
            label="明细">
    </el-table-column>
    <el-table-column
            prop="status"
            label="状态">
    </el-table-column>
    <el-table-column
            prop="miles"
            label="里程数">
    </el-table-column>
    <el-table-column
            prop="address"
            label="审核">
    </el-table-column>
    <el-table-column
            label="查看">
    </el-table-column>
  </el-table>
</div>
</template>

<script>
export default {
    name: "vehicleManage",
    props: {
        driverAdmin: {
            type: Object,
        },
    },
    data() {
        return {
            vehicleList: [],
        };
    }
};
</script>

<style scoped>

</style>