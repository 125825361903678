<template>
  <div>
    <div
      class="alarm-light"
      @click="showData"
      :class="[{ blink: maxWarningLevel > 1 }, lightClass]"
    ></div>

    <el-dialog title="行程检查" :visible.sync="show" width="70%" append-to-body>
      <el-table :data="data.routeCheckList" stripe size="small" v-if="data.routeCheckList">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="routeTime" label="路线" width="120">
          <template slot-scope="scope">
            {{ data.routeMap[scope.row.routeId].pathId | pathNameTransfer }}
          </template>
        </el-table-column>
        <el-table-column prop="routeTime" label="司机" width="80">
          <template slot-scope="scope">
            {{ data.routeMap[scope.row.routeId].driverNo }}
          </template>
        </el-table-column>
        <el-table-column prop="routeTime" label="行程时间" width="120">
          <template slot-scope="scope">
            {{ data.routeMap[scope.row.routeId].showTime }}
          </template>
        </el-table-column>
        <el-table-column prop="orderRemark" label="订单" width="120"> </el-table-column>

        <el-table-column prop="problemDesc" label="问题详情" width="300"></el-table-column>
        <el-table-column prop="maxWarningLevel" label="告警级别">
          <template slot-scope="scope">
            <span
              :class="{
                dange: scope.row.warningLevel >= 3,
                warn: scope.row.warningLevel == 2,
              }"
              >{{
                scope.row.warningLevel >= 3 ? "严重" : scope.row.warningLevel >= 2 ? "高" : "一般"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="变更记录" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="showChangeLogs(scope.row)">变更记录</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="检测时间" width="200"></el-table-column>

        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button type="text" size="small" @click="dealWarning(scope.row, 'IGNORE')"
                >忽略本次</el-button
              >
            </div>

            <div>
              <el-button type="text" size="small" @click="dealWarning(scope.row, 'DEAL')"
                >已处理</el-button
              >
            </div>

            <div>
              <el-button type="text" size="small" @click="dealWarning(scope.row, 'NO_MORE_TIP')"
                >不再提醒</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="变更记录" :visible.sync="showLogs" width="600px" append-to-body>
      <div>
        <el-timeline>
          <el-timeline-item
            v-for="item in routeChangeLogs"
            :key="item.id"
            :timestamp="item.createTime"
            >{{ item.changeInfo }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>

    <audio style="display: none" controls="controls" id="alarmDing">
      <source src="../../assets/video/alarm.wav" />
    </audio>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { handleWarning, getProblem } from "@/api";
export default {
  components: {},

  props: {},

  watch: {
    routeCheckInfo(newVal) {
      this.data = newVal;
      if (newVal && newVal.maxWarningLevel) {
        this.playAlarm();
      }
    },
  },

  created() {},

  mounted() {
    this.getInitProblem();
  },

  computed: {
    ...mapState({
      routeCheckInfo: (state) => state.routeCheckInfo,
    }),

    maxWarningLevel() {
      return (this.data && this.data.maxWarningLevel) || 1;
    },

    lightClass() {
      return "light" + this.maxWarningLevel;
    },
  },

  data() {
    return {
      show: false,
      showLogs: false,
      routeChangeLogs: [],
      data: {},
    };
  },

  methods: {
    playAlarm() {
      if ($("#alarmDing")[0]) {
        $("#alarmDing")[0].volume = 0.1;
      }
      $("#alarmDing").trigger("play");

      console.log(JSON.stringify(this.routeCheckInfo));
    },

    getInitProblem() {
      getProblem().then((res) => {
        this.data = res.data || {};
      });
    },

    showData() {
      this.show = true;
    },

    showChangeLogs(item) {
      this.showLogs = true;
      this.routeChangeLogs = this.data.routeChangeLogMap[item.routeId] || [];
    },

    dealWarning(item, status) {
      const parma = {
        id: item.id,
        status,
      };
      handleWarning(parma).then((res) => {
        this.data = res.data || {};
        this.$message.success("操作成功");
      });
    },
  },
};
</script>
<style scoped lang="less">
.alarm-light {
  width: 30px;
  height: 30px;
  background-size: contain;
  cursor: pointer;

  &.light1 {
    background-image: url("../../assets/image/green-light.png");
  }

  &.light2 {
    background-image: url("../../assets/image/orange-light.png");
  }

  &.light3 {
    background-image: url("../../assets/image/red-light.png");
  }
}

.blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
