<template>
  <div>
    <div>
      <div class="header">
        <div class="title">目标人群：{{ dateSize }}人</div>
      </div>
      <el-divider></el-divider>
      <el-table :data="list" style="width: 100%" max-height="500">
        <el-table-column prop="telephone" label="电话" width="120">
        </el-table-column>
        <el-table-column
          prop="vipLevel"
          label="VIP"
          width="100"
        ></el-table-column>

        <el-table-column
          prop="wxRegisterTime"
          width="160"
          show-overflow-tooltip
          sortable="custom"
          label="微信注册时间"
        ></el-table-column>

        <el-table-column
          prop="telRegisterTime"
          width="160"
          show-overflow-tooltip
          sortable="custom"
          label="电话注册时间"
        ></el-table-column>

        <el-table-column
          prop="appRegisterTime"
          width="160"
          show-overflow-tooltip
          sortable="custom"
          label="APP注册时间"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="操作"
          width="130"
          align="right"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.id"
              type="text"
              size="small"
              @click="go2OrderList(scope.row)"
              >订单记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';


export default {
  components: {},
  props: {
    dataList: Array,
    onlyTel: Boolean
  },
  created () {},
  mounted () {},
  watch: {
    dataList: {
      handler (val = []) {
        this.dateSize = val.length;
        const max = Math.min(1000, this.dateSize);
        const list = [];
        for (let i = 0 ;i < max; i ++) {
          if (typeof val[i] === 'string') {
            list.push({telephone: val[i]});
          } else {
            list.push(val[i]);
          }
        }
        this.list = list;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  data () {
    return {
      list: [],
      dateSize: 0,
      form: {},
      tempList: []
    };
  },
  methods: {
    go2OrderList (item) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: {tel: item.telephone}
      });
      window.open(routeUrl.href, '_blank');
    },


  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: flex-end;
  .title {
    font-weight: bold;
    line-height: 32px;
    text-align: right;
    margin-right: 20px;
  }
}

.sms-index {
  width: 30px;
  height: 30px;
  background-color: #409eff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.sms-content {
  max-width: 300px;
}
</style>