const questions = [{
  que: '不是69元/29元吗？（骗人的吗?）',
  ans: '您好！优惠乘车活动是真实有效的，在非重大节假日或非春运（节）期间，您参与城小班商务车邀请好友的活动，就可以得到大额优惠券，就可以使用最低价格乘车，但是重大节假日或春运（节）期间为保障充足的服务车辆，也为了保障乘客的出行需求，是会有临时调价的，不过即使调价，活动的优惠总额度不会变的，该享有的优惠额度还是一样的，具体的活动规则在活动页面也有写的很清楚，您可以留意一下的。'
}, {
  que: '优惠券怎么用？',
  ans: '您好，优惠券无需手动使用，下单的时候系统自动使用您账户内同路线面额最大的有效优 惠券，自动扣减车费，无需您手动使用！'
}, {
  que: '优惠券规则简介',
  ans: '您好，优惠券下单自动使用，无需手动使用；多张优惠券也不能叠加使用；优惠券和优惠口令也不能同时使用；每张优惠券都对只能在指定路线上使用，每张优惠券都有有效期，过期失效；优惠券金额不会含在开票金额内；优惠券不可提现、不可转赠、不可更换，仅限本人使用。'
}, {
  que: '不是下载（注册）就送90元的券吗？',
  ans: '您好，只有新用户首次登录注册才有90元的优惠券的，优惠券不是每次登录或下载都会送的，但是如果您想要优惠券，您可以参与邀请好友活动兑券、或者您也可以使用积分兑换券，另外每次乘车之后平台也会自动给你送券的。'
}, {
  que: '积分怎么兑换券？',
  ans: '您好，积分来自您每次的乘车记录，每3元车费兑换1积分，每积分有效期1年，有足够的积分之后，您就可以前往积分兑换页面兑换积分了（积分兑换页面按“我的”-“行程积分”进入）'
}, {

  que: '早价优惠规则',
  ans: '您好，早价优惠是指乘客提前48小时预定车票，就可以用到比正常价格优惠的早价，但是不是每条路线都有早价票的，具体的您以车票页面查询为准'
}, {
  que: '优惠口令规则',
  ans: '您好，优惠口令是不定期开放的，每次优惠口令的内容会在城小班商务车的公众号内推送，您可以进入公众号查看推文的；同时优惠口令和优惠券是不能叠加使用的哈'
}, {
  que: '怎么得优惠/或有没有什么优惠？',
  ans: '您好，城小班商务车目前有3种获取优惠券的方法\n（1）邀请好友注册城小班商务车，您将可以获得一张大额优惠券（按照不同路线分为26元和36元）。邀请好友的方法是，点击进入“首页”顶部的活动页，按提示操作即可。\n（2）积分获取优惠券，您乘坐完车之后，系统会赠送给您对应的乘车积分，乘车积分达到一定数额后可以兑换优惠券。具体操作步骤点击“我的行程”— 点击右下角“我的”—点击“行程积分”—兑换优惠券（选择您需要路线的优惠券即可）\n（3）乘车后赠送优惠券，在您预订的行程完成后系统为您自动发送返程的优惠券，如您从监利出发至武汉，系统将为您发送武汉回监利的优惠券一张，使用时会自动抵扣车费'
}, {
  que: '价格能优惠吗？',
  ans: '您好，价格都是以平台显示价格为准的，您如果想要优惠的话，可以参与我们的邀请好友活动，完成活动内容就可以领取大额优惠券哈。'
}, {
  que: '什么是邀请好友的活动？',
  ans: '您好，城小班商务车邀请好友的活动是指邀请好友注册城小班商务车（小程序邀请6位或APP邀请3位），您将可以获得一张大额优惠券（按照不同路线分为26元和36元）。邀请好友的方法是，点击进入“首页”顶部的活动页，按提示操作即可。'
}, {
  que: '为什么要邀请6人？之前不是只要2人吗？（感觉要邀请人数多了）',
  ans: '您好，因为之前的邀请规则和邀请时间比较长，邀请起来不是很方便，现在的邀请比之前简单一些，人均邀请时长会短一些，所以虽然人数增加了，但是邀请难度和耗时基本也是没有变的。'
}, {
  que: '感觉小程序邀请人数多了',
  ans: '您好，如果您觉得邀请6人比较多，您通过城小班商务车APP邀请的，只用邀请3人就够了哈 '
}, {
  que: '为什么小程序和app的邀请规则不一样？',
  ans: '您好，因为小程序的邀请链接是自带邀请码的，通过微信就能很方便的点击进入，但是APP的邀请链接没有办法自带邀请码，就只能让被邀请的用户手动输入了。'
}, {
  que: '为什么小程序的邀请人数比APP多？',
  ans: '您好，因为小程序的邀请比APP邀请更加简单、方便，耗时也更短，所以小程序的邀请人数会多一些，app的邀请人数会少一些的。'
}, {
  que: '怎么判断邀请的是不是监利人？',
  ans: '您好！邀请的所有好友必须全是家里监利或常住监利的；如果您邀请的好友不符合这个规则，系统会通过算法筛选出来的，那您的邀请就属于违规邀请了，系统的具体算法逻辑，我们客服人员也不是很清楚，但是只要您的邀请符合规则就没有关系的，具体的邀请规则您可以在活动页面查看的。'
}, {
  que: '邀请了好友半年内不乘车怎么办？',
  ans: '您好，邀请的好友在半年内没有乘车，系统会封禁您的邀请功能的（具体半年后什么时候封禁时间不定），因为所有邀请的好友如果长时间不乘车，那么这个邀请活动也就失去了意义了，希望您能理解！'
}, {
  que: '为什么我不能邀请好友/兑换优惠券了？',
  ans: '您好，因为系统侦测到您的既往邀请记录存在违规邀请的情况（既往邀请的好友中存在非监利好友），所以停用了您的邀请功能，但是不影响您正常预约用车服务的。'
}, {
  que: '6位邀请码',
  ans: '您好，6位邀请码是您的好友邀请您注册城小班商务车的凭证，您需要在首次登录城小班商务车APP之后的10分钟内，前往“我的”页面，输入邀请码，才视为好友邀请您成功的。'
}, {
  que: '到某某位置要多少钱？',
  ans: '您好，具体价格您可以直接在系统内查询的，具体方法是：首先确认起点城市和终点城市，然后点击查询商务车票，再然后进入车票页面选择出行时间，最后在具体乘车信息页面上，您输入您刚才说的地点，就会自动显示价格的。'
}, {
  que: '能不能在某某位置接或送到某某位置？',
  ans: '您好，只要上下车地点在服务范围内都可以接送的，您可以自己在系统内查询的，具体方法是：首先确认起点城市和终点城市，然后点击查询商务车票，再然后进入车票页面选择出行时间，最后在具体乘车信息页面上，您输入您上下车地点就可以查询的。'
}, {
  que: '监利到武汉（或武汉回监利）要多久？',
  ans: '您好，监利主城区至武汉主城区的时间一般在3个半小时左右（距离远近时间会有一定浮动）；但如果是远城区或非城区的地点，路程时间就要根据具体位置来变化了。武汉回监利的时间也是一样。以上情况不考虑交通拥堵或天气状况的影响'
}, {
  que: '监利到荆州（或荆州回监利）要多久？',
  ans: '您好，监利主城区至荆州主城区的时间一般在2个小时（距离远近时间会有一定浮动，沙市的时间会短一点）；但如果是远城区或非城区的地点，路程时间就要根据具体位置来变化了。荆州回监利的时间也是一样。以上情况不考虑交通拥堵或天气状况的影响'
}, {
  que: '监利到岳阳（或岳阳回监利）要多久？',
  ans: '您好，监利主城区至岳阳的时间一般在2个小时（距离远近时间会有一定浮动，岳阳东站一般2小时之内）；但如果是远城区或非城区的地点，路程时间就要根据具体位置来变化了。岳阳回监利的时间也是一样。以上情况不考虑交通拥堵或天气状况的影响。'
}, {
  que: '监利到武汉（或武汉回监利）要多少钱？',
  ans: '您好，监利主城区至武汉主城区的基础价110元每人，如果具体接送地点超过基础价接送范围，会有相应的费用加收，系统会根据您的具体上下车地点在预定页面显示具体价格的（武汉回监利也一样），如果您的账户里面有优惠券，或者能使用优惠口令的话，就可以在基础价上进行不同幅度的优惠，具体优惠金额可以在您的账户里面查看优惠券额度。'
}, {
  que: '监利到荆州（或荆州回监利）要多少钱？',
  ans: '您好，监利主城区至荆州主城区的基础价60元每人，如果具体接送地点超过基础价接送范围，会有相应的费用加收，系统会根据您的具体上下车地点在预定页面显示具体价格的（荆州回监利也一样），如果您的账户里面有优惠券，或者能使用优惠口令的话，就可以在基础价上进行不同幅度的优惠，具体优惠金额可以在您的账户里面查看优惠券额度。'
}, {
  que: '监利到岳阳（或岳阳回监利）要多少钱？',
  ans: '您好，监利主城区至岳阳主城区的基础价60元每人，如果具体接送地点超过基础价接送范围，会有相应的费用加收，系统会根据您的具体上下车地点在预定页面显示具体价格的（岳阳回监利也一样），如果您的账户里面有优惠券，或者能使用优惠口令的话，就可以在基础价上进行不同幅度的优惠，具体优惠金额可以在您的账户里面查看优惠券额度。'
}, {
  que: '用优惠口令不能享受90元的优惠了吗？',
  ans: '您好！现在武汉路线优惠价格是的80元价格，需要您邀请2位微信好友注册优选专车，您把乘车优惠信息分享给您未注册的好友，好友关注优选专车公众号，并在注册时填写您的邀请码，注册成功后您的好友立即获得10元优惠券，您获得30元的优惠券，这样您就可以得继续使用90元的优惠价格了。'
}, {
  que: '怎样使用同车口令',
  ans: '您好，您需要先预定一个订单（车票），然后在已预订的订单页面找到同车口令的按钮，进入后复制同车口令，然后让需要同车的乘客使用您的同车口令在约车首页（查询商务车票的页面）找到同样的同车口令页面，输入你的同车口令即可。'
}, {
  que: '为什么小孩要买票（坐别的车也没有买）',
  ans: '您好，因为相关的法律法规都是明令禁止车辆超载的，如果儿童不买票乘车的话，服务车辆就可能会超载，这样会影响大家的出行安全，司机师傅也是可以无责拒载的，还请您理解！'
}, {
  que: '小孩为啥要安排7座车？',
  ans: '您好，因为免票儿童乘坐5座车，就有超载20%的可能，同时换成7座车也是为了您能有更宽敞的乘车空间。'
}, {
  que: '为什么xxx要加钱？',
  ans: '您好，因为超过了基础价的接送范围之外的地点都是要加收额外费用的，主要考虑是为了服务车辆的服务成本考量，超区接送服务的成本肯定要也会增加的，而且根据出行成本的变化（主要是油价的调整）加价区域也会有相应调整的，还希望您能理解！'
}, {
  que: '为什么这么近也要加钱',
  ans: '您好，因为确实超过了基础价的接送范围，也会影响服务车辆的出行成本，而且根据出行成本的变化（主要是油价的调整）加价区域也会有相应调整的，如果您方便的话也可以到就近的不价地点上车的，不便之处还希望您能理解'
}, {
  que: '可以带小狗（猫）吗？带宠物规则',
  ans: '您好，不建议您携带宠物乘车，如果确需携带的，您要遵守以下事项哈\n1、只能携带小型宠物（体重20斤及以下），无异味、不掉毛，没有人畜共患的疾病、没有攻击性、没有经常性吠叫。否则司机有权无责任拒载。\n2、严禁宠物在车内大小便，如因此弄脏服务车辆，由所属乘客向司机赔偿。\n3、如对司乘人员造成伤害，或影响行车安全，由宠物所属乘客负责。\n4、必须由专用的宠物笼/箱装好后乘车（不可在车内活动），如司机要求置于后备箱，乘客应该配合，否则司机有权无责任拒载。\n5、宠物在行车过程中发生了疾病或其他危险状况，司机不承担责任。'
}, {
  que: '我带的xxx行李，能不能放的下？行李规则',
  ans: '您好，具体行李要求（规则）您可以在在预定车票页面查询的，您可以自己在系统内查询的，具体方法是：首先确认起点城市和终点城市，然后点击查询商务车票，再然后进入车票页面选择出行时间，最后在具体乘车信息页面上，您就可以点击行李备注查询具体行李要求了，而且您可以上传行李照片，司机可以直接看到您的行李，更方便和您确认的。'
}, {
  que: '可以开发票吗？发票介绍',
  ans: '您好，城小班商务车的乘车开票规则如下'
    + '\n1、请您知晓，预定城小班商务车，只支持开具电子发票，无法提供纸质发票。'
    + '\n2、电子发票将会发送至您指定的邮箱，请您确认电子邮箱的地址正确'
    + '\n3、开具发票时，以实际的车费金额为准，如有使用优惠，只能开具优惠后的金额。'
    + '\n4、城小班商务车开具的电子发票类目为“旅客运输服务”'
    + '\n5、单张电子发票累计开票金额不超过1000元，如超过1000元，需分开申请。'
    + '\n6、每张电子发票对应会有一个行程单，行程单上会详细注明对应行程信息（乘车日期和时间段、出发城市、到达城市、乘车人数、乘车金额），电子发票上将不备注行程信息。'
    + '\n7、超过半年的订单不支持开票'
}, {
  que: '开票流程介绍（怎样开票）',
  ans: '您好，您的行程结束之后，您就可以前往历史订单页面，查看订单，并申请电子发票了。'
}, {
  que: '招募司机吗（你还招司机吗？）',
  ans: '您好，目前还在招募司机的，您如果想加入城小班司机，您直接下载城小班司机APP并注册，然后提交人员和车辆信息进行审核，审核通过后就会有专人联系您，并办理签约事宜的'
}, {
  que: '司机需要带车吗？',
  ans: '您好，目前暂时还是需要司机自带车的。'
}, {
  que: '我要叫车/怎样叫车？',
  ans: '您好，约车直接在首页点击“查询商务车票”进入车票列表页面，选择对应时间段的车票，然后在订单详情页面确认您的具体上车地点等信息，最后确认提交您的出行信息就约车成功啦。'
}, {
  que: '邀请码在哪里？',
  ans: '您好，城小班商务车的邀请码是您邀请监利好友下载注册城小班商务车APP时的凭证，您邀请的监利好友，需要在首次登录城小班商务车后10分钟内前往账户页面绑定输入您的邀请码，如果是微信小程序邀请监利好友，就不需要使用邀请码，直接把邀请链接发给好友，好友通过您发送的邀请链接进入就可以啦'
}, {
  que: '地图上找不到位置，怎么下单',
  ans: '您好，确认具体上下车地点有3种方法，第一种是在地址框内输入您的具体地点；如果第一种方法找不到具体地点，您可以选择第二种方法，直接在订单详情页面点击地图右下角的定位图标，就可以获取您的当前位置；如果您当前的位置不是具体上下车地点，那么您可以选择第三种方法，直接在地图上进行点选，通过缩放地图大小拖拉地图显示区域找到您的上下车地点的。'
}, {
  que: '司机什么时候到？',
  ans: '您好，司机具体到达时间您可以在订单页面查看的，上面有车辆的具体定位信息，您可以很方便的查看的哈。'
}, {
  que: '司机电话是多少/能把司机号码发我吗？',
  ans: '您好，司机的电话需要您提交出行订单信息并指派车辆之后才有的，如果您的订单已经指派了服务车辆，那么您进入订单页面就可以查看到服务车辆和司机的具体信息，并可以直接在订单页面拨打和查看司机的电话哈（温馨提示您：为保障乘客的个人信息隐私，乘客与司机之间的通话电话为临时虚拟号码，是能司乘双方通话，第三方无法拨通的，订单结束后司乘双方也无法再通话的哈）'
}, {
  que: '怎么还没有司机联系我？',
  ans: '您好，提交出行的订单信息之后，一般是在您出发时间的前1-2小时为您指派服务车辆和司机，司机收到您的出行信息之后就会及时联系您的。请您放心，只要没有极端天气或交通管制等特殊情况一定为您指派服务车辆的，如果有任何疑问您都可以随时联系我们的哈'
}, {
  que: '分派了司机怎么还没有联系我？',
  ans: '您好，司机可能在驾车过程中，如果您需要及时和司机联系，您也可以进入订单页面查看到服务车辆和司机的具体信息，并可以直接在订单页面拨打和查看司机的电话哈'
}, {
  que: '能把我和xx安排在同一辆车上吗？',
  ans: '您好，您如果需要和指定乘客（出行时间和路线一致）同乘一辆车，您可以和指定乘客使用同车口令的哈，您用他/她的同车口令，或者他/她用您的同车口令都可以的，但是您需要确认同车的乘客起点和终点是顺路或一起的，而且同车最多人数也没有超过6人。'
}, {
  que: '没有票了，还能加车吗？',
  ans: '您好，目前确实没有多的票了，您可以稍晚点再查询一下的，如果有加班车会及时开放预定的哈。'
}, {
  que: '怎么投诉？',
  ans: '您好，如果您有需要的投诉的任何事项，您可以随时向我们反馈的。'
}, {
  que: '订单可以取消吗？',
  ans: '您好！订单可以取消的，但是建议您务必尽量提前取消，因为不取消司机肯定会去接您的，为了避免司机空跑，您尽早确认出行意向，感谢您的理解哈！'
}, {
  que: '取消订单',
  ans: '您好，取消订单直接在订单页面点击取消就可以的！'
}, {
  que: '能最后接我吗？',
  ans: '您好，具体的接送顺序，是根据同车每位乘客不同的上下车地点来合理安排的，具体的接送顺序，我们也无法给您承诺的，您可以和司机进行沟通或协商，如果可以的话司机师傅会答应您的，但是如果确实不行，也请您谅解！'
}, {
  que: '能最先接我吗？',
  ans: '您好，具体的接送顺序，是根据同车每位乘客不同的上下车地点来合理安排的，具体的接送顺序，我们也无法给您承诺的，您可以和司机进行沟通或协商，如果可以的话司机师傅会答应您的，但是如果确实不行，也请您谅解！'
}, {
  que: '我要个副驾驶',
  ans: '您好，具体的座位安排，一般是根据乘客上车的先后顺序自主选择的，我们也无法给您承诺的，您可以和司机进行沟通或协商，如果可以的话司机师傅会答应您的，但是如果确实不行，也请您谅解！'
}, {
  que: '电子发票为什么没有章？',
  ans: '您好，根据《国家税务总局关于增值税发票综合服务平台等事项的公告》(国家税务总局公告2020年第1号)规定，自2020年01月08日起,增值税电子普通发票不再加盖发票专用章，您申请开具的电子发票都是通过税务开票系统开具出来的正规增值税发票，其法律效力、基本用途、基本使用规定等与增值税普通纸质发票相同，如您对发票的真实性存在疑问，您可以登录全国增值税发票查验平台(https://inv-veri.chinatax.gov.cn)进行真实性核验。'
}];

const commonWords = [
  '您好，我是城小班客服，请问有什么可以帮到您？',
  '不好意思，让您久等了',
  '您好，客服电话是400-0716-123',
  '您好，帮您查询一下，稍后回复您',
  '感谢您选乘城小班商务车，祝您生活愉快！',
  '您好，优惠口令是城小班商务车的客服电话400-0716-123',
];

export {commonWords, questions};










