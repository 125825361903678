<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="IPCType：">
          <el-select
            v-model="param.ipcType"
            placeholder="请选择"
            @change="query()"
            filterable
            clearable
          >
            <el-option v-for="item in ipcTypeOpts" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态：">
          <el-select @change="query()" v-model="param.success" placeholder="请选择" clearable>
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上报时间：">
          <el-date-picker
            placeholder="选择时间"
            v-model="param.startTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
          ></el-date-picker>至
          <el-date-picker
            placeholder="选择时间"
            v-model="param.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px;margin-right: 20px" type="primary" @click="query()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" border stripe max-height="600" @sort-change="handleSort">
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
        <el-table-column prop="createTime" label="上报时间" sortable="custom"></el-table-column>

        <el-table-column prop="interfaceUrl" label="上报url"></el-table-column>

        <el-table-column prop="ipcType" label="ipcType" width="200px"></el-table-column>

        <el-table-column prop="paramsStr" width="350px" label="上报参数">
          <template slot-scope="scope">
            <span class="text-json">
              <span class="long-text">{{scope.row.paramsStr}}</span>
              <span class="text-blue pointer" @click="showJson(scope.row)">查看详情</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="interfaceResult" label="上报结果" width="200px"></el-table-column>

        <el-table-column prop="status" label="返回码" width="100px"></el-table-column>

        <el-table-column prop="success" label="成功失败" width="180px">
          <template slot-scope="scope">{{scope.row.success | enumTransfer(statusOpts)}}</template>
        </el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="report(scope.row)">重新上报</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="100"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="查看数据"
      :visible="visible"
      width="600px"
      @opened="opened"
      fullscreen
      :before-close="handleClose"
    >
      <div id="json"></div>
    </el-dialog>
  </div>
</template>

<script>
import {ParamUtil} from '@/util';
import {reportAgain} from '@/api';

export default {
  created () {
    this.query();
  },
  data () {
    return {
      param: {},
      list: [],
      total: 0,
      asc: false,
      currentPage: 1,
      statusOpts: [{
        label: '上报成功',
        value: 1,
      }, {
        label: '上报失败',
        value: 0,
      }],
      ipcTypeOpts: ['baseInfoCompany', 'baseInfoCompanyStat', 'baseInfoCompanyPay', 'baseInfoCompanyService',
        'baseInfoCompanyPermit', 'baseInfoCompanyFare', 'baseInfoVehicle', 'baseInfoVehicleInsurance',
        'baseInfoVehicleTotalMile', 'baseInfoDriver', 'baseInfoDriverEducate', 'baseInfoDriverApp',
        'baseInfoPassenger', 'baseInfoDriverStat', 'orderCreate', 'orderMatch',
        'orderCancel', 'operateLogin', 'operateLogout', 'operateDepart',
        'operateArrive', 'operatePay', 'positionDriver', 'positionVehicle',
        'shareRoute', 'shareOrder', 'sharePay', 'shareCompany',
        'ratedPassenger', 'ratedPassengerComplaint', 'ratedDriverPunish', 'ratedDriver'],
      visible: false
    };
  },
  methods: {

    query (page = 1) {
      const url = '/admin/report/log/list';
      const param = {
        page: {
          current: page,
          size: 100,
          orders: [{
            column: 'id',
            asc: this.asc,
          }]
        },
        param: ParamUtil.filterEmptyData(this.param),
        startTime: this.param.startTime,
        endTime: this.param.endTime,
      };
      this.$http.post(url, param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showJson (item) {
      this.visible = true;
      this.paramsStr = item.paramsStr;
    },
    opened () {
      $('#json').JSONView(this.paramsStr);
    },
    report (item) {
      const param = {
        id: item.id
      };
      reportAgain(param).then(() => {
        this.$message.success('上报成功');
        this.query();
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },

    handleSort (item) {
      this.asc = item.order.indexOf('asc') > -1;
      this.query();
    },

    handleClose () {
      this.visible = false;
    }

  }
};
</script>

<style scoped lang="less">
.text-json {
  display: flex;
  .long-text {
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
    flex: 4;
  }
  .pointer {
    flex: 1;
    line-height: 30px;
  }
}

.pointer {
  cursor: pointer;
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
