<template>
  <div class="incr-box">
    <div class="head">
      <div class="item">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <el-select
          class="span"
          clearable
          v-model="param.registerChan"
          placeholder="全部（行为）"
        >
          <el-option
            v-for="item in registerOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>

        <el-select
          class="span"
          :disabled="icrType == '增长'"
          clearable
          v-model="param.registerSrc"
          placeholder="全部（客户端）"
        >
          <el-option
            v-for="item in registerSrcOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>

        <el-radio-group v-model="icrType" class="span">
          <el-radio-button label="增长"></el-radio-button>
          <el-radio-button label="注册"></el-radio-button>
        </el-radio-group>

        <el-button
          type="primary"
          style="width: 120px; float: right"
          class="span"
          @click="query"
          >查询</el-button
        >
      </div>
    </div>

    <div class="table main" :class="[icrType == '增长' ? 'icr' : '']">
      <div class="right">
        <el-radio-group v-model="chartGroupType">
          <el-radio-button label="日"></el-radio-button>
          <el-radio-button label="周"></el-radio-button>
          <el-radio-button label="月"></el-radio-button>
          <el-radio-button label="年"></el-radio-button>
        </el-radio-group>
      </div>

      <div>
        <chart ref="chart" :option="chartOpt" style="margin-bottom: 10px" />
        <chart ref="chart1" :option="chartOpt1" style="margin-bottom: 10px" />
        <chart v-if="icrType == '增长'" ref="chart10" :option="chartOpt1" />
      </div>
    </div>

    <!-- 注册饼状统计 -->
    <div class="table pie" v-show="icrType == '注册'">
      <div class="item">
        <div class="sub-head">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param2.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          至
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param2.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <el-select
            class="span"
            clearable
            v-model="param2.registerChan"
            placeholder="全部（行为）"
          >
            <el-option
              v-for="item in registerOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>

          <el-select
            class="span"
            clearable
            v-model="param2.registerSrc"
            placeholder="全部（客户端）"
          >
            <el-option
              v-for="item in onlineRegisterSrcOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-button type="primary" class="span" @click="query2"
            >查询</el-button
          >
        </div>
        <div class="sub-item">
          <chart-view
            ref="chart2"
            valueField="value"
            nameField="name"
            title="线上用户注册后多久下单"
            initChartType="pie"
          />
        </div>
      </div>
    </div>

    <!-- 增长饼状统计 -->
    <div class="table pie" v-show="icrType == '增长'">
      <div class="item">
        <div class="sub-head">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param4.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          至
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param4.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <el-select class="span" v-model="param4.orderType" placeholder="全部">
            <el-option
              v-for="item in orderTypeOpt"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>

          <el-button type="primary" class="span" @click="query4"
            >查询</el-button
          >
        </div>
        <div class="sub-item">
          <chart-view
            ref="chart4"
            valueField="value"
            nameField="name"
            :title="
              param4.orderType === 'deal'
                ? '被邀请用户成交比例'
                : '被邀请用户下单比例'
            "
            initChartType="pie"
          />
        </div>
      </div>
      <div class="item">
        <div class="sub-head">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param5.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          至
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param5.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <el-select
            class="span"
            v-model="param5.registerSrc"
            clearable
            placeholder="渠道"
          >
            <el-option
              v-for="item in onlineRegisterSrcOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-button type="primary" class="span" @click="query5"
            >查询</el-button
          >
        </div>
        <div class="sub-item">
          <chart-view
            ref="chart5"
            valueField="value"
            nameField="name"
            title="第1次参与邀请的人与他们后续邀请的人的比例"
            initChartType="pie"
          />
        </div>
      </div>

      <div class="item">
        <div class="sub-head">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param6.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          至
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param6.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <el-select
            class="span"
            clearable
            v-model="param6.registerSrc"
            placeholder="渠道"
          >
            <el-option
              v-for="item in onlineRegisterSrcOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="primary" class="span" @click="query6"
            >查询</el-button
          >
        </div>
        <div class="sub-item">
          <chart-view
            ref="chart6"
            valueField="value"
            nameField="name"
            :title="`被邀请的人再次参与邀请的比例（总数： ${
              inviteAginRate.totalNum || 0
            }人）`"
            initChartType="pie"
          />
        </div>
      </div>
      <div class="item">
        <div class="sub-head">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param7.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          至
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param7.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>

          <el-select
            class="span"
            v-model="param7.registerSrc"
            placeholder="渠道"
            clearable
          >
            <el-option
              v-for="item in onlineRegisterSrcOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-button type="primary" class="span" @click="query7"
            >查询</el-button
          >
        </div>
        <div class="sub-item">
          <chart-view
            ref="chart7"
            valueField="value"
            nameField="name"
            :title="`新增用户参与邀请比例（总数： ${
              incrInvitedRate.totalNum || 0
            }人）`"
            initChartType="pie"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../ChartView';
import { ParamUtil, DateUtil} from '@/util';


const registerOptions = [{label: "自注册", value: 3}, {label: "分享注册", value: 4}, {label: "助力注册", value: 5}, {label: "广告注册", value: 0}];
export default {
  components: {
    ChartView
  },


  filters: {
    percent (val) {
      return Number(val * 100).toFixed(2) + '%';
    },
    precision (val, precision) {
      return Number(val).toFixed(precision) ;
    }
  },

  data () {
    return {
      param: {
        startDate: "",
        endDate: "",
        registerSrc: "",
        registerChan: ""
      },
      param2: {
        startDate: "",
        endDate: "",
        registerChan: "",
      },

      param4: {
        startDate: "",
        endDate: "",
        orderType: "order",
      },
      param5: {
        startDate: "",
        endDate: "",
        registerChan: "",
      },
      param6: {
        startDate: "",
        endDate: "",
        registerChan: "",
      },
      param7: {
        startDate: "",
        endDate: "",
        registerChan: "",
      },
      param8: {
        startDate: "",
        endDate: "",
        registerChan: "",
      },

      //////////////////////////////////////////////////////////////////
      ////////////////////////页面元数据//////////////////////////////////
      //////////////////////////////////////////////////////////////////

      icrType: "注册",
      chartGroupType: "日",
      registerOptions,
      registerSrcOptions: [{label: "APP注册", value: "app"}, {label: "微信注册", value: "wx"}, {label: "电话注册", value: "tel"}],
      onlineRegisterSrcOptions: [{label: "APP注册", value: "app"}, {label: "微信注册", value: "wx"}],
      orderTypeOpt: [{label: "下单", value: "order"}, {label: "成交", value: "deal"}],
      orderTimeOpt: [{label: "1个月内", value: "1"}, {label: "1-3个月", value: "1-3"}, {label: "3-6个月", value: "3-6"}, {label: "6个月以上", value: "6"}],
      periodOptions: [{label: "1天", value: "<=1"}, {label: "2-7", value: "2-7"}, {label: "8-30", value: "8-30"},
        {label: "31-60", value: "31-60"}, {label: "大于60", value: ">60"}],
      chartOpt: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      },
      chartOpt1: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      },

      //////////////////////////////////////////////////////////////////
      ///////////////////////////数据处理/////////////////////////////////
      //////////////////////////////////////////////////////////////////
      summary: [],
      propagationSummary: {},
      inviteAginRate: {},
      incrInvitedRate: {},
    };
  },
  mounted () {
    //默认一个月的时间跨度
    //注册增长
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime(1));
    this.param2.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param2.endDate = DateUtil.format(DateUtil.getDateTime());


    //裂变增长
    this.param4.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param4.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param5.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param5.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param6.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param6.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param7.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param7.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param8.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param8.endDate = DateUtil.format(DateUtil.getDateTime());
  },
  watch: {
    chartGroupType () {
      if (this.icrType == "增长") {
        this.renderPropagationChart();
        this.renderOrderInvitedChart();
      } else {
        this.renderChart();
      }
    },
    icrType (val) {
      if (val == "增长") {
        this.registerOptions = [{label: "分享注册", value: 4}, {label: "助力注册", value: 5}];
        this.$set(this.param, 'registerSrc', null);
      } else if (val == "注册") {
        this.registerOptions = registerOptions;
      }
      this.query();
    },
    "param.registerChan" (val) {
      if (val) {
        this.param.registerSrc = null;
      }
    },
    "param.registerSrc" (val) {
      if (val) {
        this.param.registerChan = null;
      }
    }
  },
  methods: {
    viewIncr () {
      this.$http.post("/admin/analysis/passenger/incr", ParamUtil.filterEmptyData(this.param)).then(res=>{
        this.summary = res.data || [];
        this.renderChart();
      });
    },
    viewPropagationIncr () {
      this.$http.post("/admin/analysis/passenger/propagationincr", ParamUtil.filterEmptyData(this.param)).then(res=>{
        this.propagationSummary = res.data || {};
        this.$nextTick(()=>{
          this.renderPropagationChart();
          this.renderOrderInvitedChart();
        });
      });
    },
    query () {
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("请选择起止日期。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截至日期。");
        return;
      }
      if (this.icrType == "增长") {
        this.viewPropagationIncr();
      } else {
        this.viewIncr();
      }
    },
    query2 () {
      this.$http.post("/admin/analysis/passenger/ordertime", ParamUtil.filterEmptyData(this.param2)).then(res=>{
        var resData = res.data || {};
        let data = [{name: "1天", value: resData.day1},
          {name: "2-7", value: resData.day2},
          {name: "8-30", value: resData.day3},
          {name: "30-60", value: resData.day4},
          {name: "大于60", value: resData.day5},
          {name: "未下单", value: resData.day6}];
        this.$refs.chart2.setData(data);
      });
    },
    query4 () {
      this.$http.post("/admin/analysis/passenger/propagationorderoverview", ParamUtil.filterEmptyData(this.param4)).then(res=>{
        var resData = res.data || {};
        const data = [{name: "1个月内", value: resData.day1},
          {name: "1-3个月", value: resData.day2},
          {name: "3-6个月", value: resData.day3},
          {name: "6个月以上", value: resData.day4},
          {name: this.param4.orderType === 'deal' ? "未成交" : "未下单", value: resData.day5},
        ];
        this.$refs.chart4.setData(data);
      });
    },
    query5 () {
      this.$http.post("/admin/analysis/passenger/propagationinvite", ParamUtil.filterEmptyData(this.param5)).then(res=>{
        let propagationInvitedRate = res.data || {};
        let data = [{name: "第一次邀请", value: propagationInvitedRate.firstInviteNum},
          {name: "后续邀请", value: propagationInvitedRate.inviteTotal || 0}];
        this.$refs.chart5.setData(data);
      });
    },
    query6 () {
      this.$http.post("/admin/analysis/passenger/propagationinviteaginrate", ParamUtil.filterEmptyData(this.param6)).then(res=>{
        const inviteAginRate = res.data || {};
        inviteAginRate.inviteNum = inviteAginRate.inviteNum || 0;
        const data = [{name: "未再邀请人数", value: inviteAginRate.totalNum - inviteAginRate.inviteNum}, {name: "再次邀请人数", value: inviteAginRate.inviteNum }];
        this.$refs.chart6.setData(data);
        this.inviteAginRate = inviteAginRate;
      });
    },
    query7 () {
      this.$http.post("/admin/analysis/passenger/propagationincrinvitedrate", ParamUtil.filterEmptyData(this.param7)).then(res=>{
        const incrInvitedRate = res.data || {};
        this.incrInvitedRate = incrInvitedRate;
        incrInvitedRate.inviteNum = incrInvitedRate.inviteNum || 0;
        const data = [{name: "未参与邀请人数", value: incrInvitedRate.totalNum - incrInvitedRate.inviteNum}, {name: "参与邀请人数", value: incrInvitedRate.inviteNum }];
        this.$refs.chart7.setData(data);

      });
    },




    ////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////main table line chart ////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////



    getNextDay (day) {
      let nextDay = new Date(new Date(day).getTime() + 3600 * 24 * 1000);
      return nextDay.getFullYear() + "-" + (nextDay.getMonth() < 9 ? "0" + (nextDay.getMonth() + 1) : (nextDay.getMonth() + 1)) + "-"
                + (nextDay.getDate() < 10 ? "0" + nextDay.getDate() : nextDay.getDate());
    },
    getOrderInviteDataset (data) {
      var axis = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];

      let dim = this.param.startDate;
      let week = 1;
      while (dim <= this.param.endDate) {
        let d = {};
        for (var j = 0; j < data.length; j ++) {
          if (data[j].regdate == dim) {
            d = data[j];
            break;
          }
        }

        if (this.chartGroupType == "日") {
          axis.push(dim);
          data1.push(d.invite_num || 0);
          data2.push(d.order_num || 0);
          data3.push(d.order_finish_num || 0);
        } else if (this.chartGroupType != "日") {
          let key;
          if (this.chartGroupType == "月") {
            key = dim.substring(0, 7);
          } else if (this.chartGroupType == "年") {
            key = dim.substring(0, 4);
          } else if (this.chartGroupType == "周") {
            if (new Date(dim).getDay() == 0) {
              key = week ++;
            } else {
              key = week;
            }
          }
          if (axis.indexOf(key) >= 0) {
            let pos = data1.length - 1;
            data1[pos] = data1[pos] + (d.invite_num || 0);
            data2[pos] = data2[pos] + (d.order_num || 0);
            data3[pos] = data3[pos] + (d.order_finish_num || 0);
          } else {
            axis.push(key);
            data1.push(d.invite_num || 0);
            data2.push(d.order_num || 0);
            data3.push(d.order_finish_num || 0);
          }
        }
        //next loop
        dim = this.getNextDay(dim);
      }

      let dimensions = ["邀请", "下单", "成交"];
      return {
        axis: axis,
        dimensions: dimensions,
        source: [data1, data2, data3],
      };
    },

    getRegDataset (data, showBySource) {
      var axis = [];
      var data0 = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];
      var data4 = [];
      var total0 = 0;
      var total1 = 0;
      var total2 = 0;
      var total3 = 0;
      var total4 = 0;

      let dim = this.param.startDate;
      let week = 1;
      while (dim <= this.param.endDate) {
        let d = {};
        for (var j = 0; j < data.length; j ++) {
          if (data[j].regdate == dim) {
            d = data[j];
            break;
          }
        }

        if (showBySource) {
          total0 += (d.totalNum || 0);
          total1 += (d.wxNum || 0);
          total2 += (d.telNum || 0);
          total3 += (d.appNum || 0);
          if (this.chartGroupType == "日") {
            axis.push(dim);
            data0.push(d.totalNum || 0);
            data1.push(d.wxNum || 0);
            data2.push(d.telNum || 0);
            data3.push(d.appNum || 0);
          } else if (this.chartGroupType != "日") {
            let key;
            if (this.chartGroupType == "月") {
              key = dim.substring(0, 7);
            } else if (this.chartGroupType == "年") {
              key = dim.substring(0, 4);
            } else if (this.chartGroupType == "周") {
              if (new Date(dim).getDay() == 0) {
                key = week ++;
              } else {
                key = week;
              }
            }
            if (axis.indexOf(key) >= 0) {
              let pos = data0.length - 1;
              data0[pos] = data0[pos] + (d.totalNum || 0);
              data1[pos] = data1[pos] + (d.wxNum || 0);
              data2[pos] = data2[pos] + (d.telNum || 0);
              data3[pos] = data3[pos] + (d.appNum || 0);
            } else {
              axis.push(key);
              data0.push(d.totalNum || 0);
              data1.push(d.wxNum || 0);
              data2.push(d.telNum || 0);
              data3.push(d.appNum || 0);
            }
          }
        } else {
          total0 += (d.totalNum || 0);
          total1 += (d.selfNum || 0);
          total2 += (d.shareNum || 0);
          total3 += (d.assisNum || 0);
          total4 += (d.advNum || 0);
          if (this.chartGroupType == "日") {
            axis.push(dim);
            data0.push(d.totalNum || 0);
            data1.push(d.selfNum || 0);
            data2.push(d.shareNum || 0);
            data3.push(d.assisNum || 0);
            data4.push(d.advNum || 0);
          } else if (this.chartGroupType != "日") {
            let key;
            if (this.chartGroupType == "月") {
              key = dim.substring(0, 7);
            } else if (this.chartGroupType == "年") {
              key = dim.substring(0, 4);
            } else if (this.chartGroupType == "周") {
              if (new Date(dim).getDay() == 0) {
                key = week ++;
              } else {
                key = week;
              }
            }
            if (axis.indexOf(key) >= 0) {
              let pos = data0.length - 1;
              data0[pos] = data0[pos] + (d.totalNum || 0);
              data1[pos] = data1[pos] + (d.selfNum || 0);
              data2[pos] = data2[pos] + (d.shareNum || 0);
              data3[pos] = data3[pos] + (d.assisNum || 0);
              data4[pos] = data4[pos] + (d.advNum || 0);
            } else {
              axis.push(key);
              data0.push(d.totalNum || 0);
              data1.push(d.selfNum || 0);
              data2.push(d.shareNum || 0);
              data3.push(d.assisNum || 0);
              data4.push(d.advNum || 0);
            }
          }
        }
        //next loop
        dim = this.getNextDay(dim);
      }

      return {
        axis: axis,
        source: showBySource ? [data0, data1, data2, data3] : [data0, data1, data2, data3, data4],
        total: showBySource ? [total0, total1, total2, total3] : [total0, total1, total2, total3, total4],
        dimensions: showBySource ? ["全部", '微信', '电话', 'APP'] : ["全部", '自注册', '分享注册', '助力注册', '广告注册']
      };
    },

    getIncDataset (data, showByChan) {
      var axis = [];
      var data0 = [];
      var data1 = [];
      var data2 = [];
      var total0 = 0;
      var total1 = 0;
      var total2 = 0;

      let dim = this.param.startDate;
      let week = 1;
      while (dim <= this.param.endDate) {
        let d = {};
        for (var j = 0; j < data.length; j ++) {
          if (data[j].regdate == dim) {
            d = data[j];
            break;
          }
        }


        const filed1 = showByChan ? 'shareNum' : 'wxNum';
        const filed2 = showByChan ? 'assisNum' : 'appNum';

        total0 += (d.totalNum || 0);
        total1 += (d[filed1] || 0);
        total2 += (d[filed2] || 0);
        if (this.chartGroupType == "日") {
          axis.push(dim);
          data0.push(d.totalNum || 0);
          data1.push(d[filed1] || 0);
          data2.push(d[filed2] || 0);
        } else if (this.chartGroupType != "日") {
          let key;
          if (this.chartGroupType == "月") {
            key = dim.substring(0, 7);
          } else if (this.chartGroupType == "年") {
            key = dim.substring(0, 4);
          } else if (this.chartGroupType == "周") {
            if (new Date(dim).getDay() == 0) {
              key = week ++;
            } else {
              key = week;
            }
          }
          if (axis.indexOf(key) >= 0) {
            let pos = data0.length - 1;
            data0[pos] = data0[pos] + (d.totalNum || 0);
            data1[pos] = data1[pos] + (d[filed1] || 0);
            data2[pos] = data2[pos] + (d[filed2] || 0);
          } else {
            axis.push(key);
            data0.push(d.totalNum || 0);
            data1.push(d[filed1] || 0);
            data2.push(d[filed2] || 0);
          }
        }

        //next loop
        dim = this.getNextDay(dim);
      }

      return {
        axis: axis,
        source: [data0, data1, data2],
        total: [total0, total1, total2],
        dimensions: showByChan ? ["全部", '分享注册', '助力注册'] : ["全部", '微信', 'APP']
      };
    },

    renderChart () {
      const showBySource = !this.param.registerSrc;
      const dataset = this.getRegDataset(this.summary, showBySource);

      let option;
      if (showBySource) {
        option = {
          ...this.chartOpt,
          title: {
            text: "注册用户趋势",
          },
          legend: {
            data: dataset.dimensions
          },
          xAxis: {type: 'category', show: true, data: dataset.axis},
          yAxis: {
            show: true
          },
          series: [{
            name: dataset.dimensions[0],
            type: "line",
            data: dataset.source[0]
          }, {
            name: dataset.dimensions[1],
            type: "line",
            data: dataset.source[1]
          }, {
            name: dataset.dimensions[2],
            type: "line",
            data: dataset.source[2]
          }, {
            name: dataset.dimensions[3],
            type: "line",
            data: dataset.source[3]
          }],

        };



      } else {
        option = {
          ...this.chartOpt,
          title: {
            text: "注册用户趋势",
          },
          legend: {
            data: dataset.dimensions
          },
          xAxis: {type: 'category', show: true, data: dataset.axis},
          yAxis: {
            show: true
          },
          series: [{
            name: dataset.dimensions[0],
            type: "line",
            data: dataset.source[0]
          }, {
            name: dataset.dimensions[1],
            type: "line",
            data: dataset.source[1]
          }, {
            name: dataset.dimensions[2],
            type: "line",
            data: dataset.source[2]
          }, {
            name: dataset.dimensions[3],
            type: "line",
            data: dataset.source[3]
          }, {
            name: dataset.dimensions[4],
            type: "line",
            data: dataset.source[4]
          }],

        };
      }
      this.$refs.chart.setOption(option, true);

      let source = [];
      // i从1开始，去除"全部"
      for (let i = 1 ; i < dataset.dimensions.length; i ++) {
        source.push({name: dataset.dimensions[i], value: dataset.total[i]});
      }
      const nameField = 'name';
      const valueField = 'value';
      const dataset2 = {
        dimensions: [nameField, valueField],
        source
      };
      const option1 = {
        ...this.chartOpt,
        title: {
          text: `各渠道新增注册用户占比（合计：${dataset.total[0]}）`,
        },
        color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        dataset: dataset2,
        xAxis: {
          show: false
        },
        yAxis: {
          show: false
        },
        legend: {
          show: true,
          type: 'scroll',
          orient: 'vertical',
          right: 20,
          top: 20,
          bottom: 20,
        },
        series: [{
          type: 'pie',
          smooth: true,
          trigger: 'item',
          radius: '70%',
          center: ['50%', '50%'],
          tooltip: {
            show: true,
            formatter: (params)=>{
              const {data, percent} = params;
              return `${data[nameField]}: ${data[valueField]} ${percent}%`;
            }
          },
        }],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },


      };
      this.$refs.chart1.setOption(option1);
    },
    renderOrderInvitedChart () {
      let data = this.propagationSummary.orderedInviteRate || [];
      let dataset = this.getOrderInviteDataset(data);
      let option = {
        title: {
          text: "下单用户与参与邀请用户比",
        },
        legend: {
          data: dataset.dimensions
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {
          show: true
        },
        series: [{
          name: dataset.dimensions[0],
          type: "line",
          data: dataset.source[0]
        }, {
          name: dataset.dimensions[1],
          type: "line",
          data: dataset.source[1]
        }, {
          name: dataset.dimensions[2],
          type: "line",
          data: dataset.source[2]
        }],
        ...this.chartOpt1
      };
      this.$refs.chart10.setOption(option, true);
    },

    renderPropagationChart () {
      const data = this.propagationSummary.propagationIncr || [];
      const registerChan = !this.param.registerChan;
      const dataset = this.getIncDataset(data, registerChan);
      const option = {
        title: {
          text: "裂变增长分析",
        },
        legend: {
          data: dataset.dimensions
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {
          show: true
        },
        series: [{
          name: dataset.dimensions[0],
          type: "line",
          data: dataset.source[0]
        }, {
          name: dataset.dimensions[1],
          type: "line",
          data: dataset.source[1]
        }, {
          name: dataset.dimensions[2],
          type: "line",
          data: dataset.source[2]
        }],
        ...this.chartOpt
      };
      this.$refs.chart.setOption(option, true);


      let source = [];
      // i从1开始，去除"全部"
      for (let i = 1 ; i < dataset.dimensions.length; i ++) {
        source.push({name: dataset.dimensions[i], value: dataset.total[i]});
      }
      const nameField = 'name';
      const valueField = 'value';
      const dataset2 = {
        dimensions: [nameField, valueField],
        source
      };
      const option1 = {
        ...this.chartOpt,
        title: {
          text: `裂变用户占比（合计：${dataset.total[0]}）`,
        },
        color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        dataset: dataset2,
        xAxis: {
          show: false
        },
        yAxis: {
          show: false
        },
        legend: {
          show: true,
          type: 'scroll',
          orient: 'vertical',
          right: 20,
          top: 20,
          bottom: 20,
        },
        series: [{
          type: 'pie',
          smooth: true,
          trigger: 'item',
          radius: '70%',
          center: ['50%', '50%'],
          tooltip: {
            show: true,
            formatter: (params)=>{
              const {data, percent} = params;
              return `${data[nameField]}: ${data[valueField]} ${percent}%`;
            }
          },
        }],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },


      };
      this.$refs.chart1.setOption(option1);
    }
  }
};
</script>

<style lang="less" scoped>
.incr-box {
  min-width: 1200px;
  padding: 10px;
  background-color: #edf1f5;

  .el-date-editor--date {
    width: 150px !important;
  }
  .el-select {
    width: 150px !important;
  }

  .head,
  .sub-head {
    margin-bottom: 10px;
    .span {
      margin-left: 8px;
    }
  }

  .table {
    position: relative;

    .right {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .total {
      padding: 16px 20px;
      background-color: white;
      display: flex;
      justify-content: space-between;

      .item {
        margin: 0 20px;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
      }
    }

    &.main {
      border: 1px solid #ddd;
      padding: 10px;
    }

    &.pie {
      display: grid;
      grid-template-columns: repeat(2, 49%);
      grid-template-rows: repeat(2, 368px);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      padding-top: 10px;

      > div.item {
        background: #fff;
        border: 1px solid #ddd;
        position: relative;
      }

      .sub-head {
        padding: 10px;
      }

      .sub-item {
        height: 300px;
      }
    }
  }

  .trans-box {
    display: flex;
    .left {
      margin-right: 60px;
      > div {
        margin: 10px 0;
      }
    }

    .right {
      .imp {
        font-weight: bold;
        color: orange;
      }
      > div {
        margin: 10px 0;
      }
    }
  }
}
</style>