<template>
  <div class="main">
    <el-row>
      <el-col :span="24"
              style="text-align: right; margin-top: 8px">
        <el-button @click="showDocxPannel = true">上传题目</el-button>
        <el-button @click="back">返回</el-button>
        <el-button type="primary"
                   @click="saveSetting">保存</el-button>
      </el-col>
    </el-row>

    <el-menu :default-active="activeIndex"
             class="el-menu-demo"
             mode="horizontal"
             @select="handleSelect">
      <el-menu-item index="1">考试引导页</el-menu-item>
      <el-menu-item index="2">编辑题目</el-menu-item>
      <el-menu-item index="3">考卷设置</el-menu-item>
    </el-menu>

    <PapersCover :cover-info="bankInfo.coverInfo"
                 v-if="activeIndex == '1'"></PapersCover>

    <BankEditor :bank-info="bankInfo"
                v-if="activeIndex == '2'"></BankEditor>

    <BankSetting :bank-setting="bankInfo.bankSetting"
                 v-if="activeIndex == '3'"></BankSetting>

    <el-dialog title="上传题目"
               :visible.sync="showDocxPannel"
               width="396px">
      <el-upload class="upload-demo"
                 drag
                 :multiple="false"
                 :auto-upload="true"
                 :before-upload="checkBankExistOrNot"
                 :action="`/admin/exam/bank/uploadfile/${bankId}`"
                 :on-success="upploadDocxFile">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import PapersCover from "./PapersCover";
import BankEditor from "./BankEditor";
import BankSetting from "./BankSetting";

export default {
  name: "BankInfo",
  components: {
    PapersCover,
    BankEditor,
    BankSetting,
  },
  data () {
    return {
      showDocxPannel: false,
      activeIndex: "1",
      bankId: "",
      bankInfo: {
        team: "",
        coverInfo: {
          title: "",
          passDesc: "",
          itmNum: "",
          itmType: "",
          desc: "",
        },
        itms: [],
        bankSetting: {
          isBankPub: true,
          isExamPub: true,
          doneTraining: true,
          finishLearn: true,
          miniWrRate: 30,
          optionRdm: true,
          itmRdm: true,
          itmNums: 10,
          minutes: 10,
          atHours: 24,
          goodScore: 90,
          offDuty: false,
          gradeInfos: [],
          train: "",
        },
      },
    };
  },
  mounted () {
    this.bankId = this.$route.query.bankId;
    if (this.bankId) {
      var url = "/admin/exam/bank/getbank/" + this.bankId;
      this.$http.get(url).then((res) => {
        this.bankInfo = res.data || {};
      });
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    upploadDocxFile (response, file, fileList) {
      this.showDocxPannel = false;
      if (response.code === 200) {
        this.bankInfo = response.data;
      } else {
        this.$message.error(response.message);
      }
    },
    handleSelect (key) {
      this.activeIndex = key;
    },
    back () {
      this.$router.push({ name: "BankList" });
    },
    checkBankExistOrNot () {
      if (!this.bankId) {
        this.$message.error("请先保存题库再上传题目。");
        return false;
      } else {
        return true;
      }
    },
    saveSetting () {
      if (!this.checkBankInfo()) {
        return;
      }
      var url = "/admin/exam/bank/save";
      const data = this.bankInfo;

      // 考试题目总数
      const itmNum = data.bankSetting.itmNums;

      if (100 % itmNum !== 0) {
        this.$message.error("题目数必须能被100整除");
        return;
      }
      const score = 100 / itmNum;

      data.itms.forEach((item) => {
        // 根据题目数设置每题分数
        item.score = score;
        // 从答案中过滤掉不存在的选项
        const allOptions = item.itms.map((t) => t.id);
        if (item.answer && item.answer.filter) {
          item.answer = item.answer.filter((t) =>
            allOptions.includes(t)
          );
        }
      });
      this.$http.post(url, this.bankInfo).then((res) => {
        this.bankInfo = res.data || {};
        this.bankId = res.data.id;
      });
    },
    checkBankInfo () {
      var pass = true;
      // check cover info.
      var coverInfo = this.bankInfo.coverInfo;
      if (!coverInfo.title) {
        this.$message.error("考试标题不能为空。");
        pass = false;
      }
      if (!coverInfo.passDesc) {
        this.$message.error("及格标准不能为空。");
        pass = false;
      }
      if (!coverInfo.itmNum) {
        this.$message.error("考试题量不能为空。");
        pass = false;
      }
      if (!coverInfo.itmType) {
        this.$message.error("考试题型不能为空。");
        pass = false;
      }
      if (!coverInfo.itmSrc) {
        this.$message.error("题目来源不能为空。");
        pass = false;
      }
      if (!coverInfo.desc) {
        this.$message.error("备注说明不能为空。");
        pass = false;
      }
      if (!pass) {
        return pass;
      }

      var itms = this.bankInfo.itms || [];
      for (var i = 0; i < itms.length; i ++) {
        var itm = itms[i];
        if (!itm.answer) {
          this.$message.error("第" + (i + 1) + "题没有设置答案。");
          pass = false;
        }
      }
      return pass;
    },
  },
};
</script>

<style scoped>
.main {
  margin: 0 24px;
}
</style>