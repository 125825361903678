<template>
  <div>
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="统计时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-select v-model="param.isAvg">
              <el-option
                v-for="item in typeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item>
            <el-select
              key="driverIds"
              style="width: 300px"
              v-model="driverIds"
              multiple
              @change="driverIdsChange"
              placeholder="请选择司机"
              clearable
              collapse-tags
            >
              <el-option
                v-for="item in driverList"
                :key="item.id"
                :label="`${item.no} - ${item.name}`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <chart ref="chart" :option="option" :height="600" />
    </div>

    <div style="margin-top: 60px">
      <div class="">{{ date }} 数据：</div>
      <chart ref="barChart" :option="option" :height="600" />
      <div class="table-box">
        <el-table :data="tableList" stripe max-height="480">
          <el-table-column label="序号" type="index" width="100"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="value" label="得分"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { DateUtil } from "@/util";
import { getServiceScoreTrend } from "@/api";
import _ from "lodash";

export default {
  components: {},

  props: {},

  watch: {},

  created() {
    this.query();
  },

  mounted() {},

  computed: {},

  beforeDestroy() {
    if (this.$refs.chart) {
      this.$refs.chart.chart.off("click");
      this.$refs.chart.chart.dispose();
    }
  },

  data() {
    return {
      typeOpts: [
        {
          value: false,
          label: "服务总分",
        },
        {
          value: true,
          label: "服务平均分",
        },
      ],
      param: {
        startDate: DateUtil.format(DateUtil.getMonthFirstDay()),
        endDate: DateUtil.getDayStr(0),
        isAvg: true,
      },
      option: {
        xAxis: { type: "category" },
        yAxis: {},
        tooltip: {
          show: true,
          trigger: "axis",
        },
      },

      driverList: [],

      tableList: [],

      date: "",
    };
  },

  methods: {
    query() {
      getServiceScoreTrend(this.param).then((res) => {
        this.data = res.data;
        const { driverList } = this.data || {};
        this.driverList = driverList;
        this.driverIds = driverList.map((item) => item.id);
        this.renderChart();
      });
    },

    // driverIdsChange(ids) {
    //   this.driverIds = ids || [];
    //   this.renderChart();
    // },

    renderChart() {
      const { driverList, dataList } = this.data || {};
      const driverMap = _.keyBy(driverList, "id");

      const dimensions = ["date"];
      const series = [];
      dimensions.push("0");
      series.push({
        type: "line",
        smooth: true,
        name: `平均分`,
        encode: {
          x: "date",
          y: "0",
        },
        label: {
          show: true,
        },
      });
      this.driverIds.forEach((id) => {
        const key = `${id}`;
        const driver = driverMap[id];
        dimensions.push(key);
        series.push({
          type: "line",
          smooth: true,
          name: `${driver.no} - ${driver.name}`,
          encode: {
            x: "date",
            y: key,
          },
          label: {
            show: false,
          },
        });
      });

      const option = {
        ...this.option,
        series,
        dataset: {
          dimensions,
          source: dataList,
        },
        legend: {
          show: true,
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 200,
          formatter: function (name) {
            return name;
          },
        },
      };
      this.chart = this.$refs.chart.setOption(option, true);

      this.$refs.chart.chart.on("click", (params) => {
        const { dataIndex } = params;
        this.renderBarChart(dataList[dataIndex]);
      });

      // 构造柱状图
      this.renderBarChart(dataList[dataList.length - 1]);
    },

    renderBarChart(data) {
      const driverMap = _.keyBy(this.driverList, "id");

      const series = [];
      for (let key in data) {
        if (key === "date") {
          this.date = data[key];
          continue;
        }
        if (key === "0") {
          series.push({
            name: `平均分`,
            value: data[key],
          });
        } else {
          const driver = driverMap[key];
          series.push({
            name: driver ? `${driver.no} - ${driver.name}` : `${key}`,
            value: data[key],
          });
        }
      }

      // 将series根据value排序
      series.sort((a, b) => b.value - a.value);

      this.tableList = series;

      const barOption = {
        xAxis: {
          type: "category",
          data: series.map((item) => item.name),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: series.map((item) => {
              return {
                value: item.value,
                itemStyle: {
                  color: item.name === "平均分" ? "#c23531" : "#67C23A",
                },
              };
            }),
            type: "bar",
          },
        ],
      };

      this.$refs.barChart.setOption(barOption, true);
    },
  },
};
</script>
<style scoped lang="less">
.content {
  margin-top: 50px;
  height: 550px;
  width: 100%;
}

.table-box {
  padding: 20px 100px;
}
</style>
