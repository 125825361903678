<template>
  <div class="main">
    <el-dialog title="上传文档" :visible.sync="showExlsPannel" width="396px">
      <el-upload class="upload-demo" drag :multiple="false" :auto-upload="true"
        :action="`/admin/training/relation/upload`" :on-success="upploadExlsFile">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>

    <el-dialog title="修改" :visible.sync="showUpdatePannel" width="800px">
      <div class="label">处罚信息:</div>
      <div class="row">
        <span>违规大类:</span><el-select v-model="currentRelation.violateClass1" placeholder="请选择" style="width: 200px;">
          <el-option v-for="item in violate1Opts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">违规小类:</span><el-select v-model="currentRelation.violateClass2" placeholder="请选择"
          style="width: 200px;">
          <el-option v-for="item in violate2OptsForSelect" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="row">
        <span>违规简介:</span><el-input v-model="currentRelation.violateDesc"></el-input>
      </div>
      <div class="row">
        <span>违规处罚:</span><el-input v-model="currentRelation.punishText"
          style="width: 100px; margin: 0px 10px;"></el-input>
        <span style="margin-left: 20px;">默认罚款:</span><el-input v-model="currentRelation.punishAmount"
          style="width: 200px; margin: 0px 10px;"></el-input>
        <span style="margin-left: 20px;">扣分:</span><el-input v-model="currentRelation.punishScore"
          style="width: 200px; margin: 0px 10px;"></el-input>
      </div>
      <div class="row">
        <span>是否累计:</span>
        <span style="margin: 0px 10px;">
          <el-radio v-model="currentRelation.accumulate" :label="true">是</el-radio>
          <el-radio v-model="currentRelation.accumulate" :label="false">否</el-radio>
        </span>
        <span style="display: inline-block; margin-left: 20px;">罚款范围:</span><el-input
          v-model="currentRelation.punishAmountRange" style="width: 200px; margin: 0px 10px;"></el-input>
      </div>

      <div class="label">文档信息:</div>
      <div class="row">
        <span>一级标题:</span>
        <el-input v-model="currentRelation.cap1Code" style="width: 100px; margin: 0px 10px;"></el-input>
        <span>二级标题:</span>
        <el-input v-model="currentRelation.cap2Code" style="width: 100px;  margin: 0px 10px;"></el-input>
      </div>

      <div class="label">考试题库</div>
      <div class="row">
        <span>考题编号:</span><el-input v-model="currentRelation.examCode" style="width: 100px; margin: 0px 10px;"></el-input>
      </div>

      <div class="label">其他设置</div>
      <div class="row">
        <span>备注:</span><el-input v-model="currentRelation.bz" type="textarea" style="margin: 0px 10px;"></el-input>
      </div>

      <div class="row">
        <el-button @click="showUpdatePannel = false;" style="margin-right: 10px;">取消</el-button>
        <el-button @click="doEditRelation()" type="primary">保存</el-button>
      </div>
    </el-dialog>

    <div style="padding: 10px;">
      <div>
        <div class="query-container" style="padding-bottom: 20px; display: flex; justify-content: space-between;">
          <div>
            <el-input v-model="param.capCode" placeholder="标题编号" style="width: 200px; margin-right: 10px;"></el-input>
            <el-input v-model="param.examCode" placeholder="题目编号" style="width: 200px; margin-right: 10px;"></el-input>
            <el-button type="primary" @click="query">查询</el-button>
          </div>

          <div>
            <el-button @click="showExlsPannel = true">上传文档</el-button>
          </div>
        </div>
        <el-table :data="relations" stripe max-height="600" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="大类编号" prop="violateClass1" width="100"></el-table-column>
          <el-table-column label="违规大类" prop="violateName1" width="100"></el-table-column>
          <el-table-column label="小类编号" prop="violateClass2" width="100"></el-table-column>
          <el-table-column label="违规小类" prop="violateName2" width="100"></el-table-column>
          <el-table-column label="违规简介" prop="violateDesc" width="100"></el-table-column>
          <el-table-column label="一级标题编号" prop="cap1Code" width="80"></el-table-column>
          <el-table-column label="一级标题" prop="cap1Title"></el-table-column>
          <el-table-column label="二级标题编号" prop="cap2Code" width="80"></el-table-column>
          <el-table-column label="二级标题" prop="cap2Title"></el-table-column>
          <el-table-column label="题目编号" prop="examCode" width="80"></el-table-column>
          <el-table-column label="处罚类型" prop="punishText" width="100"></el-table-column>
          <el-table-column label="默认罚款金额" prop="punishAmount" width="100"></el-table-column>
          <el-table-column label="是否累计" width="100">
            <template slot-scope="scope">
              {{ scope.row.accumulate ? "是" : "" }}
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="bz" width="100"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="editRelation(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="deleteRelation(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination :current-page="page.pageNo" :page-sizes="pageSizes" :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="onPageSizeChange"
            @current-change="onPageNoChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  computed: {
    ...mapState({
      size: state => state.btnSize,
      violate1Opts: state => state.enumMap['DRIVER_VIOLATE_TYPE'],
      violate2Opts: state => state.enumMap['DRIVER_VIOLATE_OPT_TYPE'],
    }),
  },

  data () {
    return {
      showExlsPannel: false,
      showUpdatePannel: false,
      currentRelation: {},
      violate2OptsForSelect: [],
      relations: [],
      pageSizes: [10, 30, 50],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      param: {
        capCode: "",
        examCode: ""
      },
    };
  },
  mounted () {
    this.initPage();
  },
  methods: {

    initPage () {
      const params = {};
      Object.assign(params, this.param, this.page);
      this.$http.post("/admin/training/relation/list", params).then(res => {
        let page = res.data || {};
        this.page.pageNo = page.current;
        this.page.pageSize = page.size;
        this.page.total = page.total;
        this.relations = page.records || [];
      });
    },


    query () {
      this.resetPage();
      this.initPage();
    },

    resetPage () {
      this.page = {
        pageNo: 1,
        pageSize: 10,
        total: 0
      };
    },
    // eslint-disable-next-line no-unused-vars
    upploadExlsFile (response, file, fileList) {
      this.showExlsPannel = false;
      if (response.code === 200) {
        this.initPage();
      } else {
        this.$message.error(response.message);
      }
    },
    onPageSizeChange (pageSize) {
      if (this.page.pageSize != pageSize) {
        this.page.pageSize = pageSize;
        this.initPage();
      }
    },
    onPageNoChange (pageNo) {
      if (this.page.pageNo != pageNo) {
        this.page.pageNo = pageNo;
        this.initPage();
      }
    },
    deleteRelation (relation) {
      this.$confirm('删除将不可恢复， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = relation.id;
        this.$http.get("/admin/training/relation/delete/" + id).then(() => {
          for (var i = 0; i < this.relations.length; i ++) {
            if (this.relations[i].id == id) {
              this.relations.splice(i, 1);
              break;
            }
          }
          this.$message.success("删除成功！");
        });
      });
    },
    changeViolateOpt () {
      if (this.currentRelation.violateClass2 && this.currentRelation.violateClass2.indexOf(this.currentRelation.violateClass1) != 0) {
        this.currentRelation.violateClass2 = "";
      }
      this.violate2OptsForSelect = this.violate2Opts.filter(opt => opt.value.indexOf(this.currentRelation.violateClass1) == 0);
    },
    editRelation (relation) {
      this.currentRelation = Object.assign({}, relation);
      this.changeViolateOpt();
      this.showUpdatePannel = true;
    },
    doEditRelation () {
      let params = Object.assign({}, this.currentRelation);
      this.$http.post("/admin/training/relation/update", params).then(() => {
        let id = this.currentRelation.id;
        for (var i = 0; i < this.relations.length; i ++) {
          if (this.relations[i].id == id) {
            this.relations.splice(i, 1, this.currentRelation);
            break;
          }
        }
        this.showUpdatePannel = false;
        this.$message.success("更新成功！");
      });
    }
  }
};
</script>

<style scoped>
.label {
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.row {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>