import { Http } from "@/util";

// 查询发版记录
export function getAppVersionList(param) {
  return Http.post("/admin/app/version/list", param);
}

// 增加发版
export function addAppVersion(param) {
  return Http.post("/admin/app/add", param);
}

// 修改发版
export function modifyAppVersion(param) {
  return Http.post("/admin/app/update", param);
}

// 删除发版
export function delAppVersion(id) {
  return Http.get("/admin/app/del", { id });
}
