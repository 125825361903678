<template>
  <div class="box">
    <div class="head">
      <div class="title">{{ title }}</div>

      <div>
        <el-select
          class="type-select"
          v-model="chartType"
          size="small"
          @change="renderChart"
          placeholder="请选择"
        >
          <el-option
            v-for="item in chartTypeOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <chart
      ref="chart"
      :option="option"
      :height="height"
      :onLoad="onChartLoaded"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    valueField: String,
    nameField: String,
    initChartType: String,
    height: {
      type: Number,
      default: 280
    },
    chartOption: Object
  },

  created () {},
  mounted () {},
  watch: {},

  data () {
    return {
      chartType: this.initChartType,
      chartTypeOpts: [{label: '饼状图', value: 'pie'}, {label: '柱状图', value: 'bar'}],
      option: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
        },
      }
    };
  },
  methods: {
    setData (data) {
      this.data = data;
      this.renderChart();
    },

    setOption (option, isMerge) {
      this.$refs.chart.setOption(option, isMerge);
    },

    onChartLoaded () {
      if (this.chartOption) {
        this.$refs.chart.setOption(this.chartOption);
      }

    },

    renderChart () {
      const nameField = this.nameField;
      const valueField = this.valueField;
      const dataset = {
        dimensions: [nameField, valueField],
        source: this.data
      };
      //   const pathFilter = this.$options.filters['pathNameTransfer'];
      //
      if (this.chartType === 'pie') {
        const option = {
          dataset,
          xAxis: {
            show: false
          },
          yAxis: {
            show: false
          },
          legend: {
            show: true,
            type: 'scroll',
            orient: 'vertical',
            right: 20,
            top: 20,
            bottom: 20,
          },

          series: [{
            type: 'pie',
            smooth: true,
            trigger: 'item',
            radius: '70%',
            center: ['40%', '50%'],
            tooltip: {
              show: true,
              formatter: (params)=>{
                const {data, percent} = params;
                return `${data[nameField]}: ${data[valueField]} ${percent}%`;
              }
            },
          }],
        };
        this.$refs.chart.setOption(option);
      } else {
        const option = {
          dataset,
          xAxis: {type: 'category', show: true},
          yAxis: {
            show: true
          },
          series: [{
            type: 'bar',
            smooth: true,
            label: {
              formatter: '{b}: {@value} '
            },
            // name : "1232131",

            tooltip: {
              show: true,
              formatter: (params)=>{
                const {data} = params;
                return `${data[nameField]}: ${data[valueField]}`;
              }
            },
          }],
          ...this.option
        };
        this.$refs.chart.setOption(option);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  position: relative;

  .head {
    background: #fff;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 0;
  }
}
</style>