import Vue from 'vue';

/**
 *  脱敏手机号
 */
Vue.filter('encryptTel', function (value) {
  if (value && value.length) {
    const item = value.substr(0, 3) + "****" + value.substr(7);
    return item;
  }
  return '';
});
