<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" :model="form" inline>
        <el-row>
          <el-col>
            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.startDate"
                style="width: 150px"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.endDate"
                style="width: 150px"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="路线" prop="pathIds">
              <el-select
                multiple
                v-model="form.pathIds"
                style="width: 220px"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in pathOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="渠道" prop="pathIds">
              <el-select
                v-model="form.orderSrc"
                multiple
                clearable
                placeholder="全部"
              >
                <el-option
                  v-for="item in orderSourceOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="状态" prop="pathIds">
              <el-select
                style="width: 120px"
                v-model="form.orderStatus"
                clearable
              >
                <el-option
                  v-for="item in orderStatusOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item style="margin-left: 50px">
              <el-button type="primary" style="width: 80px" @click="query()"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="box">
      <div class="table">
        <div class="item">
          <el-table :data="list" border size="medium">
            <el-table-column prop="name" label="类型"></el-table-column>
            <el-table-column prop="value" label="平均提前下单时间">
              <template slot-scope="scope">
                {{ scope.row.value | hourFilter }} 小时
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="item">
          <el-table :data="orderAheadList" border size="medium">
            <el-table-column prop="name" label="提前时间"></el-table-column>
            <el-table-column prop="value" label="订单数"> </el-table-column>
            <el-table-column prop="rate" label="占比">
              <template slot-scope="scope">
                {{ scope.row.rate | rateFilter }} %
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div>
        <chart-view
          style="margin-top: 10px"
          ref="chart1"
          valueField="total"
          nameField="hour"
          title="客户在什么时间段下单"
        />
      </div>

      <div>
        <chart-view
          style="margin-top: 10px"
          ref="chart2"
          valueField="total"
          nameField="hour"
          title="客户订单时间段分布"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import { ParamUtil} from '@/util';
import {getTimeAnalysis} from '@/api';
import ChartView from '../../ChartView';

export default {
  components: {
    ChartView
  },

  filters: {
    hourFilter (val) {
      return Number(val / 60).toFixed(1);
    },
    rateFilter (val) {
      return Number(val * 100).toFixed(2);
    }
  },
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderSourceOpts: state => state.enumMap['ORDER_SOURCE'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      },
    })
  },
  data () {
    return {
      orderStatusOpts: [{label: "下单", value: ''}, {label: "成交", value: 'FINISH'}],
      form: {
        orderStatus: ''
      },
      list: [],
      orderAheadList: []
    };
  },
  methods: {

    query () {
      getTimeAnalysis(ParamUtil.filterEmptyData(this.form)).then((res)=>{

        const {all, app, common, tel, wx, vip, bookTimeList, orderTimeList, h1, h2, h3, h4, h5, h6, h7, h8, h9, total} = res.data || {};

        const list = [];
        list.push({name: '全部', value: all});
        list.push({name: '微信', value: wx});
        list.push({name: 'APP', value: app});
        list.push({name: '电话', value: tel});
        list.push({name: 'VIP客户', value: vip});
        list.push({name: '普通客户', value: common});
        this.list = list;

        const orderAheadList = [];
        orderAheadList.push({name: '1小时内', value: h1, rate: h1 / total});
        orderAheadList.push({name: '1-2小时', value: h2, rate: h2 / total});
        orderAheadList.push({name: '2-6小时', value: h3, rate: h3 / total});
        orderAheadList.push({name: '6-12小时', value: h4, rate: h4 / total});
        orderAheadList.push({name: '12-24小时', value: h5, rate: h5 / total});
        orderAheadList.push({name: '24-36小时', value: h6, rate: h6 / total});
        orderAheadList.push({name: '36-48小时', value: h7, rate: h7 / total});
        orderAheadList.push({name: '48-60小时', value: h8, rate: h8 / total});
        orderAheadList.push({name: '60小时以上', value: h9, rate: h9 / total});
        this.orderAheadList = orderAheadList;

        this.$refs.chart1.setData(bookTimeList);
        this.$refs.chart2.setData(orderTimeList);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  background-color: #edf1f5;
  padding: 20px;

  .table {
    padding: 20px;

    display: flex;

    .item {
      flex: 1;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}
</style>