<template>
  <div class="box">
    <div id="cmsv6flash"></div>

    <div class="btns-box">
      {{ online ? "设备在线" : "设备离线" }}

      <div class="file-list">
        <div v-for="item in files" :key="item.DownUrl">
          {{ item }}
          <el-button @click="download(item)">下载</el-button>
        </div>
      </div>

      <div id="videoMap"></div>

      <div class="btns" v-if="online">
        <div>
          <el-button @click="play(0, 0)">车外</el-button>
          <el-button @click="recording(0)">录像</el-button>
          <el-button :loading="capturePicLoading[0]" @click="capturePic(0)">抓拍</el-button>
        </div>

        <div>
          <el-button @click="play(2, 1)">车内</el-button>
          <el-button @click="recording(2)">录像</el-button>
          <el-button :loading="capturePicLoading[2]" @click="capturePic(2)">抓拍</el-button>
        </div>
        <div>
          <el-button @click="play(1, 2)">副驾</el-button>
          <el-button @click="recording(1)">录像</el-button>
          <el-button :loading="capturePicLoading[1]" @click="capturePic(1)">抓拍</el-button>
        </div>

        <div>
          <el-button @click="play(3, 3)">主驾</el-button>
          <el-button @click="recording(3)">录像</el-button>
          <el-button :loading="capturePicLoading[3]" @click="capturePic(3)">抓拍</el-button>
        </div>
      </div>

      <!-- <div>
                <el-button @click="login">对讲</el-button>
            </div>

            <div>
                <el-button @click="stopTalk">停止对讲</el-button>
            </div> -->
    </div>

    <!-- <video id="videoElement" crossOrigin="anonymous" style="width: 50%;" controls="controls"></video> -->
    <!-- <video controls preload="none" width="352" height="288" data-setup="{}"><source
        src="http://47.100.10.103:6604/hls/1_013812345678_1_1.m3u8?JSESSIONID=98a5d2c9aab242e09f2c7eb7ac5eb9e0"
        type="application/x-mpegURL"></video> -->
  </div>
</template>

<script>
import {
  videoMonitorLogout,
  openLiveWindow,
  videoDownload,
  getAlarmRecords,
  videoMonitorLogin,
  videoServerIp,
  getRealTimeLocation,
  isDeviceOnline,
  getHisVideo,
  recordVideo,
  capturePicture,
  getCapturePicture,
} from "@/api";
import { DateUtil } from "@/util";
export default {
  components: {},

  props: {},

  watch: {},

  created() {
    if (this.$route.query.deviceNo) {
      this.deviceNo = this.$route.query.deviceNo;
    }
    this.login();
  },

  mounted() {
    window.onTtxVideoMsg = this.onTtxVideoMsg;
    this.initMap();
    this.listenMove();
    this.locationMonitor();

    // this.aaa();
    // this.aaa('http://218.75.172.98:8606/live/10038860142_2.flv?params={"subid":131}&subid=131');
  },

  beforeDestroy() {
    this.close();
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.locationTimer) {
      clearInterval(this.locationTimer);
    }
  },

  computed: {},

  data() {
    return {
      deviceNo: "", // '041330058290', //
      token: null,
      carStatus: null,
      online: false,
      files: [],
      capturePicLoading: [false, false, false, false],
      idleTime: 0,
    };
  },

  methods: {
    listenMove() {
      let idleTime = this.idleTime; // 记录鼠标静止时间
      const idleThreshold = 3 * 60 * 1000; // 3分钟的时间间隔，以毫秒为单位

      // 增加静止时间计时器
      const idleInterval = setInterval(() => {
        idleTime += 1000;
        if (idleTime >= idleThreshold) {
          clearInterval(idleInterval); // 如果静止时间达到阈值，清除计时器
          this.close();
        }
      }, 1000);

      this.timer = idleInterval;

      // 监听鼠标移动事件
      document.addEventListener("mousemove", this.updateIdleTime);
      document.addEventListener("keypress", this.updateIdleTime);
    },

    locationMonitor() {
      this.locationTimer = setInterval(() => {
        this.getLocation();
      }, 5000);
    },

    // 更新鼠标静止时间
    updateIdleTime() {
      this.idleTime = 0;
    },

    initMap() {
      const map = new AMap.Map("videoMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.instance = map;
      AMap.plugin(["AMap.Scale", "AMap.Driving", "AMap.MoveAnimation", "AMap.Geocoder"], () => {
        var toolbar = new AMap.Scale();
        this.geocoder = new AMap.Geocoder();
        map.addControl(toolbar);
        this.driving = new AMap.Driving({
          policy: AMap.DrivingPolicy.LEAST_TIME,
          autoFitView: true,
          hideMarkers: true,
          map,
        });
      });
    },

    // 伊爱视频播放
    // aaa (url) {
    //     if (flvjs.isSupported()) {
    //         var videoElement = document.getElementById('videoElement');
    //         var flvPlayer = flvjs.createPlayer({
    //             type : 'flv',
    //             url
    //         });
    //         flvPlayer.attachMediaElement(videoElement);
    //         flvPlayer.load();
    //         flvPlayer.play();
    //         flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) =>{

    //             // errType: 错误类型

    //             // NETWORK_ERROR：网络错误
    //             // MEDIA_ERROR：媒体错误
    //             // OTHER_ERROR： 其他

    //             // errDetail: 错误详情，提供流的一些详细信息

    //             // NETWORK_STATUS_CODE_INVALID：HTTP 状态码错误
    //             // MEDIA_MSE_ERROR: 与 MediaSource 的错误有关，如解码问题
    //             // MEDIA_FORMAT_ERROR：媒体格式不支持
    //             // MEDIA_FORMAT_UNSUPPORTED：媒体格式不支持, 不是 flv 格式
    //             // NETWORK_TIMEOUT：连接超时

    //             console.log(errType, errDetail);
    //         });

    //         this.flvPlayer = flvPlayer;
    //     }
    // },

    destroyFlvPlayer() {
      if (!this.flvPlayer) return;
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
      this.flvPlayer = null;
    },

    login() {
      videoMonitorLogin()
        .then((res) => {
          this.token = res.jsession;
          this.onlineCheck();
        })
        .catch((err) => {
          console.error(err);
        });
      // getDeviceByVehicle,  queryUserVehicle, queryAlarmDetail, getGpsInfo,queryDevicePlayerUrl, stopDevicePlayerUrl

      // getGpsInfo().then(res => {
      //     console.log(res.data[0]);
      //     if (res.data[0].videostat) {
      //         queryDevicePlayerUrl().then(res => {
      //             console.log(res);
      //             if (res.data[2]) {
      //                 this.aaa(res.data[2].flvurl);
      //             }
      //         });
      //     }
      // });

      // 查询伊爱报警
      // queryAlarmDetail('2023-04-15 00:00:00', '2023-04-18 00:00:00').then(res => {
      //     console.log('获取报警数据：');
      //     console.table(res.data);
      // });

      // queryDevicePlayerHisUrlDown('2023-04-17 19:00:00', '2023-04-17 19:20:00').then(res => {
      //     console.log(res);

      //     if (res.data[0]) {
      //         debugger;
      //         this.aaa(res.data[0].flvurl);
      //     }

      // });
    },

    logout() {
      videoMonitorLogout().then(() => {
        this.token = null;
      });
    },

    onlineCheck() {
      isDeviceOnline(this.token, this.deviceNo).then((res) => {
        this.online = res.onlines[0].online;
        if (this.online) {
          this.init();
          this.getLocation();
        }
      });

      // this.init();
      // this.getLocation();
      this.getAlarmList();
    },

    view() {
      openLiveWindow("013812345678");
    },

    getAlarmList() {
      getAlarmRecords(this.token, this.deviceNo, "2023-08-29 00:00:00", "2023-08-29 17:00:00").then(
        (res) => {
          console.log(res);
        }
      );
    },

    getLocation() {
      getRealTimeLocation(this.token, this.deviceNo).then((res) => {
        if (res.status.length) {
          this.carStatus = res.status[0];
          this.renderCarPosition();
        }
      });
    },

    renderCarPosition() {
      const map = this.instance;
      const { mlat, mlng, gt, ps } = this.carStatus;
      const carMarker = new AMap.Marker({
        draggable: true,
        raiseOnDrag: false,
        position: new AMap.LngLat(mlng, mlat),
        label: {
          direction: "top",
          content: `<div class='info }'><div>${ps}</div><div>${gt}</div></div>`,
        },
        icon: new AMap.Icon({
          size: new AMap.Size(20, 40), //图标大小
          image: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
          imageSize: new AMap.Size(20, 40),
        }),
        offset: new AMap.Pixel(-10, -20),
      });

      if (this.carMarker) {
        map.remove(this.carMarker);
      }
      this.carMarker = carMarker;
      map.add(carMarker);
      map.setFitView([carMarker]);
    },

    init() {
      var options = {
        domId: "cmsv6flash",
        isVodMode: false,
        width: 1200,
        height: 800,
      };
      var swfobject = new Cmsv6Player(options);
      this.swfobject = swfobject;
      // 再配置当前的窗口数目
      swfobject.setWindowNum(4);
      swfobject.setServerInfo(videoServerIp, "6605");
      this.player = swfobject.getObjectById("cmsv6flash");
    },

    onTtxVideoMsg(index, type) {
      if (type == "showDownLoadDialog") {
        alert("down pcm tool");
      } else if (type == "isTalking") {
        alert("is talking");
      }
    },

    play(chIndex, winIndex) {
      this.player.setVideoInfo(winIndex, `CH${chIndex + 1}`);
      this.player.startVideo(winIndex, this.token, this.deviceNo, chIndex, 1, true);
    },

    stopPlay() {
      this.player.stopVideo(0);
    },

    talk() {
      this.player.startTalkback(this.token, this.deviceNo, 0);
    },

    stopTalk() {
      this.player.stopTalkback();
    },

    close() {
      const swfobject = this.swfobject;
      if (swfobject) {
        swfobject.getObjectById("cmsv6flash").stopVideo(0);
        swfobject.getObjectById("cmsv6flash").stopVideo(1);
        swfobject.getObjectById("cmsv6flash").stopVideo(2);
        swfobject.getObjectById("cmsv6flash").stopVideo(3);
        swfobject.getObjectById("cmsv6flash").stopListen();
      }
    },

    getVideo() {
      getHisVideo(this.token, this.deviceNo, 1, "2023-03-21 00:00:00", "2023-03-21 23:00:00").then(
        (res) => {
          if (res.result === 0) {
            this.files = res.files;
          } else {
            alert(res.message);
          }
        }
      );
    },

    recording(chn) {
      this.$set(this.capturePicLoading, chn, true);
      // 当前时间
      const now = DateUtil.getDateTimeStr();
      const label = `${this.deviceNo}-CH${chn + 1}-${now}录像`;
      recordVideo(this.token, this.deviceNo, chn, label).then((res) => {
        this.$set(this.capturePicLoading, chn, false);
        if (res.result !== 0) {
          alert(res.message);
        } else {
          this.$message.success("录像成功");
        }
      });
    },

    capturePic(chn) {
      this.$set(this.capturePicLoading, chn, true);
      capturePicture(this.token, this.deviceNo, chn).then((data) => {
        this.$set(this.capturePicLoading, chn, false);
        const { FLENGTH, FOFFSET, FPATH } = data;
        const url = getCapturePicture(this.token, FLENGTH, FOFFSET, FPATH, "tstss.png");
        window.open(url, "_blank");
      });
    },

    download(item) {
      // 下载前3分钟的录像
      // const startTime = moment(item.startTime).subtract(3, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      videoDownload(
        this.token,
        item.devIdno,
        item.file,
        item.chn,
        item.len,
        "2023-03-21 00:00:00",
        "2023-03-21 00:02:20"
      );
    },
  },
};
</script>
<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  display: flex;

  .btns-box {
    flex: 1;
    margin-left: 10px;

    > div {
      margin-bottom: 20px;
    }

    .file-list {
      max-height: 300px;
      overflow: auto;
    }

    .btns {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        margin-bottom: 10px;

        button {
          margin-right: 10px;
          width: 100px;
        }
      }
    }
  }

  #videoMap {
    width: 100%;
    height: 400px;
  }
}
</style>
