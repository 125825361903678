<template>
  <div class="tel-cell">
    <div v-if="order.isAppointOrder"
         class="column">
      <div v-click-tel="order.appointTelephone"
           class="flex">
        <span>{{ order.appointTelephone | encryptTel }}</span>
        <span class="hw">
          <tel-record :userTel="order.appointTelephone"
                      :prinum="order.appointPrinum"
                      :status="order.appointPrinumBindStatus"
                      :bindId="order.appointPrinumId"
                      :orderId="order.id"
                      :type="2"
                      :userType="2"
                      :isContacted="order.isContacted" />
        </span>
      </div>
      <div v-click-tel="order.telephone"
           class="flex">
        <span>{{ order.telephone | encryptTel }}</span>
        <span class="hw">
          <tel-record :orderId="order.id"
                      :type="1"
                      :userTel="order.telephone"
                      :prinum="order.prinum"
                      :status="order.prinumBindStatus"
                      :bindId="order.prinumId"
                      :isContacted="order.isContacted" />
        </span>
      </div>
    </div>
    <div v-else
         class="flex">
      <span v-click-tel="order.telephone">{{
        order.telephone | encryptTel
      }}</span>
      <span class="hw">
        <tel-record :orderId="order.id"
                    :type="1"
                    :userTel="order.telephone"
                    :prinum="order.prinum"
                    :status="order.prinumBindStatus"
                    :bindId="order.prinumId"
                    :isContacted="order.isContacted" />
      </span>
    </div>
  </div>
</template>

<script>
import TelRecord from "./TelRecord";
export default {
  components: {
    TelRecord,
  },
  props: {
    order: Object,
    showModel: String,
  },
};
</script>

<style scoped  lang="less">
.tel-cell {
  .column {
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex;
  }

  div {
    white-space: nowrap;
  }
  .hw {
    margin-left: 5px;
  }
}
</style>
