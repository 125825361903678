<template>
  <div class="root-box">
    <el-table :data="list" border stripe max-height="800">
      <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
      <el-table-column prop="keyField" label="参数名"></el-table-column>

      <el-table-column prop="description" label="参数描述"></el-table-column>

      <el-table-column label="开关">
        <template slot-scope="scope">
          <el-switch v-if="scope.row.type === 1" :value="scope.row.valueField" @change="(e) => change(e, scope.row)"
            active-text="开启" active-value="1" inactive-text="关闭"></el-switch>

          <div v-if="scope.row.type === 2">
            <el-input-number size="small" disabled :value="scope.row.valueField"></el-input-number>

            <span style="margin-left: 20px">
              <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
            </span>
          </div>

          <div v-if="scope.row.type === 3">
            <el-input size="small" style="width: 180px" disabled :value="scope.row.valueField"></el-input>

            <span style="margin-left: 20px">
              <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="op-box">
      <div class="title">系统常用操作：</div>
      <el-button type="primary" size="small" @click="clearPsgCacheData"
        >清除乘客端初始化数据缓存</el-button
      >
    </div> -->

    <el-dialog :visible.sync="visible" width="600px" max-height="600" title="修改配置信息" :close-on-click-modal="false">
      <el-form ref="from" :model="form" :size="size">
        <el-form-item :label="form.description" prop="valueField">
          <el-input-number v-if="form.type === 2" v-model="form.valueField" style="width: 200px"></el-input-number>

          <el-input v-if="form.type === 3" v-model="form.valueField" style="width: 200px"></el-input>
        </el-form-item>


      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="save">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { getSystemSetting, clearPassengerInitDataCache } from '@/api';

export default {
  computed: {
    ...mapState({
      size: state => state.btnSize,
    })
  },
  data () {
    return {
      visible: false,
      list: [],
      form: {}
    };
  },
  created () {
    this.query();
  },
  methods: {
    query () {
      getSystemSetting().then((res) => {
        this.list = res.data || [];
      });
    },


    clearPsgCacheData () {
      clearPassengerInitDataCache().then(() => {
        this.$message.success("操作成功");
      });
    },

    change (value, item) {
      const msg = value ? '开启' : '关闭';
      this.$confirm(`确认${msg}吗?`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const url = `/admin/setting/system/modify`;
        const param = _.cloneDeep(item);
        param.valueField = value ? '1' : '0';
        this.$http.post(url, param).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    save () {
      const url = `/admin/setting/system/modify`;
      this.$http.post(url, this.form).then(() => {
        this.$message.success("操作成功");
        this.query();
        this.visible = false;
      });
    },
    openEdit (row) {
      this.form = _.cloneDeep(row);
      this.visible = true;
    }
  }
};
</script>

<style scoped lang="less">
.core {
  margin: 20px 0;
  width: 120px;
}

.op-box {
  margin: 20px 0;

  .title {
    margin: 10px 0;
    font-size: 14px;
  }
}
</style>
