<template>
    <div class="root-box">

        <el-row class="condition">
            <el-form size="small" :inline="true">

                <el-form-item label="车牌号：">
                    <el-input v-model="param.carNo"/>
                </el-form-item>

                <el-form-item label="电话：">
                    <el-input v-model="param.telephone"/>
                </el-form-item>

                <el-form-item label="考核开始时间：">
                    <el-date-picker
                            placeholder="选择时间"
                            value-format="yyyy-MM-dd"
                            v-model="param.startTime">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="考核结束时间：">
                    <el-date-picker
                            placeholder="选择时间"
                            value-format="yyyy-MM-dd"
                            v-model="param.endTime">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button style="width: 100px;margin-right: 20px" type="primary" @click="query()">查询</el-button>
                </el-form-item>
            </el-form>
        </el-row>

        <div class="table">
            <el-table
                    :data="list"
                    stripe
                    max-height="600"
            >
                <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

                <el-table-column
                        prop="name"
                        label="司机姓名"
                >
                </el-table-column>

                <el-table-column
                        prop="carNo"
                        label="车牌号"
                >
                </el-table-column>

                <el-table-column
                        prop="telephone"
                        label="司机电话"
                >
                </el-table-column>

                <el-table-column
                        prop="sumAvg"
                        label="评分"
                >
                </el-table-column>

                <el-table-column
                        prop="complaintCount"
                        label="投诉"
                >
                </el-table-column>

                <el-table-column
                        prop="workTimeCount"
                        label="接客时长"
                >
                </el-table-column>
                <el-table-column
                        prop="workMileCount"
                        label="接客里程"
                >
                </el-table-column>
                <el-table-column
                        prop="workMileCount"
                        label="空驶里程"
                >
                </el-table-column>

                <el-table-column
                        prop="orderCount"
                        label="接单量"
                >
                </el-table-column>
                <el-table-column
                        prop="successCount"
                        label="成单量"
                >
                </el-table-column>

                <el-table-column
                        prop="cancelCount"
                        label="取消量"
                >
                </el-table-column>



            </el-table>
        </div>

        <div class="page">
            <el-pagination
                    @current-change="handleCurrentChange"
                    background
                    :current-page="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>

export default {
  created () {
    this.query();
  },
  data () {
    return {
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      userTypeOpts: [{
        label: '管理员',
        value: 1
      }, {
        label: '乘客',
        value: 2
      }, {
        label: '司机',
        value: 3
      }]

    };
  },
  methods: {
    query (page = 1) {
      const url = '/admin/driver/driverReportList';
      const param = {
        content: page,
        pageSize: 10,
        ...this.param
      };
      this.$http.post(url, param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange (page) {
      this.query(page);
    },
  }
};
</script>

<style scoped lang="less">


    .head-box {
        position: relative;
        height: 50px;
        line-height: 50px;
        background-color: rgba(0, 0, 0, .1);
    }

    .condition {
        background-color: rgba(0, 0, 0, .1);
        padding: 20px 20px 0;
        margin-bottom: 10px;
    }


    .table {
        margin: 20px 0;
    }

    .page {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

</style>
