<template>
  <div class="active-box">
    <div class="head">
      <div class="item">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <el-select
          class="span"
          clearable
          v-model="param.registerSrc"
          placeholder="全部（客户端）"
        >
          <el-option
            v-for="item in registerSrcOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span class="tip" @click="showTip('成交人次：1单多人，只算1人。')"
          >?</span
        >
        <el-button class="span" type="primary" @click="query">查询</el-button>
      </div>
    </div>

    <div class="table main">
      <div class="item right">
        <el-radio-group v-model="chartGroupType">
          <el-radio-button label="日"></el-radio-button>
          <el-radio-button label="周"></el-radio-button>
          <el-radio-button label="月"></el-radio-button>
          <el-radio-button label="年"></el-radio-button>
        </el-radio-group>
      </div>
      <chart style="top: 60px" ref="chart1" :option="chartOpt" />
      <chart style="margin-top: 80px" ref="chart2" :option="chartOpt" />
    </div>

    <!-- pie active -->
    <div class="head active">
      <div class="item">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param2.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param2.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <el-select
          class="span"
          v-model="param2.activeType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in activeTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span
          class="tip"
          @click="
            showTip('一个uv指在1天之内统计，如果一个用户2天都来，统计为2个uv。')
          "
          >?</span
        >
        <el-button class="span" type="primary" @click="queryAct"
          >查询</el-button
        >
      </div>
    </div>
    <div class="table">
      <div class="item">
        <chart-view
          ref="chart3"
          valueField="value"
          nameField="name"
          title="不同客户端活跃比"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../ChartView';
import { DateUtil} from '@/util';

export default {
  components: {
    ChartView
  },
  data () {
    return {
      param: {
        startDate: "",
        endDate: "",
        registerSrc: ""
      },
      param2: {
        startDate: "",
        endDate: "",
        activeType: "uv"
      },
      registerSrcOptions: [{label: "APP注册", value: "app"}, {label: "小程序注册", value: "miniapp"}],
      activeTypeOptions: [{label: "按uv", value: "uv"}, {label: "按下单", value: "order"}, {label: "按成交", value: "deal"}],
      chartGroupType: "日",
      chartOpt: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      },
      summary: {},
      activeRate: []
    };
  },
  created () {
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param2.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param2.endDate = DateUtil.format(DateUtil.getDateTime());
  },
  watch: {
    chartGroupType () {
      this.renderChart();
    }
  },
  methods: {
    showTip (msg) {
      this.$message(msg);
    },
    query () {
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }

      this.$http.post("/admin/analysis/passenger/active", this.param).then(res=>{
        this.summary = res.data || {};
        this.renderChart();
      });
    },
    queryAct () {
      if (!this.param2.startDate || !this.param2.endDate) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param2.startDate > this.param2.endDate) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }

      this.$http.post("/admin/analysis/passenger/activerate", this.param2).then(res=>{
        this.activeRate = res.data || {};
        this.rederTotalChart();
      });
    },
    getNextDay (day) {
      let nextDay = new Date(new Date(day).getTime() + 3600 * 24 * 1000);
      return nextDay.getFullYear() + "-" + (nextDay.getMonth() < 9 ? "0" + (nextDay.getMonth() + 1) : (nextDay.getMonth() + 1)) + "-"
                + (nextDay.getDate() < 10 ? "0" + nextDay.getDate() : nextDay.getDate());
    },
    renderChart () {
      var axis = [];
      var data_uv = [];
      var data_uv_login = [];
      var data_orders = [];
      var data_finishOrders = [];

      var uv = this.summary.uv || [];
      var orders = this.summary.orders || [];
      var finishOrders = this.summary.finishOrders || [];
      var totalNum = this.summary.totalNum;
      var identityUv = this.summary.identityUv || [];
      let dim = this.param.startDate;
      let week = 1;
      while (dim <= this.param.endDate) {
        var d1 = {};
        var d2 = {};
        var d3 = {};
        var d4 = {};
        for (var i = 0; i < uv.length; i ++) {
          if (uv[i].sdate == dim) {
            d1 = uv[i];
          }
        }
        for (var j = 0; j < orders.length; j ++) {
          if (orders[j].sdate == dim) {
            d2 = orders[j];
          }
        }
        for (var k = 0; k < finishOrders.length; k ++) {
          if (finishOrders[k].sdate == dim) {
            d3 = finishOrders[k];
          }
        }
        for (var l = 0; l < identityUv.length; l ++) {
          if (identityUv[l].sdate == dim) {
            d4 = identityUv[l];
          }
        }



        if (this.chartGroupType == "日") {
          axis.push(dim);
          data_uv_login.push(d1.num || 0);
          data_orders.push(d2.num || 0);
          data_finishOrders.push(d3.num || 0);
          data_uv.push(d4.num || 0);
        } else if (this.chartGroupType != "日") {
          let key;
          if (this.chartGroupType == "月") {
            key = dim.substring(0, 7);
          } else if (this.chartGroupType == "年") {
            key = dim.substring(0, 4);
          } else if (this.chartGroupType == "周") {
            if (new Date(dim).getDay() == 0) {
              key = week ++;
            } else {
              key = week;
            }
          }

          if (axis.indexOf(key) >= 0) {
            let pos = data_uv_login.length - 1;
            data_uv_login[pos] = data_uv_login[pos] + (d1.num || 0);
            data_orders[pos] = data_orders[pos] + (d2.num || 0);
            data_finishOrders[pos] = data_finishOrders[pos] + (d3.num || 0);
            data_uv[pos] = data_uv[pos] + (d4.num || 0);
          } else {
            axis.push(key);
            data_uv_login.push(d1.num || 0);
            data_orders.push(d2.num || 0);
            data_finishOrders.push(d3.num || 0);
            data_uv.push(d4.num || 0);
          }
        }
        //next loop
        dim = this.getNextDay(dim);
      }
      const option = {
        legend: {
          data: ["UV", "登录UV", "下单人次", "成交人次"]
        },
        xAxis: {type: 'category', show: true, data: axis},
        yAxis: {
          show: true
        },
        series: [{
          name: "UV",
          type: "line",
          data: data_uv
        }, {
          name: "登录UV",
          type: "line",
          data: data_uv_login
        }, {
          name: "下单人次",
          type: "line",
          data: data_orders
        }, {
          name: "成交人次",
          type: "line",
          data: data_finishOrders
        }],
        ...this.chartOpt
      };

      var rate_uv_login = [];
      var rate_orders = [];
      var rate_finishOrders = [];
      var rate_uv = [];
      for (var i1 = 0; i1 < data_uv_login.length; i1 ++) {
        rate_uv_login.push(data_uv_login[i1] / totalNum);
      }
      for (var j1 = 0; j1 < data_orders.length; j1 ++) {
        rate_orders.push(data_orders[j1] / totalNum);
      }
      for (var k1 = 0; k1 < data_finishOrders.length; k1 ++) {
        rate_finishOrders.push(data_finishOrders[k1] / totalNum);
      }
      for (var l1 = 0; l1 < data_finishOrders.length; l1 ++) {
        rate_uv.push(data_uv[l1] / totalNum);
      }
      const optionRate = {
        legend: {
          data: ["UV", "登录UV", "下单人次", "成交人次"]
        },
        xAxis: {type: 'category', show: true, data: axis},
        yAxis: {
          show: true
        },
        series: [{
          name: "UV",
          type: "line",
          data: rate_uv
        }, {
          name: "登录UV",
          type: "line",
          data: rate_uv_login
        }, {
          name: "下单人次",
          type: "line",
          data: rate_orders
        }, {
          name: "成交人次",
          type: "line",
          data: rate_finishOrders
        }],
        ...this.chartOpt
      };
      this.$refs.chart1.setOption(option);
      this.$refs.chart2.setOption(optionRate);
    },
    rederTotalChart () {
      var data = [];
      var activeRate = this.activeRate || [];
      for (var i = 0; i < activeRate.length; i ++) {
        if (activeRate[i].orderSource == 4) {
          data.push({name: "APP", value: activeRate[i].num || 0});
        } else if (activeRate[i].orderSource == 2) {
          data.push({name: "电话", value: activeRate[i].num || 0});
        } else {
          data.push({name: "小程序", value: activeRate[i].num || 0});
        }
      }
      this.$refs.chart3.setData(data);
    }
  }
};
</script>

<style scoped lang="less">
.active-box {
  min-width: 1200px;
  padding: 10px;
  background-color: #edf1f5;

  .el-date-editor--date {
    width: 150px !important;
  }
  .el-select {
    width: 150px !important;
  }
  .tip {
    cursor: pointer;
    color: #dcdfe6;
  }
  .head {
    margin-bottom: 10px;
    .span {
      margin-left: 16px;
    }
    &.active {
      margin-top: 24px;
    }
  }

  .table {
    border: 1px solid #ddd;
    padding: 10px;

    &.main {
      height: 600px;
    }

    .right {
      position: absolute;
      right: 20px;
    }
  }
}
</style>