<template>
  <div class="remain-container">
    <div class="head">
      <div class="item">
        <label>开始时间：</label>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
          @change="start1Change"
        ></el-date-picker>

        <label class="label">结束时间：</label>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <label class="label">订单路线：</label>
        <el-select
          v-model="param.route"
          multiple
          clearable
          placeholder="请选项"
        >
          <el-option
            v-for="item in pathOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <label class="label">订单渠道：</label>
        <el-select
          v-model="param.orderSrc"
          multiple
          clearable
          placeholder="全部"
        >
          <el-option
            v-for="item in orderSourceOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <label class="label">订单类型：</label>
        <el-select
          style="width: 120px"
          v-model="param.orderStatus"
          clearable
          placeholder="全部"
        >
          <el-option
            v-for="item in orderStatusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="item">
        <label>开始时间：</label>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate2"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <label class="label">结束时间：</label>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate2"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <label class="label">订单路线：</label>
        <el-select
          v-model="param.route2"
          multiple
          clearable
          placeholder="请选项"
        >
          <el-option
            v-for="item in pathOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <label class="label">订单渠道：</label>
        <el-select
          v-model="param.orderSrc2"
          multiple
          clearable
          placeholder="全部"
        >
          <el-option
            v-for="item in orderSourceOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <label class="label">订单类型：</label>
        <el-select
          v-model="param.orderStatus2"
          style="width: 120px"
          clearable
          placeholder="全部"
        >
          <el-option
            v-for="item in orderStatusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-button
          type="warning"
          plain
          style="margin-left: 20px; width: 100px"
          @click="clear"
          >清除</el-button
        >

        <el-button
          type="primary"
          plain
          style="margin-left: 20px; width: 100px"
          @click="query"
          :loading="showLoading"
          >查询</el-button
        >
      </div>
    </div>

    <div class="table2" v-if="remainInfo.passengerTotal">
      {{ remainInfo.remainTotal }}（留存人数） /
      {{ remainInfo.passengerTotal }} （下单人数） =
      {{ (remainInfo.remainTotal / remainInfo.passengerTotal) | persent }} （
      留存率）

      <div></div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import { ParamUtil } from '@/util';
import moment from 'moment';

export default {

  filters: {
    persent (val) {
      return Number(val * 100).toFixed(2) + '%';
    }
  },

  props: {
    interval: {
      type: Number,
      default: 6
    }
  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      orderSourceOpts: state => state.enumMap['ORDER_SOURCE'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      }
    }),
  },

  data () {
    return {
      param: {},
      showLoading: false,
      registerChanOpts: [{label: "自注册", value: 1}, {label: "分享注册", value: 2}, {label: "助力注册", value: 3}, {label: "广告注册", value: 4}],
      orderStatusOpts: [{label: "下单", value: ''}, {label: "成交", value: 'FINISH'}],
      remainInfo: {},
    };
  },

  methods: {

    clear () {
      this.param = {};
    },


    query () {
      this.param.remainType = "留存";
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }
      if (!this.param.startDate2 || !this.param.endDate2) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param.startDate2 > this.param.endDate2) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }

      this.param._hideLoading = true;
      this.showLoading = true;
      this.remainInfo = {};
      this.$http.post("/admin/analysis/passenger/remain", ParamUtil.filterEmptyData(this.param)).then(res => {
        this.remainInfo = res.data || {};
        this.showLoading = false;
      }).catch(()=>{
        this.showLoading = false;
      });
    },

    start1Change (val) {
      const date = moment(val);
      const endDate = date.add(this.interval, 'M').subtract(1, 'd');
      this.$set(this.param, 'endDate', endDate.format('YYYY-MM-DD'));
      const startDate1 = endDate.add(1, 'd');
      this.$set(this.param, 'startDate2', startDate1.format('YYYY-MM-DD'));
      const endDate1 = startDate1.add(this.interval, 'M').subtract(1, 'd');
      this.$set(this.param, 'endDate2', endDate1.format('YYYY-MM-DD'));
    }
  }
};
</script>

<style scoped lang="less">
.remain-container {
  .el-date-editor--date {
    width: 150px !important;
  }

  .head {
    margin-top: 20px;

    .item {
      margin-bottom: 10px;
    }
    .label {
      margin-left: 5px;
    }
    .el-input {
      width: 80px;
    }
    .query-btn {
      margin-left: 704px;
    }
  }
}
</style>