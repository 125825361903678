<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-form class="prop-container">
    <el-form-item label="题型:" :required="true">
      <br />
      <el-select v-model="paperItm.itmtype">
        <el-option key="itmtype-radio" label="单选" value="radio"></el-option>
        <el-option
          key="itmtype-checkbox"
          label="多选"
          value="checkbox"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="编号:">
      <br />
      <span style="width: 100px; display: inline-block"
      ><el-input v-model="paperItm.seq"></el-input
      ></span>
    </el-form-item>
    <el-form-item label="题干:" :required="true">
      <br />
      <el-row id="editor"></el-row>
      <el-row style="margin-top: 8px" v-if="refresh">
        <file-upload
                ref="uploader"
                :onUploadSuccess="
                (src) => {
                  uploadFile(null, src);
                }"
                filePath="/driver_train"
                v-model="paperItm.media">
        </file-upload>
      </el-row>
    </el-form-item>
    <el-form-item label="选项:" :required="true">
      <br />
      <OptionsEditor
        @opt-change="changeUptime"
        :paper-itm="paperItm"
      ></OptionsEditor>
    </el-form-item>
    <el-form-item label="分值:">
      <br />
      <el-row>
        <el-col :span="8">
          <span>全对得分</span>
          <span style="width: 100px; display: inline-block"
            ><el-input v-model="paperItm.score"></el-input
          ></span>
        </el-col>
        <el-col :span="8">
          <span>错误得分</span>
          <span style="width: 100px; display: inline-block"
            ><el-input v-model="paperItm.wrscore"></el-input
          ></span>
        </el-col>
        <el-col :span="8">
          <span>半对得分</span>
          <span style="width: 100px; display: inline-block"
            ><el-input v-model="paperItm.hfscore"></el-input
          ></span>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-row>
        <el-checkbox v-model="paperItm.mustChoose">此题随机必考</el-checkbox>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import OptionsEditor from "./OptionsEditor";
import WangEditor from "wangeditor";
import {DateUtil} from '@/util';
export default {
  name: "ItemEditor",
  components: {
    OptionsEditor,
  },
  props: {
    paperItm: {
      type: Object
    }
  },
  data () {
    return {
      refresh: true,
      editor: null,
      uploadUrl: "/admin/upload/file"
    };
  },
  mounted () {
    this.initEditor();
  },
  watch: {
    paperItm: {
      handler () {
        this.refresh = false;
        setTimeout(()=> {
          this.refresh = true;
        }, 100);
      }
    },
    "paperItm.title": {
      handler () {
        this.editor && this.editor.txt.html(this.paperItm.title);
      }
    }
  },
  methods: {
    changeUptime () {
      // eslint-disable-next-line vue/no-mutating-props
      this.paperItm.uptime = DateUtil.getDateTimeStr();
    },
    initEditor () {
      this.editor = new WangEditor('#editor');
      this.editor.config.onchange = (html) => {
        if (this.paperItm.title != html) {
          // eslint-disable-next-line vue/no-mutating-props
          this.paperItm.title = html;
          this.changeUptime();
        }
      };
      this.editor.create();
      this.editor.txt.html(this.paperItm.title);
    },
    uploadFile (field, src) {
      // eslint-disable-next-line vue/no-mutating-props
      this.paperItm.media = src;
    }
  }
};
</script>

<style lang="less" scoped>
.prop-container {
  padding: 0 24px;

  #editor .w-e-text-container {
    height: 160px !important;
  }
  .el-select {
    width: 100%;
  }
  .el-upload {
    width: 100%;
  }
}
.el-select-dropdown {
  z-index: 10002 !important;
}
</style>