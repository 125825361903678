<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="show"
    :width="simpleMode ? '1000px' : '1500px'"
    top="70px"
    :title="title"
    :show-close="true"
    :before-close="close"
    :close-on-click-modal="false"
    :hide-required-asterisk="true"
    :destroy-on-close="false"
  >
    <div style="display: flex">
      <div style="width: 900px">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          size="medium"
          v-if="form"
          :rules="rules"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="订单类型" prop="orderType">
                <el-select v-model="form.orderType" placeholder="请选择订单类型" :disabled="true">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in orderTypeOpts"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="订单渠道">
                <el-select
                  v-model="form.orderSource"
                  placeholder="请选择渠道"
                  :disabled="simpleMode"
                  style="width: 100%"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in orderSourceOpts"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="路线" prop="pathId">
                <el-select v-model="form.pathId" :disabled="simpleMode" placeholder="请选择路线">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in pathOpts"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="日期" prop="date">
                <el-date-picker
                  :disabled="simpleMode"
                  style="width: 190px"
                  v-model="form.date"
                  type="date"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="时间" prop="ticketId">
                <el-select v-model="form.ticketId" :disabled="simpleMode" placeholder="请选择时间">
                  <el-option
                    :label="item.showTime"
                    :value="item.id"
                    v-for="item in tableData"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="成人人数" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  v-model="form.adultNumber"
                  :min="1"
                  :max="10"
                  :disabled="simpleMode"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="儿童人数" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  v-model="form.childNumber"
                  :min="0"
                  :max="10"
                  :disabled="simpleMode"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="数量" v-else-if="form.orderType === OrderTypeEnum.THING">
                <el-input-number
                  v-model="form.number"
                  :min="0"
                  :max="0"
                  style="width: 100%"
                  disabled
                ></el-input-number>
              </el-form-item>

              <el-form-item label="人数" v-else-if="form.orderType === OrderTypeEnum.CHARTER">
                <el-select v-model="form.number" :disabled="simpleMode">
                  <el-option label="5座" :value="4"></el-option>
                  <el-option label="7座" :value="6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="form.orderType === OrderTypeEnum.NORMAL">
              <el-form-item label="免票儿童">
                <el-input-number
                  v-model="form.freeChildNumber"
                  :min="0"
                  :max="10"
                  :disabled="simpleMode"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="其他加价">
                <el-input-number
                  v-model="form.otherUpPrice"
                  :min="0"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="起点加价">
                <el-input-number
                  v-model="form.startAreaUpPrice"
                  :min="0"
                  :step="10"
                  disabled
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="终点加价">
                <el-input-number
                  v-model="form.endAreaUpPrice"
                  :min="0"
                  :step="10"
                  disabled
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="折扣">
                <el-input-number
                  v-model="form.otherDiscount"
                  :min="0"
                  :step="10"
                  :disabled="simpleMode"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="成人价格" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  disabled
                  v-model="form.basePrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="基础价格" v-else>
                <el-input-number
                  disabled
                  v-model="form.basePrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="儿童价格" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  disabled
                  v-model="form.childPrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总价">
                <el-input-number
                  disabled
                  :value="totalPrice"
                  :min="0"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否代下单">
                <el-select :disabled="simpleMode" v-model="form.isAppointOrder" style="width: 100%">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="16">
              <el-row :gutter="20">
                <el-col :span="form.isAppointOrder ? 12 : 24">
                  <el-form-item
                    :label="form.isAppointOrder ? '下单人电话' : '乘车人电话'"
                    prop="telephone"
                  >
                    <el-input :disabled="simpleMode" v-model="form.telephone" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="form.isAppointOrder">
                  <el-form-item label="乘车人电话" prop="appointTelephone">
                    <el-input :disabled="simpleMode" v-model="form.appointTelephone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="起点" prop="start">
                <poi-selector
                  v-model="form.start"
                  :scope="startArea.name"
                  :areaId="startArea.id"
                  :adcode="startArea.adcode"
                  :suggest="startSuggest"
                  :pathId="form.pathId"
                  :ticketId="form.ticketId"
                  :isStart="true"
                  @onUpPriceGet="(val) => onUpPriceGet(val, true)"
                  style="width: 100%"
                  :disabled="simpleMode"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="终点" prop="end">
                <poi-selector
                  v-model="form.end"
                  :scope="endArea.name"
                  :areaId="endArea.id"
                  :suggest="endSuggest"
                  :adcode="endArea.adcode"
                  :pathId="form.pathId"
                  :ticketId="form.ticketId"
                  :isStart="false"
                  @onUpPriceGet="(val) => onUpPriceGet(val, false)"
                  style="width: 100%"
                  :disabled="simpleMode"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="乘客留言">
                <el-input
                  :disabled="disableMsg || onlineOrder"
                  type="textarea"
                  :rows="1"
                  v-model="form.psgMessage"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="tags">
            <el-tag
              size="small"
              v-for="item in pasMsgs"
              :key="item.remark"
              @click="addMsg(item, 1)"
              >{{ item.remark }}</el-tag
            >
          </div>
          <div class="tags">
            <div v-if="tipDescription" class="red">{{ tipDescription }}</div>
          </div>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="司机备注">
                <el-input
                  :disabled="disableMsg"
                  type="textarea"
                  :rows="1"
                  v-model="form.remark"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tags">
            <el-tag
              size="small"
              v-for="item in remarkOpts"
              :key="item.remark"
              @click="addMsg(item, 3)"
              >{{ item.remark }}</el-tag
            >
          </div>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="客服留言">
                <el-input
                  :disabled="disableMsg"
                  type="textarea"
                  :rows="1"
                  v-model="form.adminMessage"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tags">
            <el-tag :key="item" size="small" v-for="item in adminMsgs" @click="addMsg(item, 2)">{{
              item
            }}</el-tag>
          </div>
        </el-form>
      </div>
      <div style="flex: 1; margin-left: 20px" v-if="!simpleMode">
        <el-table
          :data="tableData"
          border
          size="medium"
          highlight-current-row
          max-height="640"
          style="width: 100%"
        >
          <el-table-column prop="showTime" width="120" label="排班时间"></el-table-column>
          <el-table-column prop="usedNum" label="已订"></el-table-column>
          <el-table-column prop="num" label="余票">
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <span slot="footer" class="footer">
      <el-button size="small" @click="close">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { DateUtil } from "@/util";
import { BaseDataService } from "@/service";
import PoiSelector from "@/components/PoiSelector";
import { mapState } from "vuex";
import {
  getPathAreas,
  getTicketList,
  getUserAddress,
  getOrderBill,
  modifyOrder,
  modifyUnimportantOrderInfo,
  listSpecArea,
} from "@/api";
import _ from "lodash";
import { ORDER_CHANGE } from "@/core/const/global-event";

const OrderTypeEnum = {
  NORMAL: 1,
  THING: 2,
  CHARTER: 3,
};

const orderSource = BaseDataService.orderSource();

const defaultForm = {
  isAppointOrder: false,
  date: DateUtil.getDayStr(),
  gender: 1,
  number: 1,
  orderType: 1,
  orderSource: 2,
  start: null,
  end: null,
  adminMessage: "",
  remark: "",
  psgMessage: "",
  pathId: null,
};

const telReg = /^1\d{10}$/;

export default {
  name: "TelOrderModifyModal",
  components: {
    PoiSelector,
  },
  filters: {},
  props: {
    title: {
      type: String,
      required: false,
    },
    callback: {
      type: Function,
      required: false,
    },
    simpleMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableMsg: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoFresh: {
      type: Boolean,
      required: false,
      default: true,
    },
    onUpdated: Function,
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      orderSource: (state) => state.enumMap["ORDER_SOURCE"],
      orderTypeOpts: (state) => state.enumMap["ORDER_TYPE"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
      pasMsgs: (state) => state.pasMsgs,
    }),
    totalPrice() {
      const {
        basePrice = 0,
        number = 1,
        childNumber = 0,
        adultNumber = 1,
        orderType = OrderTypeEnum.NORMAL,
        startAreaUpPrice = 0,
        endAreaUpPrice = 0,
        otherDiscount = 0,
        childPrice = 0,
        otherUpPrice = 0,
      } = this.form;
      if (orderType === OrderTypeEnum.NORMAL) {
        return (
          basePrice * adultNumber +
          childPrice * childNumber +
          startAreaUpPrice +
          endAreaUpPrice -
          otherDiscount +
          otherUpPrice
        );
      }
      if (orderType === OrderTypeEnum.THING) {
        return basePrice + startAreaUpPrice + endAreaUpPrice - otherDiscount + otherUpPrice;
      }
      return basePrice * number + startAreaUpPrice + endAreaUpPrice - otherDiscount + otherUpPrice;
    },
    pathId() {
      // 做中间层监听pathId变化
      return this.form.pathId;
    },
    tel() {
      // 做中间层监听telephone变化
      return this.form.telephone;
    },
    ticketId() {
      // 做中间层监听time变化
      return this.form.ticketId;
    },
    date() {
      // 做中间层监听date变化
      return this.form.date;
    },
    orderType() {
      // 做中间层监听orderType变化
      return this.form.orderType;
    },
    onlineOrder() {
      return (
        this.form.orderSource === 1 || this.form.orderSource === 3 || this.form.orderSource === 4
      );
    },
  },
  watch: {},
  data() {
    const validatePoi = (rule, value, callback) => {
      if (!value || !value.jd || !value.wd) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      OrderTypeEnum,
      show: false,
      form: _.clone(defaultForm),
      remarkOpts: [
        { remark: "起点位置不准" },
        { remark: "终点位置不准" },
        { remark: "起点终点位置都不准" },
        { remark: "买入订单" },
      ],
      rules: {
        pathId: [
          {
            required: true,
            message: "请选择路线",
            trigger: "change",
          },
        ],
        ticketId: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入人数",
            trigger: "change",
          },
        ],
        start: [
          {
            required: true,
            message: "请输入起点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
        end: [
          {
            required: true,
            message: "请输入终点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入乘车人电话",
            trigger: "change",
          },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        appointTelephone: [
          {
            required: true,
            message: "请输入下单人电话",
            trigger: "change",
          },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
      },
      orderSourceOpts: [
        {
          label: "电话补录",
          value: orderSource.DZ.value,
        },
        {
          label: "购买客源",
          value: orderSource.GM.value,
        },
        {
          label: "交换客源",
          value: orderSource.JH.value,
        },
      ],
      tableData: [],
      adminMsgs: ["买入", "红包未发", "红包已发", "要越野车", "不要七座车", "不赶时间", "赶时间 "],
      startSuggest: [],
      endSuggest: [],
      startArea: {},
      endArea: {},
      startAreaSettings: [],
      tipDescription: "",
      endAreaSettings: [],
    };
  },
  methods: {
    resetForm() {
      this.form = _.cloneDeep(defaultForm);
      this.tableData = [];
      this.startArea = {};
      this.endArea = {};
      this.startSuggest = [];
      this.endSuggest = [];
      this.tipDescription = "";
      this.$refs.form.resetFields();
    },
    querySpecArea(pathId) {
      if (pathId) {
        listSpecArea(pathId).then((res) => {
          const { startList = [], endList = [] } = res.data || {};
          this.startAreaSettings = startList;
          this.endAreaSettings = endList;
        });
      }
    },
    changePrice() {
      if (this.tableData.length && this.ticketId && this.orderType) {
        const ticket = this.tableData.find((item) => item.id === this.ticketId);
        let basePrice = ticket.price;

        if (this.orderType === OrderTypeEnum.THING) {
          basePrice = ticket.goodsPrice;
        } else if (this.orderType === OrderTypeEnum.CHARTER) {
          basePrice = ticket.price - ticket.charterDiscount;
        } else if (this.orderType === OrderTypeEnum.NORMAL) {
          this.$set(this.form, "childPrice", ticket.childrenPrice);
        }
        this.$set(this.form, "basePrice", basePrice);
        this.$set(this.form, "childPrice", ticket.childrenPrice);
      }
    },

    onUpPriceGet(val, isStart) {
      if (isStart) {
        this.$set(this.form, "startAreaUpPrice", val);
      } else {
        this.$set(this.form, "endAreaUpPrice", val);
      }
    },
    getPassengerAddress() {
      const { telephone, pathId } = this.form;
      if (telReg.test(telephone) && pathId) {
        getUserAddress({ tel: telephone, pathId }).then((res) => {
          const { start = [], end = [] } = res.data || {};
          this.startSuggest = this.format(start);
          this.endSuggest = this.format(end);
        });
      }
    },
    queryPathArea() {
      const { pathId } = this.form;
      if (pathId) {
        return getPathAreas({ pathId }).then((res) => {
          this.startArea = res.data[0];
          this.endArea = res.data[1];
        });
      }
    },

    format(data = []) {
      return data.map((item) => {
        return {
          ...item,
          address: `🍉${item.address}`,
        };
      });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = this.form;
          const {
            start,
            end,
            ticketId,
            orderType,
            adultNumber = 0,
            childNumber = 0,
            number = 0,
          } = formData;

          const ticket = this.tableData.find((item) => item.id === ticketId);

          formData.departure = start.name;
          formData.depLongitude = start.jd;
          formData.depLatitude = start.wd;
          formData.destination = end.name;
          formData.destLongitude = end.jd;
          formData.destLatitude = end.wd;
          formData.date = ticket.date;
          formData.time = ticket.time;
          formData.totalPrice = this.totalPrice;

          // 计算总人数
          if (orderType === OrderTypeEnum.NORMAL) {
            formData.number = adultNumber + childNumber;
          } else if (orderType === OrderTypeEnum.CHARTER) {
            formData.adultNumber = number;
          } else {
            formData.number = number;
          }

          if (this.callback) {
            this.callback(formData);
          } else {
            if (this.simpleMode) {
              modifyUnimportantOrderInfo(formData).then(() => {
                this.$message.success("订单修改成功");

                if (this.autoFresh) {
                  this.$bus.$emit(ORDER_CHANGE);
                }

                this.close();

                if (this.onUpdated) {
                  this.onUpdated();
                }
              });
            } else {
              modifyOrder(formData).then(() => {
                this.$message.success("订单修改成功");
                if (this.autoFresh) {
                  this.$bus.$emit(ORDER_CHANGE);
                }

                this.close();

                if (this.onUpdated) {
                  this.onUpdated();
                }
              });
            }
          }
        }
      });
    },
    addMsg(msg, type) {
      if (type === 1) {
        this.form.psgMessage += ` ${msg.remark}`;
        this.tipDescription = msg.description;
      } else if (type === 2) {
        this.form.adminMessage += ` ${msg}`;
      } else if (type === 3) {
        this.form.remark += ` ${msg.remark}`;
      }
    },
    close() {
      this.unbindWatch();
      this.resetForm();
      this.show = false;
    },
    open(item) {
      if (item) {
        const cloneItem = _.cloneDeep(item);
        const defVal = _.cloneDeep(defaultForm);
        this.form = { ...defVal, ...cloneItem };

        const start = {
          jd: cloneItem.depLongitude,
          wd: cloneItem.depLatitude,
          name: cloneItem.departure,
        };

        const end = {
          jd: cloneItem.destLongitude,
          wd: cloneItem.destLatitude,
          name: cloneItem.destination,
        };

        // 设置地点
        this.queryPathArea().then(() => {
          this.$set(this.form, "start", start);
          this.$set(this.form, "end", end);
        });

        // 查询常用地址
        this.getPassengerAddress();
        // 查询车票信息
        this.queryTickets();
        // 查询账单详情
        this.queryBill(cloneItem.id);
        // 查询可接送区域
        this.querySpecArea(cloneItem.pathId);

        // 绑定watch事件
        this.bindWatch();
      }
      this.show = true;
    },
    queryBill(billId) {
      getOrderBill({ billId }).then((res) => {
        const {
          startAreaUpPrice,
          endAreaUpPrice,
          otherDiscount,
          otherUpPrice = 0,
          childNumber = 0,
          number = 0,
          adultNumber = 0,
        } = res.data || {};

        const unitFen = 100;
        this.$set(this.form, "startAreaUpPrice", startAreaUpPrice / unitFen);
        this.$set(this.form, "endAreaUpPrice", endAreaUpPrice / unitFen);
        this.$set(this.form, "otherDiscount", otherDiscount / unitFen);
        this.$set(this.form, "otherUpPrice", otherUpPrice / unitFen);
        this.$set(this.form, "adultNumber", adultNumber);
        this.$set(this.form, "childNumber", childNumber);
        this.$set(this.form, "number", number);
      });
    },
    queryTickets() {
      const { date, pathId } = this.form || {};
      if (date && pathId) {
        const param = {
          date,
          pathId,
        };
        getTicketList(param).then((res) => {
          const { list = [] } = res.data || {};
          this.tableData = list;
          this.changePrice();
        });
      }
    },
    bindWatch() {
      const un1 = this.$watch("pathId", this.pathIdWatch);
      const un2 = this.$watch("date", this.dateWatch);
      const un3 = this.$watch("tel", this.telWatch);
      const un4 = this.$watch("ticketId", this.timeWatch);
      const un5 = this.$watch("orderType", this.orderTypeWatch);
      this.unwatch = [un1, un2, un3, un4, un5];
    },
    unbindWatch() {
      if (this.unwatch && this.unwatch.length) {
        this.unwatch.forEach((unFun) => unFun());
      }
    },
    pathIdWatch(val) {
      this.queryPathArea();
      this.getPassengerAddress();
      this.queryTickets();
      this.querySpecArea(val);
      this.$set(this.form, "ticketId", null);
    },
    dateWatch() {
      this.queryTickets();
      this.$set(this.form, "ticketId", null);
    },
    telWatch() {
      this.getPassengerAddress();
    },
    timeWatch() {
      this.changePrice();
    },
    orderTypeWatch(val) {
      if (val === OrderTypeEnum.CHARTER) {
        this.$set(this.form, "number", 4);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 4);
      } else if (val === OrderTypeEnum.NORMAL) {
        this.$set(this.form, "number", 1);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 1);
      } else if (val === OrderTypeEnum.THING) {
        this.$set(this.form, "number", 1);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 0);
      }
      this.changePrice();
    },
  },
};
</script>

<style lang="less">
.tel-order-dialog {
  margin-top: 25px !important;
}

.tags {
  margin-left: 100px;
  position: relative;
  top: -18px;
  .el-tag {
    cursor: pointer;
    margin-right: 4px;
  }
}
</style>
\
