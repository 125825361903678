const questions = [{
  que: '加盟车辆需求',
  flow: '客服受理意向司机的加盟需求后，记录意向司机的手机号码，然后将意向司机的号码和大体情况（车辆情况与个人情况）发送给队长，交由队长进行沟通。同时如果车辆和司机的要求达不到标准客服可以直接拒绝意向的请求（车辆标准：裸车价13万人民币以上；轴距达到2650mm；车辆初次注册日期距今不超过3年；司机驾龄2年以上；无酒驾醉驾记录）',
  ans: '如果您需要加盟，您留下您的手机号码车队长会与联系，由车队长先与您沟通，了解您和您车辆的详细情况后，并对您和车辆的详情进行审核，审核通过后，可以内部注册通过成为平台司机'
}, {
  que: '投诉司机',
  flow: ' 投诉司机的种类较多，情况也分为很多种；如果是紧急客情，按照紧急客情的规定流程处理；如果是一般投诉类客情，按照对应的投诉种类进行处理；一般由客服听完乘客的投诉内容，然后对投诉内容进行核实，如果可以立即核实的，马上给予乘客回复，不能立即核实的待核实后向乘客回复核实情况并回复处理意见，核实后的处理意见按照一般客情对应的投诉种类进行处理并回复乘客。',
  ans: '您好，使用优惠口令可以享受优惠价，优惠口令具体每条路线可能不一样，目前监利出发至武汉荆州岳阳的口令都是我们的免费服务电话4000716123，您遇到问题也可以及时拨打这个电话与我们联系，其余路线的优惠口令不定期开放，您可以阅读最近的公众号推文查询。'
}, {
  que: '司机找不到乘客',
  flow: '司机或乘客如果反馈找不对方，一般分为2种情况，一种情况是司机或乘客任意一方的手机欠费停机或者处于无信号区域，那么请司乘双方保持耐心等候一会，客服可以引导乘客按照订单约定的位置耐心等候车辆到达。或者客服也可以引导乘客前往客户端查看车辆的最新位置，达到安抚乘客情绪的目的；一种情况是司乘双方根据订单位置找不到对方（这种情况发生的概率极小），此时可以协助司机和乘客约定一个最近的显著性位置（例如小区大门口、公交车站等）以双方再协定的位置进行接送。',
  ans: '您好，可能是司机（乘客）的手机处于无信号区域（或者是暂时欠费停机了），请您耐心等候一会，司机（乘客）会及时与您联系的。目前司机的位置'
}, {
  que: '乘客出行人数增加',
  flow: '如果乘客选用的是拼车服务，但是临时人数增加，影响到司机的出车计划或影响其他乘客的出行，上述情况一般由司机处理，但是如果由乘客或者司机反馈到客服这里来，那么增加后的人数加上其他已经预定的乘客数量，如果超出了车辆的核载人数，客服（司机）需要拒绝该乘客临时增加人数的需求，该乘客可以选择按照原定人数继续乘坐，也可以取消订单。如果增加后的人数加上其他已经预定的乘客数量，没有超出车辆核载人数，那么司机可以继续接送，但是增加人数的乘客需要在客户端重新预约新增人数的订单；另外如果乘客人数临时减少，还是以其原定的人数作为订单人数计算。',
  ans: '您好，因为你选用的选用的是拼车服务，您现在临时增加的人数已经超过了车辆的核载人数，司机师傅是没有办法超载的，您看是按照之前的人数乘车出行，还是取消订单重新预约呢？不便之处，还请您理解！'
}, {
  que: '乘客起终点变化',
  flow: '如果乘客临时修改订单位置（起点或终点），在非拼车模式下客服（司机）可以受理乘客的修改位置的请求；在拼车模式下如果乘客临时修改订单位置系统判断会影响到其他乘客（不顺路）那么客服（司机）不能受理乘客的请求，如果系统判断顺路，那么客服（司机）可以受理乘客的请求。',
  ans: '您好，因为您选用的是拼车服务，您修改的起点（终点）位置与同车其他乘客的位置不顺路，所以目前只能按照您之前预定的订单地点进行接送，不便之处，还请您理解！'
}, {
  que: '带货需求',
  flow: '用车服务特指对乘客本人进行接送服务，不提供送货服务，（因为带货服务涉及相关货运资质或物流资质，同时对于所带货物本身也存在不可控因素），所以对于乘客的提出的送货申请，客服（司机）需要拒绝，但是对于乘客在乘车的同时顺带物品或不超车内空间的正常货物，可以允许携带。',
  ans: '您好，您选用的是专车（拼车）服务，是不能单纯带货的，我们目前没有这项服务，请您理解。'
}, {
  que: '低龄乘客乘车问题',
  flow: '未满16岁周岁的未成年乘客不提供单独乘车服务，必须有一名成年人陪同出行',
  ans: '您好！为了保障出行安全，目前未满16周岁的乘客乘车必须要有一名成年人陪同出行的，不便之处还请您理解。'
}, {
  que: '拼车服务中的儿童问题',
  flow: '在拼车出行的情景下，可能存在在儿童超载的问题，一位家长带着多位儿童乘车，那么儿童（婴儿也计算为儿童）必须按照正常占座的标准进行计算，严禁司机超载接单。',
  ans: '您好，您选用的是拼车服务，儿童也是按照正常成年人的标准去占用座位数量的，为了车辆的行车安全，也为您和您孩子的出行舒适性，司机师傅是不超载接单的，还请您理解。'
}, {
  que: '醉酒乘客处理',
  flow: '醉酒乘客乘客乘车的安全风险比较高，司机本身也存在服务盲区，同时如果是拼车服务，醉酒乘客也会影响到其他乘客的出行体验，所以对于醉酒乘客，可以拒载。',
  ans: '您好，您目前是处于醉酒状态，为了您的安全我们暂不能向您提供服务的，您可以就地稍作休息，或者联系您的家人来接您，不便之处还请您谅解。'
}, {
  que: '物品遗失',
  flow: '物品遗失一般分为另种情况， 一种是司机主动上报的，此时需要司机明确告知客服遗失物品是什么，在不涉及乘客隐私的情况下，客服要求司机对遗失物品进行拍照留存，然后由客服联系乘客，告知乘客遗失了物品，如果司机方便可以由司机到乘客手上，如果司机不方便可以由司机选择快递到付的方式将物品快递给乘客；如果联系不上乘客或者不知道是哪位乘客丢失的，那么司机将捡到的物品交由车队长转存至公司内部保存，等候乘客主动联系。如果丢失的物品是贵重物品，客服协助司机及时报警，交由警方处理；一种是乘客主动上报的，此时客服需要询问丢失了什么东西，询问丢失物品的详细特征（例如手机型号、颜色、品牌；钱包内的金额数量证件种类、号码等），然后由客服向司机询问车内是否有上述物品，如果没有就如实回复乘客，如果有就核对物品特征是否吻合，如不吻合，需要将物品报警交由警方处理；如果吻合，可以参照上述归还方式将物品还给客户。',
  ans: '您好！请问您是手机尾号为xxxx的乘客吗？我是优选专车的客服，您这边在xx日乘车的路途中是不是有物品遗失？……嗯！好的请问您遗失的物品具体是什么呢？……好的，请问您遗失的物品具体都写什么特征？……好的，您遗失的物品将由司机师傅通过快递到付的方式将寄送给您，请您提供一下您的收件地址信息。'
}, {
  que: '车费异常',
  flow: '乘客的车费是由系统按照计费策略进行自动定价和计价的，如果乘客对车费有疑问，客服需要解释乘客的车费构成因素；如果实际车费没有异常只需要向乘客进行正常的解释即可，如果有异常那么需要排查异常原因，是系统本身的原因，排查清楚后，对超收的车费对乘客进行退还。同时如果乘客反馈的车费问题是由于司机违规超收导致的，客服按对应的投诉流程处理即可。',
  ans: '您好，您咨询的车费异常问题，经核实后是没有问题的，您的实际车费是xx元，车费的具体构成是……/您好，您反映的车费异常问题，经核实，因为系统故障确实导致存在异常，在此郑重向您道歉，我们马上会将超收的车费原路退回您的支付账户，请您注意查收。'
}, {
  que: '防疫要求',
  flow: '客服需要及时了解公司背部传达的防疫要求，对乘客咨询的疫情期间出行注意事项需要做到仔细的告知，对于不符合防疫要求的乘客，客服（司机）需要及时拒载。',
  ans: '您好，根据政府部门最新的疫情防控要求，您出行必须具备……的条件，如果您不符合上述要求，司机师傅是不能接送您的。不便之处还请您理解。'
}, {
  que: '注册问题',
  flow: '注册问题一般分为以下几种情况，一种是已经注册的需要更换注册手机号的问题，这种情况下客服只需要将自助更换手机号的流程告知乘客即可，一种是注册是收不到手机验证码的问题，这种情况下，客服只需要核对手机号码为乘客本人使用之后，后台手动为乘客发送验证码，让乘客核对验证码注册即可。',
  ans: '您好，更换手机号只需要自助前往乘客app的账户页面自助更换即可……/您好，已经为您手动发送验证码，请您将收到的验证码告诉我即可。'
}, {
  que: '优惠类问题',
  flow: '目前还没有开通营销模块，也没有开通优惠券的使用，暂时不提供优惠券。',
  ans: '您好，目前暂时还没有派发优惠券的安排，您可以留意平台上的最新优惠活动通知，所有的优惠通知都会通过平台消息推送的。'
}, {
  que: '同车问题',
  flow: '同车需求特指拼车业务中，乘客向司机（客服）提出想要与指定的其他乘客同行，这种情况下，客服（司机）无法答应客户的请求。只能以系统派单为准。因为司机的车内乘客人数必须账户内订单人数一致，不能接额外的非系统指定订单。',
  ans: '您好，因为您选用的是拼车服务，司机的接送人数以您的订单为准，如果您需要和您的一辆车，您可以让您的朋友与您一起上车，然后你按照实际人数进行预约就可以了。'
}, {
  que: '行李问题',
  flow: '非拼车服务中也存在行李超大的问题，但行李问题一般在拼车场景中多见，对于非拼车服务，行李限制即车辆行李箱的体积，如超出了车辆空间，客服只能协助司机取消订单，拼车服务的行李有限制，每位乘客可以免费携带一个26寸大小的行李箱和一个随身携带的小包，超出上述标准的行李箱需要至少额外添加一同行人数来增加行李存放体积。',
  ans: '您好，您选用的是拼车服务，拼车服务的每位乘客携带行李的上限是一个26寸的行李箱和一个随身的小包，您目前携带的行李体积已经超出了上述要求，可能会与同车其他乘客行李冲突导致车辆行李箱装不下，您可以取消订单后添加一个出行人数再预约车辆，这样您可以多出一个人的行李空间了。'
}, {
  que: '座位问题',
  flow: '座位问题特指在拼车出行的过程中，车上不同乘客的座位要求有冲突，例如同时有两个乘客要求乘坐副驾驶，那么客服（司机）需要对乘客的需求冲突进行协调，协调原则是先上车的乘客有优先的选择的权利，同时对于老弱病残孕等特殊群体的客户客服有责任尽力协调满足他们的特殊性需求；对于不符合上述原则的座位需求，客服只能尽力安抚。',
  ans: '您好，因为您选用的拼车出行的服务，拼车服务中的车辆座位是以上车的先后顺序由乘客自行选座的，您目前的需求座位确实已经被先上车的乘客乘坐了，建议您和这名乘客进行沟通协商……'
}, {
  que: '路程时间问题',
  flow: '路程时间在乘客端都会有显示，所显示的时间一般也都是实时准确的，而路程时间问题的咨询一般出现在非拼车的出行服务中，因为拼车出行的服务并非直接的起点到终点，中途可能会对顺路的其他乘客进行接送或者对绕路时间在可接受范围内的乘客进行接送，这就会导致部分乘客的路程时间会有相应的延长。可能会导致这部分乘客对路程时间有异议或者投诉。那么在面对此类咨询时客服可以参考系统上的预计时间反馈给乘客，但是如果需要很精确的时间，客服可以让乘客咨询司机，以司机的答复为准，同时如果乘客是预约单还没有上车，对于时间有精确要求的乘客，客服可以协调乘客选用非拼车服务，这样时间上会相对有保证。',
  ans: '您好，路程时间您可以在乘客app上面查看的，通常情况下app上面显示的路程时间是可以作为参考的……/您好，因为您选用的是拼车服务，拼车服务的路程时间相对于乘客端app显示的路程时间可能会有一定程度的延长，延长的主要原因是在等待其他同行乘客上车的过程中可能会因为同车乘客上车时间延时而导致您的路程时间被延长，如果您对于路程时间比较敏感，建议您选乘专车服务。'
}, {
  que: '出发时间问题',
  flow: '在专车服务中不存在出发时间问题，上车时间就是出发时间，咨询出发时间问题一般以拼车服务的乘客为主，因为是拼车服务，所以在同一个上车点如有有多个乘客先后上车（上车时间前后极限差不超过30分钟），那么对于先上车的乘客而言就存在什么时间出发的问题，对于这类咨询，客服（司机）需要向乘客进行说明后面的乘客什么时间上车，乘客需要等多久，预计的出发时间是什么时候。如果乘客对于出发时间有严格要求，可以建议乘客换乘专车服务。',
  ans: '您好，您选乘的是拼车服务，拼车服务的出发时间是以同车的最后一名乘客的上车时间为出发参考时间，同车乘客的前后上车时间不会超过30分钟。您预定行程的同车乘客中最后一名上车的乘客时间是……'
}, {
  que: '价格问题',
  flow: '优选专车提供的所有打车服务价格以客户端显示的价格为准，严禁司机以任何名目向乘客超收车费。但是有一种情况就是乘客在预定了订单之后自己又修改了订单的起点和终点或者乘客在实际乘车过程中不按照订单地点上下车，导致司机的真实接送里程超过了订单的预约里程数。这种情况救护导致乘客的实际车费会超过预约订单时候的车费，这种情况是允许司机按照实际里程数来收取的。',
  ans: '您好，车费就是按照你预约订单时显示的车费价格收取的，司机不会超收您的费用……您好，经过核实，您的实际上下车地点和订单预约的地点不一致，您的实际上下车地点导致里程数有所增加，所有对应的车费和原来的预计的车费是不一样的。'
}, {
  que: '宠物问题',
  flow: '在优选专车的用车服务中，专车出行的乘客可以携带一只中型犬或同等大小的宠物，但是拼车出行的服务中在乘客可以携带1只小型犬或同等大小宠物；以上携带宠物确保宠物没有异味，且需用专用宠物箱装好或者做好防护措施，否则司机有权拒载，同时建议置于后备厢，以免发生宠物伤人的情况，如发生宠物伤人的情况，由携带宠物的乘客自行承担责任，宠物在车内如有对车辆内饰造成破坏，携带宠物的乘客也需要承担相应的责任和赔偿义务。',
  ans: '您好，在乘车过程中如果要携带宠物，您携带的宠物标准是……，在乘车过程中，请您照顾好您的宠物，如有发生宠物伤人或者破坏了车内内饰，您是需要承担相应的责任的。'
}, {
  que: '虚拟号码咨询',
  flow: '虚拟号码主要是起到两种作用，一是对乘客的个人隐私进行有效的保护，二是对司机的服务进行有效的监管和事后追踪。使用虚拟号码导致的咨询问题可能有下车后乘客无法再次联系司机；使用虚拟号码也只能是司乘双方的通话，任何第三方无法通过虚拟号码与司乘联系。',
  ans: '您好，为了保护乘客的个人隐私，也为了保证服务质量，目前对于司乘双方的通话采用的绑定虚拟小号进行通话，订单结束后司乘双方就无法再进行正常通话了，您如果有什么情况需要联系司机，请您告诉我您的需求，我们会为您转告的。'
}, {
  que: '选择车型',
  flow: '在乘客进行服务预约的时候，会有对应的车型供乘客进行选择，但是有一种情况是如果某种车型的车辆暂时预约完了，那么这种车型就无法进行预约了，如果乘客咨询，客服只能为乘客进行其他车型预约引导，不能承诺该车型的预约服务。',
  ans: '您好，目前xxx的车型暂时已经被预约满了，没有多余的车辆可以提供对应的服务了，但是xx车型和xx车型的空载车辆是有的，您可以改换成对应的车型进行预约，或者您可以稍等一会，在进入预约界面查看最新的可预约车辆种类，如果可以预约了您就可以预约下单了。'
}, {
  que: '取消订单',
  flow: '优选专车的订单在系统指派司机之前乘客都可以取消订单，此种情况取消订单是没有任何违约成本的，但是对于已经指派了司机或司机已经在上车点等候的订单，乘客再取消订单是有违约成本的，具体费用以乘客端显示的费用为准；',
  ans: '您好，因为司机在来接您的路上（司机已经到达上车地点），您现在取消订单，会影响司机空载成本，所以您的这笔订单不能无责取消的，取消的违约金额以您订单页面的提示为准。'
}, {
  que: '服务态度恶劣',
  flow: '一般投诉司机的服务态度恶劣，客服需要在受理投诉后，马上调取车辆的行车轨迹、通话录音、 车内影像进行核验，作出投诉是否成立的结论，投诉如果成立对乘客进行回复，一般是发放10-30元的车费优惠券，如果乘客还不满意，可以进一步反馈给客服主管，客服主管可以将补偿上升到免收车费的一半并继续发放10-30元的优惠券。同时对于被投诉的司机进行当次订单一星评价处理，如果涉及其他情况，可以向客服主管进一步反馈；如果投诉不成立，也要及时给乘客反馈结果，并解释原因。',
  ans: '您好，您的之前反应的司机服务态度问题，经过核实，现在向您反馈结果……'
}, {
  que: '行车不安全',
  flow: '对于乘客投诉司机驾车不安全的反馈，客服需要及时调取车辆的行车轨迹、 车内影像进行核验，核实后司机确实有不安全的驾车的行为，客服及时记录在司机账号内，并在当天将情况反馈给客服主管；对于反馈的乘客单次给予30元车费优惠券奖励。',
  ans: '您好，您刚才反应的司机驾车不安全的情况，经过核实，现在向您反馈……'
}, {
  que: '司机不接电话',
  flow: '司机不接乘客的电话，可能是司机不便接听，也可能是司机处于信号盲区，司机一般不会拒接乘客电话的，这种情况下，可以建议乘客到订单约定的上车地点等候司机的到来，或者在客户端实时查看司机的定位，参照司机的实时位置规划等候时间和地点。同时客服也要及时联系司机，排除重大或紧急客情。',
  ans: '您好，司机可能是不方便接听电话或处于信号盲区……'
}, {
  que: '车辆卫生不达标',
  flow: '投诉车辆卫生不达标，可以让乘客发送车辆的外观或内部照片，经过核实后可以对司机进行一星评价处理，同时提醒司机将车辆车辆卫生整理达标，否则将会暂时封号处理。对于反馈的乘客单次给予10-20元车费优惠券奖励。',
  ans: '您好，感谢您反馈的情况，这对于改善我们的服务很有帮助……'
}, {
  que: '司机迟到',
  flow: '对于乘客反馈的司机迟到的问题，需要核实是司机主观原因迟到还是因为客观原因导致的迟到（需要客服核实司机的行车轨迹以及中途停车时间），如果是客观原因导致的迟到，可以向乘客解释说明，如果是司机主观原因的导致的迟到，应该及时向乘客解释并赔礼，对乘客给予10-30元的车费优惠券发放，如果乘客还不满意，可以进一步反馈给客服主管，客服主管可以将补偿上升到免收车费的一半并继续发放10-30元的优惠券。同时对于被投诉的司机进行当次订单一星评价处理，如果涉及其他情况，可以向客服主管进一步反馈',
  ans: '您好，您的之前反应的司机服迟到的问题，经过核实，现在向您反馈结果……'
}, {
  que: '司机绕路',
  flow: '司机绕路一般分为两种情况，一种类是客观的拼车业务中的接送多人或者修路交通管制的等情况导致的；一种是主观的司机故意绕路；如果是客观原因导致的迟到，可以向乘客解释说明，如果是司机主观原因的导致的迟到，应该及时向乘客解释并赔礼，并按照原来的预定的价格收取（多收取的车费退还给乘客）',
  ans: '您好，您刚才反馈的司机绕路的情况，经过核实，现在向您反馈结果……'
}];


const questions2 = [{
  que: '乘客一键报警',
  ans: '乘客在客户端一键报警，客服后台收到一键报警的信息后，需要第一时间向客人回拨电话核对情况；同时实时追踪车辆定位，查看车辆的位置信息和轨迹信息，并对相关信息本地留档处理'
}, {
  que: '车内人员发生严重冲突',
  ans: '如果确认车内人员（司机和乘客，拼车业务中的乘客和乘客）发生严重的肢体冲突、暴力行为、性侵行为、诈骗行为、涉恐行为或其他涉刑举动，客服需要第一时间报警，并将车辆、司机和乘客的相关信息（定位、轨迹、通话录音、车内录音）及时本地保存，以备公安部门调查取证；然后客服将上述情况及时向客服主管反馈，客服主管需及时介入了解情况并配合公安部门调查取证，联系该司机或乘客的紧急联系人，通报情况，客服主管同时也许向客服领导小组组长及时汇报警情，客服领导小组组长也需第一时间参与警情处理，尽量将警情危害控制到最低水平'
}, {
  que: '乘客之间纠纷',
  ans: '车上乘客之间如果发生纠纷（特指拼车业务），遇到司机或者乘客上报纠纷，客服需要第一时间介入，首先车内录音存档；然后了解纠纷过程；明显过失方（如一方醉酒、骚扰或故意滋事等情况）可以请过失方无责下车，如果不配合或者纠纷进一步升级客服可以参照（车内人员发生严重冲突）报警处理。客服需要做好全程证据链（聊天记录、通话录音、车内录音）的保存工作；如果纠纷双方无法判责，客服可以协助司机友好的请两位乘客下车，但是需要在保证行车安全的前提下。如果纠纷双方不配合或纠纷进一步升级，处理原则同上。'
}, {
  que: '司乘之间纠纷',
  ans: '司乘之间如果发生纠纷，遇到司机或者乘客上报纠纷，客服需要第一时间介入，首先车内录音存档；然后了解纠纷过程中所有聊天记录也要做存档处理；如果是司机过失，那么请司机在保障安全的前提下就近停车 让乘客下车，并为乘客安排最近最快的车辆继续提供服务。对于乘客赶时间或者没有就近就快的车辆安排，如果司机后续能继续提供服务乘客也愿意基础乘坐，那么继续由该司机提供服务；如果不能那么全额赔偿乘客的全部车费，同时对于该司机的问题及时系统内存档，并向车队长报备；如果是乘客过失，那么在保证司机行车安全的前提下，可以友好的请乘客下车，如果乘客不愿下车且纠纷进一步升级，客服可以可以参照（车内人员发生严重冲突）报警处理。'
}, {
  que: '车辆故障',
  ans: '此类客情为车辆故障导致车辆无法再继续行驶或者是车辆存在安全隐患，值班客服收到司机或者乘客上报的车辆故障后，让司机就近停车，在司机保障停车安全的前提下，对于车辆无法再安全行驶的情况，及时为车上乘客安排就近的车辆接送，如果乘客不愿意等待，可以全额补偿乘客当次车费。同时对于故障车辆所属的司机账号进行强制休假处理，将车辆故障信息报送给车队长。车辆故障修复后由车队长检查合格，通知客服予以司机账号上线。'
}, {
  que: '车辆事故处理',
  ans: '值班客服收到司机或乘客的事故反馈后，首先确认车内司乘人员的安全，如没有人员受伤，处理原则参照车辆故障处理原则，如果有车内人员受伤，客服第一时间协助司乘人员报警并汇报客服主管，然后客服核对车内人员伤情，确认或询问车内受伤人员的紧急联系人，并联系受伤人员的紧急联系人告知具体情况。客服在进行上述工作的同时要安抚车内人员情绪，保持全程连线。直至警察或医护人员赶到现场。'
}, {
  que: '车内司乘人员失联',
  ans: '车内人员失联具体分为乘客失联与司机失联，特指持续60分钟及以上无法联系到司乘人员且司乘人员的账号状态、进行中的订单状态、车辆状态没有任何更新或者活动轨迹。车内失联人员如果是司机，客服需要及时联系司机的紧急联系人，告知司机的失联消息同时协助联络司机，同时如果司机失联时长达到报警标标准（失联24小时），客服及时通知司机家人协助报警，同时将失联情况汇报给客服主管。'
}, {
  que: '协助公安部门处理警情',
  ans: '协助调查警情的状况一般都是公安部门联系公司后，由公司其他部门协助处理，但是也不排除公安部门直接客服部门，当客服收到公安部门人员的协助处理警情的情况，首先需要向对方核实身份（具体什么公安部门，联系人员的姓名与警号）并问清调查事宜，客服记录好上述信息后，需要告知对方让对方出具协助调查函后联系公司负责人（客服领导小组组长）的联系方式。然后将记录的情况及时向客服主管进行汇报。客服人员不得随意将客户信息（手机号、订单信息等）透露给未经身份核实的任意第三方。'
}, {
  que: '协助其他政府部门处理',
  ans: '协助政府部门处理公共事务，一般都是政府部门联系公司后，由公司其他部门协助处理，但是也不排除政府部门直接客服部门，当客服收到政府部门的协查的通知，首先需要向对方核实身份（具体什么部门，联系人员的姓名）并问清调查事宜，客服记录好上述信息后，需要告知对方让对方联系公司负责人（客服领导小组组长）。然后将记录的情况及时向客服主管进行汇报。客服人员不得随意将客户信息（手机号、订单信息等）透露给未经身份核实的任意第三方。'
}, {
  que: '协助联系车内人员',
  ans: '客服接到需要联系车内人员（乘客或司机）或查车内人员的行程信息的请求之后，需要核对对方身份并核对联系原因，确认后可以予以告知。核对身份需要问明对方姓名、身份证号、与被查询人员的关系，同时需要提供被查询人员的手机号、性别、家庭住址、最近的行程信息（起终点、出行大概时间），此类咨询涉及用户隐私需要在内部系统中登记并当天内向客服主管报备。'
}, {
  que: '乘客行程中突发疾病',
  ans: '在乘客的收到车内乘客突发疾病的紧急客情之后，客服需要让司机协助对乘客进行紧急救助，将乘客送往最近的医院救治，并同时联系乘客的紧急联系人告知情况。司机需要有义务协助参与乘客的全程救助，如果车内还有其他的拼车乘客那么客服需要对同行的其他乘客进行安抚，并同时安排就近车辆对同车乘客进行后续的接送服务。'
}, {
  que: '司机酒驾、毒驾',
  ans: '在接到乘客反馈司机有酒驾或毒驾等对行车安全造成重大影响的情况时，客服应该第一时间联系司机，委婉请司机在保证安全的前提下就近停车，并安排最近的车辆来对乘客进行继续的接送服务；对于涉事司机不要当即告诉其乘客反馈的情况，避免司乘矛盾，同时也是保护乘客；需要在乘客乘坐上后续车辆后，对当事司机的账号进行封号处理，并及时将当事司机的情况及时向客服主管报备，以便公司及时查证后处理。'
}, {
  que: '车内司乘人员具有重大社会安全风险',
  ans: '接到司机或同车其他乘客反馈的某位乘客或司机具有重大社会安全风险（包含通缉人员、随身携带违禁物品、行为举止异常有人身攻击倾向、自杀倾向、散布违反法律法规的言论、物品且不听劝阻的），客服需要第一时间对车辆的行车轨迹进行记录、车内影像信息留存；协助稳定车内状况；并同时报警处理；然后及时向客服领导小组组长反馈。'
}];

const commonWords = ['您好，已经为您处理成功，如有问题，请及时联系我们！',
  '您好，优选专车客服！请问有什么可以帮您？',
  '您好，优选专车的客服电话是400-0716-123',
  '感谢您对优选专车的支持，祝您生活愉快！',
  '感谢您选乘优选专车，祝您生活愉快！',
  '您好，麻烦您稍等一下，我为您查询一下',
  '不好意思，让您久等了',
  '如果在后续的使用中还有问题，及时与我们联系，好吗？',
  '武汉回监利，监利回武汉都可以通行的',
  '您好！需要绿色健康码和身份证'];

export {commonWords, questions, questions2};