import { Http } from "@/util";

export function listDiscountWord (param) {
  return Http.post('/admin/marketing/word/list', param);
}

export function addDiscountWord (param) {
  return Http.post('/admin/marketing/word/add', param);
}

export function deleteWord (param) {
  return Http.get('/admin/marketing/word/delete', param);
}

export function modifyDiscountWord (param) {
  return Http.post('/admin/marketing/word/update', param);
}

export function listAdvertisement (param) {
  return Http.post('/admin/marketing/advertisement/list', param);
}

export function addAdvertisement (param) {
  return Http.post('/admin/marketing/advertisement/add', param);
}

export function deleteAdvertisement (param) {
  return Http.get('/admin/marketing/advertisement/delete', param);
}

export function modifyAdvertisement (param) {
  return Http.post('/admin/marketing/advertisement/update', param);
}

export function listCoupon (param) {
  return Http.post('/admin/marketing/coupon/list', param);
}

export function addCoupon (param) {
  return Http.post('/admin/marketing/coupon/add', param);
}

export function modifyCoupon (param) {
  return Http.post('/admin/marketing/coupon/update', param);
}

export function queryExchangeCode (param) {
  return Http.post('/admin/marketing/exchange_code/list', param);
}

export function genExchangeCode (param) {
  return Http.post('/admin/marketing/gen/coupon_code', param);
}