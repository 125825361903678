<template>
  <el-dialog :visible.sync="visible" width="660px">
    <el-tabs v-model="activeName">
      <el-tab-pane label="操作日志" name="first">
        <div v-loading="loading" class="box">
          <el-timeline>
            <el-timeline-item
              v-for="item in data"
              :key="item.id"
              :type="item.opLevel"
              :timestamp="item.createTime"
              >{{ item.userType | enumTransfer(logUserTypeOpts) }} {{ item.userName }}
              {{ item.operate }}</el-timeline-item
            >
          </el-timeline>
        </div>
      </el-tab-pane>
      <el-tab-pane label="改签日志" name="second">
        <div class="box">
          <el-table :data="changeData" stripe size="small">
            <el-table-column prop="createTime" label="时间" width="140"> </el-table-column>
            <el-table-column prop="changeTyeName" label="操作" width="100"> </el-table-column>
            <el-table-column prop="beforeVal" label="之前"> </el-table-column>
            <el-table-column prop="afterVal" label="之后"> </el-table-column>
            <el-table-column prop="operator" label="操作人" width="80"> </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { getOrderLogs, getOrderChangeLogs } from "@/api";
import store from "@/store";

export default {
  computed: {
    logUserTypeOpts() {
      return store.state.enumMap["LOG_USER_TYPE"];
    },
  },

  data() {
    return {
      visible: true,
      data: [],
      loading: false,
      changeData: [],
      activeName: "first",
    };
  },
  methods: {
    show(id) {
      this.visible = true;
      this.loading = true;
      getOrderLogs(id).then((res) => {
        this.data = res.data || [];
        this.loading = false;
      });

      getOrderChangeLogs(id).then((res) => {
        this.changeData = res.data || [];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  height: 460px;
  overflow: auto;
  padding: 20px 10px;
}
</style>
