import Vue from 'vue';

Vue.filter('twoLevelCascaderTransfer', function (value, enumOpts, valueField = "value", labelField = "label") {
  if (enumOpts && enumOpts.length) {
    if (value !== undefined && value !== null) {
      for (const index in enumOpts) {
        const item = enumOpts[index];
        if (value.split("-")[0] == item[valueField]) {
          for (const index2 in item.children) {
            const item2 = item.children[index2];
            if (value === item2[valueField]) {
              return `${item[labelField]}/${item2[labelField]}`;
            }

          }
        }
      }
    }
  }
  return value;
});
