<template>
  <div class="root-box">
    <el-tabs value="first">
      <el-tab-pane label="路线管理" name="first">
        <div class="operate-container">
          <el-button type="primary" :size="size" @click="openAdd">新增路线</el-button>
        </div>

        <el-table :data="list" stripe max-height="600">
          <el-table-column label="序号" type="index" width="50"></el-table-column>

          <el-table-column prop="name" label="路线名称"></el-table-column>

          <!-- <el-table-column prop="startArea" label="起点">
            <template slot-scope="scope">{{
              scope.row.startArea | enumTransfer(areaOpts)
            }}</template>
</el-table-column>

<el-table-column prop="endArea" label="终点">
  <template slot-scope="scope">{{
              scope.row.endArea | enumTransfer(areaOpts)
            }}</template>
</el-table-column> -->

          <el-table-column prop="configId" label="路线配置" width="120">
            <template slot-scope="scope">{{
              scope.row.configId | enumTransfer(configOpts)
            }}</template>
          </el-table-column>

          <el-table-column prop="state" label="是否启用">
            <template slot-scope="scope">{{ scope.row.state | enumTransfer(yesNoOpts) }}</template>
          </el-table-column>

          <!-- <el-table-column prop="isStartPath" label="正向路线">
            <template slot-scope="scope">{{
              scope.row.isStartPath | enumTransfer(yesNoOpts)
            }}</template>
          </el-table-column> -->

          <el-table-column prop="safeTicketNum" label="手控间隔"> </el-table-column>

          <el-table-column prop="routeTime" label="行程时间">
            <template slot-scope="scope">{{ scope.row.routeTime }}</template>
          </el-table-column>

          <el-table-column prop="canCharter" label="可包车：">
            <template slot-scope="scope">{{
              scope.row.canCharter | enumTransfer(yesNoOpts)
            }}</template>
          </el-table-column>

          <el-table-column prop="charterStartTime" label="包车时间">
            <template slot-scope="scope"><span v-if="scope.row.charterStartTime">{{ scope.row.charterStartTime }}点 - {{
              scope.row.charterEndTime }}点</span></template>
          </el-table-column>

          <!-- <el-table-column prop="maxDiscount" label="最大优惠金额"> </el-table-column> -->

          <el-table-column prop="shuntFee" label="分流费用"> </el-table-column>

          <el-table-column prop="powerFee" label="单边电费"> </el-table-column>

          <el-table-column prop="emptyRouteCost" label="放空费用"> </el-table-column>

          <el-table-column prop="emptyRoutePowerFee" label="放空电费"> </el-table-column>

          <el-table-column prop="dispatchPriority" label="派单优先级"> </el-table-column>

          <el-table-column prop="highwayEntranceId" label="高速入口" width="120">
            <template slot-scope="scope">{{
              scope.row.highwayEntranceId | enumTransfer(allHighwayList, "id", "name")
            }}</template>
          </el-table-column>

          <el-table-column prop="highwayExitId" label="高速出口" width="120">
            <template slot-scope="scope">{{
              scope.row.highwayExitId | enumTransfer(allHighwayList, "id", "name")
            }}</template>
          </el-table-column>

          <el-table-column prop="standardMiles" label="标准里程"> </el-table-column>
          <el-table-column prop="standardSpeed" label="标准速度"> </el-table-column>


          <!-- <el-table-column prop="remark" label="备注"></el-table-column> -->

          <el-table-column prop="state" label="操作">
            <template slot-scope="scope">
              <span>
                <el-button type="text" size="small" @click="changeState(scope.row)">
                  <span v-if="scope.row.state">停用</span>
                  <span v-else class="red">启用</span>
                </el-button>

                <el-button type="text" size="small" @click="updatePath(scope.row)">修改</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="配置管理" name="second">
        <div class="operate-container">
          <el-button type="primary" :size="size" @click="addConfig">新增配置</el-button>
        </div>

        <el-table :data="configList" stripe max-height="600">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="name" label="配置名称"></el-table-column>
          <el-table-column prop="departureInterval" label="发班间隔（分钟）" width="200"></el-table-column>
          <el-table-column prop="expectedTimeOfRoute" label="单趟行程时间（分钟）" width="200"></el-table-column>

          <el-table-column prop="advanceTimeOfChangeTicket" label="改签提前时间（分钟）" width="200"></el-table-column>
          <el-table-column prop="maxContinuedTripTimes" label="司机最大发班次数" width="200"></el-table-column>
          <el-table-column prop="basePrice" label="路线基础价格"></el-table-column>

          <el-table-column prop="autoTicket" label="自动排票">
            <template slot-scope="scope">{{ scope.row.autoTicket ? "是" : "否" }}</template>
          </el-table-column>
          <el-table-column prop="autoTicket" label="自动提醒接单">
            <template slot-scope="scope">{{ scope.row.autoReminder ? "是" : "否" }}</template>
          </el-table-column>

          <el-table-column prop="enablePay" label="开启支付">
            <template slot-scope="scope">{{ scope.row.enablePay ? "是" : "否" }}</template>
          </el-table-column>

          <el-table-column prop="valuePoints" label="行程积分"></el-table-column>

          <el-table-column prop="state" label="操作">
            <template slot-scope="scope">
              <span>
                <el-button type="text" size="small" @click="updateConfig(scope.row)">修改</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="发车标准" name="third">
        <div class="operate-container">
          <el-form ref="queryForm" :model="queryForm" inline :size="size">
            <el-form-item label="路线：" prop="pathId">
              <el-select v-model="queryForm.pathId" clearable @change="getDepartStandardList">
                <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-button type="primary" :size="size" @click="openAddDepartStanard(true)">新增发车标准</el-button>
        </div>

        <el-table :data="departStandardList" stripe max-height="680" style="width: 99%">
          <el-table-column label="序号" type="index" width="100"></el-table-column>

          <el-table-column prop="pathId" label="路线">
            <template slot-scope="scope">{{ scope.row.pathId | pathNameTransfer }}</template>
          </el-table-column>

          <el-table-column prop="vehicleTypeId" label="车型">
            <template slot-scope="scope">{{
              scope.row.vehicleTypeId | enumTransfer(vehicleTypeOpts, "id", "content")
            }}</template>
          </el-table-column>

          <el-table-column prop="departStandard" label="发车标准">
            <template slot-scope="scope">{{ scope.row.departStandard }} 人</template>
          </el-table-column>

          <el-table-column prop="validOn" label="生效日期起"> </el-table-column>

          <el-table-column prop="validOff" label="生效日期止"> </el-table-column>

          <el-table-column prop="state" label="操作">
            <template slot-scope="scope">
              <span>
                <el-button type="text" size="small" @click="openAddDepartStanard(false, scope.row)">修改</el-button>
              </span>
              <span style="margin-left: 10px">
                <el-button type="text" size="small" @click="removeDepartStanard(scope.row)">删除</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="visible" width="1200px" max-height="600" :title="editPath ? '修改路线' : '新增路线'"
      :close-on-click-modal="false">
      <el-form ref="form" :model="form" :size="size" label-width="130px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="起点区域：" prop="startArea">
              <el-select v-model="form.startArea" :disabled="editPath" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in areaOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="终点区域：" prop="endArea">
              <el-select v-model="form.endArea" :disabled="editPath" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in areaOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="路线配置" prop="configId">
              <el-select v-model="form.configId" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in configOpts" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="路线名称：" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="是否可包车：" prop="canCharter">
              <el-select v-model="form.canCharter" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="包车开始时间：" prop="charterStartTime">
              <el-select v-model="form.charterStartTime" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in 24" :key="item" :label="`${item - 1}点`" :value="item - 1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="包车结束时间：" prop="charterEndTime">
              <el-select v-model="form.charterEndTime" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in 24" :key="item" :label="`${item - 1}点`" :value="item - 1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="行程时间" prop="routeTime">
              <el-input-number class="form-input-num" v-model="form.routeTime"></el-input-number>
            </el-form-item>

            <el-form-item label="手控间隔数量" prop="safeTicketNum">
              <el-input-number class="form-input-num" v-model="form.safeTicketNum"></el-input-number>
            </el-form-item>

            <el-form-item label="最大优惠金额" prop="maxDiscount">
              <el-input-number class="form-input-num" v-model="form.maxDiscount"></el-input-number>
            </el-form-item>

            <el-form-item label="分流费用" prop="maxDiscount">
              <el-input-number class="form-input-num" v-model="form.shuntFee"></el-input-number>
            </el-form-item>

            <el-form-item label="派单优先级" prop="dispatchPriority">
              <el-input-number class="form-input-num" v-model="form.dispatchPriority"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="12">


            <el-form-item label="单边电费" prop="maxDiscount">
              <el-input-number class="form-input-num" v-model="form.powerFee"></el-input-number>
            </el-form-item>



            <el-form-item label="放空电费" prop="emptyRoutePowerFee">
              <el-input-number class="form-input-num" v-model="form.emptyRoutePowerFee"></el-input-number>
            </el-form-item>

            <el-form-item label="放空费用" prop="emptyRouteCost">
              <el-input-number class="form-input-num" v-model="form.emptyRouteCost"></el-input-number>
            </el-form-item>

            <el-form-item label="高速入口：" prop="highwayEntranceId">
              <el-select v-model="form.highwayEntranceId" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in entranceOpts" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>

            <el-form-item label="高速出口：" prop="highwayExitId">
              <el-select v-model="form.highwayExitId" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in exitOpts" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>

            <el-form-item label="标准里程" prop="standardMiles">
              <el-input-number class="form-input-num" v-model="form.standardMiles"></el-input-number>
            </el-form-item>

            <el-form-item label="标准速度" prop="standardSpeed">
              <el-input-number class="form-input-num" v-model="form.standardSpeed"></el-input-number>
            </el-form-item>

            <el-form-item label="是否启用：" prop="state">
              <el-select v-model="form.state" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否正向路线：" prop="isStartPath">
              <el-select v-model="form.isStartPath" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="备注：" prop="remark">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="save">保存</el-button>
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="configVisible" width="600px" max-height="600" :title="configForm.id ? '修改配置' : '新增配置'"
      :close-on-click-modal="false">
      <el-form ref="configForm" :model="configForm" :size="size" label-width="200px" :rules="configRules">
        <el-form-item label="配置名称" prop="name">
          <el-input v-model="configForm.name"></el-input>
        </el-form-item>
        <el-form-item label="发班间隔（分钟）" prop="departureInterval">
          <el-select v-model="configForm.departureInterval" class="form-input-num" placeholder="请选择"
            style="width: 100%">
            <el-option label="30分钟" :value="30"></el-option>
            <el-option label="60分钟" :value="60"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="单趟行程时间（分钟）" prop="expectedTimeOfRoute">
          <el-input-number class="form-input-num" v-model="configForm.expectedTimeOfRoute"></el-input-number>
        </el-form-item>

        <el-form-item label="改签提前时间（分钟）" prop="advanceTimeOfChangeTicket">
          <el-input-number class="form-input-num" v-model="configForm.advanceTimeOfChangeTicket"></el-input-number>
        </el-form-item>

        <el-form-item label="司机最大发班次数" prop="maxContinuedTripTimes">
          <el-input-number class="form-input-num" v-model="configForm.maxContinuedTripTimes"></el-input-number>
        </el-form-item>

        <el-form-item label="行程积分" prop="valuePoints">
          <el-input-number class="form-input-num" v-model="configForm.valuePoints"></el-input-number>
        </el-form-item>

        <el-form-item label="路线基础价格" prop="valuePoints">
          <el-input-number class="form-input-num" v-model="configForm.basePrice"></el-input-number>
        </el-form-item>

        <el-form-item label="自动排票" prop="autoTicket">
          <el-switch v-model="configForm.autoTicket"></el-switch>
        </el-form-item>

        <el-form-item label="自动提醒接单" prop="autoReminder">
          <el-switch v-model="configForm.autoReminder"></el-switch>
        </el-form-item>
        <el-form-item label="开启支付" prop="enablePay">
          <el-switch v-model="configForm.enablePay"></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveConfig">保存</el-button>
        <el-button type="info" :size="size" @click="closeConfigDiag">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="departStandardVisible" width="500px" max-height="600"
      :title="departStandardForm.id ? '修改发车标准' : '新增发车标准'" :close-on-click-modal="false">
      <el-form ref="departStandardForm" :model="departStandardForm" :size="size" label-width="100px"
        :rules="departStandardRules">
        <el-form-item label="路线：" prop="pathId">
          <el-select v-model="departStandardForm.pathId" :disabled="departStandardForm.id" style="width: 100%">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车型：" prop="vehicleTypeId">
          <el-select v-model="departStandardForm.vehicleTypeId" :disabled="departStandardForm.id" style="width: 100%">
            <el-option v-for="item in vehicleTypeOpts" :key="item.id" :label="item.content"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发车标准：" prop="departStandard">
          <el-input-number style="width: 100%" v-model="departStandardForm.departStandard"
            placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="有效期起：" prop="validOn">
          <el-date-picker style="width: 100%" placeholder="选择时间" value-format="yyyy-MM-dd"
            v-model="departStandardForm.validOn"></el-date-picker>
        </el-form-item>

        <el-form-item label="有效期止：" prop="validOff">
          <el-date-picker style="width: 100%" placeholder="选择时间" value-format="yyyy-MM-dd"
            v-model="departStandardForm.validOff"></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="updateDepartStandard">保存</el-button>
        <el-button type="info" :size="size" @click="departStandardVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  modifyPath,
  addPath,
  getPathList,
  addPathConfig,
  modifyPathConfig,
  listPathConfig,
  listDepartStandard,
  removeDepartStandard,
  saveOrUpdateDepartStandard,
  listAllHighway,
} from "@/api";
import _ from "lodash";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";

const defaultValue = {
  state: 1,
};

export default {
  created() {
    this.query();
    this.queryConfig();
    this.getDepartStandardList();
    this.initData();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      areaOpts: (state) => state.areaOpts,
    }),
    editPath() {
      return !!this.form.id;
    },
  },

  watch: {
    "form.startArea"(val) {
      if (val) {
        this.entranceOpts = this.allHighwayList.filter(
          (item) => item.areaId === val && item.type === 4
        );
      } else {
        this.entranceOpts = [];
      }
    },

    "form.endArea"(val) {
      if (val) {
        this.exitOpts = this.allHighwayList.filter(
          (item) => item.areaId === val && item.type === 6
        );
      } else {
        this.exitOpts = [];
      }
    },
  },

  data() {
    return {
      visible: false,
      configVisible: false,
      form: defaultValue,
      configForm: {},
      list: [],
      configList: [],
      departStandardList: [],
      departStandardForm: {},
      departStandardVisible: false,
      rules: {
        startArea: [
          {
            required: true,
            message: "请选择起点区域",
            trigger: "blur",
          },
        ],
        endArea: [
          {
            required: true,
            message: "请选择终点区域",
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message: "请输入启用状态",
            trigger: "blur",
          },
        ],
        isStartPath: [
          {
            required: true,
            message: "请选择是否正向路线",
            trigger: "blur",
          },
        ],
        minAheadTime: [
          {
            required: true,
            message: "请输入赶车最少提前时间",
            trigger: "blur",
          },
        ],
        maxDiscount: [
          {
            required: true,
            message: "请输入最大优惠金额",
            trigger: "blur",
          },
        ],
      },
      departStandardRules: {
        pathId: [{ required: true, message: "请选择路线", trigger: "blur" }],
        vehicleTypeId: [{ required: true, message: "请选择车型", trigger: "blur" }],
        departStandard: [
          {
            required: true,
            message: "请输入发车标准",
            trigger: "blur",
          },
        ],
        validOn: [
          {
            required: true,
            message: "请输入生效日期起",
            trigger: "blur",
          },
        ],
        validOff: [
          {
            required: true,
            message: "请输入生效日期止",
            trigger: "blur",
          },
        ],
      },
      configRules: {
        name: [
          {
            required: true,
            message: "请输入配置名称",
            trigger: "blur",
          },
        ],
        departureInterval: [
          {
            required: true,
            message: "请选择发班间隔",
            trigger: "blur",
          },
        ],
        expectedTimeOfRoute: [
          {
            required: true,
            message: "请输入单趟行程时间",
            trigger: "blur",
          },
        ],

        maxContinuedTripTimes: [
          {
            required: true,
            message: "请输入司机最大发班次数",
            trigger: "blur",
          },
        ],
      },
      configOpts: [],
      yesNoOpts: BaseDataService.yesNoOpts(),
      queryForm: {},
      allHighwayList: [],
      entranceOpts: [],
      exitOpts: [],
    };
  },
  methods: {
    async initData() {
      const res = await listAllHighway();
      this.allHighwayList = res.data;
    },

    closeDiag() {
      this.visible = false;
    },
    closeConfigDiag() {
      this.configVisible = false;
    },

    openAdd() {
      this.visible = true;
      this.form = _.cloneDeep(defaultValue);
    },
    changeState(item) {
      const data = {
        id: item.id,
        state: item.state ? 0 : 1,
      };
      modifyPath(data).then(() => {
        this.$message.success("状态修改成功");
        this.query();
      });
    },

    openAddDepartStanard(isAdd, item) {
      this.departStandardVisible = true;
      this.departStandardForm = isAdd ? {} : _.cloneDeep(item);
    },

    updateDepartStandard() {
      this.$refs.departStandardForm.validate((valid) => {
        if (!valid) {
          return;
        }

        saveOrUpdateDepartStandard(this.departStandardForm).then(() => {
          this.$message.success("操作成功");
          this.departStandardVisible = false;
          this.getDepartStandardList();
        });
      });
    },

    removeDepartStanard(item) {
      this.$confirm("确认要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeDepartStandard(item).then(() => {
          this.$message.success("操作成功");
          this.getDepartStandardList();
        });
      });
    },

    getDepartStandardList() {
      const { pathId } = this.queryForm || {};
      listDepartStandard({ pathId }).then((res) => {
        this.departStandardList = res.data || [];
      });
    },

    updateConfig(item) {
      this.configForm = _.cloneDeep(item);
      this.configVisible = true;
    },

    updatePath(item) {
      this.form = _.cloneDeep(item);
      this.visible = true;
    },
    addConfig() {
      // 发班间隔只能是60分钟
      this.configForm = { departureInterval: 60 };
      this.configVisible = true;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const { startArea, endArea } = this.form;

        if (startArea === endArea) {
          this.$message.error("起点和终点不能相同");
          return;
        }

        if (this.form.id) {
          modifyPath(this.form).then(() => {
            this.$message.success("路线修改成功");
            this.visible = false;
            this.query();
          });
        } else {
          addPath(this.form).then(() => {
            this.$message.success("路线新增成功");
            this.visible = false;
            this.query();
          });
        }
      });
    },

    saveConfig() {
      this.$refs.configForm.validate((valid) => {
        if (!valid) {
          return;
        }

        if (this.configForm.id) {
          modifyPathConfig(this.configForm).then(() => {
            this.$message.success("修改成功");
            this.closeConfigDiag();
            this.queryConfig();
          });
        } else {
          addPathConfig(this.configForm).then(() => {
            this.$message.success("新增成功");
            this.closeConfigDiag();
            this.queryConfig();
          });
        }
      });
    },
    query() {
      getPathList().then((res) => {
        this.list = res.data || [];
      });
    },
    queryConfig() {
      listPathConfig().then((res) => {
        this.configList = res.data || [];
        this.configOpts = this.configList.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.form-input-num {
  width: 100%;
}

.operate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
