<template>
  <div class="root-box">
    <el-form ref="form" size="small" :model="param" inline>
      <el-row>
        <el-col>
          <el-form-item label="统计开始时间：">
            <el-date-picker
              size="small"
              v-model="param.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              align="left"
              @change="query"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车队：">
            <el-select v-model="param.teamId">
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 80px" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="table">
      <div class="avg">
        <div class="item">
          <div>车队5星平局值</div>
          <div>{{ demo.fiveStarAvg }}</div>
        </div>
        <div class="item">
          <div>车队1星平局值</div>
          <div>{{ demo.oneStarAvg }}</div>
        </div>
        <div class="item">
          <div>车队服务分均值</div>
          <div>{{ demo.serviceScoreAvg }}</div>
        </div>
      </div>

      <el-table
        :data="list"
        stripe
        max-height="600"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="driverName" label="司机姓名"></el-table-column>

        <el-table-column prop="driverNo" label="司机编号"></el-table-column>

        <el-table-column prop="fiveStarRank" sortable label="5星排名">
          <template slot-scope="scope">
            {{ scope.row.fiveStarRank }}

            <i v-if="scope.row.redCompare > 0" class="iconfont zan">&#xe612;</i>
            <i v-if="scope.row.redCompare < 0" class="iconfont">&#xea32;</i>
          </template>
        </el-table-column>

        <el-table-column prop="oneStarRank" sortable label="1星排名">
          <template slot-scope="scope">
            {{ scope.row.oneStarRank }}

            <i v-if="scope.row.blackCompare < 0" class="iconfont zan"
              >&#xe612;</i
            >
            <i v-if="scope.row.blackCompare > 0" class="iconfont">&#xea32;</i>
          </template>
        </el-table-column>

        <el-table-column prop="serviceScoreRank" sortable label="服务分排名">
          <template slot-scope="scope">
            {{ scope.row.serviceScoreRank }}

            <i v-if="scope.row.serviceScoreCompare > 0" class="iconfont zan"
              >&#xe612;</i
            >
            <i v-if="scope.row.serviceScoreCompare < 0" class="iconfont"
              >&#xea32;</i
            >
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="serviceScore"
          label="服务分"
        ></el-table-column>

        <el-table-column
          prop="totalOrders"
          sortable
          label="总接单数"
        ></el-table-column>

        <el-table-column
          sortable
          prop="totalEvaluation"
          label="总评价数"
        ></el-table-column>
        <el-table-column
          prop="fiveStarNum"
          sortable
          label="5星个数"
        ></el-table-column>
        <el-table-column
          prop="fourStarNum"
          sortable
          label="4星个数"
        ></el-table-column>
        <el-table-column
          prop="threeStarNum"
          sortable
          label="3星个数"
        ></el-table-column>
        <el-table-column
          prop="twoStarNum"
          sortable
          label="2星个数"
        ></el-table-column>
        <el-table-column
          prop="oneStarNum"
          sortable
          label="1星个数"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

import {getTeamDriverEvaRank} from '@/api';
import {DateUtil} from '@/util';
import {mapState} from 'vuex';
import config from '@/config/config';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      teamOpts: state => state.teamOpts
    }),
    demo () {
      if (this.list.length) {
        return this.list[0];
      }
      return {};
    }
  },
  data () {
    const today = DateUtil.getDayStr(0);
    return {
      pickerOptions: {

        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const start = DateUtil.getDayStr(-7);
            picker.$emit('pick', [start, today]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const start = DateUtil.getDayStr(-30);
            picker.$emit('pick', [start, today]);
          }
        }, {
          text: '最近两个月',
          onClick (picker) {
            const start = DateUtil.getDayStr(-60);
            picker.$emit('pick', [start, today]);
          }
        }, {
          text: '最近1年',
          onClick (picker) {
            const start = DateUtil.getDayStr(-365);
            picker.$emit('pick', [start, today]);
          }
        }, {
          text: '最近2年',
          onClick (picker) {
            const start = DateUtil.getDayStr(-730);
            picker.$emit('pick', [start, today]);
          }
        }]
      },
      param: {
        // date : [DateUtil.getDayStr(DateUtil.getMonthFirstDay()), today],
        date: [DateUtil.getDayStr(-30), today],
        teamId: config.initTeam
      },
      list: []
    };
  },
  methods: {
    query () {
      const {date = [], teamId} = this.param;
      const param = {teamId, startDate: date[0], endDate: date[1] };
      getTeamDriverEvaRank(param).then(res=>{
        this.list = res.data || [];
      });
    },

    getSummaries (param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计：';
          return;
        }
        if (index === 0 || index === 2 || index === 3 || index === 4 || index === 5 || index === 6) {
          sums[index] = '';
          return;
        }

        const values = data.map(item => {
          const property = column.property;
          return Number(item[property]);
        });
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = parseFloat(sums[index]).toFixed(0);
        } else {
          sums[index] = 'N/A';
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped>
.avg {
  display: flex;
  margin-bottom: 20px;

  .item {
    display: flex;
    align-items: center;
    background: #ecf5ff;
    color: #409eff;
    justify-content: flex-start;
    width: 300px;
    padding: 20px;
    div {
      margin: 0 10px;
    }
  }
}

.iconfont {
  margin-left: 10px;
  opacity: 0.7;
}

.zan {
  color: red;
}
</style>