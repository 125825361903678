<template>
  <div class="box">
    <el-form ref="form" size="small" label-width="80px" :model="param" inline>
      <el-row>
        <el-col>
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.startDate"
              style="width: 200px"
              type="date"
              :clearable="false"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.endDate"
              style="width: 200px"
              type="date"
              :clearable="false"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车队：">
            <el-select v-model="param.teamId">
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 100px; margin-left: 60px" @click="query()"
              >查询</el-button
            >
          </el-form-item>

          <el-form-item style="float: right">
            <el-button type="primary" style="width: 120px" @click="calcModalVisibl = true"
              >生成财务账单</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="table">
      <el-table
        :data="teamList"
        stripe
        height="600"
        show-summary
        @row-click="showDetail"
        :summary-method="getSummaries"
      >
        <el-table-column prop="teamName" label="车队" width="60"></el-table-column>

        <el-table-column prop="period_start" label="周期" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.periodStart }}</div>
            <div>{{ scope.row.periodEnd }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="driverNum" label="司机数" width="70"></el-table-column>

        <el-table-column prop="num" label="成交人数" width="100"> </el-table-column>

        <el-table-column prop="routeNum" label="边数" width="60"></el-table-column>

        <el-table-column prop="totalBaseIncome" label="流水" width="90">
          <template slot-scope="scope">
            {{ scope.row.totalBaseIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncrease" label="加价流水" width="90">
          <template slot-scope="scope">
            {{ scope.row.totalIncrease | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncome" label="合计流水" width="90">
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalAheadDiscount" label="早价补贴">
          <template slot-scope="scope">
            {{ scope.row.totalAheadDiscount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="discount" label="优惠补贴" width="100">
          <template slot-scope="scope">
            {{ scope.row.discount | moneyFormat(0) }}
          </template>

          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="此优惠补贴包含早价补贴"
              placement="top-start"
            >
              <span
                >优惠补贴
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="subsidyAmount" label="保底补贴" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="此保底补贴未扣减司机提点"
              placement="top-start"
            >
              <span
                >保底补贴
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.subsidyAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="totalDiscount" label="合计补贴" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="合计补贴 = 优惠补贴+保底补贴"
              placement="top-start"
            >
              <span
                >合计补贴
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="avgDiscount" label="人均补贴" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="人均补贴 = 优惠补贴（合计补贴） / 成交人数"
              placement="top-start"
            >
              <span
                >人均补贴
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div>{{ scope.row.avgDiscountWhitSubsidy | moneyFormat }}</div>
            <div>{{ scope.row.avgDiscount | moneyFormat }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="avgIncome" label="人均收入" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="人均收入 = 合计流水 / 成交人数"
              placement="top-start"
            >
              <span
                >人均收入
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.avgIncome | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="profitRate" label="毛利润率" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="毛利润率 =毛收入 / 流水"
              placement="top-start"
            >
              <span
                >毛利润率
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.profitRate }}%</template>
        </el-table-column>

        <el-table-column prop="profitRate" label="净利润率" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="毛利润率 = 净收入 / 流水"
              placement="top-start"
            >
              <span
                >净利润率
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.pureProfitRate }}%</template>
        </el-table-column>

        <el-table-column prop="shuntFee" label="分流费用" width="100">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="参考应收分流费用/实际分流费用"
              placement="top-start"
            >
              <span
                >分流费用
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div>{{ scope.row.totalShuntFee | moneyFormat }}</div>
            <div>{{ scope.row.totalActShuntFee | moneyFormat }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="totalAskLeaveFee" label="超假费用" width="80">
          <template slot-scope="scope">
            {{ scope.row.totalAskLeaveFee | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="totalCommission" label="毛收入">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="毛收入即净抽成收入"
              placement="top-start"
            >
              <span
                >毛收入
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.totalCommission | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="sumIncome" label="净收入">
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="净收入 = 毛收入 - 优惠补贴"
              placement="top-start"
            >
              <span
                >净收入
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.sumIncome | moneyFormat }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="chart">
      <div>
        <chart-view
          ref="chart1"
          valueField="totalIncome"
          nameField="teamName"
          title="合计流水占比"
          initChartType="pie"
        />
      </div>
      <div>
        <chart-view
          ref="chart2"
          valueField="num"
          nameField="teamName"
          title="成交人数占比"
          initChartType="pie"
        />
      </div>
      <div>
        <chart-view
          ref="chart3"
          valueField="totalDiscount"
          nameField="teamName"
          title="合计补贴占比"
          initChartType="pie"
        />
      </div>
      <div>
        <chart-view
          ref="chart4"
          valueField="totalCommission"
          nameField="teamName"
          title="抽成收入占比"
          initChartType="pie"
        />
      </div>
    </div>

    <el-drawer size="1700px" :visible.sync="visible" :append-to-body="true" direction="ltr">
      <div slot="title">
        司机明细：
        <el-tag style="margin-right: 5px">
          {{ selectedItem.periodStart }}~{{ selectedItem.periodEnd }}
        </el-tag>
      </div>

      <div class="drawer-table">
        <el-table
          :data="driverList"
          stripe
          max-height="780"
          show-summary
          :summary-method="getDetailSummaries"
        >
          <el-table-column
            label="序号"
            type="index"
            show-overflow-tooltip
            width="50"
          ></el-table-column>

          <el-table-column prop="driverName" label="司机姓名"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号"></el-table-column>

          <el-table-column prop="commission" label="提点">
            <template slot-scope="scope"> {{ scope.row.commission / 10 }}% </template>
          </el-table-column>

          <el-table-column prop="num" label="成交人数"></el-table-column>

          <el-table-column prop="routeNum" label="边数" width="60"></el-table-column>

          <el-table-column prop="baseIncome" label="流水">
            <template slot-scope="scope">
              {{ scope.row.baseIncome | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="totalIncrease" label="加价流水">
            <template slot-scope="scope">
              {{ scope.row.totalIncrease | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="totalIncome" label="合计流水">
            <template slot-scope="scope">
              {{ scope.row.totalIncome | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="totalDiscount" label="优惠补贴">
            <template slot-scope="scope">
              {{ scope.row.totalDiscount | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="guaranteeSubsidy" label="保底补贴">
            <template slot-scope="scope">
              {{ scope.row.guaranteeSubsidy | moneyFormat }}
            </template>
          </el-table-column>

          <el-table-column prop="shuntFee" label="分流费用">
            <template slot-scope="scope">
              {{ scope.row.totalShuntFee | moneyFormat }} /
              {{ scope.row.totalActShuntFee | moneyFormat }}
            </template>
          </el-table-column>

          <el-table-column prop="askLeaveFee" label="超假费用">
            <template slot-scope="scope">
              {{ scope.row.askLeaveFee | moneyFormat }}
            </template>
          </el-table-column>

          <el-table-column prop="commissionAmount" label="抽成金额">
            <template slot-scope="scope">
              {{ scope.row.commissionAmount | moneyFormat(2) }}
            </template>
          </el-table-column>

          <el-table-column prop="totalPayable" label="本期应缴">
            <template slot-scope="scope">
              {{ scope.row.totalPayable | moneyFormat }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="adjustSettlement(scope.row)"
                >调整</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <el-dialog
      :visible.sync="calcModalVisibl"
      width="550px"
      :destroy-on-close="true"
      title="生成财务账单"
    >
      <el-form ref="form" :rules="rules" size="small" :model="form" label-width="120px">
        <el-form-item label="统计时间：" prop="date">
          <el-date-picker
            style="width: 100%"
            v-model="form.date"
            type="daterange"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="车队：" prop="teamId">
          <el-select
            v-model="form.teamId"
            clearable
            placeholder="全部"
            style="width: 100%"
            @change="handleTeamChange"
          >
            <el-option
              v-for="item in teamOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="计算类型：" prop="type">
          <el-select v-model="form.type" clearable placeholder="全部" style="width: 100%">
            <el-option
              v-for="item in calcTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="保底价格：" prop="basePrice">
          <el-input-number v-model="form.basePrice" style="width: 100%" />
        </el-form-item>

        <el-form-item label="超假扣费月份：" prop="askLeaveMonth">
          <el-date-picker
            style="width: 100%"
            v-model="form.askLeaveMonth"
            value-format="yyyy-MM-dd"
            type="month"
          >
          </el-date-picker>
        </el-form-item>

        <div class="red" style="font-size: 13px; margin: 0 0 0 50px">
          注意：超假扣费月份只能选择1个月之前的月份（例如7月份只能统计5月份及之前的月份），且注意不要重复统计
        </div>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="calcModalVisibl = false">取 消</el-button>
        <el-button type="primary" @click="calc" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="settlementVisible"
      width="550px"
      :destroy-on-close="true"
      title="修正财务账单"
    >
      <el-form
        ref="settlementForm"
        :rules="rules"
        size="small"
        :model="settlementForm"
        label-width="140px"
      >
        <el-form-item label="司机姓名：" prop="name">
          <el-input v-model="settlementForm.driverName" disabled style="width: 100%" />
        </el-form-item>

        <el-form-item label="司机编号：" prop="driverNo">
          <el-input v-model="settlementForm.driverNo" disabled style="width: 100%" />
        </el-form-item>

        <el-form-item label="分流费用（分）：" prop="totalActShuntFee">
          <el-input-number v-model="settlementForm.totalActShuntFee" style="width: 100%" />
        </el-form-item>

        <el-form-item label="超假扣费（分）：" prop="askLeaveFee">
          <el-input-number v-model="settlementForm.askLeaveFee" style="width: 100%" />
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="settlementVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSettlement" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  calcDriverSettlement,
  selectDetailOverviewByTeam,
  selectDetailOverviewByDriver,
  getSettlementOverview,
  updateDriverSettlement,
} from "@/api";
import { mapState } from "vuex";
import { DateUtil } from "@/util";
import ChartView from "../../ChartView";

export default {
  components: {
    ChartView,
  },
  props: {},
  created() {},
  mounted() {
    this.query();
  },
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teamOpts: (state) => state.teamOpts,
    }),
  },
  data() {
    return {
      param: {
        startDate: DateUtil.getDayStr(-100),
        endDate: DateUtil.getDayStr(0),
      },
      teamList: [],
      driverList: [],
      period: [],
      visible: false,
      calcModalVisibl: false,
      form: {},
      calcTypeOpts: [
        { value: 1, label: "保持往期提成比例" },
        { value: 2, label: "按服务分计算" },
      ],
      rules: {
        teamId: [{ required: true, message: "请选择车队", trigger: "change" }],
        date: [{ required: true, message: "请统计时间", trigger: "change" }],
        type: [{ required: true, message: "请选择计算类型", trigger: "change" }],
      },
      option: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },

        tooltip: {
          show: true,
        },
      },
      selectedItem: {},
      settlementVisible: false,
      settlementForm: {},
    };
  },
  methods: {
    query() {
      selectDetailOverviewByTeam(this.param).then((res) => {
        const { data = [] } = res.data || {};
        this.$refs.chart1.setData(data);
        this.$refs.chart2.setData(data);
        this.$refs.chart3.setData(data);
        this.$refs.chart4.setData(data);
      });

      getSettlementOverview(this.param).then((res) => {
        this.teamList = res.data || [];
      });
    },

    handleTeamChange(teamId) {
      const team = this.teamOpts.find((t) => t.id === teamId);
      this.$set(this.form, "basePrice", team.basePrice);
    },

    adjustSettlement(item) {
      this.settlementForm = { ...item };
      this.settlementVisible = true;
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：";
          return;
        }

        // 不需要除以100的字段
        const oriArr = ["driverNum", "num", "routeNum"];
        const values = data.map((item) => {
          const property = column.property;
          if (oriArr.indexOf(property) > -1) {
            return Number(item[property]);
          } else {
            return Number(item[property] / 100);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result.toFixed(2);
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },

    getDetailSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：";
          return;
        }

        const unValFields = ["driverName", "driverNo"];
        if (unValFields.includes(column.property)) {
          sums[index] = "N/A";
          return;
        }

        // 不需要除以100的字段
        const oriArr = ["num", "routeNum"];
        const values = data.map((item) => {
          const property = column.property;
          if (oriArr.indexOf(property) > -1) {
            return Number(item[property]);
          } else {
            return Number(item[property] / 100);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result.toFixed(2);
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },

    showDetail(item) {
      this.visible = true;
      const param = {
        startDate: item.periodStart,
        endDate: item.periodEnd,
        teamId: item.teamId,
      };
      this.selectedItem = item;
      selectDetailOverviewByDriver(param).then((res) => {
        this.driverList = res.data || [];
      });
    },

    calc() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { teamId, type, date, askLeaveMonth, basePrice } = this.form || {};
          const param = {
            teamId,
            type,
            startDate: date[0],
            endDate: date[1],
            askLeaveMonth,
            basePrice,
          };
          calcDriverSettlement(param).then(() => {
            this.$message.success("计算成功");
            this.calcModalVisibl = false;
          });
        }
      });
    },

    updateSettlement() {
      this.$refs.settlementForm.validate((valid) => {
        if (valid) {
          updateDriverSettlement(this.settlementForm).then(() => {
            this.$message.success("更新成功");
            this.settlementVisible = false;
            this.showDetail(this.selectedItem);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  .chart {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-template-rows: repeat(2, 300px);
    grid-row-gap: 40px;
    grid-column-gap: 4%;
    padding: 20px;

    > div {
      border: 1px solid #ddd;
    }
  }
}

.drawer-table {
  padding: 0 20px;
}

.period {
  max-width: 500px;
  overflow: auto;

  span {
    margin-right: 5px;
  }
}
</style>
