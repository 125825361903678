<template>
  <div class="box">
    <div ref="chart" class="chart" :style="{ height: `${height}}px` }"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    option: Object,
    onLoad: Function,
    height: {
      type: Number,
      default: 280
    },
  },
  created () {},
  mounted () {
    this.init();
  },
  computed: {},
  data () {
    return {

    };
  },
  methods: {
    init () {
      const chart = echarts.init(this.$refs.chart);
      this.chart = chart;
      chart.setOption(this.option);
      if (this.onLoad) {
        this.onLoad(chart);
      }
    },

    setOption (option, notMerge = false, lazyUpdate = false) {
      const newOpt = {...this.option, ...option};
      this.chart.setOption(newOpt, notMerge, lazyUpdate);
      this.chart.resize({ height: `${this.height - 20}px`});
    }

  },
};
</script>
<style lang="less" scoped>
.box {
  background: #fff;
  position: relative;
  width: 100%;

  .chart {
    width: 100%;
  }
}
</style>