<template>
<div class="root">
  <div class="select-area">
    <el-radio v-model="detailMode" label="audit">审批管理</el-radio>
    <el-radio v-model="detailMode" label="vehicle">车辆管理</el-radio>
  </div>

  <div v-if="detailMode=='audit'">
    <audit-manage :driverAdmin="driverAdmin"></audit-manage>
  </div>

  <div v-if="detailMode=='vehicle'">
    <vehicle-manage :driverAdmin="driverAdmin"></vehicle-manage>
  </div>
</div>
</template>

<script>
import auditManage from './auditManage';
import vehicleManage from './vehicleManage';
export default {
    name: "detailPage",
    props: {
        driverAdmin: {
            type: Object,
        },
    },
    data() {
        return {
            detailMode: "audit"
        };
    },
    components: {
        auditManage, vehicleManage
    }
};
</script>

<style scoped>

</style>