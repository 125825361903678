<template>
<div>
 <el-tabs v-model="activeName" @tab-click="changeTab">
   <el-tab-pane label="概况" name="gk"></el-tab-pane>
   <el-tab-pane label="明细" name="mx"></el-tab-pane>
 </el-tabs>

  <state-gk v-if="activeName=='gk'"></state-gk>
  <state-mx v-if="activeName=='mx'"></state-mx>
</div>
</template>

<script>
import stateGk from "./stateGk";
import stateMx from "./stateMx";

export default {
  name: "billStat",
  components: { stateGk, stateMx },
  data () {
    return {
      activeName: 'gk',
    };
  },
  mounted () {
  },
  methods: {
    changeTab (tab) {
      this.activeName = tab.name;
    },
  }
};
</script>

<style scoped>
</style>