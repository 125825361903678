import { Http } from "@/util";

export function querySettlement(param) {
  return Http.post("/admin/query/settlement", param);
}

// 查询本月流水概况
export function querySettlementOverview(param) {
  return Http.post("/admin/query/income/overview", param);
}

// 查询流水渠道分析
export function querySettlementSourceAnalysis(param) {
  return Http.post("/admin/query/income/dlsource", param);
}

// 查询流水渠道分析概況
export function querySettlementSourceOverviewAnalysis(param) {
  return Http.post("/admin/query/income/ovsource", param);
}

// 司机流水按车队汇总.
export function selectDetailOverviewByTeam(param) {
  return Http.get("/admin/query/team/income/detail", param);
}

// 司机流水按车队、周期汇总.
export function getSettlementOverview(param) {
  return Http.get("/admin/query/team/income/detail/list", param);
}

// 司机流水明细
export function selectDetailOverviewByDriver(param) {
  return Http.get("/admin/query/driver/income/detail", param);
}

// 查询司机流水明细
export function getTeamDriverBillDetail(param) {
  return Http.get("/admin/query/team/bill/detail", param);
}

// 查询司机服务分排名
export function getTeamDriverEvaRank(param) {
  return Http.get("/admin/query/team/eva/rank", param);
}

// 查询用户留存
export function getPassengerRetain(param) {
  return Http.get("/admin/analysis/passenger/retain", param);
}

// 查询订单统计
export function getOrderAnalysis(param) {
  return Http.post("/admin/analysis/order/statistics", param);
}

// 查询常用下单地址
export function getCommonAddress(param) {
  return Http.post("/admin/common/address/list", param);
}

// 乘客时间行为分析
export function getTimeAnalysis(param) {
  return Http.post("/admin/analysis/passenger/time", param);
}

// 乘客增长转化分析
export function passengerTransAnalysis(param) {
  return Http.post("/admin/analysis/passenger/trans", param);
}

// 仅登录分析
export function justLoginAnalysis(param) {
  return Http.post("/admin/analysis/just_login", param);
}

// 派单后查看订单分析
export function viewMyOrderAnalysis(param) {
  return Http.post("/admin/analysis/view_my_order", param);
}

// 优惠概况
export function discountSummary(param) {
  return Http.post("/admin/analysis/discount/summary", param);
}

// 优惠种类分析
export function discountTypeAnalysis(param) {
  return Http.post("/admin/analysis/discount/type", param);
}

// 定项优惠分析
export function specAnalysis(param) {
  return Http.post("/admin/analysis/spec", param);
}

// 发券分析
export function sendCouponAnalysis(param) {
  return Http.post("/admin/analysis/send_coupon", param);
}

// 邀请分析
export function getCouponInfoList(param) {
  return Http.post("/admin/passenger/invite/coupon_info/list", param);
}

// 邀请记录
export function getInviteList(passengerId) {
  return Http.get("/admin/passenger/invite/list", { passengerId });
}

// 邀请记录
export function getPageInviteList(param) {
  return Http.post("/admin/passenger/invite/page/list", param);
}

// 标记风险用户
export function flagPassengerCouponInfo(param) {
  return Http.post("/admin/passenger/invite/flag/coupon_info", param);
}

// 查询邀请问题
export function getPassengerInviteQuestion(param) {
  return Http.post("/admin/passenger/invite/question/list", param);
}

// 新增修改邀请问题
export function saveOrUpdatePassengerInviteQuestion(param) {
  return Http.post("/admin/passenger/invite/question/save_update", param);
}

// 删除邀请问题
export function delPassengerInviteQuestion(param) {
  return Http.post("/admin/passenger/invite/question/del", param);
}

// 查询路程时长
export function getRouteDuration(param) {
  return Http.post("/admin/analysis/route/duration", param);
}
