<template>
    <div class="box-container">
        <div class="header">
            <el-form ref="form" size="small" :model="param" inline>
                <el-form-item label="月份：" prop="settlementMonth">
                    <el-date-picker v-model="param.date" @change="query()" style="width: 160px;margin-right:8px;"
                        type="month" :clearable="true" placeholder="选择日期"></el-date-picker>
                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="autoSet()">自动设置节假日</el-button>
                </el-form-item>

                <el-form-item>
                    &nbsp;&nbsp;&nbsp;当前每日最大请假人数：<span class="red">{{ maxLeaveNum }}</span>人
                </el-form-item>

            </el-form>
        </div>



        <el-calendar v-model="param.date">

            <template slot="dateCell" slot-scope="{date, data}">
                <div v-if="calendarMap[data.day]" @dblclick="setting(date, data)" class="cell"
                    :class="{ focus: calendarMap[data.day].isHoliday || calendarMap[data.day].leaveNum > maxLeaveNum }">
                    <div class="box1">
                        <span>
                            {{ data.day.split('-').slice(1).join('-') }}
                        </span>
                        <div>
                            <el-dropdown @command="(type)=>setting(data, type)">
                                <el-button  size="mini">
                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="1">{{ calendarMap[data.day].isHoliday ? '取消节假日' : '设为节假日'
                                    }}</el-dropdown-item>
                                    <el-dropdown-item :command="2">{{ calendarMap[data.day].isHighRoadFree ? '取消高速免费' : '设为高速免费'
                                    }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="box1">

                        <div class="red">
                            <span
                                v-if="calendarMap[data.day].isHoliday && calendarMap[data.day].isHighRoadFree">节假日/高速免费</span>
                            <span v-else-if="calendarMap[data.day].isHighRoadFree">高速免费</span>
                            <span v-else-if="calendarMap[data.day].isHoliday">节假日</span>
                        </div>
                        <div v-if="calendarMap[data.day].leaveNum" :class="{ red: calendarMap[data.day].leaveNum > maxLeaveNum }">
                            请假{{ calendarMap[data.day].leaveNum }}人
                        </div>
                    </div>
                </div>
            </template>
        </el-calendar>
    </div>
</template>
<script>
import { DateUtil } from '@/util';
import { getCalendarInfo, setCalendarInfo, autoSetHoliday } from '@/api';

export default {
  components: {

  },

  props: {

  },

  watch: {
    'param.date': {
      handler (newVal, oldVal) {
        // 如果是不通月份的话，重新查询
        if (!oldVal || newVal.getMonth() !== oldVal.getMonth()) {
          this.query();
        }

      },
      deep: true
    }
  },

  created () {

  },

  mounted () {
    this.query();
  },

  computed: {

  },

  data () {
    return {
      param: {
        date: DateUtil.getDateTime()
      },
      calendarMap: {},
      visible1: false,
      visible2: false,
      maxLeaveNum: 3
    };
  },

  methods: {


    query () {
      getCalendarInfo(DateUtil.format(this.param.date)).then(res => {
        const {dateMap, maxLeaveNum} = res.data || {};
        this.calendarMap = dateMap || {};
        this.maxLeaveNum = maxLeaveNum;
      });
    },


    setting (data, type) {
      const currentIsSet = type === 1 ? this.calendarMap[data.day].isHoliday : this.calendarMap[data.day].isHighRoadFree;
      this.$confirm(`确定要进行当前操作吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          date: data.day,
          type: type,
          isSet: !currentIsSet
        };

        setCalendarInfo(params).then(() => {
          this.$message.success("设置成功");
          this.query();
        });
      });
    },

    autoSet () {

      const year = this.param.date.getFullYear();
      this.$confirm(`确定要自动设置${year}年的节假日吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        autoSetHoliday(year).then(() => {
          this.$message.success("设置成功");
          this.query();
        });
      });
    }
  },

};
</script>
<style scoped lang="less">
:deep(.el-calendar-day) {
    padding: 1px;
}


.box-container {
    position: relative;

    .header {
        background-color: #fff;
        position: absolute;
        top: 8px;
        left: 20px;
    }

    .cell {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 8px;


        &.focus {
            background-color: rgba(238, 94, 94, 0.1);
        }

        .box1 {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            align-items: center;
        }
    }
}
</style>
