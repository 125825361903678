import Vue from "vue";
import { Notification } from "element-ui";
import router from "@/route";
import { CHANGE_RUIMA_QUEUE, SHOW_ROUTE_ALARM_MODAL } from "@/core/const/global-event";
import EventBus from "@/core/event/event-bus";
import { LocalStorage } from "@/util";
import store from "@/store";
import { ROUTE_CHECK_INFO } from "@/store/mutation-types";

export class PushNoticeService {
  static handlePushNotice(wsData) {
    const type = wsData.type;
    const data = wsData.data || {};
    const pathFilter = Vue.options.filters["pathNameTransfer"];
    const encryptTel = Vue.options.filters["encryptTel"];
    const orderSourceOpts = store.state.enumMap["ORDER_SOURCE"];

    const silent = LocalStorage.get("silent");

    // type 33为行程检查，不用这个提示音
    if (!silent && $("#video") && type != 33) {
      if ($("#video")[0]) {
        $("#video")[0].volume = 0.1;
      }
      $("#video").trigger("play");
    }

    if (type == 1) {
      const { passengerTel } = JSON.parse(data);
      const tel = encryptTel(passengerTel);

      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "报警提醒",
        message: `接到乘客（${tel}）报警，请及时处理`,
        type: "warning",
      });
      router.push({
        path: "/order/alarm",
      });
    } else if (type == 2) {
      let { tel } = JSON.parse(data);
      tel = encryptTel(tel);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "开票提醒",
        message: `接到乘客（${tel}）开票请求`,
        type: "info",
      });
    } else if (type == 3) {
      const { dateTime, pathName, lackNum } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "缺车报警",
        message: `${dateTime} ${pathName} 缺${lackNum}坐，请关注车票预订情况`,
        type: "error",
      });
    } else if (type == 5) {
      const { driverNo, pathId, date, showTime, tel, value } = JSON.parse(data);

      if (value == 2) {
        const scrTel = encryptTel(tel);
        const pathName = pathFilter(pathId);
        Notification({
          position: "bottom-right",
          customClass: "push-msg",
          duration: 0,
          title: "司机申请获取乘客实时定位",
          message: `${date} ${showTime} ${pathName}，司机【${driverNo}】申请获取乘客【${scrTel}】的实时定位`,
          type: "warning",
        });
      } else if (value == 3) {
        const pathName = pathFilter(pathId);
        Notification({
          position: "bottom-right",
          customClass: "push-msg",
          duration: 0,
          title: "司机申请分流用车",
          message: `${date} ${showTime} ${pathName}，司机【${driverNo}】申请分流用车`,
          type: "warning",
        });
      }
    } else if (type == 6) {
      const { driverName, passengerTel, detail, orderId } = JSON.parse(data);
      const scrTel = encryptTel(passengerTel);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "乘客投诉司机",
        message: `乘客【${scrTel}】因为 ${detail}投诉了司机【${driverName}】，订单id：【${orderId}】`,
        type: "warning",
      });
    } else if (type == 7) {
      const { pathId, telephone, date, showTime, orderSource, orderType, isBackup } =
        JSON.parse(data);
      const scrTel = encryptTel(telephone);
      const pathName = pathFilter(pathId);
      // 非微信单显示渠道
      const source =
        orderSource === 1 ? "" : orderSourceOpts.find((i) => i.value === orderSource).label;

      let title = "乘客下单";
      if (orderSource === 3) {
        if (orderType === 3) {
          title = "乘客包车【小程序】";
        } else {
          title = "乘客下单【小程序】";
        }
      } else if (orderSource === 4) {
        if (orderType === 3) {
          title = "乘客包车【APP】";
        } else {
          title = "乘客下单【APP】";
        }
      } else if (orderSource === 8) {
        title = "司机代下";
      } else if (orderSource === 5) {
        title = "代理下单";
      } else if (orderSource === 1) {
        title = "乘客下单【微信】";
      } else {
        title = `客服下单【${source}】`;
      }
      if (isBackup) {
        title += "【候补票】";
      }

      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title,
        message: `乘客【${scrTel}】预订了 ${pathName} ${date} ${showTime} 的车票`,
        type: "success",
      });
    } else if (type == 8) {
      const { changeType, order } = JSON.parse(data);
      const { pathId, telephone, date, showTime } = order;
      const pathName = pathFilter(pathId);
      const scrTel = encryptTel(telephone);
      const isRefund = changeType === "退票";
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: isRefund ? "乘客退票" : "乘客改签",
        message: `乘客【${scrTel}】${pathName} ${date} ${showTime} 的车票进行了【${changeType}】操作`,
        type: isRefund ? "error" : "warning",
      });
    } else if (type == 10) {
      const { driverNo, tel, pathId, date, showTime } = JSON.parse(data);
      const scrTel = encryptTel(tel);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "司机未及时联系乘客",
        message: `司机【${driverNo}】没有及时联系【${scrTel}】的乘客，订单信息${pathName} ${date} ${showTime}，请及时处理`,
        type: "warning",
      });
    } else if (type == 11) {
      // 切换队列
      const { isDay } = JSON.parse(data);
      EventBus.$emit(CHANGE_RUIMA_QUEUE, isDay);
    } else if (type == 12) {
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "没有在线客服",
        message: `当前没有在线微信客服，请注意！！！`,
        type: "error",
      });
    } else if (type == 13) {
      const { orderId, failType } = JSON.parse(data);
      let op = "";
      if (failType === 2) {
        op = "改签操作";
      }
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "用户支付超时",
        message: `用户支付超时，订单【${orderId}】${op}已被系统取消，请手动处理`,
        type: "error",
      });
    } else if (type == 16) {
      const { name } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "司机提交了注册信息",
        message: `【${name}】提交了注册信息，请及时审核`,
        type: "success",
      });
    } else if (type == 17) {
      const { driverNo, telephone, pathId, showTime } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "系统自动派单",
        message: `${pathName} ${showTime} 【${telephone}】分派给司机 【${driverNo}】`,
        type: "info",
      });
    } else if (type == 18) {
      const { driverNo, isAskLeave, date, pathId, isAutoFinish } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      const str = isAutoFinish ? "自动销假" : "销假";
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: isAskLeave ? "司机请假" : "司机销假",
        message: isAskLeave
          ? `${pathName} ${date} 司机【${driverNo}】请假`
          : `${pathName} ${date} 司机【${driverNo}】${str}`,
        type: "info",
      });
    } else if (type == 20) {
      const { msg } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "未录入分流金额",
        message: msg,
        type: "info",
      });
    } else if (type == 21) {
      const { no } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: `司机发起申诉`,
        message: `司机【${no}】发起申诉，请及时处理`,
        type: "warning",
      });
    } else if (type == 22) {
      const { driverNo, content, type, date, showTime, pathId, tel, orderIndex } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      const scrTel = encryptTel(tel);
      if (type == 1) {
        Notification({
          position: "bottom-right",
          customClass: "push-msg",
          duration: 0,
          title: "司机申请获取乘客实时定位",
          message: `${date} ${showTime} ${pathName}，司机【${driverNo}】申请获取乘客【${scrTel}】的实时定位`,
          type: "warning",
        });
      } else if (type == 100) {
        Notification({
          position: "bottom-right",
          customClass: "push-msg",
          duration: 0,
          title: "司机申请分流用车",
          message: `${date} ${showTime} ${pathName}，司机【${driverNo}】申请分流用车`,
          type: "warning",
        });
      } else {
        Notification({
          position: "bottom-right",
          customClass: "push-msg",
          duration: 0,
          title: `司机【${driverNo}】订单反馈`,
          message: `${pathName} ${showTime} 手机【${scrTel}】${orderIndex + 1}号单，${content}`,
          type: "warning",
        });
      }
    } else if (type == 23) {
      const { driverNo, showTime, pathId } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "订单未确认行程规划",
        message: `${pathName} ${showTime} 司机【${driverNo}】的行程规划未确认`,
        type: "warning",
      });
    } else if (type == 24) {
      // 获取当前小时
      const hour = new Date().getHours() + 2;
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "自动转正提醒",
        message: data instanceof String ? data : `${hour}：00的预派单系统已自动转正`,
        type: data ? "warning" : "info",
      });
    } else if (type == 25) {
      const { driverNo, showTime, pathId } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "分流提醒",
        message: `司机【${driverNo}】${pathName} ${showTime}的行程需要安排分流`,
        type: "info",
      });
    } else if (type == 26) {
      const { userType, userTel } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "收到意见反馈",
        message: userType === "driver" ? `收到司机意见反馈` : `收到乘客【${userTel}】意见反馈`,
        type: "info",
      });
    } else if (type == 28) {
      const { pathId, showTime, status } = JSON.parse(data);
      const isSuccess = status === 1;
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: isSuccess ? "派单规划完成" : "派单规划失败",
        message: `${pathName}  ${showTime} 规划任务${isSuccess ? "完成" : "失败"}`,
        type: isSuccess ? "success" : "error",
      });
    } else if (type == 29) {
      const { driverNo, msg } = JSON.parse(data);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "语音提醒失败",
        message: ` 司机【${driverNo}】 ${msg}`,
        type: "error",
      });
    } else if (type == 30) {
      const dataMap = JSON.parse(data);
      EventBus.$emit(SHOW_ROUTE_ALARM_MODAL, dataMap);
    } else if (type == 31) {
      const { driverNo, stopTime, pathId, routeTime } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "司机长时间停车",
        message: `司机【${driverNo}】在【${pathName} ${routeTime}】的行程，当前已停车${stopTime}分钟，请关注`,
        type: "warning",
      });
    } else if (type == 32) {
      const { pathId, telephone, date, showTime } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      const scrTel = encryptTel(telephone);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "订单未分派",
        message: `乘客【${scrTel}】 ${pathName} ${date} ${showTime} 的订单还未分派司机`,
        type: "warning",
      });
    } else if (type == 33) {
      store.commit(ROUTE_CHECK_INFO, data.maxWarningLevel ? JSON.parse(data) : {});
    } else if (type == 34) {
      const { pathId, driverNo } = JSON.parse(data);
      const pathName = pathFilter(pathId);
      Notification({
        position: "bottom-right",
        customClass: "push-msg",
        duration: 0,
        title: "高速路口调整",
        message: `【${driverNo}】 ${pathName} 的行程高速路口已调整，请关注`,
        type: "warning",
      });
    }
  }
}
