<template>
  <div class="root-box">
    <div class="btns">
      <el-button type="primary" @click="setMenu">设置微信菜单</el-button>
      <el-button type="primary" @click="showJson">显示JSON</el-button>
      <el-button type="primary" @click="setJson(false)">老菜单</el-button>
      <el-button type="primary" @click="setJson(true)">新菜单</el-button>
    </div>

    <div class="content">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入内容"
        v-model="json"
      >
      </el-input>

      <div id="json"></div>
    </div>
  </div>
</template>

<script>

import {creatWxMenu} from '@/api';

export default {
  components: {},
  props: {},
  created () {},
  mounted () {
    this.json = this.menuJson;
  },
  watch: {},
  computed: {},
  data () {
    return {
      json: '{}',
      menuJson: '{"button":[{"type":"miniprogram","name":"我要叫车","url":"http://mp.weixin.qq.com","appid":"wxc6d4616783492122","pagepath":"pages/pre-loading"},{"type":"miniprogram","name":"我的行程","url":"http://mp.weixin.qq.com","appid":"wxc6d4616783492122","pagepath":"pages/pre-loading?redirect=CurrentOrder"},{"name":"APP下载","sub_button":[{"type":"click","name":"在线客服","key":"btn_custom_help"},{"type":"view","name":"司机注册","url":"https://pub.youxuanshunfengche.com/driver"},{"type":"view","name":"乘客APP","url":"https://pub.youxuanshunfengche.com/"}]}]}',
      rollBackJson: '{"button":[{"type":"miniprogram","name":"我要叫车","url":"http://mp.weixin.qq.com","appid":"wxc6d4616783492122","pagepath":"pages/pre-loading"},{"type":"miniprogram","name":"我的行程","url":"http://mp.weixin.qq.com","appid":"wxc6d4616783492122","pagepath":"pages/pre-loading?redirect=CurrentOrder"},{"name":"APP下载","sub_button":[{"type":"click","name":"在线客服","key":"btn_custom_help"},{"type":"view","name":"司机注册","url":"https://pub.youxuanshunfengche.com/driver"},{"type":"view","name":"乘客APP","url":"https://pub.youxuanshunfengche.com/"}]}]}'
    };
  },
  methods: {
    setMenu () {
      this.$prompt('请输入菜单JSON', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(({ value = "" }) => {
        creatWxMenu(value).then(()=>{
          this.$message.success("调用成功");
        });
      }).catch(() => {});
    },

    showJson () {
      $('#json').JSONView(this.json);
    },
    setJson (isNew) {
      if (isNew) {
        this.json = this.menuJson;
      } else {
        this.json = this.rollBackJson;
      }

    }
  },
};
</script>
<style lang="less" scoped>
.btns {
  margin: 10px;

  .content {
    margin: 20px 10px;
  }
}
</style>