<template>
  <div class="violate">
    <el-tabs v-model="activeIndex" @tab-click="handleSelect">
      <el-tab-pane label="待发送的处罚" name="ref1">
        <bill-list ref="ref1" state="1"></bill-list>
      </el-tab-pane>

      <el-tab-pane label="已发送的处罚" name="ref2">
        <bill-list ref="ref2" state="3" @edit="viewPunishBill"></bill-list>
      </el-tab-pane>

      <el-tab-pane label="处罚统计" name="ref3">
        <bill-stat ref="ref3"></bill-stat>
      </el-tab-pane>
    </el-tabs>

    <el-button class="violateButton" size="small" @click="newPunishBill()">开罚单</el-button>

    <el-dialog :visible.sync="showDocxPannel" width="1100px">
      <div v-for="doc in docxContent" :key="doc.id" class="doc-container">
        <div class="title">{{ doc.seq }}. {{ doc.title }}</div>
        <div class="content">{{ doc.content }}</div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showExamPannel" width="628px">
      <ItemView
        v-for="(exam, indx) in exams"
        :key="exam.id"
        :sno="indx + 1"
        :paperItm="exam"
        :preview="true"
      ></ItemView>
    </el-dialog>

    <el-dialog :visible.sync="showPunishBillInfoDialog" width="1100px">
      <el-container>
        <el-main class="border billinfo-container" style="border-right: none">
          <div>编号：{{ punishBill.code }}</div>
          <div class="row title">罚单预览</div>

          <div class="content row">
            <div class="left">
              <div class="left-span row">
                <div class="item">
                  <span style="font-weight: 600">司机：</span> {{ driverInfo.no }}
                </div>
                <div class="item">{{ driverInfo.name }} {{ driverInfo.teamName }}</div>
                <div class="item">
                  {{ driverInfo.seats + "座" + driverInfo.vehicleColor + driverInfo.brand }}
                </div>
                <div class="item">{{ driverInfo.telephone }} {{ driverInfo.registerTime }}注册</div>
              </div>

              <div class="left-span row">
                <div class="item">违规日期：{{ punishBill.violateDate }}</div>
                <div class="item">违规线路：{{ punishBill.pathName }}</div>
              </div>

              <div class="left-span row">
                <div class="item">违规订单：{{ punishBill.orderIndex }}号订单</div>
                <div class="item">违规来源：{{ punishBill.violateSrcName }}</div>
              </div>
            </div>

            <div class="right">
              <div class="right-span row">
                <div class="item">
                  有违规证据：
                  <div
                    v-for="annex in punishBill.annexes"
                    :key="annex"
                    class="media-box"
                    :class="isAudio(annex) ? 'media-box-audio' : ''"
                  >
                    <img v-if="isImage(annex)" :src="annex" class="itm-image" />
                    <i
                      class="el-icon-video-play"
                      v-if="isVedio(annex)"
                      style="font-size: 28px"
                      :src="annex"
                    ></i>
                    <audio
                      v-if="isAudio(annex)"
                      controls
                      style="width: 120px; height: 28px"
                    ></audio>
                  </div>
                </div>
                <div class="item">处罚结果：{{ punishBill.punishResult }}</div>
                <div class="item">
                  <span style="display: inline-block" class="violateCommonElement"
                    >违规文档依据: <el-link @click="openTrainingDocx">详情</el-link></span
                  >
                  <span>违规题库依据: <el-link @click="openExams">详情</el-link></span>
                </div>
              </div>

              <div class="right-span row">
                <div class="item">违规大类: {{ punishBill.violateClassify1Name }}</div>
                <div class="item">违规小类: {{ punishBill.violateClassify2Name }}</div>
                <div class="item">有无申诉：{{ punishBill.appealed ? "有" : "无" }}</div>
              </div>

              <div class="right-span row">
                <div class="item">罚单开单时间：{{ punishBill.createTime }}</div>
                <div class="item">罚单编辑时间：{{ punishBill.updTime }}</div>
                <div class="item">罚单发送状态：{{ getStateName(punishBill.state) }}</div>
                <div class="item">
                  开单人：{{ punishBill.cbyName }} 编辑人：{{ punishBill.updName }} 发送人{{
                    punishBill.sendByName
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">处罚备注：{{ punishBill.remark }}</div>

          <div class="row">处罚备注：{{ punishBill.bz }}</div>

          <div class="row bottom">
            <el-button @click="showPunishBillInfoDialog = false">关闭</el-button>
          </div>
        </el-main>
        <el-aside width="300px" class="border">
          <div class="appeal-container">
            <div class="title">司机申诉</div>
            <div class="appeal-record" v-if="!appealedRecords || !appealedRecords.length">
              暂无申诉
            </div>
            <div v-else>
              <div class="appeal-record" v-for="(record, i) in appealedRecords" :key="record.id">
                <div>
                  {{ i + 1 }}. {{ record.createTime }}
                  {{
                    !record.adminResponse.length
                      ? "申诉待回复"
                      : record.adminResponse[0].state == "-1"
                      ? "申诉不成立"
                      : "申诉成功"
                  }}
                </div>
                <div class="content">
                  <div>{{ record.content }}</div>
                  <div v-if="record.annexes && record.annexes.length">
                    申诉附件：
                    <div
                      v-for="annex in record.annexes"
                      :key="annex"
                      class="media-box"
                      :class="isAudio(annex) ? 'media-box-audio' : ''"
                    >
                      <img v-if="isImage(annex)" :src="annex" class="itm-image" />
                      <i
                        class="el-icon-video-play"
                        v-if="isVedio(annex)"
                        style="font-size: 28px"
                        :src="annex"
                      ></i>
                      <audio
                        v-if="isAudio(annex)"
                        controls
                        style="width: 120px; height: 28px"
                      ></audio>
                    </div>
                  </div>
                </div>
                <div v-for="r in record.adminResponse" :key="r.id" class="content">
                  <div>{{ r.content }}</div>
                  <div v-if="r.annexes && r.annexes.length">
                    回复附件：
                    <div
                      v-for="annex in r.annexes"
                      :key="annex"
                      class="media-box"
                      :class="isAudio(annex) ? 'media-box-audio' : ''"
                    >
                      <img v-if="isImage(annex)" :src="annex" class="itm-image" />
                      <i
                        class="el-icon-video-play"
                        v-if="isVedio(annex)"
                        style="font-size: 28px"
                        :src="annex"
                      ></i>
                      <audio
                        v-if="isAudio(annex)"
                        controls
                        style="width: 120px; height: 28px"
                      ></audio>
                    </div>
                  </div>
                  <div>回复人：{{ r.adminName }}, 回复时间：{{ r.createTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import billList from "./billList";
import billStat from "./billStat";
import ItemView from "../../Exam/admin/editor/ItemView";
import { mapState } from "vuex";
import { queryAppealRecords, savePunishBill, viewDocxOrExam, getRouteList } from "@/api";
import { SHOW_PUNISH_DIAG } from "@/core/const/global-event";
import { FileUtil } from "@/util";

export default {
  name: "punishBill",
  components: { ItemView, billList, billStat },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teams: (state) => state.teamOpts,
      paths: (state) => state.pathList,
      violateSrcOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
      violateClassify1Opts: (state) => state.enumMap["DRIVER_VIOLATE_TYPE"],
      violateClassify2Opts: (state) => state.enumMap["DRIVER_VIOLATE_OPT_TYPE"],
    }),
  },

  mounted() {
    this.handleSelect();
  },

  data() {
    return {
      activeIndex: "ref1",
      showPunishBillDialog: false,
      showPunishBillInfoDialog: false,
      showDocxPannel: false,
      showExamPannel: false,
      //下拉选项
      orderOpts: [],
      routeOpts: [],
      filterClassify2Opts: [],
      evidenceOpts: [
        { value: "1", label: "有" },
        { value: "0", label: "无" },
      ],
      //下拉选项
      driverInfo: {},
      punishInfo: {},
      punishBill: {
        driverId: null,
        driverNo: null,
        teamId: null,
        violateDate: null,
        violateTime: null,
        pathId: null,
        pathName: null,
        routeId: null,
        orderId: null,
        violateSrc: null,
        violateClassify1: null,
        violateClassify2: null,
        evidence: null,
        annexes: [],
        punishResult: null,
        remark: null,
        bz: null,
        ljBillCnt: 0,
        evaluationId: null,
        state: "1",
      },
      docxContent: [],
      exams: [],
      appealedRecords: [],
      refresh: true,
    };
  },

  methods: {
    getStateName(state) {
      switch (state) {
        case "1":
          return "暂存";
        case "2":
          return "未发送";
        case "3":
          return "发送中";
        case "4":
          return "已发送";
        case "5":
          return "申诉中";
        case "6":
          return "申诉已处理";
        case "8":
          return "已确认";
        case "9":
          return "已免责";
      }
      return "";
    },

    handleSelect() {
      this.$refs[this.activeIndex].query && this.$refs[this.activeIndex].query();
    },

    transfer2PunishBill: async function (evaluation) {
      let punishBill = {};
      punishBill.driverId = evaluation.driverId;
      punishBill.driverNo = evaluation.driverNo;
      punishBill.teamId = evaluation.teamId;
      punishBill.violateDate = evaluation.data;
      punishBill.pathName = evaluation.pathName;
      punishBill.pathId = evaluation.pathId;
      punishBill.routeId = evaluation.routeId;
      punishBill.orderId = evaluation.orderId;
      punishBill.orderIndex = evaluation.orderIndex;

      if (evaluation.channel) {
        punishBill.violateSrc = parseInt(evaluation.channel);
      }
      punishBill.annexes = evaluation.annexes;
      if (punishBill.annexes) {
        punishBill.evidence = "1";
      }
      punishBill.evaluationId = evaluation.id;
      punishBill.violateDate = evaluation.date;
      punishBill.appealed = evaluation.appealed;
      if (evaluation.punishType) {
        let ds = evaluation.punishType.split(",");
        punishBill.violateClassify1 = ds[0];
        punishBill.violateClassify2 = ds[1];
      }
      return punishBill;
    },

    newPunishBill(evaluation = {}) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, evaluation);
    },

    viewPunishBill(bill) {
      this.punishBill = bill;
      this.queryRoutes();
      this.punishBill.evidence = bill.annexes && bill.annexes.length ? "1" : "0";
      this.showPunishBillInfoDialog = true;
      this.appealedRecords = [];
      queryAppealRecords({ billId: bill.id }).then((res) => {
        let datas = res.data || [];
        let records = [];
        for (var i = 0; i < datas.length; i++) {
          var record = datas[i];
          if (!record.response) {
            record.adminResponse = [];
            records.push(record);
          } else {
            records[records.length - 1].adminResponse.push(record);
          }
        }
        this.appealedRecords = records.reverse();
      });
    },
    savePunishBill(state) {
      if (this.punishBill.state == "2" && state == "1") {
        this.$message.error("罚单已保存过，不可再暂存。");
        return;
      }
      this.punishBill.state = state;
      this.punishBill.teamId = this.driverInfo.team;
      this.punishBill.seat = this.driverInfo.seats;
      let data = JSON.parse(JSON.stringify(this.punishBill));
      let added = !this.punishBill.id;
      savePunishBill(data).then((res) => {
        this.showPunishBillDialog = false;
        this.$message.success("保存成功。");
        if (added) {
          this.$bus.$emit("punishbillCreated", res.data);
        }
      });
    },

    queryRoutes() {
      if (this.punishBill.violateDate && this.punishBill.pathId && this.punishBill.driverId) {
        let params = {
          date: this.punishBill.violateDate,
          pathId: this.punishBill.pathId,
          driverId: this.punishBill.driverId,
        };
        getRouteList(params).then((res) => {
          this.routeOpts = res.data || [];
          if (this.routeOpts.length == 1) {
            this.punishBill.routeId = this.routeOpts[0].value;
          } else {
            var exists = false;
            for (var i = 0; i < this.routeOpts.length; i++) {
              if (this.routeOpts[i].value == this.routeOpts.orderId) {
                exists = true;
              }
            }
            if (!exists) {
              this.punishBill.routeId = null;
            }
          }
        });
      } else {
        this.routeOpts = [];
        this.punishBill.routeId = null;
      }
    },
    queryOrders() {},
    isImage(src) {
      return FileUtil.isImage(src);
    },
    isVedio(src) {
      return FileUtil.isVedio(src);
    },
    isAudio(src) {
      return FileUtil.isAudio(src);
    },

    addAnnex(response, src) {
      let annexes = this.punishBill.annexes || [];
      annexes.push(src);
      this.punishBill.annexes = annexes;
      this.refresh = false;
      setTimeout(() => {
        this.refresh = true;
      }, 100);
    },

    removeAnnex(src) {
      let annexes = this.punishBill.annexes || [];
      for (var i = 0; i < annexes.length; i++) {
        if (src == annexes[i]) {
          annexes.splice(i, 1);
          break;
        }
      }
    },
    evidenceChange(val) {
      if (val == "0") {
        this.punishBill.annexes = [];
      }
    },
    openTrainingDocx() {
      if (!this.docxContent || !this.docxContent.length) {
        this.queryDocxOrExams();
      }
      this.showDocxPannel = true;
    },
    openExams() {
      if (!this.exams || !this.exams.length) {
        this.queryDocxOrExams();
      }
      this.showExamPannel = true;
    },
    queryDocxOrExams() {
      viewDocxOrExam({
        violateClassify1: this.punishBill.violateClassify1,
        violateClassify2: this.punishBill.violateClassify2,
      }).then((res) => {
        this.docxContent = res.data.docx || [];
        this.exams = res.data.exams || [];
      });
    },
  },
};
</script>

<style lang="less">
.violate {
  padding: 10px;

  .violateButton {
    position: absolute;
    top: 60px;
    right: 20px;
  }

  .row {
    margin-bottom: 10px;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  .violateCommonElement {
    width: 140px;
    margin-right: 10px;
  }

  .border {
    border: 1px solid #eee;
  }

  .media-box {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    vertical-align: top;
    position: relative;
  }

  .media-box img {
    width: 100%;
    height: 100%;
  }

  .media-box-audio {
    width: 120px !important;
  }

  .icon-del {
    position: absolute;
    right: -8px;
    top: -6px;
  }

  .el-upload-dragger {
    width: 40px;
    height: 40px;

    .el-icon-plus {
      margin-top: 10px;
    }
  }

  .violate-preview-container {
    .note {
      padding: 20px;
      line-height: 32px;
      border-bottom: 1px solid #eee;
    }

    .violate-preview {
      padding: 20px;

      .content {
        text-indent: 28px;
        margin-top: 20px;
      }

      .tip {
        font-weight: 600;
      }
    }
  }

  .appeal-container {
    .title {
      padding-top: 16px;
    }

    .appeal-record {
      padding-left: 12px;
      margin-top: 12px;
      line-height: 28px;
      border-top: 1px solid #eee;
    }
  }

  .billinfo-container {
    .content {
      display: flex;

      .left,
      .right {
        flex: 1;
      }

      .left {
        margin-right: 20px;
      }
    }

    .bottom {
      text-align: center;
    }
  }

  .doc-container {
    margin-bottom: 10px;

    .title {
      font-weight: 600;
      text-align: left;
      font-size: 16px;
    }

    .content {
      text-indent: 28px;
    }
  }
}
</style>
