<template>
  <div class="leave">
    <el-row class="total-info">
      <el-col :span="6">
        <el-card shadow="always" class="cnt-card"> 当前请假司机{{ cntInfo.total }}人 </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always" class="cnt-card"> 超假{{ cntInfo.exceedDays }}天 </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always" class="cnt-card"> 临时事假{{ cntInfo.emergencyCnt }}人 </el-card>
      </el-col>
      <el-col :span="6"></el-col>
    </el-row>

    <el-tabs v-model="tabPosition" @tab-click="tabPositionChange" style="margin: 0 24px">
      <el-tab-pane label="待审批" value="0"></el-tab-pane>
      <el-tab-pane label="请假中" value="1"></el-tab-pane>
    </el-tabs>

    <el-table :data="leaves" class="table" @cell-click="checkLeave">
      <el-table-column sortable prop="carNo" label="班号" width="80"></el-table-column>
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="team" label="车队" width="100"></el-table-column>
      <el-table-column prop="ctime" label="请假时间" width="160"></el-table-column>
      <el-table-column prop="leaveFirst" label="是否请假优先" width="120"></el-table-column>
      <el-table-column sortable prop="sdate" label="请假起始时间" width="140"></el-table-column>
      <el-table-column sortable prop="mdays" label="本月已请假" width="120"></el-table-column>
      <el-table-column sortable prop="days" label="预计时长" width="120"></el-table-column>
      <el-table-column prop="routeName" label="生效地点" width="100"></el-table-column>
      <el-table-column prop="leaveTypeName" label="请假类型" width="100"></el-table-column>
      <el-table-column prop="leaveCauseName" label="请假原因" width="100"></el-table-column>
      <el-table-column prop="causeBz" label="详情"></el-table-column>
      <el-table-column prop="adminName" label="操作人" width="80"></el-table-column>
      <el-table-column label="审批" width="80" v-if="tabPosition == '0'">
        <template slot-scope="scope">
          <span v-if="scope.row.leaveType != 'EMERGENCY'">-</span>
          <span v-else>{{
            scope.row.agree == 1 ? "已审批" : scope.row.agree == 0 ? "已驳回" : "未审批"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showPoper" title="请假审批">
      <div style="height: 600px; overflow-y: scroll">
        <p style="text-align: center; font-size: 16px; font-weight: 600">
          审批司机{{ curLeave.carNo + curLeave.name }}的本次临时病假
        </p>
        <el-row class="leave-row">
          <span>请假生效日期:{{ curLeave.sdate }}</span>
          <span>请假生效地点:{{ curLeave.routeName }}</span>
          <span>是否接单中: {{ curScheduleInfo.hasOrder ? "是" : "否" }}</span>
          <span>排班路线：{{ curScheduleInfo.pathName ? curScheduleInfo.pathName : "-" }}</span>
          <span>排班名次:{{ curScheduleInfo.schNo ? curScheduleInfo.schNo : "-" }}</span>
        </el-row>
        <el-row class="leave-row"> 请假事由：{{ curLeave.causeBz }} </el-row>

        <el-row class="leave-row" v-for="confirm in curLeave.confirms" :key="confirm.id">
          <el-divider></el-divider>
          <div v-if="confirm.admin">
            <div>
              <span>审批员工：{{ confirm.adminName }} {{ confirm.ctime }}</span>
              <span>审批意见：{{ confirm.agree == 1 ? "同意" : "驳回" }}</span>
            </div>
            <div>
              <span>{{ confirm.agree == 1 ? "同意" : "驳回" }}意见：{{ confirm.causeBz }}</span>
            </div>
          </div>
          <div v-else>
            <div>请假事由：{{ confirm.causeBz }}</div>
            <div>
              <el-image
                v-for="img in confirm.imgList"
                style="width: 100px; height: 100px; margin-right: 8px"
                :src="img"
                :preview-src-list="confirm.imgList"
                :key="img"
                fit="contain"
              ></el-image>
            </div>
            <div>申请时间：{{ confirm.ctime }}</div>
          </div>
        </el-row>

        <el-divider></el-divider>
        <el-row class="leave-row">
          <div class="operate-box">
            <span style="vertical-align: top"
              >审核无责请假意见：
              <el-radio v-model="audit.agree" label="1">同意</el-radio>
              <el-radio v-model="audit.agree" label="0">驳回</el-radio>
            </span>
            <span style="vertical-align: top"
              >请假实际计算天数：
              <el-input size="small" v-model="audit.realDays" style="width: 100px"></el-input>
            </span>
          </div>
          <span>
            <el-input
              class="remark"
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="audit.adminBz"
            >
            </el-input>
          </span>
        </el-row>
        <el-row class="btn-group">
          <el-button size="mini" type="text" @click="showPoper = false">取消</el-button>
          <el-button size="mini" type="primary" @click="applyLeave">确定</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ParamUtil } from "@/util";
export default {
  name: "LeaveInfo",
  props: {
    team: {
      type: Number,
    },
  },
  data() {
    return {
      tabPosition: "0",
      leaves: [],
      cntInfo: {},
      curLeave: {},
      curScheduleInfo: {},
      showPoper: false,
      isSpecLeaveType: false,
      audit: {},
    };
  },
  created() {
    this.query();
  },
  watch: {
    team: function () {
      this.query();
    },
  },
  methods: {
    tabPositionChange() {
      this.query();
    },
    query() {
      let param = { teamId: this.team, queryType: this.tabPosition };
      this.$http.post("/admin/driver/leave/list", ParamUtil.filterEmptyData(param)).then((res) => {
        this.leaves = res.data.leaves || [];
        this.leaves.forEach((leave) => {
          leave.confirms = [];
          leave.sdate = leave.sdate.substring(0, 10);
          if (leave.leaveType == "EMERGENCY") {
            leave.leaveTypeName = "临时";
          } else if (leave.leaveType == "LONG") {
            leave.leaveTypeName = "超长假";
          } else {
            leave.leaveTypeName = "常规";
          }

          if (leave.leaveCause == "SICK") {
            leave.leaveCauseName = "病假";
          } else {
            leave.leaveCauseName = "事假";
          }
        });
        this.cntInfo = res.data.cntInfo || { total: 0, emergencyCnt: 0, exceedDays: 0 };
      });
    },
    checkLeave(row, column) {
      if (column.label != "审批") {
        return;
      }

      if (row.leaveType != "EMERGENCY") {
        return;
      }
      this.curLeave = row;
      this.$http
        .post("/admin/driver/leave/listaudit", {
          leaveId: this.curLeave.id,
          teamId: this.curLeave.teamId,
          driverId: this.curLeave.driverId,
        })
        .then((res) => {
          let confirms = res.data.confirms || [];
          this.curScheduleInfo = res.data.curScheduleInfo || {};
          confirms.forEach((confirm) => {
            if (confirm.imgs) {
              confirm.imgList = confirm.imgs.split(",");
            }
          });
          this.$set(this.curLeave, "confirms", confirms);
          this.$set(this.audit, "realDays", parseInt(row.days));
          this.showPoper = true;
        });
    },
    applyLeave() {
      if (!this.audit.agree) {
        this.$message.error("请选择审核无责请假意见");
        return;
      }
      if (!this.audit.adminBz) {
        this.$message.error("请填写审批意见");
        return;
      }
      this.$http
        .post("/admin/driver/leave/apply", Object.assign(this.audit, { id: this.curLeave.id }))
        .then(() => {
          this.showPoper = false;
          this.curLeave.agree = this.audit.agree;
          let idx = -1;
          for (let i = 0; i < this.leaves.length; i++) {
            if (this.leaves[i]["id"] == this.curLeave.id) {
              idx = i;
              break;
            }
          }
          this.leaves.splice(idx, 1, this.curLeave);
          this.audit = {};
        });
    },
  },
};
</script>

<style lang="less">
.leave {
  .total-info {
    padding: 24px;
  }
  .cnt-card {
    width: 300px;
    height: 80px;
    line-height: 40px;
    text-align: center;
  }
  .table {
    width: calc(100% - 48px);
    margin: 0 24px;
  }
  .leave-row {
    line-height: 32px;
  }

  .operate-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .leave-row span {
    display: inline-block;
    margin-right: 16px;
  }
  .el-popover.el-popper {
    margin-left: 300px;
  }
  .btn-group {
    margin-top: 24px;
    text-align: center;
  }
  .remark {
    width: 700px;
    textarea {
      height: 100px !important;
    }
  }
  span.el-radio__input {
    margin-right: 0px;
    .el-radio__inner {
      margin-right: 0px;
    }
  }
}
</style>
