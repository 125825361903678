<template>
  <div class="tip-box">
    <el-tooltip effect="dark" placement="top">
      <div slot="content">
        <slot></slot>
      </div>

      <div class="label">{{ label }} <i class="el-icon-question"></i></div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: String,
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.tip-box {
  position: relative;
  .label {
    padding-bottom: 10px;
  }
}
</style>
