import Vue from 'vue';
import store from '@/store';

/**
 *  路线名称转换
 */
Vue.filter('pathNameTransfer', function (value) {
  const pathList = store.state.pathList;
  if (value instanceof Array) {
    const labels = pathList.filter(item=> value.includes(item.id));
    return labels.map(t=> t.name).join("\n");
  } else {
    const path = pathList.find(item=> item.id === value);
    if (path) {
      return path.name;
    }
  }

  return '';
});

/**
 *  文件名称转换
 */
Vue.filter('fileNameTransfer', function (name, index) {
  const dotIndex = name.lastIndexOf('.');
  const fileType = name.substr(dotIndex);
  return `附件${index}${fileType}`;
});
