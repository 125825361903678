<template>
<div class="award-summary">
  <div class="query-container">
    <div>任务结束时间段：</div>
    <div><el-date-picker
            v-model="queryVo.startDate"
            type="date"
            placeholder="选择日期">
    </el-date-picker> 至 <el-date-picker
            v-model="queryVo.endDate"
            type="date"
            placeholder="选择日期">
    </el-date-picker></div>
    <div style="margin: 0 8px;">
        <el-select v-model="queryVo.flCode" placeholder="请选择">
          <el-option
                  v-for="item in flCodeOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
    </div>
    <div>
      <el-button type="primary" @click.native="query">查询</el-button>
    </div>
  </div>

  <div class="data-container" v-if="!loading">
      <div class="summary">
        <div class="summary-item">
          <div class="num">{{awardInfo.num}}次</div>
          <div class="desc">总任务数</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.totalAward}}元</div>
          <div class="desc">总奖池</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.exchargeAward}}元</div>
          <div class="desc">已发奖金</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.totalReachNum}}人</div>
          <div class="desc">总获奖</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.totalAwardNum}}人次</div>
          <div class="desc">总获奖人次</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.participateRate}}%</div>
          <div class="desc">任务参与率</div>
        </div>
        <div class="summary-item">
          <div class="num">{{awardInfo.reachRate}}%</div>
          <div class="desc">任务完成率</div>
        </div>
      </div>
      <div style="margin-left: 10px; margin-top: 10px;">
        <el-radio-group v-model="chartMode">
          <el-radio-button label="奖金"></el-radio-button>
          <el-radio-button label="次数"></el-radio-button>
        </el-radio-group>
      </div>
      <div ref="chart" class="chart"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "awardSumPage",
  data () {
    return {
      loading: true,
      chartMode: "奖金",
      queryVo: {
        startDate: "",
        endDate: "",
        flCode: ""
      },
      flCodeOpts: [{
        label: "01",
        value: "1"
      }, {
        label: "02",
        value: "2"
      }, {
        label: "03",
        value: "3"
      }],
      awardInfo: {}
    };
  },
  mounted () {},
  watch: {
    chartMode () {
      this.initChart(this.awardInfo.details);
    }
  },
  methods: {
    query () {
      this.$http.post("/admin/awardtask/overview", this.queryVo).then(res => {
        this.loading = false;
        this.awardInfo = res.data || {};
        this.initChart(this.awardInfo.details);
      });
    },
    initChart (datas) {
      datas = datas || [];

      const labels = [];
      const series = [];
      datas.forEach(data => {
        for (var p in data) {
          if (p == 'flCode') {
            labels.push(p);
          } else if (this.chartMode == '奖金' && p == "totalAward") {
            series.push(data[p]);
          } else if (this.chartMode == '次数' && p == "totalNum") {
            series.push(data[p]);
          }
        }
      });
      const option = {
        xAxis: {
          data: labels
        },
        yAxis: {
        },
        series: [
          {
            data: series,
            type: "bar"
          }],

      };
      this.$nextTick(() => {
        const chart = echarts.init(this.$refs.chart);
        chart.setOption(option);
      });
    }
  }
};
</script>

<style lang="less" scoped>
  .query-container {
      padding: 10px 0 10px 10px;
      div {
        display: inline-block;
      }
  }

  .summary {
    .summary-item {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px solid #dcdfe5;
        margin: 10px;
        border-radius: 2px;
        text-align: center;
        .num {
          margin-top: 20px;
          margin-bottom: 10px;
        }
    }
  }

  .chart {
    height: 300px;
    width: 820px;
  }

</style>