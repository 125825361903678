import Vue from 'vue';
import {SessionStorage} from '@/util';
import {PERMISSION_ARR} from "@/core/const/global-const";

/**
 * 根据权限id隐藏没有权限的按钮
 */
Vue.directive('perm', {
  bind: function (el, binding) {
    const premissions = SessionStorage.get(PERMISSION_ARR);
    const pKey = binding.value;
    if (premissions.indexOf(pKey) < 0) {
      el.style.display = 'none';
    }
  },
});
