<template>
  <div class="root-box">
    <QueryCondition ref="param" :queryFun="query" />

    <div class="head-box">
      <div class="item">
        <div class="title">累计优惠金额</div>
        <div class="amount">
          <span class="value">{{ data.totalDiscountAmount / 1000000 }}</span>
          <span class="unit">万</span>
        </div>
      </div>

      <div class="item">
        <div class="title">累计优惠人单</div>
        <div class="amount">
          <span class="value">{{ data.totalUsedOrders }}</span>
          <span class="unit">单</span>/
          <span class="value">{{ data.totalUsedPersons }}</span>
          <span class="unit">人</span>
        </div>
      </div>

      <div class="item">
        <div class="title">未使用优惠人单</div>
        <div class="amount">
          <span class="value">{{ data.totalUnUsedOrders }}</span>
          <span class="unit">单</span>/
          <span class="value">{{ data.totalUnUsedPersons }}</span>
          <span class="unit">人</span>
        </div>
      </div>

      <div class="item">
        <div class="title">人均优惠额度</div>
        <div class="amount">
          <span class="value">{{ data.avgDiscountOfPerson / 100 }}</span>
          <span class="unit">元</span>
        </div>
      </div>

      <div class="item">
        <div class="title">单均优惠额度</div>
        <div class="amount">
          <span class="value">{{ data.avgDiscountOfOrder / 100 }}</span>
          <span class="unit">元</span>
        </div>
      </div>
    </div>

    <div style="margin-top: 80px">
      <chart ref="chart" :option="chartOpt" :height="400" />
    </div>

    <div class="table">
      <div class="item">
        <chart-view
          ref="chart1"
          valueField="num"
          nameField="name"
          title="优惠单与非优惠单数量比"
          initChartType="pie"
        />
      </div>

      <div class="item">
        <chart-view
          ref="chart2"
          valueField="num"
          nameField="name"
          title="优惠人数与非优惠人数数量比"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { discountSummary } from "@/api";
import ChartView from "../../ChartView.vue";

import QueryCondition from "../QueryCondition.vue";
export default {
  components: {
    QueryCondition,
    ChartView,
  },
  props: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  data() {
    return {
      data: {},
      chartOpt: {
        title: {
          text: "优惠均值趋势图",
        },
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
      },
    };
  },
  methods: {
    query() {
      const param = this.$refs.param.getParam();
      discountSummary(param).then((res) => {
        this.data = res.data || {};
        const {
          list = [],
          totalUsedOrders,
          totalUnUsedOrders,
          totalUsedPersons,
          totalUnUsedPersons,
        } = this.data;
        const dimensionNames = ["单均优惠金额", "人均优惠金额", "优惠总单", "优惠总额"];
        const axis = [];
        const data = [];
        const series = [];

        dimensionNames.forEach((item, index) => {
          data.push([]);
          series.push({
            name: dimensionNames[index],
            type: "line",
            data: data[index],
          });
        });

        list.forEach((item) => {
          data[0].push(item.avgDiscountOfOrder / 100);
          data[1].push(item.avgDiscountOfPerson / 100);
          data[2].push(item.totalUsedOrders);
          data[3].push(item.totalDiscount / 100);
          axis.push(item.date);
        });

        const opt = {
          ...this.chartOpt,
          xAxis: { type: "category", show: true, data: axis },
          yAxis: {
            show: true,
          },
          series,
        };
        this.$refs.chart.setOption(opt, true);
        const chart1Data = [
          { name: "优惠单", num: totalUsedOrders },
          { name: "非优惠单", num: totalUnUsedOrders },
        ];
        const chart2Data = [
          { name: "优惠人数", num: totalUsedPersons },
          { name: "非优惠人数", num: totalUnUsedPersons },
        ];
        this.$refs.chart1.setData(chart1Data);
        this.$refs.chart2.setData(chart2Data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.head-box {
  display: flex;
  margin: 40px 0;
  justify-content: space-around;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amount {
      margin-top: 10px;

      .value {
        font-size: 20px;
      }

      .unit {
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(2, 300px);
  grid-row-gap: 40px;
  grid-column-gap: 4%;
  padding: 20px;
  margin-top: 60px;

  > div {
    background: #fff;
    border: 1px solid #ddd;
    position: relative;
  }
}
</style>
