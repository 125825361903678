<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <el-row
      v-for="(opt, idx) in paperItm.itms"
      :key="opt.id"
      @mouseenter.native="showDelIcon(opt)"
      @mouseleave.native="hideDelIcon(opt)"
      style="margin-bottom: 8px"
    >
      <el-col :span="1" v-if="paperItm.itmtype == 'radio'">
        <el-radio
          @change="answerChange"
          v-model="paperItm.answer"
          :key="opt.id + '_id'"
          :label="opt.id"
        ></el-radio>
      </el-col>
      <el-col :span="1" v-else>
        <el-checkbox
          @change="answerChange"
          v-model="paperItm.answer"
          :key="opt.id + '_id'"
          :label="opt.id"
        ></el-checkbox>
      </el-col>
      <el-col :span="23">
        <el-input
          @change="valChange"
          v-model="opt.val"
          :key="opt.id + '_val'"
        />
      </el-col>
      <div
        class="opt-del"
        v-show="opt.showIcon"
        @click="deleteOption(opt, idx)"
      >
        <i class="el-icon-circle-close"></i>
      </div>
    </el-row>
    <el-row style="margin-top: 8px">
      <el-button @click="addOption" style="width: 100%"
        ><i class="el-icon-plus"></i>增加选项</el-button
      >
    </el-row>
  </div>
</template>

<script>

import Toolkit from "@/util/toolkit";
export default {
  name: "OptionsEditor",
  props: {
    paperItm: {
      type: Object
    }
  },
  data () {
    return {
      answer: "",
    };
  },
  watch: {
    "paperItm.itmtype": {
      handler () {
        if (this.paperItm.itmtype == "radio") {
          if (!this.paperItm.answer || Array.isArray(this.paperItm.answer)) {
            // eslint-disable-next-line vue/no-mutating-props
            this.paperItm.answer = "";
          }
        } else if (this.paperItm.itmtype == "checkbox") {
          if (!this.paperItm.answer || !Array.isArray(this.paperItm.answer)) {
            // eslint-disable-next-line vue/no-mutating-props
            this.paperItm.answer = [];
          }
        }
      }
    }
  },
  methods: {
    addOption () {
      var rdmId = "rdm-" + Toolkit.uuid();
      var itm = {"id": rdmId, "val": ""};
      // eslint-disable-next-line vue/no-mutating-props
      this.paperItm.itms.push(itm);
      this.$emit("opt-change");
    },
    showDelIcon (opt) {
      this.$set(opt, "showIcon", true);
    },
    hideDelIcon (opt) {
      this.$set(opt, "showIcon", false);
    },
    deleteOption (opt, idx) {
      if (this.paperItm.itms.length <= 1) {
        this.$message.error("至少保留一个选项。");
        return;
      }

      let answers = this.paperItm.answer;
      if (typeof (answers) == "string") {
        answers = [answers];
      }
      if (answers.indexOf(this.paperItm.itms[idx].id) >= 0) {
        this.$message.error("请先取消答案选项再删除。");
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.paperItm.itms.splice(idx, 1);
      this.$emit("opt-change");
    },
    valChange () {
      this.$emit("opt-change");
    },
    answerChange () {
      this.$emit("opt-change");
    }
  }
};
</script>

<style lang="less" scoped>
.opt-del {
  position: absolute;
  top: -7px;
  right: -6px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 18px;
  background-color: #fff;
  color: #409eff;
}
.opt-del i {
  vertical-align: 9px;
}
.el-radio__label {
  display: none !important;
}
</style>