<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="handleClick">
      <el-tab-pane label="短信统计" name="ref1">
        <CountMsg></CountMsg>
      </el-tab-pane>

      <el-tab-pane label="发送计划" name="ref2">
        <SendPlan ref="sendPlan"></SendPlan>
      </el-tab-pane>

      <el-tab-pane label="用户筛选" name="ref3">
        <UserGroupFilter></UserGroupFilter>
      </el-tab-pane>

      <el-tab-pane label="短信模板" name="ref4">
        <SmsTemplate></SmsTemplate>
      </el-tab-pane>

      <el-tab-pane label="优惠券模板" name="ref5">
        <SmsCoupon></SmsCoupon>
      </el-tab-pane>
    </el-tabs>

    <div v-if="info.balance" class="account">
      云片账户余额：{{ info.balance }} 元
    </div>
  </div>
</template>

<script>

import CountMsg from './CountMsg/index';
import UserGroupFilter from './UserGroupFilter/index';
import SmsTemplate from './SmsTemplate/index';
import SmsCoupon from './SmsCoupon/index';
import SendPlan from './SendPlan/index';

import {getYunpianAccountInfo} from '@/api';

export default {
  components: {
    CountMsg,
    UserGroupFilter,
    SmsTemplate,
    SmsCoupon,
    SendPlan
  },
  props: {},
  created () {
    this.getAccountInfo();
  },
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1',
      info: {}
    };
  },
  methods: {

    handleClick (tab) {
      if (tab.name === 'ref2') {
        this.$refs.sendPlan.initData();
      }
    },

    getAccountInfo () {
      getYunpianAccountInfo().then(res=>{
        this.info = JSON.parse(res.data);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.root-box {
  position: relative;
  .account {
    position: absolute;
    right: 20px;
    top: 14px;
  }
}
</style>
