<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="标题">
          <el-input
            style="width: 200px"
            v-model="param.title"
            @change="query()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="标签">
          <el-input
            style="width: 200px"
            v-model="param.label"
            @change="query()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="内容">
          <el-input
            style="width: 200px"
            v-model="param.content"
            @change="query()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >新建短信模板</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column prop="smsIndex" label="序号" width="80">
          <template slot-scope="scope">
            <div class="sms-index">{{ scope.row.smsIndex }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="title" label="标题"> </el-table-column>

        <el-table-column prop="content" label="短信内容"></el-table-column>

        <el-table-column prop="words" label="字数" width="100">
          <template slot-scope="scope">
            {{ scope.row.words }}字/{{ scope.row.words > 70 ? "67" : "70" }}字
          </template>
        </el-table-column>

        <el-table-column
          prop="auditState"
          label="审核状态"
          width="100"
        ></el-table-column>

        <el-table-column prop="reason" label="不通过原因">
          <template slot-scope="scope">
            {{ scope.row.auditState === "FAIL" ? scope.row.reason : "" }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="audit(scope.row)"
              >发送审核</el-button
            >

            <el-button type="text" size="small" @click="openForm(scope.row)"
              >修改</el-button
            >

            <el-button type="text" size="small" @click="delTemp(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="短信模板"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :size="size" :rules="rules">
        <el-form-item label="序号：" prop="smsIndex">
          <el-input v-model="form.smsIndex" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item label="标题：" prop="title">
          <el-input v-model="form.title" style="width: 100%"></el-input>
        </el-form-item>

        <div>
          <el-tag
            type="success"
            style="margin-right: 10px"
            v-for="item in commonLinks"
            :key="item.value"
            @click="addLink(item)"
            >{{ item.label }}</el-tag
          >
        </div>
        <el-form-item label="内容：" prop="content">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.content"
            style="width: 100%"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <span class="red">注意：以“回T退订”结尾的为营销短信</span>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {delSmsTemplate, getSmsTemplateList, saveOrUpdateSmsTemplate, auditTemplate} from '@/api';
import { Toolkit } from '@/util';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
    })
  },
  data () {
    return {
      rules: {
        content: [
          {required: true, message: '请输入短信内容', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入短信标题', trigger: 'blur'},
        ],
        smsIndex: [
          {required: true, message: '请输入短信序号', trigger: 'blur'},
        ],
      },
      visible: false,
      param: {},
      form: {},
      list: [],
      commonLinks: [{label: '首页', value: ' https://cxbswc/mp '}, {label: '分享页面', value: ' https://cxbswc/mp/s '}, {label: '优惠券页面', value: ' https://cxbswc/mp/c '},
        {label: '积分页面', value: ' https://cxbswc/mp/p '}, {label: '常用乘车人', value: ' https://cxbswc/mp/mc '}, {label: '安全中心', value: ' https://cxbswc/mp/se '}]
    };
  },
  methods: {

    query () {
      getSmsTemplateList(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
    },

    openForm (item) {
      if (item) {
        this.form = {...item};
      } else {
        this.form = {content: ' 回T退订'};
      }
      this.visible = true;
    },

    saveOrUpdate () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = Toolkit.filterEmpty({...this.form});
        saveOrUpdateSmsTemplate(param).then(() => {
          this.$message.success('操作成功');
          this.visible = false;
          this.query();
        });
      });

    },


    delTemp (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSmsTemplate({id: item.id}).then(()=>{
          this.$message.success('删除成功');
          this.query();
        });
      });
    },

    audit (item) {
      this.$prompt(`请输入短息使用场景（可不填）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(({ value }) => {
        item.applyDescription = value;
        auditTemplate(item).then(()=>{
          this.$message.success('发送审核成功');
          this.query();
        });
      }, ()=>{});
    },

    addLink (item) {

      const content = this.form.content + item.value;
      this.$set(this.form, 'content', content);
    }
  },
};
</script>
<style lang="less" scoped>
.sms-index {
  width: 50px;
  height: 50px;
  background-color: #409eff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
</style>