import { render, staticRenderFns } from "./TelOrderModal.vue?vue&type=template&id=339d967e&scoped=true"
import script from "./TelOrderModal.vue?vue&type=script&lang=js"
export * from "./TelOrderModal.vue?vue&type=script&lang=js"
import style0 from "./TelOrderModal.vue?vue&type=style&index=0&id=339d967e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339d967e",
  null
  
)

export default component.exports