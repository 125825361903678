import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App';
import store from './store';
import VueRx from 'vue-rx';
import EventMixin from './core/event/event-mixin.js';
import './assets/css/global.less';

import '@/core/plugins';
import '@/core/filter';
import '@/core/directive';
import './core/global-components';
import router from './route';


Vue.mixin(EventMixin);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(VueRx);


// 全局errorHandler
// Vue.config.errorHandler = function (err) {
// //     // Message.error(err.message);
// // };

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');

