import Vue from 'vue';
import {DateUtil} from '@/util';

Vue.filter('dateFormat', function (value, format) {
  if (value) {
    const date = new Date(value);
    return DateUtil.format(date, format);
  } else {
    return '';
  }

});
