<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.startDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker
        ></el-form-item>

        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.endDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table main">
      <div class="header">
        <div class="title">短信营销效果分析</div>
        <el-radio-group v-model="chartGroupType" @change="renderChart">
          <el-radio-button label="1">日</el-radio-button>
          <el-radio-button label="2">周</el-radio-button>
          <el-radio-button label="3">月</el-radio-button>
          <el-radio-button label="4">年</el-radio-button>
        </el-radio-group>
      </div>
      <el-divider></el-divider>

      <div class="box">
        <div>
          <chart ref="chart" :option="chartOpt" style="margin-bottom: 10px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateUtil, Toolkit } from '@/util';
import {getSummary} from '@/api';

export default {
  components: {},
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
      },
      chartGroupType: "1",
      chartOpt: {
        grid: {
          bottom: 30,
        },

        tooltip: {
          show: true,
          trigger: "axis"
        },
      },

      chartData: []
    };
  },
  methods: {
    query () {
      getSummary(Toolkit.filterEmpty(this.param)).then(res=>{
        this.chartData = res.data || [];
        this.renderChart();
      });

    },

    renderChart () {
      const dataset = this.getDataset();
      const option = {
        ...this.chartOpt,

        legend: {
          data: dataset.dimensions,
          bottom: 'auto'
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {
          show: true
        },
        series: [0, 1, 2, 3, 4, 5, 6].map((index)=>{
          return {
            name: dataset.dimensions[index],
            type: "line",
            data: dataset.source[index]
          };
        }),

      };

      this.$refs.chart.setOption(option, true);
    },

    getDataset () {
      const list = this.chartData;
      var axis = [];
      var data0 = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];
      var data4 = [];
      var data5 = [];
      var data6 = [];

      let dim = this.param.startDate;
      let week = 1;
      while (dim <= this.param.endDate) {
        const data = list[dim];

        const sendNum = data['sendNum'];
        const uv = data['uv'];
        const appUv = data['appUv'];
        const mpUv = data['mpUv'];
        const dealNum = data['dealNum'];
        const appDealNum = data['appDealNum'];
        const mpDealNum = data['mpDealNum'];

        if (this.chartGroupType == "1") {
          axis.push(dim);
          data0.push(sendNum);
          data1.push(uv);
          data2.push(appUv);
          data3.push(mpUv);
          data4.push(dealNum);
          data5.push(appDealNum);
          data6.push(mpDealNum);
        } else {
          let key;
          if (this.chartGroupType == "3") {
            key = dim.substring(0, 7);
          } else if (this.chartGroupType == "4") {
            key = dim.substring(0, 4);
          } else if (this.chartGroupType == "2") {
            if (new Date(dim).getDay() == 0) {
              key = week ++;
            } else {
              key = week;
            }
          }
          if (axis.indexOf(key) >= 0) {
            let pos = data0.length - 1;
            data0[pos] = data0[pos] + sendNum;
            data1[pos] = data1[pos] + uv;
            data2[pos] = data2[pos] + appUv;
            data3[pos] = data4[pos] + mpUv;
            data1[pos] = data1[pos] + dealNum;
            data2[pos] = data2[pos] + appDealNum;
            data2[pos] = data2[pos] + mpDealNum;
          } else {
            axis.push(key);
            data0.push(sendNum);
            data1.push(uv);
            data2.push(appUv);
            data3.push(mpUv);
            data4.push(dealNum);
            data5.push(appDealNum);
            data6.push(mpDealNum);
          }
        }

        //next loop
        dim = DateUtil.getSpecDayStr(dim, 1);
      }

      return {
        axis: axis,
        source: [data0, data1, data2, data3, data4, data5, data6],
        dimensions: ["发送人数", '全部UV', 'APPUV', '小程序UV', '成交订单总数', '成交订单数(APP)', '成交订单数(MP)']
      };
    },


  },
};
</script>
<style lang="less" scoped>
.table {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    .title {
      font-weight: bold;
    }
  }

  &.main {
    padding: 10px;
  }
}
</style>