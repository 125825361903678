<template>
    <div class="root-box">
        <el-row class="condition">
            <el-form size="small" :inline="true">
                <el-form-item label="IPCType：">
                    <el-select style="width: 300px;" v-model="param.ipcType" placeholder="请选择" @change="query()" filterable
                        clearable>
                        <el-option v-for="item in ipcTypeOpts" :key="item.ipcType" :label="`${item.name}（${item.ipcType}）`"
                            :value="item.ipcType"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="数据状态：">
                    <el-select style="width: 200px;" v-model="param.rptState" placeholder="请选择" @change="query()" filterable clearable>
                        <el-option v-for="item in rptStateOpts" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="创建时间：">
                    <el-date-picker style="width: 150px;" placeholder="选择时间" v-model="param.startTime" type="date"
                        value-format="yyyy-MM-dd" clearable @change="query()"></el-date-picker>至
                    <el-date-picker style="width: 150px;" placeholder="选择时间" v-model="param.endTime" type="date"
                        value-format="yyyy-MM-dd" clearable @change="query()"></el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button style="width: 100px;margin-right: 20px" type="primary" @click="query()">查询</el-button>
                </el-form-item>


                <el-form-item style="float: right;">
                    <el-button style="width: 100px; margin-right: 20px;" @click="openForm()">新增数据</el-button>
                    <el-button style="width: 100px;" @click="reportAll()">全部上报</el-button>
                </el-form-item>
            </el-form>
        </el-row>

        <div v-if="fieldList.length">
            <div class="table">
                <el-table :data="list" border stripe max-height="600" @sort-change="handleSort">
                    <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

                    <el-table-column v-for="field in fieldList" :key="field" :prop="field" sortable
                        :label="field"></el-table-column>

                    <el-table-column prop="rptState" label="数据状态">
                        <template slot-scope="scope">
                            <div>{{ scope.row.rptState | enumTransfer(rptStateOpts) }}</div>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" align="center" label="操作" width="80">
                        <template slot-scope="scope">
                            <div>
                                <el-button type="text" size="small" @click="reportData(scope.row)">上报数据</el-button>
                            </div>
                            <div>
                                <el-button type="text" size="small" @click="openForm(scope.row)">修改数据</el-button>
                            </div>

                            <div>
                                <el-button type="text" size="small" @click="openForm(scope.row, true)">复制数据</el-button>
                            </div>
                            <div>
                                <el-button type="text" size="small" @click="del(scope.row, true)">删除数据</el-button>
                            </div>

                        </template>
                    </el-table-column>

                </el-table>
            </div>

            <div class="page">
                <el-pagination @current-change="handleCurrentChange" background :current-page="currentPage"
                    :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
        </div>


        <el-dialog :visible.sync="visible" width="800px" max-height="600" :size="size" :title="form.id ? '修改数据' : '新增数据'"
            :close-on-click-modal="false">
            <el-form ref="dataForm" :model="form" :size="size" label-width="120px">
                <el-form-item v-for="field in formFieldList" :label="field" :prop="field" :key="field">
                    <el-input v-model="form[field]"></el-input>
                </el-form-item>


            </el-form>

            <span slot="footer">
                <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
                <el-button type="info" :size="size" @click="closeForm">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>

import { getReportData, reportSingleData, pushTask, saveOrUpdateReportData, deleteReportData } from '@/api';
import { mapState } from "vuex";
import { ParamUtil } from '@/util';


export default {
  mounted () {
    this.query();
  },
  computed: {
    ...mapState({
      rptStateOpts: (state) => state.enumMap["RPT_STATE"],
      size: (state) => state.btnSize,
    }),

    formFieldList () {
      const fields = ['feedbackStatus', 'createTime'];
      return this.fieldList.filter(item => !fields.includes(item));
    }

  },
  data () {
    return {
      param: {
        ipcType: 'baseInfoDriver'
      },
      list: [],
      total: 0,
      asc: false,
      currentPage: 1,
      statusOpts: [{
        label: '上报成功',
        value: 1,
      }, {
        label: '上报失败',
        value: 0,
      }],
      ipcTypeOpts: [{
        name: '驾驶员基本信息',
        page: 40,
        ipcType: 'baseInfoDriver',
        disable: true,
        twice: true,
        num: 200,
        sort: 8,
      }, {
        name: '驾驶员培训信息',
        page: 43,
        ipcType: 'baseInfoDriverEducate',
        disable: true,
        twice: true,
        num: 200,
        sort: 9,
      }, {
        name: '驾驶员APP信息',
        page: 45,
        ipcType: 'baseInfoDriverApp',
        disable: true,
        twice: true,
        num: 200,
        sort: 10,
      }, {
        name: '驾驶员统计信息',
        page: 46,
        ipcType: 'baseInfoDriverStat',
        disable: true,
        twice: true,
        num: 200,
        sort: 11,
      }, {
        name: '车辆基本信息',
        page: 34,
        ipcType: 'baseInfoVehicle',
        disable: true,
        twice: true,
        num: 200,
        sort: 12,
      }, {
        name: '车辆保险信息',
        page: 39,
        ipcType: 'baseInfoVehicleInsurance',
        disable: true,
        twice: true,
        num: 200,
        sort: 13,
      }, {
        name: '车辆里程信息',
        page: 39,
        ipcType: 'baseInfoVehicleTotalMile',
        disable: true,
        twice: true,
        num: 200,
        sort: 14,
      }, {
        name: '乘客信息',
        page: 47,
        ipcType: 'baseInfoPassenger',
        disable: true,
        num: 600,
        sort: 15,
      }, {
        name: '车辆经营上线',
        page: 53,
        ipcType: 'operateLogin',
        disable: true,
        dyna: true,
        num: 1000,
        sort: 16,
      }, {
        name: '车辆经营下线',
        page: 54,
        ipcType: 'operateLogout',
        disable: true,
        dyna: true,
        num: 1000,
        sort: 17,
      }, {
        name: '订单发起',
        page: 49,
        ipcType: 'orderCreate',
        disable: true,
        dyna: true,
        num: 600,
        sort: 19,
      }, {
        name: '订单成功',
        page: 50,
        ipcType: 'orderMatch',
        disable: true,
        dyna: true,
        num: 500,
        sort: 21,
      }, {
        name: '订单取消',
        page: 52,
        ipcType: 'orderCancel',
        disable: true,
        dyna: true,
        num: 100,
        sort: 22,
      }, {
        name: '经营出发',
        page: 55,
        ipcType: 'operateDepart',
        disable: true,
        dyna: true,
        num: 500,
        sort: 23,
      }, {
        name: '经营到达',
        page: 56,
        ipcType: 'operateArrive',
        disable: true,
        dyna: true,
        num: 500,
        sort: 24,
      }, {
        name: '经营支付',
        page: 58,
        ipcType: 'operatePay',
        disable: true,
        dyna: true,
        num: 500,
        sort: 25,
      }, {
        name: '乘客评价',
        page: 64,
        ipcType: 'ratedPassenger',
        disable: true,
        dyna: true,
        num: 500,
        sort: 27,
      }, {
        name: '乘客投诉',
        page: 65,
        ipcType: 'ratedPassengerComplaint',
        disable: true,
        dyna: true,
        num: 100,
        sort: 28,
      }, {
        name: '司机定位信息',
        page: 60,
        ipcType: 'positionDriver',
        disable: true,
        dyna: true,
        num: 6000,
        sort: 31,
      }, {
        name: '车辆定位信息',
        page: 62,
        ipcType: 'positionVehicle',
        disable: true,
        dyna: true,
        num: 6000,
        sort: 32,
      }, {
        name: '驾驶员信誉信息',
        page: 67,
        ipcType: 'ratedDriver',
        disable: true,
        twice: true,
        aa: true,
        num: 200,
        sort: 30,
      }, {
        name: '驾驶员处罚信息',
        page: 66,
        ipcType: 'ratedDriverPunish',
        disable: true,
        twice: true,
        aa: true,
        num: 100,
        sort: 29,
      }, {
        name: '私人小客车合乘行程',
        page: 70,
        ipcType: 'shareRoute',
        disable: true,
        dyna: true,
        num: 50,
        sort: 18,
      }, {
        name: '私人小客车合乘订单',
        page: 71,
        ipcType: 'shareOrder',
        disable: true,
        dyna: true,
        num: 50,
        sort: 20,
      }, {
        name: '私人小客车合乘订单支付信息',
        page: 73,
        ipcType: 'sharePay',
        disable: true,
        dyna: true,
        num: 50,
        sort: 26,
      }, {
        name: '公司信息',
        url: '/company',
        page: 25,
        ipcType: 'baseInfoCompany',
        num: 1,
        sort: 1,
      }, {
        name: '公司支付信息',
        url: '/companyPay',
        page: 28,
        ipcType: 'baseInfoCompanyPay',
        num: 1,
        sort: 2,
      }, {
        name: '公司经营许可信息',
        url: '/CompanyPermit',
        page: 31,
        ipcType: 'baseInfoCompanyPermit',
        num: 1,
        sort: 3,
      }, {
        name: '服务机构信息',
        url: '/companyService',
        page: 29,
        ipcType: 'baseInfoCompanyService',
        num: 1,
        sort: 4,
      }, {
        name: '公司运营规模信息',
        url: '/companyStat',
        page: 27,
        ipcType: 'baseInfoCompanyStat',
        num: 1,
        sort: 5,
      }, {
        name: '合乘公司服务平台基本信息',
        url: '/shareCompany',
        page: 68,
        ipcType: 'shareCompany',
        num: 1,
        sort: 6,
      }, {
        name: '运价',
        page: 32,
        ipcType: 'baseInfoCompanyFare',
        disable: true,
        twice: true,
        num: 1,
        sort: 7,
      }],
      visible: false,
      fieldList: [],
      form: {},
      pageSize: 100,
    };
  },
  methods: {

    query (page = 1) {

      if (this.param.ipcType) {
        const param = {
          ...this.param,
          current: page,
          pageSize: this.pageSize,
        };
        getReportData(ParamUtil.filterEmptyData(param)).then((res) => {
          const { fieldList = [], data } = res.data || {};
          const { records = [], current = 1, total = 0 } = data;
          this.fieldList = fieldList;
          this.list = records;
          this.currentPage = current;
          this.total = total;
        });
      } else {
        this.fieldList = [];
        this.list = [];
        this.currentPage = 0;
        this.total = 0;
      }

    },

    reportData (item) {
      this.$confirm('确认上报吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          ipcType: this.param.ipcType,
          id: item.id,
        };
        reportSingleData(param).then(() => {
          this.$message.success('上报成功');
          this.query();
        });
      });
    },

    reportAll () {
      const tip = this.param.ipcType ? `确认上报所有【${this.param.ipcType}】类型的数据吗?` : '确认上报所有数据吗？';
      this.$confirm(tip, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          ipcType: this.param.ipcType,
        };
        pushTask(param).then(() => {
          this.$message({
            message: "数据上报成功",
            type: 'success'
          });
        });
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },

    handleSort (item) {
      this.asc = item.order.indexOf('asc') > -1;
      this.query();
    },

    openForm (item, isCopy = false) {
      this.visible = true;
      this.form = {
        ...item,
      };

      if (isCopy) {
        this.form.id = null;
      }
    },


    closeForm () {
      this.visible = false;
      this.form = {};
    },

    saveOrUpdate () {
      saveOrUpdateReportData(this.param.ipcType, this.form).then(() => {
        this.$message.success('保存成功');
        this.closeForm();
        this.query();
      });
    },

    del (item) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteReportData(this.param.ipcType, item.id).then(() => {
          this.$message.success('删除成功');
          this.closeForm();
          this.query();
        });
      });
    }

  }
};
</script>

<style scoped lang="less">
.text-json {
    display: flex;

    .long-text {
        display: inline-block;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 30px;
        flex: 4;
    }

    .pointer {
        flex: 1;
        line-height: 30px;
    }
}

.pointer {
    cursor: pointer;
}

.table {
    margin: 20px 0;
}

.page {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
