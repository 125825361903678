<template>
  <el-row class="row">
    <el-col :span="4">{{ item.no }}</el-col>
    <el-col :span="4">{{ item.name | nameFilter }}</el-col>
    <el-col :span="4">{{ item.seats }}坐</el-col>
    <el-col :span="2">
      <i @click.stop="click(item)" class="el-icon-close"></i>
    </el-col>
  </el-row>
</template>


<script>
import { ElementMixin} from 'vue-slicksort';
export default {
  name: "SortableItem",
  mixins: [ElementMixin],
  props: {
    item: Object,
    click: Function,
    value: Number
  },
  filters: {
    nameFilter (val) {
      return val.substr(0, 1) + '师傅';
    }
  },
};
</script>

<style scoped>
</style>
