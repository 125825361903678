<template>
  <div class="root" :class="{ 'bg-red': active, disabled: disable }">
    <span>{{ scheduling.orderIndex }}、</span>
    <div class="fix-box">
      <span v-if="isPick">{{ scheduling.showTime }}&nbsp;-&nbsp;</span>
      <span v-if="isPick">{{ scheduling.number }}人</span>

      <span
        v-if="!isPick"
        :class="{ red: scheduling.totalDuration - scheduling.standardDuration > maxAllowDelay }"
        >全时:{{ scheduling.totalDuration }}（{{
          scheduling.totalDuration - scheduling.standardDuration
        }}）</span
      >
    </div>
    <span style="margin-right: 16px; width: 32px">
      <span :class="{ red: isPick && isTimeOk(scheduling) }">{{
        scheduling.arriveTime | dateFormat("hh:mm")
      }}</span>
    </span>
    <span class="address">
      <el-button type="text" size="mini" @click.stop="locate(scheduling)">
        {{ scheduling.address }}</el-button
      >
      <span v-if="scheduling.togetherPwd && isPick" class="red"
        >（{{ scheduling.togetherPwd }}）</span
      >
    </span>

    <span class="cursor">
      <i class="el-icon-close" @click="delItem(scheduling)"></i>
    </span>

    <span v-handle class="cursor" style="cursor: move">
      <i class="el-icon-rank"></i>
    </span>
  </div>
</template>

<script>
import { ElementMixin, HandleDirective } from "vue-slicksort";
import { mapState } from "vuex";
import moment from "moment";
import { DateUtil } from "@/util";
export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },

  props: {
    scheduling: Object,
    isPick: Boolean,
    delItem: Function,
    active: Boolean,
    disable: Boolean,
    onUpdate: Function,
    locateFun: Function,
    task: Object,
  },

  filters: {},

  computed: {
    ...mapState({
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
    }),

    item2() {
      let list = this.isPick
        ? this.data.routePlan.sendOrderList
        : this.data.routePlan.pickOrderList;
      list = list.filter((t) => t.orderId === this.item1.orderId);
      return list[0];
    },

    maxAllowDelay() {
      return this.task.maxAllowDelay;
    },

    timeWinSlack() {
      return this.task.timeWinSlack;
    },
  },

  data() {
    return {};
  },

  methods: {
    selectOrder() {
      this.onClick(this.item1);
    },

    setWaitTime(isPlus) {
      if (this.item1.waitTime <= 0 && !isPlus) {
        return;
      }
      let value = isPlus ? this.item1.waitTime + 300 : this.item1.waitTime - 300;
      if (value < 0) {
        value = 0;
      }
      const data = { ...this.item1, waitTime: value };
      this.onUpdate(data, this.index);
    },

    locate(item) {
      const order = this.isPick
        ? { depLatitude: item.lat, depLongitude: item.lng }
        : { destLatitude: item.lat, destLongitude: item.lng };
      this.locateFun(order, this.isPick);
    },

    isTimeOk(item) {
      const bookStartTime = item.dateTime;
      const bookEndTime = moment(bookStartTime).add(1, "h").format("YYYY-MM-DD HH:mm:ss");
      // timeWinSlack 默认加1 ，该判断不包括bookStartTime和bookEndTime
      return !DateUtil.isTimeBetween(
        bookStartTime,
        bookEndTime,
        item.arriveTime,
        this.timeWinSlack + 1
      );
    },
  },
};
</script>

<style scoped lang="less">
.root {
  display: flex;
  align-items: center;
  line-height: 1.5;
  border: 1px dashed #eee;
  padding: 2px;

  &.bg-red {
    background-color: #e8f5f5 !important;
  }

  &.disabled {
    background-color: #eee;
  }
  .drag-handle {
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      cursor: move;
      position: relative;
      top: -2px;
    }
  }

  .fix-box {
    display: inline-block;
    width: 130px;
  }

  .address {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cursor {
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
  }
}
</style>
