<template>
    <div class="root-box">
        <div class="query-container space-between">
            <div>
                <el-form size="small" :inline="true">
                    <el-form-item>
                        <el-input v-model="param.driverNo" placeholder="司机编号" clearable></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="param.vehicleNo" placeholder="车牌号" clearable></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button style="width: 100px; margin-right: 20px" type="primary"
                            @click="query()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-button type="primary" :size="size" @click="initData">初始化</el-button>

            </div>
        </div>

        <div class="table">
            <el-table :data="list" stripe max-height="650">
                <el-table-column type="index" label="序号" show-overflow-tooltip width="50"></el-table-column>
                <el-table-column prop="driverName" label="司机"></el-table-column>
                <el-table-column prop="driverNo" label="司机编号"></el-table-column>
                <el-table-column prop="vehicleNo" label="车牌号" width="120"></el-table-column>
                <el-table-column prop="brand" label="车辆品牌" width="130"></el-table-column>
                <el-table-column prop="miles" label="总里程" width="100"></el-table-column>
                <el-table-column prop="lastMtcMiles" label="上次保养里程" width="120"></el-table-column>
                <el-table-column prop="nextInspectionTime" label="下次检测时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.nextInspectionTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nextWashTime" label="下次洗车时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.nextWashTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nextMtcTime" label="下次保养时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.nextMtcTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastInspectionTime" label="上次检测时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastInspectionTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastWashTime" label="上次洗车时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastWashTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastMtcTime" label="上次保养时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastMtcTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastRepairTime" label="上次维修时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastRepairTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="lastVmeTime" label="上次车载维护时间" width="140">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastVmeTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastAssessTime" label="定损时间" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lastAssessTime | dateFormat("MM月dd日") }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="washPeriod" label="洗车周期" width="100"></el-table-column>
                <el-table-column prop="inspectionPeriod" label="检测周期" width="100"></el-table-column>
                <el-table-column prop="mtcPeriod" label="保养公里数" width="100"></el-table-column>

                <el-table-column prop="washFee" label="洗车费用" width="100"></el-table-column>

                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="openEdit(scope.row)">修改信息</el-button>
                    </template>
                </el-table-column>
            </el-table>


        </div>

        <el-dialog title="修改信息" :visible.sync="formVisible" width="1200px" max-height="700" :size="size"
            :close-on-click-modal="false">
            <el-form ref="form" :model="form" :size="size" label-width="140px" inline>
                <el-form-item label="车牌号：" prop="vehicleNo" clearable>
                    <el-input v-model="form.vehicleNo" disabled></el-input>
                </el-form-item>
                <el-form-item label="司机：" prop="driverName" clearable>
                    <el-input v-model="form.driverName" disabled></el-input>
                </el-form-item>
                <el-form-item label="司机编号：" prop="driverNo" clearable>
                    <el-input v-model="form.driverNo" disabled></el-input>
                </el-form-item>

                <el-form-item label="总里程：" prop="miles">
                    <el-input-number v-model="form.miles" placeholder="总里程" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>

                <el-form-item label="上次保养里程：" prop="lastMtcMiles">
                    <el-input-number v-model="form.lastMtcMiles" placeholder="上次保养里程" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>

                <el-form-item label="洗车费用（分）：" prop="washFee">
                    <el-input-number v-model="form.washFee" placeholder="洗车费用" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>
                <el-form-item label="洗车周期（天）：" prop="washPeriod">
                    <el-input-number v-model="form.washPeriod" placeholder="洗车周期" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>
                <el-form-item label="检测周期（天）：" prop="inspectionPeriod">
                    <el-input-number v-model="form.inspectionPeriod" placeholder="洗车周期" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>
                <el-form-item label="保养周期（公里）：" prop="mtcPeriod">
                    <el-input-number v-model="form.mtcPeriod" placeholder="洗车周期" clearable
                        style="width: 200px;"></el-input-number>
                </el-form-item>
                <el-form-item label="下次检测时间：" prop="nextInspectionTime">
                    <el-date-picker style="width: 200px" v-model="form.nextInspectionTime" type="date" clearable
                        value-format="yyyy-MM-dd 20:00:00" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="下次洗车时间：" prop="nextWashTime">
                    <el-date-picker style="width: 200px" v-model="form.nextWashTime" type="date" clearable
                        value-format="yyyy-MM-dd 20:00:00" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="下次保养时间：" prop="nextMtcTime">
                    <el-date-picker style="width: 200px" v-model="form.nextMtcTime" type="date" clearable
                        value-format="yyyy-MM-dd 20:00:00" placeholder="选择日期"></el-date-picker>
                </el-form-item>
            </el-form>

            <span slot="footer">
                <el-button type="warning" :size="size" @click="updateItem">保 存</el-button>
                <el-button type="info" :size="size" @click="formVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { getServiceScheduleList, updateServiceSchedule, initServiceSchedule } from '@/api';
export default {
    components: {

    },

    props: {

    },

    watch: {

    },

    created() {
        this.query();
    },

    mounted() {

    },

    computed: {

    },

    data() {
        return {
            formVisible: false,
            param: {},
            form: {},
            list: [],
            size: 'small',
        };
    },

    methods: {

        query() {
            getServiceScheduleList(this.param).then(res => {
                this.list = res.data;
            });
        },

        initData() {
            this.$confirm("确定要初始化吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                initServiceSchedule().then(() => {
                    this.$message.success('初始化成功');
                });
            });
        },

        openEdit(row) {
            this.form = { ...row };
            this.formVisible = true;
        },


        updateItem() {
            updateServiceSchedule(this.form).then(() => {
                this.$message.success('修改成功');
                this.formVisible = false;
                this.query();
            });
        },
    },

};
</script>
<style scoped lang="less"></style>
