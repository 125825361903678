<template>
<div class="staff-root">
  <el-form ref="form" :model="currentStaff" :rules="rules" label-width="100px">
    <el-form-item label="姓名:" prop="name">
      <el-input v-model="currentStaff.name" placeholder="请输入员工姓名"></el-input>
    </el-form-item>
    <el-form-item label="手机号码:" prop="telephone">
      <el-input v-model="currentStaff.telephone" placeholder="请输入员工手机号码"></el-input>
    </el-form-item>
    <el-form-item label="员工头像:" prop="avatar">
      <image-upload v-model="currentStaff.avatar" filePath="/partner" bucket="yxmgt-public" />
    </el-form-item>
    <el-form-item label="是否管理员:">
      <el-radio-group v-model="currentStaff.manager">
        <el-radio-button label="1">是</el-radio-button>
        <el-radio-button label="0">否</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click.native="saveStaff">提交</el-button>
    <el-button @click.native="closeWin">取消</el-button>
  </span>
</div>
</template>

<script>
import { savePartnerStaff } from "@/api/partner_admin";
export default {
  name: "providerStaff",
  props: {
      staff: {
          type: Object
      }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "公司名称不能为空", trigger: "blur" }],
        telephone: [{ required: true, message: "联系电话不能为空", trigger: "blur" }],
      },
      currentStaff: {},
    };
  },
  mounted() {
      this.initPage();
  },
  methods: {
    initPage() {
        this.currentStaff = JSON.parse(JSON.stringify(this.staff));
    },
    saveStaff() {
      if (!this.currentStaff.name || !this.currentStaff.telephone) {
        return;
      }

      if (!this.currentStaff.roles) {
        this.currentStaff.roles = [];
      }
      let idx = this.currentStaff.roles.indexOf("manager");
      if (this.currentStaff.manager == 0 && idx != -1) {
        this.currentStaff.roles.splice(idx, 1);
      } else if (this.currentStaff.manager == 1 && idx == -1) {
        this.currentStaff.roles.push("manager");
      }
      savePartnerStaff(this.currentStaff).then((res) => {
        let staff = res.data;
        this.$emit("staffchange", staff, true);
        this.$message.success("保存成功。");
      });
    },
    closeWin() {
        this.$emit("staffchange", this.currentStaff, false);
    }
  },
};
</script>

<style scoped></style>
