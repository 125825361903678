<template>
  <el-drawer :visible.sync="visible" direction="ltr" :with-header="false" custom-class="drawer-modal" @opened="onOpen"
    :show-close="false" :before-close="beforeClose" :withHeader="false">
    <div class="header">
      <el-form size="small" :inline="true">
        <el-form-item label="地址搜索">
          <input id="locationSelectInput" type="text" />
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="onOK">确定选择</el-button>
        </el-form-item>
      </el-form>

    </div>

    <div id="locationSelectMap" class="map-b"></div>

  </el-drawer>
</template>

<script>
const markB = require("../assets/image/mark_b.png");

export default {
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    show(point, callback) {
      this.point = point;
      this.callback = callback;
      this.visible = true;
    },

    onOpen() {
      this.showMap();
    },

    showMap() {
      const map = new AMap.Map("locationSelectMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map = map;

      AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "locationSelectInput",
          city: "全国",
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.on("select", this.select);
      });

      const { name = "", lat, lng } = this.point || {};
      if (lat && lng) {
        this.addMarker([lng, lat], name);
      }
    },

    onOK() {
      if (this.marker) {
        this.visible = false;
        const { lat, lng } = this.marker.getPosition();
        this.callback({ lat, lng });
      } else {
        this.$message.error("请确定一个地点");
      }
    },

    select(info) {
      this.info = info;

      this.addMarker(info.poi.location, info.poi.name);
    },

    addMarker(position, title) {
      if (this.marker) {
        this.map.remove(this.marker);
      }

      const marker = new AMap.Marker({
        position,
        title,
        icon: new AMap.Icon({
          image: markB,
        }),
        draggable: true,
        label: {
          //修改label相对于maker的位置
          direction: "top",
          content: "<div class='info active'>" + title + "</div>",
        },
      });

      this.marker = marker;
      this.map.add(marker);
      this.map.setFitView();
    },

    beforeClose(done) {
      if (this.map) {
        this.map.clearMap();
      }
      done && done();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 50% !important;
}

#locationSelectMap {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 16px 0 0 20px;
  background-color: #ddd;
}

#locationSelectInput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}
</style>
