<template>
  <div style="margin: 24px">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">通用题库</el-menu-item>
      <el-menu-item index="2">车队题库</el-menu-item>
    </el-menu>

    <el-dialog title="设置依赖、车队、题库编号" :visible.sync="showDependencyDialog" width="450px">
      <el-select v-model="selectDependency" multiple placeholder="请选择题库">
        <el-option
                v-for="item in dependency"
                :key="item.id"
                :label="item.name"
                :value="item.id"
        >
        </el-option>
      </el-select>

      <el-input v-model="seqNo" placeholder="题库编号" style="width: 217px; margin-top: 10px;"></el-input>

      <el-select v-model="selectTeam" multiple clearable placeholder="请选择车队" style="margin-top: 10px;">
        <el-option
                v-for="item in teams"
                :key="item.id"
                :label="item.name"
                :value="item.id"
        >
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showDependencyDialog = false">取 消</el-button>
        <el-button type="primary" @click="doDependencyBankInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-card
      v-for="bankInfo in bankList"
      :key="bankInfo.id"
      class="card"
      @click.native="editBankInfo(bankInfo)"
    >
      <el-row class="card-title"
        >{{ bankInfo.title2 ? bankInfo.title2 : bankInfo.title }}&nbsp;(共{{ bankInfo.itmNums }}题)</el-row
      >
      <el-row class="card-uptime">更新时间：{{ bankInfo.uptime1 }}</el-row>
      <el-row class="card-bottom">
        <el-col :span="8">
          <i v-if="!bankInfo.isPub" class="el-icon-s-promotion" @click.stop="pubBankInfo(bankInfo)"></i>
          <i v-else class="el-icon-s-release" @click.stop="revokeBankInfo(bankInfo)"></i>
          <i class="el-icon-delete" style="margin-left: 16px;" @click.stop="deleteBankInfo(bankInfo)"></i>
          <i class="el-icon-setting" style="margin-left: 16px;" @click.stop="setDependencyBankInfo(bankInfo)"></i>
        </el-col>
        <el-col
          :span="16"
          style="text-align: right"
          >{{ bankInfo.teamAndPubStr }}</el-col
        >
      </el-row>
    </el-card>

    <el-card class="card add" @click.native="addBankInfo">
      <el-row class="add-icon">
        <i class="el-icon-plus"></i>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "BankList",
  data () {
    return {
      showDependencyDialog: false,
      activeIndex: "2",
      bankList: [],
      teamBankList: [],
      commBankList: [],
      totalBank: [],
      selectBank: null,
      teams: [],
      dependency: [],
      selectTeam: [],
      selectDependency: [],
      seqNo: ""
    };
  },
  created () {},
  mounted () {
    this.initPage();
  },
  methods: {
    initPage () {
      let param = {};
      let url = "/admin/exam/bank/list";
      this.$http.post(url, param).then((res) => {
        this.teams = res.data.teams || [];
        let banks = res.data.banks || [];
        this.totalBank = banks;
        this.refreshBankGroup();
        if (this.activeIndex == "1") {
          this.bankList = this.commBankList;
        } else {
          this.bankList = this.teamBankList;
        }
      });
    },
    refreshBankGroup () {
      this.teamBankList = [];
      this.commBankList = [];
      this.totalBank.forEach(bank => {
        bank.uptime1 = bank.uptime ? bank.uptime.substring(0, 16) : "";
        bank.teamAndPubStr = "";
        if (bank.team) {
          let bankTeams = bank.team.split(",");
          for (var i = 0; i < this.teams.length; i ++) {
            for (var j = 0; j < bankTeams.length; j ++) {
              if (bankTeams[j] == this.teams[i].id) {
                bank.teamAndPubStr += this.teams[i].name.substr(0, 2);
                break;
              }
            }
          }
          this.teamBankList.push(bank);
          bank.teamAndPubStr += "车队";
        } else {
          this.commBankList.push(bank);
        }
        bank.teamAndPubStr += (bank.isPub ? "已发布" : "未发布");
      });
    },
    handleSelect (key) {
      this.activeIndex = key;
      if (this.activeIndex == "1") {
        this.bankList = this.commBankList;
      } else {
        this.bankList = this.teamBankList;
      }
    },
    addBankInfo () {
      this.selectBank = null;
      this.$router.push({name: "BankInfo"});
    },
    editBankInfo (bankInfo) {
      this.$router.push({name: "BankInfo", query: {bankId: bankInfo.id}});
    },
    setDependencyBankInfo (bankInfo) {
      this.showDependencyDialog = true;
      this.selectDependency = bankInfo.dependency;
      this.seqNo = bankInfo.seq;
      this.selectTeam = [];
      if (bankInfo.team) {
        var teams = bankInfo.team.split(",");
        for (let i = 0; i < teams.length; i ++) {
          this.selectTeam.push(parseInt(teams[i]));
        }
      }
      this.selectBank = bankInfo;
      this.dependency = [];
      for (let i = 0 ; i < this.totalBank.length; i ++) {
        if (this.totalBank[i].id == bankInfo.id) {
          continue;
        }
        this.dependency.push({id: this.totalBank[i].id, name: this.totalBank[i].title });
      }
    },
    doDependencyBankInfo () {
      let params = {
        bankId: this.selectBank.id,
        dependency: this.selectDependency,
        seq: this.seqNo
      };
      if (this.selectTeam.length) {
        params.team = this.selectTeam.join(",");
      }
      let url = "/admin/exam/bank/setdependency";
      this.$http.post(url, params).then(() => {
        this.selectBank.dependency = this.selectDependency;
        this.selectBank.seq = this.seqNo;
        if (this.selectTeam.length) {
          this.selectBank.team = this.selectTeam.join(",");
        }
        this.showDependencyDialog = false;
        this.$message.success("修改成功。");
      });
    },
    pubBankInfo (bankInfo) {
      if (bankInfo.isPub) {
        return;
      }
      this.$confirm('发布题库， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {"bankId": bankInfo.id};
        let url = "/admin/exam/bank/pub";
        this.$http.post(url, param).then((res) => {
          if (res.code == 200) {
            this.$message.success("题库发布成功。");
            this.$set(bankInfo, "isPub", true);
          }
        });
      });
    },
    revokeBankInfo (bankInfo) {
      if (!bankInfo.isPub) {
        return;
      }
      this.$confirm('撤销发布，用户将无法使用该题库， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {"bankId": bankInfo.id};
        let url = "/admin/exam/bank/revoke";
        this.$http.post(url, param).then((res) => {
          if (res.code == 200) {
            this.$message.success("题库发布已撤销。");
            this.$set(bankInfo, "isPub", false);
          }
        });
      });
    },
    deleteBankInfo (bankInfo) {
      this.$confirm('删除题库， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {"bankId": bankInfo.id};
        let url = "/admin/exam/bank/delete";
        this.$http.post(url, param).then((res) => {
          if (res.code == 200) {
            for (var i = 0; i < this.bankList.length; i ++) {
              if (this.bankList[i].id == bankInfo.id) {
                this.bankList.splice(i, 1);
              }
            }
            this.$message.success("题库删除成功。");
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.card {
  width: 300px;
  height: 155px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.card .card-title {
  font-size: 18px;
  font-weight: 600;
}
.card .el-row {
  margin: 12px 0;
}
.card .add-icon {
  text-align: center;
  text-align: center;
  font-size: 32px;
  margin-top: 32px;
}
</style>