<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small"
                 :inline="true">
          <!-- <el-form-item label="路线："
                        prop="pathId">
            <el-select v-model="param.pathId"
                       placeholder="请选择"
                       @change="query">
              <el-option v-for="item in pathOpts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="规则类型："
                        prop="ruleType">
            <el-select clearable
                       v-model="param.ruleType"
                       @change="query"
                       placeholder="请选择">
              <el-option v-for="item in ruleTypeOpts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px"
                       type="primary"
                       @click="query">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary"
                   style="width: 100px"
                   :size="size"
                   @click="openAdd">新增退票规则</el-button>
      </div>
    </div>

    <el-table :data="list"
              stripe
              max-height="600">
      <el-table-column label="序号"
                       type="index"
                       width="100"></el-table-column>

      <!-- <el-table-column prop="pathId"
                       label="路线名称">
        <template slot-scope="scope">
          <div>{{ scope.row.pathId | pathNameTransfer }}</div>
        </template>
      </el-table-column> -->

      <el-table-column prop="timeRange"
                       label="时间"></el-table-column>

      <el-table-column prop="type"
                       label="规则类型">
        <template slot-scope="scope">
          <div>{{ scope.row.ruleType | enumTransfer(ruleTypeOpts) }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="type"
                       label="费率类型">
        <template slot-scope="scope">
          <div>{{ scope.row.feeType | enumTransfer(feeTypeOpts) }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="fee"
                       label="费率"></el-table-column>

      <el-table-column prop="ruleDesc"
                       label="说明"></el-table-column>

      <el-table-column prop="id"
                       label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text"
                       size="small"
                       @click="openEdit(scope.row)">修改</el-button>

            <el-button type="text"
                       size="small"
                       @click="delRule(scope.row)">
              删除
            </el-button>

            <el-button type="text"
                       size="small"
                       @click="openEdit(scope.row, true)">复制</el-button>

          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="visible"
               width="600px"
               max-height="600"
               :size="size"
               :title="title"
               :close-on-click-modal="false">
      <el-form ref="form"
               :model="form"
               :size="size"
               label-width="120px"
               :rules="rules">
        <!-- <el-form-item label="路线："
                      prop="pathId">
          <el-select v-model="form.pathId"
                     style="width: 100%">
            <el-option v-for="item in pathOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="时间："
                      prop="timeRange">
          <el-input-number style="width: 100%"
                           v-model="form.timeRange"
                           placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="规则类型："
                      prop="ruleType">
          <el-select v-model="form.ruleType"
                     placeholder="请选择"
                     style="width: 100%"
                     :disabled="this.mode === MODE.EDIT">
            <el-option v-for="item in ruleTypeOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费率类型："
                      prop="feeType">
          <el-select v-model="form.feeType"
                     placeholder="请选择"
                     style="width: 100%">
            <el-option v-for="item in feeTypeOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费率值："
                      prop="fee">
          <el-input-number style="width: 100%"
                           v-model="form.fee"
                           placeholder></el-input-number>
        </el-form-item>

        <el-form-item label="规则说明："
                      prop="ruleDesc">
          <el-input style="width: 100%"
                    v-model="form.ruleDesc"
                    placeholder></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning"
                   :size="size"
                   @click="saveOrUpdate">保存</el-button>
        <el-button type="info"
                   :size="size"
                   @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listRefundRule,
  addRefundRule,
  updateRefundRule,
  delRefundRule,
} from "@/api";
import _ from "lodash";
import { Toolkit } from "@/util";
import { mapState } from "vuex";
import config from "@/config/config";

const MODE = {
  EDIT: 1,
  ADD: 2,
};
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? "新增退票规则" : "修改退票规则";
    },
    ...mapState({
      size: (state) => state.btnSize,
      ruleTypeOpts: (state) => state.enumMap["REFUND_RULE_TYPE"],
      feeTypeOpts: (state) => state.enumMap["REFUND_FEE_TYPE"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  data () {
    return {
      MODE,
      visible: false,
      param: {
        pathId: config.initPath,
      },
      form: {},
      list: [],
      mode: MODE.EDIT,
      rules: {
        pathId: [
          {
            required: true,
            message: "路线不能为空",
            trigger: "blur",
          },
        ],
        ruleType: [
          {
            required: true,
            message: "规则类型不能为空",
            trigger: "blur",
          },
        ],
        feeType: [
          {
            required: true,
            message: "费率类型不能为空",
            trigger: "blur",
          },
        ],
        timeRange: [
          {
            required: true,
            message: "时间不能为空",
            trigger: "blur",
          },
        ],
        fee: [
          {
            required: true,
            message: "费率不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    closeDiag () {
      this.visible = false;
    },
    openEdit (item, copy) {
      this.mode = copy ? MODE.ADD : MODE.EDIT;
      this.visible = true;
      const data = _.cloneDeep(item);
      // 如果是复制记录，则需要删除id
      if (copy) {
        delete data.id;
      }
      this.form = data;
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {
        state: true,
      };
    },

    saveOrUpdate () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const data = _.cloneDeep(this.form);
        if (data.relPathIds) {
          data.relPathIds = data.relPathIds.join(",");
        }
        if (this.mode === MODE.EDIT) {
          updateRefundRule(data).then(() => {
            this.$message.success("退票规则修改成功");
            this.visible = false;
            this.query();
          });
        } else {
          addRefundRule(data).then(() => {
            this.$message.success("退票规则新增成功");
            this.visible = false;
            this.query();
          });
        }
      });
    },

    delRule (item) {
      this.$confirm("确认删除该规则吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRefundRule(item).then(() => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.visible = false;
          this.query();
        });
      });
    },

    query () {
      listRefundRule(Toolkit.filterEmpty(this.param)).then((res) => {
        let data = res.data || [];
        data = data.map((item) => {
          if (item.relPathIds) {
            item.relPathIds = item.relPathIds
              .split(",")
              .map((item) => parseInt(item));
          }

          return item;
        });
        this.list = data;
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
