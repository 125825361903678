<template>
  <div class="root-box">
    <div class="operate-container">
      <el-button type="primary" :size="size" @click="openAdd"
        >新增区域</el-button
      >
    </div>

    <el-table :data="list" stripe max-height="600">
      <el-table-column label="序号" type="index" width="100"></el-table-column>

      <el-table-column prop="name" label="区域名称"></el-table-column>

      <el-table-column prop="cityCode" label="区号"></el-table-column>

      <el-table-column prop="adcode" label="区域编码"></el-table-column>

      <el-table-column prop="sort" label="区域显示顺序"></el-table-column>

      <el-table-column prop="state" label="是否启用">
        <template slot-scope="scope">{{
          scope.row.state | enumTransfer(yesNoOpts)
        }}</template>
      </el-table-column>

      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)"
              >修改</el-button
            >
            <!--                            <el-button type="text" size="small" @click="deletePerm(scope.row)">删除</el-button>-->
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :size="size"
      :title="title"
      :close-on-click-modal="false"
    >
      <el-form
        ref="userForm"
        :model="form"
        :size="size"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="区域名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="区号：" prop="cityCode">
          <el-input v-model="form.cityCode"></el-input>
        </el-form-item>

        <el-form-item label="区域编码：" prop="adcode">
          <el-input v-model="form.adcode"></el-input>
        </el-form-item>

        <el-form-item label="是否启用：" prop="state">
          <el-select
            v-model="form.state"
            placeholder="请选择"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域显示顺序：" prop="text">
          <el-input-number
            style="width: 100%"
            v-model="form.sort"
            placeholder
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addArea, modifyArea, listArea} from '@/api';
import _ from 'lodash';
import {BaseDataService} from '@/service';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.query();
  },
  computed: {
    title () {
      return this.mode === MODE.ADD ? '新增区域' : '修改区域';
    },
    ...mapState({
      size: state => state.btnSize
    })
  },
  data () {
    return {
      MODE,
      visible: false,
      form: {},
      list: [],
      mode: MODE.EDIT,
      yesNoOpts: BaseDataService.yesNoOpts(),
      rules: {
        cityCode: [
          {required: true, message: '请输入区号', trigger: 'blur'},
        ],
        adcode: [
          {required: true, message: '请输入区域编码', trigger: 'blur'},
        ],

        name: [
          {required: true, message: '请输入区域名称', trigger: 'blur'},
        ],
      }
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.visible = true;
      this.form = _.cloneDeep(item);
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {};
    },
    saveOrUpdate () {
      const {id, name, sort, state, cityCode, adcode} = this.form;
      const data = {
        name,
        sort,
        state,
        cityCode,
        adcode
      };
      if (this.mode === MODE.EDIT) {
        data.id = id;
        modifyArea(data).then(() => {
          this.$message.success("区域修改成功");
          this.visible = false;
          this.query();
        });
      } else {
        addArea(data).then(() => {
          this.$message.success("区域新增成功");
          this.visible = false;
          this.query();
        });
      }

    },
    query () {
      listArea({}).then(res=>{
        this.list = res.data || [];
      });
    }

  }
};
</script>

<style scoped lang="less">
.table {
  margin-top: 10px;
}

.operate-div {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.menu {
  color: #2aaaff;
}

.button {
  color: #37c604;
}
</style>
