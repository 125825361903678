<template>
  <div class="root-box">
    <div class="content">
      <div class="title">
        <span class="fixed">订单详情</span>
      </div>
      <div class="detail">
        <div class="box">
          <el-timeline>
            <el-timeline-item placement="top" v-if="order">
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{ order.createTime }}</span>
                  <div class="status">乘客下单</div>
                </div>
                <div class="item">
                  <i class="el-icon-user-solid" />
                  <div>
                    乘客电话： {{ order.telephone | encryptTel }}
                    <span class="appoint" v-if="order.appointTelephone"
                      >代：{{ order.appointTelephone | encryptTel }}</span
                    >
                  </div>
                </div>

                <div class="item">
                  <i class="el-icon-s-order" />
                  <div>
                    订单概要： {{ order.pathId | pathNameTransfer }} &nbsp;
                    {{ order.date }} &nbsp; {{ order.showTime }}
                  </div>
                </div>

                <div class="item blue">
                  <i class="iconfont">&#xe618;</i>
                  {{ order.departure }}
                </div>
                <div class="item orange">
                  <i class="iconfont">&#xe618;</i>
                  {{ order.destination }}
                </div>

                <div class="item">
                  <i class="iconfont">&#xe608;</i>
                  <div>
                    行程总价：{{ order.price | moneyFormat }}（元）
                    <span v-operate-order:bill="order.id">明细</span>
                  </div>
                </div>
              </el-card>
            </el-timeline-item>

            <el-timeline-item placement="top" v-if="driver">
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{ order.matchTime }}</span>
                  <div class="status">指派司机</div>
                </div>
                <div class="item">
                  <i class="el-icon-postcard" />
                  <div>
                    {{ driver.no }} {{ driver.name }} {{ driver.telephone }}
                  </div>
                </div>
                <div class="item">
                  <i class="el-icon-wind-power" />
                  <div>
                    {{ driver.vehicleNo }} {{ driver.vehicleColor }}
                    {{ driver.brand }}
                  </div>
                </div>
              </el-card>
            </el-timeline-item>

            <el-timeline-item
              placement="top"
              v-if="order && order.orderStatus === 'CANCEL'"
            >
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{ order.createTime }}</span>
                  <div class="status text-red">
                    {{ order.cancelType | enumTransfer(cancelTypeOpts) }}
                  </div>
                </div>
                <div class="detail1" v-if="order.cancelDesc">
                  <div>取消原因：{{ order.cancelDesc }}</div>
                </div>
              </el-card>
            </el-timeline-item>

            <template v-if="routeDetail">
              <el-timeline-item
                placement="top"
                v-for="item in routeDetail.status"
                :key="item"
              >
                <el-card>
                  <div v-if="item === 1">
                    <div class="item">
                      <i class="el-icon-time text-blue" />
                      <span class="text-blue">{{ routeDetail.startTime }}</span>
                      <div class="status">司机出发</div>
                    </div>

                    <div
                      class="item pointer"
                      @click="
                        showMarker(
                          routeDetail.startLatitude,
                          routeDetail.startLongitude,
                          routeDetail.startPosition,
                          '司机出发'
                        )
                      "
                    >
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        {{ routeDetail.startPosition }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="item === 2">
                    <div class="item">
                      <i class="el-icon-time text-blue" />
                      <span class="text-blue">{{
                        routeDetail.arriveTime
                      }}</span>
                      <div class="status">司机到达乘客上车地点</div>
                    </div>

                    <div
                      class="item pointer"
                      @click="
                        showMarker(
                          routeDetail.arriveLatitude,
                          routeDetail.arriveLongitude,
                          routeDetail.arrivePosition,
                          '司机到达上车点'
                        )
                      "
                    >
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        {{ routeDetail.arrivePosition }}</span
                      >
                    </div>

                    <div class="item">
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        空驶里程：{{ routeDetail.waitMile | mileFilter }}</span
                      >
                    </div>

                    <div class="item" v-if="routeDetail.depWrongReason">
                      <i class="iconfont">&#xe64c;</i>
                      <span class="text-blue">
                        位置偏离原因：{{
                          routeDetail.depWrongReason
                            | enumTransfer(wrongStartPositionOpts)
                        }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="item === 3">
                    <div class="item">
                      <i class="el-icon-time text-blue" />
                      <span class="text-blue">{{ routeDetail.depTime }}</span>
                      <div class="status">乘客上车</div>
                    </div>

                    <div
                      class="item pointer"
                      @click="
                        showMarker(
                          routeDetail.depLatitude,
                          routeDetail.depLongitude,
                          routeDetail.departure,
                          '乘客上车'
                        )
                      "
                    >
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        {{ routeDetail.departure }}</span
                      >
                    </div>

                    <div class="item">
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        等待时间：{{ routeDetail.waitTime | timeFilter }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="item === 4">
                    <div class="item">
                      <i class="el-icon-time text-blue" />
                      <span class="text-blue">{{ routeDetail.destTime }}</span>
                      <div class="status">到达目的地</div>
                    </div>

                    <div
                      class="item pointer"
                      @click="
                        showMarker(
                          routeDetail.destLatitude,
                          routeDetail.destLongitude,
                          routeDetail.destination,
                          '到达目的地'
                        )
                      "
                    >
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        {{ routeDetail.destination }}</span
                      >
                    </div>

                    <div class="item" v-if="routeDetail.destWrongReason">
                      <i class="iconfont">&#xe64c;</i>
                      <span class="text-blue">
                        位置偏离原因：{{
                          routeDetail.destWrongReason
                            | enumTransfer(wrongEndPositionOpts)
                        }}</span
                      >
                    </div>
                  </div>

                  <div v-else-if="item === 5">
                    <div class="item">
                      <i class="el-icon-time text-blue" />
                      <span class="text-blue">{{
                        routeDetail.finishTime
                      }}</span>
                      <div class="status">完成订单</div>
                    </div>

                    <div class="item">
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        总时间：{{ routeDetail.totalTime | timeFilter }}</span
                      >
                    </div>
                    <div class="item">
                      <i class="iconfont">&#xe600;</i>
                      <span class="text-blue">
                        总里程：{{ routeDetail.totalMile | mileFilter }}</span
                      >
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </template>

            <el-timeline-item placement="top" v-if="orderBill">
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{ orderBill.payTime }}</span>
                  <div class="status">乘客支付</div>
                </div>
                <div class="detail1">
                  <div class="text-red">
                    订单金额（元）：{{ orderBill.price | moneyFormat }}
                  </div>
                  <div class="text-red">
                    实际支付金额（元）：{{ orderBill.factPrice | moneyFormat }}
                  </div>
                  <div>支付方式：{{ orderBill.line_name || "现金支付" }}</div>
                </div>
              </el-card>
            </el-timeline-item>

            <el-timeline-item placement="top" v-if="evaluation">
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{ evaluation.evaluateTime }}</span>
                  <div class="status">乘客评价</div>
                </div>

                <div class="detail1">
                  <div class="rate">
                    评分：
                    <el-rate
                      show-score
                      disabled
                      text-color="#ff9900"
                      v-model="evaluation.serviceScore"
                    ></el-rate>
                  </div>
                  <div>评价内容： {{ evaluation.detail }}</div>
                </div>
              </el-card>
            </el-timeline-item>

            <el-timeline-item
              placement="top"
              v-if="evaluation && evaluation.driverEvaluateScore"
            >
              <el-card>
                <div class="item">
                  <i class="el-icon-time text-blue" />
                  <span class="text-blue">{{
                    evaluation.driverEvaluateTime
                  }}</span>
                  <div class="status">司机评价</div>
                </div>

                <div class="detail1">
                  <div class="rate">
                    评分：
                    <el-rate
                      show-score
                      disabled
                      text-color="#ff9900"
                      v-model="evaluation.driverEvaluateScore"
                    ></el-rate>
                  </div>
                  <div>评价内容： {{ evaluation.driverEvaluateDetail }}</div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>

    <div class="map">
      <div class="title">
        <span>订单地图</span>
      </div>

      <div id="detail-map"></div>

      <div class="btns">
        <div class="source">
          定位来源：
          <el-radio-group v-model="source" @change="route(true)">
            <el-radio label="jimi">GPS</el-radio>
            <el-radio label="driver">手机</el-radio>
          </el-radio-group>
        </div>

        <div>
          <el-button @click="clearMap" size="small" type="primary" plain
            >清除地图</el-button
          >
          <el-button @click="route" size="small" type="primary" plain
            >轨迹回放</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const startPng = require('../../../assets/image/start.png');
const endPng = require('../../../assets/image/end.png');
import {getOrderRouteDetail, getDriverTrack} from '@/api';
import { DateUtil, UnitUtil } from '@/util';
import {mapState} from 'vuex';

export default {
  filters: {
    timeFilter (val = 0) {
      return UnitUtil.accurateTimeFilter(val);
    },
  },
  created () {
    const orderId = this.$route.query.id;
    if (orderId) {
      this.orderId = orderId;
      this.init();
    }
  },
  mounted () {
    this.initMap();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      cancelTypeOpts: state => state.enumMap['ORDER_CANCEL_TYPE'],
      wrongStartPositionOpts: state => state.enumMap['WRONG_POSITION_START'],
      wrongEndPositionOpts: state => state.enumMap['WRONG_POSITION_END'],
    })
  },
  data () {
    return {
      order: null,
      routeDetail: null,
      orderBill: null,
      evaluation: null,
      driver: null,
      source: 'jimi',
      addressMarkers: []
    };
  },
  methods: {
    init () {
      getOrderRouteDetail(this.orderId).then((res) => {
        const {order, orderBill, routeDetail, evaluation, driver} = res.data || {};
        this.order = order;
        this.routeDetail = routeDetail;
        this.orderBill = orderBill;
        this.evaluation = evaluation;
        this.driver = driver;
        this.createStartAndEnd();
      });
    },
    initMap () {
      const map = new AMap.Map('detail-map', {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14
      });

      this.instance = map;
      AMap.plugin(['AMap.Scale', 'AMap.Driving', 'AMap.MoveAnimation'], () =>{
        var toolbar = new AMap.Scale();
        map.addControl(toolbar);
        this.driving = new AMap.Driving({
          policy: AMap.DrivingPolicy.LEAST_TIME,
          autoFitView: true,
          hideMarkers: true,
          map
        });
        if (this.order) {
          const {depLongitude, depLatitude, destLongitude, destLatitude} = this.order ;
          this.driving.search([depLongitude, depLatitude], [destLongitude, destLatitude]);
        }
      });
    },
    clearMap () {
      this.instance.remove(this.addressMarkers);
    },
    createStartAndEnd () {

      const {depLongitude, depLatitude, destLongitude, destLatitude, departure, destination} = this.order || {};

      const iconSize = 36;
      const map = this.instance;
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(iconSize, iconSize),
        // 图标的取图地址
        image: startPng,
        // 图标所用图片大小
        imageSize: new AMap.Size(iconSize, iconSize),

      });

      // 将 icon 传入 marker
      const startMarker = new AMap.Marker({
        position: new AMap.LngLat(depLongitude, depLatitude),
        icon: startIcon,
        label: {
          direction: 'top',
          content: "<div class='info'>" + `乘客起点：${departure} ` + "</div>",
        },
      });

      const endIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(iconSize, iconSize),
        // 图标的取图地址
        image: endPng,
        // 图标所用图片大小
        imageSize: new AMap.Size(iconSize, iconSize),
      });

      // 将 icon 传入 marker
      const endMarker = new AMap.Marker({
        position: new AMap.LngLat(destLongitude, destLatitude),
        icon: endIcon,
        label: {
          direction: 'top',
          content: "<div class='info'>" + `乘客终点：${destination} ` + "</div>",
        },
      });

      map.add([startMarker, endMarker]);
      map.setFitView();
      this.driving && this.driving.search([depLongitude, depLatitude], [destLongitude, destLatitude]);
    },

    route (forceRefresh = false) {
      const map = this.instance;

      if (forceRefresh) {
        map.clearMap();
        this.createStartAndEnd();
      } else if (this.marker) {
        this.marker.moveAlong(this.lineArr, 500);
        return;
      }

      let {startTime, destTime} = this.routeDetail || {};

      if (!startTime) {
        this.$message.error('司机还未出发');
        return;
      }

      if (!destTime) {
        destTime = DateUtil.getDateTimeStr();
      }


      const queryParam = {
        driverId: this.driver.id,
        startTime: startTime,
        endTime: destTime,
        vendor: this.source
      };

      getDriverTrack(queryParam).then((res) => {
        const {locations = [], locationStartTime, locationEndTime} = res.data || {};
        const lineArr = [];
        locations.forEach(item => {
          lineArr.push([+item[0], +item[1]]);
        });

        this.lineArr = lineArr;
        this.locationStartTime = locationStartTime;
        this.locationEndTime = locationEndTime;


        this.driving && this.driving.clear();
        const marker = new AMap.Marker({
          map: map,
          position: lineArr[0],
          icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
          offset: new AMap.Pixel(-13, -26),
        });


        // 绘制轨迹
        new AMap.Polyline({
          map: map,
          path: lineArr,
          showDir: true,
          strokeColor: "#28F", //线颜色
          strokeOpacity: 0.5, //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });

        const passedPolyline = new AMap.Polyline({
          map: map,
          strokeColor: "#AF5", //线颜色
          strokeWeight: 6, //线宽
        });



        marker.on('moving', function (e) {
          passedPolyline.setPath(e.passedPath);
        });


        marker.moveAlong(lineArr, {
          // 每一段的时长
          duration: 500,
          // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
          autoRotation: true,
        });

        this.marker = marker;
        this.lineArr = lineArr;
      });
    },
    showMarker (lat, lng, name, title) {
      const map = this.instance;
      const marker = new AMap.Marker({
        map: map,
        position: [lng, lat],
        draggable: true,
        label: {
          direction: 'top',
          content: "<div class='info'>" + `${title}：${name} ` + "</div>",
        },
      });
      map.add(marker);
      this.addressMarkers.push(marker);
    }
  }
};
</script>

<style scoped lang="less">
.root-box {
  height: calc(100% - 30px);
  //   background-color: #edf1f5;

  display: flex;

  .appoint {
    margin-left: 10px;
  }

  .content {
    width: 700px;
    height: calc(100% - 10px);
    .detail {
      margin: 10px 0;
      .box {
        height: 780px;
        overflow: auto;
        padding-right: 30px;
        .item {
          align-items: center;
          margin-bottom: 8px;
          display: flex;
          font-size: 15px;
          color: #90a4ae;

          &.orange {
            color: orange;
          }

          &.blue {
            color: #2ba6ef;
          }

          i {
            margin-right: 10px;
          }

          .status {
            margin-left: 20px;
            color: #303133;

            &.text-red {
              color: red;
            }
          }
        }
      }
    }
  }

  .title {
    padding: 8px;
    color: #2aaaff;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    span {
      border-left: #2aaaff 3px solid;
      padding-left: 10px;
      font-size: 15px;
      position: relative;
    }
  }

  .map {
    margin-left: 20px;
    flex: 1;
    padding-left: 20px;

    .title {
      position: relative;
      left: -20px;
    }
  }

  #detail-map {
    margin-top: 20px;
    width: 100%;
    height: 720px;
    border: 1px solid #ddd;
  }

  .btns {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .source {
      font-size: 15px;
    }
  }
}

.detail1 {
  > div {
    margin-left: 25px;
    margin-top: 5px;
    color: #6d7f8e;
  }

  .text-red {
    color: var(--red);
  }
}

.text-blue {
  color: #2ba6ef;
}

.rate {
  > div {
    display: inline-block;
  }
  margin: 5px 0;
}

.flex {
  display: flex;
}
</style>
