import Vue from "vue";
import store from "@/store";

/**
 * 订单操作
 */

const handle = (el, binding) => {
  const source = binding.value;
  const opts = store.state.enumMap["ORDER_SOURCE"];
  if (source === 1 || source === 3 || source === 4) {
    el.classList.add("dir-red");
  } else {
    el.classList.remove("dir-red");
  }
  if (opts && source) {
    const item = opts.find((i) => i.value === source);
    el.innerText = item.label;
  }
};

Vue.directive("orderSource", {
  bind: function (el, binding) {
    handle(el, binding);
  },
  update: function (el, binding) {
    handle(el, binding);
  },
  unbind: function (el) {
    el.innerText = "";
    el.classList.remove("dir-red");
  },
});
