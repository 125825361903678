<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="路线：">
          <el-select
            v-model="param.pathId"
            placeholder="请选择"
            @change="queryOrders"
            clearable
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.date"
            @change="queryOrders"
            style="width: 200px"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="时间">
          <el-time-select
            @change="queryOrders"
            v-model="param.startTime"
            style="width: 150px"
            :picker-options="timeOpts"
            placeholder="开始时间"
          >
          </el-time-select>

          &nbsp;&nbsp;&nbsp;

          <el-time-select
            @change="queryOrders"
            v-model="param.endTime"
            style="width: 150px"
            :picker-options="timeOpts"
            placeholder="结束时间"
          >
          </el-time-select>
        </el-form-item>

        <el-form-item>
          <el-button
            style="margin-right: 20px"
            type="primary"
            @click="queryOrders"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button style="margin-right: 20px" type="primary" @click="calc1"
            >计算(Jsprit)</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button style="margin-right: 20px" type="primary" @click="calc2"
            >计算(Planner)</el-button
          >
        </el-form-item>

        <el-button
          style="float: right"
          type="primary"
          size="small"
          @click="drawer = true"
          >显示计算结果</el-button
        >
      </el-form>
    </el-row>

    <div class="content">
      <div class="order-list">
        <el-table
          :data="orderList"
          stripe
          max-height="700"
          ref="table"
          @select="onSelect"
          current-row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            show-overflow-tooltip
            width="50"
          ></el-table-column>

          <el-table-column
            prop="showTime"
            width="120"
            label="时间"
          ></el-table-column>

          <el-table-column
            prop="departure"
            width="120"
            label="时间"
          ></el-table-column>

          <el-table-column
            prop="destination"
            width="120"
            label="时间"
          ></el-table-column>
        </el-table>
      </div>
      <div class="map-box">
        <div class="map" id="map"></div>
      </div>
    </div>

    <el-drawer
      title="派单计划"
      :visible.sync="drawer"
      direction="ltr"
      :modal="false"
      custom-class="dispatch-drawer"
    >
      <div class="drawer-box" v-if="drawer && calcType === 1 && vrpSolution">
        <div>行程最早开始时间： {{ calcTime }}</div>
        <div
          v-for="item in vrpSolution.routes"
          :key="item.vehicle.id"
          class="item"
        >
          <div class="driver">
            派单司机：{{ item.vehicle.id }}
            <el-button type="text" @click="showRoute(item.activities)"
              >显示路径</el-button
            >
          </div>

          <div v-for="act in item.activities" :key="act.index">
            <div class="address">
              {{ orderMap[act.job.id].index }}、{{
                orderMap[act.job.id].number
              }}人
              {{ orderMap[act.job.id].showTime }}
              {{ act.job.name }}
            </div>
            <div class="time">
              到达时间 {{ act.arrTime | toStartTime(calcTime) }}，再出发时间
              {{ act.endTime | toStartTime(calcTime) }}
            </div>
          </div>
        </div>
      </div>

      <div class="drawer-box" v-if="drawer && calcType === 2 && vrpSolution2">
        <div style="margin: 6px 0; font-size: 14px">
          共计分派{{ vrpSolution2.totalNum }}人，其中4座车{{
            vrpSolution2.fourSeatsCar
          }}辆，6座车{{ vrpSolution2.sixSeatsCar }}辆。
          <span
            :class="{ red: !vrpSolution2.score.feasible }"
            style="font-size: 14px"
          >
            {{ vrpSolution2.score.feasible ? "满足硬性要求" : "满足硬性要求" }}
          </span>
        </div>
        <div style="margin: 6px 0; font-size: 14px">
          分派得分：SoftScore:{{ vrpSolution2.score.softScore }}，HardScore:{{
            vrpSolution2.score.hardScore
          }}
        </div>

        <div
          v-for="(orders, driverNo) in vrpSolution2.dispatchResult"
          :key="driverNo"
          class="item"
        >
          <div class="driver">
            派单司机：{{ driverNo }}
            <el-button type="text" @click="renderRoute2(driverNo)"
              >显示路径</el-button
            >
          </div>

          <div v-for="item in orders" :key="'' + item.orderId + item.start">
            <div class="address">
              {{ orderMap[item.orderId + ""].index }}、{{ item.demand }}人
              {{ orderMap[item.orderId + ""].showTime }}
              {{ item.location.name }}
            </div>
            <div class="time">
              到达时间{{ item.arrivalTime | minutesTransfer }}，再出发时间
              {{ item.departureTime | minutesTransfer }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {getOrderList, calcDispatch1, calcDispatch3} from '@/api';
import { DateUtil } from '@/util';
import moment from 'moment';

export default {
  components: {},
  filters: {
    toStartTime (val, startTime) {
      let time = moment(startTime, 'HH:mm:ss');
      time = time.add(val, 's');
      return time.format('HH:mm:ss');
    },

    minutesTransfer (val) {
      let hour = parseInt(val / 60) ;
      if (hour < 10) {
        hour = `0${hour}`;
      }
      let minutes = val % 60;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${hour}:${minutes}`;
    },
  },
  props: {},
  created () {},
  mounted () {
    this.initMap();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state => state.pathList,
    })
  },
  data () {
    return {
      param: {
        date: DateUtil.getDayStr(-20),
        pathId: 6,
      },
      timeOpts: {
        start: '00:00',
        step: '00:60',
        end: '23:00',
      },
      orderList: [],
      drawer: false,
      vrpSolution: null,
      vrpSolution2: null,
      orderMap: {},
      calcTime: '',
      calcType: 2,
    };
  },
  methods: {

    initMap () {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14
      });


      AMap.plugin(
        ["AMap.PlaceSearch", "AMap.AutoComplete", "AMap.Driving"],
        () => {
          const autoOptions = {
            // input 为绑定输入提示功能的input的DOM ID
            input: "tipinput",
            city: "武汉"
          };
          const autoComplete = new AMap.AutoComplete(autoOptions);
          autoComplete.on("select", this.select);

          this.driving = new AMap.Driving({
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            policy: AMap.DrivingPolicy.LEAST_TIME,
            map: this.map
          });
        }
      );
    },
    queryOrders () {
      const param = {...this.param} || {};
      if (param.startTime) {
        param.startTime = `${param.startTime}:00`;
      }
      if (param.endTime) {
        param.endTime = `${param.endTime}:00`;
      }
      const query = {
        page: {
          current: 1,
          size: 2000,
        },
        param,

      };
      getOrderList(query).then(res=>{
        const {records = []} = res.data || {};
        this.drawer = false;
        // 总人数
        let totalPerson = 0;
        records.forEach(item => {
          totalPerson += item.number;

        });
        this.totalPerson = totalPerson;
        this.orderList = records;
        this.handleData(this.orderList);
        this.$refs.table.toggleAllSelection();
      });
    },

    handleData (dataList) {
      this.map.clearMap();
      const markers = [];
      dataList.forEach((item, index)=>{

        markers.push(new AMap.Marker({
          position: [item.depLongitude, item.depLatitude],
          title: `${index + 1}`,
          label: {
            content: '<div class="info rec">' + `${index + 1} ${item.showTime}` + "</div>",
            direction: 'top',
          },
          draggable: true,
        }));

        markers.push(new AMap.Marker({
          position: [item.destLongitude, item.destLatitude],
          title: `${index + 1}`,
          label: {
            content: '<div class="info rec">' + `${index + 1} ${item.showTime}` + "</div>",
            direction: 'top',
          },
          draggable: true,
        }));


      });

      this.map.add(markers);
      this.map.setFitView();
    },

    onSelect (selection) {
      this.handleData(selection);
    },

    handleSelectionChange (val) {
      if (!val || val.length < 1) {
        return;
      }
      this.selectOrderIds = val.map(item=> item.id);
      const orderMap = {};
      val.forEach((item, index)=>{
        item.index = index + 1;
        orderMap[`${item.id}`] = item;
      });
      this.orderMap = orderMap;
      this.calcTime = val[0].time;
    },

    calc1 () {
      this.calcType = 0;
      calcDispatch1(this.selectOrderIds, this.param.pathId).then((res)=>{
        this.calcType = 1;
        this.vrpSolution = res.data || {};
        this.drawer = true;
        this.$message.success('操作成功');
      });
    },

    calc2 () {
      this.calcType = 0;
      calcDispatch3(this.selectOrderIds, this.param.pathId).then((res)=>{
        this.calcType = 2;
        this.vrpSolution2 = res.data || {} ;
        this.drawer = true;
        this.$message.success('操作成功');
      });
    },


    renderRoute2 (driverNo) {
      const solution = this.vrpSolution2.dispatchResult;
      if (solution[driverNo].length > 1) {
        const points = solution[driverNo].map(item=> [item.location.lng, item.location.lat]);
        const start = points[0];
        const end = points[points.length - 1];
        const waypoints = points.slice(1, points.length - 1);
        const opts = {
          // 途经点参数，最多支持传入16个途经点
          waypoints
        };

        this.driving.search(start, end, opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案

          console.log(status, result);
        });
      }
    },

    showRoute (acts) {
      const markers = [];
      acts.forEach(act=>{
        const id = act.job.id;
        const order = this.orderMap[id];
        markers.push(new AMap.Marker({
          position: [order.destLongitude, order.destLatitude],
          label: {
            content: '<div class="info rec">' + `${order.index} ${order.number}人 ${order.showTime}` + "</div>",
            direction: 'top',
          },
          draggable: true,
        }));
      });
      this.map.clearMap();
      this.map.add(markers);
      this.map.setFitView();
    }


  },
};
</script>
<style lang="less" scoped>
/deep/ .el-drawer__wrapper {
  width: 700px;

  .dispatch-drawer {
    width: 100% !important;
    border-right: 1px solid #ddd;
  }
}

.root-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .content {
    flex: 1;
    display: flex;
    .order-list {
      width: 500px;
      overflow: auto;
      padding: 10px;
    }

    .map-box {
      flex: 1;
      padding: 10px;
      .map {
        width: 100%;
        height: 100%;
      }
    }
  }

  .drawer-box {
    margin: 10px;
    overflow: auto;

    .item {
      margin-bottom: 40px;
      .driver {
        margin-top: 10px;
      }

      .time {
        font-size: 13px;
        margin-left: 28px;
        margin-top: 3px;
        color: #409eff;
        margin-bottom: 5px;
      }

      .address {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>