<template>
  <div>
    <el-form ref="form" :model="form" :size="size" label-width="120px" inline>
      <el-row>
        <el-form-item prop="task">
          <el-select
            v-model="form.task"
            placeholder="主考评对象"
            style="width: 160px"
            @change="handleChange"
          >
            <el-option
              v-for="item in taskOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <span v-if="!isFixedLogic(form.task)">
          <el-form-item prop="assessReach">
            <el-select v-model="form.assessReach" placeholder="考评逻辑" style="width: 160px">
              <el-option
                v-for="item in assessOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="assessValue">
            <el-input-number
              v-model="form.assessValue"
              placeholder="数值"
              style="width: 120px; margin-right: 10px"
              @change="handleAssessValueChange"
            ></el-input-number>
            <el-input
              v-model="form.showValue"
              placeholder="数值（显示）"
              style="width: 100px"
            ></el-input>
          </el-form-item>

          <el-form-item prop="assessSort" v-if="isMainItem">
            <el-select v-model="form.assessSort" placeholder="评分逻辑" style="width: 160px">
              <el-option
                v-for="item in sortOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </span>

        <el-form-item prop="taskName">
          <el-input v-model="form.taskName" placeholder="标题" style="width: 180px"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item prop="description">
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="2"
            placeholder="说明"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  props: {
    isMainItem: Boolean,
    value: Object,
  },
  created() {
    this.form = this.value;
  },
  mounted() {},
  watch: {
    form: {
      deep: true,
      handler() {
        this.$set(this.form, "mainTask", this.isMainItem);
        this.$emit("input", this.form);
      },
    },
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      taskOpts: (state) => state.reward.initData.tasks || [],
      sortOpts: (state) => state.reward.initData.sorts || [],
      assessOpts: (state) => state.reward.initData.assesses || [],
    }),
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    isFixedLogic(val) {
      if (val) {
        const item = this.taskOpts.find((item) => item.value === val);
        return item.fixedLogic;
      }
      return false;
    },

    handleChange(val) {
      const item = this.taskOpts.find((item) => item.value === val);
      this.$set(this.form, "taskName", item.desc);
      this.$set(this.form, "icon", item.icon);
    },

    handleAssessValueChange(val) {
      this.$set(this.form, "showValue", val);
    },
  },
};
</script>
<style lang="less" scoped></style>
