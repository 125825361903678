<template>
  <div class="root-box">
    <el-form ref="form" size="small" :model="param" inline>
      <el-row>
        <el-col>
          <el-form-item label="统计开始时间：">
            <el-date-picker
              size="small"
              v-model="param.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              align="left"
              @change="query"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车队：">
            <el-select v-model="param.teamId" @change="query">
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 80px" @click="query()">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="table">
      <el-table
        :data="list"
        stripe
        max-height="700"
        :summary-method="getSummaries"
        show-summary
        v-if="param.teamId === 4"
        key="table1"
      >
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
          fixed
        ></el-table-column>

        <el-table-column prop="driverName" label="姓名" width="80" fixed></el-table-column>

        <el-table-column prop="driverNo" label="编号" width="80" sortable fixed></el-table-column>

        <el-table-column prop="num" label="人数1" sortable width="100" fixed>
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="按照车队基础价格折算的人数"
              placement="top-start"
            >
              <span
                >人数1
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="pathConvertedNum" label="人数2" sortable width="100" fixed>
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="按照路线基础价格折算的人数"
              placement="top-start"
            >
              <span
                >人数2
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="routeNum" label="边数" sortable fixed></el-table-column>

        <el-table-column
          prop="avgNum"
          label="平均人数"
          width="100"
          sortable
          fixed
        ></el-table-column>
        <el-table-column prop="actAttendance" label="上座率" sortable width="90" fixed>
          <template slot-scope="scope"> {{ scope.row.actAttendance }}% </template>
        </el-table-column>
        <el-table-column prop="routeValuePoints" label="行程积分" width="100" sortable fixed>
        </el-table-column>

        <el-table-column prop="baseIncome" label="流水" sortable>
          <template slot-scope="scope">
            {{ scope.row.baseIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncrease" label="加价流水" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalIncrease | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncome" label="合计流水" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalDiscount2" label="优惠补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount2 | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalAheadDiscount" label="早价补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalAheadDiscount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalDiscount" label="合计补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="totalActShuntFee" label="分流费用" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalActShuntFee | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="powerFee" label="电费成本" sortable>
          <template slot-scope="scope">
            {{ scope.row.powerFee | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="tollFee" label="过路费成本" sortable>
          <template slot-scope="scope">
            {{ scope.row.tollFee | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="baseSalaryCost" label="工资成本" sortable>
          <template slot-scope="scope">
            {{ scope.row.baseSalaryCost | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="extraPointsCommission" label="超额提成" sortable>
          <template slot-scope="scope">
            {{ scope.row.extraPointsCommission | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="carRentCost" label="车租成本" sortable>
          <template slot-scope="scope">
            {{ scope.row.carRentCost | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="awardAmount" label="奖励金额" width="80" sortable>
          <template slot-scope="scope">
            {{ scope.row.awardAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="punishAmount" label="违规金额" width="80" sortable>
          <template slot-scope="scope">
            {{ scope.row.punishAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="prueCommissionAmount" label="毛收入" sortable>
          <template slot-scope="scope">
            {{ scope.row.prueCommissionAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="leaveDays" label="请假天数" sortable></el-table-column>

        <el-table-column prop="exceedFine" label="超假罚款" sortable>
          <template slot-scope="scope">
            {{ scope.row.exceedDays }}天 / {{ scope.row.exceedFine | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="commissionAmount" label="抽成收入" sortable>
          <template slot-scope="scope">
            {{ scope.row.commissionAmount | moneyFormat }}
          </template>
        </el-table-column>
      </el-table>

      <el-table
        key="table2"
        :data="list"
        stripe
        max-height="700"
        :summary-method="getSummaries"
        show-summary
        v-else
      >
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
          fixed
        ></el-table-column>

        <el-table-column prop="driverName" label="姓名" width="80" fixed></el-table-column>

        <el-table-column prop="driverNo" label="编号" width="80" sortable fixed></el-table-column>

        <el-table-column prop="num" label="人数1" sortable width="100" fixed>
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="按照车队基础价格折算的人数"
              placement="top-start"
            >
              <span
                >人数1
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="pathConvertedNum" label="人数2" sortable width="100" fixed>
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="按照路线基础价格折算的人数"
              placement="top-start"
            >
              <span
                >人数2
                <i class="el-icon-warning"></i>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="routeNum" label="边数" sortable fixed></el-table-column>

        <el-table-column
          prop="avgNum"
          label="平均人数"
          width="100"
          sortable
          fixed
        ></el-table-column>
        <el-table-column prop="actAttendance" label="上座率" sortable width="90" fixed>
          <template slot-scope="scope"> {{ scope.row.actAttendance }}% </template>
        </el-table-column>

        <el-table-column prop="baseIncome" label="流水" sortable fixed>
          <template slot-scope="scope">
            {{ scope.row.baseIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncrease" label="加价流水" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalIncrease | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncome" label="合计流水" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalDiscount2" label="优惠补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount2 | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalAheadDiscount" label="早价补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalAheadDiscount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="totalDiscount" label="合计补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="totalShuntFee" label="分流费用" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalShuntFee | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="commissionAmount" label="抽成收入" sortable>
          <template slot-scope="scope">
            {{ scope.row.commissionAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="subsidyNum" label="保底人数" sortable> </el-table-column>

        <el-table-column prop="subsidyAmount" label="保底补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.subsidyAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="awardAmount" label="奖励金额" width="80">
          <template slot-scope="scope">
            {{ scope.row.awardAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="punishAmount" label="违规金额" width="80">
          <template slot-scope="scope">
            {{ scope.row.punishAmount | moneyFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="prueCommissionAmount1" label="抽成" sortable>
          <template slot-scope="scope">
            {{ scope.row.prueCommissionAmount1 | moneyFormat }}
          </template>
        </el-table-column>

        <!--
        <el-table-column prop="commission" label="当前提点">
          <template slot-scope="scope"> {{ scope.row.commission }}‰</template>
        </el-table-column>

       <el-table-column prop="totalDiscount1" label="补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalDiscount1 | moneyFormat }}
          </template>
        </el-table-column>


        <el-table-column prop="commission" label="保底上座率">
          <template slot-scope="scope"> {{ scope.row.attendance }}‰ </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import { getTeamDriverBillDetail } from "@/api";
import { DateUtil } from "@/util";
import { mapState } from "vuex";
import config from "@/config/config";
import _ from "lodash";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teamOpts: (state) => state.teamOpts,
    }),
    demo() {
      if (this.list.length) {
        return this.list[0];
      }
      return {};
    },
  },
  data() {
    const today = DateUtil.getDayStr(0);
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const start = DateUtil.getDayStr(-7);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const start = DateUtil.getDayStr(-30);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近两个月",
            onClick(picker) {
              const start = DateUtil.getDayStr(-60);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近1年",
            onClick(picker) {
              const start = DateUtil.getDayStr(-365);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近2年",
            onClick(picker) {
              const start = DateUtil.getDayStr(-730);
              picker.$emit("pick", [start, today]);
            },
          },
        ],
      },
      param: {
        date: [DateUtil.format(DateUtil.getMonthFirstDay()), today],
        // date : [DateUtil.getDayStr(-30), today],
        teamId: config.initTeam,
      },
      list: [],
    };
  },
  methods: {
    query() {
      const { date = [], teamId } = this.param;
      const param = { teamId, startDate: date[0], endDate: date[1] };
      getTeamDriverBillDetail(param).then((res) => {
        this.list = res.data || [];
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        const property = column.property;
        if (index === 1) {
          sums[index] = "合计：";
          return;
        }
        if (index === 0 || index === 2) {
          sums[index] = "";
          return;
        }

        if (property == "avgNum") {
          let totalRouteNum = 0;
          let realNum = 0;
          data.forEach((item) => {
            realNum += item.pathConvertedNum;
            totalRouteNum += item.routeNum;
          });

          if (totalRouteNum) {
            sums[index] = (realNum / totalRouteNum).toFixed(2);
          } else {
            sums[index] = "0";
          }
          return;
        }

        if (property == "actAttendance") {
          let fullNum = 0;
          let realNum = 0;
          data.forEach((item) => {
            fullNum += item.routeNum * item.seats;
            realNum += item.pathConvertedNum;
          });

          if (fullNum) {
            sums[index] = ((realNum / fullNum) * 100).toFixed(2);
          } else {
            sums[index] = "0";
          }
          return;
        }

        if (property == "routeValuePoints") {
          let totalPoints = 0;
          data.forEach((item) => {
            if (item.routeValuePoints) {
              totalPoints += parseFloat(item.routeValuePoints);
            }
          });
          sums[index] = totalPoints.toFixed(1);
          return;
        }

        // 不需要除以100的字段
        const oriArr = [
          "num",
          "pathConvertedNum",
          "routeNum",
          "subsidyNum",
          "commission",
          "leaveDays",
        ];
        const values = data.map((item) => {
          if (oriArr.indexOf(property) > -1) {
            if (_.isNumber(item[property])) {
              return Number(item[property]);
            } else {
              return 0;
            }
          } else {
            if (_.isNumber(item[property])) {
              return Number(item[property] / 100);
            } else {
              return 0;
            }
          }
        });
        const sumVal = _.sumBy(values);
        // 没有小数的字段
        const noFloatArr = [
          "baseIncome",
          "totalIncrease",
          "totalIncome",
          "totalDiscount2",
          "totalAheadDiscount",
        ];
        sums[index] = parseFloat(sumVal).toFixed(noFloatArr.includes(property) ? 0 : 2);
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped></style>
