<template>
  <el-dialog
    :visible="show"
    width="800px"
    title="添加排班"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="box">
      <div>
        <div class="title">
          <el-input
            v-model="keywords"
            placeholder="请输入汽车编号"
            size="small"
          ></el-input>
        </div>
        <div class="main">
          <div
            @click="goRight(driver)"
            v-for="driver in filterDriver"
            :key="driver.id"
          >
            <el-row class="row">
              <el-col :span="4">{{ driver.no }}</el-col>
              <el-col :span="4">{{ driver.name | nameFilter }}</el-col>
              <el-col :span="4">{{ driver.seats }}坐</el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div>
        <div class="title right">
          已选：{{ selectData.length }} 总共: {{ idleDriversData.length }}
        </div>
        <div class="main selected">
          <sortable-list lockAxis="y" v-model="selectData" :pressDelay="100">
            <sortable-item
              v-for="(driver, index) in selectData"
              :index="index"
              :key="index"
              :item="driver"
              :click="goLeft"
            />
          </sortable-list>
        </div>
      </div>
    </div>

    <span slot="footer" class="footer">
      <el-button type="primary" size="small" @click="save">确 定</el-button>
      <el-button @click="show = false" size="small">取 消</el-button>
      <el-button type="success" plain @click="clear" size="small"
        >清 空</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {addScheduling, getIdleDrivers} from '@/api';
import SortableItem from './SortableItem';

export default {
  components: {
    SortableItem,
  },
  props: {
    callback: Function,
    areaId: Number,
    cb: Function,
  },
  filters: {
    nameFilter (val) {
      return val.substr(0, 1) + '师傅';
    }
  },
  data () {
    return {
      show: false,
      keywords: '',
      selectData: [],
      idleDriversData: []
    };
  },
  computed: {
    filterDriver () {
      if (this.keywords) {
        return this.idleDriversData.filter(item=> item.no.indexOf(this.keywords) > -1);
      } else {
        return this.idleDriversData;
      }
    }
  },
  methods: {
    async   open () {
      this.selectData = [];
      this.show = true;
      this.keywords = '';
      await getIdleDrivers(this.areaId).then(res=>{
        this.idleDriversData = res.data;
      });
    },
    clear () {
      this.idleDriversData = this.idleDriversData.concat(this.selectData);
      this.selectData = [];
    },
    save () {
      const driverIds = this.selectData.map(item=> item.id);
      // 加入排班
      const param = {
        driverIds,
        areaId: this.areaId,
      };

      addScheduling(param).then(()=>{
        this.cb();
        this.show = false;
      });
    },
    goRight (driver) {
      this.idleDriversData = this.idleDriversData.filter(item=> item.id !== driver.id);
      this.selectData.push(driver);
    },
    goLeft (driver) {
      this.selectData = this.selectData.filter(item=> item.id !== driver.id);
      this.idleDriversData.push(driver);
    }
  }
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  > div {
    flex: 1;
    margin: 0 10px;

    .main {
      position: relative;
      border: 1px solid #ddd;
      padding: 10px;
      height: 500px;
      overflow: auto;
      .row {
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  .title {
    height: 30px;
    margin-bottom: 10px;
    &.right {
      position: relative;
      top: 6px;
    }
  }
}

.date {
  position: relative;
  top: -5px;
  right: -32px;
}
</style>
