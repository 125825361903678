<template>
  <div class="root-box">
    <div class="operate-container">
      <el-button type="primary" :size="size" @click="openAdd"
        >新增权限</el-button
      >
    </div>

    <el-table
      :data="permissionData"
      row-key="id"
      stripe
      :default-expand-all="false"
      max-height="720"
      :tree-props="{ children: 'children' }"
    >
      <el-table-column prop="id" label="权限ID" width="180"> </el-table-column>

      <el-table-column prop="text" label="权限名称" width="180">
      </el-table-column>
      <el-table-column prop="permKey" label="权限key" width="180">
      </el-table-column>
      <el-table-column prop="url" label="前端路由"> </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <span
            :class="{
              menu: scope.row.type === '1',
              button: scope.row.type === '2',
            }"
          >
            {{ scope.row.type | enumTransfer(typeOpts) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="图标"> </el-table-column>
      <el-table-column prop="sort" label="排序"> </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span>
            <el-button type="text" size="small" @click="openEdit(scope.row)"
              >修改</el-button
            >
            <el-button type="text" size="small" @click="deletePerm(scope.row)"
              >删除</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible="visible"
      width="600px"
      max-height="600"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="权限名称：" prop="text">
          <el-input v-model="form.text"></el-input>
        </el-form-item>

        <el-form-item label="类型：" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="权限key：" prop="permKey">
          <el-input v-model="form.permKey"></el-input>
        </el-form-item>

        <el-form-item label="父权限ID：" prop="pid">
          <el-input v-model="form.pid"></el-input>
        </el-form-item>

        <el-form-item v-if="form.type === '2'" label="前端路由：" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>

        <el-form-item v-if="form.type === '1'" label="图标：" prop="icon">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>

        <el-form-item label="排序：" v-if="form.type !== '3'" prop="icon">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getPermissionTree, changePermission, deletePermission} from '@/api';
import _ from 'lodash';
import { mapState } from 'vuex';

const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.getTree();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize
    })
  },
  data () {
    return {
      permissionData: [],
      typeOpts: [{
        label: '父级菜单',
        value: "1",
      }, {
        label: '叶子菜单',
        value: "2",
      }, {
        label: '按钮',
        value: "3",
      }],
      visible: false,
      form: {},
      rules: {
        text: [
          {required: true, message: '权限名称必填', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '权限类型必须选择', trigger: 'blur'},
        ],
        pid: [
          {required: true, message: '父权限必填,根级节点请填0', trigger: 'blur'},
        ],
      },
      mode: MODE.EDIT
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },
    getTree () {
      getPermissionTree().then((res) => {
        this.permissionData = res.data || [];
      });
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.visible = true;
      this.form = _.cloneDeep(item);
    },
    openAdd () {
      this.mode = MODE.ADD;
      this.visible = true;
      this.form = {};
    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const {id, text, url, icon, sort, permKey, pid, type} = this.form;
        const data = {
          name: text,
          url,
          icon,
          sort,
          permission: permKey,
          parentId: pid,
          resourceType: type
        };
        if (this.mode === MODE.EDIT) {
          data.id = id;
        }
        changePermission(data).then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.getTree();
        });
      });

    },
    deletePerm (item) {
      this.$confirm(`确认删除吗?`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePermission(item).then(()=>{
          this.$message.success("操作成功");
          this.getTree();
        });
      });


    }

  }
};
</script>

<style scoped lang="less">
.table {
  margin-top: 10px;
}

.operate-div {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.menu {
  color: #2aaaff;
}

.button {
  color: #37c604;
}
</style>
