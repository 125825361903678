<template>
  <el-drawer
    :visible.sync="visible"
    direction="ttb"
    :with-header="false"
    custom-class="drawer-modal"
    @opened="onOpen"
    :show-close="false"
    :before-close="beforeClose"
    :withHeader="false"
  >
    <div class="header">
      <span class="info"> 已选择：{{ totalNum }}人</span>

      <span class="info" v-if="routeInfo"
        >{{ routeInfo.distance | mileFilter }}，{{
          routeInfo.time | timeFilter
        }}

        <span class="arrive-time">预计{{ routeInfo.time | dateTime }}到达</span>
      </span>
      <span
        ><el-button
          type="primary"
          size="small"
          style="width: 80px"
          @click="dispatch"
          >派单</el-button
        ></span
      >
    </div>

    <div id="dispatchMap" class="map-b"></div>

    <div class="result left" v-if="orders.length">
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="(item, index) in orders"
          @change="selectPassenger(index)"
          :key="item.id"
          :label="index"
          >{{ index + 1 }}、{{ item.showTime }}
          {{ isStart ? item.departure : item.destination | maxStrLimit(10) }}
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="result setting right">
      <el-form>
        <el-form-item>
          <el-switch
            style="display: block"
            v-model="isStart"
            @change="resetAndShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="订单起点"
            inactive-text="订单终点"
          >
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-switch
            style="display: block"
            v-model="showDetail"
            @change="resetAndShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="显示地址"
            inactive-text="隐藏地址"
          >
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-switch
            style="display: block"
            v-model="showDriver"
            @change="showDriverLocation"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="显示司机"
            inactive-text="隐藏司机"
          >
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            style="width: 100%"
            size="small"
            plain
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
import { DateUtil } from "@/util";
import { getDriverLocationAndRoute } from "@/api";
const markB = require("../assets/image/mark_b.png");
const markG = require("../assets/image/mark_g.png");
const markY = require("../assets/image/mark_y.png");
const carPng = require("../assets/image/car_blue.png");
const carRedPng = require("../assets/image/car_red.png");
const carGreenPng = require("../assets/image/car_green.png");
const carYellowPng = require("../assets/image/car_yellow.png");

export default {
  props: {},
  filters: {
    dateTime (val = 0) {
      const now = new Date().getTime();
      const thatTime = new Date(now + val * 1000);
      return DateUtil.format(thatTime, "yyyy-MM-dd hh:mm:ss");
    },
  },
  computed: {
    selectOrders () {
      if (this.checkList.length) {
        const orders = this.orders.filter(
          (item, index) => this.checkList.indexOf(index) > -1
        );
        return orders;
      }
      return [];
    },
    totalNum () {
      const orders = this.selectOrders;
      let total = 0;
      orders.forEach((item) => {
        total += item.number;
      });
      return total;
    },
  },
  data () {
    return {
      visible: false,
      isStart: true,
      showDetail: true,
      showDriver: true,
      orders: [],
      checkList: [],
      selectPassengers: [],
      routeInfo: null,
    };
  },
  methods: {
    show (isShow, orders, callback) {
      if (isShow) {
        this.orders = orders;
        this.callback = callback;
      } else {
        this.beforeClose();
      }
      this.visible = isShow;
    },

    reset () {
      this.beforeClose();
      this.showMap();
      this.showDriverLocation();
    },

    resetAndShow () {
      this.checkList = [];
      this.showMap();
      this.showDriverLocation();
    },

    onOpen () {
      this.showMap();
      this.showDriverLocation();
    },

    showMap () {
      const map = new AMap.Map("dispatchMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map = map;

      const orders = this.orders;
      const markers = [];
      orders.forEach((item, index) => {
        const marker = this.createPassengerBaseInfoMarker(item, index, false);

        marker.on("click", () => {
          const index = marker.getExtData();
          // 规划路线
          if (this.selectCar) {
            this.drawRoute(index, false);
          } else {
            this.selectPassenger(index, true);
          }
        });

        markers.push(marker);
      });

      this.markers = markers;
      map.add(markers);
      map.setFitView();
    },

    showDriverLocation () {
      const map = this.map;
      if (this.showDriver) {
        if (this.carMarkers) {
          map.add(this.carMarkers);
          map.setFitView();
        } else {
          this.getDriverLocation();
        }
      } else {
        if (map && this.carMarkers) {
          map.remove(this.carMarkers);
        }
      }
    },

    getDriverLocation () {
      const map = this.map;

      const demo = this.orders[0];
      const data = {
        pathId: demo.pathId,
        vendor: "driver",
      };

      getDriverLocationAndRoute(data).then((res) => {
        const { data = [] } = res || {};
        const carMarkers = [];
        data.forEach((item, index) => {
          const { info, routeDetail, location, status: driverStatus } = item;
          if (!location) {
            return;
          }
          const { lng, lat } = location || {};

          let leftSeats = info.seats;
          if (routeDetail && routeDetail.route) {
            leftSeats = routeDetail.route.number - routeDetail.route.used;
          }

          // 根据状态显示颜色
          let carImg = carGreenPng;
          // 异常状态
          if (driverStatus === 3) {
            carImg = carRedPng;
          } else if (driverStatus === 2) {
            carImg = carPng;
          }

          const icon = new AMap.Icon({
            size: new AMap.Size(40, 40), //图标大小
            image: carImg,
            imageSize: new AMap.Size(40, 40),
          });
          const extData = { oriIcon: icon, index, data: item };

          const marker = new AMap.Marker({
            position: [lng, lat],
            draggable: true,
            raiseOnDrag: false,
            label: {
              //修改label相对于maker的位置
              direction: "top",
              content:
                "<div class='info rec'>" + `${info.no} 余${leftSeats}座` + "</div>",
            },
            icon,
            extData,
          });

          marker.on("click", () => {
            this.chooseCar(marker);
          });

          carMarkers.push(marker);
        });

        this.carMarkers = carMarkers;
        map.add(carMarkers);
        map.setFitView();
      });
    },

    drawRoute (choosePassenger, isRoutePassenger) {
      // 重置路程信息
      if (this.driving) {
        this.driving.clear();
      }
      this.routeInfo = null;

      // 区分是车上的乘客，还是未接的乘客
      const passenger = isRoutePassenger
        ? `oncar${choosePassenger}`
        : `wait${choosePassenger}`;

      const selectPassengers = this.selectPassengers;
      const findIndex = selectPassengers.indexOf(passenger);

      // 移除该点
      if (findIndex > -1) {
        selectPassengers.splice(findIndex, 1);
      } else {
        //加入该点
        selectPassengers.push(passenger);
      }

      // 所有选择的marker
      const allSelect = [];
      // 找到对应的marker
      selectPassengers.forEach((item) => {
        if (item.indexOf("oncar") > -1) {
          const index = item.replaceAll("oncar", "");
          allSelect.push(this.orderMarkers[index]);
        } else if (item.indexOf("wait") > -1) {
          const index = item.replaceAll("wait", "");
          allSelect.push(this.markers[index]);
        }
      });

      let waypoints = [];

      const total = allSelect.length;
      if (total > 1) {
        waypoints = allSelect.slice(0, total - 1).map((item) => item.getPosition());
      } else if (total < 1) {
        return;
      }

      const driverLocation = this.selectCar.getPosition();
      const destLocation = allSelect[total - 1].getPosition();

      AMap.plugin("AMap.Driving", () => {
        const driving = new AMap.Driving({
          // 驾车路线规划策略，考虑实时路况
          policy: AMap.DrivingPolicy.REAL_TRAFFIC,
          autoFitView: false,
          hideMarkers: true,
          map: this.map,
        });
        driving.search(driverLocation, destLocation, { waypoints }, (status, result) => {
          if (status === "complete") {
            this.routeInfo = result.routes[0];
          }
        });
        this.driving = driving;
      });
    },

    chooseCar (marker) {
      const extData = marker.getExtData();

      const chooseCarPng = new AMap.Icon({
        size: new AMap.Size(40, 40), //图标大小
        image: carYellowPng,
        imageSize: new AMap.Size(40, 40),
      });

      this.clearDrivingRoute();

      if (this.selectCar) {
        // 换点
        if (this.selectCar.getExtData().index !== extData.index) {
          // 之前选的点恢复原来颜色
          const oriData = this.selectCar.getExtData();
          this.selectCar.setIcon(oriData.oriIcon);

          // 当前选中设置蓝色
          marker.setIcon(chooseCarPng);
          this.selectCar = marker;

          this.showRouteOrders(extData);
        } else {
          // 反选
          const oriData = this.selectCar.getExtData();
          this.selectCar.setIcon(oriData.oriIcon);
          this.selectCar = null;
        }
      } else {
        marker.setIcon(chooseCarPng);
        this.selectCar = marker;
        this.showRouteOrders(extData);
      }
    },

    showRouteOrders (extData) {
      const map = this.map;
      const { routeDetail = {} } = extData.data || {};
      const { orderList } = routeDetail;

      const markers = [];
      orderList.forEach((item, index) => {
        const marker = this.createPassengerBaseInfoMarker(item, index, true);
        marker.on("click", () => {
          const index = marker.getExtData();
          // 规划路线
          this.drawRoute(index, true);
        });

        markers.push(marker);
      });

      this.orderMarkers = markers;
      map.add(markers);
      map.setFitView();
    },

    createPassengerBaseInfoMarker (order, index, isRoutePassenger) {
      const {
        depLongitude,
        depLatitude,
        departure,
        destination,
        destLongitude,
        destLatitude,
        showTime,
        telephone,
      } = order || {};
      const address = this.isStart ? departure : destination;
      const position = this.isStart
        ? [depLongitude, depLatitude]
        : [destLongitude, destLatitude];

      const subTel = "尾号" + telephone.substr(7);
      const clazz = isRoutePassenger ? "info order pointer" : "info  pointer";

      // 获取行程状态
      const routeStatus = order.routeStatus || 0;
      const routeDetailOpts = this.$store.state.enumMap["ROUTE_DETAIL_STATUS"];
      const routeStr = this.$options.filters.enumTransfer(routeStatus, routeDetailOpts);

      const content = this.showDetail
        ? `<div class="${clazz}"><div>${index + 1}、 ${showTime} （${
          order.number
        }人） ${subTel}</div><div>${address} （${routeStr}）</div></div>`
        : `<div class="${clazz}">${index + 1}、 ${showTime} （${
          order.number
        }人） ${subTel}</div>`;
      const marker = new AMap.Marker({
        label: {
          content,
          direction: "top",
        },
        icon: new AMap.Icon({
          image: isRoutePassenger ? markY : markB,
        }),
        position,
        clickable: true,
        draggable: true,
        raiseOnDrag: false,
        extData: index,
      });

      return marker;
    },

    clearDrivingRoute () {
      this.selectPassengers = [];
      this.routeInfo = null;
      if (this.driving) {
        this.driving.clear();
      }
      if (this.orderMarkers) {
        this.map.remove(this.orderMarkers);
      }
    },

    dispatch () {
      if (this.callback) {
        const orders = this.orders.filter(
          (item, index) => this.checkList.indexOf(index) > -1
        );

        if (orders.length < 1) {
          this.$message.error("请选择订单");
        }
        this.callback(orders);
      }
    },

    selectPassenger (val, iconClick) {
      const uncheckedIcon = new AMap.Icon({
        image: markB,
      });

      const checkedIcon = new AMap.Icon({
        image: markG,
      });
      const findIndex = this.checkList.indexOf(val);

      // 判断是否选中当前点
      const checked = (!iconClick && findIndex > -1) || (iconClick && findIndex === -1);

      if (checked) {
        this.markers[val].setIcon(checkedIcon);
        if (iconClick) {
          this.checkList.push(val);
        }
      } else {
        if (iconClick) {
          this.checkList.splice(findIndex, 1);
        }
        this.markers[val].setIcon(uncheckedIcon);
      }
    },

    beforeClose (done) {
      if (this.map) {
        this.map.clearMap();
      }
      this.checkList = [];
      this.callback = null;
      this.carMarkers = null;
      this.callback = null;
      this.isStart = true;
      this.showDetail = true;
      this.showDriver = true;
      this.selectCar = null;
      this.clearDrivingRoute();
      done && done();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90% !important;
}

.info {
  font-size: 18px;
  font-weight: bold;
  color: #ff5722;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  min-height: 200px;
  max-height: 90%;
  overflow-y: auto;
  top: 100px;
  right: 0;
  width: 350px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  &.setting {
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    min-height: auto;
  }

  &.right {
    right: 0;
    left: auto;
  }

  &.left {
    left: 0;
    right: auto;
  }

  .el-checkbox {
    color: #fff;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

#dispatchMap {
  width: 100%;
  height: 100%;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  align-items: center;
  margin: 0 20px;
  height: 60px;

  .arrive-time {
    color: black;
    margin-left: 10px;
  }

  .route-info {
    color: #ff5722;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
