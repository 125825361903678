<template>
  <div class="root-box">
    <QueryCondition ref="param" :queryFun="query" :showType="true" />

    <div style="margin-top: 80px">
      <chart ref="chart" :option="chartOpt" :height="400" />
    </div>

    <div class="table">
      <div class="item">
        <chart-view
          ref="chart1"
          valueField="num"
          nameField="name"
          title="优惠类型金额占比"
          initChartType="pie"
        />
      </div>

      <div class="item">
        <chart-view
          ref="chart2"
          valueField="num"
          nameField="name"
          title="优惠类型单数占比"
          initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {sendCouponAnalysis} from '@/api';
import ChartView from '../../ChartView.vue';

import QueryCondition from '../QueryCondition.vue';
export default {
  components: {
    QueryCondition,
    ChartView
  },
  props: {},
  created () {

  },
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      data: {},
      chartOpt: {
        legend: {
          show: true,
          bottom: 0,
        },
        xAxis: {type: 'category'},
        yAxis: {},
        tooltip: {
          show: true,
          trigger: 'axis',
        },
      }
    };
  },
  methods: {
    query () {
      const param = this.$refs.param.getParam();
      sendCouponAnalysis(param).then(res=>{
        const source = res.data || [];
        // 按金额维度统计
        const dimensions1 = ['newUserCouponAmount', 'finishRouteCouponAmount', 'pointsExchangeCouponAmount', 'helpCouponAmount', 'inviteExchangeCouponAmount', 'otherCouponAmount'];
        const nameMap1 = {
          newUserCouponAmount: '新用户优惠', finishRouteCouponAmount: '成行优惠', pointsExchangeCouponAmount: '积分兑换优惠',
          helpCouponAmount: '助力5元', inviteExchangeCouponAmount: '邀请兑换优惠', otherCouponAmount: '其他优惠' };
        // 按单数维度统计
        const dimensions2 = ['newUserCouponNum', 'finishRouteCouponNum', 'pointsExchangeCouponNum', 'helpCouponNum', 'inviteExchangeCouponNum', 'otherCouponNum'];
        const nameMap2 = {
          newUserCouponNum: '新用户优惠', finishRouteCouponNum: '成行优惠', pointsExchangeCouponNum: '积分兑换优惠',
          helpCouponNum: '助力5元', inviteExchangeCouponNum: '邀请兑换优惠', otherCouponNum: '其他优惠' };

        const series = [];
        const dimensions = param.countType === 'amount' ? dimensions1 : dimensions2;
        const nameMap = param.countType === 'amount' ? nameMap1 : nameMap2;
        dimensions.forEach(item=>{
          series.push({
            type: 'line',
            smooth: true,
            name: nameMap[item],
            encode: {
              x: 'date',
              y: item
            }
          });

        });
        const option = {
          ...this.chartOpt,
          series,
          dataset: {
            dimensions: ['date', ...dimensions],
            source
          },
          legend: {
            show: true,
            bottom: 0,
            formatter: function (name) {
              return name;
            }
          },

        };
        this.$refs.chart.setOption(option, true);


        const amountDataMap = {};
        const numDataMap = {};
        // 饼图汇总
        source.forEach(item=>{
          dimensions1.forEach(d1=>{
            const name = nameMap1[d1];
            let val = amountDataMap[name] || 0;
            val += item[d1];
            amountDataMap[name] = val;
          });
          dimensions2.forEach(d2=>{
            const name = nameMap2[d2];
            let val = numDataMap[name] || 0;
            val += item[d2];
            numDataMap[name] = val;
          });
        });

        const amountData = [];
        for (let name in amountDataMap) {
          amountData.push({name, num: amountDataMap[name]});
        }

        const numData = [];
        for (let name in numDataMap) {
          numData.push({name, num: numDataMap[name]});
        }
        this.$refs.chart1.setData(amountData);
        this.$refs.chart2.setData(numData);

      });
    }
  },
};
</script>
<style lang="less" scoped>
.head-box {
  display: flex;
  margin: 40px 0;
  justify-content: space-around;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amount {
      margin-top: 10px;

      .value {
        font-size: 20px;
      }

      .unit {
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(2, 300px);
  grid-row-gap: 40px;
  grid-column-gap: 4%;
  padding: 20px;
  margin-top: 60px;

  > div {
    background: #fff;
    border: 1px solid #ddd;
    position: relative;
  }
}
</style>