<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item>
            <el-date-picker
              v-model="date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择周"
              @change="query()"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="table">
      <el-table :data="list" stripe size="small" max-height="600" ref="table">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="driverName" label="司机"></el-table-column>
        <el-table-column prop="driverNo" label="司机编号"></el-table-column>
        <el-table-column prop="startDate" label="奖励期间">
          <template slot-scope="scope">
            {{ scope.row.startDate | dateFormat("MM月dd日") }} -
            {{ scope.row.endDate | dateFormat("MM月dd日") }}
          </template>
        </el-table-column>
        <el-table-column prop="value" label="当前好评数"> </el-table-column>
        <el-table-column prop="curAwardStandard" label="当前奖励标准">
          <template slot-scope="scope">
            {{ scope.row.curAwardStandard | moneyFormat(0) }}元/个
          </template>
        </el-table-column>
        <el-table-column prop="curTotalAward" label="当前总奖金">
          <template slot-scope="scope"> {{ scope.row.curTotalAward | moneyFormat(1) }}元 </template>
        </el-table-column>
        <el-table-column prop="invalidateValue" label="差评、投诉、拉黑"> </el-table-column>
        <el-table-column prop="settled" label="是否结算">
          <template slot-scope="scope">
            {{ scope.row.settled ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column prop="driverSpecialAwardStandard" label="奖励标准">
          <template slot-scope="scope">
            <div>
              <div>
                {{ scope.row.driverSpecialAwardStandard.baseAward | moneyFormat(0) }}元 /
                {{ scope.row.driverSpecialAwardStandard.baseValue }}个起
              </div>
              <div>
                {{ scope.row.driverSpecialAwardStandard.level1Award | moneyFormat(0) }}元 /
                {{ scope.row.driverSpecialAwardStandard.level1Value }}个起
              </div>
              <div>
                {{ scope.row.driverSpecialAwardStandard.level2Award | moneyFormat(0) }}元 /
                {{ scope.row.driverSpecialAwardStandard.level2Value }}个起
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <!-- <el-table-column prop="id" fixed="right" width="200" label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import { getDriverSpecialAwardRecords } from "@/api";
import { DateUtil } from "@/util";
export default {
  components: {},

  mounted() {
    this.query();
  },

  data() {
    return {
      date: DateUtil.getDayStr(),
      list: [],
    };
  },
  methods: {
    query() {
      getDriverSpecialAwardRecords(this.date).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
