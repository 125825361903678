<template>
  <div class="main-box">
    <div class="price-box">
      <el-divider content-position="left">退费明细</el-divider>
      <div class="block">
        <DetailItem name="订单实付金额"
                    :price="bill.receivedAmount"
                    :show="true"
                    :level="1"
                    :isDiscount="false" />

        <DetailItem :name="`成人票 x ${bill.adultNumber}`"
                    :price="bill.basePrice * bill.adultNumber"
                    :show="bill.adultNumber"
                    :isDiscount="false" />

        <DetailItem :name="`儿童票 x ${bill.childNumber}`"
                    :price="bill.childrenPrice * bill.childNumber"
                    :show="bill.childNumber"
                    :isDiscount="false" />

        <DetailItem name="起点加价"
                    :price="bill.startAreaUpPrice"
                    :show="bill.startAreaUpPrice"
                    :isDiscount="false" />

        <DetailItem name="终点加价"
                    :price="bill.endAreaUpPrice"
                    :show="bill.endAreaUpPrice"
                    :isDiscount="false" />

        <DetailItem name="优惠券优惠"
                    :price="bill.couponAmount"
                    :show="bill.couponAmount"
                    :isDiscount="true" />

        <DetailItem name="优惠口令优惠"
                    :price="bill.discountWordAmount"
                    :show="bill.discountWordAmount"
                    :isDiscount="true" />

        <DetailItem :name="`早价优惠 x ${bill.adultNumber}`"
                    :price="bill.aheadAmount"
                    :show="bill.aheadAmount"
                    :isDiscount="true" />

        <DetailItem name="其他优惠"
                    :price="bill.otherDiscount"
                    :show="bill.otherDiscount"
                    :isDiscount="true" />

        <DetailItem name="VIP优惠"
                    :price="bill.vipDiscount"
                    :show="bill.vipDiscount"
                    :isDiscount="true" />
      </div>

      <div class="block">
        <DetailItem name="助力红包扣除"
                    :price="bill.helpActionDiscount"
                    :level="1"
                    :show="bill.helpActionDiscount"
                    desc="助力活动奖励金额"
                    :isDiscount="false" />

      </div>

      <div class="block">
        <DetailItem name="选座费用退还"
                    :price="bill.seatPrice"
                    :level="1"
                    :show="bill.seatPrice > -1"
                    :isDiscount="false" />

      </div>

      <div class="block">
        <DetailItem name="起点加价扣除"
                    :price="bill.startAreaUpPrice"
                    :level="1"
                    :show="bill.isCarStart"
                    desc="司机已出发，远途油费补偿"
                    :isDiscount="true" />

      </div>

      <div class="block">
        <DetailItem name="退票手续费"
                    :price="bill.serviceChargeAmount"
                    :level="1"
                    :show="bill.serviceChargeAmount"
                    :desc="feeDesc"
                    :isDiscount="true" />

      </div>

      <div class="sum">
        <span>合计应退款</span>
        <span class="price">{{  -bill.price| moneyFormat(0) }}</span>
        <span>元</span>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import DetailItem from "./DetailItem";
export default {
  components: {
    DetailItem,
  },

  props: {
    data: Object,
  },

  watch: {},

  cteated () {},

  mounted () {},

  computed: {
    bill () {
      return this.data.transactionBill || {};
    },
    feeDesc () {
      const moneyFormat = Vue.options.filters["moneyFormat"];
      const money = moneyFormat(this.bill.fullTicketAmount, 0);
      return this.bill.serviceChargeRate == 0
        ? `全票金额${money} * 当前退票费率${this.bill.serviceChargeRate}% `
        : `全票金额${money} * 当前退票费率${this.bill.serviceChargeRate}% （最低1元）`;
    },
  },

  data () {
    return {};
  },

  methods: {},
};
</script>
<style scoped lang="less">
.block {
  margin-bottom: 20px;
}

.main-box {
  display: flex;

  .spe {
    width: 80px;
  }
  .price-box {
    flex: 1;
    .price {
      font-size: 18px;
      font-weight: bold;
      color: red;
      position: relative;
      top: -3px;
      &.refund {
        color: green;
      }
    }
    .sum {
      margin: 10px 0 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
