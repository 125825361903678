import Vue from 'vue';
import flyioPlugin from './flyio-plugin';
import eventBusPlugin from './event-bus-plugin';
import orderLogPlugin from '@/core/plugins/order-log-plugin';
import orderBillPlugin from '@/core/plugins/order-bill-plugin';
import orderTransactionPlugin from '@/core/plugins/order-transaction-plugin';
import billDetailPlugin from './bill-detail-plugin';
import './tinymce';

Vue.use(flyioPlugin);
Vue.use(eventBusPlugin);
Vue.use(orderLogPlugin);
Vue.use(orderBillPlugin);
Vue.use(orderTransactionPlugin);
Vue.use(billDetailPlugin);
