
import {Http} from '@/util';

// 查询在线客服
export function getOnlineKefuList () {
  return Http.get('/admin/kf/listkf');
}

// 踢出指定客服
export function kickoffKefu (adminId) {
  return Http.get('/admin/kf/kickoff', {adminId});
}