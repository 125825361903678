import { Http } from "@/util";

// 排序详情
export function orderSortDetail(param) {
  return Http.post("/beta/sort_order/sort/detail", param);
}

// 查询订单排序结果
export function getSortResultList(groupId) {
  return Http.get("/beta/sort_order/result/list", { groupId });
}

// 查询订单点
export function getSortPointList(groupId) {
  return Http.get("/beta/sort_order/point/list", { groupId });
}

// 查询排序Example列表
export function getSortExampleList(param) {
  return Http.post("/beta/sort_order/example/list", param);
}

// 查询推荐点
export function getRecommendPointList(onwayId) {
  return Http.get("/beta/sort_order/recommend/points", { onwayId });
}

// 计算指定顺序的行程信息
export function calcSpecIndexInfo(param) {
  return Http.post("/beta/sort_order/spec/index", param);
}

// 删除排序结果
export function delResult(id) {
  return Http.get("/beta/sort_order/result/del", { id });
}

// 删除排序结果
export function delSortGroup(id) {
  return Http.get("/beta/sort_order/group/del", { id });
}

// 删除点
export function delSortPoint(id) {
  return Http.get("/beta/sort_order/point/del", { id });
}

// 增加测试点
export function addRoutePoint(param) {
  return Http.post("/beta/sort_order/point/add", param);
}

// 更新排序测试组
export function updateTestSortOrderGroup(param) {
  param._hideLoading = true;
  return Http.post("/beta/sort_order/update/group", param);
}

// 检测点是否顺路
export function isTestPointOnTheWay(param) {
  param._hideLoading = true;
  return Http.post("/beta/sort_order/is_on_the_way", param);
}

// 校验排序结果
export function checkResult(groupId, resultId) {
  return Http.get("/beta/sort_order/check/result", { groupId, resultId });
}

// 删除所有顺路测试点
export function removeAllSortPoint(groupId) {
  return Http.get("/beta/sort_order/remove/sort_point", { groupId });
}

// 获取路径
export function getRoutePath(param) {
  return Http.post("/beta/sort_order/route/path", param);
}

// 获取推荐上车点
export function getNearestPoints(param) {
  return Http.post("/beta/sort_order/nearest/points", param);
}
