import Vue from 'vue';

Vue.filter('numFormat', function (value, precision = 2) {
  return Number(value).toFixed(precision);
});

Vue.filter('moneyFormat', function (value, precision = 2) {
  if (value) {
    const val = value / 100;
    return parseFloat(val).toFixed(precision);
  } else if (value === 0) {
    return '0';
  } else {
    return '';
  }

});
