import Vue from 'vue';

Vue.filter('orderTypeTransfer', function (value) {
  if (value && value.orderType) {
    const orderType = value.orderType;
    if (orderType == 1) {
      return value.number + "人";
    } else if (orderType == 2) {
      return "1货";
    } else if (orderType == 3) {
      return value.number + "人包车";
    } else if (orderType === 4) {
      return '空单';
    }
  }
  return '';

});
