import { Http } from "@/util";

export function findViolate(param) {
  return Http.post("/admin/driver/punishbill/relation/findviolate", param);
}

export function getPunishBillNum(param) {
  return Http.post("/admin/driver/punishbill/queryrecord", param);
}

export function queryAppealRecords(param) {
  return Http.post("/admin/driver/punishbill/queryappeal", param);
}

export function savePunishBill(punishBill) {
  return Http.post("/admin/driver/punishbill/save", punishBill);
}

export function viewDocxOrExam(params) {
  return Http.post("/admin/training/relation/query", params);
}

export function getPunishRelationList(param) {
  return Http.post("/admin/driver/punishbill/relation/list", param);
}

export function initPunishRelationList() {
  return Http.get("/admin/driver/punishbill/init/relation");
}

export function getDoc(code) {
  return Http.get("/admin/driver/punishbill/find/doc", { code });
}

export function getExamItems(code) {
  return Http.get("/admin/driver/punishbill/find/exam_item", { code });
}

export function saveRelation(param) {
  return Http.post("/admin/driver/punishbill/save_or_update/relation", param);
}

export function delRelation(param) {
  return Http.post("/admin/driver/punishbill/del/relation", param);
}

export function sendPunish(param) {
  return Http.post("/admin/driver/punishbill/sendtype", param);
}

export function getPunishBill(billId) {
  return Http.get("/admin/driver/punishbill/get", { billId });
}

export function listDriverPunishBills(param) {
  return Http.post("/admin/driver/punishbill/list", param);
}

export function dealPunishAppeal(punishId) {
  return Http.get("/admin/driver/punishbill/deal/appeal", { punishId });
}
