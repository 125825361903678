<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="开启状态：" prop="path">
          <el-select
            v-model="param.state"
            placeholder="请选择"
            @change="query()"
            clearable
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" type="primary" @click="openForm()"
            >添加广告位</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column width="250" label="广告图片">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.url"
              :preview-src-list="[scope.row.url]"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column prop="href" label="广告链接"></el-table-column>

        <el-table-column prop="title" label="广告标题"></el-table-column>

        <el-table-column prop="content" label="广告内容"></el-table-column>

        <el-table-column prop="defaultAd" label="是否默认广告位">
          <template slot-scope="scope">{{
            scope.row.defaultAd | enumTransfer(yesNoOpts)
          }}</template>
        </el-table-column>

        <el-table-column label="有效时间">
          <template slot-scope="scope">
            <div>{{ scope.row.validOn }}</div>
            <div>{{ scope.row.validOff }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="开启状态">
          <template slot-scope="scope">{{
            scope.row.state ? "启用" : "停用"
          }}</template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建日期"></el-table-column>

        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="changeState(scope.row)"
              >{{ scope.row.state ? "停用" : "启用" }}</el-button
            >
            <el-button type="text" size="small" @click="openForm(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="delAdvertisement(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="广告位"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="广告图片：" prop="url">
          <image-upload v-model="form.url" filePath="/advertisement" bucket="yxmgt-public" />
        </el-form-item>

        <el-form-item label="是否默认：" prop="defaultAd">
          <el-select
            v-model="form.defaultAd"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="有效期起：" prop="validOn">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            type="datetime"
            :disabled="!!form.defaultAd"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.validOn"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="有效期止：" prop="validOff">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            type="datetime"
            :disabled="!!form.defaultAd"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.validOff"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="广告链接：" prop="href">
          <el-input v-model="form.href"></el-input>
        </el-form-item>

        <el-form-item label="广告标题：" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="广告内容：" prop="content">
          <el-input v-model="form.content"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listAdvertisement, addAdvertisement, deleteAdvertisement, modifyAdvertisement} from '@/api';
import {mapState} from 'vuex';
import {BaseDataService} from '@/service';

export default {
  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
    })
  },
  data () {
    return {
      visible: false,
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      form: {

      },
      yesNoOpts: BaseDataService.yesNoOpts(),
      rules: {
        url: [
          {required: true, message: '请上传广告图片', trigger: 'blur'},
        ],
        defaultAd: [
          {required: true, message: '请选择是否默认', trigger: 'blur'},
        ],
      },
    };
  },
  methods: {
    uploadSuccess (response, file, fileList, type) {
      if (response.code === 200) {
        this.$set(this.form, type, response.data);
      } else {
        this.$message.error('上传失败');
      }
    },
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: this.param
      };
      listAdvertisement(param).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange (page) {
      this.query(page);
    },
    delAdvertisement (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAdvertisement({id: item.id}).then(()=>{
          this.$message.success('广告位删除成功');
          this.query();
        });
      });
    },
    openForm (item) {
      this.visible = true;

      if (item) {
        const form = {...item};
        if (item.scopes && item.scopes.length) {
          form.scopes.map(item=> item.path);
        }
        this.form = form;
      } else {
        this.form = {};
      }

      this.$refs.uploader && this.$refs.uploader.clearFiles();

    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.visible = false;
        const param = {...this.form};
        const scopes = param.scopes || [];
        param.scopes = scopes.map(item => {
          return {
            path: item
          };
        });
        if (param.id) {
          modifyAdvertisement(param).then(()=>{
            this.$message.success('广告位修改成功');
            this.query();
          });
        } else {
          addAdvertisement(param).then(()=>{
            this.$message.success('广告位新增成功');
            this.query();
          });
        }
      });

    },
    changeState (item) {
      const param = {
        id: item.id,
        state: !item.state
      };
      modifyAdvertisement(param).then(()=>{
        this.$message.success('操作成功');
        this.query();
      });
    }
  }
};
</script>

<style scoped>
</style>
