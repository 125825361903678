<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="概况" name="ref1">
        <overview ref="ref1"></overview>
      </el-tab-pane>

      <el-tab-pane label="增长" name="ref2">
        <increasement ref="ref2"></increasement>
      </el-tab-pane>

      <el-tab-pane label="转化" name="ref6">
        <transfer ref="ref6"></transfer>
      </el-tab-pane>

      <el-tab-pane label="活跃" name="ref3">
        <active ref="ref3"></active>
      </el-tab-pane>

      <el-tab-pane label="留存" name="ref4">
        <remain ref="ref4"></remain>
      </el-tab-pane>

      <el-tab-pane label="流失" name="ref5">
        <lost ref="ref5"></lost>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Overview from './Overview';
import Increasement from './Increasement';
import Active from './Active';
import Remain from './Remain';
import Lost from './Lost';
import Transfer from './Transfer';
export default {
  components: {Overview, Increasement, Active, Remain, Lost, Transfer},
  props: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {
    query (tab) {
      this.$refs[tab.name].query && this.$refs[tab.name].query();
    }
  },
};
</script>