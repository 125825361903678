<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="用券记录" name="ref1">
        <CouponInfo />
      </el-tab-pane>

      <el-tab-pane label="邀请记录" name="ref2">
        <InviteRecord />
      </el-tab-pane>

      <el-tab-pane label="邀请问题" name="ref3">
        <InviteQuestion />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CouponInfo from './CouponInfo';
import InviteRecord from './InviteRecord';
import InviteQuestion from './InviteQuestion';
export default {
  components: {
    CouponInfo,
    InviteRecord,
    InviteQuestion
  },
  props: {},
  created () {
  },
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1',
    };
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
</style>