import { Http } from "@/util";

export function getQcResult(routeId) {
  return Http.get("/admin/qc/result", { routeId });
}

// 保存质检证据资源
export function saveQcResource(resource) {
  return Http.post("/admin/qc/save/resource", resource);
}

// 对每项任务进行审核
export function checkQcItem(item) {
  return Http.post("/admin/qc/detail/check", item);
}

// 获取停车时长
export function getCarStopList(param) {
  return Http.get("/admin/qc/car_stop/list", param);
}

export function getRouteResourceList(routeId) {
  return Http.get("/admin/qc/get/route/resource/list", { routeId });
}
