import _ from 'lodash';
export default class ParamUtil {
  static obj2Str (param, key, encode) {
    if (param == null) return '';
    let paramStr = '';
    const t = typeof (param);
    if (t === 'string' || t === 'number' || t === 'boolean') {
      paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
      for (const i in param) {
        const k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
        paramStr += ParamUtil.obj2Str(param[i], k, encode);
      }
    }
    return paramStr;
  }

  static parseQueryString (url) {
    const obj = {};
    let keyvalue = [];
    let key = '';
    let value = '';
    const paraString = url.substring(url.indexOf('?') + 1, url.length).split('&');
    for (const i in paraString) {
      keyvalue = paraString[i].split('=');
      key = keyvalue[0];
      value = keyvalue[1];
      obj[key] = value;
    }
    return obj;
  }


  static filterEmptyData (data) {
    const res = {};
    _.forEach(data, (item, key)=>{
      if (!_.isEmpty(item) || _.isNumber(item) || _.isBoolean(item)) {
        res[key] = item;
      }
    });
    return res;
  }

  static getUrlParam () {
    let url = location.href;
    return ParamUtil.parseQueryString(url);
  }
}
