import {Http} from '@/util';

// 查询路线列表
export function getPathList (param) {
  return Http.post('/admin/path/list', param);
}


// 查询路线对应区域
export function getPathAreas (param = {}) {
  param._useCache = true;
  return Http.get('/admin/path/areas', param);
}


// 查询区域推荐地点
export function getRecommendAddress (param = {}) {
  param._useCache = true;
  return Http.post('/admin/path/recommend/address', param);
}


// 查询加价
export function getUpPrice (param = {}) {
  return Http.post('/admin/path/area/upprice', param);
}


// 查询单边路线列表
export function getOneSidePathList (param) {
  return Http.post('/admin/path/one_side/list', param);
}
