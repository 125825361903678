<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="优惠券" name="ref1">
        <Coupon ref="ref1"></Coupon>
      </el-tab-pane>

      <el-tab-pane label="兑换码" name="ref2">
        <ExchangeCode ref="ref2"></ExchangeCode>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Coupon from '@/pages/Marketing/Coupon/Coupon';
import ExchangeCode from '@/pages/Marketing/Coupon/ExchangeCode';

export default {
  components: {
    Coupon,
    ExchangeCode
  },

  created () {

  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pageSize: state => state.pageSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },


  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {



  }
};
</script>

<style scoped>
</style>
