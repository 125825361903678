import { Http } from "@/util";

export function getAppealList(param) {
  return Http.post("/admin/driver/notice/get/appeal/list", param);
}

export function saveTalkRecord(param) {
  return Http.post("/admin/driver/notice/save/talk", param);
}

export function getDriverFeedbackList(param) {
  return Http.post("/admin/driver/notice/get/feedback/list", param);
}

export function getPassengerFeedbackList(param) {
  return Http.post("/admin/passenger/feedback/list", param);
}

export function tipDriverHaveRest(driverId) {
  return Http.get("/admin/driver/notice/tip/hava_rest", { driverId });
}

export function punishedBefore(punishId) {
  return Http.get("/admin/driver/punishbill/punished_before", { punishId });
}
