<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">文档学习</el-menu-item>
      <el-menu-item index="2">视频学习</el-menu-item>
      <el-menu-item index="3">题库学习</el-menu-item>
      <el-menu-item index="4">正式考试</el-menu-item>
    </el-menu>

    <FileStat v-if="activeIndex == '1'"></FileStat>
    <VedioStat v-else-if="activeIndex == '2'"></VedioStat>
    <TrainStat v-else-if="activeIndex == '3'"></TrainStat>
    <ExamStat v-else></ExamStat>
  </div>
</template>

<script>
import FileStat from "./FileStat";
import VedioStat from "./VedioStat";
import TrainStat from "./TrainStat";
import ExamStat from "./ExamStat";
export default {
  name: "TrainExamStat",
  components: {FileStat, VedioStat, TrainStat, ExamStat},
  data () {
    return {
      activeIndex: "1"
    };
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key;
    }
  }
};
</script>

<style scoped>
</style>