import {Http, LocalStorage} from '@/util';
import {Loading, Message} from 'element-ui';
import {SocketService} from './socket-service';
import Cookies from 'js-cookie';
import router from '@/route';

const KEY_LOGIN = 'yx_login';
export class CommonService {

  // 退出系统
  static logout () {
    Cookies.remove(KEY_LOGIN);
    SocketService.disconnect();
    const loading = Loading.service({
      lock: true,
      text: '正在退出...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });

    Http.get({
      url: '/admin/logout',
    }).then(() => {
      loading.close();
      LocalStorage.clearAll();
      router.push('/');
      window.location.reload();
    }).catch(err => {
      loading.close();
      Message.error(err.message);
    });
  }


  // 判断是否登录
  static isLogin () {
    return !!Cookies.get(KEY_LOGIN);
  }

  static login () {
    Cookies.set(KEY_LOGIN, true);
  }
}