<template>
  <div class="container">
    <div class="head">
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param.startDate"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>
      至
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param.endDate"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>

      <el-select
        class="span"
        v-model="param.orderSource"
        placeholder="全部（客户端）"
      >
        <el-option
          v-for="item in orderSourceOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-button class="span" type="primary" @click="query">查询</el-button>
    </div>
    <div class="table">
      <div class="item right">
        <el-radio-group v-model="param.chartGroupType">
          <el-radio-button label="日"></el-radio-button>
          <el-radio-button label="周"></el-radio-button>
          <el-radio-button label="月"></el-radio-button>
          <el-radio-button label="年"></el-radio-button>
        </el-radio-group>
      </div>
      <chart style="top: 60px" ref="chart" :option="chartOpt" />
      <div class="bottom">
        <div>全部：{{ orderTotal.total }}人</div>
        <div>微信：{{ orderTotal.wxTotal }}人</div>
        <div>电话：{{ orderTotal.telTotal }}人</div>
        <div>APP：{{ orderTotal.appTotal }}人</div>
      </div>
    </div>

    <div class="head" style="margin-top: 20px; display: none">
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param2.startDate"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>
      至
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param2.endDate"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>

      <el-select
        class="span"
        v-model="param2.orderSource"
        placeholder="全部（客户端）"
      >
        <el-option
          v-for="item in orderSourceOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-button class="span" type="primary" @click="query2">查询</el-button>
    </div>
    <div class="table" style="display: none">
      <div class="item right">
        <el-radio-group v-model="param2.chartGroupType">
          <el-radio-button label="日"></el-radio-button>
          <el-radio-button label="周"></el-radio-button>
          <el-radio-button label="月"></el-radio-button>
          <el-radio-button label="年"></el-radio-button>
        </el-radio-group>
      </div>
      <chart style="top: 60px" ref="chart2" :option="chartOpt" />
    </div>
  </div>
</template>

<script>
import { ParamUtil, DateUtil} from '@/util';
export default {
  name: "incr",
  data () {
    return {
      param: {
        startDate: null,
        endDate: null,
        orderSource: "",
        chartGroupType: '日'
      },
      param2: {
        startDate: null,
        endDate: null,
        orderSource: "",
        chartGroupType: '日'
      },
      orderSourceOpts: [{value: 4, label: "APP"}, {value: 3, label: "微信"}, {value: 2, label: "电话"}],
      orders: [],
      orderTotal: {total: 0, wxTotal: 0, telTotal: 0, appTotal: 0},
      chartOpt: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        }
      }
    };
  },
  mounted () {
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param2.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param2.endDate = DateUtil.format(DateUtil.getDateTime());
  },
  watch: {
    "param.chartGroupType": function () {
      this.renderIncrOrdersChart(this.orders);
    }
  },
  methods: {
    query () {
      this.$http.post("/admin/analysis/order/propagationincr", ParamUtil.filterEmptyData(this.param)).then(res => {
        //set incr datas
        this.orders = res.data || [];
        this.renderIncrOrdersChart(this.orders);
      });
    },
    query2 () {
      this.$http.post("/admin/analysis/order/advincr", this.param2).then(() => {
        //TODO
      });
    },
    getNextDay (day) {
      let nextDay = new Date(new Date(day).getTime() + 3600 * 24 * 1000);
      return nextDay.getFullYear() + "-" + (nextDay.getMonth() < 9 ? "0" + (nextDay.getMonth() + 1) : (nextDay.getMonth() + 1)) + "-"
                + (nextDay.getDate() < 10 ? "0" + nextDay.getDate() : nextDay.getDate());
    },
    renderIncrOrdersChart (orders) {
      if (!orders || !orders.length) {
        return;
      }

      let dataset = this.getDataSet(orders);
      this.orderTotal = dataset.total;
      let option = {
        title: {
          text: "裂变订单图",
        },
        legend: {
          data: dataset.dimensions
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {show: true},
        series: [{
          name: dataset.dimensions[0],
          type: "line",
          data: dataset.source[0]
        }, {
          name: dataset.dimensions[1],
          type: "line",
          data: dataset.source[1]
        }, {
          name: dataset.dimensions[2],
          type: "line",
          data: dataset.source[2]
        }, {
          name: dataset.dimensions[3],
          type: "line",
          data: dataset.source[3]
        }],
        ...this.chartOpt
      };
      this.$refs.chart.setOption(option, true);
    },
    getDataSet (orders) {
      var axis = [];
      var totalDatas = [];
      var wxDatas = [];
      var telDatas = [];
      var appDatas = [];
      var totalObj = {
        total: 0,
        wxTotal: 0,
        telTotal: 0,
        appTotal: 0
      };

      let week = 1;
      let dim = this.param.startDate;
      while (dim <= this.param.endDate) {
        //set axis and init data.
        let key;
        if (this.param.chartGroupType == "日") {
          key = dim;
        } else {
          if (this.param.chartGroupType == "月") {
            key = dim.substring(0, 7);
          } else if (this.param.chartGroupType == "年") {
            key = dim.substring(0, 4);
          } else if (this.param.chartGroupType == "周") {
            if (new Date(dim).getDay() == 0) {
              key = week ++;
            } else {
              key = week;
            }
          }
        }
        if (axis.indexOf(key) < 0) {
          axis.push(key);
          totalDatas.push(0);
          wxDatas.push(0);
          telDatas.push(0);
          appDatas.push(0);
        }

        for (var j = 0; j < orders.length; j ++) {
          if (orders[j].orderDate == dim) {
            let d = orders[j] ;
            totalObj.total += (d.orderNum || 0);
            let pos = totalDatas.length - 1;
            totalDatas[pos] = totalDatas[pos] + (d.orderNum || 0);
            switch (d.orderSource) {
              case 1 :
                totalObj.wxTotal += (d.orderNum || 0);
                wxDatas[pos] = wxDatas[pos] + (d.orderNum || 0);
                break;
              case 2 :
                totalObj.telTotal += (d.orderNum || 0);
                telDatas[pos] = telDatas[pos] + (d.orderNum || 0);
                break;
              case 3 :
                totalObj.wxTotal += (d.orderNum || 0);
                wxDatas[pos] = wxDatas[pos] + (d.orderNum || 0);
                break;
              case 4 :
                totalObj.appTotal += (d.orderNum || 0);
                appDatas[pos] = appDatas[pos] + (d.orderNum || 0);
                break;
              default:
            }
          }
        }

        //next loop
        dim = this.getNextDay(dim);
      }

      return {
        axis: axis,
        source: [totalDatas, wxDatas, telDatas, appDatas],
        total: totalObj,
        dimensions: ["全部", '微信', '电话', 'APP']
      };
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #edf1f5;
  padding: 8px;

  .head {
    margin-bottom: 8px;
    .span {
      margin-left: 8px;
    }
  }

  .table {
    position: relative;
    border: 1px solid #ddd;
    padding: 8px;
    min-height: 360px;
    .right {
      position: absolute;
      right: 20px;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0px;
    margin-left: 10px;
    margin-bottom: 10px;
    div {
      display: inline-block;
      margin-right: 20px;
    }
  }
}
</style>