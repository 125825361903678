<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="整体" name="ref1">
        <DriverSafetyScoreSummary />
      </el-tab-pane>
      <el-tab-pane label="明细" name="ref2">
        <DriverSafetyScoreDetail />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DriverSafetyScoreSummary from './Summary';
import DriverSafetyScoreDetail from './Detail';

export default {
  components: {
    DriverSafetyScoreSummary,
    DriverSafetyScoreDetail
  },
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>