<template>
  <el-dialog :visible.sync="visible" width="500px" :destroy-on-close="true">
    <div class="content" v-loading="loading">

      <!-- 非退票 -->
      <div v-if="transactionBill && transactionBill.changeType != 3">
        <div class="box" v-for="item in items" :key="item.detailDesc">
          <div class="item">
            <div class="m1">{{ item.title }}</div>
            <div class="m2"></div>
            <div class="m3">
              <span v-if="item.discount && item.amount">-</span>
              <span :class="{ green: item.discount, red: !item.discount }">¥{{ item.amount | moneyFormat }}</span>
            </div>
          </div>
          <div class="desc" v-if="item.detailDesc">
            {{ item.detailDesc }}
          </div>

        </div>


        <div style="float:right;margin-top: 20px;">合计：{{ transactionBill.price > 0 ? '支付 ' : '退款 ' }}{{
          transactionBill.price
          > 0 ? transactionBill.price : -transactionBill.price | moneyFormat }}元</div>


        <div style="margin-top:80px;" v-if="transactionBill.changeType === 1">

          <div>费率表</div>

          <el-image src="https://cdn.youxuanshunfengche.com/miniapp/img/feilv2.png"
            :preview-src-list="['https://cdn.youxuanshunfengche.com/miniapp/img/feilv2.png']"></el-image>
        </div>
      </div>
      <div v-else class="refund-box">


        <div class="box">

          <div class="just-between title">
            <div>
              <span class="word green">订</span>
              <span>订单实付金额</span>
            </div>
            <div> {{ transactionBill.receivedAmount | moneyFormat }}元 </div>
          </div>
          <div class="item">
            <div class="m1">成人票 x {{ transactionBill.adultNumber }}</div>
            <div class="m2"></div>
            <div class="m3">¥{{ (transactionBill.basePrice * transactionBill.adultNumber) | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.childNumber">
            <div class="m1">儿童票 x {{ transactionBill.childNumber }}</div>
            <div class="m2"></div>
            <div class="m3">¥{{ (transactionBill.childrenPrice * transactionBill.childNumber) | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.startAreaUpPrice">
            <div class="m1">起点加价</div>
            <div class="m2"></div>
            <div class="m3">¥{{ transactionBill.startAreaUpPrice | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.endAreaUpPrice">
            <div class="m1">终点加价</div>
            <div class="m2"></div>
            <div class="m3">¥{{ transactionBill.endAreaUpPrice | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.couponAmount">
            <div class="m1">优惠券优惠</div>
            <div class="m2"></div>
            <div class="m3">-¥{{ transactionBill.couponAmount | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.discountWordAmount">
            <div class="m1">优惠口令优惠</div>
            <div class="m2"></div>
            <div class="m3">-¥{{ transactionBill.discountWordAmount | moneyFormat }}</div>
          </div>
          <div class="item" v-if="transactionBill.aheadAmount">
            <div class="m1">早价优惠 x {{ transactionBill.adultNumber }}</div>
            <div class="m2"></div>
            <div class="m3">-¥{{ transactionBill.aheadAmount | moneyFormat }}</div>
          </div>
        </div>

        <div class="box" v-if="transactionBill.seatPrice">
          <div class="just-between title">
            <div>
              <span class="word blue">选</span>
              <span>选座费用退还</span>
            </div>
            <div> {{ transactionBill.seatPrice | moneyFormat }}元 </div>
          </div>
          <div class="item">
            <div class="m1">选座费用退还</div>
          </div>
        </div>

        <div class="box" v-if="transactionBill.helpActionDiscount">
          <div class="just-between title">
            <div>
              <span class="word red">助</span>
              <span>助力红包扣除</span>
            </div>
            <div> -{{ transactionBill.helpActionDiscount | moneyFormat }}元 </div>
          </div>
          <div class="item">
            <div class="m1">助力活动奖励金额</div>
          </div>
        </div>

        <div class="box" v-if="transactionBill.isCarStart && transactionBill.startAreaUpPrice">
          <div class="just-between title">
            <div>
              <span class="word yellow">起</span>
              <span>起点加价扣除</span>
            </div>
            <div> -{{ transactionBill.startAreaUpPrice | moneyFormat }}元 </div>
          </div>
          <div class="item">
            <div class="m1">司机已出发，远途油费补偿</div>
          </div>
        </div>

        <div class="box">
          <div class="just-between title">
            <div>退票手续费</div>
            <div>
              <span v-if="transactionBill.serviceChargeAmount">-</span>
              <span>{{ transactionBill.serviceChargeAmount | moneyFormat }}元</span>
            </div>
          </div>
          <div class="item" v-if="transactionBill.fullTicketAmount && transactionBill.serviceChargeRate">
            <div class="m1">
              <span v-if="transactionBill.serviceChargeRate === 0">全票金额{{ transactionBill.fullTicketAmount | moneyFormat
              }}
                *
                当前退票费率{{
                  transactionBill.serviceChargeRate
                }}%
              </span>
              <span wx:else>全票金额{{ transactionBill.fullTicketAmount | moneyFormat }} * 当前退票费率{{
                transactionBill.serviceChargeRate
              }}%（最低1元）
              </span>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="just-between green">
            <div>实际退票金额</div>
            <div> {{ transactionBill.price | moneyFormat }}元 </div>
          </div>
        </div>

        <div style="margin-top:20px;">

          <div>费率表</div>

          <el-image src="https://cdn.youxuanshunfengche.com/miniapp/img/feilv2.png"
            :preview-src-list="['https://cdn.youxuanshunfengche.com/miniapp/img/feilv2.png']"></el-image>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getOrderTransactionBillDetail } from '@/api';
import { mapState } from "vuex";

export default {
  props: {
    span: String,
    billId: Number,
    modal: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      changeTypeOpts: (state) => state.enumMap["CHANGE_TYPE"],
    }),
    items () {
      return (this.billDetail && this.billDetail.billBlock && this.billDetail.billBlock.billItems) || [];
    }
  },

  data () {
    return {
      billDetail: null,
      transactionBill: null,
      loading: false,
      visible: false
    };
  },
  methods: {
    show (billId) {
      this.visible = true;
      this.loading = true;
      getOrderTransactionBillDetail(billId).then((res) => {
        const { billDetail, transactionBill } = res.data;
        this.loading = false;
        this.billDetail = billDetail;
        this.transactionBill = transactionBill;
      });
    }

  }

};
</script>
<style lang="less" scoped>
.content {
  min-height: 400px;
  padding: 10px 20px 60px;

  .box {
    margin-bottom: 10px;

    .desc {
      font-size: 12px;
      color: #999;
      margin-top: 2px;
      text-align: left;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;

      .m1 {
        color: #6b6b6b;
        margin-right: 20px;
      }

      .m2 {
        flex: 1;
        border-bottom: 1px dashed #979797;
        height: 1px;
      }

      .m3 {
        display: flex;
        justify-content: center;
        margin-left: 20px;

        .red {
          color: #ff0404;
        }

        .green {
          color: #43966a;
        }
      }
    }
  }
}

.refund-box {
  .box {
    background: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 6px;
      color: grey;

      .m1 {
        margin-right: 20px;
      }

      .m2 {
        flex: 1;
        border-bottom: 1px dashed #979797;
        height: 1px;
      }

      .m3 {
        margin-left: 20px;
      }
    }

    .word {
      font-size: 14px;
      width: 20px;
      height: 20px;
      color: white;
      border-radius: 8px;
      padding: 6px;
      margin-right: 12px;
      display: inline-block;
      line-height: 20px;
      text-align: center;

      &.blue {
        background: #5da1df;
      }

      &.green {
        background: #43966a;
      }

      &.red {
        background: #ff845d;
      }

      &.yellow {
        background: #F7A900;
      }
    }
  }
}


.green {
  color: #43966A;
}

.just-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.span {
  color: #4894ef;
  cursor: pointer;
}
</style>