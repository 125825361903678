import { Http } from "@/util";

// 查询空闲司机
export function getIdleDrivers(areaId) {
  return Http.get("/admin/scheduling/idle/driver/list", { areaId });
}

// 查询司机排班情况
export function schedulingDrivers(pathId) {
  return Http.get("/admin/scheduling/driver/list", { pathId });
}

// 将司机加入排班
export function addScheduling(param) {
  return Http.post("/admin/scheduling/add_scheduling", param);
}

// 取消发班
export function cancelTrip(param) {
  return Http.post("/admin/scheduling/cancel_trip", param);
}

// 调整发班顺序
export function adjustScheduling(param) {
  return Http.post("/admin/scheduling/adjust_scheduling", param);
}

// 查询所有区域的排班情况
export function getSchedulingMap() {
  return Http.get("/admin/scheduling/scheduling_map");
}

// 保存区域排班比例
export function saveAreaSchedulingProportion(param, areaId) {
  return Http.post(`/admin/scheduling/save/scheduling_proportion?areaId=${areaId}`, param);
}

// 查询区域排班比例
export function getAreaSchedulingProportion() {
  return Http.get("/admin/scheduling/get/scheduling_proportion");
}

// 修改行程座位数
export function modifyRouteSeats(routeId, seats) {
  return Http.get("/admin/scheduling/modify/route/seats", { routeId, seats });
}

// 帮司机请假
export function submitLeave(param) {
  return Http.post("/admin/driver/leave/submit", param);
}
