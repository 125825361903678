<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="开始日期">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="param.startTime"
              style="width: 200px"
              @change="query()"
              type="datetime"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="param.endTime"
              style="width: 200px"
              @change="query()"
              type="datetime"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="路线：">
            <el-select
              v-model="param.pathIds"
              placeholder="请选择"
              multiple
              clearable
              @change="query()"
            >
              <el-option
                v-for="item in pathOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="交易类型：">
            <el-select
              v-model="param.transactionTypes"
              placeholder="请选择"
              multiple
              clearable
              @change="query()"
            >
              <el-option
                v-for="item in transactionTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否确认">
            <el-select v-model="param.confirmed" placeholder="请选择" clearable @change="query()">
              <el-option :key="1" label="是" :value="1"></el-option>
              <el-option :key="0" label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单ID">
            <el-input v-model="param.orderId" style="width: 200px" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="summary">
      <div class="item">
        总应收：{{ billSummary.totalAmount | moneyFormat }} = 订单金额（{{
          billSummary.orderAmount | moneyFormat
        }}）+ 服务费（{{ billSummary.serviceAmount | moneyFormat }}）+ 座位费（{{
          billSummary.seatAmount | moneyFormat
        }}）+ 出发补贴（{{ billSummary.departureSubsidyAmount | moneyFormat }}）- 助力金额（{{
          billSummary.helpActionAmount | moneyFormat
        }}）- 人工退款（{{ billSummary.refundAmount | moneyFormat }}）
      </div>
      <div class="item">
        <span>
          实收：{{ billSummary.totalReceivedAmount | moneyFormat }} /{{
            billSummary.totalActReceivedAmount | moneyFormat
          }}</span
        >
        <span
          >实退：{{ billSummary.totalRefundAmount | moneyFormat }} /
          {{ billSummary.totalActRefundAmount | moneyFormat }}</span
        >

        <span>司机代付：{{ billSummary.driverPaidAmount | moneyFormat }}</span>
        <span>剩余可退：{{ billSummary.available | moneyFormat }}</span>
      </div>
    </div>

    <div class="table">
      <el-table :data="list" stripe :row-class-name="tableRowClassName" max-height="560">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="transactionId" label="交易ID" width="150"></el-table-column>

        <el-table-column prop="tradeNo" label="交易单号" width="150"></el-table-column>

        <el-table-column prop="orderId" label="订单ID" width="150">
          <template slot-scope="scope">
            <el-link v-operate-order:transaction="scope.row.orderId" type="primary">{{
              scope.row.orderId
            }}</el-link>
          </template>
        </el-table-column>

        <el-table-column prop="remark" label="说明"></el-table-column>

        <el-table-column prop="confirmed" label="是否确认">
          <template slot-scope="scope">{{ scope.row.confirmed ? "是" : "否" }}</template>
        </el-table-column>

        <el-table-column prop="isRefund" label="是否退款">
          <template slot-scope="scope">{{ scope.row.isRefund ? "是" : "否" }}</template>
        </el-table-column>

        <el-table-column prop="amount" label="交易金额">
          <template slot-scope="scope">
            <span v-operate-order:billDetail="scope.row.transactionBillId"
              >{{ scope.row.amount | moneyFormat }}元</span
            >
          </template>
        </el-table-column>

        <el-table-column prop="confirmAmount" label="确认金额">
          <template slot-scope="scope">{{ scope.row.confirmAmount | moneyFormat }}</template>
        </el-table-column>

        <el-table-column prop="transactionType" label="交易类型">
          <template slot-scope="scope">{{
            scope.row.transactionType | enumTransfer(transactionTypeOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="refundStatus" label="退款/支付状态" width="150">
          <template slot-scope="scope">{{ scope.row.payState || scope.row.refundStatus }}</template>
        </el-table-column>

        <el-table-column
          prop="refundRelTransactionId"
          label="退款关联交易ID"
          width="150"
        ></el-table-column>

        <el-table-column prop="refundSuccessTime" label="退款/支付成功时间" width="150">
          <template slot-scope="scope">{{
            scope.row.paySuccessTime || scope.row.refundSuccessTime
          }}</template>
        </el-table-column>

        <el-table-column
          prop="refundRecvAccount"
          label="退款入账账户"
          width="150"
        ></el-table-column>

        <!-- <el-table-column
          prop="refund_account"
          label="退款资金来源"
          width="150"
        ></el-table-column>

        <el-table-column
          prop="pay_state_desc"
          label="交易状态描述"
          width="150"
        ></el-table-column> -->

        <el-table-column prop="payBankType" label="付款银行"></el-table-column>

        <el-table-column prop="returnMsg" label="返回信息"></el-table-column>

        <el-table-column prop="errMsg" label="错误信息"></el-table-column>

        <el-table-column prop="errCode" label="错误码"></el-table-column>

        <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-sizes="pageSizes"
        layout="total, prev, sizes,pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BaseDataService } from "@/service";
import { queryTransactionList } from "@/api";
import { Toolkit, DateUtil } from "@/util";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
      transactionTypeOpts: (state) => state.enumMap["TRANSACTION_TYPE"],
    }),
  },
  data() {
    return {
      yesNoOpts: BaseDataService.yesNoOpts(),
      param: {
        pathIds: [],
        confirmed: 1,
        startTime: DateUtil.format(DateUtil.getMonthFirstDay(), "yyyy-MM-dd 00:00:00"),
      },
      list: [],
      pageSizes: [50, 100, 150, 200],
      total: 0,
      currentPage: 1,
      pageSize: 50,
      summary: [],
      availSummary: {},
      billSummary: {},
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: Toolkit.filterEmpty(this.param),
      };
      queryTransactionList(param).then((res) => {
        const { listData, availSummary, billSummary } = res.data || {};

        const { total, current, records = [] } = listData;
        this.list = records;
        this.currentPage = current;
        this.total = total;
        this.availSummary = availSummary;
        this.billSummary = billSummary;
      });
    },

    handleSizeChange(pageSize) {
      this.currentPage = pageSize;
      this.pageSize = pageSize;
      this.query(pageSize);
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    tableRowClassName({ row }) {
      return row.is_refund ? "highlight" : "";
    },
  },
};
</script>
<style lang="less" scoped>
.summary {
  margin: 10px 0;
  background: #ecf5ff;
  color: #409eff;
  padding: 8px 20px;
  flex: 1;
  border-radius: 5px;
  text-align: left;
  border-color: #b3d8ff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .item {
    margin: 6px;

    span {
      margin-right: 16px;
    }
  }
}
</style>
