<template>
  <div>
    <div class="query-container space-between">
      <el-form size="small" :inline="true">
        <el-form-item label="查询时间：">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.startDate"
            type="date"
            placeholder="选择日期"
            @change="query(1)"
            style="width: 200px"
          ></el-date-picker
        ></el-form-item>

        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.endDate"
            type="date"
            placeholder="选择日期"
            @change="query(1)"
            style="width: 200px"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="param.status" @change="query(1)" style="width: 200px" clearable>
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="司机编号">
          <el-input v-model="param.driverNo" clearable style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table
        :data="list"
        stripe
        max-height="650"
        @expand-change="handleRowExpand"
        :expand-row-keys="expandRow"
        row-key="id"
        size="small"
      >
        <el-table-column type="expand" show-overflow-tooltip>
          <template slot-scope="props">
            <div style="padding: 20px 160px 20px 80px">
              <el-table :data="props.row.tripList" :show-header="false">
                <el-table-column
                  label="序号"
                  type="index"
                  show-overflow-tooltip
                  width="50"
                ></el-table-column>

                <el-table-column prop="startStationName" label="高速入口"></el-table-column>
                <el-table-column prop="endStationName" label="高速出口"></el-table-column>
                <el-table-column prop="tripStartTime" label="进站时间"></el-table-column>
                <el-table-column prop="tripEndTime" label="出站时间"></el-table-column>
                <el-table-column prop="totalAmount" label="ETC费用">
                  <template slot-scope="scope">
                    {{ scope.row.totalAmount | moneyFormat(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="driverNo" label="司机编号"></el-table-column>
        <el-table-column prop="date" label="行程日期"></el-table-column>
        <el-table-column prop="totalAmount" label="总金额">
          <template slot-scope="scope">
            {{ scope.row.totalAmount | moneyFormat(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="highwayEntrance" label="规划入口"></el-table-column>
        <el-table-column prop="highwayExit" label="规划出口"></el-table-column>
        <el-table-column prop="createTime" label="结算时间"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.status === "CONFIRMED"
                ? "已确认"
                : scope.row.status === "ABNORMAL"
                ? "待确认"
                : "正常"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="详情">
          <template slot-scope="scope">
            <div class="spe-box">
              <div>
                <el-button type="text" size="small" @click="showRoutePlan(scope.row.routeId)"
                  >行程规划</el-button
                >
              </div>

              <div>
                <el-button type="text" size="small" @click="go2QcPage(scope.row.routeId)"
                  ><span>质检详情</span></el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="detail" label="审核">
          <template slot-scope="scope">
            <div class="spe-box">
              <div>
                <el-button type="text" size="small" @click="openConfirmDialog(scope.row)"
                  ><span>审核</span></el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          @size-change="handleSizeChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="replyVisible"
      width="660px"
      max-height="600"
      :size="size"
      title="过路费审核"
      :rules="rules"
      :close-on-click-modal="false"
    >
      <el-form :model="confirmForm" :size="size" ref="confirmForm">
        <el-form-item prop="totalAmount" label="ETC合计金额（元）">
          {{ confirmForm.totalAmount | moneyFormat(2) }}</el-form-item
        >

        <div style="margin: 4px 0 6px">
          <span class="red"
            >请注意这里的单位是 <span style="font-weight: bold">（分）！！！</span></span
          >
        </div>

        <el-form-item prop="confirmAmount" label="实际金额（分）">
          <el-input-number
            v-model="confirmForm.confirmAmount"
            clearable
            placeholder="实际金额"
            :precision="0"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button @click.stop="submit" type="primary" size="small">确 认</el-button>

        <el-button @click.stop="replyVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DateUtil, ParamUtil } from "@/util";
import { getTollFeeSettlementList, getEtcTripList, toolFeeConfirm } from "@/api";
import Router from "@/route";
import { SHOW_ROUTE_PLAN } from "@/core/const/global-event";

export default {
  components: {},

  props: {},

  watch: {},

  created() {},

  mounted() {
    this.query();
  },

  computed: {},

  data() {
    return {
      size: "small",
      rules: {
        confirmAmount: [{ required: true, message: "请输入实际金额", trigger: "blur" }],
      },
      statusOpts: [
        { label: "待确认", value: "ABNORMAL" },
        { label: "已确认", value: "CONFIRMED" },
        { label: "正常", value: "NORMAL" },
      ],
      replyVisible: false,
      confirmForm: {},
      param: {
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
        status: "ABNORMAL",
      },
      currentPage: 1,
      total: 0,
      pageSize: 10,
      list: [],
      expandRow: [],
    };
  },

  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: ParamUtil.filterEmptyData(this.param),
        startTime: this.param.startDate,
        endTime: this.param.endDate,
      };

      getTollFeeSettlementList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.expandRow = [];
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleRowExpand(item, expandedItems) {
      const oldItems = this.expandRow;
      this.expandRow = expandedItems.map((i) => i.id);
      // 是否是展开
      const expanded = expandedItems.filter((i) => !oldItems.includes(i.id));
      if (expanded.length) {
        getEtcTripList(item.routeId).then((res) => {
          item.tripList = res.data;
          const index = this.list.findIndex((i) => i.id === item.id);
          this.$set(this.list, index, item);
        });
      }
    },

    showRoutePlan(routeId) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, { id: routeId }, false);
    },

    go2QcPage(routeId) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId },
      });
      window.open(href, "_blank");
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    openConfirmDialog(row) {
      this.confirmForm = { ...row, confirmAmount: row.totalAmount };
      this.replyVisible = true;
    },

    submit() {
      this.$refs.confirmForm.validate((valid) => {
        if (valid) {
          const param = { ...this.confirmForm };
          toolFeeConfirm(param).then(() => {
            this.replyVisible = false;
            this.query();
          });
        }
      });
    },

    handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.query(1);
    },
  },
};
</script>
<style scoped lang="less"></style>
