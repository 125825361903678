<template>
  <div class="editor">
    <div class="query-container">
      <span>查询时段：</span>
      <el-date-picker
              v-model="params.startDate"
              type="datetime"
              format="yyyy-MM-dd"
              placeholder="起始时间"
      ></el-date-picker>
        <el-date-picker
                v-model="params.endDate"
                type="datetime"
                format="yyyy-MM-dd"
                placeholder="截止时间"
                style="margin-left: 8px;"
        ></el-date-picker>

      <el-select v-model="params.flCode" style="margin-left: 8px;" placeholder="模板分类" clearable>
        <el-option
                v-for="item in flCodeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>

      <el-select v-model="params.emergency" style="margin-left: 8px;" placeholder="紧急程度" clearable>
        <el-option
                v-for="item in emergencyObjs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>

      <el-select v-model="params.needConfirm" style="margin-left: 8px;" placeholder="是否需要确认" clearable>
        <el-option
                v-for="item in needConfirmObjs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>

      <el-button @click="query" type="primary" style="margin-left: 8px;">查询</el-button>
    </div>

    <el-table :data="notices" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="emergencyName" label="重要性" width="120">
      </el-table-column>
      <el-table-column prop="receiverObj" label="发送对象" width="200">
      </el-table-column>
      <el-table-column prop="title" label="主标题" width="150">
      </el-table-column>
      <el-table-column prop="subTitle" label="副标题" width="150">
      </el-table-column>
      <el-table-column prop="readObj" label="已读" width="120">
      </el-table-column>
      <el-table-column prop="submitObj" label="已确认" width="120">
      </el-table-column>
      <el-table-column prop="sendByName" label="发送人" width="120">
      </el-table-column>
      <el-table-column prop="sendTime" label="发送时间" width="120">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="showNoticeInfo(scope.row)" type="text" size="small"
            >详情</el-button
          >
          <el-button v-if="scope.row.readNum" @click="viewNotice(scope.row)" type="text" size="small"
          >数据</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page.pageNo"
      :page-sizes="pageSizes"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="onPageSizeChange"
      @current-change="onPageNoChange"
    />

    <el-dialog :visible.sync="showDetailDialog" title="消息详情">
      <div class="main">
        <div class="title row">{{ noticeInfo.emergencyName }}通知</div>
        <div class="row">
          <span class="label">标题：</span>
          <span class="content">{{ noticeInfo.title }}</span>
        </div>
        <div class="row">
          <span class="label">副标题：</span>
          <span class="content">{{ noticeInfo.subTitle }}</span>
        </div>
        <div class="row">
          <span class="label">内容：</span>
          <span class="content" v-html="noticeInfo.content"></span>
        </div>
        <div v-for="field in noticeInfo.fields" :key="field.id" class="row">
          <span class="label"></span>
          <span class="content">
              <template v-if="field.type == 'img'">
                <el-image :src="field.value" style="width: 178px"></el-image>
              </template>
              <template v-if="field.type == 'vedio'">
                <video :src="field.value" style="width: 178px"></video>
              </template>
              <template v-if="field.type == 'voice'">
                <audio :src="field.value"></audio>
              </template>
              <template v-if="field.type == 'map'">
                <div :id="field.id" style="width: 100%; height: 148px">
                  <img src="../../../assets/image/map.png" style="width: 100%" />
                </div>
              </template>
              <template v-if="field.type == 'link'">
                {{ field.tip
                }}<el-link type="primary">{{ field.name }}</el-link>
              </template>
              <template v-if="field.type == 'input'">
                <div>{{ field.name }}</div>
                <el-input
                        type="textarea"
                        style="border-radius: 0px"
                        :readonly="true"
                        :placeholder="field.tip"
                ></el-input>
                <el-button
                        style="width: 100%; border-radius: 0px; border-top: 0px"
                >{{ field.btnName }}</el-button
                >
              </template>
            </span>
        </div>
        <div class="row btns">
          <el-button v-for="btn in noticeInfo.btns" :key="btn.id">{{
            btn.name
            }}</el-button>
        </div>
      </div>

      <div class="footer">
        <el-button @click="showDetailDialog = false" type="primary"
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showDataDialog" title="数据详情">
      <div class="main">
        <div class="row">
          <span>{{noticeInfo.templateObj.code}}</span>
          <span>{{noticeInfo.templateObj.flCode}}</span>
          <span>{{noticeInfo.templateObj.emergency}}</span>
          <span>发送人：{{noticeInfo.templateObj.sendByName}}</span>
          <span>发送时间：{{noticeInfo.templateObj.sendTime}}</span>
        </div>
        <div class="row">
          <span>通知人数： {{noticeInfo.receiverNum}}</span>
          <span>查看人数： {{noticeInfo.readNum}}</span>
          <span>确认人数： {{noticeInfo.confirmNum}}</span>
        </div>

        <el-table :data="noticeInfo.details" style="width: 100%">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="driverNo" label="司机编号" width="120">
          </el-table-column>
          <el-table-column prop="teamName" label="车队" width="200">
          </el-table-column>
          <el-table-column prop="seat" label="车型" width="150">
          </el-table-column>
          <el-table-column prop="readed" label="是否阅读" width="150">
          </el-table-column>
          <el-table-column prop="readTime" label="阅读时间" width="120">
          </el-table-column>
          <el-table-column prop="confirmed" label="是否确认" width="120">
          </el-table-column>
          <el-table-column prop="confirmTime" label="确认时间" width="120">
          </el-table-column>
          <el-table-column prop="btnValue" label="按钮" width="120">
          </el-table-column>
          <el-table-column prop="inputText" label="输入值" width="120">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button @click="gotoDriverInfo(scope.row)" type="text" size="small"
              >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <el-button @click="showDataDialog = false" type="primary"
        >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {DateUtil} from '@/util/index';
export default {
  name: "NoticeSendRecord",
  data () {
    return {
      params: {},
      flCodeOpts: [],
      noticeTypeEnums: [],
      emergencyObjs: [],
      needConfirmObjs: [{value: "0", label: "否"}, {value: "1", label: "是"}],
      pageSizes: [10, 30, 50],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      notices: [],
      noticeInfo: {templateObj: {}},
      showDetailDialog: false,
      showDataDialog: false
    };
  },
  mounted () {
    this.initData();
    this.query();
  },
  methods: {
    initData () {
      this.emergencyObjs = window.emergencyObjs;
      this.flCodeOpts = window.flCodeOpts;
      this.noticeTypes = window.noticeTypes;
    },
    query () {
      this.initPage();
    },
    initPage () {
      let params = {};
      Object.assign(params, this.params, this.page);
      if (params.startDate) {
        params.startDate = DateUtil.format(params.startDate, "yyyy-MM-dd");
      }
      if (params.endDate) {
        params.endDate = DateUtil.format(params.endDate, "yyyy-MM-dd");
      }
      this.$http.post("/admin/notice/queryrecord", params).then(res => {
        let page = res.data || {};
        this.page.pageNo = page.current;
        this.page.pageSize = page.size;
        this.page.total = page.total;
        this.notices = page.records || [];
        this.notices.forEach(notice => {
          for (var i = 0; i < this.noticeTypeEnums.length; i ++) {
            let noticeType = this.noticeTypeEnums[i];
            if (notice.code == noticeType.code) {
              notice.displayCode = noticeType['displayName'];
            }
          }
          for (var j = 0; j < this.emergencyObjs.length; j ++) {
            let emergencyObj = this.emergencyObjs[j];
            if (notice.emergency == emergencyObj.value) {
              notice.emergencyName = emergencyObj.label;
            }
          }
          notice.receiverObj = notice.receiverTeam || notice.receiverNo;
          notice.readObj = notice.readNum + "/" + notice.receiverNum;
          notice.submitObj = notice.confirmNum + "/" + notice.receiverNum;
        });
      });
    },
    onPageSizeChange (pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    onPageNoChange (pageNo) {
      this.page.pageNo = pageNo;
      this.initPage();
    },
    viewNotice (notice) {
      this.noticeInfo = notice;
      if (!this.noticeInfo.details) {
        this.$http.post("/admin/notice/record/detail/" + this.noticeInfo.id, {}).then((res) => {
          //get detail list here
          this.noticeInfo.templateObj = res.data.template || {};
          this.noticeInfo.details = res.data.details || [];
          this.showDataDialog = true;
        });
      }
    },
    showNoticeInfo (notice) {
      if (notice.templateObj) {
        this.noticeInfo = notice;
        this.showDetailDialog = true;
      } else {
        this.$http.post("/admin/notice/template/" + notice.templateId, {}).then((res) => {
          notice.templateObj = res.data || {};
          this.noticeInfo = notice;
          this.showDetailDialog = true;
        });
      }
    },
    gotoDriverInfo () {
      //TODO
    }
  }
};
</script>

<style lang="less" scoped>
.query-container {
  padding-bottom: 20px;
}
.row {
  margin: 10px 0;
  .label {
    display: inline-block;
    width: 72px;
  }
  .content {
    display: inline-block;
  }
}
.btns.row {
  margin-top: 50px;
}

.title {
  margin-bottom: 40px;
  font-weight: 600;
}
.footer {
  text-align: right;
  margin-top: 10px;
}
</style>