<template>
  <span @click="show = true" class="slot">
    <slot></slot>

    <el-dialog
      :visible="show"
      width="900px"
      :show-close="false"
      :close-on-click-modal="false"
      @opened="getLocation"
    >
      <div id="driverMap" class="map"></div>

      <div class="tip" style="margin-top: 10px">{{ tip }}</div>
      <span slot="footer" class="footer">
        <el-button v-if="order" @click.stop="refreshData" size="small"
          >刷 新</el-button
        >
        <el-button @click.stop="close" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
const carPng = require('../assets/image/car_blue.png');
const start = require('../assets/image/start.png');
import {getDriverLocation, getOrderRealTimePositioning} from '@/api';
import { DateUtil } from '@/util';

const SHOW_TYPE = {
  DRIVER: 'driver',
  ORDER: 'order',
  SPEC: 'spec',
};
export default {
  name: "DriverLocation",
  props: {
    id: Number,
    carNo: String,
  },
  created () {

  },
  data () {
    return {
      show: false,
      tip: '',
      order: null,
      type: SHOW_TYPE.DRIVER,
    };
  },
  methods: {
    close () {
      this.tip = '';
      this.order = null;
      this.show = false;

    },
    showLocation (driverId) {
      this.type = SHOW_TYPE.DRIVER;
      this.driverId = driverId;
      this.show = true;
    },

    showSpecLocation (item) {
      this.type = SHOW_TYPE.SPEC;
      this.locationInfo = item;
      this.show = true;
    },

    showRealTimeLocation (order) {
      this.type = SHOW_TYPE.ORDER;
      this.order = order;
      this.show = true;
    },

    refreshData () {

      getOrderRealTimePositioning(this.order.id).then(res=>{
        this.$set(this.order, 'realTimePositioning', res.data);
        this.getLocation();
      });
    },

    getLocation () {
      let map = this.map;
      if (!map) {
        map = new AMap.Map("driverMap", {
          resizeEnable: true,
          dragEnable: true,
          zoom: 14
        });
        this.map = map;
      } else {
        map.clearMap();
      }

      const type = this.type;

      if (SHOW_TYPE.SPEC === type) {
        const {lat, lng, address, location_time} = this.locationInfo || {};
        if (!lat || !lng) {
          return;
        }

        const time = new Date(location_time);
        const timeStr = DateUtil.format(time, 'yyyy-MM-dd hh:mm:ss');

        new AMap.Marker({
          position: [lng, lat],
          draggable: false,
          raiseOnDrag: false,
          map: map,
          label: {
            //修改label相对于maker的位置
            direction: 'top',
            content: "<div class='info'>" + `${address} | ${timeStr}` + "</div>",
          },
          icon: new AMap.Icon({
            size: new AMap.Size(40, 40), //图标大小
            image: carPng,
            imageSize: new AMap.Size(40, 40)
          })
        });

        map.setCenter([lng, lat]);
      } else if (SHOW_TYPE.DRIVER === type) {

        getDriverLocation(this.driverId).then((res) =>{
          const {driver, location = {}} = res.data || {};
          const {lat, lng, gpsTime = '', vendor} = location || {};

          const time = gpsTime.substr(5);
          const locationSource = vendor === "jimi" ? 'GPS' : '手机';
          if (lat && lng) {
            new AMap.Marker({
              position: [lng, lat],
              draggable: true,
              raiseOnDrag: false,
              map: map,
              label: {
                //修改label相对于maker的位置
                direction: 'top',
                content: "<div class='info'>" + `${driver.no} | ${time} | ${locationSource}` + "</div>",
              },
              icon: new AMap.Icon({
                size: new AMap.Size(40, 40), //图标大小
                image: carPng,
                imageSize: new AMap.Size(40, 40)
              })
            });

            map.setCenter([lng, lat]);
          }

        });
      } else if (SHOW_TYPE.ORDER === type) {
        const {driverId, depLongitude, depLatitude, realTimePositioning} = this.order;
        this.tip = '距原订单地址为' + realTimePositioning.distance + '米';
        getDriverLocation(driverId).then((res) =>{
          const {driver, location = {}} = res.data || {};
          const {lat, lng, gpsTime = '', vendor} = location || {};
          const time = gpsTime.substr(5);
          const locationSource = vendor === "jimi" ? 'GPS' : '手机';

          const markers = [];
          if (lat && lng) {
            const car = new AMap.Marker({
              position: [lng, lat],
              draggable: true,
              raiseOnDrag: false,
              label: {
                //修改label相对于maker的位置
                direction: 'top',
                content: "<div class='info'>" + `${driver.no} | ${time} | ${locationSource}` + "</div>",
              },
              icon: new AMap.Icon({
                size: new AMap.Size(40, 40), //图标大小
                image: carPng,
                imageSize: new AMap.Size(40, 40)
              })
            });
            const dep = new AMap.Marker({
              position: [+depLongitude, +depLatitude],
              draggable: true,
              raiseOnDrag: false,
              icon: new AMap.Icon({
                image: start,
                size: new AMap.Size(40, 40), //图标大小
                imageSize: new AMap.Size(40, 40)
              })
            });
            const cur = new AMap.Marker({
              position: [+realTimePositioning.lng, +realTimePositioning.lat],
              draggable: true,
              raiseOnDrag: false,
              label: {
                //修改label相对于maker的位置
                direction: 'top',
                content: "<div class='info'>" + `${realTimePositioning.updateTime} | ${realTimePositioning.address}` + "</div>",
              },
            });
            markers.push(car);
            markers.push(dep);
            markers.push(cur);

            map.setCenter([+realTimePositioning.lng, +realTimePositioning.lat]);
            map.add(markers);
          }

        });
      }

    }
  }
};
</script>

<style scoped lang="less">
.slot {
  font-size: 1.5em;
  cursor: pointer;
}

.map {
  width: 850px;
  height: 600px;
}
</style>
