<template>
  <div>
    <el-tabs v-model="tab">
      <el-tab-pane label="数据概况" name="ref1">
        <Summary />
      </el-tab-pane>
      <el-tab-pane label="变化趋势" name="ref2">
        <Trend />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Summary from "./summary";
import Trend from "./Trend";

export default {
  components: {
    Summary,
    Trend,
  },
  props: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  data() {
    return {
      tab: "ref1",
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped></style>
