<template>


    <div>
        <el-drawer :visible.sync="visible" direction="ltr" :with-header="false" custom-class="drawer-modal" title="乘客地图"
            :withHeader="false">

            <div class="box">

                <div class="btn-box">
                    <el-button type="primary" :size="size" @click="addPoint()">添加根推荐点</el-button>
                    <el-button type="warning" :size="size" @click="saveAll()">保存</el-button>

                </div>

                <div class="item-box">

                    <el-tree :data="pointTree" :props="defaultProps" :default-expand-all="true">

                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span class="item">{{ data.name }}</span>
                            <span>

                                <el-button type="text" size="mini" @click.stop="() => remove(node, data)">
                                    删除
                                </el-button>
                                <el-button type="text" size="mini" @click.stop="() => addPoint(data)">
                                    添加
                                </el-button>
                                <el-button type="text" size="mini" @click.stop="() => update(node, data)">
                                    编辑
                                </el-button>
                                <el-button type="text" size="mini" :disabled="!data.lat || !data.lng"
                                    @click.stop="() => locate(data)">
                                    <span :class="isWarnData(data) ? 'red' : ''">查看定位</span>
                                </el-button>
                            </span>
                        </span>

                    </el-tree>

                </div>
            </div>
        </el-drawer>

        <el-dialog :visible.sync="formVisable" width="630px" max-height="600" title="推荐点设置"
            :close-on-click-modal="false">
            <el-form ref="form" :model="pointForm" label-width="120px" :size="size" :rules="rules">
                <el-form-item label="所属区域：" prop="areaName">
                    <el-input v-model="pointForm.areaName" style="width: 100%"></el-input>
                </el-form-item>

                <el-form-item label="推荐点名称：" prop="name">
                    <el-input v-model="pointForm.name" style="width: 100%"></el-input>
                </el-form-item>

                <el-form-item label="位置：">
                    <el-input v-model="pointForm.lat" style="width: 30%"></el-input> &nbsp;&nbsp;
                    <el-input v-model="pointForm.lng" style="width: 30%"></el-input> &nbsp;&nbsp;
                    <el-button type="primary" icon="el-icon-location-information" circle
                        @click="showLocation"></el-button>
                </el-form-item>

                <el-form-item label="图文提示：" prop="imgTips">
                    <div style="margin-bottom: 8px;"><el-button type="warning" :size="size"
                            @click="addImgTip()">添加引导</el-button></div>
                </el-form-item>


                <div v-for="(item, index) in pointForm.imgTips" :key="`tip${index}`">
                    <el-form-item :label="`引导步骤${index + 1}`" prop="imgTips">
                        <div class="tip-box">
                            <span class="content">
                                <img-tip v-model="pointForm.imgTips[index]"></img-tip>
                            </span>

                            <span class="clear">
                                <el-button type="primary" plain @click="delImgTip(index)">删除</el-button>
                            </span>

                        </div>
                    </el-form-item>

                </div>
            </el-form>

            <span slot="footer">
                <el-button type="warning" :size="size" @click="saveRecPoint()">保存</el-button>
                <el-button type="info" :size="size" @click="formVisable = false">取消</el-button>
            </span>
        </el-dialog>
    </div>


</template>
<script>
import ImgTip from './ImgTip.vue';
import { SHOW_LOCATION_SELECT_MAP } from "@/core/const/global-event";

import { Toolkit } from '@/util';
export default {
    components: {
        ImgTip
    },

    props: {

    },

    watch: {

    },

    created() {

    },

    mounted() {

    },

    computed: {

    },

    data() {
        return {
            size: 'small',
            visible: false,
            formVisable: false,
            pointForm: {},
            defaultProps: {
                children: 'nextRecPoints',
                label: 'name'
            },
            rules: {
                price: [
                    {
                        required: true,
                        message: "请输入加价金额",
                        trigger: "blur",
                    },
                ],
            },
            pointTree: []
        };
    },

    methods: {
        show(item) {
            this.areaData = item;
            if (!item.recPoints) {
                this.pointTree = [];
            } else {
                this.pointTree = [...item.recPoints];
            }
            this.visible = true;
        },

        hide() {
            this.visible = false;
        },


        isWarnData(data) {
            return (!data.lat || !data.lng) && (!data.nextRecPoints || !data.nextRecPoints.length);
        },

        remove(node, data) {
            this.$confirm("确认删除吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                const parent = node.parent;
                const children = parent.data.nextRecPoints || parent.data;
                const index = children.findIndex(d => d.id === data.id);
                children.splice(index, 1);
            });
        },

        update(node, data) {
            this.currentNode = node;
            this.formVisable = true;
            this.pointForm = { ...data };
        },

        addPoint(data) {
            const newChild = { id: Toolkit.uuid(), areaName: this.areaData.name, name: '新推荐点' };
            if (data) {
                if (data.nextRecPoints) {
                    data.nextRecPoints.push(newChild);
                } else {
                    this.$set(data, 'nextRecPoints', []);
                }
            } else {
                this.pointTree.push(newChild);
            }
        },

        addImgTip() {
            const imgTips = this.pointForm.imgTips || [];
            imgTips.push({ imgList: [], tip: '' });
            this.$set(this.pointForm, 'imgTips', imgTips);
        },

        delImgTip(index) {
            const imgTips = this.pointForm.imgTips;
            imgTips.splice(index, 1);
            this.$set(this.pointForm, 'imgTips', imgTips);
        },

        saveRecPoint() {
            const data = this.pointForm;
            const parent = this.currentNode.parent;
            const children = parent.data.nextRecPoints || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1, data);
            this.formVisable = false;
        },

        showLocation() {
            const { lat, lng, name } = this.pointForm;
            const point = {
                lat,
                lng,
                name
            };
            this.$bus.$emit(SHOW_LOCATION_SELECT_MAP, point, ({ lat, lng }) => {
                this.$set(this.pointForm, 'lat', lat);
                this.$set(this.pointForm, 'lng', lng);
            });
        },

        locate(data) {
            this.$bus.$emit(SHOW_LOCATION_SELECT_MAP, data);
        },

        saveAll() {
            // 检查是否所有叶子节点都有经纬度
            const dataRight = Toolkit.findLeafNodes(this.pointTree, 'nextRecPoints').filter(d => !d.lat || !d.lng).length === 0;
            if (dataRight) {
                this.$emit('save', this.pointTree);
            } else {
                this.$message.error('请确保所有推荐点都有经纬度');
            }
        },
    },
};
</script>
<style scoped lang="less">
.box {
    padding: 20px;

    .item-box {
        margin-top: 20px;
        border: 1px solid #ebeef5;


    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
}


.btn-box {
    display: flex;
    justify-content: space-between;
}

.tip-box {
    display: flex;
    justify-content: space-between;

    .clear {
        margin-left: 20px;
        width: 60px;
    }

    .content {
        flex: 1;
    }
}
</style>
