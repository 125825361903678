<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="数据分析" name="ref1">
        <track-info></track-info>
      </el-tab-pane>
      <el-tab-pane label="数据明细" name="ref2">
        <track-detail></track-detail>
      </el-tab-pane>
      <el-tab-pane label="仅登录分析" name="ref3">
        <JustLogin />
      </el-tab-pane>
      <el-tab-pane label="查看订单分析" name="ref5">
        <ViewMyOrder />
      </el-tab-pane>
      <el-tab-pane label="资源配置" name="ref4">
        <ResourceConfig />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TrackInfo from "./TrackInfo";
import TrackDetail from "./TrackDetail";
import JustLogin from './JustLogin';
import ResourceConfig from './ResourceConfig';
import ViewMyOrder from './ViewMyOrder';

export default {
  name: "Track",
  components: {
    TrackDetail,
    TrackInfo,
    JustLogin,
    ResourceConfig,
    ViewMyOrder
  },
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {
    query () {


    }
  }
};
</script>

<style scoped>
</style>