export default class UnitUtil {
  static timeFilter (val = 0) {
    let theTime = parseInt(val) + 60;// 需要转换的时间秒
    let theTime1 = 0;// 分
    let theTime2 = 0;// 小时
    let theTime3 = 0;// 天
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
        if (theTime2 > 24) {
          // 大于24小时
          theTime3 = parseInt(theTime2 / 24);
          theTime2 = parseInt(theTime2 % 24);
        }
      }
    }
    let result = '';
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1) + '分钟' ;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2) + '小时' + result;
    }
    if (theTime3 > 0) {
      result = '' + parseInt(theTime3) + '天' + result;
    }
    return result;
  }


  static accurateTimeFilter (val = 0) {
    let theTime = parseInt(val);// 需要转换的时间秒
    let theTime1 = 0;// 分
    let theTime2 = 0;// 小时
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
      }
    }
    let result = `${theTime}秒`;
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1) + '分钟' + result;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2) + '小时' + result;
    }
    return result;
  }

  static mileFilter (val = 0) {
    if (val > 1000) {
      return `${parseFloat(val / 1000).toFixed(1)}公里`;
    }
    return `${val}米`;
  }
}
