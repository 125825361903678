<template>
    <div class="main">
        <div style="padding: 10px;">
            <div class="query-container" style="padding-bottom: 20px; display: flex; justify-content: space-between;">
                <div>
                    <el-select clearable v-model="param.violateClass1" placeholder="违规大类" @change="(val)=>onViolateClassify1Change(val, false)"
                        style="width: 200px; margin-right: 20px;">
                        <el-option v-for="item in violate1Opts" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select clearable filterable v-model="param.violateClass2" placeholder="违规小类" @change="query"
                        style="width: 200px;margin-right: 20px;">
                        <el-option v-for="item in filterClassify2Opts" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" @click="query">查询</el-button>
                </div>

                <div>
                    <el-button @click="initData">初始化数据</el-button>
                    <el-button @click="openEdit()">新增</el-button>
                </div>
            </div>
            <el-table :data="relationList" stripe max-height="700" style="width: 100%">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="违规大类" prop="violateClass1" width="100">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.violateClass1 | enumTransfer(violate1Opts)
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="违规小类" prop="violateClass2" width="200">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.violateClass2 | enumTransfer(violate2Opts)
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="小类编号" prop="violateClass2" width="100"></el-table-column>

                <!-- <el-table-column label="违规说明" prop="violateMark" width="100"></el-table-column> -->
                <el-table-column label="违规简介" prop="violateDesc" width="100"></el-table-column>

                <el-table-column label="对应题库" prop="examCode">
                    <template slot-scope="scope">
                        <span v-for="item in scope.row.questionCodes" :key="item" style="margin-right: 4px;">
                            <el-link type="primary" @click="openExams(item)">{{ item }}</el-link>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="对应文档" prop="examCode">
                    <template slot-scope="scope">
                        <span v-for="item in scope.row.docCodes" :key="item" style="margin-right: 4px;">
                            <el-link type="primary" @click="openTrainingDocx(item)">{{ item }}</el-link>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="处罚类型" prop="punishText">
                    <template slot-scope="scope">
                        <el-checkbox-group :value="scope.row.defaultPunishes">
                            <el-checkbox :label="item" v-for="item in scope.row.punishOpts" :key="item"></el-checkbox>
                        </el-checkbox-group>
                    </template>
                </el-table-column>
                <el-table-column label="默认罚款金额" prop="punishAmount">
                    <template slot-scope="scope">
                        <span v-if="scope.row.punishAmount">{{ scope.row.punishAmount }}元</span> <span
                            v-if="scope.row.minPunishAmount && scope.row.maxPunishAmount">（ {{ scope.row.minPunishAmount }}
                            - {{ scope.row.maxPunishAmount }}）</span>
                    </template>
                </el-table-column>
                <el-table-column label="默认扣分" prop="punishScore"></el-table-column>
                <el-table-column label="是否累计" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.accumulate ? "是" : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="申诉题库" prop="appealExamRemark" width="100"></el-table-column>
                <el-table-column label="备注" prop="bz" width="100"></el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="openEdit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="delData(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </div>


        <el-dialog :visible.sync="showDocxPannel" width="1100px">
            <div v-if="doc">
                <div style="text-align: center; font-size: 18px; font-weight: bold; margin-bottom: 10px;">{{ doc.seq }}. {{
                    doc.title }}</div>
                <div style="line-height:1.6;" v-html="doc.content"></div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showExamPannel" width="628px">
            <div v-if="exam">
                <ItemView :paperItm="exam" :sno="1" :preview="true"></ItemView>
            </div>
        </el-dialog>


        <el-dialog :title="form.id ? '修改' : '新增'" :visible.sync="visible" width="900px" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="80px" size="small" :inline="true" :rules="rules"
                label-position="top">

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="违规大类：" prop="violateClass1">
                            <el-select clearable v-model="form.violateClass1" placeholder="违规大类" @change="(val)=>onViolateClassify1Change(val, true)">
                                <el-option v-for="item in violate1Opts" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="违规小类：" prop="violateClass2">
                            <el-select clearable filterable v-model="form.violateClass2" placeholder="违规小类">
                                <el-option v-for="item in formFilterClassify2Opts" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">

                        <el-form-item label="处罚类型：" prop="punishOpts">
                            <el-checkbox-group v-model="form.punishOpts">
                                <el-checkbox :label="item" v-for="item in punishOpts" :key="item"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="默认处罚类型：" prop="defaultPunishes">
                            <el-checkbox-group v-model="form.defaultPunishes">
                                <el-checkbox :label="item" v-for="item in form.punishOpts" :key="item"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="默认罚款金额：" style="margin-right: 30px;">
                            <el-input-number :disabled="!form.punishOpts.includes('罚款')" v-model="form.punishAmount"
                                placeholder=""></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="罚款区间：">
                            <el-input-number :disabled="!form.punishOpts.includes('罚款')" v-model="form.minPunishAmount"
                                placeholder="" style="margin-right:10px"></el-input-number>
                            <el-input-number :disabled="!form.punishOpts.includes('罚款')" v-model="form.maxPunishAmount"
                                placeholder=""></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="默认扣分：">
                            <el-input-number :disabled="!form.punishOpts.includes('扣分')" v-model="form.punishScore"
                                placeholder=""></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="对应题库：" prop="questionCodes">
                            <el-input v-model="form.questionCodes" placeholder="对应题库"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="对应文档：" prop="docCodes">
                            <el-input v-model="form.docCodes" placeholder="对应文档"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="是否累计：" prop="accumulate">
                            <el-select clearable v-model="form.accumulate" placeholder="是否累计">
                                <el-option label="是" :value="true"> </el-option>
                                <el-option label="否" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-form-item label="违规简介：" style="width:100%">
                        <el-input type="textarea" :rows="2" v-model="form.violateDesc" placeholder="违规简介"></el-input>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-form-item label="申诉题库：" style="width:100%">
                        <el-input type="textarea" :rows="1" v-model="form.appealExamRemark" placeholder="申诉题库"></el-input>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-form-item label="备注：" style="width:100%">
                        <el-input type="textarea" :rows="1" v-model="form.bz" placeholder="备注"></el-input>
                    </el-form-item>
                </el-row>


            </el-form>

            <span slot="footer">
                <el-button size="small" @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="saveData" size="small">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { initPunishRelationList, getPunishRelationList, getExamItems, getDoc, saveRelation, delRelation } from "@/api";
import ItemView from '../../../../pages/Exam/admin/editor/ItemView';
import { Toolkit } from '@/util';
import _ from 'lodash';
export default {


  components: {
    ItemView
  },


  computed: {
    ...mapState({
      size: state => state.btnSize,
      violate1Opts: state => state.enumMap['DRIVER_VIOLATE_TYPE'],
      violate2Opts: state => state.enumMap['DRIVER_VIOLATE_OPT_TYPE'],
    }),
  },

  data () {
    return {
      visible: false,
      showDocxPannel: false,
      showExamPannel: false,
      form: {
        punishOpts: [],
        defaultPunishes: []
      },
      filterClassify2Opts: [],
      formFilterClassify2Opts: [],
      param: {},
      relationList: [],
      punishOpts: ['警告', '扣分', '罚款', '解约'],
      doc: null,
      exam: null,
      rules: {
        violateClass1: [
          { required: true, message: '违规大类不能为空', trigger: 'blur' },
        ],
        violateClass2: [
          { required: true, message: '违规小类不能为空', trigger: 'blur' },
        ],
        punishOpts: [
          { required: true, message: '处罚类型不能为空', trigger: 'blur' },
        ],

        questionCodes: [
          { required: true, message: '对应题库不能为空', trigger: 'blur' },
        ],
        docCodes: [
          { required: true, message: '对应文档不能为空', trigger: 'blur' },
        ],
        accumulate: [
          { required: true, message: '是否累计不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  mounted () {
    this.query();
  },
  methods: {



    initData () {
      initPunishRelationList().then(() => {
        this.$message.success("初始化成功");
      });
    },


    query () {
      getPunishRelationList(Toolkit.filterEmpty(this.param)).then(res => {
        this.relationList = res.data || [];
      });
    },

    onViolateClassify1Change (val, isForm) {
      if (isForm) {
        this.formFilterClassify2Opts = [];
        this.$set(this.form, 'violateClass2', null);
        const list = [];
        this.violate2Opts.forEach(opt => {
          if (String(opt.value).indexOf(val + "-") == 0) {
            list.push(opt);
          }
        });
        this.formFilterClassify2Opts = list;
      } else {
        this.filterClassify2Opts = [];
        const list = [];
        this.$set(this.param, 'violateClass2', null);
        this.violate2Opts.forEach(opt => {
          if (String(opt.value).indexOf(val + "-") == 0) {
            list.push(opt);
          }
        });
        this.filterClassify2Opts = list;
        this.query();
      }
    },

    openEdit (item) {
      if (item) {
        const form = _.cloneDeep(item);
        const { questionCodes = [], docCodes = [] } = form;
        if (!form.punishOpts) {
          form.punishOpts = [];
          form.defaultPunishes = [];
        }
        form.questionCodes = questionCodes.join(',');
        form.docCodes = docCodes.join(',');

        // 过滤出违规小类
        if (form.violateClass1) {
          const list = [];
          this.violate2Opts.forEach(opt => {
            if (String(opt.value).indexOf(form.violateClass1 + "-") == 0) {
              list.push(opt);
            }
          });
          this.formFilterClassify2Opts = list;
        }
        this.form = form;
        this.visible = true;
      } else {
        this.form = {
          punishOpts: [],
          defaultPunishes: []
        };
        this.visible = true;
      }
    },

    openTrainingDocx (code) {
      this.showDocxPannel = true;
      this.doc = null;
      getDoc(code).then(res => {
        this.doc = res.data;
      });
    },

    openExams (code) {
      this.showExamPannel = true;
      this.exam = null;
      getExamItems(code).then(res => {
        this.exam = res.data;
      });
    },

    saveData () {
      this.$refs.form.validate((valid) => {

        if (valid) {
          const param = { ...this.form };
          if (param.questionCodes) {
            param.questionCodes = param.questionCodes.split(',');
          }
          if (param.docCodes) {
            param.docCodes = param.docCodes.split(',');
          }

          saveRelation(param).then(() => {
            this.$message.success("保存成功");
            this.visible = false;
            this.query();
          });
        }
      });
    },

    delData (item) {
      this.$confirm('确定删除该条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRelation(item).then(() => {
          this.$message.success("删除成功");
          this.query();
        });
      }).catch(() => {
      });
    },



  }
};
</script>

<style scoped>
.label {
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.row {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>