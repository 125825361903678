import Vue from 'vue';
import VueRouter from 'vue-router';
import routeConfig from './routes-config';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: routeConfig
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});


export default router;
