import { Http } from "@/util";

// 查询订单交易明细
export function orderTransactionDetail(orderId) {
  return Http.get("/admin/transaction/order", { orderId });
}

// 实时查询订单交易详情
export function getTransactionDetail(item) {
  return Http.post("/admin/transaction/detail", item);
}

// 手动退款
export function manualRefund(item) {
  return Http.post("/admin/transaction/manual/refund", item);
}

// 重试退款
export function retryRefund(item) {
  return Http.post("/admin/transaction/retry/refund", item);
}

// 查询流水
export function queryTransactionList(param) {
  return Http.post("/admin/transaction/query", param);
}

// 查询失败订单记录
export function queryFailOrderList(param) {
  return Http.post("/admin/transaction/fail/order/list", param);
}

// 处理失败订单记录
export function dealFailOrder(id) {
  return Http.get("/admin/transaction/deal/fail/order", { id });
}
