<template>
<div class="da-root">
  <div class="row">
    <div class="item" style="width: 220px">司管名称：{{ driverAdmin.name }}</div>
    <div class="item" style="width: 280px">开通时间：{{ driverAdmin.ctime }}</div>
    <div class="item" style="width: 280px">最后登录时间：{{ driverAdmin.lastLoginTime }}</div>
    <div class="item">
      头像：<el-link type="primary" @click.native="showPhoto(driverAdmin.avatar)"
    >{{driverAdmin.avatar ? '（已上传）' : '（无）'}}</el-link>
    </div>
  </div>

  <div class="row">
    <div class="item" style="width: 220px">绑定号码：{{ driverAdmin.telephone }}</div>
    <div class="item" style="width: 280px">
      账户状态：{{ driverAdmin.status == 1 ? "正常" : "禁用"}}
        <i class="el-icon-edit"
            style="cursor: pointer"
            @click="stopStatus(driverAdmin)"
            v-if="driverAdmin.status == 1"
        ></i>
    </div>
  </div>

  <div class="row">
    <div class="item">账户权限：{{ transeferIdNames(driverAdmin.svcScopes) }}</div>
  </div>

  <el-dialog title="预览" :visible.sync="showPhotoDialog">
    <el-image style="width: 500px; height: 500px" :src="photoUrl" fit="contain"></el-image>
  </el-dialog>
</div>
</template>

<script>
import { stopAdminRelation } from '@/api/partner_admin';
export default {
    name: "adminAccount",
    props: {
        driverAdmin: {
            type: Object,
        },
    },
    data() {
        return {
            serviceScopeObjs: [],
            showPhotoDialog: false,
            photoUrl: "",
        };
    },
    mounted() {
      this.serviceScopeObjs = window.serviceScopeObjs;
      this.initPage();
    },
    methods: {
        initPage() {

        },
        transeferIdNames(svcScopes) {
            if (!svcScopes || !svcScopes.length) {
                return "（未设置）";
            }
            return svcScopes
                .map((ss) => {
                    for (var i = 0; i < this.serviceScopeObjs.length; i++) {
                        if (this.serviceScopeObjs[i].value == ss) {
                            return this.serviceScopeObjs[i].label;
                        }
                    }
                    return "";
                })
                .join();
        },

        stopStatus(admin) {
            let params = {id: admin.id, status: 0};
            this.$confirm("确定要禁用吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                stopAdminRelation(params).then(
                    () => {
                        admin.status = 0;
                        this.$message.success("禁用成功。");
                    }
                );
            });
        },
        showPhoto(url) {
            this.photoUrl = url;
            this.showPhotoDialog = true;
        },
    }
};
</script>

<style lang="less">
.da-root {
  .el-dialog {
    width: 550px !important;
    height: auto !important;
  }

  .row {
    margin-bottom: 10px;

    .item {
      display: inline-block;
      margin-right: 20px;
    }
  }
}
</style>