import Vue from 'vue';
Vue.directive('drag', {

  bind: function (el) {

    el.onmousedown = function (ev) {
      const disX = ev.clientX - el.offsetLeft;
      const disY = ev.clientY - el.offsetTop;

      document.onmousemove = function (ev) {
        let l = ev.clientX - disX;
        var t = ev.clientY - disY;

        if (l <= 0) {
          l = 0;
        } else if (l >= document.documentElement.clientWidth - el.offsetWidth) {
          l = document.documentElement.clientWidth - el.offsetWidth;
        }

        if (t <= 0) {
          t = 0;
        } else if (t >= document.documentElement.clientHeight - el.offsetHeight) {
          t = document.documentElement.clientHeight - el.offsetHeight;

        }

        el.style.left = l + 'px';
        el.style.top = t + 'px';
      };

    };

    el.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
    };
  }

});