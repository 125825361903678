<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" :model="param" inline>
        <el-row>
          <el-col :span="22">
            <el-form-item label="时间">
              <el-date-picker
                style="width: 160px"
                value-format="yyyy-MM-dd 00:00:00"
                v-model="param.startTime"
                type="date"
                placeholder="选择开始日期"
                clearable
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-date-picker
                style="width: 160px"
                value-format="yyyy-MM-dd 23:59:59"
                v-model="param.endTime"
                type="date"
                placeholder="选择结束日期"
                clearable
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="手机号码" prop="telephone">
              <el-input
                v-model="param.telephone"
                clearable
                style="width: 160px"
              />
            </el-form-item>
          </el-col>

          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                :size="size"
                style="width: 80px"
                @click="query()"
                >搜索</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-table
        :data="list"
        size="small"
        stripe
        max-height="650"
        @sort-change="handleSort"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          prop="telephone"
          width="140"
          label="手机号"
        ></el-table-column>
        <el-table-column prop="recommendCode" label="推荐码"></el-table-column>

        <el-table-column
          prop="useTotal"
          label="总用券次数"
          width="120"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="useCnt"
          label="连续用券次数"
          sortable="custom"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="orderTotal"
          label="成交次数"
          width="120"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="recommendOrderTotal"
          sortable="custom"
          label="推荐用户成交次数"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="totalInvite"
          sortable="custom"
          label="总共邀请人数"
          width="140"
        ></el-table-column>

        <el-table-column
          prop="inviteDealIds"
          sortable="custom"
          label="邀请成交用户数"
          width="140"
        >
          <template slot-scope="scope">{{
            scope.row.inviteDealIds ? scope.row.inviteDealIds.length : 0
          }}</template>
        </el-table-column>

        <el-table-column prop="rate" label="邀请成交比">
          <template slot-scope="scope">{{ scope.row | inviteRate }}</template>
        </el-table-column>

        <el-table-column
          prop="inviteLogoutTimes"
          sortable="custom"
          label="邀请注销人数"
          width="140"
        ></el-table-column>

        <el-table-column
          prop="updtime"
          label="创建/更新时间"
          sortable="custom"
          width="160"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.ctime }}</div>
            <div>{{ scope.row.updtime }}</div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="updtime"
          label="最后更新时间"
          sortable="custom"
          width="160"
        ></el-table-column> -->

        <el-table-column prop="isRisk" label="是否风险用户" width="120">
          <template slot-scope="scope"
            ><span :class="{ red: scope.row.isRisk }">{{
              scope.row.isRisk ? "是" : "否"
            }}</span></template
          >
        </el-table-column>

        <el-table-column prop="remark" label="备注"></el-table-column>

        <el-table-column
          prop="id"
          label="操作"
          align="right"
          width="200"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="go2User(scope.row.telephone)"
              >用户</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              size="small"
              @click="go2OrderList(scope.row.telephone)"
              >订单</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              size="small"
              @click="showInviteList(scope.row.id)"
              >邀请记录</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" size="small" @click="flagUser(scope.row)">{{
              scope.row.isRisk ? "解封" : "拉黑"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          @size-change="handleSizeChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <el-drawer
        title="邀请详情："
        size="1400px"
        :visible.sync="visible"
        :append-to-body="true"
        direction="ltr"
      >
        <div class="drawer-box">
          <div>
            <el-table :data="inviteList" stripe max-height="700" size="small">
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column
                prop="invitePassengerTel"
                label="被邀请人电话"
              ></el-table-column>

              <el-table-column
                prop="createTime"
                label="邀请时间"
              ></el-table-column>

              <el-table-column prop="createTime" label="邀请渠道">
                <template slot-scope="scope">{{
                  scope.row.appRegisterTime ? "APP" : "小程序"
                }}</template>
              </el-table-column>

              <el-table-column prop="isInvalid" label="是否有效邀请">
                <template slot-scope="scope">{{
                  scope.row.isInvalid ? "否" : "是"
                }}</template>
              </el-table-column>

              <el-table-column prop="expiredTime" label="兑换过期时间" />

              <el-table-column prop="wxExchanged" label="是否已兑换">
                <template slot-scope="scope">{{
                  scope.row.wxExchanged || scope.row.appExchanged ? "是" : "否"
                }}</template>
              </el-table-column>

              <el-table-column prop="lastOrderTime" label="最后下单时间" />

              <el-table-column prop="lastDealTime" label="最后成交时间" />

              <el-table-column label="操作" type="id">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="go2User(scope.row.invitePassengerTel)"
                    >用户</el-button
                  >
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    size="small"
                    @click="go2OrderList(scope.row.invitePassengerTel)"
                    >订单</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {getCouponInfoList, getInviteList, flagPassengerCouponInfo} from '@/api';
import {Toolkit, ParamUtil} from '@/util';
import { mapState } from 'vuex';

export default {
  components: {},
  filters: {
    inviteRate (item) {
      if (item.inviteDealIds && item.totalInvite) {
        const val = item.inviteDealIds.length / item.totalInvite * 100;
        return val.toFixed(0) + '%';
      }
      return 0;
    }
  },
  props: {},
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
    })
  },
  data () {
    return {
      param: {},
      total: 0,
      currentPage: 1,
      asc: false,
      column: '',
      list: [],
      pageSize: 10,
      inviteList: [],
      visible: false
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize
        },
        param: ParamUtil.filterEmptyData(this.param),
        startTime: this.param.startTime,
        endTime: this.param.endTime,
      };
      if (this.column) {
        param.page.orders = [{
          column: this.column,
          asc: this.asc,
        }];
      }
      getCouponInfoList(param).then(res=>{
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleSort (item) {
      if (item.order) {
        this.asc = item.order.indexOf('asc') > -1;
        this.column = Toolkit.toLine(item.prop);
        this.query();
      } else {
        this.column = null;
      }

    },

    handleCurrentChange (page) {
      this.query(page);
    },

    handleSizeChange (pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.query(1);
    },

    go2OrderList (telephone) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: {tel: telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    go2User (telephone) {
      const routeUrl = this.$router.resolve({
        path: `/passenger/list`,
        query: {tel: telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    showInviteList (id) {
      this.visible = true;
      getInviteList(id).then(res=>{
        this.inviteList = res.data || [];
      });
    },

    flagUser (item) {
      const isRisk = !item.isRisk;
      this.$prompt(isRisk ? `确认标记为风险用户吗` : '确认解封吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(({ value }) => {
        const param = {
          id: item.id,
          isRisk,
          remark: value
        };
        flagPassengerCouponInfo(param).then(()=>{
          this.$message.success('操作成功');
          this.query();
        });
      }, ()=>{});
    }
  },
};
</script>
<style lang="less" scoped>
</style>