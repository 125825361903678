<template>
   <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="转化后数据" name="ref1">
        <FinalData />
      </el-tab-pane>
      <el-tab-pane label="Excel原始数据" name="ref2">
        <ExcelData />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ExcelData from './ExcelData.vue';
import FinalData from './FinalData.vue';

export default {
  components: {
    ExcelData,
    FinalData
  },


  data () {
    return {
      tab: 'ref1'
    };
  },

  methods: {


  }
};
</script>

<style scoped>

</style>