<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="地址类型：" prop="type">
          <el-select v-model="param.type" placeholder="请选择" style="width: 100%" @change="query">
            <el-option
              v-for="item in typeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域：" prop="pathId">
          <el-select
            v-model="param.areaId"
            placeholder="请选择"
            style="width: 100%"
            @change="query"
          >
            <el-option
              v-for="item in enableAreaOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item label="地址搜索">
          <input id="tipinput" type="text" />
        </el-form-item>

        <el-form-item label>
          <el-button type="success" icon="el-icon-document-copy" @click="add"
            >加入常用列表</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="up_price_map">
      <div id="up_price_map"></div>

      <div class="box">
        <el-table :data="list" stripe max-height="600" :row-class-name="tableRowClassName">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="区域名称"></el-table-column>
          <el-table-column prop="keyName" label="关键字"></el-table-column>

          <el-table-column v-if="param.type === 3" prop="subType" label="子区域类型">
            <template slot-scope="scope">
              <span>{{ scope.row.subType | enumTransfer(stationOpts) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="sortIndex" label="排序序号" width="80"></el-table-column>

          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openDiag(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="syncPosition(scope.$index)"
                >同步位置</el-button
              >
              <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="500px"
      max-height="600"
      title="修改"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="120px" :size="size" :rules="rules">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="form.name" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item v-if="param.type === 3" label="车站类型" prop="subType">
          <el-select v-model="form.subType" style="width: 100%">
            <el-option
              v-for="item in stationOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="关键字：" prop="keyName" v-if="param.type === 4 || param.type === 6">
          <el-input v-model="form.keyName" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="price">
          <el-input-number v-model="form.sortIndex" style="width: 100%"></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="save">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listArea,
  addRecommendAddress,
  modifyRecommendAddress,
  removeRecommendAddress,
  listRecommendAddress,
} from "@/api";
import { mapState } from "vuex";
import _ from "lodash";

const markY = require("../../../assets/image/mark_y.png");
export default {
  mounted() {
    this.initMap();
    this.queryArea();
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      typeOpts: (state) => state.enumMap["RECOMMEND_ADDRESS_TYPE"],
      stationOpts: (state) => state.enumMap["STATION_TYPE"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  data() {
    return {
      visible: false,
      form: {},
      param: {
        areaId: 1,
        type: 1,
      },
      enableAreaOpts: [],
      list: [],
      rules: {},
      selectMarkerIndex: -1,
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("up_price_map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "tipinput",
          city: "全国",
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.on("select", this.select);
      });
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex === this.selectMarkerIndex) {
        return "select-row";
      }
      return "";
    },

    queryArea() {
      // 只查询启用的区域
      listArea({}).then((res) => {
        const areas = res.data || [];
        this.enableAreaOpts = areas.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      });
    },

    add() {
      if (this.info && this.info.poi) {
        const { address, location, name } = this.info.poi;
        const param = {
          areaId: this.param.areaId,
          type: this.param.type,
          name: address + name,
          longitude: location.lng,
          latitude: location.lat,
        };
        addRecommendAddress(param).then(() => {
          this.query();
        });
      }
    },
    openDiag(record) {
      this.form = _.cloneDeep(record);
      this.visible = true;
    },

    select(info) {
      this.info = info;
      this.addMarker(info.poi.location, info.poi.name);
    },
    addMarker(position, title) {
      if (this.marker) {
        this.map.remove(this.marker);
      }

      const marker = new AMap.Marker({
        position,
        title,
        icon: new AMap.Icon({
          image: markY,
        }),
        draggable: true,
        label: {
          //修改label相对于maker的位置
          direction: "top",
          content: "<div class='info active'>" + title + "</div>",
        },
      });

      this.marker = marker;
      this.map.add(marker);
      this.map.setFitView();
    },
    query() {
      listRecommendAddress({ ...this.param }).then((res) => {
        this.list = res.data || [];
        this.renderMarkers();
      });
    },

    syncPosition(index) {
      const marker = this.markers[index];
      const data = marker.getExtData();
      const position = marker.getPosition();
      data.latitude = position.lat;
      data.longitude = position.lng;
      modifyRecommendAddress(data).then(() => {
        this.query();
      });
    },

    renderMarkers() {
      const markers = [];
      const map = this.map;
      map.clearMap();
      for (let index = 0; index < this.list.length; index++) {
        const item = this.list[index];
        const position = [item.longitude, item.latitude];
        const marker = new AMap.Marker({
          label: {
            //修改label相对于maker的位置
            direction: "top",
            content: "<div class='info '>" + item.name + "</div>",
          },
          position,
          draggable: true,
          extData: item,
        });
        marker.on("click", () => {
          this.selectMarkerIndex = index;
        });
        markers.push(marker);
      }

      this.markers = markers;
      map.add(markers);
      map.setFitView();
    },
    del(record) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeRecommendAddress(record).then(() => {
          this.query();
        });
      });
    },
    save() {
      modifyRecommendAddress({ ...this.form }).then(() => {
        this.visible = false;
        this.query();
      });
    },
  },
};
</script>

<style scoped lang="less">
#up_price_map {
  height: 100%;
  width: 60%;
}

.up_price_map {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;
  display: flex;

  .box {
    flex: 1;
    margin-left: 10px;
  }
}

#tipinput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}

.query-container {
  height: 40px;
  padding: 10px 20px;
}

/deep/ .el-table .select-row {
  background: #f0f9eb !important;
  td {
    background: #f0f9eb !important;
  }
}
</style>
