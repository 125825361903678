<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">请假/销假详情</el-menu-item>
      <el-menu-item index="2">请假/销假统计</el-menu-item>
      <el-menu-item index="3">节假日设定</el-menu-item>
    </el-menu>
    <el-select v-model="team" placeholder="所有车队" class="team" :clearable="true">
      <el-option
        v-for="item in teams"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      ></el-option>
    </el-select>
    <LeaveInfo v-if="activeIndex == '1'" :team="team"></LeaveInfo>
    <LeaveSum v-if="activeIndex == '2'" :team="team"></LeaveSum>
    <CalendarSetting v-if="activeIndex == '3'"/>
  </div>
</template>

<script>
import LeaveInfo from "./LeaveInfoStat";
import LeaveSum from "./LeaveSumStat";
import CalendarSetting from './CalendarSetting';
export default {
  name: "LeaveStat",
  components: {LeaveInfo, LeaveSum, CalendarSetting},
  data () {
    return {
      activeIndex: "1",
      teams: [],
      team: null
    };
  },
  created () {
    this.initPage();
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key;
    },
    initPage () {
      this.$http.post("/admin/driver/leave/init", {}).then(res=>{
        this.teams = res.data || [];
      });
    }
  }
};
</script>

<style scoped>
.team {
  position: fixed;
  right: 8px;
  top: 64px;
}
</style>