<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="标签">
          <el-input
            style="width: 200px"
            v-model="param.label"
            @change="queryGroup()"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="queryGroup()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="filterUser()">筛选用户</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="groupList" stripe max-height="600">
        <el-table-column label="序号" type="index" show-overflow-tooltip></el-table-column>

        <el-table-column prop="label" label="标签"></el-table-column>

        <el-table-column prop="totalNum" label="目标人数">
          <template slot-scope="scope">
            <div>{{ scope.row.totalNum }}人</div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showUser(scope.row)">查看用户</el-button>

            <el-button type="text" size="small" @click="filterUser(scope.row)"
              >展示筛选条件</el-button
            >
            <el-button type="text" size="small" @click="delGroup(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="筛选用户"
      :visible.sync="drawerVisible"
      direction="ltr"
      :with-header="false"
      :size="1000"
    >
      <div class="box">
        <div class="header">
          <div class="title">筛选对象</div>

          <el-radio-group v-model="userType" size="small">
            <el-radio-button label="1">线上用户</el-radio-button>
            <el-radio-button label="2">线下用户</el-radio-button>
          </el-radio-group>
        </div>

        <el-divider></el-divider>

        <div v-if="userType === '1'">
          <Online ref="online" />
        </div>
        <div v-else>
          <Offline ref="offline" />
        </div>

        <div class="btn">
          <el-button type="primary" size="small" @click="queryUser">查询预计发送人数</el-button>
        </div>
      </div>

      <div class="box">
        <ResultView :dataList="userList" :onlyTel="false" />
        <div class="btn">
          <el-button type="primary" size="small" @click="saveGroup"> 保存查询分组</el-button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      title="筛选用户结果"
      :visible.sync="drawerVisible2"
      direction="rtl"
      :with-header="false"
      :append-to-body="true"
      :size="1000"
    >
      <div class="box">
        <ResultView :dataList="userList" :onlyTel="false" />
        <div class="btn">
          <el-button type="primary" size="small" @click="saveGroup"> 保存查询分组</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Online from "./Online.vue";
import Offline from "./Offline.vue";
import ResultView from "./ResultView.vue";

import { predictSmsSendUser, saveUserGroup, getUserGroup, delUserGroup, getPlanUsers } from "@/api";

export default {
  components: {
    Online,
    Offline,
    ResultView,
  },
  props: {},
  computed: {
    ...mapState({
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  created() {
    this.queryGroup();
  },
  mounted() {},
  watch: {},
  data() {
    return {
      drawerVisible: false,
      drawerVisible2: false,
      userType: "1",
      userList: null,
      groupList: [],
      param: {},
    };
  },
  methods: {
    queryUser() {
      const param =
        this.userType === "1" ? this.$refs.online.getParam() : this.$refs.offline.getParam();
      predictSmsSendUser(param).then((res) => {
        this.userList = res.data || [];
        // this.drawerVisible2 = true;
      });
    },

    queryGroup() {
      getUserGroup({}).then((res) => {
        this.groupList = res.data || [];
      });
    },

    saveGroup() {
      this.$prompt(`请输入分组标签`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{2,}/,
        inputErrorMessage: `请输入分组标签`,
        closeOnClickModal: false,
      }).then(
        ({ value }) => {
          let param =
            this.userType === "1" ? this.$refs.online.getParam() : this.$refs.offline.getParam();
          param.label = value;
          if (param.filter2.userType !== "unreg") {
            param.userIds = this.userList.map((t) => t.id);
          } else {
            param.userTels = this.userList;
          }
          saveUserGroup(param).then(() => {
            this.$message.success("操作成功");
            this.queryGroup();
          });
        },
        () => {}
      );
    },

    delGroup(item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUserGroup(item).then(() => {
          this.$message.success("删除成功");
          this.queryGroup();
        });
      });
    },

    filterUser(item) {
      this.drawerVisible = true;
      if (item) {
        const param = JSON.parse(item.filter);
        this.userType = param.isOnline ? "1" : "2";
        setTimeout(() => {
          if (param.isOnline) {
            this.$refs.online.setParam(param.filter1, param.filter2);
          } else {
            this.$refs.offline.setParam(param.filter2);
          }
        }, 0);
      } else {
        this.userType = "1";
        setTimeout(() => {
          this.$refs.online.initData();
        }, 0);
      }
    },

    showUser(item) {
      if (item.userIds) {
        getPlanUsers({ userIds: item.userIds }).then((res) => {
          this.userList = res.data || [];
        });
      } else {
        this.userList = item.userTels.map((item) => {
          return {
            telephone: item,
          };
        });
      }
      this.drawerVisible2 = true;
    },
  },
};
</script>
//
<style lang="less" scoped>
.box {
  padding: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: bold;
      margin-right: 50px;
    }
  }

  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
