<template>
  <div class="row">
    <div class="time">{{ time }}</div>

    <div class="res-box">
      <div v-for="item in showList" :key="item.id" class="img-box">
        <div v-if="item.url" class="item">
          <el-image
            v-if="isImage('.' + item.fileType)"
            :src="item.url"
            lazy
            :preview-src-list="imgList"
          ></el-image>

          <video
            v-if="isVedio('.' + item.fileType)"
            :src="item.url"
            class="video-box"
            controls
            autoplay
          ></video>
        </div>
        <div v-else class="item">未获取到图片:{{ item.url }}</div>

        <div class="desc">
          {{ item.channel | enumTransfer(channelOpts) }}
          <span v-if="item.routeStatus"
            >- {{ item.routeStatus | enumTransfer(routeDetailOpts) }}</span
          >
          <span
            ><el-button @click="showImgEditor(item.url, item.id)" type="text">
              {{ rcMap[`VIDEO_IMG_${item.id}`] ? "已标记" : "标记" }}</el-button
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toolkit, DateUtil, OssUtil, FileUtil } from "@/util";
import _ from "lodash";
import { mapState } from "vuex";
import { saveQcResource } from "@/api";
import { SHOW_EDIT_IMG_DIAG } from "@/core/const/global-event";

export default {
  components: {},

  props: {
    time: {
      type: String,
      default: "",
    },

    list: {
      type: Array,
      default: () => [],
    },

    showAllChannel: Boolean,

    route: {
      type: Object,
      default: () => ({}),
    },

    rcMap: {
      type: Object,
      default: () => ({}),
    },

    updateRcMap: Function,
  },

  watch: {
    list: {
      handler() {
        this.dealData();
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
    }),
  },

  data() {
    return {
      channelOpts: [
        { label: "车外", value: 1 },
        { label: "副驾", value: 2 },
        { label: "车内", value: 3 },
        { label: "主驾", value: 4 },
      ],
      showList: [],
      imgList: [],
    };
  },

  methods: {
    isImage(src) {
      return FileUtil.isImage(src);
    },

    isVedio(src) {
      return FileUtil.isVedio(src);
    },

    isAudio(src) {
      return FileUtil.isAudio(src);
    },

    dealData() {
      // 按照channel分组,不存在channel的是报警图片
      const channelMap = _.groupBy(this.list, (item) => item.channel || 2);
      const result = [];

      for (let i = 1; i <= 4; i++) {
        if (channelMap[i]) {
          result.push(...channelMap[i]);
        } else if (this.showAllChannel) {
          result.push({ id: Toolkit.uuid() });
        }
      }

      this.showList = result;
      this.imgList = result.filter((item) => item.url).map((item) => item.url);
    },

    showImgEditor(url, oriResourceId) {
      const warterMark = "仅限内部使用  " + this.route.driverNo;
      this.$bus.$emit(SHOW_EDIT_IMG_DIAG, url, warterMark, (file) => {
        this.saveImgResource(file, oriResourceId, url);
      });
    },

    async saveImgResource(file, oriResourceId, oriUrl) {
      const ossClient = await OssUtil.getOssClient("yxmgt-private");
      const now = DateUtil.format(new Date(), "yyyyMMddhhmmss");
      const fileType = "jpg";
      const { id: routeId, driverId } = this.route;
      const fileName = `qc_img_${driverId}_${now}` + "." + fileType;
      const fullName = `qc/route_imgs/${fileName}`;

      ossClient
        .put(fullName, file)
        .then((res) => {
          const param = {
            fileType,
            url: res.url,
            name: fileName,
            driverId,
            bizType: "VIDEO_IMG",
            routeId,
            oriResourceId,
            oriUrl,
          };

          saveQcResource(param).then((res) => {
            if (res.data) {
              this.updateRcMap(`VIDEO_IMG_${oriResourceId}`, res.data);
            }
            this.$message.success("保存成功");
          });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },
  },
};
</script>
<style scoped lang="less">
.row {
  display: flex;
  align-items: center;

  .time {
    margin-right: 40px;
    font-size: 14px;
  }

  .res-box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .img-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        width: 200px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }

      .video-box {
        width: 200px;
        height: 150px;
      }

      .desc {
        font-size: 12px;
      }
    }
  }
}
</style>
