export class MenuDataService {

  // 生成菜单
  static genMenu (data = [], result = []) {

    data.forEach(item=>{

      const menu = {
        name: item.text,
        index: item.url || item.id,
        icon: item.icon,
        key: item.id,
        type: item.type,
        children: [],
      };

      result.push(menu);

      if (item.children) {
        MenuDataService.genMenu(item.children, menu.children);
      }

    });

    return result;
  }
}
