<template>
  <div>
    <el-row class="query">
      <el-select v-model="queryObj.teamId" placeholder="选择车队">
        <el-option
          v-for="team in teams"
          :key="team.id"
          :label="team.name"
          :value="team.id"
        >
        </el-option>
      </el-select>
      &nbsp;
      <el-select v-model="queryObj.fileId" placeholder="所有文档">
        <el-option
          v-for="file in files"
          :key="file.id"
          :label="file.name"
          :value="file.id"
        >
        </el-option>
      </el-select>
      &nbsp;
      <el-button @click.native="query">查询</el-button>
    </el-row>
    <el-table :data="learns" style="width: 100%">
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column sortable prop="no" label="班号" width="180">
      </el-table-column>
      <el-table-column sortable prop="uptime" label="最后阅读时间">
      </el-table-column>
      <el-table-column sortable prop="lRate" label="阅读进度">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "FileStat",
  data () {
    return {
      queryObj: {fileId: "", teamId: ""},
      files: [],
      teams: [],
      learns: []
    };
  },
  mounted () {
    this.initPage();
  },
  methods: {
    initPage () {
      this.$http.post("/admin/train/stat/initfile", {}).then(res=>{
        this.files = res.data.files || [];
        this.files.splice(0, 0, {"id": "", "name": "所有文档"});
        this.teams = res.data.teams || [];
        this.queryObj.teamId = this.teams[0].id;
      });
    },
    query () {
      let url = "/admin/train/stat/file";
      this.$http.post(url, this.queryObj).then(res => {
        this.learns = res.data || [];
      });
    }
  }
};
</script>

<style scoped>
.query {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: right;
}
</style>