<template>
  <div class="menu" :class="{ collapse: isCollapse }">
    <div class="logo" @click="goIndex">
      <img src="../assets/image/logo.png" />
      <div class="text">
        <!--                <span>在线运营平台</span>-->
      </div>
    </div>

    <el-menu :default-active="active" :collapse="isCollapse" router ref="menu" :unique-opened="true">
      <el-submenu :index="`${item.index}`" v-for="item in menus" :key="item.index">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </template>

        <template v-for="sub in item.children">
          <el-menu-item v-if="sub.type === '2'" :index="`${sub.index}`" :key="sub.index">{{ sub.name }}</el-menu-item>

          <el-submenu v-if="sub.type === '1'" :index="`${sub.index}`" :key="sub.index">
            <template slot="title">
              <span>{{ sub.name }}</span>
            </template>
            <template v-for="sub2 in sub.children">
              <el-menu-item v-if="sub2.type === '2'" :index="`${sub2.index}`" :key="sub2.index">{{ sub2.name
              }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
      </el-submenu>
    </el-menu>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { MenuDataService } from '@/service';
import { PERMISSION } from "@/core/const/global-const";
import { LocalStorage } from '@/util';

export default {
  name: "Navigation",
  created () {
    // 首次进入的时候根据路由选中菜单
    this.active = this.$route.path;
    const permissions = LocalStorage.get(PERMISSION);
    this.menus = MenuDataService.genMenu(permissions);
  },
  computed: {
    ...mapState({
      isCollapse: state => state.menuCollapse,
    }),
  },
  watch: {
    $route (to) {
      const { path } = to;
      this.active = path;
    }
  },
  data () {
    return {
      active: '',
      menus: [],
    };
  },
  methods: {
    goIndex () {
      this.$router.push({
        path: '/index'
      });
    }
  }
};
</script>

<style lang="less" type="text/less" scoped>
.menu {
  height: 100%;
  width: 100%;
  box-shadow: 2px 1px 3px #e2e1e1;
  border-right: solid 1px #e6e6e6;
  display: flex;
  flex-direction: column;

  .logo {
    background-color: #438adf;
    height: 50px;
    text-align: center;
    color: white;
    display: flex;

    .text {
      font-size: 14px;
      position: relative;

      span {
        position: absolute;
        bottom: 5px;
        width: 150px;
        left: 20px;
        text-align: left;
      }
    }

    img {
      margin-left: 10px;
      width: 50px;
      height: 50px;
    }
  }

  &.collapse {
    width: 64px;
  }

  .el-menu {
    border-right: none;
    overflow: auto;
    flex: 1;
  }

  .el-menu-item {
    margin-left: 10px;
  }
}
</style>
