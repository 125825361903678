<template>
  <div class="root-box">
    <div class="item">
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param.startDate"
        style="width: 200px"
        type="date"
        placeholder="选择日期"
      ></el-date-picker
      >至
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="param.endDate"
        style="width: 200px"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>
      <el-select v-model="param.rsIds" style="margin: 0 8px" clearable multiple>
        <el-option
          v-for="item in analysisOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="query">确定</el-button>
    </div>
    <div style="margin-top: 16px">
      <chart ref="chart" :option="chartOpt" />
    </div>
    <!-- 用户每天点击了哪些按钮 -->
    <div class="item" style="margin-top: 48px">
      <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="param2.startTime"
        style="width: 200px"
        type="datetime"
        placeholder="选择日期"
      ></el-date-picker
      >至
      <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="param2.endTime"
        style="width: 200px"
        type="datetime"
        placeholder="选择日期"
      ></el-date-picker>
      <el-input
        v-model="param2.mobile"
        placeholder="乘客手机号"
        style="margin: 0 8px; display: inline-block; width: 200px"
      />
      <el-button type="primary" @click="query2">确定</el-button>
    </div>
    <div style="margin-top: 16px">
      <OptDetail ref="detail" />
    </div>
  </div>
</template>

<script>
import {DateUtil} from '@/util';
import {userActionDetail, optTrackMetas, userOptTrackDailySum} from '@/api/track.js';

import OptDetail from './OptDetail.vue';

export default {
  name: "TrackInfo",
  components: {
    OptDetail
  },
  data () {
    return {
      analysisOptions: [],
      param: {
        startDate: null,
        endDate: null,
        rsIds: []
      },
      param2: {
        startTime: null,
        endTime: null,
        mobile: ""
      },
      tracks: [],
      chartOpt: {
        title: {
          text: "资源访问统计",
        },
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      }
    };
  },
  mounted () {
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-30));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime());
    this.param2.startTime = DateUtil.format(DateUtil.getDateTime(-1), "yyyy-MM-dd hh:mm:ss");
    this.param2.endTime = DateUtil.format(DateUtil.getDateTime(), "yyyy-MM-dd hh:mm:ss");
    this.initPage();
    this.renderChart();
  },
  methods: {
    initPage () {
      optTrackMetas({}).then(res => {
        let list = res.data || [];
        list.forEach(rsInfo => {
          this.analysisOptions.push({value: rsInfo.rsId, label: rsInfo.name});
        });
      });
    },
    query () {
      userOptTrackDailySum(this.param).then(res=>{
        this.tracks = res.data || [];
        this.renderChart();
      });
    },
    renderChart () {
      var axis = [];
      var dimensions = [];
      var dimensionNames = [];
      var data = [];
      let tracks = this.tracks || [];
      for (var i = 0; i < tracks.length; i ++) {
        let track = tracks[i];
        if (dimensions.indexOf(track.rsId) < 0) {
          dimensions.push(track.rsId);
          this.analysisOptions.forEach(itm => {
            if (itm.value == track.rsId) {
              dimensionNames.push(itm.label);
              return false;
            }
          });
        }
      }
      let dim = this.param.startDate;
      while (dim <= this.param.endDate) {
        axis.push(dim);
        //next loop
        dim = this.getNextDay(dim);
      }

      for (var j = 0; j < dimensions.length; j ++) {
        let d = [];
        for (var k = 0; k < axis.length; k ++) {
          let exist = false;
          for (var n = 0; n < tracks.length; n ++) {
            let time = axis[k];
            let dim = dimensions[j];
            if (tracks[n].optTime == time && tracks[n].rsId == dim) {
              exist = true;
              d.push(tracks[n].visit);
            }
          }
          if (!exist) {
            d.push(0);
          }
        }
        data.push(d);
      }
      let series = [];
      for (var m = 0; m < dimensionNames.length; m ++) {
        series.push({
          name: dimensionNames[m],
          type: "line",
          data: data[m]
        });
      }
      const opt = {
        legend: {
          data: dimensionNames
        },
        xAxis: {type: 'category', show: true, data: axis},
        yAxis: {
          show: true
        },
        series,
        ...this.chartOpt
      };
      this.$refs.chart.setOption(opt, true);
    },


    query2 () {
      userActionDetail(this.param2).then(res=>{
        this.$refs.detail.show(res.data);
      });
    },

    getNextDay (day) {
      let nextDay = new Date(new Date(day).getTime() + 3600 * 24 * 1000);
      return nextDay.getFullYear() + "-" + (nextDay.getMonth() < 9 ? "0" + (nextDay.getMonth() + 1) : (nextDay.getMonth() + 1)) + "-"
                    + (nextDay.getDate() < 10 ? "0" + nextDay.getDate() : nextDay.getDate());
    }
  }
};
</script>

<style scoped>
.root-box {
  background-color: #edf1f5;
}
</style>