<template>
  <el-drawer :visible.sync="visible"
             direction="ttb"
             :with-header="false"
             custom-class="drawer-modal"
             @opened="showMap"
             title="乘客地图"
             @closed="beforeClose"
             :withHeader="false">
    <div class="header">

      <div class="uprice">
        该区域加价： <span>{{ uprice }}</span> 元
      </div>

      <el-button type="primary"
                 @click="chooseAddress()"
                 v-if="canChoose">选择当前地址</el-button>
    </div>

    <div id="passengerMap"
         class="map-b"></div>

    <div class="result right"
         v-if="recommanAddress.length">

      <div class="recommend">推荐不加价上车地点：</div>
      <div v-for="(item, index) in recommanAddress"
           :key="item.id">
        <div class="marker-content">
          <div class="name">
            <template>
              <el-link @click="chooseAddress(item)"
                       v-if="canChoose"
                       type="success">
                {{ index + 1 }}、{{ item.name }}</el-link>
              <span v-else>{{ index + 1 }}、{{ item.name }}</span>
            </template>
            <span class="detail">{{ item.distance | mileFilter }}，{{
                item.duration | timeFilter
              }}</span>
          </div>
        </div>
      </div>
    </div>

  </el-drawer>
</template>

<script>
import { MapUtil } from "@/util";
import { listUpriceArea, getNearestAddress, getDriverLocation } from "@/api";
const carPng = require("../assets/image/car_blue.png");
const markY = require("../assets/image/mark_g.png");
const AREA_TYPE = {
  UPRICE: 1,
  CITY_SCOPE: 2,
  STANDARD_AREA: 3,
  RECOMMEND_ADDRESS: 4,
  SPEC_AREA: 10,
};

export default {
  props: {},
  filters: {},
  mounted () {},
  watch: {},

  computed: {},
  data () {
    return {
      visible: false,
      recommanAddress: [],
      uprice: 0,
      canChoose: false,
      type: 0,
      routeInfo: null,
      isStart: true,
      order: null,
    };
  },
  methods: {
    hide () {
      this.visible = false;
    },
    show (param) {
      const {
        order,
        type,
        isStart = true,
        chooseCb,
        recommendAddressParam,
      } = param;
      this.order = order;
      this.type = type;
      this.recommendAddressParam = recommendAddressParam;
      this.isStart = isStart;
      this.uprice = 0;
      this.recommanAddress = [];
      this.visible = true;
      this.chooseCb = chooseCb;
      this.driverLocation = null;
    },

    showMap () {
      this.map = new AMap.Map("passengerMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });
      AMap.plugin(["AMap.Geocoder"], () => {
        this.geocoder = new window.AMap.Geocoder();
      });

      const type = this.type;
      // 查看乘客位置
      if (type === 1) {
        const {
          depLongitude,
          depLatitude,
          departure,
          destination,
          destLongitude,
          destLatitude,
          pathId,
        } = this.order || {};
        this.address = this.isStart ? departure : destination;
        this.position = this.isStart
          ? [depLongitude, depLatitude]
          : [destLongitude, destLatitude];
        this.pathId = pathId;
        this.canChoose = false;
        this.renderMap();
      }
      // 新订单展示下单位置
      else if (type === 2) {
        const { address, lat, lng, pathId, orderStatus, driverId } =
                    this.order || {};
        this.pathId = pathId;
        this.address = address;
        this.position = [lng, lat];
        this.canChoose = true;
        this.renderMap();
        // 如果是分派司机的订单，则显示司机当前位置
        const activeStatus = ["PRE_DISPATCH", "MATCHED"];
        if (activeStatus.includes(orderStatus)) {
          this.showDriverMap(driverId);
        }
      }
    },

    chooseAddress (item) {
      this.visible = false;
      if (this.chooseCb) {
        let value;

        if (item) {
          value = {
            name: item.name,
            lng: item.lng,
            lat: item.lat,
          };
          this.chooseCb(value);
        } else {
          const orderMarker = this.orderMarker;
          const { lat, lng } = orderMarker.getPosition();
          const position = [lng, lat];
          this.geocoder.getAddress(position, (status, result) => {
            if (status === "complete" && result.regeocode) {
              const address = result.regeocode.formattedAddress;
              value = {
                name: address,
                lng: position[0],
                lat: position[1],
              };
              this.chooseCb(value);
            } else {
              console.error("根据经纬度查询地址失败");
            }
          });
        }
      }
    },

    async renderMap () {
      const map = this.map;
      const address = this.address;
      const position = this.position;
      const location = new AMap.Marker({
        label: {
          content: '<div class="info">' + address + "</div>",
          direction: "top",
        },
        anchor: "bottom-center",
        position,
        draggable: true,
        raiseOnDrag: false,
      });

      location.on("dragend", (e) => {
        const { lat, lng } = e.lnglat;
        this.renderPriceArea([lng, lat]);
      });

      map.add(location);
      this.orderMarker = location;
      this.renderPriceArea(position, true);
    },

    async renderPriceArea (position, needSetFitView = false) {
      let upriceArea = null;
      const map = this.map;
      let standardAreas = this.standardAreas;
      if (!standardAreas) {
        const param = {
          pathId: this.pathId,
          isStart: this.isStart,
          areaType: AREA_TYPE.STANDARD_AREA,
          _useCache: true,
        };
        // 查询城市区域
        this.renderCityScope(param, map);
        const res = await listUpriceArea(param);
        standardAreas = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#409EFF",
            fillColor: "#d3e9ff",
            fillOpacity: 0.35,
          })
        );
      }

      // 如果不在不加价区域，则要找到所在的加价区域
      if (!this.isInArea(standardAreas, position)) {
        const param = {
          pathId: this.pathId,
          isStart: this.isStart,
          areaType: AREA_TYPE.UPRICE,
          _useCache: true,
        };
        const res = await listUpriceArea(param);
        const upriceAreas = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#F44336",
            fillColor: "#ffcbc7",
            fillOpacity: 0.35,
          })
        );
        const { area, uprice } = this.getMinPriceArea(
          upriceAreas,
          position
        );
        upriceArea = area;
        this.uprice = uprice;
      }

      // 查询推荐地址
      if (this.recommendAddressParam) {
        getNearestAddress(this.recommendAddressParam).then((res) => {
          if (
            this.recommendMarkerList &&
                        this.recommendMarkerList.length
          ) {
            map.remove(this.recommendMarkerList);
          }
          const addressList = res.data || [];
          this.recommanAddress = addressList;
          const recommendMarkerList = [];
          addressList.forEach((item, index) => {
            const { lat, lng, name } = item || {};
            const marker = new AMap.Marker({
              position: [lng, lat],
              title: name,
              clickable: true,
              icon: markY,
              label: {
                content:
                                    '<div class="info rec">' +
                                    `${index + 1}、${name}` +
                                    "</div>",
                direction: "top",
              },
              extData: item,
            });
            recommendMarkerList.push(marker);
          });
          map.add(recommendMarkerList);
          this.recommendMarkerList = recommendMarkerList;
        });
      }

      if (!this.standardAreas) {
        this.standardAreas = standardAreas;
      }
      map.add(standardAreas);

      if (this.upriceArea) {
        map.remove(this.upriceArea);
      }
      if (upriceArea) {
        map.add(upriceArea);
      }
      this.upriceArea = upriceArea;

      if (needSetFitView) {
        map.setFitView();
      }
    },

    isInArea (areas, point) {
      let inArea = false;
      for (let index = 0; index < areas.length; index ++) {
        const area = areas[index];
        const isInArea = AMap.GeometryUtil.isPointInRing(
          point,
          area.getPath()
        );
        if (isInArea) {
          inArea = true;
          break;
        }
      }
      return inArea;
    },

    getMinPriceArea (areas, point) {
      let min = 0;
      let findItem;
      for (let index = 0; index < areas.length; index ++) {
        const area = areas[index];
        const isInArea = AMap.GeometryUtil.isPointInRing(
          point,
          area.getPath()
        );
        if (isInArea) {
          const extData = area.getExtData();
          const uprice = extData.originData.price;
          if (!min || uprice < min) {
            min = uprice;
            findItem = area;
          }
        }
      }
      return {
        area: findItem,
        uprice: min,
      };
    },

    async showDriverMap (driverId) {
      const map = this.map;
      getDriverLocation(driverId).then((res) => {
        const { driver, location = {} } = res.data || {};
        const { lat, lng, gpsTime, vendor } = location || {};
        const time = gpsTime.substr(5);
        const locationSource = vendor === "jimi" ? "GPS" : "手机";
        if (lat && lng) {
          const position = [lng, lat];
          const carMarker = new AMap.Marker({
            position,
            draggable: true,
            raiseOnDrag: false,
            anchor: "bottom-center",
            label: {
              //修改label相对于maker的位置
              direction: "top",
              content:
                                "<div class='info'>" +
                                `${driver.no} | ${time} | ${locationSource}` +
                                "</div>",
            },
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), //图标大小
              image: carPng,
              imageSize: new AMap.Size(40, 40),
            }),
          });
          map.add(carMarker);
          this.driverLocation = position;
        }
      });
    },

    renderCityScope (param, map) {
      const pp = {
        ...param,
        areaType: AREA_TYPE.CITY_SCOPE,
        _useCache: true,
      };
      listUpriceArea(pp).then((res) => {
        const scope = res.data.map((item) =>
          MapUtil.json2Overlay(item, {
            strokeColor: "#000",
            fillColor: "transparent",
            strokeWeight: 2,
          })
        );
        map.add(scope);
      });
    },

    beforeClose () {
      if (this.map) {
        this.map.clearMap();
      }
      this.standardAreas = null;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90% !important;
}

.route-info {
  margin: 10px 0;

  .info {
    color: #ff5722;
    font-weight: bold;
    font-size: 16px;
  }
  .address {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
  }
}

.header {
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  min-height: 200px;
  overflow-y: auto;
  top: 100px;
  right: 0;
  width: 295px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  &.right {
    right: 0;
    left: auto;
  }

  .uprice {
    margin: 10px 0;
    span {
      color: #ff5722;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .recommend {
    margin: 20px 0;
    font-size: 15px;
  }

  .marker-content {
    margin-bottom: 10px;
    line-height: 25px;
    .name {
      font-size: 14px;
    }

    .detail {
      font-size: 12px;
      color: #e2d0ad;
      margin-left: 10px;
    }
  }
}

#passengerMap {
  width: 100%;
  height: 100%;
  flex: 1;
}
</style>
