import {Http} from '@/util';

export function getPrinumRecords (param) {
  return Http.get('/admin/huawei/record/list', param);
}

export function unbindAxb (param) {
  return Http.get('/admin/huawei/axb/unbind', param);
}

export function downloadRecord (param) {
  return Http.get('/admin/huawei/axb/downloadrecord', param);
}

