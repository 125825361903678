<template>
  <div class="service-root">
    <el-link type="primary" v-if="queryStatus == '1'" style="margin-bottom: 10px" @click="batchAuditBill">批量通过</el-link>
    <el-table :data="list" border style="width: 100%" max-height="540" show-summary :summary-method="getSummaries">
      <el-table-column label="时间" prop="serviceDate" width="180"></el-table-column>
      <el-table-column label="服务商" prop="providerName" width="120"> </el-table-column>
      <el-table-column label="服务类型" width="120">
        <template slot-scope="scope">
          {{ transeferIdNames(scope.row.serviceType) }}
        </template>
      </el-table-column>
      <el-table-column prop="staffName" label="检修员" width="120"> </el-table-column>
      <el-table-column prop="vehicleNo" label="车牌号" width="120"></el-table-column>
      <el-table-column prop="driverNo" label="司机编号" width="120"></el-table-column>
      <el-table-column prop="driverName" label="司机" width="120"></el-table-column>
      <el-table-column label="费用（元）" width="120" prop="amount">
        <template slot-scope="scope">
          {{ scope.row.amount ? scope.row.amount / 100 : 0 }}
        </template>
      </el-table-column>
      <el-table-column label="服务状态" width="120">
        <template slot-scope="scope">
          {{ getBillStatusLabel(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column prop="cbyName" label="创建人">
        <template slot-scope="scope">
          {{ scope.row.cbyName ?? "系统" }}
        </template>
      </el-table-column>

      <el-table-column prop="createReason" label="创建原因"></el-table-column>
      <el-table-column label="审核状态" width="120">
        <template slot-scope="scope">
          {{ getBillAuditLabel(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-link type="primary" v-if="scope.row.status == 0" @click="serviceBill(scope.row)">送检</el-link>
          <el-link type="primary" v-if="scope.row.status == 0" @click="deleteBill(scope.row)"
            style="margin-left:10px;">删除</el-link>
          <el-link type="primary" v-if="scope.row.status >= 10" @click="viewBill(scope.row)">详情</el-link>
        </template>
      </el-table-column>
    </el-table>

    <!-- 侧边栏 -->
    <el-dialog title="单据详情" :visible.sync="showBillDialog">
      <billDetail :bill-id="currentBill.id" v-if="!!currentBill"></billDetail>

      <!-- 弹出框 -->
      <el-dialog title="驳回原因" width="30%" :visible.sync="showRemarkDialog" append-to-body>
        <el-input v-model="remark" placeholder="请输入驳回原因"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showRemarkDialog = false">取消</el-button>
          <el-button @click="doAuditBillNotPass">确定</el-button>
        </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button v-if="currentBill && (currentBill.status == 10 || currentBill.status == 20)" type="primary"
          @click="auditBillPass(currentBill)">通过</el-button>
        <el-button v-if="currentBill && (currentBill.status == 10 || currentBill.status == 20)" type="danger"
          @click="auditBillNotPass(currentBill)">不通过</el-button>
        <el-button @click="(showBillDialog = false), (currentBill = null)">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listServiceBill, toService, auditBillStatus, deleteBillInfo } from "@/api/partner_admin";
import billDetail from "../bill_detail";
export default {
  name: "serviceList",
  props: {
    provider: {
      type: Number,
    },
    start: {
      type: String,
    },
    end: {
      type: String,
    },
    queryStatus: {
      type: String,
    },
  },
  components: {
    billDetail,
  },
  data() {
    return {
      serviceScopeObjs: [],
      list: [],
      remark: null,
      showBillDialog: false,
      showRemarkDialog: false,
      currentBill: null,
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.serviceScopeObjs = window.serviceScopeObjs || [];
      let startDate = this.start;
      let endDate = this.end;
      let queryStatus = this.queryStatus;
      if (this.provider) {
        listServiceBill(this.provider, startDate, endDate, queryStatus).then((res) => {
          this.list = res.data || [];
        });
      }
    },
    transeferIdNames(serviceType) {
      if (!serviceType) {
        return "";
      }

      for (var i = 0; i < this.serviceScopeObjs.length; i++) {
        if (this.serviceScopeObjs[i].value == serviceType) {
          return this.serviceScopeObjs[i].label;
        }
      }
      return "";
    },
    getBillStatusLabel(bill) {
      return bill.status < 10 ? "待办" : "已办";
    },
    getBillAuditLabel(bill) {
      if (bill.status < 10) {
        return "-";
      } else if (bill.status == 31) {
        return "不通过";
      } else if (bill.status == 30) {
        return "通过";
      } else {
        return "待审";
      }
    },
    viewBill(bill) {
      this.showBillDialog = true;
      this.currentBill = bill;
    },
    batchAuditBill() {
      let ids = [];
      let datas = this.list;
      datas.forEach((bill) => {
        ids.push(bill.id);
      });

      this.$confirm("确定要批量通过该单据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        auditBillStatus(ids, datas[0].providerId, 30).then(() => {
          datas.forEach((bill) => (bill.status = 30));
        });
      });
    },
    auditBillPass(bill) {
      let me = this;
      this.$confirm("确定要通过该单据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        auditBillStatus([bill.id], bill.providerId, 30).then(() => {
          bill.status = 30;
          me.showBillDialog = false;
          me.currentBill = null;
        });
      });
    },
    auditBillNotPass() {
      this.showRemarkDialog = true;
      this.remark = null;
    },
    doAuditBillNotPass() {
      let me = this;
      let bill = this.currentBill;
      let remark = this.remark;
      auditBillStatus([bill.id], bill.providerId, 31, remark).then(() => {
        bill.status = 31;
        me.showRemarkDialog = false;
        me.showBillDialog = false;
        me.currentBill = null;
      });
    },
    serviceBill(bill) {
      this.$confirm("确定要送检吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        toService(bill.id).then(() => {
          bill.status = 2;
        });
      });
    },
    deleteBill(bill) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBillInfo(bill.id).then(() => {
          let idx = -1;
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].id == bill.id) {
              idx = i;
              break;
            }
          }
          this.list.splice(idx, 1);
        });
      });
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：";
          return;
        }
        const property = column.property;


        if (column.property !== "amount") {
          sums[index] = "";
          return;
        }


        const values = data.map((item) => {
          return Number(item[property] / 100);
        });

        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result ? result.toFixed(2) : 0;
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
  },
};
</script>

<style scoped></style>
