import { Http } from "@/util";

export function execAutoDispatchTask(param) {
  return Http.post("/auto_dispatch/exec_task", param);
}

export function getDispatchTaskList(date) {
  return Http.get("/auto_dispatch/task_list", { date });
}

export function reExecTask(param) {
  return Http.post("/auto_dispatch/re_exec_task", param);
}

export function doDispatch(param, ignoreError) {
  return Http.post(`/auto_dispatch/do_dispatch?ignoreError=${ignoreError}`, param);
}

export function saveDispatchPlan(param, taskId) {
  return Http.post(`/auto_dispatch/save_plan?taskId=${taskId}`, param);
}
