import Vue from 'vue';
import Router from '@/route';
import { Message } from 'element-ui';

/**
 * 查询行程详情
 */
Vue.directive('goRouteReplay', {

  bind: function (el, binding) {
    el.binding = binding;
    el.style.cursor = 'pointer';
    el.addEventListener('click', function () {
      const data = binding.value;
      const {id, orderId} = data || {};
      if (id) {
        const {href} = Router.resolve({
          path: '/route/replay', query: {id, orderId}
        });
        window.open(href, '_blank');
      } else {
        Message({type: 'warning', message: '此订单没有行程信息'});
      }
    });
  },
  update: function (el, binding) {
    el.binding = binding;
  },
  unbind: function () {


  }
});
