<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="show"
    width="1300px"
    :title="title"
    :show-close="true"
    :before-close="close"
    :close-on-click-modal="false"
    :hide-required-asterisk="true"
    :destroy-on-close="true"
    custom-class="tel-order-dialog"
  >
    <div style="display: flex">
      <div style="width: 900px">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          size="medium"
          v-if="form"
          :rules="rules"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="订单类型" prop="orderType">
                <el-select v-model="form.orderType" placeholder="请选择订单类型">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in orderTypeOpts"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="订单渠道">
                <el-select v-model="form.orderSource" placeholder="请选择渠道" style="width: 100%">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in orderSourceOpts"
                    :key="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="路线" prop="pathId">
                <el-select v-model="form.pathId" placeholder="请选择路线">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in pathOpts"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="日期" prop="date">
                <el-date-picker
                  style="width: 180px"
                  v-model="form.date"
                  type="date"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="时间" prop="ticketId">
                <el-select v-model="form.ticketId" placeholder="请选择时间">
                  <el-option
                    :label="item.showTime"
                    :value="item.id"
                    v-for="item in tableData"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="成人人数" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  v-model="form.adultNumber"
                  :min="1"
                  :max="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名" prop="username">
                <el-input v-model="form.username" style="width: 60px"></el-input>

                &nbsp;
                <el-select v-model="form.gender" style="width: 100px" placeholder="请选择性别">
                  <el-option label="先生" :value="true"></el-option>
                  <el-option label="女士" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="儿童人数" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  v-model="form.childNumber"
                  :min="0"
                  :max="10"
                  style="width: 80%"
                ></el-input-number>
                <i class="el-icon-warning" @click="showTip"></i>
              </el-form-item>

              <el-form-item label="数量" v-else-if="form.orderType === OrderTypeEnum.THING">
                <el-input-number
                  v-model="form.number"
                  :min="0"
                  :max="0"
                  style="width: 100%"
                  disabled
                ></el-input-number>
              </el-form-item>

              <el-form-item label="人数" v-else-if="form.orderType === OrderTypeEnum.CHARTER">
                <el-select v-model="form.number">
                  <el-option label="5座" :value="4"></el-option>
                  <el-option label="7座" :value="6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="其他加价">
                <el-input-number
                  v-model="form.otherUpPrice"
                  :min="0"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="起点加价">
                <el-input-number
                  v-model="form.startAreaUpPrice"
                  :min="0"
                  :step="10"
                  disabled
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="终点加价">
                <el-input-number
                  v-model="form.endAreaUpPrice"
                  :min="0"
                  :step="10"
                  disabled
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="其他折扣">
                <el-input-number
                  v-model="form.otherDiscount"
                  :min="0"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="成人价格" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  disabled
                  v-model="form.basePrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="基础价格" v-else>
                <el-input-number
                  disabled
                  v-model="form.basePrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="儿童价格" v-if="form.orderType === OrderTypeEnum.NORMAL">
                <el-input-number
                  disabled
                  v-model="form.childPrice"
                  :min="10"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总价">
                <el-input-number
                  disabled
                  :value="totalPrice"
                  :min="0"
                  :step="10"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否代下单">
                <el-select v-model="form.isAppointOrder" style="width: 100%">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="16">
              <el-row :gutter="20">
                <el-col :span="form.isAppointOrder ? 12 : 24">
                  <el-form-item
                    :label="form.isAppointOrder ? '下单人电话' : '乘车人电话'"
                    prop="telephone"
                  >
                    <el-input v-model="form.telephone" />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="form.isAppointOrder">
                  <el-form-item label="乘车人电话" prop="appointTelephone">
                    <el-input v-model="form.appointTelephone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="起点" prop="start">
                <poi-selector
                  ref="startPoi"
                  v-model="form.start"
                  :suggest="startSuggest"
                  :pathId="form.pathId"
                  :ticketId="form.ticketId"
                  :isStart="true"
                  @onUpPriceGet="(val) => onUpPriceGet(val, true)"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="终点" prop="end">
                <poi-selector
                  ref="endPoi"
                  v-model="form.end"
                  :suggest="endSuggest"
                  :pathId="form.pathId"
                  :ticketId="form.ticketId"
                  :isStart="false"
                  @onUpPriceGet="(val) => onUpPriceGet(val, false)"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="乘客留言">
                <el-input type="textarea" :rows="1" v-model="form.psgMessage"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tags">
            <el-tag
              size="small"
              v-for="item in pasMsgs"
              :key="item.remark"
              @click="addMsg(item, 1)"
              >{{ item.remark }}</el-tag
            >
          </div>
          <div class="tags">
            <div v-if="tipDescription" class="red">{{ tipDescription }}</div>
          </div>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="司机备注">
                <el-input type="textarea" :rows="1" v-model="form.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tags">
            <el-tag
              size="small"
              v-for="item in remarkOpts"
              :key="item.remark"
              @click="addMsg(item, 3)"
              >{{ item.remark }}</el-tag
            >
          </div>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="客服留言">
                <el-input type="textarea" :rows="1" v-model="form.adminMessage"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tags">
            <el-tag :key="item" size="small" v-for="item in adminMsgs" @click="addMsg(item, 2)">{{
              item
            }}</el-tag>
          </div>
        </el-form>
      </div>
      <div style="flex: 1; margin-left: 20px">
        <el-table
          :data="tableData"
          border
          size="medium"
          highlight-current-row
          max-height="800"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column prop="showTime" width="120" label="排班时间"></el-table-column>
          <el-table-column prop="usedNum" width="120" label="已订"></el-table-column>
          <el-table-column prop="num" width="120" label="余票">
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <span slot="footer" class="footer">
      <el-checkbox v-model="checkOnway" style="margin-right: 6px; color: red">顺路检测</el-checkbox>

      <el-button type="warning" plain @click="createEmpty" size="small">创建空单</el-button>
      <el-button size="small" @click="close">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { DateUtil } from "@/util";
import { BaseDataService } from "@/service";
import PoiSelector from "@/components/PoiSelector";
import { mapState } from "vuex";
import {
  getPathAreas,
  getTicketList,
  getPassengerInfo,
  getUserAddress,
  createTelOrder,
  listSpecArea,
  listRecommendAddress,
  createEmptyOrder,
} from "@/api";
import _ from "lodash";
import moment from "moment";
import { ORDER_CHANGE } from "@/core/const/global-event";

const orderSource = BaseDataService.orderSource();

const defaultForm = {
  isAppointOrder: false,
  date: DateUtil.getDayStr(),
  gender: true,
  number: 1,
  childNumber: 0,
  adultNumber: 1,
  orderType: 1,
  orderSource: 2,
  start: null,
  end: null,
  adminMessage: "",
  remark: "",
  psgMessage: "",
  startAreaUpPrice: null,
  endAreaUpPrice: null,
};

const telReg = /^1\d{10}$/;

const OrderTypeEnum = {
  NORMAL: 1,
  THING: 2,
  CHARTER: 3,
};

export default {
  components: {
    PoiSelector,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  filters: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      orderTypeOpts: (state) => state.enumMap["ORDER_TYPE"],
      pathOpts: (state) => state.pathList,
      pasMsgs: (state) => state.pasMsgs,
    }),
    totalPrice() {
      const {
        basePrice = 0,
        number = 1,
        childNumber = 0,
        adultNumber = 1,
        orderType = OrderTypeEnum.NORMAL,
        startAreaUpPrice = 0,
        endAreaUpPrice = 0,
        otherDiscount = 0,
        childPrice = 0,
        otherUpPrice = 0,
      } = this.form;
      if (orderType === OrderTypeEnum.NORMAL) {
        return (
          basePrice * adultNumber +
          childPrice * childNumber +
          startAreaUpPrice +
          endAreaUpPrice -
          otherDiscount +
          otherUpPrice
        );
      }
      if (orderType === OrderTypeEnum.THING) {
        return basePrice + startAreaUpPrice + endAreaUpPrice - otherDiscount + otherUpPrice;
      }
      return basePrice * number + startAreaUpPrice + endAreaUpPrice - otherDiscount + otherUpPrice;
    },
    pathId() {
      // 做中间层监听pathId变化
      return this.form.pathId;
    },
    tel() {
      // 做中间层监听telephone变化
      return this.form.telephone;
    },
    ticketId() {
      // 做中间层监听time变化
      return this.form.ticketId;
    },
    date() {
      // 做中间层监听date变化
      return this.form.date;
    },
    orderType() {
      // 做中间层监听orderType变化
      return this.form.orderType;
    },
    addressStart() {
      // 做中间层监听orderType变化
      return this.form.start;
    },
    addressEnd() {
      // 做中间层监听orderType变化
      return this.form.end;
    },
  },
  watch: {
    pathId() {
      this.queryPathArea();
      this.getPassengerAddress();
      this.queryTickets();
      this.$set(this.form, "start", null);
      this.$set(this.form, "end", null);
    },
    date() {
      this.queryTickets();
    },
    tel() {
      this.getUserInfo();
      this.getPassengerAddress();
    },
    ticketId() {
      this.changePrice();
    },
    orderType(val) {
      if (val === OrderTypeEnum.CHARTER) {
        this.$set(this.form, "number", 4);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 4);
      } else if (val === OrderTypeEnum.NORMAL) {
        this.$set(this.form, "number", 1);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 1);
      } else if (val === OrderTypeEnum.THING) {
        this.$set(this.form, "number", 0);
        this.$set(this.form, "childNumber", 0);
        this.$set(this.form, "adultNumber", 0);
      }
      this.changePrice();
    },

    addressStart(val) {
      if (this.startStation && val) {
        const arr = this.startStation || [];
        for (let station in arr) {
          if (val.name.indexOf(arr[station].name) > -1) {
            let msg = this.form.psgMessage || "";
            msg = `${msg} 到站时间??`;
            this.$set(this.form, "psgMessage", msg);
            break;
          }
        }
      }
    },

    addressEnd(val) {
      if (this.endStation && val) {
        const arr = this.endStation || [];
        for (let station in arr) {
          if (val.name.indexOf(arr[station].name) > -1) {
            let msg = this.form.psgMessage || "";
            msg = `${msg} 几点的车票??`;
            this.$set(this.form, "psgMessage", msg);
            break;
          }
        }
      }
    },
  },
  data() {
    const validatePoi = (rule, value, callback) => {
      if (!value || !value.jd || !value.wd) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      OrderTypeEnum,
      show: false,
      checkOnway: true,
      form: _.clone(defaultForm),
      remarkOpts: [
        { remark: "起点位置不准" },
        { remark: "终点位置不准" },
        { remark: "起点终点位置都不准" },
        { remark: "买入订单" },
      ],
      rules: {
        pathId: [
          {
            required: true,
            message: "请选择路线",
            trigger: "change",
          },
        ],
        ticketId: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入人数",
            trigger: "change",
          },
        ],
        start: [
          {
            required: true,
            message: "请输入起点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
        end: [
          {
            required: true,
            message: "请输入终点",
            validator: validatePoi,
            trigger: "change",
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入乘车人电话",
            trigger: "change",
          },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        appointTelephone: [
          {
            required: true,
            message: "请输入下单人电话",
            trigger: "change",
          },
          {
            pattern: telReg,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
      },
      orderSourceOpts: [
        {
          label: "电话补录",
          value: orderSource.DZ.value,
        },
        {
          label: "购买客源",
          value: orderSource.GM.value,
        },
        {
          label: "交换客源",
          value: orderSource.JH.value,
        },
      ],
      tableData: [],
      tipDescription: "",
      adminMsgs: ["买入", "红包未发", "红包已发", "要越野车", "不要七座车", "不赶时间", "赶时间 "],
      startSuggest: [],
      endSuggest: [],
    };
  },
  methods: {
    resetForm() {
      this.form = _.cloneDeep(defaultForm);
      this.tableData = [];
      this.startArea = {};
      this.endArea = {};
      this.startSuggest = [];
      this.endSuggest = [];
      this.tipDescription = "";
      this.checkOnway = true;
      this.$refs.form.resetFields();
    },
    querySpecArea(pathId) {
      if (pathId) {
        listSpecArea(pathId).then((res) => {
          const { startList = [], endList = [] } = res.data || {};
          this.startAreaSettings = startList;
          this.endAreaSettings = endList;
        });
      }
    },
    changePrice() {
      if (this.tableData.length && this.ticketId && this.orderType) {
        const ticket = this.tableData.find((item) => item.id === this.ticketId);
        let basePrice = ticket.price;
        if (this.orderType === OrderTypeEnum.THING) {
          basePrice = ticket.goodsPrice;
        } else if (this.orderType === OrderTypeEnum.CHARTER) {
          basePrice = ticket.price - ticket.charterDiscount;
        } else if (this.orderType === OrderTypeEnum.NORMAL) {
          this.$set(this.form, "childPrice", ticket.childrenPrice);
        }
        this.$set(this.form, "basePrice", basePrice);
      }
    },
    onUpPriceGet(val, isStart) {
      if (isStart) {
        this.$set(this.form, "startAreaUpPrice", val);
      } else {
        this.$set(this.form, "endAreaUpPrice", val);
      }
    },
    getUserInfo() {
      const { telephone } = this.form;
      if (telReg.test(telephone)) {
        getPassengerInfo({ tel: telephone }).then((res) => {
          const user = res.data || {};
          this.$set(this.form, "gender", user.gender);
          this.$set(this.form, "username", user.name);
        });
      }
    },
    getPassengerAddress() {
      const { telephone, pathId } = this.form;
      if (telReg.test(telephone) && pathId) {
        getUserAddress({ tel: telephone, pathId }).then((res) => {
          const { start = [], end = [] } = res.data || {};
          this.startSuggest = this.format(start);
          this.endSuggest = this.format(end);
        });
      }
    },
    queryPathArea() {
      const { pathId } = this.form;

      if (pathId) {
        getPathAreas({ pathId }).then((res) => {
          this.startArea = res.data[0];
          this.endArea = res.data[1];

          // 查询车站机场信息
          this.queryStation(this.startArea.id, true);
          this.queryStation(this.endArea.id, false);
        });
      }
    },
    tableRowClassName({ row }) {
      const now = moment().format("YYYY-MM-DD HH:00:00");

      const num = row.num;
      // 如果num < 0 ，则按照num的大小分级
      if (num < 0) {
        return "warn1";
      } else if (num < -5) {
        return "warn2";
      } else if (num < -10) {
        return "warn3";
      }

      if (row.showTicket && row.dateTime >= now) {
        return "";
      }
      return "hide-row";
    },
    format(data = []) {
      return data.map((item) => {
        return {
          ...item,
          address: `🍉${item.address}`,
        };
      });
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = this.form;
          const {
            start,
            end,
            ticketId,
            orderType,
            adultNumber = 0,
            childNumber = 0,
            number = 0,
          } = formData;

          const ticket = this.tableData.find((item) => item.id === ticketId);

          formData.departure = start.name;
          formData.depLongitude = start.jd;
          formData.depLatitude = start.wd;
          formData.destination = end.name;
          formData.destLongitude = end.jd;
          formData.destLatitude = end.wd;
          formData.date = ticket.date;
          formData.time = ticket.time;
          formData.totalPrice = this.totalPrice;
          formData.checkOnway = this.checkOnway;

          // 计算总人数
          if (orderType === OrderTypeEnum.NORMAL) {
            formData.number = adultNumber + childNumber;
          } else if (orderType === OrderTypeEnum.CHARTER) {
            formData.adultNumber = number;
          } else {
            formData.number = number;
          }

          createTelOrder(formData).then((res) => {
            if (res.data && !res.data.isOk) {
              this.$message.error("系统检测不顺路，取消顺路检测可下单");
            } else {
              this.$message.success("订单保存成功");
              this.close();
              this.$bus.$emit(ORDER_CHANGE);
            }
          });
        }
      });
    },

    createEmpty() {
      const formData = this.form;
      if (!formData.pathId) {
        this.$message.error("请选择路线");
        return;
      }
      if (!formData.ticketId) {
        this.$message.error("请选择时间");
        return;
      }
      createEmptyOrder(formData).then(() => {
        this.$message.success("订单保存成功");
        this.close();
        this.$bus.$emit(ORDER_CHANGE);
      });
    },

    addMsg(msg, type) {
      if (type === 1) {
        this.form.psgMessage += ` ${msg.remark}`;
        this.tipDescription = msg.description;
      } else if (type === 2) {
        this.form.adminMessage += ` ${msg}`;
      } else if (type === 3) {
        this.form.remark += ` ${msg.remark}`;
      }
    },
    close() {
      this.resetForm();
      this.show = false;
      // 终点区域限制查询参数
      this.limitRouteIds = null;
    },
    open(tel) {
      if (tel) {
        this.$set(this.form, "telephone", tel);
      }
      this.show = true;
    },
    queryTickets() {
      const { date, pathId } = this.form || {};
      if (date && pathId) {
        const param = {
          date,
          pathId,
        };
        getTicketList(param).then((res) => {
          const { list = [] } = res.data || {};
          // 只显示当前时间以后的车票
          const now = moment().subtract(1, "hours").format("YYYY-MM-DD HH:00:00");
          this.tableData = list.filter((item) => item.dateTime >= now);
          this.$set(this.form, "ticketId", null);
        });
      }
    },
    showTip() {
      this.$alert(
        "身高1米（含）至1.2米儿童需要购买儿童票，儿童票有座位；1.2米（含）以上儿童需要购买成人票，1米以下儿童可以免票，但不保证有座位",
        "儿童票规则",
        {
          confirmButtonText: "确定",
        }
      );
    },

    // 查询车站机场
    queryStation(areaId, isStart) {
      const param = {
        _useCache: true,
        _hideLoading: true,
        type: 3,
        areaId,
      };
      listRecommendAddress(param).then((res) => {
        if (isStart) {
          this.startStation = res.data || [];
        } else {
          this.endStation = res.data || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tel-order-dialog {
  margin-top: 25px !important;
}

.tags {
  margin-left: 100px;
  position: relative;
  top: -18px;

  .el-tag {
    cursor: pointer;
    margin-right: 4px;
  }
}

/deep/ .el-icon-warning {
  margin-left: 10px;
  font-size: 16px;
  color: orange;
  cursor: pointer;
}
</style>
