import Vue from 'vue';
import LimitText from '@/components/LimitText';
import OrderCancel from '@/components/OrderCancel';
import VipLabel from '@/components/VipLabel';
import TelOrder from "@/components/TelOrder";
import TelCell from '@/components/TelCell';
import TelOrderModifyModal from '@/components/TelOrder/TelOrderModifyModal';
import Chart from '@/components/Chart';
import ImageUpload from '@/components/ImageUpload';
import FileUpload from '@/components/FileUpload';
import LabelTip from '@/components/LabelTip';
import MultipleFileUpload from '@/components/MultipleFileUpload';
import DriverSelector from '@/components/DriverSelector';
import SortableList from '@/components/SortableList';
import CountDown from '@/components/CountDown';




// 注册全局组件
Vue.component('limit-text', LimitText);
Vue.component('order-cancel', OrderCancel);
Vue.component('vip-label', VipLabel);
Vue.component('chart', Chart);
Vue.component('tel-order', TelOrder);
Vue.component('tel-cell', TelCell);
Vue.component('tel-order-modify-modal', TelOrderModifyModal);
Vue.component('image-upload', ImageUpload);
Vue.component('file-upload', FileUpload);
Vue.component('label-tip', LabelTip);
Vue.component('multiple-file-upload', MultipleFileUpload);
Vue.component('driver-selector', DriverSelector);
Vue.component('sortable-list', SortableList);
Vue.component('count-down', CountDown);
