<template>
  <div>
    <span @click="visible = true" class="box">
      <el-input
        :value="text"
        suffix-icon="el-icon-zoom-in"
        style="width: 200px"
        size="small"
      ></el-input>
    </span>
    <el-dialog
      title="选择司机"
      :visible.sync="visible"
      width="1200px"
      append-to-body
    >
      <el-form ref="form" :model="form" size="small" inline>
        <el-form-item prop="teamId" label="司机车队：">
          <el-select v-model="form.teamId" clearable @change="query">
            <el-option
              v-for="item in teamOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="vehicleTypes" label="车型：">
          <el-select
            v-model="form.vehicleTypes"
            clearable
            @change="query"
            placeholder=""
          >
            <el-option
              v-for="item in vehicleTypeOpts"
              :key="item.id"
              :label="item.content"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="vehicleType" label="注册时间月份：">
          <el-input-number
            v-model="form.regTimeLimit"
            :min="1"
            label="label"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item prop="driverNos" label="指定司机：">
          <el-input
            v-model="form.driverNos"
            placeholder="司机编号"
            style="width: 830px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            style="width: 100px; margin: 0 20px; float: right"
            @click="query"
            >查询</el-button
          >
        </el-form-item>
      </el-form>

      <div style="margin: 20px 0 40px">
        <el-table
          :data="list"
          border
          size="medium"
          max-height="400"
          ref="table"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="no" label="司机编号"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="teamId" label="车队">
            <template slot-scope="scope">
              <span>{{
                scope.row.teamId | enumTransfer(teamOpts, "id", "name")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="vehicleTypeId" label="车型">
            <template slot-scope="scope">
              <span>{{
                scope.row.vehicleTypeId
                  | enumTransfer(vehicleTypeOpts, "id", "content")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="注册日期"></el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <el-button type="warning" :size="size" @click="handleOk"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import {filterDrivers} from '@/api';

export default {
  components: {},
  props: {
    value: Array
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      teamOpts: state => state.teamOpts,
      vehicleTypeOpts: state => state.vehicleTypeOpts,
    }),
    text () {
      if (this.value && this.value.length) {
        return '已选择' + this.value.length + "个司机";
      }
      return '';
    }
  },
  data () {
    return {
      visible: false,
      list: [],
      form: {},
      selectDrivers: []
    };
  },
  methods: {

    query () {
      filterDrivers(this.form).then(res=>{
        this.list = res.data || [];
      });
    },


    handleOk () {
      if (this.selectDrivers.length) {
        this.$emit("input", this.selectDrivers);
        this.visible = false;
      } else {
        this.$message.error('请至少选择一个司机');
      }
    },

    handleSelectionChange (val) {
      this.selectDrivers = val.map(item=> item.id);
    }
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
}
</style>