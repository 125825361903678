<template>
  <div>
    <el-row class="query">
      <el-select v-model="queryObj.teamId" placeholder="选择车队">
        <el-option
          v-for="team in teams"
          :key="team.id"
          :label="team.name"
          :value="team.id"
        >
        </el-option>
      </el-select>
      &nbsp;
      <el-select v-model="queryObj.bankId" placeholder="所有题库">
        <el-option
          v-for="bankInfo in exbanks"
          :key="bankInfo.id"
          :label="bankInfo.name"
          :value="bankInfo.id"
        >
        </el-option>
      </el-select>
      &nbsp;
      <el-button @click.native="query">查询</el-button>
    </el-row>

    <el-table
      v-if="queryObj.bankId"
      :key="key1"
      :data="exams"
      style="width: 100%"
    >
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column sortable prop="no" label="班号" width="180">
      </el-table-column>
      <el-table-column sortable prop="examtime" label="累计考核时长">
      </el-table-column>
      <el-table-column sortable prop="uptime" label="最后考核时间">
      </el-table-column>
      <el-table-column sortable prop="pass" label="是否通过"> </el-table-column>
      <el-table-column sortable prop="examNum" label="考核次数">
        <template slot-scope="scope">
          <div slot="reference">
            <el-link type="primary" @click="showExamDetail(scope.row)">{{
              scope.row.examNum
            }}</el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable prop="passNum" label="合格次数">
      </el-table-column>
      <el-table-column sortable prop="passRate" label="合格率">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div slot="reference">
            <el-tag
              :type="scope.row.pass == '通过' ? 'primary' : 'info'"
              @click="resetExamResult(scope.row)"
              >修改结果</el-tag
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-else :key="key2" :data="exams" style="width: 100%">
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column sortable prop="no" label="班号" width="180">
      </el-table-column>
      <el-table-column sortable prop="examtime" label="累计考核时长">
      </el-table-column>
      <el-table-column sortable prop="uptime" label="最后考核时间">
      </el-table-column>
      <el-table-column sortable prop="pass" label="通过几门"> </el-table-column>
      <el-table-column sortable prop="goodRate" label="通过率">
      </el-table-column>
      <el-table-column sortable prop="examNum" label="考核次数">
      </el-table-column>
      <el-table-column sortable prop="passNum" label="合格次数">
      </el-table-column>
      <el-table-column sortable prop="passRate" label="合格率">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {DateUtil, Toolkit} from "@/util/index";
export default {
  name: "ExamStat",
  data () {
    return {
      queryObj: {bankId: "", teamId: ""},
      exbanks: [],
      teams: [],
      exams: [],
      key1: Toolkit.uuid(),
      key2: Toolkit.uuid()
    };
  },
  mounted () {
    this.initPage();
  },
  watch: {
    "queryObj.bankId": function () {
      if (this.queryObj.bankId) {
        this.key1 = Toolkit.uuid();
      } else {
        this.key2 = Toolkit.uuid();
      }
      this.exams = [];
    }
  },
  methods: {
    initPage () {
      this.$http.post("/admin/train/stat/initexbank", {}).then(res=>{
        this.exbanks = res.data.exbanks || [];
        this.exbanks.splice(0, 0, {"id": "", "name": "所有题库"});
        this.teams = res.data.teams || [];
        this.queryObj.teamId = this.teams[0].id;
      });
    },
    query () {
      let url = "/admin/train/stat/exam";
      this.$http.post(url, this.queryObj).then(res => {
        this.exams = res.data || [];
        if (!this.queryObj.bankId) {
          var total = this.exbanks.length;
          this.exams.forEach(exam => {
            this.$set(exam, "goodRate", (exam.pass * 100 / total).toFixed(2));
          });
        }
      });
    },
    showExamDetail (row) {
      if (!row.records || !row.records.length) {
        return;
      }

      var htmlText = "";
      for (var i = 0 ; i < row.records.length; i ++) {
        var record = row.records[i];
        htmlText += "<div>"
                  + "<span style='display: inline-block; width: 100px'>" + DateUtil.format(record.ctime) + "</span>"
                  + "<span style='display: inline-block; width: 100px'>" + DateUtil.getFormatMMSS((record.uptime.getTime() - record.ctime.getTime()) / 1000) + "</span>"
                  + "<span style='display: inline-block; width: 50px'>" + record.grade + "</span>"
                  + "<span style='display: inline-block; width: 50px'>" + (record.status == 1 ? "通过" : "未通过") + "</span>"
                  + "</div>";
      }
      this.$alert(htmlText, '考试详情', {
        dangerouslyUseHTMLString: true
      });
    },
    resetExamResult (row) {
      //只能将 ”已通过" 改成 "未通过"
      if (row.pass != "通过") {
        return;
      }

      this.$confirm('将司机' + row.no + '的考试成绩改成不通过, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/admin/train/stat/exam/reset", {testerId: row.driverId, bankId: this.queryObj.bankId}).then(res=> {
          if (res.code == 20) {
            row.pass = "未通过";
            this.$message({type: "success", message: "修改成功。"});
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    }
  }
};
</script>

<style scoped>
.query {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: right;
}
</style>