<template>
  <div class="box">
    <el-form ref="form" size="small" :model="param" inline>
      <el-row>
        <el-col>
          <el-form-item label="车队：">
            <el-select
              v-model="param.teamId"
              style="width: 160px; margin-right: 8px"
              @change="query()"
            >
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始日期：" prop="startDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.periodStart"
              style="width: 160px; margin-right: 8px"
              type="date"
              :clearable="true"
              placeholder="选择日期"
              @change="query()"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期：" prop="endDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="param.periodEnd"
              style="width: 160px; margin-right: 8px"
              type="date"
              :clearable="true"
              placeholder="选择日期"
              @change="query()"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 100px; margin-left: 60px" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="table">
      <el-table :data="list" stripe height="650" show-summary :summary-method="getSummaries">
        <!-- <el-table-column prop="totalBaseIncome" label="车队" width="90">
          <template slot-scope="scope">
            {{ scope.row.teamId | enumTransfer(teamOpts, "id", "name") }}
          </template>
        </el-table-column> -->
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="driverName" label="司机" width="80"></el-table-column>
        <el-table-column prop="driverNo" label="编号" width="60"></el-table-column>

        <el-table-column prop="totalIncome" label="总收入" width="100" sortable>
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="routeNum" label="边数" width="80" sortable></el-table-column>
        <el-table-column
          prop="routeValuePointsStr"
          label="行程积分"
          sortable
          width="100"
        ></el-table-column>
        <el-table-column prop="nightDays" label="过夜天数" sortable width="100"></el-table-column>

        <el-table-column prop="tollFee" label="过路费" width="100">
          <template slot-scope="scope">
            {{ scope.row.tollFee | moneyFormat(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="powerFee" label="电费补贴">
          <template slot-scope="scope">
            {{ scope.row.powerFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="baseSalary" label="基本收入">
          <template slot-scope="scope">
            {{ scope.row.baseSalary | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="floatingSalary" label="浮动收入">
          <template slot-scope="scope">
            {{ scope.row.floatingSalary | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="awardAmount" label="奖励金额" sortable width="120">
          <template slot-scope="scope">
            {{ scope.row.awardAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="punishAmount" label="处罚金额" sortable width="120">
          <template slot-scope="scope">
            {{ scope.row.punishAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="excessLeaveFee" label="超假扣费">
          <template slot-scope="scope">
            {{ scope.row.excessLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="abnormalLeaveFee" label="非常规请假扣费" width="120">
          <template slot-scope="scope">
            {{ scope.row.abnormalLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherSubsidyAmount" label="其他补贴金额" width="120">
          <template slot-scope="scope">
            {{ scope.row.otherSubsidyAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherFeeReissue" label="其他费用补发" width="120">
          <template slot-scope="scope">
            {{ scope.row.otherFeeReissue | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="nightSubsidy" label="过夜补贴">
          <template slot-scope="scope">
            {{ scope.row.nightSubsidy | moneyFormat(0) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getDailySummaryList } from "@/api";
import { mapState } from "vuex";
import { DateUtil } from "@/util";

export default {
  components: {},
  props: {},
  created() {},
  mounted() {
    this.query();
  },
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teamOpts: (state) => state.teamOpts,
      auditStateOpts: (state) => state.enumMap["DRIVER_SETTLEMENT_AUDIT_STATE"],
      settlementModeOpts: (state) => state.enumMap["SETTLEMENT_MODE"],
      settlementSummaryTypeOpts: (state) => state.enumMap["SETTLEMENT_SUMMARY_TYPE"],
    }),
  },
  data() {
    return {
      param: {
        periodStart: DateUtil.format(DateUtil.getMonthFirstDay(), "yyyy-MM-dd"),
        periodEnd: DateUtil.getDayStr(0),
        teamId: 4,
      },
      list: [],
      visible: false,
      form: {},
      selectIds: [],
    };
  },
  methods: {
    query() {
      getDailySummaryList(this.param).then((res) => {
        this.list = res.data || [];
      });
    },

    handleSelectionChange(val) {
      this.selectIds = val;
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：";
          return;
        }
        const property = column.property;

        const unValFields = [
          "driverNo",
          "summaryType",
          "periodStart",
          "auditState",
          "withdrawn",
          "commission",
        ];
        if (unValFields.includes(column.property)) {
          sums[index] = "";
          return;
        }

        // 不需要除以100的字段
        const oriArr = [
          "routeNum",
          "routeValuePoints",
          "nightDays",
          "convertedNumber",
          "guaranteeNumber",
          "subsidyNum",
        ];
        const values = data.map((item) => {
          if (oriArr.indexOf(property) > -1) {
            return Number(item[property]);
          } else {
            return Number(item[property] / 100);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result ? result.toFixed(2) : 0;
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-table {
  padding: 0 20px;
}

.period {
  max-width: 500px;
  overflow: auto;

  span {
    margin-right: 5px;
  }
}
</style>
