<template>
  <div class="root-box">
    <div>
      <div class="item">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        <el-select v-model="param.rsId" clearable style="margin-left: 16px">
          <el-option
            v-for="item in resourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          class="query-btn"
          style="margin-left: 20px"
          type="primary"
          @click="query"
          >查询</el-button
        >
      </div>
    </div>
    <div class="table">
      <el-table :data="trackDetails" style="width: 100%">
        <el-table-column prop="rsName" label="资源名称"> </el-table-column>
        <el-table-column prop="curVersn" label="版本号"> </el-table-column>
        <el-table-column prop="optTime" label="访问时间"> </el-table-column>
        <el-table-column prop="duration" label="访问时长"> </el-table-column>
        <el-table-column prop="agent" label="客户端"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { DateUtil } from '@/util';
import {optTrackMetas, userOptTrackDetail} from '@/api/track.js';
export default {
  name: "TrackDetail",
  data () {
    return {
      resourceList: [],
      param: {
        startDate: null,
        endDate: null,
        rsId: null
      },
      trackDetails: []
    };
  },
  mounted () {
    this.param.startDate = DateUtil.format(DateUtil.getDateTime(-2));
    this.param.endDate = DateUtil.format(DateUtil.getDateTime());
    optTrackMetas({"rsType": "page"}).then((res) => {
      let list = res.data || [];
      list.forEach(rsInfo => {
        var result = {value: rsInfo.id, label: rsInfo.name};
        this.resourceList.push(result);
      });
    });
  },
  methods: {
    query () {
      userOptTrackDetail(this.param).then(res => {
        let list = res.data || [];
        this.trackDetails = list;
      });
    }
  }
};
</script>

<style scoped>
.root-box {
  background-color: #edf1f5;
}

.item {
  margin-bottom: 10px;
}
</style>