<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="true"
    :close-on-click-modal="false"
    :fullscreen="true"
    :append-to-body="true"
    @opened="getData"
    :before-close="beforeClose"
    :destroy-on-close="false"
  >
    <div v-if="data">
      <Plan
        ref="plan"
        :data="data"
        :needShow2MatchBtn="needShow2MatchBtn"
        :refresh="getData"
        :closeFun="closeFun"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getRoutePlan } from "@/api";
import Plan from "./Plan.vue";
export default {
  components: {
    Plan,
  },

  data() {
    return {
      visible: false,
      data: null,
      needShow2MatchBtn: false,
    };
  },

  methods: {
    show(route, needShow) {
      this.needShow2MatchBtn = needShow;
      this.route = route;
      this.visible = true;
    },

    getData() {
      this.data = null;
      const { id } = this.route;
      getRoutePlan(id).then((res) => {
        this.data = res.data;
      });
    },

    closeFun() {
      this.visible = false;
    },

    beforeClose(done) {
      done && done();
      this.data = null;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  padding: 0;
}
</style>
