<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-row>
          <el-form-item label="用户类型：">
            <el-select
              v-model="form.userType"
              placeholder="请选择"
              clearable
              style="width: 200px"
              @change="query()"
            >
              <el-option
                v-for="item in userTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="客户端类型：">
            <el-select
              v-model="form.client"
              placeholder="请选择"
              clearable
              style="width: 200px"
              @change="query()"
            >
              <el-option
                v-for="item in clientTypeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="操作用户：">
            <el-input v-model="form.userId" clearable style="width: 200px" />
          </el-form-item>

          <el-form-item label="错误内容：">
            <el-input v-model="form.errMsg" clearable style="width: 200px" />
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="开始时间：">
            <el-date-picker
              placeholder="选择时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.startTime"
              style="width: 200px"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束时间：">
            <el-date-picker
              placeholder="选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              v-model="form.endTime"
              style="width: 200px"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-left: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="errMsg" label="错误内容">
          <template slot-scope="scope">
            <div class="long-text">{{ scope.row.errMsg }}</div>
            <span class="text-blue pointer" @click="showText(scope.row.errMsg)">查看详情</span>
          </template>
        </el-table-column>

        <el-table-column prop="deviceInfo" label="设备信息" width="300"></el-table-column>

        <el-table-column prop="client" label="客户端类型" width="140">
          <template slot-scope="scope">{{
            scope.row.client | enumTransfer(clientTypeOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="version" label="版本" width="140"> </el-table-column>

        <el-table-column prop="userType" label="用户角色" width="140">
          <template slot-scope="scope">{{
            scope.row.userType | enumTransfer(userTypeOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="userId" label="用户" width="140"> </el-table-column>

        <el-table-column prop="createTime" label="操作时间" width="200"></el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog title="查看数据" :visible="visible" width="1000px" :before-close="handleClose">
      <el-input type="textarea" :rows="24" v-model="errorMsg"></el-input>
    </el-dialog>
  </div>
</template>

<script>
import { getClientErrLog } from "@/api";
import { ParamUtil, DateUtil } from "@/util";
import { mapState } from "vuex";

export default {
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
    }),
  },
  data() {
    return {
      form: {
        startTime: DateUtil.getDateTimeStr(0, "yyyy-MM-dd 00:00:00"),
        endTime: null,
      },
      list: [],
      total: 0,
      currentPage: 1,
      clientTypeOpts: [
        {
          label: "IOS",
          value: "IOS",
        },
        {
          label: "ANDROID",
          value: "ANDROID",
        },
        {
          label: "小程序",
          value: "MP",
        },
      ],
      userTypeOpts: [
        {
          label: "乘客端",
          value: "passenger",
        },
        {
          label: "司机端",
          value: "driver",
        },
      ],
      visible: false,
      errorMsg: null,
    };
  },
  methods: {
    query(page = 1) {
      const _form = ParamUtil.filterEmptyData(this.form);
      const param = {
        page: {
          current: page,
        },
        param: _form,
        startTime: _form.startTime,
        endTime: _form.endTime,
      };
      getClientErrLog(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },
    handleCurrentChange(page) {
      this.query(page);
    },
    showText(json) {
      this.visible = true;
      this.errorMsg = json;
    },

    handleClose() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="less">
.long-text {
  max-height: 50px;
  text-overflow: ellipsis;
  line-height: 30px;
  display: inline-block;
}

.pointer {
  flex: 1;
  line-height: 30px;
  color: #4894ef;
  margin-left: 10px;
}

.head-box {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.1);

  .btn-add {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.condition {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
