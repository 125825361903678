<template>
  <div class="box">
    <el-form ref="form" size="small" :model="param" inline>
      <el-row>
        <el-col :span="24">
          <el-form-item label="车队：">
            <el-select v-model="param.teamId" style="width: 160px; margin-right: 8px" @change="query()">
              <el-option v-for="item in teamOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="结算类型：">
            <el-select v-model="param.summaryType" style="width: 160px; margin-right: 8px" @change="query()" clearable>
              <el-option v-for="item in settlementSummaryTypeOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="月结月份：" prop="settlementMonth">
            <el-date-picker value-format="yyyy-MM-01" v-model="param.settlementMonth" @change="query()"
              style="width: 160px; margin-right: 8px" type="month" :clearable="true"
              placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="审核状态：">
            <el-select v-model="param.auditState" clearable style="width: 160px; margin-right: 8px" @change="query()">
              <el-option v-for="item in auditStateOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始日期：" prop="startDate">
            <el-date-picker value-format="yyyy-MM-dd" v-model="param.periodStart"
              style="width: 160px; margin-right: 8px" type="date" :clearable="true" placeholder="选择日期"
              @change="query()"></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期：" prop="endDate">
            <el-date-picker value-format="yyyy-MM-dd" v-model="param.periodEnd" style="width: 160px; margin-right: 8px"
              type="date" :clearable="true" placeholder="选择日期" @change="query()"></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 100px; margin-left: 10px" @click="query()">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item>
            <el-button type="success" plain size="small" style="width: 120px" @click="auditSummary">审核通过</el-button>
            <el-button type="error" plain size="small" style="width: 120px" @click="delSummary">删除审核</el-button>
            <el-button type="primary" plain size="small" style="width: 120px" @click="genSummary">生成本月结算</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="table">
      <el-table v-if="param.teamId === 4" key="table1" :data="list" stripe max-height="600" show-summary
        :summary-method="getSummaries" @selection-change="handleSelectionChange">
        <!-- <el-table-column prop="totalBaseIncome" label="车队" width="90">
          <template slot-scope="scope">
            {{ scope.row.teamId | enumTransfer(teamOpts, "id", "name") }}
          </template>
</el-table-column> -->
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
        <el-table-column prop="driverName" label="司机" width="80"></el-table-column>
        <el-table-column prop="driverNo" label="编号" width="60"></el-table-column>

        <el-table-column prop="summaryType" label="结算类型">
          <template slot-scope="scope">
            {{ scope.row.summaryType | enumTransfer(settlementSummaryTypeOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="periodStart" label="周期" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.periodStart }}</div>
            <div>{{ scope.row.periodEnd }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="auditState" label="审核状态">
          <template slot-scope="scope">
            {{ scope.row.auditState | enumTransfer(auditStateOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="withdrawn" label="已提现/提现成功" width="140">
          <template slot-scope="scope">
            {{ scope.row.withdrawn ? "是" : "否" }} / {{ scope.row.withdrawnSuccess ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncome" label="总收入" width="100">
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="routeNum" label="边数" width="80" sortable></el-table-column>
        <el-table-column prop="routeValuePointsStr" label="行程积分" sortable></el-table-column>
        <el-table-column prop="nightDays" label="过夜天数" sortable></el-table-column>

        <el-table-column prop="tollFee" label="过路费补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.tollFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="powerFee" label="电费补贴" sortable>
          <template slot-scope="scope">
            {{ scope.row.powerFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="baseSalary" label="基本收入" sortable>
          <template slot-scope="scope">
            {{ scope.row.baseSalary | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="floatingSalary" label="浮动收入">
          <template slot-scope="scope">
            {{ scope.row.floatingSalary | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="nightSubsidy" label="过夜补贴">
          <template slot-scope="scope">
            {{ scope.row.nightSubsidy | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="carRental" label="车辆补贴">
          <template slot-scope="scope">
            {{ scope.row.carRental | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="excessLeaveFee" label="超假扣费">
          <template slot-scope="scope">
            {{ scope.row.excessLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="abnormalLeaveFee" label="非常规请假扣费">
          <template slot-scope="scope">
            {{ scope.row.abnormalLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="awardAmount" label="奖励金额">
          <template slot-scope="scope">
            {{ scope.row.awardAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="punishAmount" label="处罚金额">
          <template slot-scope="scope">
            {{ scope.row.punishAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherSubsidyAmount" label="其他补贴金额">
          <template slot-scope="scope">
            {{ scope.row.otherSubsidyAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherFeeReissue" label="其他费用补发">
          <template slot-scope="scope">
            {{ scope.row.otherFeeReissue | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherDeduction" label="其他扣款">
          <template slot-scope="scope">
            {{ scope.row.otherDeduction | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="200" label="创建时间"></el-table-column>

        <el-table-column prop="id" label="操作" align="right" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" :disabled="!scope.row.withdrawn"
              @click="viewTask(scope.row.id)">提现结果</el-button>
            <el-button type="text" size="small" :disabled="scope.row.auditState === 1"
              @click="reGen(scope.row.id)">重新生成</el-button>
            <el-button type="text" size="small" @click="showSummaryDetails(scope.row)">结算明细</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-table v-else key="table2" :data="list" stripe max-height="600" show-summary :summary-method="getSummaries"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column prop="totalBaseIncome" label="车队" width="90">
          <template slot-scope="scope">
            {{ scope.row.teamId | enumTransfer(teamOpts, "id", "name") }}
          </template>
        </el-table-column> -->
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="driverName" label="司机" width="80"></el-table-column>
        <el-table-column prop="driverNo" label="编号" width="60"></el-table-column>

        <el-table-column prop="summaryType" label="结算类型">
          <template slot-scope="scope">
            {{ scope.row.summaryType | enumTransfer(settlementSummaryTypeOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="periodStart" label="周期" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.periodStart }}</div>
            <div>{{ scope.row.periodEnd }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="auditState" label="审核状态">
          <template slot-scope="scope">
            {{ scope.row.auditState | enumTransfer(auditStateOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="withdrawn" label="已提现/提现成功" width="140">
          <template slot-scope="scope">
            {{ scope.row.withdrawn ? "是" : "否" }} / {{ scope.row.withdrawnSuccess ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column prop="totalIncome" label="总收入" width="100">
          <template slot-scope="scope">
            {{ scope.row.totalIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="routeNum" label="边数" width="80" sortable></el-table-column>

        <el-table-column prop="baseIncome" label="抽成基础收入" width="90">
          <template slot-scope="scope">
            {{ scope.row.baseIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="commission" label="抽成比例">
          <template slot-scope="scope"> {{ scope.row.commission }}% </template>
        </el-table-column>

        <el-table-column prop="commissionAmount" label="抽成金额" width="90">
          <template slot-scope="scope">
            {{ scope.row.commissionAmount | moneyFormat(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="convertedNumber" label="保底折算人数"></el-table-column>
        <el-table-column prop="guaranteeNumber" label="应保底人数"></el-table-column>

        <el-table-column prop="increaseIncome" label="加价收入" width="90">
          <template slot-scope="scope">
            {{ scope.row.increaseIncome | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="attendance" label="上座率">
          <template slot-scope="scope">
            <span v-if="scope.row.attendance"> {{ scope.row.attendance }}%</span>
          </template>
        </el-table-column>

        <el-table-column prop="subsidyNum" label="补贴人数" width="60"></el-table-column>

        <el-table-column prop="attendance" label="补贴金额">
          <template slot-scope="scope">
            {{ scope.row.subsidyAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="shuntFee" label="分流费用">
          <template slot-scope="scope">
            {{ scope.row.shuntFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="supplementaryAmount" label="补单金额">
          <template slot-scope="scope">
            {{ scope.row.supplementaryAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="excessLeaveFee" label="超假扣费">
          <template slot-scope="scope">
            {{ scope.row.excessLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="abnormalLeaveFee" label="非常规请假扣费">
          <template slot-scope="scope">
            {{ scope.row.abnormalLeaveFee | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="awardAmount" label="奖励金额">
          <template slot-scope="scope">
            {{ scope.row.awardAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="punishAmount" label="处罚金额">
          <template slot-scope="scope">
            {{ scope.row.punishAmount | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="otherDeduction" label="其他扣款">
          <template slot-scope="scope">
            {{ scope.row.otherDeduction | moneyFormat(0) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column prop="id" label="操作" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" :disabled="!scope.row.withdrawn"
              @click="viewTask(scope.row.id)">提现结果</el-button>
            <el-button type="text" size="small" :disabled="scope.row.auditState === 1"
              @click="reGen(scope.row.id)">重新生成</el-button>
            <el-button type="text" size="small" @click="showSummaryDetails(scope.row)">结算明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible="detailFormVisible" width="560px" :show-close="false" :close-on-click-modal="false">
      <el-form ref="form" :model="detailForm" size="small" label-width="120px">
        <el-form-item prop="teamId" label="司机：">
          <el-input :value="detailForm.driverNo" style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item label="月结月份：" prop="settlementMonth">
          <el-date-picker value-format="yyyy-MM-01" type="month" style="width: 200px" disabled
            :value="detailForm.settlementMonth" placeholder="选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item prop="settlementType" label="结算类型：">
          <el-select v-model="detailForm.settlementType" style="width: 200px">
            <el-option v-for="item in addSettlementTypeOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="amount" label="金额：">
          <el-input-number v-model="detailForm.amount" style="width: 200px"></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button type="info" @click.stop="detailFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click.stop="saveDetail" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-drawer size="1700px" :visible.sync="taskVisible" :append-to-body="true" direction="ltr">
      <div slot="title">提现任务执行情况：</div>

      <div class="drawer-table">
        <el-table :data="taskList" stripe max-height="780">
          <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

          <el-table-column prop="tryTimes" label="执行次数"></el-table-column>

          <el-table-column prop="createTime" label="创建时间"></el-table-column>

          <el-table-column prop="lastExecTime" label="最近一次执行时间"></el-table-column>

          <el-table-column prop="amount" label="支付金额">
            <template slot-scope="scope">
              {{ scope.row.amount | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="tradeNo" label="交易单号"></el-table-column>
          <el-table-column prop="errMsg" label="错误信息"></el-table-column>
          <el-table-column prop="errCode" label="错误码"></el-table-column>
          <el-table-column prop="returnMsg" label="返回信息"></el-table-column>

          <el-table-column prop="succeed" label="转账成功">
            <template slot-scope="scope">
              <span :class="{ red: !scope.row.succeed }">{{
                scope.row.succeed ? "成功" : "失败"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="transDate" label="订单支付时间"></el-table-column>
          <el-table-column prop="aliPayAccount" label="支付宝账号"></el-table-column>
          <el-table-column prop="aliPayName" label="支付宝姓名"></el-table-column>
        </el-table>
      </div>

      <div class="task-btn-box" v-if="taskList.length">
        <el-button type="primary" size="small" :disabled="taskList[0].withdrawnSuccess"
          @click="execTask()">再次执行提现</el-button>
      </div>
    </el-drawer>

    <el-drawer size="1700px" :visible.sync="detailVisible" :append-to-body="true" direction="ltr">
      <div slot="title">
        结算明细：
        <span v-if="selectedSummary" style="margin-right: 20px">
          {{ selectedSummary.driverName }} {{ selectedSummary.driverNo }}
        </span>

        <el-button type="primary" plain size="small" style="width: 120px" @click="addSettlementDetail">新增明细</el-button>
      </div>

      <div class="drawer-table">
        <el-table :data="detailList" stripe max-height="780">
          <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

          <el-table-column prop="date" label="结算日期">
            <template slot-scope="scope">
              {{ scope.row.date || scope.row.settlementMonth }}
            </template>
          </el-table-column>

          <el-table-column prop="settlementType" label="结算类型">
            <template slot-scope="scope">
              {{ scope.row.settlementType | enumTransfer(settlementTypeOpts) }}
            </template>
          </el-table-column>

          <el-table-column prop="amount" label="总金额">
            <template slot-scope="scope">
              <span v-if="!scope.row.edit" @dblclick="showEdit(scope.row)">
                {{ scope.row.amount | moneyFormat(0) }}</span>
              <el-input-number v-else v-model="scope.row.amount" size="mini" style="width: 180px"></el-input-number>
            </template>
          </el-table-column>

          <el-table-column prop="tollFee" label="过路费">
            <template slot-scope="scope">
              {{ scope.row.tollFee | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="powerFee" label="电费">
            <template slot-scope="scope">
              {{ scope.row.powerFee | moneyFormat(0) }}
            </template>
          </el-table-column>

          <el-table-column prop="routeValuePoints" label="行程积分"></el-table-column>

          <el-table-column prop="createTime" label="创建时间"></el-table-column>

          <el-table-column prop="id" label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="updateDetail(scope.row)">更新</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getSummaryList,
  auditSummaryList,
  getSummaryTaskResult,
  execSummaryTask,
  deleteSummaryList,
  genSummaryList,
  reGenSummary,
  getSummaryDetailList,
  addSettlementDetail,
  updateSettlementDetail,
} from "@/api";
import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  created() { },
  mounted() {
    this.query();
  },
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teamOpts: (state) => state.teamOpts,
      auditStateOpts: (state) => state.enumMap["DRIVER_SETTLEMENT_AUDIT_STATE"],
      settlementModeOpts: (state) => state.enumMap["SETTLEMENT_MODE"],
      settlementSummaryTypeOpts: (state) => state.enumMap["SETTLEMENT_SUMMARY_TYPE"],
      settlementTypeOpts: (state) => state.enumMap["SETTLEMENT_TYPE"],
      addSettlementTypeOpts: (state) =>
        state.enumMap["SETTLEMENT_TYPE"] &&
        state.enumMap["SETTLEMENT_TYPE"].filter((t) => [5, 12, 13].includes(t.value)),
    }),
  },
  data() {
    return {
      param: {
        teamId: 4,
        auditState: 0,
      },
      list: [],
      visible: false,
      detailVisible: false,
      form: {},
      selectIds: [],
      taskVisible: false,
      taskList: [],
      detailList: [],
      detailFormVisible: false,
      detailForm: {},
      selectedSummary: null,
    };
  },
  methods: {
    query() {
      getSummaryList(this.param).then((res) => {
        this.list = res.data || [];
      });
    },

    reGen(id) {
      this.$confirm("确认重新生成吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        reGenSummary(id).then(() => {
          this.$message.success("重新生成成功");
          this.query();
        });
      });
    },

    updateDetail(item) {
      this.$confirm("确认更新吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateSettlementDetail(item).then(() => {
          this.$message.success("更新成功");
          getSummaryDetailList(this.selectedSummary.id).then((res) => {
            this.detailList = res.data;
          });
        });
      });
    },

    showEdit(row) {
      const list = [...this.detailList];
      list.forEach((item) => {
        if (item.id === row.id) {
          item.edit = true;
        }
      });
      this.detailList = [];
      this.$nextTick(() => {
        this.detailList = list;
      });
    },

    addSettlementDetail() {
      const { driverNo, driverName, driverId, teamId, settlementMonth } = this.selectedSummary;
      this.detailForm = {
        driverId,
        teamId,
        driverNo,
        driverName,
        settlementMonth,
      };
      this.detailFormVisible = true;
    },

    saveDetail() {
      const summaryId = this.selectedSummary.id;
      addSettlementDetail(summaryId, this.detailForm).then(() => {
        this.$message.success("保存成功");
        this.detailFormVisible = false;
      });
    },

    showSummaryDetails(item) {
      this.selectedSummary = item;
      getSummaryDetailList(item.id).then((res) => {
        this.detailList = res.data;
        this.detailVisible = true;
      });
    },

    openReBatchGenModal() {
      this.reGenVisible = true;
      this.genForm = {};
    },

    viewTask(summaryId) {
      getSummaryTaskResult(summaryId).then((res) => {
        this.taskList = [res.data];
        this.taskVisible = true;
      });
    },

    execTask() {
      const item = this.taskList[0];
      execSummaryTask(item.id).then(() => {
        this.$message.success("执行成功");
      });
    },

    handleSelectionChange(val) {
      this.selectIds = val;
    },

    auditSummary() {
      const ids = this.selectIds.map((item) => item.id);
      if (ids.length) {
        this.$confirm("确认审核通过吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          auditSummaryList(ids).then(() => {
            this.$message.success("审核成功");
            this.query();
          });
        });
      } else {
        this.$message.error("请选择要审核的数据");
      }
    },

    delSummary() {
      const ids = this.selectIds.map((item) => item.id);
      if (ids.length) {
        this.$confirm("确认删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteSummaryList(ids).then(() => {
            this.$message.success("删除成功");
            this.query();
          });
        });
      } else {
        this.$message.error("请选择要审核的数据");
      }
    },

    genSummary() {
      this.$confirm("确认生成本月结算吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        genSummaryList().then(() => {
          this.$message.success("生成成功");
          this.query();
        });

      });
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        }
        if (index === 1) {
          sums[index] = "合计：";
          return;
        }
        const property = column.property;

        const unValFields = [
          "driverNo",
          "summaryType",
          "periodStart",
          "auditState",
          "withdrawn",
          "commission",
        ];
        if (unValFields.includes(column.property)) {
          sums[index] = "";
          return;
        }

        // 不需要除以100的字段
        const oriArr = [
          "routeNum",
          "routeValuePoints",
          "nightDays",
          "convertedNumber",
          "guaranteeNumber",
          "subsidyNum",
        ];
        const values = data.map((item) => {
          if (oriArr.indexOf(property) > -1) {
            return Number(item[property]);
          } else {
            return Number(item[property] / 100);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result ? result.toFixed(2) : 0;
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-table {
  padding: 0 20px;
}

.period {
  max-width: 500px;
  overflow: auto;

  span {
    margin-right: 5px;
  }
}

.task-btn-box {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
</style>
