<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="姓名：">
          <el-input v-model="param.name" clearable />
        </el-form-item>

        <el-form-item label="司机编号：">
          <el-input v-model="param.no" clearable />
        </el-form-item>

        <el-form-item label="电话：">
          <el-input v-model="param.telephone" clearable />
        </el-form-item>

        <el-form-item label="车队">
          <el-select v-model="param.teamId" clearable size="small" @change="query()">
            <el-option
              v-for="item in teamOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态：">
          <el-select v-model="param.status" placeholder="请选择" @change="query()" clearable>
            <el-option
              v-for="item in driverStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="680">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="name" label="姓名"></el-table-column>

        <el-table-column prop="no" label="司机编号"></el-table-column>

        <el-table-column prop="telephone" width="120" label="电话">
          <template slot-scope="scope">
            <span v-click-tel="scope.row.telephone">{{
              scope.row.telephone || scope.row.telephoneBak | encryptTel
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="seats" label="车座">
          <template slot-scope="scope">
            <span>{{ scope.row.seats }}座</span>
          </template>
        </el-table-column>

        <el-table-column prop="etcSignStatus" label="ETC签约状态" width="140"></el-table-column>

        <el-table-column prop="teamId" label="所属车队">
          <template slot-scope="scope">
            <span>{{ scope.row.teamId | enumTransfer(teamOpts, "id", "name") }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="vehicleNo" width="140" label="车牌号"></el-table-column>

        <el-table-column prop="commission" width="120" label="车型">
          <template slot-scope="scope">
            <span>{{ scope.row.vehicle_color }}{{ scope.row.brand }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="remark"
                         label="备注"></el-table-column> -->

        <el-table-column prop="contractingAreaId" label="签约城市">
          <template slot-scope="scope">{{
            scope.row.contractingAreaId | enumTransfer(areaOpts, "id", "name")
          }}</template>
        </el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | enumTransfer(driverStatusOpts) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="baseSalary" label="基本工资"></el-table-column>

        <el-table-column prop="carRental" label="车租"></el-table-column>
        <el-table-column prop="firstCarRentalDate" label="保底积分">
          <template slot-scope="scope">
            <span>{{ scope.row.basePoints ? scope.row.basePoints / 100 : 44 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="maxLeaveDaysPerMonth" label="月休天数">
          <template slot-scope="scope">
            <span>{{ scope.row.maxLeaveDaysPerMonth ? scope.row.maxLeaveDaysPerMonth : 4 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="registerTime" label="注册时间" width="160"></el-table-column>
        <el-table-column prop="lastWorkDate" label="退出日期" width="120"></el-table-column>

        <el-table-column prop="vehicleTypeId" width="110" label="车型">
          <template slot-scope="scope">
            <span>{{
              scope.row.vehicleTypeId | enumTransfer(vehicleTypeOpts, "id", "content")
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="attendance" label="上座率">
          <template slot-scope="scope">
            <span>{{ scope.row.attendance }}‰</span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="settlementMode" width="100" label="结算方式">
          <template slot-scope="scope">{{
            scope.row.settlementMode | enumTransfer(settlementModeOpts)
          }}</template>
        </el-table-column> -->

        <!-- <el-table-column prop="commission" label="提成比例">
          <template slot-scope="scope">
            <span>{{ scope.row.commission }}‰</span>
          </template>
        </el-table-column> -->

        <el-table-column prop="limitPathIds" label="限制区域">
          <template slot-scope="scope">{{
            scope.row.limitPathIds | enumTransfer(pathOpts, "id", "id")
          }}</template>
        </el-table-column>

        <el-table-column prop="id" label="操作" width="130" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="go2RouteList(scope.row)"
              >订单记录</el-button
            >

            <el-dropdown
              @command="(command) => handleCommand(command, scope.row)"
              style="margin-left: 10px"
              class="dropdown-meun"
            >
              <span class="el-dropdown-link">
                操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">{{
                  scope.row.status === 2 ? "解封账号" : "封禁账号"
                }}</el-dropdown-item>

                <el-dropdown-item command="2">成为代理</el-dropdown-item>
                <el-dropdown-item command="3">司机详情</el-dropdown-item>
                <el-dropdown-item command="4">账号解锁</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="5">修改信息</el-dropdown-item>
                <!-- <el-dropdown-item v-if="isManager" command="6">新增合同</el-dropdown-item>-->
                <el-dropdown-item v-if="isManager" command="7">ETC签约</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="11">ETC解绑</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="8">绑定车辆</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="10">录入人证信息</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="9">上报数据</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="12">查看监控</el-dropdown-item>
                <el-dropdown-item command="13">重置密码</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="14">ETC行程查询</el-dropdown-item>
                <el-dropdown-item v-if="isManager" command="15">ETC欠费查询</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="currentPage"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :visible="visible" title="修改司机信息" width="900px">
      <div>
        <el-form
          ref="form"
          :model="form"
          label-width="150px"
          size="small"
          :inline="true"
          :rules="rules"
        >
          <el-form-item label="手机号码：" prop="telephone">
            <el-input v-model="form.telephone" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="司机编号：" prop="no">
            <el-input v-model="form.no" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="所属车队：" prop="teamId">
            <el-select v-model="form.teamId" style="width: 200px" placeholder="请选择">
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="月休天数：" prop="maxLeaveDaysPerMonth">
            <el-input-number
              v-model="form.maxLeaveDaysPerMonth"
              style="width: 200px"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="车坐数：" prop="seats">
            <el-input-number v-model="form.seats" style="width: 200px"></el-input-number>
          </el-form-item>

          <el-form-item label="车型：" prop="vehicleTypeId">
            <el-select v-model="form.vehicleTypeId" style="width: 200px">
              <el-option
                v-for="item in vehicleTypeOpts"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="签约城市：" prop="contractingAreaId">
            <el-select v-model="form.contractingAreaId" placeholder="请选择" style="width: 200px">
              <el-option
                v-for="item in areaOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="结算方式：" prop="settlementMode">
            <el-select v-model="form.settlementMode" style="width: 200px">
              <el-option
                v-for="item in settlementModeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="GPS_IMEI：" prop="gpsImei">
            <el-input v-model="form.gpsImei" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="监控ID：" prop="videoDeviceId">
            <el-input v-model="form.videoDeviceId" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="基本工资：" prop="baseSalary">
            <el-input-number v-model="form.baseSalary" style="width: 200px"></el-input-number>
          </el-form-item>

          <el-form-item label="保底积分(*100)：" prop="basePoints">
            <el-input-number
              :min="4400"
              v-model="form.basePoints"
              style="width: 200px"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="车月租：" prop="carRental">
            <el-input-number v-model="form.carRental" style="width: 200px"></el-input-number>
          </el-form-item>

          <!-- <el-form-item label="首次车租打款日期：" prop="firstCarRentalDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.firstCarRentalDate"
              style="width: 200px"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item> -->

          <el-form-item label="退出日期：" prop="lastWorkDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.lastWorkDate"
              style="width: 200px"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="限制路线：" prop="limitPathIds">
            <el-select v-model="form.limitPathIds" style="width: 560px" multiple>
              <el-option
                v-for="item in pathOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="modifyInfo" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="contactVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :title="isAddContact ? '新增合同' : '修改合同'"
      top="40px"
      width="1000px"
    >
      <el-form
        ref="contractForm"
        :model="contractForm"
        size="small"
        :rules="contractRules"
        inline
        label-position="top"
      >
        <div class="step1">
          <el-divider content-position="left">合同基本信息</el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="合同扫描件1：" prop="picture1">
                <image-upload v-model="contractForm.picture1" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同扫描件2：" prop="picture2">
                <image-upload v-model="contractForm.picture2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="合同签订日期：" prop="signDate">
                <el-date-picker
                  placeholder="选择时间"
                  value-format="yyyy-MM-dd 00:00:00"
                  v-model="contractForm.signDate"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同有效期起：" prop="contractOn">
                <el-date-picker
                  placeholder="选择时间"
                  value-format="yyyy-MM-dd"
                  v-model="contractForm.contractOn"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同有效期止：" prop="contractOff">
                <el-date-picker
                  placeholder="选择时间"
                  value-format="yyyy-MM-dd"
                  v-model="contractForm.contractOff"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-divider content-position="left">合同费用信息</el-divider>

          <el-row>
            <el-col :span="6">
              <el-form-item label="提成比例（‰）：" prop="commission">
                <el-input-number
                  v-model="contractForm.commission"
                  :min="100"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="最高提成比例（‰）：" prop="maxCommission">
                <el-input-number
                  v-model="contractForm.maxCommission"
                  :min="100"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="最低提成比例（‰）：" prop="minCommission">
                <el-input-number
                  v-model="contractForm.minCommission"
                  :min="50"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上座率（‰）：" prop="attendance">
                <el-input-number
                  v-model="contractForm.attendance"
                  :min="500"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="月度管理费：" prop="monthlyManagementFees">
                <el-input-number
                  v-model="contractForm.monthlyManagementFees"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="年度管理费：" prop="annualManagementFees">
                <el-input-number
                  v-model="contractForm.annualManagementFees"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押金：" prop="deposit">
                <el-input-number
                  v-model="contractForm.deposit"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="closeContractModal">取 消</el-button>
        <el-button type="primary" @click="saveContract" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="carInfoVisible"
      :close-on-click-modal="false"
      title="修改车辆信息"
      top="40px"
      width="1400px"
    >
      <el-form
        ref="carInfoForm"
        :model="carInfoForm"
        size="small"
        :rules="carInfoRules"
        inline
        label-position="top"
      >
        <div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="行驶证正本：">
                <image-upload v-model="carInfoForm.drivingLicensePic1" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="行驶证副本：">
                <image-upload v-model="carInfoForm.drivingLicensePic2" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车辆左前方照片：">
                <image-upload v-model="carInfoForm.carPic1" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="车辆侧面照片：">
                <image-upload v-model="carInfoForm.carPic2" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车辆正后方照片：">
                <image-upload v-model="carInfoForm.carPic3" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="行驶里程：" prop="totalMile">
                <el-input-number
                  v-model="carInfoForm.totalMile"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="车牌号：">
                <el-input v-model="carInfoForm.vehicleNo"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车辆所有人：">
                <el-input v-model="carInfoForm.ownerName"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="车辆型号：">
                <el-input v-model="carInfoForm.model"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车辆厂牌：" prop="brand">
                <el-input v-model="carInfoForm.brand"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车身颜色：" prop="vehicleColor">
                <el-select
                  v-model="carInfoForm.vehicleColor"
                  filterable
                  allow-create
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vehicleColorOpts"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="核定载客位（含司机）" prop="seats">
                <el-select v-model="carInfoForm.seats" placeholder="请选择">
                  <el-option
                    v-for="item in seatsOpts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item label="发动机号：">
                <el-input v-model="carInfoForm.engineId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车辆VIN码：">
                <el-input v-model="carInfoForm.vin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车辆注册日期：">
                <el-input v-model="carInfoForm.certifyDateA"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车辆燃料类型：" prop="fuelType">
                <el-select v-model="carInfoForm.fuelType" placeholder="请选择">
                  <el-option
                    v-for="item in fuelTypeOpts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="发动机排量：" prop="engineDisplace">
                <el-select v-model="carInfoForm.engineDisplace" placeholder="请选择">
                  <el-option
                    v-for="item in engineDisplaceOpts"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="下次年检日期：" prop="nextFixDate">
                <el-date-picker
                  placeholder="选择时间"
                  v-model="carInfoForm.nextFixDate"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="closeCarInfoModal">取 消</el-button>
        <el-button type="primary" @click="updateCarInfo" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="vehicleBindVisible"
      :close-on-click-modal="false"
      title="选择绑定车辆"
      width="600px"
    >
      <el-select
        style="width: 300px"
        :size="size"
        v-model="bindVehicleId"
        filterable
        remote
        clearable
        placeholder="请输入关键词"
        :remote-method="getVehicle"
        :loading="loading"
      >
        <el-option
          v-for="item in vehicleOptions"
          :key="item.id"
          :label="item.vehicleNo"
          :value="item.id"
        >
          <div style="display: flex; justify-content: space-between">
            <span style="flex: 1">{{ item.vehicleNo }}</span>
            <span style="flex: 1" class="red" v-if="!item.gpsImei">没有绑定GPS</span>
            <span style="flex: 1" class="red" v-if="item.bindDriverId"
              >已绑定司机{{ item.bindDriverName }}</span
            >
          </div>
        </el-option>
      </el-select>

      <span slot="footer">
        <el-checkbox v-model="forceBind" style="margin-right: 16px; color: red"
          >强制绑定</el-checkbox
        >
        <el-button type="warning" :size="size" @click="selectVehicle">确认</el-button>
        <el-button type="info" :size="size" @click="vehicleBindVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="detailVisible"
      :close-on-click-modal="false"
      title="修改司机详情"
      width="500px"
    >
      <el-form ref="driverDetail" :model="driverDetail" inline size="small" label-width="160px">
        <div>
          <el-form-item label="资格证号：">
            <el-input v-model="driverDetail.certificateNo"></el-input>
          </el-form-item>
          <el-form-item label="发证机构：">
            <el-input v-model="driverDetail.networkCarIssueOrganization"></el-input>
          </el-form-item>
          <el-form-item label="报备日期：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="driverDetail.registerDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="发证日期：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="driverDetail.networkCarIssueDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="初次领取资格证日期：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="driverDetail.getNetworkCarProofDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="资格证有效期起：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="driverDetail.networkCarProofOn"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="资格证有效期止：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="driverDetail.networkCarProofOff"
            ></el-date-picker>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="detailVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDetailInfo" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="etcRouteVisible"
      :close-on-click-modal="false"
      title="ETC通行记录"
      width="1000px"
    >
      <el-table :data="etcRouteList" stripe max-height="680">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="startStationName" label="高速入口" width="160"></el-table-column>
        <el-table-column prop="endStationName" label="高速出口" width="160"></el-table-column>
        <el-table-column prop="tripStartTime" label="进站时间" width="160"></el-table-column>
        <el-table-column prop="tripEndTime" label="出站时间" width="160"></el-table-column>
        <el-table-column prop="totalAmount" label="ETC费用" width="160"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="etcOwFeesVisible"
      :close-on-click-modal="false"
      title="ETC欠费记录"
      width="800px"
    >
      <el-table :data="etcOwFees" stripe max-height="680">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="outOrderId" label="outOrderId" width="160"></el-table-column>
        <el-table-column prop="bizAgreementNo" label="bizAgreementNo" width="160"></el-table-column>
        <el-table-column prop="tripId" label="tripId" width="160"></el-table-column>
        <el-table-column prop="payTime" label="支付时间" width="160"></el-table-column>
        <el-table-column prop="totalAmount" label="ETC费用" width="160"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDriverList,
  banDriver,
  transToPartner,
  unlockDriver,
  updateVehicle,
  modifyDriverCoreInfo,
  saveOrUpdateContract,
  getDriverDetail,
  bindVehicle,
  listVehicle,
  reportDriverData,
  updateDriverDetailInfo,
  startWaybill,
  endWaybill,
  resetDriverPwd,
  getDriverEtcRouteList,
  queryEtcOwFees,
} from "@/api";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import { ParamUtil } from "@/util";
import Router from "@/route";

export default {
  created() {
    const telephone = this.$route.query.tel;
    if (telephone) {
      if (telephone.length > 10) {
        this.$set(this.param, "telephone", telephone);
      } else {
        this.$set(this.param, "no", telephone);
      }
    }
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      isManager: (state) => state.isManager,
      driverStatusOpts: (state) => state.enumMap["DRIVER_STATUS"],
      settlementModeOpts: (state) => state.enumMap["SETTLEMENT_MODE"],
      teamOpts: (state) => state.teamOpts,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      areaOpts: (state) => state.areaOpts,
      pathOpts: (state) => state.pathList,
    }),
  },

  data() {
    return {
      visible: false,
      contactVisible: false,
      carInfoVisible: false,
      forceBind: false,
      etcRouteVisible: false,
      etcOwFeesVisible: false,
      param: {
        status: 1,
      },
      list: [],
      total: 0,
      currentPage: 1,
      form: {},
      contractForm: {},
      carInfoForm: {},
      isAddContact: true,
      yesNoOpts: BaseDataService.yesNoOpts(),
      rules: {
        telephone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          { pattern: /^1\d{10}$/, message: "请输入正确的手机号" },
        ],
        seats: [
          {
            required: true,
            message: "车坐数不能为空",
            trigger: "blur",
          },
        ],
        no: [
          {
            required: true,
            message: "司机编号不能为空",
            trigger: "blur",
          },
        ],
        teamId: [
          {
            required: true,
            message: "司机车队不能为空",
            trigger: "blur",
          },
        ],
        vehicleTypeId: [
          {
            required: true,
            message: "车型不能为空",
            trigger: "blur",
          },
        ],
      },
      contractRules: {
        signDate: [
          {
            required: true,
            message: "请输入合同签订日期",
            trigger: "blur",
          },
        ],
        contractOn: [
          {
            required: true,
            message: "请输入合同有效期起",
            trigger: "blur",
          },
        ],
        contractOff: [
          {
            required: true,
            message: "请输入合同有效期止",
            trigger: "blur",
          },
        ],
        commission: [
          {
            required: true,
            message: "请输入提成比例",
            trigger: "blur",
          },
        ],
        maxCommission: [
          {
            required: true,
            message: "请输入最高提成比例",
            trigger: "blur",
          },
        ],
        minCommission: [
          {
            required: true,
            message: "请输入最低提成比例",
            trigger: "blur",
          },
        ],
        attendance: [
          {
            required: true,
            message: "请输入上座率",
            trigger: "blur",
          },
        ],
      },
      carInfoRules: {},
      fit: "contain",
      fuelTypeOpts: BaseDataService.fuelTypeOpts(),
      seatsOpts: BaseDataService.seatsOpts(),
      vehicleColorOpts: BaseDataService.vehicleColorOpts(),
      engineDisplaceOpts: BaseDataService.engineDisplaceOpts(),
      vehicleBindVisible: false,
      vehicleOptions: [],
      bindVehicleId: null,
      loading: false,
      detailVisible: false,
      driverDetail: {},
      etcRouteList: [],
      etcOwFees: [],
    };
  },
  methods: {
    query(page = this.currentPage, pageSize = 10) {
      const param = {
        param: ParamUtil.filterEmptyData(this.param),
        page: {
          current: page,
          size: pageSize,
        },
      };
      getDriverList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    go2MonitorPage(item) {
      const { videoDeviceId } = item;
      const { href } = Router.resolve({
        path: "/video/monitor",
        query: { deviceNo: videoDeviceId },
      });
      window.open(href, "_blank");
    },

    getVehicle(key) {
      if (key) {
        this.loading = true;
        const param = {
          page: {
            current: 1,
            size: 100,
          },
          param: {
            vehicleNo: key,
          },
        };
        listVehicle(param).then((res) => {
          this.loading = false;
          const { records = [] } = res.data || {};
          this.vehicleOptions = records;
        });
      }
    },

    showVehicleList(item) {
      this.currentDriver = item;
      this.forceBind = false;
      this.vehicleBindVisible = true;
    },

    selectVehicle() {
      if (this.bindVehicleId) {
        bindVehicle(this.currentDriver.id, this.bindVehicleId, this.forceBind).then(() => {
          this.$message.success("绑定成功");
          this.vehicleBindVisible = false;
          this.query();
        });
      } else {
        this.$message.error("请选择要绑定的车辆");
      }
    },

    detail(item) {
      const { href } = this.$router.resolve({
        name: "driverDetail",
        query: { id: item.id },
      });
      window.open(href, "_blank");
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.query(1, pageSize);
    },

    banUser(item) {
      const str = item.status === 1 ? "封禁" : "解封";
      this.$confirm(`确认${str}该司机吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        banDriver(item.id).then(() => {
          this.query();
          this.$message.success("操作成功");
        });
      });
    },

    toBePartner(item) {
      this.$prompt("请输入微信场景ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]{5,}$/,
        inputErrorMessage: "微信场景ID不正确",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          transToPartner(item.id, value).then(() => {
            this.$message.success("操作成功");
          });
        })
        .catch(() => {});
    },

    go2RouteList(item) {
      const { href } = this.$router.resolve({
        name: "routeList",
        query: { id: item.id },
      });
      window.open(href, "_blank");
    },

    unlockAccount(item) {
      unlockDriver(item.id).then(() => {
        this.$message.success("账户已解锁");
      });
    },

    handleCommand(type, item) {
      if (type === "1") {
        this.banUser(item);
      } else if (type === "2") {
        this.toBePartner(item);
      } else if (type === "3") {
        this.detail(item);
      } else if (type === "4") {
        this.unlockAccount(item);
      } else if (type === "5") {
        const itemCopy = { ...item };
        if (!itemCopy.limitPathIds) {
          itemCopy.limitPathIds = [];
        }
        this.form = itemCopy;
        this.visible = true;
      } else if (type === "6") {
        this.openContractModal(item, true);
      } else if (type === "7") {
        this.startEtcSign(item);
      } else if (type === "8") {
        this.showVehicleList(item);
      } else if (type === "9") {
        this.$confirm("确认上报该司机数据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          reportDriverData(item.id).then(() => {
            this.$message.success("操作成功");
          });
        });
      } else if (type === "10") {
        this.openDetailModal(item);
      } else if (type == "11") {
        this.endEtcSign(item);
      } else if (type == "12") {
        this.go2MonitorPage(item);
      } else if (type == "13") {
        this.resetPwdFun(item);
      } else if (type == "14") {
        this.getEtcRouteList(item);
      } else if (type == "15") {
        this.getEtcOwFees(item);
      }
    },

    getEtcRouteList(driver) {
      getDriverEtcRouteList(driver.id).then((res) => {
        this.etcRouteList = res.data || [];
        this.etcRouteVisible = true;
      });
    },

    getEtcOwFees(driver) {
      queryEtcOwFees(driver.id).then((res) => {
        this.etcOwFees = res.data || [];
        this.etcOwFeesVisible = true;
      });
    },

    openContractModal(item, isAdd) {
      this.selectDriver = item;
      this.contactVisible = true;
      // 如果是修改合同，则需要查询当前合同
      if (!isAdd) {
        getDriverDetail({ id: item.id }).then((res) => {
          this.contractForm = res.data.driverContract;
        });
      }
    },

    startEtcSign(item) {
      this.$confirm("确认开始ETC签约吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        startWaybill(item.id).then(() => {
          this.$message.success("操作成功");
        });
      });
    },

    endEtcSign(item) {
      this.$confirm("确认解绑ETC签约吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        endWaybill(item.id).then(() => {
          this.$message.success("操作成功");
        });
      });
    },

    resetPwdFun(item) {
      this.$confirm("确认重置密码吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        resetDriverPwd(item.id).then(() => {
          this.$message.success("操作成功");
        });
      });
    },

    openCarInfoModal(item) {
      this.selectDriver = item;
      this.carInfoVisible = true;
      getDriverDetail({ id: item.id }).then((res) => {
        this.carInfoForm = res.data.vehicle;
      });
    },

    openDetailModal(item) {
      this.selectDriver = item;
      this.detailVisible = true;
      getDriverDetail({ id: item.id }).then((res) => {
        const { driverDetail } = res.data;
        const detail = { ...driverDetail };
        if (!detail.certificateNo) {
          detail.certificateNo = driverDetail.cardNo;
        }
        if (!detail.networkCarIssueOrganization) {
          detail.networkCarIssueOrganization = "岳阳市交通运输局";
        }
        this.driverDetail = detail;
      });
    },

    modifyInfo() {
      modifyDriverCoreInfo(this.form).then(() => {
        this.query();
        this.visible = false;
        this.$message.success("修改成功");
      });
    },

    updateDetailInfo() {
      updateDriverDetailInfo(this.driverDetail).then(() => {
        this.detailVisible = false;
        this.$message.success("修改成功");
      });
    },

    closeContractModal() {
      this.contactVisible = false;
      this.contractForm = {};
    },

    closeCarInfoModal() {
      this.carInfoVisible = false;
      this.carInfoForm = {};
    },

    updateCarInfo() {
      this.$refs.carInfoForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const parma = this.carInfoForm;
        updateVehicle(parma, this.selectDriver.id).then(() => {
          this.closeCarInfoModal();
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    saveContract() {
      this.$refs.contractForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const parma = this.contractForm;
        const { id } = this.selectDriver;
        parma.driverId = id;
        saveOrUpdateContract(parma).then(() => {
          this.closeContractModal();
          this.$message.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.condition {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.step1 {
  /deep/ .el-row {
    margin-left: 40px;
  }

  /deep/ .el-divider {
    margin-bottom: 40px;
  }
}
</style>
