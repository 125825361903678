<template>
  <div class="root">
    <div class="query-container" style="padding-bottom: 20px" v-if="state == '3'">
      <el-form size="small" :inline="true">
        <el-date-picker size="small" v-model="params.startDate" placeholder="起始日期" type="date" value-format="yyyy-MM-dd"
          class="violateCommonElement"></el-date-picker>-
        <el-date-picker size="small" v-model="params.endDate" placeholder="截止日期" type="date" value-format="yyyy-MM-dd"
          class="violateCommonElement"></el-date-picker>

        <el-select size="small" class="violateCommonElement" v-model="params.violateClassify1" clearable filterable
          placeholder="违规大类">
          <el-option v-for="item in violateClassify1Opts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select style="width: 200px" size="small" class="violateCommonElement" v-model="params.violateClassify2"
          clearable filterable placeholder="违规小类">
          <el-option v-for="item in violateClassify2Opts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select size="small" class="violateCommonElement" v-model="params.team" clearable placeholder="选择车队">
          <el-option v-for="item in teams" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select size="small" class="violateCommonElement" v-model="params.violateSrc" clearable placeholder="罚单来源">
          <el-option v-for="item in violateSrcOpts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select size="small" class="violateCommonElement" v-model="params.evidence" clearable placeholder="有无证据">
          <el-option v-for="item in evidenceOpts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-input style="width: 140px" size="small" class="evaCommonElement" v-model="params.driverNo"
          placeholder="请输入司机编号"></el-input>

        <el-button type="primary" size="small" @click="query">查询</el-button>
      </el-form>
    </div>

    <el-table :data="bills" stripe max-height="600" style="width: 100%">
      <el-table-column label="罚单编号" prop="code" width="100"></el-table-column>
      <el-table-column label="违规大类">
        <template slot-scope="scope">
          {{ parseInt(scope.row.violateClassify1) | enumTransfer(violateClassify1Opts) }}
        </template>
      </el-table-column>
      <el-table-column label="违规小类">
        <template slot-scope="scope">
          {{ scope.row.violateClassify2 | enumTransfer(violateClassify2Opts) }}
        </template>
      </el-table-column>

      <el-table-column label="司机" prop="driverNo" width="100"></el-table-column>
      <el-table-column label="违规日期" prop="violateDate" width="120"></el-table-column>
      <el-table-column label="违规线路" prop="pathName" width="120"></el-table-column>
      <el-table-column label="罚单来源" prop="violateSrcName" width="120"></el-table-column>
      <el-table-column label="违规证据" prop="evidence" width="80">
        <template slot-scope="scope">
          {{ scope.row.annexes && scope.row.annexes.length ? "有" : "无" }}
        </template>
      </el-table-column>
      <el-table-column label="违规处罚" prop="punishResult" width="160"></el-table-column>
      <el-table-column label="编辑罚单" prop="updByName" width="120" v-if="state == '1'"></el-table-column>
      <el-table-column label="发送人" prop="sendByName" width="120" v-if="state == '3'"></el-table-column>
      <el-table-column label="开单时间" prop="createTime" width="180"></el-table-column>
      <el-table-column label="有无申诉" width="80">
        <template slot-scope="scope">
          {{ scope.row.appealed ? "有" : "无" }}
        </template>
      </el-table-column>
      <el-table-column label="罚单状态" prop="state" width="100">
        <template slot-scope="scope">
          {{ stateName(scope.row.state) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button @click="sendBill(scope.row)" type="text" size="small"
            v-if="state == '1' && scope.row.state == '2'">发送</el-button>
          <el-button @click="editDetail(scope.row)" type="text" size="small" v-if="state == '1'">编辑</el-button>
          <el-button @click="deletePunishBill(scope.row)" type="text" size="small" v-if="state == '1'">删除</el-button>
          <el-button @click="showDetail(scope.row)" type="text" size="small" v-if="state == '3'">预览</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination :current-page="page.pageNo" :page-sizes="pageSizes" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="onPageSizeChange"
        @current-change="onPageNoChange"></el-pagination>
    </div>

    <el-dialog :visible.sync="showSendPanelDialog" width="386px">
      <div class="send-container">
        <div class="title">罚单{{ currentPunishBill.code }} 发送设置</div>
        <div class="send-content">
          <el-radio v-model="currentPunishBill.sendType" label="1">系统定时发送</el-radio>
          <el-radio v-model="currentPunishBill.sendType" label="2">立即发送</el-radio>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ParamUtil } from "@/util/index";
import { mapState } from "vuex";
import { SHOW_PUNISH_DIAG } from "@/core/const/global-event";
import { sendPunish, listDriverPunishBills } from "@/api";

export default {
  name: "billList",
  props: ["state"],

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teams: (state) => state.teamOpts,
      paths: (state) => state.pathList,
      violateSrcOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
      violateClassify1Opts: (state) => state.enumMap["DRIVER_VIOLATE_TYPE"],
      violateClassify2Opts: (state) => state.enumMap["DRIVER_VIOLATE_OPT_TYPE"],
    }),
  },

  data() {
    return {
      showSendPanelDialog: false,
      bills: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      pageSizes: [10, 30, 50],
      params: {
        startDate: null,
        endDate: null,
        violateClassify1: null,
        violateClassify2: null,
        team: null,
        violateSrc: null,
        driverNo: null,
        evidence: null,
        state: this.state,
      },

      //下拉选项
      evidenceOpts: [
        { value: "1", label: "有" },
        { value: "0", label: "无" },
      ],
      //下拉选项

      currentPunishBill: {},
    };
  },
  mounted() {
    this.$bus.$on("punishbillCreated", (bill) => {
      if (this.state == "1") {
        if (bill.state == "1" || bill.state == "2") {
          this.bills.splice(0, 0, bill);
        }
      } else if (this.state == "3") {
        if (bill.state != "1" && bill.state != "2") {
          this.bills.splice(0, 0, bill);
        }
      }
    });
  },
  destroyed() {
    this.$bus.$off("punishbillCreated");
  },
  watch: {
    state(val) {
      this.bills = [];
      this.page.pageNo = 1;
      this.page.total = 0;
      if (val == "1") {
        this.query();
      }
    },
  },
  methods: {
    stateName(state) {
      switch (state) {
        case "1":
          return "暂存";
        case "2":
          return "未发送";
        case "3":
          return "发送中";
        case "4":
          return "已发送";
        case "5":
          return "申诉中";
        case "6":
          return "申诉已处理";
        case "8":
          return "已确认";
        case "9":
          return "已免责";
      }
      return "";
    },

    query() {
      let params;
      if (this.state == "1") {
        params = { pageSize: this.page.pageSize, pageNo: this.page.pageNo, state: this.state };
      } else {
        params = {
          ...this.params,
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNo,
          state: this.state,
        };
      }
      listDriverPunishBills(ParamUtil.filterEmptyData(params)).then((res) => {
        var bills = res.data.records || [];
        this.page.total = res.data.total;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.total;
        for (var i = 0; i < bills.length; i++) {
          var bill = bills[i];
          this.paths.forEach((path) => {
            if (path.id == bill.pathId) {
              bill["pathName"] = path.name;
              return false;
            }
          });
          if (bill.violateSrc) {
            bill.violateSrc = parseInt(bill.violateSrc);
          }
          this.violateSrcOpts.forEach((src) => {
            if (src.value == bill.violateSrc) {
              bill["violateSrcName"] = src.label;
            }
          });
        }
        this.bills = bills;
      });
    },

    onPageSizeChange(pageSize) {
      if (this.page.pageSize != pageSize) {
        this.page.pageSize = pageSize;
        this.query();
      }
    },
    onPageNoChange(pageNo) {
      if (this.page.pageNo != pageNo) {
        this.page.pageNo = pageNo;
        this.query();
      }
    },
    editDetail(row) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, { punishBill: { ...row } });
    },

    showDetail(row) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, { punishBill: { ...row }, viewMode: true });
    },

    deletePunishBill(bill) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.get("/admin/driver/punishbill/delete/" + bill.id).then(() => {
          this.removeFromList(bill);
        });
      });
    },
    sendBill(row) {
      this.currentPunishBill = row;
      this.doSend();
    },
    doSend() {
      this.$confirm("确认发送吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sendPunish({ id: this.currentPunishBill.id, sendType: "2" }).then(() => {
          this.removeFromList(this.currentPunishBill);
        });
      });
    },
    removeFromList(bill) {
      for (var i = 0; i < this.bills.length; i++) {
        if (this.bills[i].id == bill.id) {
          this.bills.splice(i, 1);
          break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.violateCommonElement,
.evaCommonElement {
  margin-right: 10px;
}

.send-container {
  padding: 20px;

  .send-content {
    margin: 20px 80px;
    line-height: 32px;
  }
}
</style>
