import OrderTransactionDetail from '@/components/OrderTransactionDetail';

export default {

  install (Vue) {
    const OrderTransactionDetailClass = Vue.extend(OrderTransactionDetail);

    let isMounted = false;
    let instance;

    function $orderTransaction () {
      if (!isMounted) {
        instance = new OrderTransactionDetailClass();
        // 手动创建一个未挂载的实例
        instance.$mount();
        // 挂载
        document.querySelector('body').appendChild(instance.$el);
        isMounted = true;
      }

      return instance;
    }

    Vue.$orderTransaction = Vue.prototype.$orderTransaction = $orderTransaction;
  }
};
