<template>
  <div class="root-box">
    <div class="summary"
         v-if="route.date">
      <div class="left">
        <span>
          行程详情： 司机 {{ driver.no }} &nbsp;&nbsp;{{
            route.pathId | pathNameTransfer
          }}&nbsp;&nbsp; {{ route.date | date }}&nbsp;&nbsp;{{
            route.showTime
          }}&nbsp;&nbsp; {{ route.used }}/{{ route.number }}人
        </span>

        <el-tag v-for="item in orderList"
                :key="item.id"
                :type="item.isCancel ? 'info' : 'success'"
                @click="sortData(item.id, true)"
                :effect="selected.includes(item.id) ? 'dark' : 'plain'">
          {{ item.mark }}
        </el-tag>
      </div>

      <div>
        <!-- <el-button type="primary" :size="size" @click="routeTrack()"
          >轨迹回放</el-button
        > -->
      </div>
    </div>
    <div class="content">
      <el-timeline>
        <el-timeline-item placement="top"
                          :color="item.color || '#0bbd87'"
                          :timestamp="item.time"
                          v-for="item in timeLine"
                          :key="item.index"
                          :class="{ active: item.index === selectedIndex }">
          <template slot="dot">
            <div class="dot"
                 :class="{ big: item.index === selectedIndex }"
                 :style="{ backgroundColor: item.color || '#0bbd87' }">
              {{ item.index + 1 }}
            </div>
          </template>
          <div class="time-clicker pointer"
               @click="selectTimeLine(item)"></div>

          <div class="box">
            <div class="id"
                 @click="sortData(item.orderId, true)">
              {{ item.orderId | orderMark(orderMap) }}
            </div>
            <div>
              <div v-if="item.type === typeEnum.cancelOrder">
                {{ item.content }}
              </div>
              <div v-else-if="item.type === typeEnum.tel"
                   class="flex">
                <span> {{ item.content }} </span>

                <span v-if="item.hasRecord"
                      class="marging-left">
                  <div v-if="item.recordUrl">
                    <audio class="record-audio"
                           :src="item.recordUrl"
                           controls="controls"></audio>
                  </div>

                </span>
              </div>
              <div v-else-if="item.type === typeEnum.message">
                <span> {{ item.content }} : {{ item.data.smsContent }}</span>
              </div>
              <div v-else-if="item.type === typeEnum.dispatchOrder"
                   class="flex">
                <span> {{ item.content }} </span>
                <span class="btn">
                  <el-button type="text"
                             @click="showLog(item.orderId)">操作日志</el-button>
                </span>

                <span class="btn">
                  <el-button type="text"
                             @click="showLog(item.orderId)">订单详细</el-button>
                </span>
              </div>
              <div v-else>
                {{ item.content }}
              </div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div class="map-box">
      <div class="block">
        <div class="slider">
          <el-slider v-model="process"
                     :marks="marks"
                     :min="0"
                     :max="100"
                     @change="sliderChange">
          </el-slider>
        </div>
        <div class="action">
          <i class="el-icon-video-pause"
             v-if="moving"
             @click="pauseCarMarker"></i>
          <i class="el-icon-video-play"
             v-else
             @click="resumeCarMarker"></i>
        </div>
      </div>
      <div id="map"></div>
      <div class="info"
           v-if="currentPoint">
        <div>定位时间：{{ currentPoint[2] }}</div>
        <div v-if="address">{{ address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRouteReplay, downloadRecord, getDriverTrack } from "@/api";
import _ from "lodash";
import moment from "moment";
import { mapState } from "vuex";
import { DateUtil } from "@/util";

// slider分段数
const num = 8;
const typeEnum = {
  message: 1,
  tel: 2,
  detail: 3,
  dispatchOrder: 4,
  cancelOrder: 5,
  alarm: 6,
};

export default {
  components: {},
  props: {},
  filters: {
    orderMark (value, orderMap) {
      if (value) {
        const order = orderMap[value];
        return `【尾号${order.telephone.substr(7)}】`;
      }
      return "";
    },
    date (value) {
      if (value) {
        const date = moment(value);
        return date.format("MM月DD日");
      }
      return "";
    },
  },
  created () {
    const routeId = this.$route.query.id;
    const orderId = this.$route.query.orderId;
    if (routeId) {
      if (orderId) {
        this.selected = [+orderId];
      }
      this.routeId = routeId;
      this.init();
    }
  },
  mounted () {
    this.initMap();
  },
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      cancelTypeOpts: (state) => state.enumMap["ORDER_CANCEL_TYPE"],
      wrongStartPositionOpts: (state) =>
        state.enumMap["WRONG_POSITION_START"],
      wrongEndPositionOpts: (state) =>
        state.enumMap["WRONG_POSITION_END"],
    }),
  },
  data () {
    return {
      typeEnum,
      route: {},
      driver: {},
      timeLine: [],
      orderList: [],
      selected: [],
      process: 0,
      marks: {},
      moving: false,
      currentPoint: null,
      specPosition: 0,
      address: "",
      selectedIndex: -1,
    };
  },
  methods: {
    init () {
      getRouteReplay(this.routeId).then((res) => {
        const {
          driver,
          route,
          messageList = [],
          telList = [],
          routeDetailList = [],
          orderList = [],
          logList = [],
          evaluationList = [],
          complaintList = [],
          alarmList = [],
        } = res.data || {};
        this.driver = driver;
        this.route = route;

        let timeLine = [];
        const orderMap = {};

        const logMap = _.groupBy(
          _.sortBy(logList, (o) => o.createTime),
          (item) => item.orderId
        );

        orderList.forEach((order) => {
          const orderId = order.id;
          orderMap[orderId] = order;
          const logs = logMap[orderId];
          const logLength = logs.length;
          order.mark = `【尾号${order.telephone.substr(7)}】`;
          if (order.orderStatus === "CANCEL") {
            order.isCancel = true;
            const cancelLog = logs[logLength - 1];
            const dispatchLog = logs[logLength - 2];
            timeLine.push({
              orderId: orderId,
              type: typeEnum.dispatchOrder,
              time: order.matchTime,
              content: `${dispatchLog.userName} ${dispatchLog.operate}`,
            });
            const cancelUser =
                            cancelLog.userType === 1
                              ? "乘客"
                              : cancelLog.userName;
            timeLine.push({
              orderId: orderId,
              type: typeEnum.cancelOrder,
              time: cancelLog.createTime,
              content: `${cancelUser} ${cancelLog.operate}，取消原因：${order.cancelDesc}`,
              color: "red",
            });
          } else {
            const dispatchLog = logs[logLength - 1];
            timeLine.push({
              orderId: orderId,
              type: typeEnum.dispatchOrder,
              time: order.matchTime,
              content: `${dispatchLog.userName} ${dispatchLog.operate}`,
            });
          }
        });

        this.orderMap = orderMap;
        this.orderList = orderList.sort((a) => (a.isCancel ? 1 : -1));

        messageList.forEach((message) => {
          const order = orderMap[message.orderId];
          const userTel =
                        message.type === 1
                          ? order.telephone
                          : order.appointTelephone;
          const content =
                        message.calling && message.calling.indexOf(userTel) > -1
                          ? "乘客发送短信"
                          : "司机发送短信";
          timeLine.push({
            orderId: message.orderId,
            type: typeEnum.message,
            data: message,
            time: message.timeStamp,
            content,
            keyPoint: true,
            color: "#9e9e9e",
            lat: message.lat,
            lng: message.lng,
            addressName: message.address,
          });
        });
        telList.forEach((tel) => {
          let content = tel.direction
            ? "司机拨打电话"
            : "乘客拨打电话";
          if (tel.ulFailReason !== 0) {
            content += tel.direction
              ? "，乘客未接听"
              : "，司机未接听";
          }
          timeLine.push({
            orderId: tel.orderId,
            type: typeEnum.tel,
            data: tel,
            hasRecord: tel.ulFailReason === 0,
            time: tel.callInTime,
            content,
            keyPoint: true,
            color: "#9e9e9e",
            lat: tel.lat,
            lng: tel.lng,
            addressName: tel.address,
          });
        });

        const transfer = this.$options.filters.enumTransfer;

        routeDetailList.forEach((detail) => {
          const order = orderMap[detail.id];
          order.routeDetail = detail;
          const {
            startTime,
            startPosition,
            startLongitude,
            startLatitude,
            arriveTime,
            arrivePosition,
            arriveLongitude,
            arriveLatitude,
            depTime,
            depLongitude,
            depLatitude,
            departure,
            destTime,
            destination,
            destLongitude,
            destLatitude,
            finishTime,
            depWrongReason,
            destWrongReason,
          } = detail || {};
          if (startTime) {
            timeLine.push({
              orderId: detail.id,
              type: typeEnum.detail,
              data: order,
              time: startTime,
              content: "出发去接乘客",
              keyPoint: true,
              lat: startLatitude,
              lng: startLongitude,
              addressName: startPosition,
            });
          }
          if (arriveTime) {
            timeLine.push({
              orderId: detail.id,
              type: typeEnum.detail,
              data: order,
              time: arriveTime,
              content: "到达乘客上车点",
              keyPoint: true,
              lat: arriveLatitude,
              lng: arriveLongitude,
              addressName: arrivePosition,
            });
          }
          if (depTime) {
            const content = depWrongReason
              ? `乘客上车，位置偏离：${transfer(
                depWrongReason,
                this.wrongStartPositionOpts
              )}`
              : "乘客上车";
            timeLine.push({
              orderId: detail.id,
              type: typeEnum.detail,
              data: order,
              time: depTime,
              content,
              keyPoint: true,
              lat: depLatitude,
              lng: depLongitude,
              addressName: departure,
              depWrongReason,
              color: depWrongReason ? "orange" : "",
            });
          }
          if (destTime) {
            const content = destWrongReason
              ? `乘客下车，位置偏离：${transfer(
                destWrongReason,
                this.wrongEndPositionOpts
              )}`
              : "乘客下车";
            timeLine.push({
              orderId: detail.id,
              type: typeEnum.detail,
              data: order,
              time: destTime,
              content,
              keyPoint: true,
              lat: destLatitude,
              lng: destLongitude,
              addressName: destination,
              destWrongReason,
              color: destWrongReason ? "orange" : "",
            });
          }
          if (finishTime) {
            timeLine.push({
              orderId: detail.id,
              type: typeEnum.detail,
              data: order,
              time: finishTime,
              content: "完成订单",
            });
          }
        });
        evaluationList.forEach((evaluation) => {
          if (evaluation.evaluateTime) {
            timeLine.push({
              orderId: evaluation.id,
              time: evaluation.evaluateTime,
              content: `乘客评价司机：${
                evaluation.serviceScore
              }星  ${evaluation.detail ? evaluation.detail : ""}`,
              color: evaluation.serviceScore < 4 ? " red" : "",
            });
          }
          if (evaluation.driverEvaluateTime) {
            timeLine.push({
              orderId: evaluation.id,
              time: evaluation.driverEvaluateTime,
              content: `司机评价乘客： ${
                evaluation.driverEvaluateScore
              }星  ${evaluation.detail ? evaluation.detail : ""}`,
              color:
                                evaluation.driverEvaluateScore < 4
                                  ? " red"
                                  : "",
            });
          }
        });
        complaintList.forEach((complaint) => {
          timeLine.push({
            orderId: complaint.orderId,
            time: complaint.complaintTime,
            content: `乘客投诉：${
              complaint.detail ? complaint.detail : ""
            }`,
            color: "red",
          });
        });
        alarmList.forEach((alarm) => {
          timeLine.push({
            orderId: alarm.orderId,
            time: alarm.callTime,
            type: typeEnum.alarm,
            content: `乘客报警：${
              alarm.detail ? alarm.detail : ""
            }`,
            color: "red",
            keyPoint: true,
            lat: alarm.latitude,
            lng: alarm.longitude,
            addressName: alarm.name,
          });
        });

        timeLine = timeLine.sort((a, b) =>
          a.time.localeCompare(b.time)
        );
        this.oriData = timeLine;
        this.sortData();

        if (!timeLine || timeLine.length < 1) {
          return;
        }
        const startTime = timeLine[0].time;
        const endTime =
                    this.route.status === "ACTIVE"
                      ? DateUtil.getDateTimeStr()
                      : this.getEndTime(timeLine);
        this.createSilder(startTime, endTime);
      });
    },

    getEndTime (timeLine) {
      const point = timeLine[timeLine.length - 1];
      const date = moment(point.time).add(10, "m");
      return date.format("YYYY-MM-DD HH:mm:ss");
    },

    initMap () {
      const map = new AMap.Map("map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.instance = map;
      AMap.plugin(
        [
          "AMap.Scale",
          "AMap.Driving",
          "AMap.MoveAnimation",
          "AMap.Geocoder",
        ],
        () => {
          var toolbar = new AMap.Scale();
          this.geocoder = new AMap.Geocoder();
          map.addControl(toolbar);
          this.driving = new AMap.Driving({
            policy: AMap.DrivingPolicy.LEAST_TIME,
            autoFitView: true,
            hideMarkers: true,
            map,
          });
        }
      );
    },

    sortData (orderId, onlyCurrent) {
      this.selectedIndex = -1;
      if (orderId) {
        const index = this.selected.indexOf(orderId);
        if (index > -1) {
          this.selected.splice(index, 1);
        } else {
          if (onlyCurrent) {
            this.selected = [orderId];
          } else {
            this.selected.push(orderId);
          }
        }
      }

      const timeLine =
                this.selected.length > 0
                  ? this.oriData.filter((t) =>
                    this.selected.includes(t.orderId)
                  )
                  : this.oriData;
      timeLine.forEach((t, index) => (t.index = index));
      this.timeLine = timeLine;
      const markers = timeLine
        .filter((item) => item.keyPoint && item.lng)
        .map((point) => {
          const order = this.orderMap[point.orderId];
          const orderIndex = _.findIndex(this.orderList, {
            id: point.orderId,
          });
          return new AMap.Marker({
            draggable: true,
            raiseOnDrag: false,
            position: new AMap.LngLat(point.lng, point.lat),
            label: {
              direction: "top",
              content: `<div class='info order${orderIndex}'>${
                point.index + 1
              } -${order.mark}- ${point.content}</div>`,
            },
          });
        });
      const map = this.instance;
      this.renderMarkers(markers);
      map.setFitView();
    },

    showLog (orderId) {
      this.$orderLog().show(orderId);
    },

    downloadTelRecord (item, index) {
      if (!item.data.recordDomain || !item.data.recordObjectName) {
        this.$message({
          message: "没有录音",
          type: "warning",
        });
        return;
      }
      const data = {
        domainMain: item.data.recordDomain,
        objectName: item.data.recordObjectName,
      };

      downloadRecord(data).then((response) => {
        if (response.data) {
          item.recordUrl = response.data;
          this.$set(this.timeLine, index, item);
        } else {
          this.$message({
            message: "没有录音",
            type: "warning",
          });
        }
      });
    },

    createSilder (startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      const startDate = DateUtil.str2Date(startTime);
      const endDate = DateUtil.str2Date(endTime);
      const startTimeStamp = startDate.getTime();
      const endTimeStamp = endDate.getTime();
      const diff = endTimeStamp - startTimeStamp;

      const range = diff / num;
      const startMoment = moment(startTime);
      const style = {
        color: "#ffffff",
      };
      const marks = {
        0: {
          label: startMoment.format("HH:mm"),
          style,
        },
      };
      for (let index = 1; index <= num; index ++) {
        const next = startMoment.add(range, "ms");
        const value = (100 / num) * index;
        marks[value] = {
          label: next.format("HH:mm"),
          style,
        };
      }

      this.marks = marks;
    },

    sliderChange (val) {
      const specPosition = parseInt((val / 100) * this.totalPoint);
      this.specPosition = specPosition;
      const lineArr = this.lineArr.slice(specPosition);
      const map = this.instance;
      map.setCenter(lineArr[0]);
      this.carMarker.moveAlong(lineArr, {
        // 每一段的时长
        duration: 120,
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
      });
      this.resetData(false);
      this.pauseCarMarker();
    },

    selectTimeLine (item) {
      this.selectedIndex = item.index;
      this.routeTrack(item.time);
      if (item.keyPoint && item.lat) {
        this.currentPoint = [+item.lng, +item.lat, item.time];
        this.address = item.addressName;
      }
    },

    routeTrack (chooseStartTime) {
      const map = this.instance;
      const timeLine = this.timeLine;
      const startTime = chooseStartTime
        ? chooseStartTime
        : timeLine[0].time;
      const endTime =
                this.route.status === "ACTIVE"
                  ? DateUtil.getDateTimeStr()
                  : this.getEndTime(timeLine);
      const queryParam = {
        driverId: this.driver.id,
        startTime,
        endTime,
        vendor: "jimi",
      };

      const markers = timeLine
        .filter(
          (item) =>
            item.keyPoint && item.lng && item.time >= startTime
        )
        .map((point) => {
          const order = this.orderMap[point.orderId];
          const orderIndex = _.findIndex(this.orderList, {
            id: point.orderId,
          });
          return new AMap.Marker({
            draggable: true,
            raiseOnDrag: false,
            position: new AMap.LngLat(point.lng, point.lat),
            label: {
              direction: "top",
              content: `<div class='info order${orderIndex}'>${
                point.index + 1
              } -${order.mark}- ${point.content}</div>`,
            },
          });
        });

      getDriverTrack(queryParam).then((res) => {
        const {
          locations = [],
          locationStartTime,
          locationEndTime,
        } = res.data || {};
        this.locations = locations;
        const lineArr = locations.map((item) => [+item[0], +item[1]]);
        this.createSilder(locationStartTime, locationEndTime);
        this.renderMarkers(markers);

        // 重置信息
        this.resetData();
        this.driving && this.driving.clear();
        this.polyLine && map.remove(this.polyLine);
        this.passedPolyline && map.remove(this.passedPolyline);
        // 绘制轨迹
        const polyLine = new AMap.Polyline({
          map: map,
          path: lineArr,
          showDir: true,
          strokeColor: "#28F", //线颜色
          strokeOpacity: 0.5, //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });
        this.polyLine = polyLine;
        this.lineArr = lineArr;

        const passedPolyline = new AMap.Polyline({
          map: map,
          strokeColor: "#AF5", //线颜色
          strokeOpacity: 0.7,
          strokeWeight: 6, //线宽
        });
        this.passedPolyline = passedPolyline;

        if (!this.carMarker) {
          const carMarker = new AMap.Marker({
            map: map,
            position: lineArr[0],
            icon: new AMap.Icon({
              size: new AMap.Size(20, 40), //图标大小
              image: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
              imageSize: new AMap.Size(20, 40),
            }),
            offset: new AMap.Pixel(-10, -20),
          });

          carMarker.on("moving", (e) => {
            if (this.totalPoint) {
              // 计算百分比进度，当前进度 = 指定进度 + 当前车子移动进度
              const currentProcess =
                                e.passedPath.length + this.specPosition;
              this.process =
                                (currentProcess / this.totalPoint) * 100;
              // console.log(`当前进度 ${currentProcess}`);
              this.currentPoint = this.locations[currentProcess];
            }
            this.passedPolyline.setPath(e.passedPath);
          });

          this.carMarker = carMarker;
        }

        map.setCenter(lineArr[0]);
        setTimeout(() => {
          this.moving = true;
          this.totalPoint = lineArr.length;
          this.carMarker.moveAlong(lineArr, {
            // 每一段的时长
            duration: 120,
            // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
            autoRotation: true,
          });
          this.pauseCarMarker();
        }, 300);
      });
    },

    resetData (allReset = true) {
      this.address = null;
      if (allReset) {
        this.totalPoint = 0;
        this.lineArr = [];
        this.specPosition = 0;
        this.process = 0;
      }
    },

    renderMarkers (markers) {
      const map = this.instance;
      if (this.markers) {
        map.remove(this.markers);
      }
      this.markers = markers;
      map.add(markers);
    },

    pauseCarMarker () {
      this.moving = false;
      this.carMarker && this.carMarker.pauseMove();
      this.getAddress();
    },

    resumeCarMarker () {
      this.moving = true;
      this.carMarker && this.carMarker.resumeMove();
    },

    getAddress () {
      if (this.currentPoint) {
        this.geocoder.getAddress(
          [this.currentPoint[0], this.currentPoint[1]],
          (status, result) => {
            if (status === "complete" && result.regeocode) {
              const address = result.regeocode.formattedAddress;
              this.address = address;
            } else {
              console.error("根据经纬度查询地址失败");
            }
          }
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
@mapWidth: 800px;
.root-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .summary {
    margin: 0 0 20px 0;
    display: flex;

    .left {
      flex: 1;
      margin-right: 20px;
    }

    .el-tag {
      margin-left: 20px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    margin-right: @mapWidth + 30px;
    overflow: auto;

    .time-clicker {
      position: absolute;
      height: 24px;
      width: 200px;
      top: 0;
    }

    .el-timeline {
      padding: 10px 0 10px 10px;
      .box {
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;
        .id {
          margin-right: 20px;
          cursor: pointer;
        }
      }

      /deep/ .active {
        .el-timeline-item__timestamp,
        .el-timeline-item__content {
          color: red !important;
        }
      }
    }
  }
}

.map-box {
  position: fixed;
  width: @mapWidth;
  bottom: 20px;
  top: 120px;
  right: 30px;
  display: flex;
  flex-direction: column;

  #map {
    width: 100%;
    flex: 1;
  }

  .info {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
  }

  .block {
    padding: 0 0 16px 30px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    .slider {
      flex: 1;
    }

    .action {
      font-size: 30px;
      width: 60px;
      margin-right: 20px;
      color: white;
      cursor: pointer;
      text-align: right;
      position: relative;
      top: 5px;
    }
  }
}

.flex {
  display: flex;
  .btn {
    .el-button {
      padding: 0 0 0 20px;
    }
  }
}

.record-audio {
  height: 25px;
}

.marging-left {
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
  color: #409eff;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  left: -6px;
  &.big {
    width: 26px;
    height: 26px;
    left: -8px;
  }
}
</style>