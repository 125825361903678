<template>
  <el-drawer
    :visible.sync="visible"
    direction="ttb"
    :with-header="false"
    custom-class="drawer-modal"
    @opened="onOpen"
    :show-close="false"
    :before-close="beforeClose"
    :withHeader="false"
  >
    <div class="auto-box" v-if="task">
      <div class="left">
        <div class="header">
          <div>
            <span> 派单时间段: {{ task.showTime }} </span>
            <span>
              <span>
                分派订单情况：已派{{ totalDispatchedPersonNum }}人/ 共{{
                  task.totalPersonNum
                }}人</span
              >
              <span>余{{ task.totalSeatsNum - totalDispatchedPersonNum }}座</span>
            </span>
          </div>
          <el-button type="primary" plain size="mini" @click="savePlan">保存方案</el-button>
        </div>

        <div class="content">
          <div class="item">
            <div class="row2" v-for="(item, index) in unDispatchedOrderList" :key="item.id">
              <div class="info">
                <span>{{ index + 1 }}、</span>
                <span class="mr">{{ item.number }}人</span>
                <span class="mr">{{ item.showTime }}</span>
                <span class="address">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding-top: 0"
                    @click.stop="locate(item, true)"
                  >
                    {{ item.departure }}</el-button
                  >
                  <span> - </span>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding-top: 0"
                    @click.stop="locate(item, false)"
                  >
                    {{ item.destination }}</el-button
                  >
                </span>
              </div>

              <i class="el-icon-close" @click="delScheduling(item)"></i>
            </div>
          </div>
          <el-collapse v-model="expandedItems">
            <el-collapse-item
              :name="index"
              v-for="(item, index) in dispatchedScheduleList"
              :key="item.scheduling.id"
              class="item"
            >
              <template slot="title">
                <div class="row1">
                  <div>
                    <span
                      class="dot"
                      :style="{ backgroundColor: colors[index % colors.length] }"
                    ></span>
                    <span>车辆编号：{{ item.scheduling.driverNo }}</span>
                    <span>派单时间段：{{ item.scheduling.showTime }}</span>
                    <span
                      >使用座位数：{{ item.scheduling.seats - item.scheduling.leftSeats }} /
                      {{ item.scheduling.seats }}</span
                    >
                  </div>

                  <div>
                    <el-button type="text" size="mini" @click.stop="exchangeCar(item)"
                      >换车</el-button
                    >
                    <el-button type="text" size="mini" @click.stop="changeStartTime(item)"
                      >时间</el-button
                    >
                    <el-button type="text" size="mini" @click.stop="delScheduling(item)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </template>
              <div class="scheduling-box">
                <sortable-list
                  lockAxis="y"
                  v-model="item.pickSeq"
                  :useDragHandle="true"
                  @input="onSortChange($event, item)"
                >
                  <PointItem
                    v-for="(scheduling, sIndex) in item.pickSeq"
                    :scheduling="scheduling"
                    :isPick="true"
                    :index="sIndex"
                    :key="scheduling.id"
                    :locateFun="locate"
                    :task="task"
                    :delItem="(scheduling) => delItem(item, scheduling, index)"
                  />
                </sortable-list>

                <div class="sep"></div>

                <sortable-list
                  lockAxis="y"
                  v-model="item.sendSeq"
                  :useDragHandle="true"
                  @input="onSortChange($event, item)"
                >
                  <PointItem
                    v-for="(scheduling, sIndex) in item.sendSeq"
                    :scheduling="scheduling"
                    :isPick="false"
                    :index="sIndex"
                    :key="scheduling.id"
                    :locateFun="locate"
                    :task="task"
                    :delItem="(scheduling) => delItem(item, scheduling, index)"
                  />
                </sortable-list>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="right">
        <div id="autoDispatchMap"></div>
        <div class="operate-box">
          <div class="left-btns">
            <el-switch v-model="showRoadLine" active-text="道路轨迹"> </el-switch>
            <el-switch v-model="showDetail" active-text="订单详情"> </el-switch>
            <el-switch v-model="showCar" active-text="车辆位置"> </el-switch>
          </div>
          <div class="right-btns">
            <el-button type="primary" size="mini" @click="dispatchConfirmVisible = true"
              >执行预派</el-button
            >
          </div>
        </div>
      </div>

      <el-dialog
        :visible.sync="schedulingVisible"
        width="540px"
        max-height="600"
        :close-on-click-modal="false"
        :append-to-body="true"
        :show-close="false"
      >
        <div>
          <div>请选择分派司机</div>
          <el-table :data="dispatchedScheduleList" stripe max-height="550">
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>

            <el-table-column prop="showTime" label="派单时间段" width="120">
              <template slot-scope="scope"> {{ scope.row.scheduling.showTime }} </template>
            </el-table-column>
            <el-table-column prop="driverNo" label="司机编号" width="100">
              <template slot-scope="scope">
                <span
                  class="dot"
                  :style="{ backgroundColor: colors[scope.$index % colors.length] }"
                ></span>
                <span> {{ scope.row.scheduling.driverNo }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders" label="剩余座位数" width="100">
              <template slot-scope="scope">
                {{ scope.row.scheduling.leftSeats }}座 / {{ scope.row.scheduling.seats }}座
              </template>
            </el-table-column>

            <el-table-column prop="psgMessage" width="130" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="chooseType === 1"
                  type="text"
                  size="small"
                  @click="dispatchToCar(scope.row)"
                  >分派</el-button
                >
                <el-button
                  v-if="chooseType === 2"
                  type="text"
                  size="small"
                  :disabled="scope.row.scheduling.id === oriScheduling.id"
                  @click="confirmExchangeCar(scope.row)"
                  >换这一辆</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button :size="size" @click="schedulingVisible = false">关闭</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="dispatchConfirmVisible"
        width="440px"
        title="提示"
        :append-to-body="true"
        :show-close="false"
      >
        <div style="display: flex; align-items: center">
          <i style="font-size: 30px; margin-right: 10px; color: orange" class="el-icon-warning"></i>
          <span>确认要执行分派吗？</span>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-checkbox v-model="ignoreError" style="margin-right: 10px">忽略异常</el-checkbox>

          <el-button :size="size" @click="dispatchConfirmVisible = false">关闭</el-button>
          <el-button :size="size" type="primary" @click="toDispatch">确认</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="startTimeVisible"
        width="440px"
        title="请选择行程出发时间"
        :append-to-body="true"
        :show-close="false"
      >
        <div style="display: flex; align-items: center">
          <el-time-select
            v-model="startTime"
            :picker-options="{
              start: '04:30',
              step: '00:05',
              end: '23:30',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button :size="size" @click="startTimeVisible = false">关闭</el-button>
          <el-button :size="size" type="primary" @click="confirmTime">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </el-drawer>
</template>

<script>
import { DateUtil } from "@/util";
import PointItem from "./PointItem";
import _ from "lodash";
import moment from "moment";
import { doDispatch, saveDispatchPlan } from "@/api";

const carPng = require("../../assets/image/car_blue.png");

export default {
  props: {},
  components: {
    PointItem,
  },

  filters: {
    dateTime(val = 0) {
      const now = new Date().getTime();
      const thatTime = new Date(now + val * 1000);
      return DateUtil.format(thatTime, "yyyy-MM-dd hh:mm:ss");
    },
  },

  computed: {
    // 已分派订单总数
    totalDispatchedOrderNum() {
      return this.dispatchedScheduleList.reduce((total, item) => {
        return total + item.pickSeq.length;
      }, 0);
    },

    // 已分派订单总人数
    totalDispatchedPersonNum() {
      return this.dispatchedScheduleList.reduce((total, item) => {
        return total + item.scheduling.seats - item.scheduling.leftSeats;
      }, 0);
    },

    // 未分派订单总人数
    totalUnDispatchedPersonNum() {
      return this.unDispatchedOrderList.reduce((total, item) => {
        return total + item.number;
      }, 0);
    },
  },

  watch: {
    showDetail() {
      this.renderMap(false);
    },

    showCar(val) {
      this.showCarPosition(val);
    },

    showRoadLine() {
      this.renderMap();
    },
  },

  data() {
    return {
      size: "small",
      colors: [
        "#FF0000",
        "#00FF00",
        "#0000FF",
        "#FFA500",
        "#800080",
        "#00FFFF",
        "#008080",
        "#8B0000",
        "#800000",
        "#008000",
      ],
      dispatchedScheduleList: [],
      unDispatchedOrderList: [],
      dispatchConfirmVisible: false,
      schedulingVisible: false,
      visible: false,
      showDetail: false,
      showCar: false,
      showRoadLine: false,
      expandedItems: [],
      task: null,
      chooseType: 1, // 1: 分派司机 2: 换车
      oriScheduling: null,
      ignoreError: false,
      startTime: null,
      startTimeVisible: false,
    };
  },
  methods: {
    show(task) {
      this.task = task;
      const { autoDispatchResult, considerDriverPosition } = task;
      const { dispatchedScheduleList, unDispatchedOrderList } = autoDispatchResult;
      this.dispatchedScheduleList = dispatchedScheduleList;
      this.unDispatchedOrderList = unDispatchedOrderList;
      this.showCar = considerDriverPosition;
      // 默认全部展开
      this.expandedItems = task.autoDispatchResult.dispatchedScheduleList.map(
        (item, index) => index
      );
      this.visible = true;
    },

    onOpen() {
      this.initMap();
      this.renderMap(true);
      if (this.showCar) {
        this.showCarPosition(true);
      }
    },

    initMap() {
      const map = new AMap.Map("autoDispatchMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
        // mapStyle: "amap://styles/whitesmoke",
      });

      AMap.plugin("AMap.Driving", () => {
        const driving = new AMap.Driving({
          // 驾车路线规划策略，考虑实时路况
          // policy: AMap.DrivingPolicy.REAL_TRAFFIC,
          // autoFitView: true,
          // hideMarkers: false,
          // map,
        });

        this.driving = driving;
      });

      this.map = map;

      const contextMenu = new AMap.ContextMenu();
      contextMenu.addItem(
        "指派司机",
        () => {
          this.chooseType = 1;
          this.schedulingVisible = true;
          setTimeout(() => {
            contextMenu.close();
          }, 100);
        },
        0
      );
      this.contextMenu = contextMenu;
    },

    showCarPosition(isShow) {
      const map = this.map;
      if (isShow && map) {
        this.showCarMarker();
      } else {
        if (this.carMarkers) {
          map.remove(this.carMarkers);
          this.carMarkers = null;
        }
      }
    },

    renderMap(setFit = false) {
      const map = this.map;
      map.clearMap();
      this.allMarkerMap = {};
      this.showMapLine(setFit);
      this.showMapMarker();
      if (setFit) {
        map.setFitView();
      }
    },

    showCarMarker() {
      const { schedulingList = [] } = this.task;
      const carMarkers = [];
      schedulingList.forEach((item) => {
        const { driverId, driverNo, lng, lat, lastOrderLocation, lastOrderTime = "" } = item;
        const time = lastOrderTime.substr(5);
        const locationSource = lastOrderLocation ? "订单" : "空闲";
        const carIcon = new AMap.Icon({
          size: new AMap.Size(40, 40), //图标大小
          image: carPng,
          imageSize: new AMap.Size(40, 40),
        });

        const carIndex = _.findIndex(this.dispatchedScheduleList, (schedule) => {
          return schedule.scheduling.driverId === driverId;
        });

        if (lat && lng) {
          const marker = new AMap.Marker({
            icon: carIcon,
            position: new AMap.LngLat(lng, lat),
            draggable: true,
            label: {
              content: `<div class="car-info"> <div class="dot" style="background-color:${
                this.colors[carIndex % 10]
              }"></div> ${driverNo} | ${time} | ${locationSource}</div>`,
              direction: "top",
            },
          });
          carMarkers.push(marker);
        }
      });
      this.carMarkers = carMarkers;
      this.map.add(carMarkers);
    },

    showMapMarker() {
      const map = this.map;
      const unDispatched = this.unDispatchedOrderList;
      unDispatched.forEach((item) => {
        const label = {
          //修改label相对于maker的位置
          direction: "top",
          content: "<div class='info'>" + `${item.showTime} | ${item.number}人` + "</div>",
        };
        const dep = new AMap.Marker({
          position: [item.depLongitude, item.depLatitude],
          title: item.departure,
          label,
          draggable: true,
          extData: item,
          map,
        });
        const dest = new AMap.Marker({
          position: [item.destLongitude, item.destLatitude],
          title: item.destination,
          label,
          extData: item,
          draggable: true,
          map,
        });

        dep.on("rightclick", (e) => {
          this.selectMarker = e.target;
          this.contextMenu.open(map, e.lnglat);
        });
        dest.on("rightclick", (e) => {
          this.selectMarker = e.target;
          this.contextMenu.open(map, e.lnglat);
        });

        // 记录所有marker
        this.allMarkerMap[`${item.orderId}_dep`] = dep;
        this.allMarkerMap[`${item.orderId}_dest`] = dest;
      });
    },

    showMapLine(setFit) {
      const map = this.map;
      const dispatched = this.dispatchedScheduleList;
      dispatched.forEach((item, index) => {
        const lineArr = [];
        item.pickSeq.forEach((point) => {
          lineArr.push([point.lng, point.lat]);
          const label = {
            //修改label相对于maker的位置
            direction: "top",
            content: "<div class='info'>" + `${point.showTime} | ${point.number}人` + "</div>",
          };
          const marker = new AMap.Marker({
            position: [point.lng, point.lat],
            title: point.address,
            label: this.showDetail ? label : null,
            content: `<div style="background-color:${
              this.colors[index % 10]
            };width:20px; height:20px; border-radius:100%; color:white; display:flex; align-items: center; justify-content:center;">${
              point.orderIndex
            }</div>`,
            anchor: "center",
            map,
          });

          // 记录所有marker
          this.allMarkerMap[`${item.orderId}_dep`] = marker;
        });

        item.sendSeq.forEach((point) => {
          lineArr.push([point.lng, point.lat]);
          const label = {
            //修改label相对于maker的位置
            direction: "top",
            content: "<div class='info'>" + `${point.showTime} | ${point.number}人` + "</div>",
          };
          const marker = new AMap.Marker({
            position: [point.lng, point.lat],
            title: point.address,
            label: this.showDetail ? label : null,
            content: `<div style="background-color:${
              this.colors[index % 10]
            };width:20px; height:20px; border-radius:100%; color:white; display:flex; align-items: center; justify-content:center;">${
              point.orderIndex
            }</div>`,
            anchor: "center",
            map,
          });

          // 记录所有marker
          this.allMarkerMap[`${item.orderId}_dest`] = marker;
        });

        if (lineArr.length) {
          if (this.showRoadLine) {
            const startPoint = lineArr[0];
            const endPoint = lineArr[lineArr.length - 1];
            const waypoints = lineArr.slice(1, lineArr.length - 1);
            this.driving.search(startPoint, endPoint, { waypoints }, (status, result) => {
              if (status === "complete") {
                const { routes = [] } = result;
                const { steps = [] } = routes[0];
                const pathArr = [];
                steps.map((i) => {
                  // const action = i.assistant_action;
                  pathArr.push(i.path);
                  return pathArr;
                });
                //通过合并数组的方法，得到所有点的集合
                const path = _.flatten(pathArr);

                new AMap.Polyline({
                  map,
                  path,
                  showDir: true,
                  strokeColor: this.colors[index % 10], //线颜色
                  strokeOpacity: 0.5, //线透明度
                  strokeWeight: 6, //线宽
                  // strokeStyle: "solid"  //线样式
                });

                if (setFit) {
                  map.setFitView();
                }
              }
            });
          } else {
            new AMap.Polyline({
              map,
              path: lineArr,
              showDir: true,
              strokeColor: this.colors[index % 10], //线颜色
              strokeOpacity: 0.5, //线透明度
              strokeWeight: 6, //线宽
              // strokeStyle: "solid"  //线样式
            });
          }
        }
      });
    },

    delItem(currentScheduling, point, planIndex) {
      const num = point.number;
      const orderId = point.orderId;
      let { scheduling, pickSeq, sendSeq } = currentScheduling;
      scheduling.leftSeats += num;
      // 记录订单上下车点
      const depOrderPoint = pickSeq.find((item) => item.orderId === orderId);
      const destOrderPoint = sendSeq.find((item) => item.orderId === orderId);

      // 构造订单数据
      const order = {
        ...depOrderPoint,
        departure: depOrderPoint.address,
        depLongitude: depOrderPoint.lng,
        depLatitude: depOrderPoint.lat,
        destination: destOrderPoint.address,
        destLongitude: destOrderPoint.lng,
        destLatitude: destOrderPoint.lat,
        standardDuration: destOrderPoint.standardDuration,
      };

      pickSeq = pickSeq.filter((item) => item.orderId !== orderId);
      sendSeq = sendSeq.filter((item) => item.orderId !== orderId);
      this.reCalcArriverTime(scheduling, pickSeq, sendSeq);

      this.dispatchedScheduleList.splice(planIndex, 1, {
        scheduling,
        pickSeq,
        sendSeq,
      });

      this.unDispatchedOrderList.push(order);
      this.renderMap();
    },

    addItem(order, currentScheduling) {
      const { scheduling, pickSeq, sendSeq } = currentScheduling;
      const orderIndex = this.unDispatchedOrderList.findIndex(
        (item) => item.orderId === order.orderId
      );
      this.unDispatchedOrderList.splice(orderIndex, 1);

      // 找到当前最大的orderIndex
      const maxOrderIndex = pickSeq.length
        ? Math.max(...[...pickSeq, ...sendSeq].map((item) => item.orderIndex))
        : 0;

      const {
        orderId,
        showTime,
        togetherPwd,
        number,
        depLatitude,
        depLongitude,
        departure,
        destLatitude,
        destLongitude,
        destination,
        standardDuration,
        dateTime,
      } = order;
      const pickPoint = {
        orderId,
        lat: depLatitude,
        lng: depLongitude,
        address: departure,
        orderIndex: maxOrderIndex + 1,
        number,
        togetherPwd,
        showTime,
        dateTime,
      };

      const sendPoint = {
        orderId,
        lat: destLatitude,
        lng: destLongitude,
        address: destination,
        orderIndex: maxOrderIndex + 1,
        number,
        togetherPwd,
        showTime,
        standardDuration,
        dateTime,
      };

      pickSeq.push(pickPoint);
      sendSeq.push(sendPoint);

      this.reCalcArriverTime(scheduling, pickSeq, sendSeq);

      const planIndex = this.dispatchedScheduleList.findIndex(
        (item) => item.scheduling.id === scheduling.id
      );

      scheduling.leftSeats -= number;

      this.dispatchedScheduleList.splice(planIndex, 1, {
        scheduling,
        pickSeq,
        sendSeq,
      });
      this.renderMap();
      if (this.showCar) {
        this.showCarPosition(true);
      }
    },

    reCalcArriverTime(scheduling, pickSeq, sendSeq) {
      const { durationMatrix, orderList, schedulingList } = this.task;
      const orderIndexMap = {};
      orderList.forEach((item, index) => {
        orderIndexMap[item.orderId] = index;
      });

      const carIndexMap = {};
      schedulingList.forEach((item, index) => {
        carIndexMap[item.driverId] = index;
      });

      let time = scheduling.startTime || pickSeq[0].dateTime;
      let preOrderId = null;
      // 如果考虑司机的位置，需要先计算司机到首位上车点的时间
      // if (considerDriverPosition && pickSeq.length) {
      //   const i = 1 + orderList.length * 2 + carIndexMap[scheduling.driverId];
      //   const j = orderIndexMap[pickSeq[0].orderId] * 2 + 1;
      //   const duration = durationMatrix[i][j];
      //   time = moment(time).add(duration, "m").format("YYYY-MM-DD HH:mm:ss");
      // }

      const orderStartTimeMap = {};
      pickSeq.forEach((item, index) => {
        const { orderId } = item;
        if (index === 0) {
          item.arriveTime = time;
        } else {
          const i = orderIndexMap[preOrderId] * 2 + 1;
          const j = orderIndexMap[orderId] * 2 + 1;
          const duration = durationMatrix[i][j];
          item.arriveTime = moment(time).add(duration, "m").format("YYYY-MM-DD HH:mm:ss");
        }
        time = item.arriveTime;
        orderStartTimeMap[orderId] = item.arriveTime;
        preOrderId = orderId;
      });

      sendSeq.forEach((item, index) => {
        const { orderId } = item;

        let i = orderIndexMap[preOrderId] * 2 + 2;
        const j = orderIndexMap[orderId] * 2 + 2;
        // 第一个点是起点到终点的时间
        if (index === 0) {
          i = orderIndexMap[preOrderId] * 2 + 1;
        }
        const duration = durationMatrix[i][j];
        item.arriveTime = moment(time).add(duration, "m").format("YYYY-MM-DD HH:mm:ss");
        time = item.arriveTime;
        // 计算订单的行程时间
        const startTime = orderStartTimeMap[orderId];
        item.totalDuration = (new Date(item.arriveTime) - new Date(startTime)) / 1000 / 60;
        preOrderId = orderId;
      });
    },

    delScheduling(dispatchedSchedule) {
      this.$confirm("确认删除整个行程吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const orderMap = {};
          // 将行程中的订单释放出来
          dispatchedSchedule.pickSeq.forEach((item) => {
            const order = {
              ...item,
              departure: item.address,
              depLongitude: item.lng,
              depLatitude: item.lat,
              destination: item.address,
              destLongitude: item.lng,
              destLatitude: item.lat,
            };
            orderMap[order.orderId] = order;
          });
          // 补全订单终点地址信息
          dispatchedSchedule.sendSeq.forEach((item) => {
            const order = orderMap[item.orderId];
            order.destination = item.address;
            order.destLongitude = item.lng;
            order.destLatitude = item.lat;
            order.standardDuration = item.standardDuration;
          });

          const orderList = _.values(orderMap);

          this.unDispatchedOrderList = [...this.unDispatchedOrderList, ...orderList];

          // 删除dispatchedSchedule中的points
          this.dispatchedScheduleList.forEach((item) => {
            if (item.scheduling.id === dispatchedSchedule.scheduling.id) {
              item.pickSeq = [];
              item.sendSeq = [];
              item.scheduling.leftSeats = item.scheduling.seats;
              item.scheduling.showTime = null;
            }
          });
          this.renderMap();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    changeStartTime(dispatchedSchedule) {
      this.selectedDispatchedSchedule = dispatchedSchedule;
      this.startTime = dispatchedSchedule.scheduling.startTime.substr(11, 5);
      this.startTimeVisible = true;
    },

    confirmTime() {
      const { scheduling, pickSeq, sendSeq } = this.selectedDispatchedSchedule;
      const ortTime = scheduling.startTime;
      scheduling.startTime = ortTime.substr(0, 10) + " " + this.startTime + ":00";
      // 计算时间差
      const timeDiff = (new Date(scheduling.startTime) - new Date(ortTime)) / 1000 / 60;

      // 接送人时间统一加上时间差
      pickSeq.forEach((item) => {
        item.arriveTime = moment(item.arriveTime)
          .add(timeDiff, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
      });

      sendSeq.forEach((item) => {
        item.arriveTime = moment(item.arriveTime)
          .add(timeDiff, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
      });

      this.reCalcArriverTime(scheduling, pickSeq, sendSeq);

      const newArr = [];
      this.dispatchedScheduleList.forEach((item) => {
        if (item.scheduling.id === scheduling.id) {
          newArr.push({
            scheduling,
            pickSeq,
            sendSeq,
          });
        } else {
          newArr.push(item);
        }
      });

      this.dispatchedScheduleList = [];
      setTimeout(() => {
        this.dispatchedScheduleList = newArr;
      }, 0);

      this.startTimeVisible = false;
    },

    exchangeCar(dispatchedSchedule) {
      this.chooseType = 2;
      this.oriScheduling = dispatchedSchedule.scheduling;
      this.schedulingVisible = true;
    },

    confirmExchangeCar(chooseDispatchedSchedule) {
      this.$confirm("确认更换车辆吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const temp1 = { ...chooseDispatchedSchedule.scheduling };
          const temp2 = { ...this.oriScheduling };
          const newDispatchedScheduleList = [];
          this.dispatchedScheduleList.forEach((item) => {
            let newItem = item;
            const { scheduling, pickSeq, sendSeq } = item;
            if (scheduling.driverId === temp1.driverId) {
              newItem = {
                pickSeq,
                sendSeq,
                scheduling: {
                  ...temp2,
                  leftSeats: scheduling.leftSeats,
                  stime: scheduling.stime,
                  etime: scheduling.etime,
                  showTime: scheduling.showTime,
                  date: scheduling.date,
                },
              };
            } else if (scheduling.driverId === temp2.driverId) {
              newItem = {
                pickSeq,
                sendSeq,
                scheduling: {
                  ...temp1,
                  leftSeats: scheduling.leftSeats,
                  stime: scheduling.stime,
                  etime: scheduling.etime,
                  showTime: scheduling.showTime,
                  date: scheduling.date,
                },
              };
            }
            newDispatchedScheduleList.push(newItem);
          });

          this.dispatchedScheduleList = newDispatchedScheduleList;

          this.schedulingVisible = false;
          this.renderMap();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onSortChange(e, dispatchedSchedule) {
      // 顺序改变了，重新计算时间
      const { scheduling, pickSeq, sendSeq } = dispatchedSchedule;
      this.reCalcArriverTime(scheduling, pickSeq, sendSeq);

      const planIndex = this.dispatchedScheduleList.findIndex(
        (item) => item.scheduling.id === dispatchedSchedule.scheduling.id
      );

      this.dispatchedScheduleList.splice(planIndex, 1, {
        scheduling,
        pickSeq,
        sendSeq,
      });

      this.renderMap();
    },

    locate(order, isDep) {
      const { depLatitude, depLongitude, destLatitude, destLongitude } = order;
      const lat = isDep ? depLatitude : destLatitude;
      const lng = isDep ? depLongitude : destLongitude;

      if (this.lastDanceMarker) {
        // todo
        // this.lastDanceMarker.setSize(new AMap.Size(20, 20));
      }
      const key = `${order.orderId}_${isDep ? "dep" : "dest"}`;
      const marker = this.allMarkerMap[key];
      if (marker) {
        // todo
        this.lastDanceMarker = marker;
      }

      this.map.setCenter([lng, lat]);
    },

    dispatchToCar(carPlan) {
      const order = this.selectMarker.getExtData();

      if (carPlan.scheduling.leftSeats < order.number) {
        this.$message.error("车辆座位不足");
        return;
      }

      this.addItem(order, carPlan);
      this.schedulingVisible = false;
    },

    toDispatch() {
      const param = {
        dispatchedScheduleList: this.dispatchedScheduleList,
        unDispatchedOrderList: this.unDispatchedOrderList,
      };
      doDispatch(param, this.ignoreError).then(() => {
        this.dispatchConfirmVisible = false;
        this.$message.success("指定预派成功");
      });
    },

    savePlan() {
      this.$confirm("确认保存吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            dispatchedScheduleList: this.dispatchedScheduleList,
            unDispatchedOrderList: this.unDispatchedOrderList,
          };
          saveDispatchPlan(param, this.task.id).then(() => {
            this.$message.success("保存方案成功");
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    refreshTask(val, needRenderMap) {
      this.task = null;
      setTimeout(() => {
        this.task = val;
        if (needRenderMap) {
          this.renderMap();
        }
      }, 0);
    },

    beforeClose(done) {
      if (this.map) {
        this.map.clearMap();
      }
      this.carMarkers = null;
      this.carPosition = null;
      done && done();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90% !important;
}

/deep/ .car-info {
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 5px 8px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px 0px;
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 10px;
  }
}

#autoDispatchMap {
  width: 100%;
  height: 800px;
  flex: 1;
  position: relative;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-left: 6px;
}

.auto-box {
  margin: 6px;
  display: flex;
  .left {
    width: 600px;
    height: 100%;
    font-size: 15px;
    margin-right: 10px;

    .header {
      margin-bottom: 8px;
      margin-left: 4px;
      display: flex;
      padding-right: 4px;
      justify-content: space-between;
      span {
        margin-right: 12px;
      }
    }

    .content {
      height: 740px;
      overflow: auto;

      .item {
        font-size: 14px;
        margin: 0 0 6px 0;
        background-color: #cff1fd;
        padding: 6px;

        .row1 {
          width: 95%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            margin-right: 10px;
          }
        }

        .row2 {
          display: flex;
          align-items: flex-start;
          margin-bottom: 4px;

          .mr {
            margin-right: 10px;
          }

          .info {
            flex: 1;
            display: flex;
            .address {
              flex: 1;
            }
          }
        }

        .scheduling-box {
          padding: 0 10px;
          .sep {
            margin: 16px 0;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    height: 100%;
    position: relative;
    .operate-box {
      position: absolute;
      left: 10px;
      top: 10px;
      right: 10px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-btns {
        > * {
          margin-right: 20px;
        }
      }
    }

    .sch-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: 0;
      background-color: #fff;
    }
  }
}
</style>
