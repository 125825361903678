<template>
  <el-container>
    <el-container>
      <el-main class="bank-main">
        <el-row v-if="bankInfo.itms.length == 0">
          <el-col :span="24" style="text-align: center">
            <el-link @click="addItm(-1)">还没有题目，马上新增题目</el-link>。
          </el-col>
        </el-row>

        <!-- 预览题目 -->
        <template v-else>
          <div v-for="(itm, idx) in bankInfo.itms" :key="idx">
            <el-divider v-if="idx != 0"></el-divider>
            <el-row
              class="bank-itm"
              :key="itm.id"
              :class="[itm.selected ? 'selected' : '']"
              @click.native="editItm(itm, idx)"
            >
              <ItemView :sno="idx + 1" :paperItm="itm" @deleteMedia="deleteMedia(itm, idx)"></ItemView>
              <el-row class="itm-btns">
                <el-col v-show="itm.selected" :span="12" class="itm-add">
                  <i class="el-icon-plus add" @click.stop="addItm(idx)"></i>
                </el-col>
                <el-col v-show="itm.selected" :span="12" class="itm-del">
                  <i
                    class="el-icon-minus delete"
                    @click.stop="removeItm(itm, idx)"
                  ></i>
                </el-col>
              </el-row>
            </el-row>
          </div>
        </template>
      </el-main>
    </el-container>

    <el-aside class="itm-prop">
      <ItemEditor
        v-if="currentItmIdx !== -1"
        :paperItm="currentItmObj"
      ></ItemEditor>
    </el-aside>
  </el-container>
</template>

<script>
import ItemEditor from "../editor/ItemEditor";
import ItemView from "../editor/ItemView";
import Toolkit from "@/util/toolkit";
import {DateUtil} from '@/util';

export default {
  name: "BankEditor",
  props: {
    bankInfo: {
      type: Object
    }
  },
  components: {
    ItemEditor,
    ItemView
  },
  data () {
    return {
      currentItmObj: {},
      currentItmIdx: -1,
      itmTempObj: {
        id: "",
        seq: "",
        title: "这里添加题干描述信息",
        desc: "",
        media: "",
        score: 10,
        hfscore: 0,
        wrscore: 0,
        itmtype: "radio",
        itms: [],
        mustChoose: false,
        comment: "",
        answer: null
      }
    };
  },
  created () {},
  mounted () {
  },
  methods: {
    createItmObj () {
      let itm = Object.assign({}, this.itmTempObj);
      itm.id = "rdm-" + Toolkit.uuid();
      itm.itms = [{"id": "rdm-" + Toolkit.uuid(), "val": "选项1"},
        {"id": "rdm-" + Toolkit.uuid(), "val": "选项2"}];
      itm.uptime = DateUtil.getDateTimeStr();
      return itm;
    },
    addItm (idx) {
      var i = idx + 1;
      this.currentItmObj = this.createItmObj();
      // eslint-disable-next-line vue/no-mutating-props
      this.bankInfo.itms.splice(i, 0, this.currentItmObj);
      this.currentItmIdx = i;
      this.bankInfo.itms.forEach((itm, k) => {
        this.$set(itm, "selected", k === this.currentItmIdx);
      });
    },
    editItm (itm, idx) {
      if (idx === this.currentItmIdx) {
        return;
      }
      this.currentItmObj = itm;
      this.currentItmIdx = idx;
      this.bankInfo.itms.forEach((itm, k) => {
        this.$set(itm, "selected", k === this.currentItmIdx);
      });
    },
    deleteMedia (itm) {
      itm.media = null;
    },
    removeItm (itm, idx) {
      var that = this;
      this.$confirm('确认删除题目吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.bankInfo.itms.splice(idx, 1);
        if (idx === that.bankInfo.itms.length) {
          that.currentItmIdx = idx - 1;
        } else {
          that.currentItmIdx = idx;
        }
        that.currentItmObj = that.bankInfo.itms[that.currentItmIdx];
        that.bankInfo.itms.forEach((itm, k) => {
          that.$set(itm, "selected", k === that.currentItmIdx);
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.bank-main {
  width: 540px;
  height: 800px;
  margin-left: auto;
  border-left: 1px solid #c9d8db;
  border-right: 1px solid #c9d8db;

  .bank-itm {
    cursor: pointer;
    padding: 0 8px;
  }
  .itm-btns i.add {
    color: #c9d8db;
    background-color: #fff;
    padding: 2px;
  }
  .itm-btns i.delete {
    color: #fff;
    background-color: red;
    padding: 2px;
  }
  .itm-add {
    line-height: 32px;
    text-align: left;
  }
  .itm-del {
    line-height: 32px;
    text-align: right;
  }
  .selected {
    background-color: #eee;
  }
}

.itm-prop {
  width: 420px !important;
  height: 800px;
}
</style>