<template>
  <div v-if="form">
    <el-form
      ref="form"
      :model="form"
      label-width="10px"
      size="small"
      :disabled="disabled"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item>
          <el-checkbox-group v-model="form.regType">
            <el-checkbox
              v-for="item in regTypeOpts"
              :label="item.value"
              :key="item.value"
              @change="handleSourceChange"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-row>

      <el-row>
        <el-form-item>
          <el-radio-group v-model="form.userType">
            <el-radio
              v-for="item in userTypeOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-row>

      <el-row v-if="showPath">
        <el-form-item>
          <el-select multiple v-model="form.pathIds" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>

      <template v-if="isSubFilter">
        <el-divider></el-divider>

        <el-row>
          <el-form-item>
            <el-checkbox-group v-model="form.couponType">
              <el-checkbox
                v-for="item in couponOpts"
                :label="item.value"
                :key="item.value"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item>
            <el-radio-group v-model="form.isVip">
              <el-radio
                v-for="item in vipOpts"
                :label="item.value"
                :key="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-form-item>
            <el-checkbox v-model="form.filter15DaysSendBefore"
              >过滤15天重复发送的人</el-checkbox
            >
          </el-form-item>
        </el-row>
      </template>
    </el-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  components: {},
  props: {
    value: Object,
    isSubFilter: Boolean,
    isDisable: Boolean,
    isOnline: Boolean,
  },

  created () {
  },
  mounted () {},
  watch: {
    showPath () {
      this.$set(this.form, 'pathIds', []);
    },

    isDisable: {
      handler (val) {
        this.disabled = val;
      },
      immediate: true,
    },

    form (val) {
      this.$emit('input', val);
    },

    value: {
      handler (val) {
        this.form = val;
      },
      immediate: true
    }
  },
  computed: {

    ...mapState({
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      },
    }),

    userTypeOpts () {
      if (this.isOnline) {
        return [{label: '未登录', value: 'logout'}, {label: '仅登录', value: 'login'}, {label: '下单未成交', value: 'order'}, {label: '成交', value: 'deal'}, {label: '全部', value: ''}];
      }
      return [{label: '未注册', value: 'unreg'}, {label: '未下单', value: 'unorder'}, {label: '下单未成交', value: 'order'}, {label: '成交', value: 'deal'}, {label: '全部', value: ''}];
    },

    regTypeOpts () {
      if (this.isOnline) {
        return [{label: '小程序', value: 'mp'}, {label: 'APP', value: 'app'}];
      }
      return [{label: '电话用户', value: 'tel'}, {label: '购买、交换等其他', value: 'other'}];
    },

    showPath () {
      return this.form && ['order', 'deal'].includes(this.form.userType) ;
    }
  },
  data () {
    return {
      couponOpts: [{label: '没使用优惠券', value: 'noCoupon'}, {label: '没使用早价优惠', value: 'noAhead'}, {label: '优惠小于10元', value: 'littleCoupon'}, {label: '没有参加邀请优惠', value: 'noInvite'}, {label: '可以积分兑换', value: 'points'}],
      vipOpts: [{label: 'vip用户', value: true}, {label: '非vip用户', value: false}, {label: '全部', value: ''}],
      form: null,
      disabled: false,
    };
  },
  methods: {
    handleSourceChange () {
      this.$set(this.form, 'userType', null);
    },
  },
};
</script>
<style lang="less" scoped>
</style>