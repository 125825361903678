<template>
  <div class="root-box">
    <div>
      <el-button @click="add" type="primary" size="small" style="width: 80px"
        >新增</el-button
      >
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column label="超级管理员">
          <template slot-scope="scope">
            <span>{{ scope.row.username === superAdmin ? "是" : "否" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="username" label="用户名"></el-table-column>

        <el-table-column prop="name" label="姓名"></el-table-column>

        <el-table-column prop="phoneNumber" label="手机号"></el-table-column>

        <el-table-column prop="agentNo" label="坐席编号"></el-table-column>

        <el-table-column prop="roleName" label="角色">
          <template slot-scope="scope">
            <span>{{ scope.row.roleName ? scope.row.roleName : "超管" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status ? "正常" : "停用" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <span>
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.username === superAdmin"
                @click="openEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                v-if="scope.row.status"
                :disabled="scope.row.username === superAdmin"
                type="text"
                size="small"
                @click="bannedUser(scope.row, 0)"
                >封号</el-button
              >
              <el-button
                v-else
                type="text"
                size="small"
                :disabled="scope.row.username === superAdmin"
                @click="bannedUser(scope.row, 1)"
                >解封</el-button
              >
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.username === superAdmin"
                @click="resetPwd(scope.row)"
                >重置密码</el-button
              >

              <!--                            <el-button type="text" size="small" @click="openEdit(scope.row)">重置密码</el-button>-->
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible="dialogVisible"
      :before-close="closeDiag"
      width="600px"
      max-height="600"
      :close-on-click-modal="false"
    >
      <el-form
        ref="userForm"
        :model="form"
        label-width="80px"
        :rules="rules"
        size="small"
        label-position="top"
      >
        <el-form-item label="用户名：" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>

        <el-form-item label="角色：" prop="roleIds">
          <el-select
            v-model="form.roleIds"
            placeholder="请选择"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.description"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="姓名：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="坐席：" prop="agentNo">
          <el-select
            v-model="form.agentNo"
            placeholder="请选择坐席"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in agentNoList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="手机号：">
          <el-input v-model="form.phoneNumber"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" size="small" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" @click="closeDiag" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {resetPwd} from '@/api';
import _ from 'lodash';
const MODE = {
  EDIT: 1,
  ADD: 2
};
export default {
  created () {
    this.getList();
    this.queryRoleList();
  },
  data () {
    return {
      MODE,
      dialogVisible: false,
      roleList: [],
      agentNoList: ['6000', '6001', '6002', '6003', '6004', '6005', '6006', '6007', '6008', '6009', '6010'],
      rules: {
        username: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
        ],
        roleIds: [
          {required: true, message: '请至少选择一个角色', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
      },
      props: {
        label: 'text',
        children: 'children'
      },
      form: {},
      selectKeys: ['2'],
      list: [],
      superAdmin: 'yxAdmin'

    };
  },
  methods: {
    add () {
      this.mode = MODE.ADD;
      this.form = {};
      this.dialogVisible = true;
    },
    closeDiag () {
      this.dialogVisible = false;
    },
    queryRoleList () {
      const url = '/admin/permission/role/list';
      const param = {
        page: {
          size: 100
        },
        param: {
          status: 1
        }
      };
      this.$http.post(url, param).then((res) => {
        this.roleList = res.data.records || [];
      });
    },
    getList () {
      const url = '/admin/list';
      const param = {
        page: {
          size: 100,
        }
      };
      this.$http.post(url, param).then((res) => {
        this.list = res.data.records || [];
      });
    },
    saveOrUpdate () {
      const mode = this.mode;
      if (mode === MODE.ADD) {
        this.save();
      } else if (mode === MODE.EDIT) {
        this.update();
      }
    },
    save () {
      this.$refs.userForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const url = '/admin/add';
        const param = {
          ...this.form,
        };
        this.$http.post(url, param).then(() => {
          this.getList();
          this.closeDiag();
        });
      });
    },
    update () {
      this.$refs.userForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const url = '/admin/modify';
        const param = this.form;
        this.$http.post(url, param).then(() => {
          this.getList();
          this.closeDiag();
        });
      });
    },
    bannedUser (item, status) {
      const url = '/admin/modify';
      const param = item;
      item.status = status;
      this.$http.post(url, param).then((res) => {
        const {data = {}} = res || {};
        this.form = data;

      });
    },
    resetPwd (item) {
      resetPwd(item).then(()=>{
        this.$message.success("重置成功");
      });
    },
    openEdit (item) {
      this.mode = MODE.EDIT;
      this.dialogVisible = true;
      this.form = _.clone(item);
    },

  }
};
</script>

<style scoped lang="less">
.table {
  margin-top: 10px;
}
</style>
