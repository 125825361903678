import { Http } from "@/util";

export function listReceipt(param) {
  return Http.post("/admin/invoice/list", param);
}

export function listDetails(orderIds) {
  return Http.get("/admin/invoice/order/detail/list", { orderIds });
}

export function addReceipt(param) {
  return Http.post("/admin/invoice/add", param);
}

export function modifyReceipt(param) {
  return Http.post("/admin/invoice/update", param);
}

export function uploadInvoice(param) {
  return Http.post("/admin/invoice/upload/invoice", param);
}
