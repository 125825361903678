<template>
  <div id="root">
    <div class="query-container" style="padding-bottom: 20px">
      <el-date-picker
        v-model="param.startDate"
        placeholder="起始日期"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="width: 200px; margin-right: 10px"
      ></el-date-picker>
      <el-date-picker
        v-model="param.endDate"
        placeholder="截止日期"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="width: 200px; margin-right: 10px"
      ></el-date-picker>
      <el-input
        v-model="param.driverNo"
        placeholder="司机编号"
        style="width: 200px; margin-right: 10px"
      ></el-input>
      <el-button type="primary" @click="query">查询</el-button>
    </div>
    <el-table :data="evaluations" stripe max-height="600" style="width: 100%; margin-left: 20px">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="日期" prop="date" width="120"></el-table-column>
      <el-table-column label="车队" prop="teamName" width="120"></el-table-column>
      <el-table-column label="司机编号" prop="driverNo" width="80"></el-table-column>
      <el-table-column label="线路" prop="pathName" width="120"></el-table-column>
      <el-table-column label="订单编号" prop="orderIndex" width="120"></el-table-column>
      <el-table-column label="评价大类">
        <template slot-scope="scope">
          {{ scope.row.evaType | enumTransfer(evaTypeOpts) }}
        </template>
      </el-table-column>
      <el-table-column label="评价小类">
        <template slot-scope="scope">
          {{ scope.row.type | enumTransfer(typeOpts) }}
        </template>
      </el-table-column>
      <el-table-column label="质检员" prop="checkerName" width="80"></el-table-column>
      <el-table-column label="是否申诉成功" prop="appealed" width="80"></el-table-column>
      <el-table-column label="评价项" prop="evaItemName" width="100"></el-table-column>
      <el-table-column label="附件" width="100">
        <template slot-scope="scope">
          <el-image
            style="width: 30px; height: 30px; margin-right: 5px"
            fit="fit"
            v-for="annexe in scope.row.annexes"
            :src="annexe"
            :key="annexe"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="来源渠道" width="100">
        <template slot-scope="scope">
          {{ scope.row.channel | enumTransfer(channelOpts) }}
        </template>
      </el-table-column>
      <el-table-column label="已生成罚单" width="100">
        <template slot-scope="scope">
          {{ scope.row.punishBillid ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="viewDetail(scope.row)" type="text" size="small">详情</el-button>
          <el-button @click="gotoPunish(scope.row)" type="text" size="small">开罚单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        :current-page="page.pageNo"
        :page-sizes="pageSizes"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        @size-change="onPageSizeChange"
        @current-change="onPageNoChange"
      ></el-pagination>
    </div>

    <el-dialog :visible.sync="showViewPannel" title="查看详情" width="800px">
      <div class="evaluate-view">
        <div class="title">司机评价</div>

        <div class="row">
          <div>司机信息</div>
          <div>
            司机编号：{{ currentEvaluation.driverNo }}
            <span v-if="driverInfo.id"
              >{{ driverInfo.name }} {{ driverInfo.teamName }}
              {{ driverInfo.seats + "座" + driverInfo.vehicleColor + driverInfo.brand }}
              {{ driverInfo.telephone }} {{ driverInfo.registerTime }}注册</span
            >
          </div>
          <el-divider></el-divider>
        </div>

        <div class="row">
          <div>评价日期: {{ currentEvaluation.date }}</div>
          <div>服务路线: {{ currentEvaluation.pathName }}</div>
          <div>服务订单: {{ currentEvaluation.orderIndex }}</div>
          <div>评价来源: {{ currentEvaluation.channel | enumTransfer(channelOpts) }}</div>
        </div>

        <div class="row">
          <div>服务大类：{{ currentEvaluation.evaType | enumTransfer(evaTypeOpts) }}</div>
          <div>服务小类：{{ currentEvaluation.type | enumTransfer(typeOpts) }}</div>
          <el-divider></el-divider>
        </div>

        <div class="row" v-if="currentEvaluation.annexes">
          <div>附件：</div>
          <div
            v-for="annex in currentEvaluation.annexes"
            :key="annex"
            class="media-box"
            :class="isAudio(annex) ? 'media-box-audio' : ''"
          >
            <img v-if="isImage(annex)" :src="annex" style="width: 100px; height: 100px" />
            <i
              class="el-icon-video-play"
              v-if="isVedio(annex)"
              style="font-size: 28px"
              :src="annex"
            ></i>
            <audio v-if="isAudio(annex)" controls style="width: 120px; height: 28px"></audio>
          </div>
        </div>

        <div class="row">
          <el-button @click="showViewPannel = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showAddPannel" title="新增/编辑" width="800px" class="evaluate">
      <div class="row">
        <el-input
          class="evaCommonElement"
          v-model="currentEvaluation.driverNo"
          placeholder="请输入司机编号"
        ></el-input>
        <span v-if="driverInfo.id"
          >司机:{{ driverInfo.name }} {{ driverInfo.no }} {{ driverInfo.teamName }}
          {{ driverInfo.seats + "座" + driverInfo.vehicleColor + driverInfo.brand }}
          {{ driverInfo.telephone }} {{ driverInfo.registerTime }}注册</span
        >
      </div>

      <div class="row">
        <el-date-picker
          class="evaCommonElement"
          v-model="currentEvaluation.date"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="评价日期"
        >
        </el-date-picker>

        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.pathId"
          clearable
          placeholder="服务路线"
        >
          <el-option v-for="item in paths" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.routeId"
          clearable
          placeholder="行程"
        >
          <el-option
            v-for="item in routeOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.orderId"
          clearable
          placeholder="服务订单"
        >
          <el-option
            v-for="item in orderOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.channel"
          clearable
          placeholder="来源"
        >
          <el-option
            v-for="item in channelOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="row">
        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.evaType"
          clearable
          placeholder="服务大类"
        >
          <el-option
            v-for="item in evaTypeOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-select
          class="evaCommonElement"
          v-model="currentEvaluation.type"
          clearable
          placeholder="服务小类"
        >
          <el-option
            v-for="item in filterTypeOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="row">
        <div
          v-for="annex in currentEvaluation.annexes"
          :key="annex"
          class="media-box"
          :class="isAudio(annex) ? 'media-box-audio' : ''"
        >
          <img v-if="isImage(annex)" :src="annex" class="itm-image" />
          <i
            class="el-icon-video-play"
            v-if="isVedio(annex)"
            style="font-size: 28px"
            :src="annex"
          ></i>
          <audio v-if="isAudio(annex)" controls style="width: 120px; height: 28px"></audio>
          <i class="el-icon-circle-close icon-del" @click="removeAnnex(annex)"></i>
        </div>

        <file-upload
          class="media-box"
          ref="uploader"
          :onUploadSuccess="
            (src) => {
              addAnnex(null, src);
            }
          "
          filePath="/driver_train"
          v-if="refresh"
        >
          <i class="el-icon-plus"></i>
        </file-upload>
      </div>

      <div class="row">
        <el-button @click="showAddPannel = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ParamUtil, FileUtil } from "@/util/index";
import { listEvaluations, getRouteList } from "@/api";
import { mapState } from "vuex";

export default {
  name: "driverEvaluation",
  data() {
    return {
      filterTypeOpts: [],
      orderOpts: [],
      routeOpts: [],
      pageSizes: [10, 30, 50],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      param: {
        driverNo: "",
        startDate: null,
        endDate: null,
      },
      evaluations: [],
      showViewPannel: false,
      showAddPannel: false,
      currentEvaluation: {
        pathId: null,
        orderId: null,
        driverId: null,
        driverNo: null,
        teamId: null,
        channel: null,
        evaType: null,
        type: null,
        annexes: [],
        punishType: "",
      },
      driverQueryFunc: null,
      driverInfo: {},
      refresh: true,
    };
  },
  watch: {
    "currentEvaluation.evaType"(val) {
      if (
        this.currentEvaluation.type &&
        String(this.currentEvaluation.type).indexOf(val + "-") != 0
      ) {
        this.currentEvaluation.type = "";
      }
      this.filterTypeOpts = [];
      this.typeOpts.forEach((opt) => {
        if (String(opt.value).indexOf(val + "-") == 0) {
          this.filterTypeOpts.push(opt);
        }
      });
    },
    "currentEvaluation.driverNo"(val) {
      if (this.driverQueryFunc) {
        clearTimeout(this.driverQueryFunc);
      }
      this.driverInfo = {};
      this.driverQueryFunc = setTimeout(() => {
        if (val) {
          this.$http.post("/admin/driver/punishbill/finddriver", { carNo: val }).then((res) => {
            this.driverInfo = res.data || {};
            this.currentEvaluation.driverId = this.driverInfo.id;
            this.currentEvaluation.teamId = this.driverInfo.team;
            this.queryOrders();
          });
        }
      }, 1000);
    },
    "currentEvaluation.date"() {
      this.queryRoutes();
    },
    "currentEvaluation.pathId"() {
      this.queryRoutes();
    },
    "currentEvaluation.routeId"() {
      this.queryOrders();
    },
  },
  mounted() {
    this.query();
  },
  computed: {
    ...mapState({
      teams: (state) => state.teamOpts,
      paths: (state) => state.pathList,
      evaTypeOpts: (state) => state.enumMap["DRIVER_EVA_TYPE"],
      typeOpts: (state) => state.enumMap["DRIVER_EVA_OPT_TYPE"],
      channelOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
    }),
  },
  methods: {
    query() {
      this.resetPage();
      this.doQuery();
    },
    resetPage() {
      this.page = {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      };
    },
    doQuery() {
      const p = Object.assign(
        { pageNo: this.page.pageNo, pageSize: this.page.pageSize },
        this.param
      );
      listEvaluations(ParamUtil.filterEmptyData(p)).then((res) => {
        var evaluations = res.data.records || [];
        this.page.total = res.data.total;
        this.page.pageNo = res.data.current;
        this.page.pageSize = res.data.size;
        this.page.total = res.data.total;
        for (var i = 0; i < evaluations.length; i++) {
          for (var j = 0; j < this.teams.length; j++) {
            if (evaluations[i].teamId == this.teams[j].id) {
              evaluations[i]["teamName"] = this.teams[j]["name"];
              break;
            }
          }
          for (var k = 0; k < this.paths.length; k++) {
            if (evaluations[i].pathId == this.paths[k].id) {
              evaluations[i]["pathName"] = this.paths[k]["name"];
              break;
            }
          }
        }
        this.evaluations = evaluations;
      });
    },
    onPageSizeChange(pageSize) {
      if (this.page.pageSize != pageSize) {
        this.page.pageSize = pageSize;
        this.doQuery();
      }
    },
    onPageNoChange(pageNo) {
      if (this.page.pageNo != pageNo) {
        this.page.pageNo = pageNo;
        this.doQuery();
      }
    },
    viewDetail(evaluation) {
      this.driverInfo = {};
      this.currentEvaluation = evaluation;
      this.showViewPannel = true;
    },
    queryRoutes() {
      if (this.currentEvaluation.date && this.currentEvaluation.pathId && this.driverInfo.id) {
        let params = {
          date: this.currentEvaluation.date,
          pathId: this.currentEvaluation.pathId,
          driverId: this.driverInfo.id,
        };
        getRouteList(params).then((res) => {
          // TODO
          // this.currentEvaluation.routeId = null;
          this.routeOpts = res.data || [];
        });
      } else {
        this.routeOpts = [];
        this.currentEvaluation.routeId = null;
      }
    },
    queryOrders() {},
    gotoPunish(evaluation) {
      if (evaluation.punishBillid) {
        this.$message.error("已生成罚单，不可重复生成。");
        return;
      }
      this.$router.push({ name: "PunishBill", params: { evaluation: evaluation } });
    },
    addEvaluation() {
      this.currentEvaluation = {
        pathId: null,
        orderId: null,
        driverId: null,
        driverNo: null,
        teamId: null,
        channel: null,
        evaType: null,
        type: null,
        annexes: [],
        punishType: "",
      };
      this.driverInfo = {};
      this.showAddPannel = true;
    },
    addAnnex(response, src) {
      let annexes = this.currentEvaluation.annexes || [];
      annexes.push(src);
      this.currentEvaluation.annexes = annexes;
      this.refresh = false;
      setTimeout(() => {
        this.refresh = true;
      }, 100);
    },
    removeAnnex(src) {
      let annexes = this.currentEvaluation.annexes || [];
      for (var i = 0; i < annexes.length; i++) {
        if (src == annexes[i]) {
          annexes.splice(i, 1);
          break;
        }
      }
    },

    isImage(src) {
      return FileUtil.isImage(src);
    },
    isVedio(src) {
      return FileUtil.isVedio(src);
    },
    isAudio(src) {
      return FileUtil.isAudio(src);
    },
  },
};
</script>

<style lang="less" scoped>
.evaButton {
  position: absolute;
  right: 10px;
  margin-top: 10px;
}

.row {
  margin-bottom: 10px;
}

.evaCommonElement {
  width: 140px !important;
  margin-right: 10px;
}

.evaluate .el-upload-dragger {
  width: 28px;
  height: 28px;
  border-radius: 2px;
  padding-top: 4px;
}

.evaluate .media-box {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: top;
  position: relative;
}

.media-box-audio {
  width: 120px !important;
}

.evaluate .el-upload-list {
  display: none;
}

.evaluate .itm-image {
  width: 100%;
  height: 100%;
}

.icon-del {
  position: absolute;
  top: -6px;
  right: -6px;
}

.evaluate-view {
  .title {
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
  }
}
</style>
