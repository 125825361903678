<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" label-width="100px" :inline="true">
        <el-row>
          <el-col>
            <el-form-item label="乘客手机号：">
              <el-input v-model="param.telephone" style="width: 200px" clearable />
            </el-form-item>

            <el-form-item label="VIP等级">
              <el-select
                style="width: 200px"
                v-model="param.vipLevel"
                placeholder="请选择"
                @change="query()"
                clearable
              >
                <el-option
                  v-for="item in vipOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="注册渠道：">
              <el-select
                style="width: 200px"
                v-model="param.regChannel"
                placeholder="请选择"
                @change="query()"
                clearable
              >
                <el-option
                  v-for="item in registerChannelOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="黑名单：">
              <el-select
                style="width: 200px"
                v-model="param.hmd"
                placeholder="请选择"
                @change="query()"
                clearable
              >
                <el-option
                  v-for="item in yesNoOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="注册邀请码：">
              <el-input v-model="param.inviteCode" style="width: 200px" clearable />
            </el-form-item>

            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                value-format="yyyy-MM-dd 00:00:00"
                v-model="param.startTime"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker
                value-format="yyyy-MM-dd 23:59:59"
                v-model="param.endTime"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="680" @sort-change="handleSort">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="name" label="昵称" width="80"></el-table-column>

        <el-table-column prop="gender" label="性别" width="80">
          <template slot-scope="scope">{{ scope.row.gender ? "男" : "女" }}</template>
        </el-table-column>
        <!--
        <el-table-column prop="age" label="年龄"></el-table-column>

        <el-table-column prop="career" label="职业"></el-table-column>-->

        <el-table-column prop="telephone" width="150" sortable="custom" label="手机号">
          <template slot-scope="scope">
            <span v-click-tel="scope.row.telephone">{{ scope.row.telephone | encryptTel }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="vipLevel"
          sortable="custom"
          label="VIP"
          width="100"
        ></el-table-column>

        <el-table-column prop="hmd" sortable="custom" label="开启支付" width="150">
          <template slot-scope="scope">{{ scope.row.enablePay ? "是" : "否" }}</template>
        </el-table-column>

        <el-table-column prop="regChannel" width="150" label="注册渠道">
          <template slot-scope="scope">
            <span>{{ scope.row.regChannel | enumTransfer(registerChannelOpts) }}</span>

            <span v-if="scope.row.regChannel === 4">
              <span v-if="scope.row.inviteCheckResult === 1"> （真） </span>
              <span v-if="scope.row.inviteCheckResult === 0"> （假） </span>
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="shareTimes"
          sortable="custom"
          width="150"
          label="分享次数"
        ></el-table-column>

        <el-table-column prop="recommendCode" label="推荐码" width="150"></el-table-column>

        <el-table-column prop="inviteCode" label="注册邀请码" width="150"></el-table-column>

        <el-table-column prop="canExemption" width="150" label="能否免责">
          <template slot-scope="scope">
            <span :class="{ red: !scope.row.canExemption }">{{
              scope.row.canExemption ? "可免责" : "不可免责"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="canExemption" width="150" label="免费携带儿童">
          <template slot-scope="scope">
            <span :class="{ red: scope.row.freeChildUsed }">{{
              scope.row.freeChildUsed ? "已使用" : "未使用"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="hmd" sortable="custom" label="黑名单" width="150">
          <template slot-scope="scope">{{ scope.row.hmd ? "是" : "否" }}</template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          width="200"
          show-overflow-tooltip
          sortable="custom"
        ></el-table-column>

        <el-table-column
          prop="wxRegisterTime"
          width="200"
          show-overflow-tooltip
          sortable="custom"
          label="微信注册时间"
        ></el-table-column>

        <el-table-column
          prop="telRegisterTime"
          width="200"
          show-overflow-tooltip
          sortable="custom"
          label="电话注册时间"
        ></el-table-column>

        <el-table-column
          prop="appRegisterTime"
          width="200"
          show-overflow-tooltip
          sortable="custom"
          label="APP注册时间"
        ></el-table-column>

        <el-table-column prop="id" label="操作" width="130" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="go2OrderList(scope.row)"
              >订单记录</el-button
            >

            <el-dropdown
              @command="(command) => handleCommand(command, scope.row)"
              style="margin-left: 10px"
              class="dropdown-meun"
            >
              <span class="el-dropdown-link">
                操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">{{
                  scope.row.hmd ? "解封账号" : "封禁账号"
                }}</el-dropdown-item>
                <!-- <el-dropdown-item command="7">邀请记录</el-dropdown-item> -->
                <el-dropdown-item command="2">无法注册</el-dropdown-item>
                <el-dropdown-item command="3">重置账户</el-dropdown-item>
                <el-dropdown-item command="4">强制下线</el-dropdown-item>
                <el-dropdown-item command="5">拉黑司机</el-dropdown-item>
                <el-dropdown-item command="6">{{
                  scope.row.sysWarn ? "解除预警" : "加入预警"
                }}</el-dropdown-item>
                <el-dropdown-item command="7">封禁邀请功能</el-dropdown-item>
                <el-dropdown-item command="8">使用首次免责</el-dropdown-item>
                <el-dropdown-item command="9">使用免费携带儿童</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :visible="blackVisible" title="拉黑司机" width="500px">
      <div>
        <div>
          <el-table :data="blackList" stripe>
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>

            <el-table-column label="姓名" prop="name" show-overflow-tooltip></el-table-column>

            <el-table-column label="司机编号" prop="no" show-overflow-tooltip></el-table-column>

            <el-table-column label="车坐数" prop="seats" show-overflow-tooltip></el-table-column>

            <el-table-column label="操作" prop="id">
              <template slot-scope="scope">
                <el-button type="text" @click="cancelBeBlack(scope.row)">取消拉黑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div style="margin-top: 20px">
        <el-form ref="blackForm" size="small" :model="blackForm" label-width="120px" :rules="rules">
          <el-form-item label="司机：" prop="driverId">
            <el-select
              style="width: 300px"
              :size="size"
              v-model="blackForm.driverId"
              filterable
              remote
              clearable
              placeholder="请输入关键词"
              :remote-method="getDriver"
              :loading="loading"
            >
              <el-option
                v-for="item in driverOptions"
                :key="item.id"
                :label="`${item.name} ${item.no} ${item.vehicleNo}`"
                :value="item.id"
              >
                <div style="display: flex; justify-content: space-between">
                  <span style="flex: 1">{{ item.name }}</span>
                  <span style="flex: 1">{{ item.no }}</span>
                  <span style="flex: 1">{{ item.vehicleNo }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注：" prop="remark">
            <el-input v-model="blackForm.remark" style="width: 300px"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="add2BlackList">加入拉黑</el-button>
        <el-button size="small" @click="blackVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPassengerList,
  updatePassenger,
  resetOpenid,
  sendResetCode,
  kickPassengerOut,
  getDriverList,
  getBlackDriver,
  cancelBlackDriver,
  changeSysWarn,
  setInviteRisk,
  useExemptionChance,
  saveBlackDriver,
} from "@/api";
import { mapState } from "vuex";
import { ParamUtil, Toolkit } from "@/util";

export default {
  created() {
    const tel = this.$route.query.tel;
    if (tel) {
      this.$set(this.param, "telephone", tel);
    } else {
      const id = this.$route.query.id;
      if (id) {
        this.$set(this.param, "id", id);
      }
    }
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      yesNoOpts: (state) => state.enumMap["YES_NO_ENUM"],
      registerChannelOpts: (state) => state.enumMap["REG_CHANNEL"],
      registerTypeOpts: (state) => state.enumMap["REGISTER_TYPE"],
    }),
  },
  data() {
    return {
      param: {},
      statusOpts: [
        {
          label: "待处理",
          value: 0,
        },
        {
          label: "处理中",
          value: 1,
        },
        {
          label: "已处理",
          value: 2,
        },
      ],
      vipOpts: [
        {
          label: "VIP1",
          value: 1,
        },
        {
          label: "VIP2",
          value: 2,
        },
        {
          label: "VIP3",
          value: 3,
        },
        {
          label: "VIP4",
          value: 4,
        },
        {
          label: "VIP5",
          value: 5,
        },
      ],
      rules: {
        driverId: [{ required: true, message: "请选择司机", trigger: "blur" }],
      },
      form: {},
      dialogVisible: false,
      list: [],
      total: 0,
      currentPage: 1,
      asc: false,
      column: "",
      blackVisible: false,
      blackForm: {},
      driverOptions: [],
      blackList: [],
    };
  },
  methods: {
    query(page = 1, pageSize = 10) {
      const param = {
        page: {
          current: page,
          size: pageSize,
        },
        param: ParamUtil.filterEmptyData(this.param),
        endTime: this.param.endTime,
        startTime: this.param.startTime,
      };

      if (this.column) {
        param.page.orders = [
          {
            column: this.column,
            asc: this.asc,
          },
        ];
      }
      getPassengerList(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    getDriver(key) {
      if (key && key.length > 1) {
        const _param = {
          param: { no: key },
          page: {
            current: 1,
            size: 10,
          },
        };
        getDriverList(_param).then((res) => {
          const { records = [] } = res.data || {};
          this.driverOptions = records;
        });
      }
    },

    handleCommand(command, item) {
      command = parseInt(command);
      this.currentSelect = item;
      switch (command) {
        case 1:
          this.changeHmd(item);
          break;
        case 2:
          this.sendCode(item);
          break;
        case 3:
          this.resetAccount(item);
          break;
        case 4:
          this.kickOut(item);
          break;
        case 5:
          this.showBlackModal(item);
          break;
        case 6:
          this.sysWarn(item);
          break;
        case 7:
          this.inviteRisk(item);
          break;
        case 8:
          this.useExemption(item);
          break;
        case 9:
          this.useFreeChild(item);
          break;
      }
    },

    useFreeChild(item) {
      this.$confirm("确认要使用免费携带儿童吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(
        () => {
          item.freeChildUsed = true;
          updatePassenger(item).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        },
        () => {}
      );
    },

    useExemption() {
      this.$confirm("确认要使用免责次数吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(
        () => {
          useExemptionChance(this.currentSelect.id).then(() => {
            this.query();
            this.$message.success("操作成功");
          });
        },
        () => {}
      );
    },

    showBlackModal(item) {
      this.blackVisible = true;
      this.blackForm = {
        passengerId: item.id,
      };
      getBlackDriver(this.currentSelect.id).then((res) => {
        this.blackList = res.data || [];
      });
    },

    inviteRisk(item) {
      this.$confirm("确认要封禁邀请功能吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(
        () => {
          setInviteRisk(item.id).then(() => {
            this.$message.success("操作成功");
          });
        },
        () => {}
      );
    },

    cancelBeBlack(item) {
      const pamra = {
        driverId: item.id,
        passengerId: this.currentSelect.id,
      };
      cancelBlackDriver(pamra).then(() => {
        getBlackDriver(this.currentSelect.id).then((res) => {
          this.blackList = res.data || [];
        });
      });
    },

    kickOut(item) {
      kickPassengerOut(item.id).then(() => {
        this.$message.success("操作成功");
      });
    },
    sendCode(item) {
      this.$confirm("确认需要重置账户吗，确认将发送验证码给乘客！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(
        () => {
          sendResetCode(item.id).then(() => {
            this.$message.success("验证码发送成功");
          });
        },
        () => {}
      );
    },
    resetAccount(item) {
      this.$prompt(`请输入验证码`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      }).then(
        ({ value }) => {
          resetOpenid(item.id, value).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        },
        () => {}
      );
    },
    changeHmd(item) {
      const msg = item.hmd ? "解封" : "封号";
      this.$prompt(`请输入${msg}原因`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{5,}/,
        inputErrorMessage: `请输入${msg}原因(最少5个字)`,
        closeOnClickModal: false,
      }).then(
        ({ value }) => {
          item.hmd = !item.hmd;
          item.remark = item.remark
            ? `${item.remark}\n ${msg}原因：${value}`
            : `${msg}原因：${value}`;
          updatePassenger(item).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        },
        () => {}
      );
    },

    changePayFunc(item) {
      item.enablePay = !item.enablePay;
      if (item.enablePay) {
        updatePassenger(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      } else {
        this.$confirm("确认要关闭支付功能吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(
          () => {
            updatePassenger(item).then(() => {
              this.$message.success("操作成功");
              this.query();
            });
          },
          () => {}
        );
      }
    },

    sysWarn(item) {
      const sysWarn = !item.sysWarn;
      if (sysWarn) {
        this.$prompt(`请输入预警原因`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /[\s\S]{5,}/,
          inputErrorMessage: `请输入预警原因(最少5个字)`,
          closeOnClickModal: false,
        }).then(
          ({ value }) => {
            const param = {
              sysWarn: true,
              telephone: this.currentSelect.telephone,
              passengerId: this.currentSelect.id,
              remark: value,
            };
            changeSysWarn(param).then(() => {
              this.$message.success("操作成功");
              this.query();
            });
          },
          () => {}
        );
      } else {
        this.$confirm("确认解除预警吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(
          () => {
            const param = {
              sysWarn: false,
              telephone: this.currentSelect.telephone,
              passengerId: this.currentSelect.id,
            };
            changeSysWarn(param).then(() => {
              this.$message.success("操作成功");
              this.query();
            });
          },
          () => {}
        );
      }
    },

    handleSort(item) {
      if (item.order) {
        this.asc = item.order.indexOf("asc") > -1;
        this.column = Toolkit.toLine(item.prop);
        this.query();
      } else {
        this.column = null;
      }
    },
    go2OrderList(item) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: { tel: item.telephone },
      });
      window.open(routeUrl.href, "_blank");
    },
    handleCurrentChange(page) {
      this.query(page);
    },
    handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.query(1, pageSize);
    },

    add2BlackList() {
      this.$refs.blackForm.validate((valid) => {
        if (valid) {
          if (valid) {
            saveBlackDriver(this.blackForm).then(() => {
              this.$message.success("操作成功");
              getBlackDriver(this.currentSelect.id).then((res) => {
                this.blackList = res.data || [];
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
