<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="来源类型：">
          <el-select v-model="param.originType" placeholder="请选择" clearable @change="query()">
            <el-option
              v-for="item in originTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车辆类型：">
          <el-select v-model="param.vehicleType" placeholder="请选择" clearable @change="query()">
            <el-option
              v-for="item in vehicleTypeOpts"
              :key="item.id"
              :label="item.content"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车辆状态：">
          <el-select v-model="param.status" placeholder="请选择" clearable @change="query()">
            <el-option
              v-for="item in vehicleStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="车牌号：">
          <el-input v-model="param.vehicleNo" clearable style="width: 180px" />
        </el-form-item>

        <el-form-item label="司机姓名：">
          <el-input v-model="param.bindDriverName" clearable style="width: 180px" />
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button style="width: 100px" plain type="primary" @click="addVehicle()"
            >新增车辆</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600" @row-dblclick="(e) => showDetail(e, true)">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column width="150" label="车辆图片">
          <template slot-scope="scope">
            <el-image
              style="width: 80px; height: 60px"
              v-if="scope.row.drivingLicensePic1"
              :src="scope.row.drivingLicensePic1"
              :preview-src-list="[scope.row.drivingLicensePic1]"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column prop="vehicleNo" label="车牌号"></el-table-column>
        <el-table-column prop="bindDriverName" label="司机"></el-table-column>

        <el-table-column prop="addressCode" label="行政区划"></el-table-column>

        <el-table-column prop="originType" label="来源类型">
          <template slot-scope="scope">{{
            scope.row.originType | enumTransfer(originTypeOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="ownerName" label="车辆所属者"></el-table-column>

        <el-table-column prop="vehicleType" label="车辆类型">
          <template slot-scope="scope">{{
            scope.row.vehicleType | enumTransfer(vehicleTypeOpts, "id", "content")
          }}</template>
        </el-table-column>

        <el-table-column prop="vehicleColor" label="车身颜色"></el-table-column>
        <el-table-column prop="brand2" label="汽车品牌"></el-table-column>
        <el-table-column prop="etcStatus" label="ETC状态"></el-table-column>
        <el-table-column prop="etcOrderId" label="ETC订单ID"></el-table-column>
        <el-table-column prop="gpsImei" label="gpsImei"></el-table-column>
        <el-table-column prop="videoDeviceId" label="videoDeviceId"></el-table-column>
        <el-table-column prop="videoSim" label="videoSim"></el-table-column>

        <!-- <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column> -->

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-dropdown @command="(type) => handleEvent(type, scope.row)">
              <span class="el-dropdown-link">
                操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">ETC办理</el-dropdown-item>
                <el-dropdown-item command="1">录入保险信息</el-dropdown-item>
                <el-dropdown-item command="2">查看保险信息</el-dropdown-item>
                <el-dropdown-item command="3">修改车辆详情</el-dropdown-item>
                <el-dropdown-item command="5">{{
                  scope.row.status === "ACTIVE" ? "停用车辆" : "启用车辆"
                }}</el-dropdown-item>
                <el-dropdown-item command="4">上报数据</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :visible="imgVisible">
      <div class="img-container">
        <img :src="dialogImageUrl" alt style="background-size: contain" />
      </div>
    </el-dialog>

    <el-dialog
      title="新增保险信息"
      :visible="insuranceVisible"
      width="600px"
      :before-close="closeInsurance"
    >
      <el-form
        ref="insuranceForm"
        :model="insuranceForm"
        label-width="80px"
        size="small"
        :rules="insuranceRules"
        label-position="top"
      >
        <el-form-item label="保险公司名称：" prop="insurCom">
          <el-input v-model="insuranceForm.insurCom"></el-input>
        </el-form-item>

        <el-form-item label="保险号：" prop="insurNum">
          <el-input v-model="insuranceForm.insurNum"></el-input>
        </el-form-item>

        <el-form-item label="保险类型：" prop="insurType">
          <el-input v-model="insuranceForm.insurType"></el-input>
        </el-form-item>

        <el-form-item label="保险金额：" prop="insurCount">
          <el-input v-model="insuranceForm.insurCount"></el-input>
        </el-form-item>

        <el-form-item label="保险生效时间：" prop="insurEff">
          <el-date-picker
            placeholder="选择时间"
            @change="handleDateChange"
            v-model="insuranceForm.insurEff"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="保险到期时间：" prop="insurExp">
          <el-date-picker
            placeholder="选择时间"
            v-model="insuranceForm.insurExp"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" size="small" @click="saveInsurance">保存</el-button>
        <el-button type="info" @click="closeInsurance" size="small">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="保险信息"
      :visible="insuranceDetailVisible"
      width="1000px"
      :before-close="closeInsuranceDetail"
    >
      <el-table :data="insuranceList" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column prop="insurCom" label="保险公司名称"></el-table-column>
        <el-table-column prop="insurNum" label="保险号"></el-table-column>
        <el-table-column prop="insurType" label="保险类型"></el-table-column>
        <el-table-column prop="insurCount" label="保险金额"></el-table-column>
        <el-table-column prop="insurEff" label="保险生效时间"></el-table-column>
        <el-table-column prop="insurExp" label="保险到期时间"></el-table-column>
      </el-table>

      <span slot="footer">
        <el-button type="info" @click="closeInsuranceDetail" size="small">关 闭</el-button>
      </span>
    </el-dialog>

    <el-drawer
      size="1700px"
      :visible.sync="vehicleDetailVisible"
      :append-to-body="true"
      direction="ltr"
      :destroy-on-close="true"
    >
      <div class="drawer-table">
        <VehicleDetail :data="vehicleInfo" :readonly="readonly" @update="query(currentPage)" />
      </div>
    </el-drawer>

    <el-dialog title="ETC办理信息" :visible="etcVisible" width="1200px">
      <el-form
        ref="etcForm"
        :model="etcForm"
        label-width="80px"
        size="small"
        :rules="etcRules"
        label-position="top"
        inline
      >
        <el-form-item label="车牌号：" prop="vehicleNo">
          <el-input v-model="etcForm.vehicleNo"></el-input>
        </el-form-item>

        <el-form-item label="品牌型号：" prop="model">
          <el-input v-model="etcForm.model"></el-input>
        </el-form-item>
        <el-form-item label="车架号：" prop="vin">
          <el-input v-model="etcForm.vin"></el-input>
        </el-form-item>
        <el-form-item label="发动机号：" prop="engineId">
          <el-input v-model="etcForm.engineId"></el-input>
        </el-form-item>

        <el-form-item label="核定载人数（包含司机）：" prop="viAc">
          <el-input v-model="etcForm.viAc"></el-input>
        </el-form-item>
        <el-form-item label="总质量：" prop="viGrossMass">
          <el-input v-model="etcForm.viGrossMass"></el-input>
        </el-form-item>
        <el-form-item label="车长：" prop="viLength">
          <el-input v-model="etcForm.viLength"></el-input>
        </el-form-item>
        <el-form-item label="车高：" prop="viHeight">
          <el-input v-model="etcForm.viHeight"></el-input>
        </el-form-item>
        <el-form-item label="车宽：" prop="viWidth">
          <el-input v-model="etcForm.viWidth"></el-input>
        </el-form-item>
        <el-form-item label="车辆所有着：" prop="ownerName">
          <el-input v-model="etcForm.ownerName"></el-input>
        </el-form-item>

        <el-form-item label="行驶证注册日期：" prop="viRegisterDate">
          <el-date-picker
            placeholder="选择时间"
            v-model="etcForm.viRegisterDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="行驶证发证日期：" prop="viGrantDate">
          <el-date-picker
            placeholder="选择时间"
            v-model="etcForm.viGrantDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="saveEtcInfo" size="small">暂 存</el-button>
        <el-button type="warning" @click="submitEtcInfo" size="small">提 交</el-button>
        <el-button type="info" @click="etcVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import moment from "moment";
import {
  listVehicle,
  listVehicleInsurance,
  addVehicleInsurance,
  getVehicleInfo,
  reportVehicleData,
  saveOrUpdateVehicleInfo,
  bindEtc,
} from "@/api";
import VehicleDetail from "../Detail/detail.vue";
import { ParamUtil } from "@/util";

export default {
  components: {
    VehicleDetail,
  },
  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      originTypeOpts: (state) => state.enumMap["VEHICLE_ORIGIN_TYPE"],
    }),
  },
  data() {
    return {
      param: {
        name: "",
        status: "ACTIVE",
      },
      insuranceForm: {},
      etcForm: {},
      imgVisible: false,
      etcVisible: false,
      vehicleDetailVisible: false,
      insuranceDetailVisible: false,
      insuranceVisible: false,
      list: [],
      insuranceList: [],
      total: 0,
      currentPage: 1,
      step: 1,
      auditResult: BaseDataService.auditOpts()[0].value,
      dialogImageUrl: "",
      auditOpts: BaseDataService.auditOpts(),
      insuranceRules: {
        insurCom: [{ required: true, message: "保险公司名称必填", trigger: "blur" }],
        insurNum: [{ required: true, message: "保险号必填", trigger: "blur" }],
        insurType: [{ required: true, message: "保险类型必填", trigger: "blur" }],
        insurCount: [{ required: true, message: "保险金额必填", trigger: "blur" }],
        insurEff: [{ required: true, message: "保险生效时间必填", trigger: "blur" }],
        insurExp: [{ required: true, message: "保险到期时间必填", trigger: "blur" }],
      },
      etcRules: {
        vehicleNo: [{ required: true, message: "车牌号必填", trigger: "blur" }],
        model: [{ required: true, message: "品牌型号必填", trigger: "blur" }],
        vin: [{ required: true, message: "车架号必填", trigger: "blur" }],
        engineId: [{ required: true, message: "发动机号必填", trigger: "blur" }],
        viAc: [{ required: true, message: "核定载人数必填", trigger: "blur" }],
        viGrossMass: [{ required: true, message: "总质量必填", trigger: "blur" }],
        viLength: [{ required: true, message: "车长必填", trigger: "blur" }],
        viHeight: [{ required: true, message: "车高必填", trigger: "blur" }],
        viWidth: [{ required: true, message: "车宽必填", trigger: "blur" }],
        ownerName: [{ required: true, message: "车辆所有着必填", trigger: "blur" }],
        viRegisterDate: [{ required: true, message: "行驶证注册日期必填", trigger: "blur" }],
        viGrantDate: [{ required: true, message: "行驶证发证日期必填", trigger: "blur" }],
      },
      vehicleStatusOpts: [
        { value: "ACTIVE", label: "正常" },
        { value: "DISABLE", label: "停用" },
      ],
      pageSize: 50,
      vehicleInfo: {},
      readonly: true,
    };
  },
  methods: {
    closeInsuranceDetail() {
      this.insuranceDetailVisible = false;
    },
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param: ParamUtil.filterEmptyData(this.param),
      };
      listVehicle(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleDateChange(val) {
      const day = moment(val);
      day.add(1, "year");
      const endDate = day.format("YYYY-MM-DD");
      this.$set(this.insuranceForm, "insurExp", endDate);
    },
    handlePictureCardPreview(fileUrl) {
      if (fileUrl) {
        this.dialogImageUrl = fileUrl;
        this.imgVisible = true;
      }
    },
    handleEvent(type, item) {
      if (type === "0") {
        this.getEctInfo(item);
      } else if (type === "1") {
        this.editInsurance(item);
      } else if (type === "2") {
        this.viewInsurance(item);
      } else if (type === "3") {
        this.showDetail(item, false);
      } else if (type === "4") {
        reportVehicleData(item.id).then(() => {
          this.$message.success("上报成功");
        });
      } else if (type === "5") {
        this.changeVehicleStatus(item);
      }
    },

    changeVehicleStatus(item) {
      var toDisable = item.status === "ACTIVE";
      this.$confirm(toDisable ? "确定要停用该车辆吗？" : "确定要启用该车辆吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (toDisable) {
            item.status = "DISABLE";
          } else {
            item.status = "ACTIVE";
          }
          saveOrUpdateVehicleInfo(item).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        })
        .catch(() => {});
    },

    getEctInfo(item) {
      getVehicleInfo(item.id).then((res) => {
        this.etcForm = res.data || {};
        // this.etcForm = {
        //   ...res.data,
        //   viLength: "4755",
        //   viHeight: "1503",
        //   viWidth: "1802",
        //   viGrossMass: "1535KG",
        //   ownerName: "武汉吉豪汽车销售有限公司",
        //   model: "帝豪牌HQ7002BEV77",
        //   viAc: 5,
        // };
        // this.etcForm = {
        //   ...res.data,
        //   viLength: "4432",
        //   viHeight: "1560",
        //   viWidth: "1833",
        //   viGrossMass: "1545KG",
        //   ownerName: "武汉优选顺风科技有限公司岳阳分公司",
        //   model: "几何牌JHC7003BEV01",
        //   viAc: 5,
        // // };
        // this.etcForm = {
        //   ...res.data,
        //   viLength: "4650",
        //   viHeight: "1510",
        //   viWidth: "1820",
        //   viGrossMass: "1600KG",
        //   ownerName: "武汉拓步汽车服务有限公司",
        //   model: "北京牌BJ7000C5D3-BEV",
        //   viAc: 5,
        // };
        this.etcVisible = true;
      });
    },

    submitEtcInfo() {
      this.$refs.etcForm.validate((valid) => {
        if (!valid) {
          return;
        }
        bindEtc(this.etcForm.id).then(() => {
          this.$message.success("ETC信息提交成功");
          this.etcVisible = false;
          this.query();
        });
      });
    },

    saveEtcInfo() {
      saveOrUpdateVehicleInfo(this.etcForm).then(() => {
        this.$message.success("ETC信息保存成功");
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    showDetail(item, readonly) {
      this.readonly = readonly;
      this.vehicleDetailVisible = true;
      getVehicleInfo(item.id).then((res) => {
        this.vehicleInfo = res.data || {};
      });
    },

    addVehicle() {
      this.readonly = false;
      this.vehicleInfo = {};
      this.vehicleDetailVisible = true;
    },

    viewInsurance(item) {
      this.insuranceDetailVisible = true;
      const param = {
        vehicleId: item.id,
      };
      listVehicleInsurance(param).then((res) => {
        this.insuranceList = res.data || [];
      });
    },
    editInsurance(item) {
      this.insuranceVisible = true;
      this.insuranceForm = {
        insurCom: "中国人民保险集团股份有限公司",
        insurType: "交强险",
        insurCount: 180000,
        insurNum: "PDZA202343060000060355",
      };
      this.current = item;
    },
    closeInsurance() {
      this.insuranceVisible = false;
    },
    saveInsurance() {
      this.$refs.insuranceForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = {
          ...this.insuranceForm,
          vehicleId: this.current.id,
        };
        addVehicleInsurance(param).then(() => {
          this.$message.success("信息保存成功");
          this.closeInsurance();
        });
      });
    },

    genRandomNum() {
      return Math.floor(Math.random() * 10);
    },
  },
};
</script>

<style scoped lang="less">
.head-box {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.1);

  .btn-add {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 80px;
  }
}

.condition {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

/deep/ .condition {
  .el-input {
    width: 180px;
  }
}

.table {
  margin: 20px 0;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.drawer-table {
  padding: 0 20px;
}

:deep(.el-drawer__header) {
  display: none;
}
</style>
