const publicPath = '/yxmgt/static/image/';

const WECHAT_EMOJI = {
  '/::)': `<img class="wechat-emoji" style="vertical-align: bottom;" src="${publicPath}wechat/100.gif" alt="微笑">`,
  '/::~': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/101.gif" alt="伤心">',
  '/::B': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/102.gif" alt="美女">',
  '/::|': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/103.gif" alt="发呆">',
  '/:8-)': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/104.gif" alt="墨镜">',
  '/::<': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/105.gif" alt="哭">',
  '/::$': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/106.gif" alt="羞">',
  '/::X': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/107.gif" alt="哑">',
  '/::Z': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/108.gif" alt="睡">',
  '/::\'(': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/109.gif" alt="哭">',
  '/::-|': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/110.gif" alt="囧">',
  '/::@': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/111.gif" alt="怒">',
  '/::P': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/112.gif" alt="调皮">',
  '/::D': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/113.gif" alt="笑">',
  '/::O': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/114.gif" alt="惊讶">',
  '/::(': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/115.gif" alt="难过">',
  '/::+': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/116.gif" alt="酷">',
  '/:--b': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/117.gif" alt="汗">',
  '/::Q': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/118.gif" alt="抓狂">',
  '/::T': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/119.gif" alt="吐">',
  '/:,@P': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/120.gif" alt="笑">',
  '/:,@-D': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/121.gif" alt="快乐">',
  '/::d': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/122.gif" alt="奇">',
  '/:,@o': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/123.gif" alt="傲">',
  '/::g': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/124.gif" alt="饿">',
  '/:|-)': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/125.gif" alt="累">',
  '/::!': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/126.gif" alt="吓">',
  '/::L': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/127.gif" alt="汗">',
  '/::>': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/128.gif" alt="高兴">',
  '/::,@': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/129.gif" alt="闲">',
  '/:,@f': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/130.gif" alt="努力">',
  '/::-S': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/131.gif" alt="骂">',
  '/:?': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/132.gif" alt="疑问">',
  '/:,@x': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/133.gif" alt="秘密">',
  '/:,@@': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/134.gif" alt="乱">',
  '/::8': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/135.gif" alt="疯">',
  '/:,@!': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/136.gif" alt="哀">',
  '/:!!!': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/137.gif" alt="鬼">',
  '/:xx': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/138.gif" alt="打击">',
  '/:bye': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/139.gif" alt="bye">',
  '/:wipe': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/140.gif" alt="汗">',
  '/:dig': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/141.gif" alt="抠">',
  '/:handclap': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/142.gif" alt="鼓掌">',
  '/:&-(': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/143.gif" alt="糟糕">',
  '/:B-)': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/144.gif" alt="恶搞">',
  '/:<@': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/145.gif" alt="什么">',
  '/:@>': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/146.gif" alt="什么">',
  '/::-O': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/147.gif" alt="累">',
  '/:>-|': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/148.gif" alt="看">',
  '/:P-(': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/149.gif" alt="难过">',
  '/::\'|': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/150.gif" alt="难过">',
  '/:X-)': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/151.gif" alt="坏">',
  '/::*': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/152.gif" alt="亲">',
  '/:@x': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/153.gif" alt="吓">',
  '/:8*': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/154.gif" alt="可怜">',
  '/:pd': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/155.gif" alt="刀">',
  '/:<W>': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/156.gif" alt="水果">',
  '/:beer': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/157.gif" alt="酒">',
  '/:basketb': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/158.gif" alt="篮球">',
  '/:oo': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/159.gif" alt="乒乓">',
  '/:coffee': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/160.gif" alt="咖啡">',
  '/:eat': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/161.gif" alt="美食">',
  '/:pig': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/162.gif" alt="动物">',
  '/:rose': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/163.gif" alt="鲜花">',
  '/:fade': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/164.gif" alt="枯">',
  '/:showlove': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/165.gif" alt="唇">',
  '/:heart': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/166.gif" alt="爱">',
  '/:break': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/167.gif" alt="分手">',
  '/:cake': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/168.gif" alt="生日">',
  '/:li': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/169.gif" alt="电">',
  '/:bome': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/170.gif" alt="炸弹">',
  '/:kn': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/171.gif" alt="刀子">',
  '/:footb': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/172.gif" alt="足球">',
  '/:ladybug': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/173.gif" alt="瓢虫">',
  '/:shit': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/174.gif" alt="翔">',
  '/:moon': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/175.gif" alt="月亮">',
  '/:sun': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/176.gif" alt="太阳">',
  '/:gift': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/177.gif" alt="礼物">',
  '/:hug': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/178.gif" alt="抱抱">',
  '/:strong': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/179.gif" alt="拇指">',
  '/:weak': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/180.gif" alt="贬低">',
  '/:share': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/181.gif" alt="握手">',
  '/:v': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/182.gif" alt="剪刀手">',
  '/:@)': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/183.gif" alt="抱拳">',
  '/:jj': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/184.gif" alt="勾引">',
  '/:@@': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/185.gif" alt="拳头">',
  '/:bad': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/186.gif" alt="小拇指">',
  '/:lvu': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/187.gif" alt="拇指八">',
  '/:no': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/188.gif" alt="食指">',
  '/:ok': '<img  class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/189.gif" alt="ok">',
  '/:love': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/190.gif" alt="情侣">',
  '/:<L>': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/191.gif" alt="爱心">',
  '/:jump': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/192.gif" alt="蹦哒">',
  '/:shake': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/193.gif" alt="颤抖">',
  '/:<O>': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/194.gif" alt="怄气">',
  '/:circle': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/195.gif" alt="跳舞">',
  '/:kotow': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/196.gif" alt="发呆">',
  '/:turn': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/197.gif" alt="背着">',
  '/:skip': '<img class="wechat-emoji" style="vertical-align: bottom;" src="static/image/wechat/198.gif" alt="伸手">',
  '/:oY': '<img class="wechat-emoji" style="vertical-align: bottom;"src="static/image/wechat/199.gif" alt="耍帅">'
};
export default WECHAT_EMOJI;