<template>
  <div class="root" :class="{ 'bg-red': active, disabled: disable }">
    <div class="main-box">
      <div class="order" @click="selectOrder">
        <div class="index" :class="{ disabled: disable }">
          {{ item1.orderIndex + 1 }}
        </div>
        <div class="main">
          <div class="address-box">
            <div class="status">
              {{ orderMap[item1.orderId].routeStatus | enumTransfer(routeDetailOpts) }}-{{
                orderMap[item1.orderId].number
              }}人 &nbsp;
            </div>
            <div class="address">{{ item1.isShunt ? "（分流点）" : "" }} {{ item1.address }}</div>
          </div>
          <div class="detail">
            <div>{{ item1.showTime }}</div>

            <div v-if="isPick">
              <span :class="{ red: item1.isArriveWarning }">出发：{{ item1.arriveTime | toMintues }}</span>
            </div>
            <div v-else>
              <span style="margin-right: 20px">出发：{{ item2.arriveTime | toMintues }}</span>
              <span>到达：{{ item1.arriveTime | toMintues }}</span>
            </div>

            <div v-if="isPick" class="wait-time">
              <span>等候</span>
              <i class="el-icon-remove-outline" @click.stop="setWaitTime(false)"></i>
              <span class="minutes">{{ item1.waitTime / 60 }}分钟</span>
              <i class="el-icon-circle-plus-outline" @click.stop="setWaitTime(true)"></i>
            </div>

            <span>直达时间:
              {{
                (item1.navTotalDuration + item1.shuntDuration + item2.shuntDuration) | timeFilter
              }}</span>
          </div>

          <div>
            <span style="margin-right: 20px">全时：{{
              (item1.totalDuration + item1.shuntDuration + item2.shuntDuration) | timeFilter
            }}
              &nbsp;-&nbsp; 标时:
              {{
                (item1.standardDuration + item1.shuntDuration + item2.shuntDuration) | timeFilter
              }}
              = {{ (item1.totalDuration - item1.standardDuration) | timeFilter }}</span>
            <span :class="{ red: item1.isDurationWarning }" v-if="item1.isDurationWarning">超标准: {{ item1 |
              calcExceedDuration(item2) | timeFilter }}</span>
          </div>

          <div>
            <span>首次约定时间: {{ item1.firstPlanPickTime ? item1.firstPlanPickTime : ' 未约定' }}</span>
            <span class="margin-left">实际{{ isPick ? "上车" : "下车" }}时间：{{ item1.actTime }}</span>
          </div>
          <div v-if="item1.actTotalDuration">
            <span v-if="item1.actWaitDuration">实际等待时间：{{ item1.actWaitDuration | timeFilter }}</span>
            <span v-if="item1.actTotalDuration" class="margin-left">实际行程时间：{{
              (item1.actTotalDuration + item1.shuntDuration + item2.shuntDuration) | timeFilter
            }}</span>
          </div>
        </div>
        <!-- <div class="msg"
         @click.stop="">
      <i class="el-icon-chat-dot-round"></i>
    </div> -->

        <div v-if="item1.isThing" class="huo bag">货</div>
        <div v-if="item1.isShunt" class="fen bag">分</div>
      </div>

      <div class="op-box" v-if="item1.shuntDuration">
        <span><i class="el-icon-warning-outline"></i></span>
        <span>分流路程时长 {{ item1.shuntDuration | timeFilter }} / {{ item1.shuntMiles | mileFilter
          }}{{ item1 | toShuntCallTime(isPick) }}</span>
      </div>
    </div>
    <div v-handle class="drag-handle">
      <i class="el-icon-rank"></i>
    </div>
  </div>
</template>

<script>
import { ElementMixin, HandleDirective } from "vue-slicksort";
import { mapState } from "vuex";
import moment from "moment";
export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },

  props: {
    orderMap: Object,
    data: Object,
    item1: Object,
    isPick: Boolean,
    onClick: Function,
    active: Boolean,
    disable: Boolean,
    onUpdate: Function,
  },

  filters: {
    toMintues(val) {
      if (val && val.length) {
        return val.substr(11, 5);
      }
      return val;
    },

    calcExceedDuration(item1, item2) {
      const val =
        item1.totalDuration +
        item1.shuntDuration +
        item2.shuntDuration -
        item1.standardDuration -
        item1.maxLimitExceedDuration;
      return val > 0 ? val : 0;
    },

    toShuntCallTime(item, isPick) {
      if (isPick) {
        const callTime = moment(item.arriveTime).subtract(item.shuntDuration + 10 * 60, "seconds");
        var str = callTime.format("YYYY-MM-DD HH:mm:ss").substr(11, 5);
        return `，请在${str}安排分流车辆`;
      } else {
        return "";
      }
    },
  },

  computed: {
    ...mapState({
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
    }),

    item2() {
      let list = this.isPick
        ? this.data.routePlan.sendOrderList
        : this.data.routePlan.pickOrderList;
      list = list.filter((t) => t.orderId === this.item1.orderId);
      return list[0];
    },
  },

  data() {
    return {};
  },

  methods: {
    selectOrder() {
      this.onClick(this.item1);
    },

    setWaitTime(isPlus) {
      if (this.item1.waitTime <= 0 && !isPlus) {
        return;
      }
      let value = isPlus ? this.item1.waitTime + 300 : this.item1.waitTime - 300;
      if (value < 0) {
        value = 0;
      }
      const data = { ...this.item1, waitTime: value };
      this.onUpdate(data, this.index);
    },
  },
};
</script>

<style scoped lang="less">
.root {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  user-select: none;

  &.bg-red {
    background-color: #e8f5f5 !important;
  }

  &.disabled {
    background-color: #eee;
  }

  .drag-handle {
    width: 40px;
    border-left: 1px solid #ddd;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    background-color: #f4f4f4 !important;

    i {
      font-size: 24px;
    }
  }

  .main-box {
    flex: 1;
  }
}

.order {
  display: flex;
  align-items: center;
  width: 540px;
  padding: 6px 10px;
  position: relative;

  .index {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    line-height: 30px;
    background-color: orange;
    color: #fff;
    margin-right: 10px;

    &.disabled {
      background-color: #999;
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    >div {
      margin: 6px 0;
    }

    .address-box {
      display: flex;

      .address {
        flex: 1;
      }

      .status {
        font-weight: bold;
      }
    }

    .detail {
      display: flex;

      div {
        margin-right: 16px;
      }

      .wait-time {
        display: flex;
        align-items: center;

        .minutes {
          width: 44px;
          text-align: center;
        }

        i {
          cursor: pointer;
          font-size: 20px;
          margin: 0 3px;
          color: #999;
        }
      }
    }

    .route-time {
      font-size: 13px;
    }
  }

  .msg {
    margin-left: 20px;
    width: 30px;
    cursor: pointer;

    i {
      font-size: 30px;
    }
  }

  .bag {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
  }

  .fen {
    background-color: rgb(141, 141, 241);
    color: #fff;
  }

  .huo {
    background-color: rgb(248, 248, 134);
  }
}

.op-box {
  border-top: 1px solid #ddd;
  padding: 6px 10px;
  background-color: #f4f4f4;

  i {
    color: rgb(240, 128, 128);
    font-size: 16px;
    margin-right: 4px;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-left {
  margin-left: 20px;
}
</style>
