<template>
  <div class="editor">
    <div class="query-container" style="padding-bottom: 20px">
      <el-select v-model="params.flCode" placeholder="模板分类" clearable>
        <el-option
          v-for="item in flCodeOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="params.isAdmin"
        style="margin-left: 8px"
        placeholder="仅管理员发送"
        clearable
      >
        <el-option
          v-for="item in adminOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="params.emergency"
        style="margin-left: 8px"
        placeholder="紧急程度"
        clearable
      >
        <el-option
          v-for="item in emergencyObjs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-button type="primary" style="margin-left: 8px" @click="query">查询</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="code" label="模板编码" width="80"> </el-table-column>
      <el-table-column prop="name" label="模板名称" width="200"> </el-table-column>
      <el-table-column prop="title" label="模板标题"> </el-table-column>
      <el-table-column prop="flCode" label="模板分类" width="120"> </el-table-column>
      <el-table-column label="仅管理员发送" width="120">
        <template slot-scope="scope">
          {{ scope.row.adminSend ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="emergencyName" label="紧急程度" width="120"> </el-table-column>
      <el-table-column prop="needConfirm" label="是否需要确认" width="120">
        <template slot-scope="scope">
          {{ scope.row.needConfirm ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="200"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="editNotice(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="deleteNotice(scope.row)" type="text" size="small">删除</el-button>
          <el-button @click="showSendNotice(scope.row)" type="text" size="small"
            >发送消息</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="showNoticeEidt" :fullscreen="true" title="消息模板设置">
      <div v-if="showNoticeEidt">
        <NoticeEdit :notice="currentNoticeTmp" @closeWindow="afterEditNotice"></NoticeEdit>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showNoticeForm" title="发送消息">
      <div v-if="showNoticeForm">
        <NoticeForm
          :notice="currentNoticeTmp"
          :sendData="sendData"
          @closeWindow="showNoticeForm = false"
        ></NoticeForm>
      </div>
    </el-dialog>

    <div class="bottom">
      <el-button type="primary" icon="el-icon-plus" circle @click.native="addNotice"></el-button>
    </div>
  </div>
</template>

<script>
import NoticeEdit from "./NoticeEdit";
import NoticeForm from "./NoticeForm";
export default {
  name: "NoticeTemplate",
  components: {
    NoticeEdit,
    NoticeForm,
  },
  data() {
    return {
      showNoticeEidt: false,
      showNoticeForm: false,
      currentNoticeTmp: {},
      tableData: [],
      noticeTypes: [],
      sendData: {},
      emergencyObjs: [
        { value: "1", label: "普通" },
        { value: "2", label: "重要" },
        { value: "3", label: "紧急" },
      ],
      flCodeOpts: [
        { value: "1", label: "" },
        { value: "2", label: "" },
      ],
      adminOpts: [
        { value: "1", label: "是" },
        { value: "0", label: "否" },
      ],
      params: { flCode: "", isAdmin: "", emergency: "" },
    };
  },
  mounted() {
    this.initData();
    this.query();
  },
  methods: {
    initData() {
      window.emergencyObjs = this.emergencyObjs;
      this.$http.post("/admin/notice/initdata", {}).then((res) => {
        this.noticeTypes = res.data.noticeTypes || [];
        this.flCodeOpts = res.data.flCodeOpts || [];
        window.flCodeOpts = this.flCodeOpts;
        window.noticeTypes = this.noticeTypes;
      });
    },
    query() {
      this.initPage();
    },
    initPage() {
      this.$http.post("/admin/notice/listtemplate", this.params).then((res) => {
        this.tableData = res.data || [];
        this.tableData.forEach((template) => {
          for (var j = 0; j < this.emergencyObjs.length; j++) {
            let emergencyObj = this.emergencyObjs[j];
            if (template.emergency == emergencyObj.value) {
              template.emergencyName = emergencyObj.label;
            }
          }
        });
      });
    },
    editNotice(template) {
      this.showNoticeEidt = true;
      this.currentNoticeTmp = template;
    },
    afterEditNotice() {
      this.showNoticeEidt = false;
      this.initPage();
    },
    addNotice() {
      this.editNotice({});
    },
    deleteNotice(template) {
      this.$confirm("此操作将永久删除通知模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.post("/admin/notice/deltemplate/" + template.id, {}).then(() => {
          this.$message.success("删除模板成功");
          this.initPage();
        });
      });
    },

    showSendNotice(template) {
      this.currentNoticeTmp = template;
      this.sendData = {};
      this.showNoticeForm = true;
    },
  },
};
</script>

<style lang="less" scoped>
.editor {
  margin: 10px;
  width: 90%;
  margin-top: 30px;

  .bottom {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .el-dialog {
    width: 100% !important;
  }
}
</style>
