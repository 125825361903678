<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="用户类型：">
          <el-select v-model="form.userType" placeholder="请选择" clearable>
            <el-option
              v-for="item in userTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="操作开始时间：">
          <el-date-picker
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.startTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="操作结束时间：">
          <el-date-picker
            placeholder="选择时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            v-model="form.endTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="userType" label="用户类型">
          <template slot-scope="scope">
            {{ scope.row.userType | enumTransfer(userTypeOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="name" label="用户姓名"> </el-table-column>

        <el-table-column prop="createTime" label="登录时间"> </el-table-column>

        <el-table-column prop="ip" label="登录IP"> </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>

import {ParamUtil} from '@/util';
import {getLoginLog} from '@/api';
import {mapState} from 'vuex';

export default {
  created () {
    this.query();
  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      userTypeOpts: state => state.enumMap['ROLE_ENUM'],
    }),
  },
  data () {
    return {
      form: {
        startTime: null,
        endTime: null,
      },
      list: [],
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    query (page = 1) {
      const param = {
        page: {
          current: page,
          size: 10
        },
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        param: ParamUtil.filterEmptyData(this.form)
      };
      getLoginLog(ParamUtil.filterEmptyData(param)).then((res) => {
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    handleCurrentChange (page) {
      this.query(page);
    },
  }
};
</script>

<style scoped lang="less">
.head-box {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.condition {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.table {
  margin: 20px 0;
}
</style>
