<template>
  <el-popover v-if="text" trigger="click" width="200" placement="bottom">
    {{text}}
    <el-button type="text" slot="reference" class="limit-text">{{text}}</el-button>
  </el-popover>
</template>

<script>
export default {
  props: {
    width: Number,
    text: String
  },
  mounted () {

  },

  methods: {

  }

};
</script>
<style lang="less" scope>
.limit-text {
  color: #606266;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>