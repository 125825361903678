<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="bank-setting">
    <el-row>
      <el-col :span="4">发布设置：</el-col>
      <el-col :span="20">
        <el-checkbox v-model="bankSetting.isBankPub"
          >题库发布/更新提示</el-checkbox
        >
        <el-checkbox v-model="bankSetting.isExamPub"
          >考试发布/更新提示</el-checkbox
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">培训分类：</el-col>
      <el-col :span="20">
        <el-select v-model="bankSetting.train" filterable placeholder="请选择">
          <el-option
            v-for="item in trains"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">测评前提：</el-col>
      <el-col :span="20">
        <el-checkbox v-model="bankSetting.doneTraining"
          >完成全部题库学习进度</el-checkbox
        ><br />
        <el-checkbox v-model="bankSetting.finishLearn"
          >完成对应文档全部学习的进度</el-checkbox
        >
        <div>
          题库学习的错误率大于<el-input
            v-model="bankSetting.miniWrRate"
          />%不允许考试
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">测评设置：</el-col>
      <el-col :span="20">
        <el-checkbox v-model="bankSetting.optionRdm"
          >所有题目选项每次做题随机排列</el-checkbox
        >
        <el-checkbox v-model="bankSetting.itmRdm"
          >同一人每次测评所选题目随机不重复</el-checkbox
        >
        <div>
          每次随机抽取<el-input
            class="num"
            v-model="bankSetting.itmNums"
          />题组成试卷（100分）
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">答题设置：</el-col>
      <el-col :span="20"
        >测评时间<el-input
          class="num"
          v-model="bankSetting.minutes"
        />分钟</el-col
      >
    </el-row>
    <el-row>
      <el-col :span="4">补考设置：</el-col>
      <el-col :span="20">
        考试结束<el-input
          class="num"
          v-model="bankSetting.atHours"
        />小时后自动补考
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">合格标准：</el-col>
      <el-col :span="20"
        >大于等于<el-input
          class="num"
          v-model="bankSetting.goodScore"
        />分（满分100分）</el-col
      >
    </el-row>
    <el-row>
      <el-col :span="4">测评结果联动：</el-col>
      <el-col :span="20">
        <el-checkbox v-model="bankSetting.offDuty"
          >测评不合格，账号冻结、无法接单（客服也无法派单）</el-checkbox
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">结果提示语：</el-col>
      <el-col :span="20" style="background-color: #eee; padding: 8px">
        <el-row class="grade-row">
          <el-col :span="6">名称</el-col>
          <el-col :span="6" style="text-align: center">分值</el-col>
          <el-col :span="10">提示语</el-col>
          <el-col :span="2"></el-col>
        </el-row>
        <el-row
          :key="gradeInfo.id"
          v-for="(gradeInfo, idx) in bankSetting.gradeInfos"
          class="grade-row"
        >
          <el-col :span="6">
            <el-input class="grade-name" v-model="gradeInfo.name" />
          </el-col>
          <el-col :span="6" style="text-align: center">
            <el-input v-model="gradeInfo.mini" />-<el-input
              v-model="gradeInfo.max"
            />
          </el-col>
          <el-col :span="10">
            <el-input class="grade-tip" v-model="gradeInfo.tip" />
          </el-col>
          <el-col :span="2">
            <i class="el-icon-plus add" @click="addGradeInfo(idx)"></i>
            <i
              class="el-icon-minus del"
              @click="removeGradeInfo(gradeInfo, idx)"
            ></i>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ToolKit from "@/util/toolkit";
export default {
  name: "BankSetting",
  props: {
    bankSetting: {
      type: Object
    }
  },
  components: {
  },
  data () {
    return {
      trains: [],
    };
  },
  mounted () {
    if (!this.bankSetting.gradeInfos.length) {
      this.addGradeInfo(0);
    }
    this.$http.post("/admin/exam/bank/listcateg", {}).then(res=> {
      this.trains = res.data || [];
    });
  },
  methods: {
    addGradeInfo (idx) {
      var info = {id: "rdm-" + ToolKit.uuid(), name: "", mini: 0, max: 0, tip: ""};
      // eslint-disable-next-line vue/no-mutating-props
      this.bankSetting.gradeInfos.splice(idx, 0, info);
    },
    removeGradeInfo (gradeInfo, idx) {
      if (this.bankSetting.gradeInfos.length <= 1) {
        this.$message.error("至少保留一个选项。");
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.bankSetting.gradeInfos.splice(idx, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.bank-setting {
  margin: 24px;
  .grade-row {
    &.el-row {
      margin-bottom: 8px;
    }
    .grade-name.el-input,
    .grade-tip.el-input {
      width: 100%;
    }
    i.add {
      margin-left: 4px;
      background-color: #409eff;
      color: #fff;
      padding: 2px;
      cursor: pointer;
    }
    i.del {
      margin-left: 4px;
      background-color: red;
      color: #fff;
      padding: 2px;
      cursor: pointer;
    }
  }
  .el-row {
    line-height: 32px;
    margin-bottom: 24px;
  }
  .el-checkbox__input + .el-checkbox__label {
    font-size: 16px;
  }
  .el-input {
    display: inline-block;
    width: 64px;
  }
}
</style>