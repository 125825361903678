<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item label="开始日期">
            <el-date-picker value-format="yyyy-MM-dd 00:00:00" v-model="param.startTime" style="width: 200px"
              type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="结束日期">
            <el-date-picker value-format="yyyy-MM-dd 23:59:59" v-model="param.endTime" style="width: 200px" type="date"
              placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="路线：">
            <el-select v-model="param.pathIds" placeholder="请选择" multiple clearable>
              <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div>
      <div id="container" ref="chart" class="map"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getCommonAddress } from '@/api';

export default {
  components: {},
  props: {},
  mounted() {
    this.initMap();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state => state.pathList,
      transactionTypeOpts: state => state.enumMap['TRANSACTION_TYPE'],
    })
  },
  data() {
    return {
      param: {},
    };
  },
  methods: {
    initMap() {
      const map = new AMap.Map('container', {
        zoom: 11,
        resizeEnable: true,
        center: [112.908197, 29.84625],
      });
      map.plugin(["AMap.HeatMap"], () => {
        //初始化heatmap对象
        this.heatmap = new AMap.HeatMap(map, {
          radius: 25, //给定半径
          opacity: [0, 0.8],
          gradient: {
            0.5: 'blue',
            0.65: 'rgb(117,211,248)',
            0.7: 'rgb(0, 255, 0)',
            0.9: '#ffea00',
            1.0: 'red'
          }

        });
      });
    },

    initChart() {
      this.chart = echarts.init(this.$refs.chart);
    },

    query() {
      getCommonAddress(this.param).then(res => {
        const list = res.data || [];

        // const points = list.map((item) => {
        //   return item.lnglat.concat([1]);
        // });

        const points = list.map((item) => {
          return {
            "lng": item.lnglat[0],
            "lat": item.lnglat[1],
            "count": 1
          };
        });

        // const chart = this.chart;
        // chart.setOption({
        //   animation: false,
        //   bmap: {
        //     center: list[0].lnglat,
        //     zoom: 14,
        //     roam: true
        //   },
        //   visualMap: {
        //     show: false,
        //     top: 'top',
        //     min: 0,
        //     max: 5,
        //     seriesIndex: 0,
        //     calculable: true,
        //     inRange: {
        //       color: ['blue', 'blue', 'green', 'yellow', 'red']
        //     }
        //   },
        //   series: [{
        //     type: 'heatmap',
        //     coordinateSystem: 'bmap',
        //     data: points,
        //     pointSize: 5,
        //     blurSize: 6,
        //     progressive: 1000,
        //     animation: false
        //   }]
        // });
        // // 添加百度地图插件
        // var bmap = chart.getModel().getComponent('bmap').getBMap();
        // bmap.addControl(new BMap.MapTypeControl());



        this.heatmap.setDataSet({
          data: points,
          max: 100
        });
      });



    },


  },
};
</script>
<style lang="less" scoped>
.map {
  height: 700px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
</style>