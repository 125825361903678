<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="司机反馈" name="ref1">
        <Feedback />
      </el-tab-pane>

      <el-tab-pane label="异常过路费" name="ref2">
        <span slot="label">
          <el-badge v-if="itemNos" :value="itemNos" class="item"><span>异常过路费</span></el-badge>
          <span v-else>异常过路费</span>
        </span>
        <TollFee />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Feedback from "./Feedback.vue";
import TollFee from "./TollFee";
import { getTollFeeSettlementList } from "@/api";

export default {
  components: {
    Feedback,
    TollFee,
  },
  props: {},
  created() {},
  mounted() {
    this.query();
  },
  watch: {},
  computed: {},
  data() {
    return {
      tab: "ref1",
      itemNos: 0,
    };
  },
  methods: {
    query() {
      const param = {
        page: {
          current: 1,
          size: 50,
        },
        param: {
          status: "ABNORMAL",
        },
      };
      getTollFeeSettlementList(param).then((res) => {
        const { total = 0 } = res.data || {};
        this.itemNos = total;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-badge.item {
  line-height: 24px;
}
</style>
