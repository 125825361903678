import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import mutations from "./mutations";
import actions from "./actions";
import reward from "./modules/reward";

Vue.use(Vuex);
// 全局状态
const state = {
  menuCollapse: false,
  btnSize: "small",
  pageSize: 10,
  pathList: [],
  enumMap: {},
  teamOpts: [],
  areaOpts: [],
  isManager: false,
  apmRs: [],
  vehicleTypeOpts: [],
  routeCheckInfo: {},
};

export default new Vuex.Store({
  plugins: [createLogger()],
  modules: {
    reward,
  },
  state,
  mutations,
  actions,
});
