<template>
  <div class="root-box">
    <div class="condition">
      <el-form ref="form" size="small" label-width="80px" :model="form" inline>
        <el-row>
          <el-col>
            <el-form-item label="路线" prop="pathId">
              <el-select
                v-model="form.pathId"
                @change="query()"
                placeholder="请选择"
                clearable
                style="width: 200px"
              >
                <el-option
                  v-for="item in pathOpts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.startDate"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.endDate"
                style="width: 200px"
                type="date"
                @change="query()"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="行程状态" @change="query()" prop="routeStatus">
              <el-select
                style="width: 300px"
                v-model="form.routeStatus"
                clearable
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in routeStatusOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="19">
            <el-form-item label="司机编号" prop="driverNo">
              <el-input v-model="form.driverNo" clearable style="width: 200px" />
            </el-form-item>

            <el-form-item label="司机ID" prop="driverId">
              <el-input v-model="form.driverId" clearable style="width: 200px" />
            </el-form-item>

            <el-form-item label="标记行程" @change="query()" prop="hasRemark">
              <el-select
                style="width: 200px"
                v-model="form.hasRemark"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in yesNoOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" style="width: 80px" @click="query()">搜索</el-button>
              <el-button type="primary" style="width: 80px" @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="route-detail">
      <el-table
        :data="list"
        default-expand-all
        max-height="650"
        :show-header="false"
        class="no-hover"
        stripe
      >
        <el-table-column type="expand" show-overflow-tooltip>
          <template slot-scope="props">
            <div class="table-expand">
              <el-table :data="props.row.orderList" :show-header="false" class="no-hover">
                <el-table-column prop="orderIndex" width="50" label="订单序号">
                  <template slot-scope="scope">
                    <span class="order-index">{{ scope.row.order.orderIndex + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="vip" width="60" label="VIP">
                  <template slot-scope="scope">
                    <vip-label :vip="scope.row.vip" />
                  </template>
                </el-table-column>

                <el-table-column prop="showTime" width="120" label="时间">
                  <template slot-scope="scope">
                    {{ scope.row.order.showTime }}
                  </template>
                </el-table-column>
                <el-table-column prop="orderSource" width="80" label="渠道">
                  <template slot-scope="scope">
                    <span v-order-source="scope.row.order.orderSource"></span>
                  </template>
                </el-table-column>

                <el-table-column prop="number" width="60" label="订票">
                  <template slot-scope="scope">
                    <span>{{ scope.row.order | orderTypeTransfer }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="price" width="80" label="总价">
                  <template slot-scope="scope">
                    <span v-operate-order:bill="scope.row.order.id"
                      >{{ scope.row.order.price | moneyFormat }}元</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="telephone" label="手机" :width="150">
                  <template slot-scope="scope">
                    <tel-cell :order="scope.row.order" />
                  </template>
                </el-table-column>

                <el-table-column prop="departure" label="起点" width="150">
                  <template slot-scope="scope">
                    <span
                      class="pointer"
                      @click="showPassengetMap(props.row.route, props.row.orderList, true)"
                      >{{ scope.row.order.departure }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="destination" label="终点" width="150">
                  <template slot-scope="scope">
                    <span
                      class="pointer"
                      @click="showPassengetMap(props.row.route, props.row.orderList, false)"
                      >{{ scope.row.order.destination }}</span
                    >
                  </template>
                </el-table-column>

                <el-table-column prop="orderStatus" label="订单状态" width="100">
                  <template slot-scope="scope">{{
                    scope.row.order.orderStatus | enumTransfer(orderStatusOpts)
                  }}</template>
                </el-table-column>

                <el-table-column prop="payStatus" label="支付状态">
                  <template slot-scope="scope">
                    <span>{{ scope.row.order.payStatus | enumTransfer(payStatusOpts) }}</span>
                  </template>
                </el-table-column>

                <el-table-column width="340" prop="operate" label="司机操作">
                  <template slot-scope="scope" v-if="scope.row.operate">
                    <el-button
                      type="text"
                      style="width: 50px"
                      :disabled="!scope.row.operate.evaluationStar"
                      @click="showEvaDetail(scope.row.order.id)"
                      >{{
                        scope.row.operate.evaluationStar
                          ? `${scope.row.operate.evaluationStar}星`
                          : "未评价"
                      }}</el-button
                    >

                    <el-button
                      type="text"
                      style="width: 80px"
                      :class="{
                        success: scope.row.operate.scoreDuration > 0,
                        dange: scope.row.operate.scoreStep3 < 0 || scope.row.operate.scoreStep4 < 0,
                      }"
                      @click="showDurDetail(scope.row.operate)"
                      v-if="scope.row.operate.actDuration"
                      >{{ scope.row.operate.actDuration | timeFilter }}</el-button
                    >
                    <el-button
                      type="text"
                      style="width: 80px"
                      @click="showDurDetail(scope.row.operate)"
                      v-else
                      >未知时长</el-button
                    >

                    <el-button type="text" @click="showOptDetail(scope.row, '')">
                      <span
                        v-if="
                          scope.row.operate.scoreArriveOntime ||
                          scope.row.operate.scoreArriveOntime === 0
                        "
                      >
                        <span class="red" v-if="scope.row.operate.scoreArriveOntime < 0"
                          >准点率不合格</span
                        >
                        <span v-if="scope.row.operate.scoreArriveOntime === 0">准点率合格</span>
                        <span class="success" v-if="scope.row.operate.scoreArriveOntime > 0"
                          >准点率优秀</span
                        >
                      </span>
                      <span v-else>未知准点率</span>
                    </el-button>

                    <el-button type="text" @click="showOptDetail(scope.row, 1)">操作</el-button>

                    <el-button type="text" @click="showComplaint(props.row.route, scope.row)">{{
                      scope.row.operate.complaint ? "有投诉" : "投诉"
                    }}</el-button>
                  </template>
                </el-table-column>

                <el-table-column width="130" prop="psgMessage" label="留言">
                  <template slot-scope="scope">
                    <limit-text :width="130" :text="scope.row.order.psgMessage"></limit-text>

                    <limit-text :width="130" :text="scope.row.order.baggageRemark"></limit-text>

                    <limit-text :width="130" :text="scope.row.order.adminMessage"></limit-text>
                  </template>
                </el-table-column>

                <el-table-column prop="id" label="操作" fixed="right" :width="100">
                  <template slot-scope="scope">
                    <el-dropdown
                      class="dropdown-meun"
                      @command="(command) => handleCommand(command, scope.row)"
                    >
                      <span class="el-dropdown-link">
                        其他操作
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="2"
                          :disabled="
                            scope.row.order.orderStatus === 'CANCEL' ||
                            scope.row.order.orderStatus === 'FINISH'
                          "
                        >
                          <span>取消订单</span>
                        </el-dropdown-item>

                        <el-dropdown-item command="0">
                          <span v-goRouteDetail="scope.row.order.id">订单详情</span>
                        </el-dropdown-item>
                        <el-dropdown-item command="1">操作日志</el-dropdown-item>

                        <el-dropdown-item command="2">拉黑司机</el-dropdown-item>
                        <el-dropdown-item command="3"> 行程回放</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="司机编号" width="80px">
          <template slot-scope="scope">
            <span class="route-info">{{ scope.row.route.driverNo }}</span>
          </template>
        </el-table-column>

        <el-table-column label="使用座位数">
          <template slot-scope="scope">
            <span class="route-info">
              使用座位数:
              <span class="red">{{ scope.row.route.used }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>{{ scope.row.route.date }} / {{ scope.row.route.showTime }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>{{ scope.row.route.pathId | pathNameTransfer }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                v-if="scope.row.hasShunt"
                :class="{ red: scope.row.hasShunt, grey: scope.row.shuntAllFinished }"
                @click.stop="showShuntMap(scope.row.route, scope.row.orderList)"
              >
                <i class="el-icon-s-flag"></i>分流</span
              >
            </span>
            <span
              v-if="scope.row.route.status === 'FINISH'"
              class="route-info"
              @click.stop="showRoutePlan(scope.row.route)"
            >
              <i class="el-icon-share"></i> 行程规划</span
            >

            <span
              v-if="scope.row.route.status === 'FINISH'"
              class="qc process1"
              @click.stop="go2QcPage(scope.row.route)"
            >
              查看质检
            </span>
          </template>
        </el-table-column>

        <el-table-column label="行程备注" width="150">
          <template slot-scope="scope">
            <limit-text :width="130" :text="scope.row.route.remark"></limit-text>
          </template>
        </el-table-column>

        <el-table-column label="使用座位数" align="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              size="small"
              icon="el-icon-bell"
              @click="addSafetyRecord(scope.row.route)"
              >添加安全违规记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="evaVisible"
      width="600px"
      max-height="600"
      :size="size"
      title="乘客评价"
      :close-on-click-modal="true"
    >
      <el-form inline>
        <el-row>
          <el-col :span="6">
            <el-form-item label="星级:"> {{ orderEvalutaion.serviceScore }}星 </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="时间：">
              {{ orderEvalutaion.evaluateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否取消评价：">
              {{ orderEvalutaion.isCancelResult ? "是" : "否" }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="原因">
            {{ orderEvalutaion.detail }}
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="durVisible"
      width="600px"
      max-height="600"
      :size="size"
      title="订单时长"
      :close-on-click-modal="true"
    >
      <el-form inline>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出发时间:">
              {{ evaOperate.depTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到达时间：">
              {{ evaOperate.destTime }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="直达时长：">
              {{ evaOperate.navDuration | timeFilter }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="实际时长：">
              {{ evaOperate.actDuration | timeFilter }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="超时时长：">
              <span v-if="evaOperate.timeoutDuration">
                {{ evaOperate.timeoutDuration | timeFilter }}</span
              >
              <span v-else>0</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="optVisible"
      width="760px"
      max-height="600"
      :size="size"
      title="司机操作"
      :close-on-click-modal="true"
    >
      <el-table :data="operateList" class="no-hover">
        <el-table-column prop="step1" label="出发"> </el-table-column>
        <el-table-column prop="step2" label="到达"> </el-table-column>
        <el-table-column prop="step3" label="上车"> </el-table-column>
        <el-table-column prop="step4" label="下车"> </el-table-column>
        <el-table-column prop="step5" label="完成"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="ontimeVisible"
      width="600px"
      max-height="600"
      :size="size"
      title="准点率"
      :close-on-click-modal="true"
    >
      <el-table :data="operateList" class="no-hover">
        <el-table-column prop="orderTime" label="约定时间"> </el-table-column>
        <el-table-column prop="arriveTime" label="准点率"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="complaintVisible"
      width="600px"
      max-height="600"
      :size="size"
      title="乘客投诉"
      :close-on-click-modal="false"
    >
      <el-row style="margin: 0 0 20px">
        <el-col :span="7"> 投诉对象： {{ selectedRoute.driverNo }}</el-col>
        <el-col :span="10"> 时间：{{ selectedRoute.date }} {{ selectedRoute.showTime }}</el-col>
        <el-col :span="7"> 行程： {{ selectedRoute.pathId | pathNameTransfer }}</el-col>
      </el-row>
      <el-form :model="complaintForm" :size="size" :rules="rules" ref="complaintForm">
        <el-form-item prop="detail">
          <el-input
            type="textarea"
            v-model="complaintForm.detail"
            :rows="5"
            style="width: 560px"
            placeholder="投诉内容"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="附件："
          style="border: 1px solid #ddd; padding: 10px; border-radius: 3px"
        >
          <div v-if="complaintForm.id">
            <span v-for="item in complaintForm.fileList" :key="item.url" style="margin-right: 24px">
              <a style="color: #409eff" :href="item.url" target="_blank">{{ item.name }}</a>
            </span>
          </div>
          <div v-else>
            <multiple-file-upload v-model="complaintForm.fileList" filePath="/eva_item" />
          </div>
        </el-form-item>

        <el-form-item label="投诉等级：" prop="complaintLevel">
          <el-radio-group v-model="complaintForm.complaintLevel">
            <el-radio :label="item.value" v-for="item in complaintLevelOpts" :key="item.value">{{
              item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="投诉渠道：" prop="complaintChannel">
          <el-select v-model="complaintForm.complaintChannel">
            <el-option
              v-for="item in complaintChannelOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处理进度：" prop="status">
          <el-select v-model="complaintForm.status">
            <el-option
              v-for="item in complaintStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button v-if="!complaintForm.id" @click.stop="saveComplaint" type="primary" size="small"
          >保 存</el-button
        >

        <el-button @click.stop="complaintVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DateUtil, ParamUtil, UnitUtil } from "@/util";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import {
  getRouteListWithOrders,
  getRouteDetail,
  cancelOrder,
  getOrderEvaluation,
  getPassengerComplaint,
  saveOrUpdateComplaint,
  saveBlackDriver,
} from "@/api";
import TelCell from "@/components/TelCell";
import { SHOW_SHUNT_MAP, SHOW_ROUTE_MAP, SHOW_ROUTE_PLAN } from "@/core/const/global-event";
import Router from "@/route";

export default {
  components: {
    TelCell,
  },
  created() {
    const driverId = this.$route.query.id;
    if (driverId) {
      this.form.driverId = driverId;
      this.form.startDate = null;
      this.form.routeStatus = [];
    }

    const routeId = this.$route.query.routeId;
    if (routeId) {
      this.form = {
        routeId,
      };
    }

    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      routeStatusOpts: (state) => state.enumMap["ROUTE_STATUS"],
      orderSourceOpts: (state) => state.enumMap["ORDER_SOURCE"],
      orderStatusOpts: (state) => state.enumMap["ORDER_STATUS"],
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
      complaintLevelOpts: (state) => state.enumMap["COMPLAINT_LEVEL"],
      complaintChannelOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
      complaintStatusOpts: (state) => state.enumMap["COMPLAINT_STATUS"],
      pathOpts: (state) => state.pathList,
      safetyOpts: (state) =>
        (state.enumMap["DRIVER_EVA_OPT_TYPE"] || []).filter((t) => t.value.includes("4-")),
    }),
  },
  data() {
    return {
      form: {
        startDate: DateUtil.getDayStr(),
        endDate: DateUtil.getDayStr(),
        routeStatus: ["ACTIVE", "FINISH"],
        driverId: null,
      },
      yesNoOpts: BaseDataService.yesNoOpts(),
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      evaVisible: false,
      durVisible: false,
      optVisible: false,
      complaintVisible: false,
      ontimeVisible: false,
      orderEvalutaion: {},
      evaOperate: {},
      operateList: [],
      complaintForm: {},
      selectedOrder: {},
      selectedRoute: {},
      rules: {
        detail: [
          {
            required: true,
            message: "请输入投诉内容",
            trigger: "blur",
          },
        ],
        complaintChannel: [
          {
            required: true,
            message: "请选择投诉渠道",
            trigger: "blur",
          },
        ],
        complaintLevel: [
          {
            required: true,
            message: "请选择投诉等级",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择投诉进度",
            trigger: "blur",
          },
        ],
      },
      safetyRules: {
        type: [
          {
            required: true,
            message: "请输入违规类型",
            trigger: "blur",
          },
        ],
        channel: [
          {
            required: true,
            message: "请选择来源渠道",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
    },
    query(page = 1, pageSize) {
      const formParam = ParamUtil.filterEmptyData(this.form);
      const param = {
        page: {
          current: page,
          size: pageSize,
        },
        param: formParam,
      };
      getRouteListWithOrders(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showEvaDetail(id) {
      getOrderEvaluation(id).then((res) => {
        this.orderEvalutaion = res.data || {};
      });
      this.evaVisible = true;
    },

    go2QcPage(item) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId: item.id },
      });
      window.open(href, "_blank");
    },

    showDurDetail(operate) {
      this.evaOperate = operate;
      this.durVisible = true;
    },

    showOptDetail(row, type) {
      const { operate, order } = row;
      this.evaOperate = operate;

      getRouteDetail(operate.id).then((res) => {
        const {
          advanceNoticeDuration,
          arriveDeviation,
          depDeviation,
          destDeviation,
          finishDeviation,
          arriveTime,
          arriveTimeDeviation,
        } = res.data || {};
        this.operateList = [
          {
            step1: operate.scoreStep1 < 0 ? "不规范" : "规范",
            step2: operate.scoreStep2 < 0 ? "不规范" : "规范",
            step3: operate.scoreStep3 < 0 ? "不规范" : "规范",
            step4: operate.scoreStep4 < 0 ? "不规范" : "规范",
            step5: operate.scoreStep5 < 0 ? "不规范" : "规范",
            orderTime: order.showTime,
            arriveTime:
              operate.scoreArriveOntime < 0
                ? "不合格"
                : operate.scoreArriveOntime > 0
                ? "优秀"
                : "合格",
          },
          {
            step1: UnitUtil.timeFilter(advanceNoticeDuration),
            step2: UnitUtil.mileFilter(arriveDeviation),
            step3: UnitUtil.mileFilter(depDeviation),
            step4: UnitUtil.mileFilter(destDeviation),
            step5: UnitUtil.mileFilter(finishDeviation),
            orderTime: arriveTime ? DateUtil.format(DateUtil.str2Date(arriveTime), "hh:mm") : "",
            arriveTime:
              arriveTimeDeviation > 0
                ? arriveTimeDeviation > 3600
                  ? "提前" + UnitUtil.timeFilter(arriveTimeDeviation - 3600)
                  : "正点"
                : "晚点" + UnitUtil.timeFilter(-arriveTimeDeviation),
          },
        ];
      });

      if (type === 1) {
        this.optVisible = true;
      } else {
        this.ontimeVisible = true;
      }
    },

    showComplaint(route, item) {
      this.selectedOrder = item;
      this.selectedRoute = route;
      getPassengerComplaint(item.order.id, route.driverId).then((res) => {
        const complaintForm = res.data || {};
        let fileList = complaintForm.annexes || [];
        // 过滤空和生成文件名
        fileList = fileList
          .filter((t) => t)
          .map((t, index) => {
            const fileType = t.substr(t.lastIndexOf("."));
            return {
              name: `文件${index + 1}.${fileType}`,
              url: t,
            };
          });
        complaintForm.fileList = fileList;
        if (!complaintForm.status) {
          complaintForm.status = 0;
        }
        this.complaintForm = complaintForm;
      });
      this.complaintVisible = true;
    },

    saveComplaint() {
      this.$refs.complaintForm.validate((valid) => {
        if (valid) {
          const param = this.complaintForm;
          const fileList = this.complaintForm.fileList || [];
          param.annexes = fileList.map((t) => t.url);
          const { id, driverId, passengerId, routeId } = this.selectedOrder.order;
          param.orderId = id;
          param.driverId = driverId;
          param.passengerId = passengerId;
          param.routeId = routeId;
          saveOrUpdateComplaint(param).then(() => {
            this.complaintVisible = false;
            this.$message.success("操作成功");
            this.query(this.currentPage);
          });
        }
      });
    },

    saveBlack(order) {
      this.$prompt("请输入拉黑原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\r\w\W\S?!]{5,}$/,
        inputErrorMessage: "请输入拉黑原因(最少5个字)",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          const param = {
            driverId: order.driverId,
            orderId: order.id,
            passengerId: order.passengerId,
            remark: value,
          };
          saveBlackDriver(param).then(() => {
            this.$message.success("操作成功");
          });
        })
        .catch(() => {});
    },

    showPassengetMap(route, orders, isStart) {
      const orderList = orders.map((t) => t.order);
      this.$bus.$emit(SHOW_ROUTE_MAP, route, orderList, isStart);
    },

    showShuntMap(route, orders) {
      const orderList = orders.map((t) => t.order);
      this.$bus.$emit(SHOW_SHUNT_MAP, route, orderList);
    },

    handleCommand(command, item) {
      command = parseInt(command);
      switch (command) {
        case 1:
          this.$orderLog().show(item.order.id);
          break;
        case 2:
          this.saveBlack(item.order);
          break;
        case 3:
          this.goRouteReplay(item.order);
          break;
      }
    },

    goRouteReplay(order) {
      const { id, routeId } = order || {};
      if (id) {
        const { href } = Router.resolve({
          path: "/route/replay",
          query: { id: routeId, orderId: id },
        });
        window.open(href, "_blank");
      } else {
        this.$message.warning("此订单没有行程信息");
      }
    },
    cancel(item) {
      this.$prompt("请输入取消原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\r\w\W\S?!]{5,}$/,
        inputErrorMessage: "请输入取消原因(最少5个字)",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          const param = {
            orderId: item.id,
            cancelDesc: value,
          };
          cancelOrder(param).then(() => {
            this.$message.success("订单取消成功");
            if (this.onCancel) {
              this.onCancel();
            }

            this.query();
          });
        })
        .catch(() => {});
    },

    handleCurrentChange(page) {
      this.query(page);
    },
    handleSizeChange(pageSize) {
      this.currentPage = 1;
      this.query(1, pageSize);
    },

    showRoutePlan(route) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, route, false);
    },
  },
};
</script>

<style scoped lang="less">
.cond {
  width: 100%;
  padding: 10px 0;
  border-bottom: 6px solid #adc6ff;
  box-sizing: border-box;
}

.grey {
  color: #909399 !important;
}

.table {
  margin: 20px 0;
  padding-right: 20px;
}

.page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.route-info {
  font-size: 15px;
  font-weight: bold;
  margin-right: 20px;
}

.order-index {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  color: white;
  background-color: #6ca5ea;
}

/deep/ .route-detail .no-hover {
  .table-expand {
    padding-left: 40px;
  }

  .expanded + tr {
    .el-table tr {
      background-color: rgba(224, 232, 251, 0.23) !important;

      &:hover {
        background-color: rgba(224, 232, 251, 0.23) !important;
      }
    }

    .table-expand {
      background-color: rgba(224, 232, 251, 0.23);
    }
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #fff;
  }

  .expanded.el-table__row--striped + tr {
    .el-table tr {
      background-color: rgba(194, 255, 164, 0.26) !important;

      &:hover {
        background-color: rgba(194, 255, 164, 0.26) !important;
      }
    }

    .table-expand {
      background-color: rgba(194, 255, 164, 0.26);
    }
  }
}

.qc {
  margin-left: 10px;
  background-color: #409eff;
  cursor: pointer;
  color: white;
  padding: 5px 16px;
  border-radius: 6px;
  font-size: 13px;
  &.process0 {
    background-color: #409eff;
  }
  &.process1 {
    background-color: orange;
  }
  &.process2 {
    background-color: #56c556;
  }
  &.process3 {
    background-color: #f56c6c;
  }
}
</style>
