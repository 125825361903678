<template>
  <div class="template-container">
    <el-container>
      <!-- 自定义字段选项区 -->
      <el-aside width="200px">
        <div class="title-label">按钮区</div>
        <div class="btn" @click="addComponent('map')">地图</div>
        <div class="btn" @click="addComponent('voice')">音频</div>
        <div class="btn" @click="addComponent('vedio')">视频</div>
        <div class="btn" @click="addComponent('img')">图片</div>
        <div class="btn" @click="addComponent('link')">链接</div>
        <div class="btn" @click="addComponent('input')">输入框</div>
        <div class="btn" @click="addComponent('btn')">按钮</div>
        <div class="btn">&nbsp;</div>

        <div class="title-label">重要程度</div>
        <div style="padding-left: 10px; margin-bottom: 10px">
          <el-radio
            v-model="noticeTemplate.emergency"
            v-for="emc in emergencyObjs"
            :key="emc.value"
            :label="emc.value"
            style="margin-top: 10px; display: block"
            >{{ emc.label }}</el-radio
          >
        </div>

        <div class="title-label">需要确认？</div>
        <div style="padding-left: 10px; margin-bottom: 10px">
          <el-radio
            v-model="noticeTemplate.needConfirm"
            :label="true"
            style="margin-top: 10px; display: block"
            >是</el-radio
          >
          <el-radio
            v-model="noticeTemplate.needConfirm"
            :label="false"
            style="margin-top: 10px; display: block"
            >否</el-radio
          >
        </div>

        <div class="title-label">仅管理员发送模板</div>
        <div style="padding-left: 10px; margin-bottom: 10px">
          <el-radio
                  v-model="noticeTemplate.adminSend"
                  :key="0"
                  :label="false"
                  style="margin-top: 10px; display: block"
          >否</el-radio
          >
          <el-radio
                  v-model="noticeTemplate.adminSend"
                  :key="1"
                  :label="true"
                  style="margin-top: 10px; display: block"
          >是</el-radio
          >
        </div>

        <div class="title-label">接收对象</div>
        <div style="padding: 0 8px 8px 8px">
          <div>选择车队</div>
          <el-select
            v-model="noticeTemplate.receiverTeam"
            placeholder="请选择接收车队"
            clearable
            multiple
          >
            <el-option
              v-for="item in teamOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div style="padding: 0 8px">
          <div>选择司机</div>
          <el-input
            v-model="noticeTemplate.receiver"
            placeholder="请输入司机编号"
          ></el-input>
        </div>
      </el-aside>

      <!-- 预览区 -->
      <el-main
        style="border-right: 1px solid #e6e6e6; border-left: 1px solid #e6e6e6"
      >
        <el-row @click.native="unsetCfg">
          <el-col :span="3">模板编码：</el-col>
          <el-col :span="12">
            <el-input v-model="noticeTemplate.code" placeholder="系统自动生成" :disabled="true"></el-input>
          </el-col>
        </el-row>
        <el-row @click.native="unsetCfg">
          <el-col :span="3">模板名称：</el-col>
          <el-col :span="12">
            <el-input v-model="noticeTemplate.name" placeholder="请输入模板名称"></el-input>
          </el-col>
        </el-row>
        <el-row @click.native="unsetCfg">
          <el-col :span="3">模板分类：</el-col>
          <el-col :span="12">
            <el-select
                    v-model="noticeTemplate.flCode"
                    placeholder="请选择模板分类"
            >
              <el-option
                      v-for="item in templateFlObj"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row @click.native="unsetCfg">
          <el-col :span="3">标题：</el-col>
          <el-col :span="12">
            <el-input
              v-model="noticeTemplate.title"
              placeholder="请输入标题"
            ></el-input>
          </el-col>
        </el-row>
        <el-row @click.native="unsetCfg">
          <el-col :span="3">副标题：</el-col>
          <el-col :span="12">
            <el-input
              v-model="noticeTemplate.subTitle"
              placeholder="请输入标题"
            ></el-input>
          </el-col>
        </el-row>
        <el-row @click.native="unsetCfg">
          <el-col :span="3">发送内容:</el-col>
          <el-col :span="12">
            <el-input
              type="textarea"
              v-model="noticeTemplate.content"
            ></el-input>
          </el-col>
        </el-row>
        <el-row v-for="(field, i) in noticeTemplate.fields" :key="field.id">
          <el-col :span="3">&nbsp;</el-col>
          <el-col :span="12">
            <template v-if="field.type == 'map'">
              <el-image
                :src="require('/static/image/map.png')"
                @click.native="editComponent(field, i)"
              ></el-image>
            </template>
            <template
              v-if="
                field.type == 'img' ||
                field.type == 'vedio' ||
                field.type == 'voice'
              "
            >
              <div class="avatar-uploader" @click="editComponent(field, i)">
                <div class="el-upload">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div v-if="field.type == 'img'">上传图片</div>
                  <div v-if="field.type == 'voice'">上传音频</div>
                  <div v-if="field.type == 'vedio'">上传视频</div>
                </div>
              </div>
            </template>
            <template v-if="field.type == 'link'">
              {{ field.tip }}
              <el-link type="primary" @click.native="editComponent(field, i)">{{
                field.name
              }}</el-link>
            </template>
            <template v-if="field.type == 'input'">
              <div>{{ field.name }}</div>
              <el-input
                type="textarea"
                style="border-radius: 0px"
                :readonly="true"
                :placeholder="field.tip"
                @click.native="editComponent(field, i)"
              ></el-input>
              <el-button
                style="width: 100%; border-radius: 0px; border-top: 0px"
                >{{ field.btnName }}</el-button
              >
            </template>
          </el-col>
        </el-row>
        <el-row>
          <el-button
            v-for="(btn, i) in noticeTemplate.btns"
            :key="btn.id"
            @click.native="editComponent(btn, i)"
            >{{ btn.name }}</el-button
          >
        </el-row>
      </el-main>

      <!-- 属性设置dialog -->
      <el-aside width="600px" style="position: relative">
        <div class="title-label">属性设置<span v-if="currentCfg.id">（id:{{currentCfg.id}}）</span></div>
        <template v-if="current == -1">
          <el-empty description="请选择组件"></el-empty>
        </template>
        <template v-else>
          <template v-if="currentCfg.type == 'map'">
            <div class="el-row">
              <span>纬度:</span>
              <span><el-input v-model="currentCfg.lat"></el-input></span>
            </div>
            <div class="el-row">
              <span>经度:</span>
              <span><el-input v-model="currentCfg.lng"></el-input></span>
            </div>
            <div class="el-row">
              <span>详细地址:</span>
              <span><el-input v-model="currentCfg.value"></el-input></span>
            </div>
          </template>

          <template
            v-if="
              currentCfg.type == 'img' ||
              currentCfg.type == 'vedio' ||
              currentCfg.type == 'voice'
            "
          >
            <div class="el-row">
              <span>附件地址:</span>
              <span><el-input v-model="currentCfg.value"></el-input></span>
            </div>
          </template>

          <template v-if="currentCfg.type == 'link'">
            <div class="el-row">
              <span>显示文字:</span>
              <span><el-input v-model="currentCfg.tip"></el-input></span>
            </div>
            <div class="el-row">
              <span>跳转文字:</span>
              <span><el-input v-model="currentCfg.name"></el-input></span>
            </div>
            <div class="el-row">
              <span>跳转地址:</span>
              <span><el-input v-model="currentCfg.value"></el-input></span>
            </div>
            <div class="el-row">
              <span>跳转参数:</span>
              <span><el-input v-model="currentCfg.args"></el-input></span>
            </div>
          </template>

          <template v-if="currentCfg.type == 'input'">
            <div class="el-row">
              <span>输入标题:</span>
              <span><el-input v-model="currentCfg.name"></el-input></span>
            </div>
            <div class="el-row">
              <span>输入提示语:</span>
              <span><el-input v-model="currentCfg.tip"></el-input></span>
            </div>
            <div class="el-row">
              <span>提交按钮:</span>
              <span><el-input v-model="currentCfg.btnName"></el-input></span>
            </div>
          </template>

          <template v-if="currentCfg.type == 'btn'">
            <div class="el-row">
              <span>按钮名称:</span>
              <span><el-input v-model="currentCfg.name"></el-input></span>
            </div>
            <div class="el-row">
              <span>按钮值:</span>
              <span><el-input v-model="currentCfg.value"></el-input></span>
            </div>
          </template>

          <div
            style="position: absolute; bottom: 10px; left: 10px; right: 10px"
          >
            <el-button style="width: 100%" type="danger" @click="removeCfg"
              >删除此组件</el-button
            >
          </div>
        </template>
      </el-aside>
    </el-container>

    <div class="footer">
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="saveNotice">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeSet",
  props: ["notice"],
  data () {
    return {
      teamOpts: [],
      noticeTypeEnums: [],
      emergencyObjs: [],
      templateFlObj: [],
      metas: {
        map: {
          "id": "",
          "type": "map",
          "value": "",
          "lat": "",
          "lng": "",
          "expired": ""
        },
        img: {
          "id": "",
          "type": "img",
          "value": "",
          "expired": ""
        },
        voice: {
          "id": "",
          "type": "voice",
          "value": "",
          "expired": ""
        },
        vedio: {
          "id": "",
          "type": "vedio",
          "value": "",
          "expired": ""
        },
        link: {
          "id": "",
          "type": "link",
          "value": "",
          "args": "",
          "name": "跳转到",
          "tip": "",
          "expired": ""
        },
        input: {
          "id": "",
          "type": "input",
          "value": "",
          "name": "",
          "btnName": "提交",
          "tip": "请输入",
          "expired": ""
        },
        btn: {
          "id": "",
          "type": "btn",
          "name": "按钮",
          "value": "",
          "expired": ""
        }
      },

      noticeTemplate: {
        fields: [],
        btns: [],
        adminSend: false,
        flCode: ""
      },
      currentCfg: {},
      current: -1
    };
  },
  mounted () {
    this.noticeTypeEnums = window.noticeTypes;
    this.emergencyObjs = window.emergencyObjs;
    this.initPage();
  },
  watch: {
    "noticeTemplate.needConfirm": function (val) {
      this.unsetCfg();
      if (val) {
        this.noticeTemplate.btns = [{"id": new Date().getTime(),
          "type": "btn",
          "name": "我知道了",
          "value": "1",
          "confirmBtn": true,
          "expired": ""}];
      } else {
        this.noticeTemplate.btns = [];
      }
    }
  },
  methods: {
    addComponent (ctyp) {
      if (this.noticeTemplate.needConfirm && ctyp == 'btn') {
        this.$message.error("已有需要确认按钮，不能再增加其他按钮了。");
        return;
      } else if (ctyp == 'input') {
        let fields = this.noticeTemplate.fields || [];
        let haseInputField = false;
        for (var i = 0; i < fields.length; i ++) {
          if (fields[i].type == 'input') {
            haseInputField = true;
            break;
          }
        }
        if (haseInputField) {
          this.$message.error("只能增加一个文本字段");
          return;
        }
      }

      let json = JSON.parse(JSON.stringify(this.metas[ctyp]));
      json.id = new Date().getTime();
      if (ctyp == 'btn') {
        this.noticeTemplate.btns.push(json);
      } else {
        this.noticeTemplate.fields.push(json);
      }
    },
    editComponent (field, i) {
      this.currentCfg = field;
      this.current = i;
    },
    removeCfg () {
      if (this.current >= 0) {
        if (this.currentCfg.type == 'btn') {
          this.noticeTemplate.btns.splice(this.current, 1);
        } else {
          this.noticeTemplate.fields.splice(this.current, 1);
        }
      }
      this.unsetCfg();
    },
    unsetCfg () {
      this.current = -1;
      this.currentCfg = {};
    },

    initPage () {
      this.$http.post("/admin/team/list", {}).then(res => {
        let teams = res.data || [];
        let teamOpts = [];
        teams.forEach(team => {
          teamOpts.push({value: team.id, label: team.name});
        });
        this.teamOpts = teamOpts;
      });

      if (this.notice && this.notice.id) {
        let url = "/admin/notice/template/" + this.notice.id;
        this.$http.post(url, {}).then(res => {
          this.noticeTemplate = res.data;
        });
      } else {
        this.noticeTemplate = {
          fields: [],
          btns: []
        };
      }
    },

    //操作noticeTemplate.
    check () {
      if (!this.noticeTemplate.name) {
        this.$message.error("模板名称不能为空");
        return false;
      }
      if (!this.noticeTemplate.title) {
        this.$message.error("标题必填");
        return false;
      }
      if (!this.noticeTemplate.content) {
        this.$message.error("内容必填");
        return false;
      }
      return true;
    },
    saveNotice () {
      if (!this.check()) {
        return;
      }
      let url = "/admin/notice/savetemplate";
      if (!this.noticeTemplate.emergency) {
        this.noticeTemplate.emergency = "1";
      }
      this.$http.post(url, this.noticeTemplate).then(() => {
        this.closeWindow();
      });
    },
    closeWindow () {
      this.$emit("closeWindow");
    }
  }
};
</script>

<style lang="less">
.template-container {
  .el-row {
    line-height: 40px;
  }
  .el-container {
    border: 1px solid #e6e6e6;
    height: 80vh;
  }
  .title-label {
    background: #eee;
    padding: 10px;
    clear: both;
  }
  .btn {
    padding: 10px 20px;
    float: left;
    width: 59px;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
  }
  .btn:nth-child(odd) {
    border-left: 1px solid #e6e6e6;
  }
  .el-row {
    margin: 10px;
  }

  .footer {
    text-align: right;
    margin: 20px 0;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>