<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >考试标题<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.title"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
      >标题(仅管理员可见)：</el-col
      >
      <el-col class="content" :span="12"
      ><el-input v-model="coverInfo.title2"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >及格标准<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.passDesc"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >考试题量<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.itmNum"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >考试题型<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.itmType"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >题目来源<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.itmSrc"></el-input
      ></el-col>
    </el-row>
    <el-row class="cover-row">
      <el-col class="label" :span="8"
        >备注说明<span class="required">*</span>：</el-col
      >
      <el-col class="content" :span="12"
        ><el-input v-model="coverInfo.desc"></el-input
      ></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PapersCover",
  props: {
    coverInfo: {
      type: Object
    }
  }
};
</script>

<style scoped>
.cover-row {
  margin-top: 12px;
}
.required {
  color: red;
}
.label {
  text-align: right;
  height: 40px;
  line-height: 40px;
}
</style>