<template>
  <div>
    <UserFilter :isSubFilter="true" :isOnline="false" v-model="form" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import UserFilter from './UserFilter.vue';
import {DateUtil, Toolkit} from '@/util';
import _ from 'lodash';

const defaultData = {
  startDate: DateUtil.getDayStr(-90),
  endDate: DateUtil.getDayStr(0),
  regType: [],
  couponType: [],
  pathIds: []
};
export default {
  components: {
    UserFilter
  },
  computed: {
    ...mapState({
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  props: {},
  created () {},
  mounted () {},
  watch: {},
  data () {
    return {
      enablePreConfition: true,
      form: defaultData,
    };
  },
  methods: {

    getParam () {
      const param = {
        filter2: Toolkit.filterEmpty(this.form),
        isOnline: false,
      };
      return param;
    },

    setParam (param) {
      this.form = param;
    },

    initData () {
      this.form = _.cloneDeep(defaultData);
    }

  },
};
</script>
<style lang="less" scoped>
</style>