<template>
  <div class="box">
    <el-form ref="form" size="small" :model="param" inline>
      <el-row>
        <el-col>
          <el-form-item label="统计开始时间：">
            <el-date-picker
              size="small"
              v-model="param.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              align="left"
              @change="query"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="分析维度">
            <el-select
              style="width: 200px"
              v-model="param.type"
              placeholder="请选择"
              @change="query()"
            >
              <el-option
                v-for="item in typeOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="param.type === 'source'">
            <el-select
              style="width: 160px"
              clearable
              v-model="param.orderSource"
              placeholder="全部"
              @change="query()"
            >
              <el-option
                v-for="item in orderSourceOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="param.type === 'team'">
            <el-select
              v-model="param.teamId"
              clearable
              style="width: 160px"
              placeholder="全部"
              @change="query()"
            >
              <el-option
                v-for="item in teamOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 80px" @click="query()">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="middle">
      <div class="table">
        <el-table :data="list" stripe max-height="300" show-summary :summary-method="getSummaries">
          <el-table-column prop="finish_order_source" label="渠道名称" v-if="typeIsSource">
            <template slot-scope="scope">
              <span>{{ scope.row.finish_order_source | enumTransfer(orderSourceOpts) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="finish_team_id" label="车队名称" v-else>
            <template slot-scope="scope">
              <span>{{ scope.row.finish_team_id | enumTransfer(teamOpts, "id", "name") }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="num" label="成交人数"></el-table-column>

          <el-table-column prop="total_discount" label="优惠补贴"></el-table-column>

          <el-table-column prop="ahead_amount" label="早价补贴"></el-table-column>

          <el-table-column prop="discount" label="合计补贴"></el-table-column>

          <el-table-column prop="total_increase" label="加价流水"></el-table-column>

          <el-table-column prop="base_income" label="流水"></el-table-column>

          <el-table-column prop="total_income" label="合计流水"></el-table-column>
        </el-table>
      </div>

      <div class="pie">
        <chart :option="option1" :height="300" ref="chart1" />
      </div>
    </div>

    <div class="chart">
      <div class="date-picker">
        <el-radio-group @change="query()" v-model="param.dateGroupType" :size="size">
          <el-radio-button :key="item.value" :label="item.value" v-for="item in periodOpts">{{
            item.label
          }}</el-radio-button>
        </el-radio-group>
      </div>
      <chart :option="option2" :height="400" ref="chart2" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateUtil, ParamUtil } from "@/util";
import { querySettlementSourceAnalysis, querySettlementSourceOverviewAnalysis } from "@/api";

const TYPE_ENUM = {
  SOURCE: "source",
  TEAM: "team",
};
export default {
  components: {},
  props: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      orderSourceOpts: (state) => state.enumMap["ORDER_SOURCE"],
      teamOpts: (state) => state.teamOpts,
    }),

    orderSourceMap() {
      const map = {};
      const arr = this.orderSourceOpts || [];
      arr.forEach((item) => (map[item.value] = item.label));
      return map;
    },

    teamMap() {
      const map = {};
      const arr = this.teamOpts || [];
      arr.forEach((item) => (map[item.id] = item.name));
      return map;
    },

    typeIsSource() {
      return (
        (this.param.type === TYPE_ENUM.SOURCE && !this.param.orderSource) ||
        (this.param.type === TYPE_ENUM.TEAM && !!this.param.teamId)
      );
    },
  },
  data() {
    const today = DateUtil.getDayStr(0);
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const start = DateUtil.getDayStr(-7);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const start = DateUtil.getDayStr(-30);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近两个月",
            onClick(picker) {
              const start = DateUtil.getDayStr(-60);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近1年",
            onClick(picker) {
              const start = DateUtil.getDayStr(-365);
              picker.$emit("pick", [start, today]);
            },
          },
          {
            text: "最近2年",
            onClick(picker) {
              const start = DateUtil.getDayStr(-730);
              picker.$emit("pick", [start, today]);
            },
          },
        ],
      },
      typeOpts: [
        {
          label: "订单渠道",
          value: TYPE_ENUM.SOURCE,
        },
        {
          label: "车队",
          value: TYPE_ENUM.TEAM,
        },
      ],

      periodOpts: [
        {
          label: "日",
          value: "byday",
        },
        {
          label: "月",
          value: "bymonth",
        },
        {
          label: "年",
          value: "byyear",
        },
      ],

      param: {
        type: TYPE_ENUM.SOURCE,
        date: [DateUtil.getDayStr(-30), today],
        dateGroupType: "byday",
      },
      list: [],
      option1: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
        },
      },
      option2: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        xAxis: { type: "category" },
        yAxis: {},
        tooltip: {
          show: true,
          trigger: "axis",
        },
      },
    };
  },
  methods: {
    query() {
      const { date, type, teamId, orderSource, dateGroupType } = this.param || {};

      const param = {
        startTime: date[0],
        endTime: date[1],
        type,
        dateGroupType,
        teamId,
        orderSource,
      };

      if (type === TYPE_ENUM.SOURCE) {
        delete param.teamId;
      } else {
        delete param.orderSource;
      }

      if (param.teamId || param.orderSource) {
        querySettlementSourceAnalysis(ParamUtil.filterEmptyData(param)).then((res) => {
          const { data = [], chart = [] } = res.data || {};
          this.list = data;
          // 如果选择了车队或者渠道，那么实际的类型和选择的类型相反
          const realType = type === TYPE_ENUM.TEAM ? TYPE_ENUM.SOURCE : TYPE_ENUM.TEAM;
          param.type = realType;
          this.renderPieChart(data, realType);
          this.renderLineChart(chart, realType);
        });
      } else {
        querySettlementSourceOverviewAnalysis(ParamUtil.filterEmptyData(param)).then((res) => {
          const { data = [], chart = [] } = res.data || {};
          this.list = data;
          this.renderPieChart(data, type);
          this.renderLineChart(chart, type);
        });
      }
    },

    renderLineChart(data, type) {
      const series = [];
      const dimensions = ["date_range"];
      if (type === TYPE_ENUM.SOURCE) {
        const arr = this.orderSourceOpts;

        arr.forEach((item) => {
          const key = `s${item.value}`;
          series.push({
            type: "line",
            smooth: true,
            name: item.label,
            encode: {
              x: "date_range",
              y: key,
            },
          });
          dimensions.push(key);
        });
      } else {
        const arr = this.teamOpts;
        arr.forEach((item) => {
          const key = `t${item.id}`;
          series.push({
            type: "line",
            smooth: true,
            name: item.name,
          });
          dimensions.push(key);
        });
      }

      const option = {
        series,
        dataset: {
          dimensions,
          source: data,
        },
      };
      this.$refs.chart2.setOption(option, true);
    },

    renderPieChart(data, type) {
      const map = type === TYPE_ENUM.SOURCE ? this.orderSourceMap : this.teamMap;
      const field = type === TYPE_ENUM.SOURCE ? "finish_order_source" : "finish_team_id";
      const list = data.map((item) => {
        return {
          name: map[item[field]],
          ...item,
        };
      });
      let dataset = {
        dimensions: ["name", "total_income"],
        source: list,
      };
      const option = {
        series: [
          {
            type: "pie",
            smooth: true,
            label: {
              formatter: "{b}: {@total_income} ({d}%)",
            },
            radius: "40%",
            center: ["50%", "50%"],
            tooltip: {
              show: true,
              // formatter : '{b}: {@total_income}万元 ({d}%)'
              formatter: (params) => {
                const { data, percent } = params;
                return `${data.name}: ${data.total_income} (${percent}%)`;
              },
            },
          },
        ],
        dataset,
      };
      this.$refs.chart1.setOption(option);
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = result.toFixed(2);
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 10px 0;
  .middle {
    display: flex;
    height: 300px;
    border: 1px solid #ddd;

    .table {
      flex: 1;
      margin-right: 10px;
    }

    .pie {
      width: 600px;
    }
  }

  .chart {
    margin: 10px 0;
    border: 1px solid #ddd;
    height: 400px;
    position: relative;

    .date-picker {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }
}
</style>
