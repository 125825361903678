<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.startDate"
            type="date"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.endDate"
            type="date"
            placeholder="选择结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-checkbox-group v-model="param.regType">
            <el-checkbox
              v-for="item in regTypeOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item style="margin-left: 40px">
          <el-radio-group v-model="param.continueDays">
            <el-radio v-for="item in continueDaysOpt" :label="item" :key="item"
              >连续{{ item }}天登录</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="query()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column label="电话" width="120">
          <template slot-scope="scope">
            {{ scope.row.passenger.telephone }}
            <span v-if="scope.row.driverNo">({{ scope.row.driverNo }})</span>
          </template>
        </el-table-column>

        <el-table-column label="VIP">
          <template slot-scope="scope">
            {{ scope.row.passenger.vipLevel }}
          </template>
        </el-table-column>

        <el-table-column label="微信注册时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.passenger.wxRegisterTime }}
          </template>
        </el-table-column>

        <el-table-column label="电话注册时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.passenger.telRegisterTime }}
          </template>
        </el-table-column>

        <el-table-column label="APP注册时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.passenger.appRegisterTime }}
          </template>
        </el-table-column>

        <el-table-column label="开始登录日期" width="120" prop="startDate">
        </el-table-column>

        <el-table-column
          label="连续登录天数"
          prop="continueDays"
        ></el-table-column>

        <el-table-column
          prop="latestOrderDateBefore"
          label="最近下单日期之前"
          width="120"
        ></el-table-column>

        <el-table-column
          prop="latestOrderDateAfter"
          label="最近下单日期之后"
          width="120"
        ></el-table-column>

        <el-table-column prop="latestOrderDays" label="最近订单距离">
        </el-table-column>

        <el-table-column prop="orderIn2Days" label="2天内是否下单">
          <template slot-scope="scope">
            {{ scope.row.orderIn2Days | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="receivedSms" label="2天内是否收到短信">
          <template slot-scope="scope">
            {{ scope.row.receivedSms | yesNo }}
          </template></el-table-column
        >

        <el-table-column
          prop="visitedDuration"
          label="访问时长"
        ></el-table-column>

        <el-table-column prop="visitedTimes" label="访问次数"></el-table-column>

        <el-table-column
          prop="visitedPageNums"
          label="访问页面数"
        ></el-table-column>

        <el-table-column
          prop="visitedLastPage"
          label="最后访问页面"
        ></el-table-column>

        <el-table-column prop="visitedHome" label="首页">
          <template slot-scope="scope">
            {{ scope.row.visitedHome | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="visitedTicket" label="列表">
          <template slot-scope="scope">
            {{ scope.row.visitedTicket | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="visitedDetail" label="详情">
          <template slot-scope="scope">
            {{ scope.row.visitedDetail | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="visitedShare" label="活动">
          <template slot-scope="scope">
            {{ scope.row.visitedShare | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="visitedCoupon" label="优惠">
          <template slot-scope="scope">
            {{ scope.row.visitedCoupon | yesNo }}
          </template>
        </el-table-column>

        <el-table-column prop="visitedCustomerService" label="客服">
          <template slot-scope="scope">
            {{ scope.row.visitedCustomerService | yesNo }}
          </template></el-table-column
        >

        <el-table-column
          prop="id"
          label="操作"
          width="100"
          align="right"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="go2OrderList(scope.row)"
              >订单</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              size="small"
              @click="getUserOptDetail(scope.row)"
              >操作</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pie-box">
      <div>
        <chart-view
          ref="chart1"
          valueField="num"
          nameField="name"
          title="2天内是否收到短信"
          initChartType="pie"
        />
      </div>

      <div>
        <chart-view
          ref="chart2"
          valueField="num"
          nameField="name"
          title="2天内是否下过单"
          initChartType="pie"
        />
      </div>

      <div>
        <chart-view
          ref="chart3"
          valueField="num"
          nameField="name"
          title="访问时长"
          initChartType="pie"
        />
      </div>
    </div>

    <div class="line-chart">
      <chart-view
        ref="chart4"
        valueField="num"
        nameField="name"
        title="访问页面"
        initChartType="line"
      />
    </div>

    <div class="line-chart">
      <chart-view
        ref="chart5"
        valueField="num"
        nameField="name"
        title="最后访问页面"
        initChartType="line"
      />
    </div>

    <div class="line-chart">
      <chart-view
        ref="chart6"
        valueField="num"
        nameField="name"
        title="访问页面数"
        initChartType="line"
      />
    </div>

    <el-drawer
      title="操作明细"
      :visible.sync="detailVisible"
      direction="ltr"
      :with-header="false"
      :append-to-body="true"
      :size="1000"
    >
      <div class="box">
        <OptDetail ref="detail" />
      </div>
    </el-drawer>
  </div>
</template>

<script>

import {justLoginAnalysis, userActionDetail} from '@/api';
import { DateUtil } from '@/util';
import ChartView from '../../ChartView';
import OptDetail from '../OptDetail.vue';
export default {
  filters: {
    yesNo (val) {
      return val === true ? '是' : '否';
    }
  },
  components: {
    ChartView,
    OptDetail
  },
  props: {},
  created () {

  },
  mounted () {
    this.query();
  },
  watch: {},
  computed: {},
  data () {
    return {
      param: {
        startDate: DateUtil.getDayStr(-2),
        endDate: DateUtil.getDayStr(0),
        regType: ['MP_PASSENGER', 'APP_PASSENGER'],
        continueDays: 2,
      },
      regTypeOpts: [{label: '小程序', value: 'MP_PASSENGER'}, {label: 'APP', value: 'APP_PASSENGER'}],
      continueDaysOpt: [1, 2, 3, 4, 5],
      list: [],
      detailVisible: false,
    };
  },
  methods: {

    query () {
      justLoginAnalysis(this.param).then(res=>{
        const {detailList = [], lastPageVisitedTop, orderedIn2Days, pageVisitedNum, pageVisitedTop, receivedSmsIn2Days, userVisitedDuring} = res.data || {};
        if (detailList.length) {
          this.list = detailList;
          this.$refs.chart1.setData(receivedSmsIn2Days);
          this.$refs.chart2.setData(orderedIn2Days);
          this.$refs.chart3.setData(userVisitedDuring);
          this.$refs.chart4.setData(pageVisitedTop);
          this.$refs.chart5.setData(lastPageVisitedTop);
          this.$refs.chart6.setData(pageVisitedNum);
        }
      });
    },

    go2OrderList (item) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: {tel: item.passenger.telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    getUserOptDetail (item) {
      const endDate = DateUtil.getSpecDayStr(item.startDate, item.continueDays);
      const param = {
        startTime: `${item.startDate} 00:00:00`,
        endTime: `${endDate} 23:59:59`,
        mobile: item.passenger.telephone
      };
      this.detailVisible = true;
      userActionDetail(param).then(res=>{
        this.$refs.detail.show(res.data, item.passenger);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.pie-box {
  display: flex;
  justify-content: space-around;
  border: 1px solid #ddd;
  margin: 40px 0;
}
</style>