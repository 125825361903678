<template>
  <el-dialog
    :visible.sync="visible"
    width="500px"
    :destroy-on-close="true"
    :modal="modal"
  >
    <div class="content" v-loading="loading">
      <template v-if="form">
        <div>
          <el-form ref="form" :model="form" size="small" label-width="120px">
            <template v-if="form.orderType === 1">
              <el-form-item v-if="form.adultNumber" label="购票："
                >{{ form.adultNumber }}成人

                <span v-if="form.childNumber"
                  >+ {{ form.childNumber }}儿童</span
                >
              </el-form-item>

              <el-form-item label="基础价格：" prop="cash"
                >{{ form.basePrice | moneyFormat }} 元</el-form-item
              >

              <el-form-item
                v-if="form.childrenPrice && form.childNumber"
                label="儿童价格："
                prop="cash"
                >{{ form.childrenPrice | moneyFormat }} 元</el-form-item
              >
            </template>

            <template v-else>
              <template v-if="form.orderType === 2">
                <el-form-item>带货</el-form-item>
              </template>
              <template v-else>
                <el-form-item> {{ form.number }}人包车</el-form-item>
              </template>
            </template>

            <el-form-item
              v-if="form.startAreaUpPrice"
              label="起点加价："
              prop="cash"
              >{{ form.startAreaUpPrice | moneyFormat }} 元</el-form-item
            >
            <el-form-item
              v-if="form.endAreaUpPrice"
              label="终点加价："
              prop="cash"
              >{{ form.endAreaUpPrice | moneyFormat }} 元</el-form-item
            >
            <el-form-item
              v-if="form.otherUpPrice"
              label="其他加价："
              prop="cash"
              >{{ form.otherUpPrice | moneyFormat }} 元</el-form-item
            >
            <el-form-item
              v-if="form.discountWordAmount"
              label="优惠口令："
              prop="cash"
              >{{ form.discountWordAmount | moneyFormat }} 元</el-form-item
            >
            <el-form-item v-if="form.couponAmount" label="优惠券：" prop="cash"
              >{{ form.couponAmount | moneyFormat }} 元</el-form-item
            >
            <el-form-item v-if="form.aheadAmount" label="早价优惠：" prop="cash"
              >{{ form.aheadAmount | moneyFormat }} 元</el-form-item
            >
            <el-form-item
              v-if="form.otherDiscount"
              label="其他折扣："
              prop="cash"
              >{{ form.otherDiscount | moneyFormat }} 元</el-form-item
            >
            <el-form-item v-if="form.price" label="总价：" prop="price"
              >{{ form.price | moneyFormat }} 元</el-form-item
            >
          </el-form>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import {getOrderBill} from '@/api';

export default {
  props: {
    text: String,
    billId: Number,
    modal: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      form: {},
      loading: false,
      visible: false
    };
  },
  methods: {
    show (id) {
      this.visible = true;
      this.loading = true;
      getOrderBill({billId: id}).then((res)=>{
        this.loading = false;
        this.form = res.data ;
      });
    }

  }

};
</script>
<style lang="less" scoped>
.content {
  min-height: 400px;
}

.text {
  color: #4894ef;
  cursor: pointer;
}
</style>