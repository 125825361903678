<template>
  <div v-if="driver && summary && data">
    <div class="info1">
      <el-form size="small" :inline="true">
        <el-row>
          <el-col :span="3">
            <el-form-item label="司机：">
              {{ driver.name }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="车队：">
              {{ driver.teamId | enumTransfer(teamOpts, "id", "name") }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="车型品牌：">
              {{ driver.vehicleColor }}·{{ driver.brand }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="注册时间：">
              {{ driver.registerTime }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="初始提点："> </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="安全加分：">
              {{ summary.totalIncrease }}分
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="3">
            <el-form-item label="班号：">
              {{ driver.no }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="手机号：">
              {{ driver.telephone }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="服务车型：">
              {{
                driver.vehicleTypeId
                  | enumTransfer(vehicleTypeOpts, "id", "content")
              }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="总行程数：">
              {{ data.totalRoute }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="当前提点：">
              {{ driver.commission }}
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="安全减分：">
              {{ summary.totalReduce }}分
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="info2">
      {{ driver.no }} 总行程{{ data.totalRoute }}，总违规{{
        summary.badList.total
      }}，累计安全分{{ summary.total }}
    </div>

    <div>
      <el-table :data="list" stripe max-height="480">
        <el-table-column
          label="序号"
          type="index"
          width="100"
        ></el-table-column>

        <el-table-column prop="date" label="日期"></el-table-column>
        <el-table-column prop="pathId" label="路线">
          <template slot-scope="scope">
            {{ scope.row.pathId | pathNameTransfer }}
          </template>
        </el-table-column>
        <el-table-column
          prop="violationName"
          label="违规类型"
        ></el-table-column>
        <el-table-column prop="score" label="扣分"></el-table-column>
        <el-table-column prop="amount" label="罚款"></el-table-column>
        <el-table-column prop="channel" label="渠道">
          <template slot-scope="scope">
            {{ scope.row.channel | enumTransfer(complaintChannelOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="annex1" label="附件">
          <template slot-scope="scope" v-if="scope.row.annexes">
            <a
              v-for="(item, index) in scope.row.annexes"
              :key="item"
              class="annex"
              :href="item"
              target="_blank"
              >附件{{ index + 1 }}</a
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  components: {},
  props: {
    value: Object
  },
  created () {},
  mounted () {},
  watch: {
    value (val) {
      this.dealData(val);
    }
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      vehicleTypeOpts: state => state.vehicleTypeOpts,
      teamOpts: state => state.teamOpts,
      complaintChannelOpts: state => state.enumMap['EVA_SOURCE_CHANNEL'],
    })
  },
  data () {
    return {
      driver: null,
      list: [],
      summary: null,
      data: null,
    };
  },
  methods: {
    dealData (data) {
      this.data = data;
      const {driver, list, summary} = data || {};
      this.driver = driver;
      this.list = list ;
      this.summary = summary;
    },
  },
};
</script>
<style lang="less" scoped>
.info1 {
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.info2 {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
</style>