<template>
  <div class="root-box">
    <el-tabs v-model="active" @tab-click="tabChange">
      <el-tab-pane label="乘客投诉类" name="1"></el-tab-pane>
      <el-tab-pane label="乘客咨询类" name="2"></el-tab-pane>
      <el-tab-pane label="紧急客情类" name="3"> </el-tab-pane>
      <el-tab-pane label="司机注册类" name="4"></el-tab-pane>
      <el-tab-pane label="司机日常类" name="5"> </el-tab-pane>
      <el-tab-pane label="其他类" name="6"></el-tab-pane>
    </el-tabs>

    <div class="head" v-if="filterQuestion.length > 0">
      <div>常见问题及解答</div>
      <div>
        <el-input
          placeholder="请输入搜索内容"
          v-model="searchKey"
          size="small"
          clearable
        >
        </el-input>
      </div>
    </div>

    <el-card
      class="box-card"
      shadow="hover"
      v-for="(item, index) in filterQuestion"
      :key="item.que"
    >
      <div slot="header" class="header">
        <span>{{ index + 1 }}、{{ item.que }}</span>
      </div>

      <div class="ans">
        <div class="title">
          <i class="el-icon-message-solid"></i> 处理流程：
        </div>
        <div class="text">
          {{ item.flow }}
        </div>
      </div>

      <div class="ans">
        <div class="title title2"><i class="el-icon-info"></i> 沟通话术：</div>
        <div class="text">
          {{ item.ans }}
        </div>
      </div>
    </el-card>

    <!-- <div
      class="content"
      v-for="(item, index) in filterQuestion"
      :key="item.que"
    >
      <div class="que">{{ index + 1 }}、{{ item.que }}</div>
      <div class="ans">
        <div>处理流程：</div>
        <div>{{ item.flow }}</div>
      </div>
      <div class="ans">
        <div class="title">沟通话术：</div>
        <div class="text">{{ item.ans }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>

import {questions} from './question';

export default {
  components: {},
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {
    filterQuestion () {

      if (this.active === '6') {
        if (!this.searchKey) {
          return this.questions;
        } else {
          return this.questions.filter(item=> item.que.indexOf(this.searchKey) > -1 || item.ans.indexOf(this.searchKey) > -1);
        }
      }
      return [];
    }
  },
  data () {
    return {
      questions,
      searchKey: '',
      active: '1',
    };
  },
  methods: {
    tabChange (tab) {
      this.active = tab.name;
    }
  },
};
</script>
<style lang="less" scoped>
.root-box {
  padding: 10px;
  .head {
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    div {
      margin-right: 20px;
    }
  }

  .box-card {
    margin-bottom: 20px;
    .text {
      font-size: 15px;
      line-height: 30px;
      word-spacing: 3px;
      text-indent: 1.5em;
    }
    background-color: #f2f6fc;

    .header {
      background-color: white;
      padding: 5px;
      font-weight: bold;
    }

    /deep/ .el-card__body {
      padding: 10px;

      .el-divider--horizontal {
        margin: 12px 0;
      }
    }

    /deep/ .el-card__header {
      padding: 8px 10px;
    }

    .ans {
      padding: 10px;
      background-color: white;
    }

    .title {
      margin-bottom: 5px;
      i {
        font-size: 18px;
        position: relative;
        top: 1px;
      }
      color: #409eff;
    }

    .title2 {
      color: #67c23a;
    }
  }

  .content {
    margin: 10px 0 40px;
    font-size: 15px;
    .que {
      font-weight: 400;
      background-color: #f5f5f5;
      font-family: Courier New !important;
      border: 1px solid #ccc;
      padding: 10px 5px;
      overflow: auto;
      margin: 5px 0;
      color: #000;
    }

    .ans {
      margin: 5px 0 15px 0;
      border: 1px solid #ddd;
      padding: 10px 5px;
      border-radius: 5px;
      word-spacing: 3px;
      text-indent: 1.5em;
      line-height: 24px;
      color: #864242;
    }
  }
}
</style>