<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="车型" name="ref1">
        <VehicleTypeList />
      </el-tab-pane>

      <el-tab-pane label="配置" name="ref2">
        <VehicleTypeConfig />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import VehicleTypeList from './List.vue';
import VehicleTypeConfig from './Config.vue';

export default {
  components: {
    VehicleTypeList,
    VehicleTypeConfig
  },
  mounted () {
  },
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {

  }
};
</script>

<style scoped lang="less">
</style>
