<template>
  <div class="root-box">
    <div>
      <el-form>
        <el-form-item style="float: right">
          <el-button
            style="width: 100px"
            size="small"
            type="primary"
            @click="openForm()"
            >添加车队</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="name" label="车队名称"></el-table-column>

        <el-table-column label="正向路线">
          <template slot-scope="scope">
            <div>{{ scope.row.route1 | pathNameTransfer }}</div>
          </template>
        </el-table-column>

        <el-table-column label="反向路线">
          <template slot-scope="scope">
            <div>{{ scope.row.route2 | pathNameTransfer }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="basePrice"
          label="保底价格（元）"
        ></el-table-column>

        <el-table-column
          prop="avgPublicIncome"
          label="人均公摊（分）"
        ></el-table-column>

        <el-table-column prop="feeDeposit" label="押金（元）"></el-table-column>

        <el-table-column
          prop="feeManage"
          label="管理费（元）"
        ></el-table-column>

        <el-table-column prop="baseScore" label="提点">
          <template slot-scope="scope">
            <div>{{ scope.row.baseScore }}‰</div>
          </template></el-table-column
        >

        <el-table-column prop="rewardScore" label="好评奖励">
          <template slot-scope="scope">
            <div>{{ scope.row.rewardScore }}‰</div>
          </template></el-table-column
        >
        <el-table-column prop="punishedScore" label="差评惩罚">
          <template slot-scope="scope">
            <div>{{ scope.row.punishedScore }}‰</div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建日期"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openForm(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      :title="isUpdate ? '修改车队' : '新增车队'"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="180px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="车队名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="正向路线：" prop="route1">
          <el-select v-model="form.route1" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="反向路线：" prop="route2">
          <el-select v-model="form.route2" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="保底价格（元）：" prop="basePrice">
          <el-input-number
            v-model="form.basePrice"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="押金（元）：" prop="feeDeposit">
          <el-input-number
            v-model="form.feeDeposit"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="管理费（元）：" prop="feeManage">
          <el-input-number
            v-model="form.feeManage"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="基本提点（‰）：" prop="baseScore">
          <el-input-number
            v-model="form.baseScore"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="人均公摊（分）：" prop="avgPublicIncome">
          <el-input-number
            v-model="form.avgPublicIncome"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="好评奖励比例（‰）：" prop="rewardScore">
          <el-input-number
            v-model="form.rewardScore"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="差评惩罚比例（‰）：" prop="punishedScore">
          <el-input-number
            v-model="form.punishedScore"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getTeams, driverTeamSaveOrUpdate} from '@/api';
import {mapState} from 'vuex';

export default {

  created () {
    this.query();
  },
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      }
    }),
    isUpdate () {
      return this.form && this.form.id;
    }
  },
  data () {
    return {
      visible: false,
      list: [],
      form: {},
      rules: {
        name: [
          {required: true, message: '请输入车队名称', trigger: 'blur'},
        ],
        route1: [
          {required: true, message: '请选择反向路线', trigger: 'blur'},
        ],
        route2: [
          {required: true, message: '请选择正向路线', trigger: 'blur'},
        ],
        punishedScore: [
          {required: true, message: '请输入惩罚比例', trigger: 'blur'},
        ],
        rewardScore: [
          {required: true, message: '请输入奖励比例', trigger: 'blur'},
        ],
        basePrice: [
          {required: true, message: '请输入保底价格', trigger: 'blur'},
        ],
      },
    };
  },
  methods: {
    query () {
      getTeams(false).then(res => {
        this.list = res.data || [];
      });
    },
    openForm (item) {
      this.visible = true;

      if (item) {
        const form = {...item};

        this.form = form;
      } else {
        this.form = {};
      }

    },
    saveOrUpdate () {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        driverTeamSaveOrUpdate(this.form).then(()=>{
          this.form = {};
          this.$message.success('操作成功');
          this.query();
          this.visible = false;
        });
      });

    }
  }
};
</script>

<style scoped>
</style>
