// 带入电话号码
export const EV_INTO_TEL = "EV_INTO_TEL";

// 电话下单
export const ORDER_CHANGE = "ORDER_CHANGE";

// 显示司机位置
export const SHOW_DRIVER_LOCATION = "SHOW_DRIVER_LOCATION";

// 显示指定司机位置
export const SHOW_DRIVER_SPEC_LOCATION = "SHOW_DRIVER_SPEC_LOCATION";

// 显示乘客位置
export const SHOW_PASSENGER_MAP = "SHOW_PASSENGER_MAP";

// 显示派单地图
export const SHOW_DISPATCH_MAP = "SHOW_DISPATCH_MAP";

// 显示分流地图
export const SHOW_SHUNT_MAP = "SHOW_SHUNT_MAP";

// 显示行程地图
export const SHOW_ROUTE_MAP = "SHOW_ROUTE_MAP";

// 显示下单地址地图
export const SHOW_BOOK_ADDRESS_MAP = "SHOW_BOOK_ADDRESS_MAP";

// 切换瑞玛队列
export const CHANGE_RUIMA_QUEUE = "CHANGE_RUIMA_QUEUE";

// 显示实时位置
export const SHOW_REALTIME_LOCATION = "SHOW_REALTIME_LOCATION";

// 显示行程规划
export const SHOW_ROUTE_PLAN = "SHOW_ROUTE_PLAN";

// 显示地点选择地图
export const SHOW_LOCATION_SELECT_MAP = "SHOW_LOCATION_SELECT_MAP";

// 显示修改订单弹框
export const SHOW_CHANGE_ORDER_MODAL = "SHOW_CHANGE_ORDER_MODAL";

// 显示退票弹框
export const SHOW_REFUND_ORDER_MODAL = "SHOW_REFUND_ORDER_MODAL";

// 显示监控弹框
export const SHOW_MONITOR_MODAL = "SHOW_MONITOR_MODAL";

// 显示罚单弹框
export const SHOW_PUNISH_DIAG = "SHOW_PUNISH_DIAG";

// 展示图片编辑弹框
export const SHOW_EDIT_IMG_DIAG = "SHOW_EDIT_IMG_DIAG";

// 展示自动派单弹框
export const SHOW_AUTO_DISPATCH_MODAL = "SHOW_AUTO_DISPATCH_MODAL";

// 报警弹框
export const SHOW_ROUTE_ALARM_MODAL = "SHOW_ROUTE_ALARM_MODAL";
