import { Http } from "@/util";

export function listVehicle(param) {
  return Http.post("/admin/vehicle/list", param);
}

export function listVehicleInsurance(param) {
  return Http.post("/admin/vehicle/insurance/query", param);
}

export function addVehicleInsurance(param) {
  return Http.post("/admin/vehicle/insurance/add", param);
}

export function getVehicleInfo(id) {
  return Http.post("/admin/vehicle/info", { id });
}

export function saveOrUpdateVehicleInfo(param) {
  return Http.post("/admin/vehicle/save_or_update", param);
}

export function reportVehicleData(vehicleId) {
  return Http.get("/admin/vehicle/report_data", { vehicleId });
}

export function bindEtc(vehicleId) {
  return Http.get(`/admin/vehicle/etc/bind`, { vehicleId });
}
