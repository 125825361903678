<template>
  <div class="editor">
    <div class="query-container">
      <div>
        <span>查询时段：</span>
        <el-date-picker
                v-model="params.startDate"
                type="datetime"
                format="yyyy-MM-dd"
                placeholder="起始时间"
        ></el-date-picker>
        <el-date-picker
                v-model="params.endDate"
                type="datetime"
                format="yyyy-MM-dd"
                placeholder="截止时间"
                style="margin-left: 8px;"
        ></el-date-picker>

        <el-select v-model="params.flCode" style="margin-left: 8px;" placeholder="模板分类" clearable>
          <el-option
                  v-for="item in flCodeOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="params.emergency" style="margin-left: 8px;" placeholder="紧急程度" clearable>
          <el-option
                  v-for="item in emergencyObjs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="params.needConfirm" style="margin-left: 8px;" placeholder="是否需要确认" clearable>
          <el-option
                  v-for="item in needConfirmObjs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 8px;">
        <div style="width: 80px; display: inline-block;"></div>
        <el-select v-model="params.receiveTeam" placeholder="所有车队" clearable>
          <el-option
                  v-for="item in teamOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>

        <el-button @click="query" type="primary" style="margin-left: 8px;">查询</el-button>
      </div>
    </div>

    <div class="state-container">
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.totalNum}}次</div>
          <div>总通知</div>
        </el-card>
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.systemNum}}次</div>
          <div>系统</div>
        </el-card>
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.manualNum}}次</div>
          <div>人工</div>
        </el-card>
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.commonNum}}次</div>
          <div>普通</div>
        </el-card>
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.imptNum}}次</div>
          <div>重要</div>
        </el-card>
        <el-card :body-style="{ padding: '0px' }">
          <div style="margin-top:32px;">{{totalInfo.emergencyNum}}次</div>
          <div>紧急</div>
        </el-card>
    </div>

    <div class="chart" style="margin-top: 10px; margin-bottom: 10px;">
      <el-radio-group v-model="params.dayInterval">
        <el-radio-button label="7天"></el-radio-button>
        <el-radio-button label="30天"></el-radio-button>
        <el-radio-button label="60天"></el-radio-button>
        <el-radio-button label="1年"></el-radio-button>
        <el-radio-button label="2年"></el-radio-button>
      </el-radio-group>
      <chart ref="chart" :option="chartOpt"/>
    </div>

    <div class="chart">
      <div class="item">
        <chart-view
                ref="chart1"
                valueField="num"
                nameField="title"
                title="人工系统占比"
                initChartType="pie"
        />
      </div>
      <div class="item">
        <chart-view
                ref="chart2"
                valueField="num"
                nameField="title"
                title="重要性占比"
                initChartType="pie"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {DateUtil} from '@/util/index';
import ChartView from '@/pages/Data/ChartView';
export default {
  name: "NoticeState",
  components: {ChartView},
  data () {
    return {
      teamOpts: [],
      noticeTypeEnums: [],
      emergencyObjs: [],
      flCodeOpts: [],
      needConfirmObjs: [{value: "0", label: "否"}, {value: "1", label: "是"}],
      params: { "dayInterval": "7天" },
      totalInfo: {},
      chartOpt: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
      }
    };
  },
  mounted () {
    this.initPage();
    this.query();
  },
  methods: {
    initPage () {
      this.noticeTypeEnums = window.noticeTypes;
      this.emergencyObjs = window.emergencyObjs;
      this.params.startDate = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
      this.params.endDate = new Date();
      this.$http.post("/admin/team/list", {}).then(res => {
        let teams = res.data || [];
        let teamOpts = [];
        teams.forEach(team => {
          teamOpts.push({value: team.id, label: team.name});
        });
        this.teamOpts = teamOpts;
      });
    },
    query () {
      let params = {};
      Object.assign(params, this.params);
      if (params.startDate) {
        params.startDate = DateUtil.format(params.startDate, "yyyy-MM-dd");
      } else {
        this.$message("请输入起始日期");
        return;
      }
      if (params.endDate) {
        params.endDate = DateUtil.format(params.endDate, "yyyy-MM-dd");
      } else {
        this.$message("请输入截止日期");
        return;
      }
      this.$http.post("/admin/notice/querystat", params).then(res => {
        this.totalInfo = res.data;
        let data1 = [{title: "人工", num: this.totalInfo.manualNum}, {title: "系统", num: this.totalInfo.systemNum}];
        let data2 = [{title: "普通", num: this.totalInfo.commonNum}, {title: "重要", num: this.totalInfo.imptNum}, {title: "紧急", num: this.totalInfo.emergencyNum}];
        this.$refs.chart1.setData(data1);
        this.$refs.chart2.setData(data2);
        this.renderNoticeChart();
      });
    },

    renderNoticeChart () {
      let details = this.totalInfo.details || [];
      let dataset = this.getNoticeDataset(details);
      let option = {
        title: {
          text: "信息紧急程度数量对比",
        },
        legend: {
          data: dataset.dimensions
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {
          show: true
        },
        series: [{
          name: dataset.dimensions[0],
          type: "line",
          data: dataset.source[0]
        }, {
          name: dataset.dimensions[1],
          type: "line",
          data: dataset.source[1]
        }, {
          name: dataset.dimensions[2],
          type: "line",
          data: dataset.source[2]
        }],
        ...this.chartOpt
      };
      this.$refs.chart.setOption(option, true);
    },
    getNoticeDataset (details) {
      var axis = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];

      for (var i = 0; i < details.length; i ++) {
        let detail = details[i];
        axis.push(detail.sendTime);
        data1.push(detail.commonNum);
        data2.push(detail.imptNum);
        data3.push(detail.emergencyNum);
      }

      let dimensions = ["普通", "重要", "紧急"];
      return {
        axis: axis,
        dimensions: dimensions,
        source: [data1, data2, data3],
      };
    }
  }
};
</script>

<style lang="less" scoped>
.state-container {
  margin-left: 8px;
  & > div {
    display: inline-block;
    width: 120px;
    height: 120px;
    margin-right: 10px;
    text-align: center;
  }
}

.chart {
  margin-left: 8px;
  border: 1px solid #eee;
  padding: 10px;
}

.item {
  display: inline-block;
  width: 50%;
  height: 330px;
}
</style>