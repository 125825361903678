import {Http} from '@/util';


// 查询用户详情
export function getPassengerInfo (param) {
  return Http.get('/admin/passenger/info', param);
}

// 查询用户列表
export function getPassengerList (param) {
  return Http.post('/admin/passenger/list', param);
}

// 修改用户信息
export function updatePassenger (param) {
  return Http.post('/admin/passenger/update', param);
}

// 重置了用户的openid
export function resetOpenid (passengerId, code) {
  return Http.get('/admin/passenger/reset/openid', {passengerId, code});
}

// 发送重置验证码
export function sendResetCode (passengerId) {
  return Http.get('/admin/passenger/send/code', {passengerId});
}


// 踢用户下线
export function kickPassengerOut (passengerId) {
  return Http.get('/admin/passenger/kick_out', {passengerId});
}


// 获取用户投诉信息
export function getComplaintList (param) {
  return Http.post('/admin/passenger/complaint/list', param);
}


// 获取用户报警信息
export function getAlarmList (param) {
  return Http.post('/admin/passenger/alarm/list', param);

}

// 新建投诉
export function saveOrUpdateComplaint (param) {
  return Http.post('/admin/order/complaint', param);
}


// 处理报警
export function dealAlarm (param) {
  return Http.post('/admin/passenger/deal/alarm', param);
}

// 拉黑司机
export function saveBlackDriver (param) {
  return Http.post('/admin/passenger/save/black_driver', param);
}


// 取消拉黑
export function cancelBlackDriver (param) {
  return Http.post('/admin/passenger/cancel/black_driver', param);
}


// 查询拉黑司机
export function getBlackDriver (passengerId) {
  return Http.get('/admin/passenger/get/black_driver', {passengerId});
}

// 加入/取消 黑名单
export function changeSysWarn (param) {
  return Http.post('/admin/passenger/change/black_list', param);
}


// 记录话单
export function saveCallinRecord (tel) {
  return Http.get('/admin/passenger/save/callin_record', {tel});
}

// 设置邀请风险用户
export function setInviteRisk (passengerId, isRisk) {
  return Http.get('/admin/passenger/set/invite/risk', {passengerId, isRisk});
}

// 使用首次免责
export function useExemptionChance (passengerId) {
  return Http.get('/admin/passenger/use/exemption', {passengerId});
}

