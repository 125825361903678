<template>
  <span v-if="!!vip && vip > 0" class="red">VIP {{vip}}</span>
</template>

<script>
export default {
  components: {},
  props: {
    vip: Number
  },

};
</script>
<style lang="less" scoped>
</style>