import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

export default class StompClient {
  constructor(url, headers) {
    this.url = url;
    this.active = false;
    this.client = new Client({
      webSocketFactory: () => new SockJS(this.url),
      connectHeaders: headers,
      reconnectDelay: 5000, // 自动重连的间隔时间
    });

    // 添加一个数组来保存订阅
    this.subscriptions = [];
  }

  // 连接到STOMP服务，并在连接成功后重新建立订阅
  connect(connectCallback, errorCallback) {
    this.client.configure({});
    // When the client connects successfully
    this.client.onConnect = (frame) => {
      console.log("Connected: " + frame);
      this.active = true;
      connectCallback(frame);

      // 重新订阅之前的订阅
      this.subscriptions.forEach((sub) => {
        this.subscribe(sub.destination, sub.callback, true);
      });
    };

    this.client.onStompError = (error) => {
      console.error("STOMP Error: " + error);
      this.active = false;
      // You can implement additional error handling logic here
    };
    this.client.onWebSocketError = (error) => {
      console.error("WebSocket Error: " + error);
      this.active = false;
      if (errorCallback) {
        errorCallback(error);
      }
    };
    this.client.activate();
  }

  // 断开STOMP服务连接
  disconnect(disconnectCallback) {
    if (this.client.active) {
      this.client.deactivate().then(() => {
        console.log("Disconnected");
        if (disconnectCallback) {
          disconnectCallback();
        }
      });
    }
  }

  // 订阅指定的STOMP目的地，并在内部数组中保存订阅信息
  subscribe(destination, callback, skipPush = false) {
    const subscription = this.client.subscribe(destination, (message) => {
      callback(message);
    });

    // 保存订阅详情，以便在断开连接后可以重新订阅
    if (!skipPush) {
      this.subscriptions.push({ destination, callback, subscription });
    }

    return subscription;
  }

  // 取消订阅并从内部数组中移除订阅信息
  unsubscribe(subscription) {
    subscription.unsubscribe();

    // 移除内部数组中的订阅记录
    this.subscriptions = this.subscriptions.filter((sub) => sub.subscription !== subscription);
  }

  // 发送消息到指定的目的地
  send(destination, headers, body) {
    this.client.publish({ destination, headers, body });
  }

  // ... Remaining parts of the StompClient class ...
}
