var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root-box"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"footer"},[_vm._m(1),_c('div',[_c('span',{staticClass:"num blue"},[_vm._v(_vm._s(_vm.data.orderCount))]),_vm._v(" 单 ")])])]),_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"footer"},[_vm._m(3),_c('div',[_c('span',{staticClass:"num orange"},[_vm._v(_vm._s(_vm.data.userCount))]),_vm._v(" 人 ")])])]),_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"footer"},[_vm._m(5),_c('div',[_c('span',{staticClass:"num green"},[_vm._v(_vm._s(_vm.data.price))]),_vm._v(" 元 ")])])])]),_c('div',{staticClass:"charts"},[_c('div',[_c('div',{staticClass:"header light-green"},[_vm._v("每日订单量")]),_c('div',{ref:"chart1",staticClass:"chart"})]),_c('div',[_c('div',{staticClass:"header light-red"},[_vm._v("每日订单金额")]),_c('div',{ref:"chart2",staticClass:"chart"})]),_c('div',[_c('div',{staticClass:"header light-orange"},[_vm._v("每日新注册用户量")]),_c('div',{ref:"chart3",staticClass:"chart"})]),_c('div',[_c('div',{staticClass:"header light-blue"},[_vm._v("每日活跃度")]),_c('div',{ref:"chart4",staticClass:"chart"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"type"},[_vm._v("今日订单量")]),_c('span',{staticClass:"tip"},[_vm._v("（实时统计数量）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blue"},[_c('i',{staticClass:"el-icon-document"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"type"},[_vm._v("今日新注册用户量")]),_c('span',{staticClass:"tip"},[_vm._v("（实时统计数量）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orange"},[_c('i',{staticClass:"el-icon-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"type"},[_vm._v("今日总金额")]),_c('span',{staticClass:"tip"},[_vm._v("（实时统计数量）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"green"},[_c('i',{staticClass:"el-icon-money"})])
}]

export { render, staticRenderFns }