<template>
  <div>
    <!-- <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item>
            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div> -->

    <div class="table">
      <el-table
        :data="list"
        stripe
        max-height="300"
        size="small"
        highlight-current-row
        @row-click="getParticipateList"
      >
        <el-table-column prop="id" label="任务编号"> </el-table-column>

        <el-table-column prop="name" label="模板标题"> </el-table-column>

        <el-table-column prop="mainTask1" label="主要标准1">
          <template slot-scope="scope">
            {{ scope.row.template.mainTask1.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="mainTask2" label="主要标准2">
          <template slot-scope="scope" v-if="scope.row.template.mainTask2">
            {{ scope.row.template.mainTask2.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="award" label="奖金">
          <template slot-scope="scope">
            {{ scope.row.template.award }}
          </template>
        </el-table-column>
        <el-table-column prop="awardNum" label="奖励人数">
          <template slot-scope="scope">
            {{ scope.row.template.awardNum || "无限制" }}
          </template>
        </el-table-column>

        <el-table-column prop="taskNum" label="参与人数">
          <template slot-scope="scope">
            {{ scope.row.participateNum }} / {{ scope.row.taskNum }}
          </template>
        </el-table-column>

        <el-table-column prop="name" label="参与率">
          <template slot-scope="scope">
            {{ ((scope.row.participateNum / scope.row.taskNum) * 100) | numFormat }}%
          </template>
        </el-table-column>

        <el-table-column prop="name" label="获奖完成率">
          <template slot-scope="scope">
            {{ ((scope.row.reachNum / scope.row.taskNum) * 100) | numFormat }}%
          </template>
        </el-table-column>

        <el-table-column prop="name" label="任务时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.startDate }}
            </div>
            <div>
              {{ scope.row.endDate }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="publishTime" label="发布时间"> </el-table-column>

        <el-table-column prop="name" label="任务状态">
          <template slot-scope="scope">
            <div>
              {{ getStatus(scope.row) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="id" fixed="right" width="150" label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="text" @click="showDetail(scope.row)">详情</el-button>
            <el-button type="text" @click="stopTask(scope.row)">终止</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div>
      <el-table
        :data="participateList"
        stripe
        max-height="450"
        size="small"
        highlight-current-row
        @row-click="getParticipateDetailList"
      >
        <el-table-column prop="driverCode" label="司机编号"> </el-table-column>
        <el-table-column prop="createTime" label="参与时间"> </el-table-column>
        <el-table-column prop="rankNo" label="当前排名"> </el-table-column>
        <el-table-column prop="reached" label="是否完成">
          <template slot-scope="scope">
            {{ scope.row.reached ? "完成" : "未完成" }}
          </template>
        </el-table-column>
        <el-table-column prop="excharged" label="是否兑换奖励">
          <template slot-scope="scope">
            {{ scope.row.excharged ? "已兑换" : "未兑换" }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="任务完成详情"
      :visible.sync="detailVisible"
      direction="ltr"
      custom-class="drawer-modal"
    >
      <el-table :data="participateDetailList" stripe size="small">
        <el-table-column prop="createTime" label="任务名称">
          <template slot-scope="scope">
            {{ scope.row.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="mainTask" label="是否主任务">
          <template slot-scope="scope">
            {{ scope.row.mainTask ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column prop="assessValue" label="任务详情">
          <template slot-scope="scope">
            {{ scope.row.assessReach | enumTransfer(assessOpts) }} {{ scope.row.assessValue }}
            <span v-if="scope.row.assessSort">{{
              scope.row.assessSort | enumTransfer(sortOpts)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="taskValue" label="进度">
          <template slot-scope="scope">
            <span v-if="scope.row.process">{{ scope.row.process | percentFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="taskValue" label="当前完成情况">
          <template slot-scope="scope">
            <span>{{ scope.row.showValue }}</span>
            <span>{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reached" label="是否完成">
          <template slot-scope="scope">
            {{ scope.row.reached === 1 ? "完成" : scope.row.reached === 0 ? "未完成" : "失败" }}
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <el-dialog
      :visible.sync="visible"
      width="1200px"
      max-height="600"
      title="任务详情"
      :close-on-click-modal="false"
    >
      <el-form ref="form" size="small" inline>
        <RewardTemplateDetail :data="form.template" />

        <div class="footer">
          <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RewardTemplateDetail from "../RewardTemplateDetail";
import {
  listAwardTasks,
  sendAwardTaskMsg,
  stopAwardTasks,
  findParticipateInfoByTaskId,
  findDriverParticipateDetails,
} from "@/api";

export default {
  components: {
    RewardTemplateDetail,
  },
  props: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      taskOpts: (state) => state.reward.initData.tasks || [],
      sortOpts: (state) => state.reward.initData.sorts || [],
      assessOpts: (state) => state.reward.initData.assesses || [],
    }),
  },
  data() {
    return {
      visible: false,
      detailVisible: false,
      form: {},
      list: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
      participateList: [],
      participateDetailList: [],
    };
  },
  methods: {
    getStatus(task) {
      if (task.status == 0) {
        return "待开始";
      } else if (task.status == 1) {
        return "进行中";
      } else if (task.status == 2) {
        return "已结束";
      } else if (task.status == 3) {
        return "已终止";
      }
      return "";
    },
    query(pageNo = 1) {
      const param = { state: 1, pageNo, pageSize: this.pageSize };
      listAwardTasks(param).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    getParticipateList(item) {
      findParticipateInfoByTaskId(item.id).then((res) => {
        this.participateList = res.data || [];
      });
    },

    getParticipateDetailList(item) {
      this.detailVisible = true;
      findDriverParticipateDetails(item.driverId, item.taskId).then((res) => {
        this.participateDetailList = res.data || [];
      });
    },

    showDetail(item) {
      this.form = item;
      this.visible = true;
    },

    immeSend(item) {
      this.$confirm("确认立即发送吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sendAwardTaskMsg(item.id).then(() => {
          this.query();
          this.$message.success("操作成功");
        });
      });
    },

    stopTask(item) {
      this.$confirm("确认要停止奖励吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stopAwardTasks(item.id).then(() => {
          this.query();
        });
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .drawer-modal {
  width: 1200px !important;
}
</style>
