<template>
  <div>
    <!-- <div class="query-container space-between">
      <div>
        <el-form size="small" :inline="true">
          <el-form-item>

            <el-button
              style="width: 100px; margin-right: 20px"
              type="primary"
              @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div> -->

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column prop="id" label="任务编号"> </el-table-column>

        <el-table-column prop="name" label="模板标题"> </el-table-column>

        <el-table-column prop="mainTask1" label="主要标准1">
          <template slot-scope="scope">
            {{ scope.row.template.mainTask1.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="mainTask2" label="主要标准2">
          <template slot-scope="scope" v-if="scope.row.template.mainTask2">
            {{ scope.row.template.mainTask2.task | enumTransfer(taskOpts) }}
          </template>
        </el-table-column>
        <el-table-column prop="award" label="奖金">
          <template slot-scope="scope">
            {{ scope.row.template.award }}
          </template>
        </el-table-column>
        <el-table-column prop="awardNum" label="奖励人数">
          <template slot-scope="scope">
            {{ scope.row.template.awardNum  }}
          </template>
        </el-table-column>

        <el-table-column prop="participateNum" label="适用司机">
        </el-table-column>
        <el-table-column prop="taskNum" label="适用司机人数"> </el-table-column>

        <el-table-column prop="name" label="获奖完成率">
          <template slot-scope="scope">
            {{ scope.row.reachNum / scope.row.taskNum }}
          </template>
        </el-table-column>

        <el-table-column prop="name" label="任务时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.startDate }}
            </div>
            <div>
              {{ scope.row.endDate }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="任务状态">
          <div>
            待发送
          </div>
        </el-table-column>

        <el-table-column
          prop="id"
          fixed="right"
          width="200"
          label="操作"
          align="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="immeSend(scope.row)"
              >立即发送</el-button
            >
            <el-button type="text" size="small" @click="modifyTask(scope.row)"
            >修改</el-button
            >
            <el-button type="text" size="small" @click="delTask(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>



    <el-dialog
      :visible.sync="visible"
      width="1200px"
      max-height="600"
      title="发送设置"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" size="small" inline>
        <RewardTemplateDetail :data="tempForm" />

        <el-form-item label="司机选择：">
          <driver-selector v-model="form.driverIds" />
        </el-form-item>

        <el-row>
          <el-form-item label="任务时长：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.startDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker
          ></el-form-item>

          <el-form-item>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.endDate"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="发送时间：" style="margin-left: 60px">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.publishTime"
              type="date"
              placeholder="发送时间："
              @change="publishTimChange"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-radio v-model="form.publishRightnow" :label="true" @click.native.stop="changeRightNow"
              >立即发送</el-radio
            >
          </el-form-item>
        </el-row>

        <div class="footer">
          <el-button type="warning" :size="size" @click="sendTask"
            >确 定</el-button
          >

          <el-button type="info" :size="size" @click="visible = false"
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import RewardTemplateDetail from '../RewardTemplateDetail';
import {findAwardTaskTemplate, saveAwardTask, listAwardTasks, sendAwardTaskMsg, deleteAwardTask} from '@/api';

export default {
  components: {
    RewardTemplateDetail
  },
  props: {},
  created () {
  },
  mounted () {
  },
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      taskOpts: state => state.reward.initData.tasks || [],
      sortOpts: state => state.reward.initData.sorts || [],
      assessOpts: state => state.reward.initData.assesses || [],
    }),
  },
  data () {
    return {
      visible: false,
      form: {},
      list: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
      tempForm: null,
    };
  },
  methods: {
    openAdd () {
      this.form = {};
      this.visible = true;
    },

    handleOk () {
      this.visible = true;
    },

    query (pageNo = 1) {
      const param = {state: 0, pageNo, pageSize: this.pageSize};
      listAwardTasks(param).then(res=>{
        const {records = [], current = 1, total = 0} = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    sendTask () {
      this.$set(this.form, 'templateId', this.tempForm.id);
      saveAwardTask(this.form).then(()=>{
        this.visible = false;
        this.query();
        this.$message.success('操作成功');
      });
    },

    immeSend (item) {
      this.$confirm('确认立即发送吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendAwardTaskMsg(item.id).then(()=>{
          this.query();
          this.$message.success('操作成功');
        });
      });
    },

    modifyTask (task) {
      const id = task.templateId;
      findAwardTaskTemplate(id).then(res=>{
        this.tempForm = res.data || {};
        this.visible = true;
        this.form = task;
      });
    },

    delTask (item) {
      this.$confirm('确认要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAwardTask(item.id).then(()=>{
          this.query();
        });
      });
    },
    changeRightNow () {
      this.form.publishTime = null;
    },
    publishTimChange () {
      if (this.form.publishTime) {
        this.form.publishRightnow = false;
      }
    },
    handleCurrentChange (page) {
      this.query(page);
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.form-item {
  border: 1px solid #ddd;
  min-width: 200px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  outline: 0;
  padding: 0 15px;
  width: 100%;
  height: 32px;
  line-height: 30px;
  background-color: #eee;
}
</style>