<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="概况" name="ref1">
        <overview />
      </el-tab-pane>
      <el-tab-pane label="明细" name="ref2">
        <driver-team />
      </el-tab-pane>
      <el-tab-pane label="新结算" name="ref4">
        <settlement-summary />
      </el-tab-pane>
      <el-tab-pane label="新流水" name="ref5">
        <settlement-detail />
      </el-tab-pane>
      <el-tab-pane label="渠道" name="ref3">
        <type-analysis />
      </el-tab-pane>
      <el-tab-pane label="服务排名" name="ref6">
        <eva-rank />
      </el-tab-pane>
      <el-tab-pane label="每日流水" name="ref7">
        <bill-detail />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import Overview from './Overview';
import DriverTeam from './DriverTeam';
import TypeAnalysis from './TypeAnalysis';
import EvaRank from './EvaRank';
import BillDetail from './BillDetail';
import SettlementSummary from './SettlementSummary';
import SettlementDetail from './SettlementDetail';


export default {
  components: {
    Overview,
    DriverTeam,
    TypeAnalysis,
    EvaRank,
    BillDetail,
    SettlementSummary,
    SettlementDetail
  },
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {
    query () {}
  },
};
</script>
<style lang="less" scoped>
</style>