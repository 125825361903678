import {Http} from '@/util';

// 查询车票
export function getTicketList (param) {
  return Http.post('/admin/ticket/list', param);
}


// 初始化自动排票
export function initAutoTest (pathId) {
  return Http.post(`/admin/ticket/auto/init?pathId=${pathId}`);
}

// 手动调票
export function manualAdjustTicket (ticket, tip = true) {
  return Http.post(`/admin/ticket/manual_adjust?tip=${tip}`, ticket);
}


export function isAutoTicket (ticket) {
  ticket._hideLoading = true;
  return Http.post(`/admin/ticket/auto/ticket`, ticket);
}

// 批量更新车票备注
export function batchUpdate (param) {
  return Http.post(`/admin/ticket/update/batch`, param);
}

// 批量更新车票价格
export function batchUpdatePrice (param, updateDepartStandard) {
  return Http.post(`/admin/ticket/update/price?updateDepartStandard=${updateDepartStandard}`, param);
}


// 获取可用备注
export function getUsableRemark () {
  return Http.get(`/admin/ticket/usable/remark`, {_useCache: true});
}

// 增加停班原因
export function addStopReason (param) {
  return Http.post(`/admin/ticket/add/stop_reason`, param);
}

// 删除停班原因
export function removeStopReason (param) {
  return Http.post(`/admin/ticket/remove/stop_reason`, param);
}