<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.startDate"
            type="date"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.endDate"
            type="date"
            placeholder="选择结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="路线" prop="pathIds">
          <el-select
            v-model="param.pathIds"
            style="width: 200px"
            placeholder="请选择"
            clearable
            multiple
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户端" style="margin-left: 20px">
          <el-checkbox-group v-model="param.orderSources">
            <el-checkbox
              v-for="item in orderSourceOpts"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="query()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600" size="small">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>
        <el-table-column label="电话" width="120">
          <template slot-scope="scope">
            {{ scope.row.telephone }}
          </template>
        </el-table-column>

        <el-table-column label="VIP" width="100">
          <template slot-scope="scope">
            {{ scope.row.vip }}
          </template>
        </el-table-column>

        <el-table-column prop="orderSource" width="80" label="渠道">
          <template slot-scope="scope">
            <span v-order-source="scope.row.orderSource"></span>
          </template>
        </el-table-column>

        <el-table-column label="日期" prop="date"> </el-table-column>
        <el-table-column label="时间" prop="showTime"> </el-table-column>

        <el-table-column prop="number" width="60" label="订票">
          <template slot-scope="scope">
            <div>
              {{ scope.row.number
              }}{{ scope.row.orderType === 2 ? "货" : "人" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" width="100" label="总价">
          <template slot-scope="scope">
            <span v-operate-order:bill="scope.row.id"
              >{{ scope.row.price | moneyFormat }}元</span
            >
          </template>
        </el-table-column>

        <el-table-column label="下单日期" prop="createTime"> </el-table-column>

        <el-table-column
          prop="id"
          label="操作"
          width="150"
          align="right"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="go2User(scope.row)"
              >用户</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" size="small" @click="go2OrderList(scope.row)"
              >订单</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              size="small"
              @click="getUserOptDetail(scope.row)"
              >操作</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 80px">
      <chart ref="chart1" :option="chartOpt" :height="400" />
    </div>

    <div>
      <chart-view
        ref="chart2"
        valueField="num"
        nameField="name"
        title="查看订单人数数量比"
        initChartType="pie"
      />
    </div>

    <el-drawer
      title="操作明细"
      :visible.sync="detailVisible"
      direction="ltr"
      :with-header="false"
      :append-to-body="true"
      :size="1000"
    >
      <div class="box">
        <OptDetail ref="detail" />
      </div>
    </el-drawer>
  </div>
</template>

<script>

import { userActionDetail, viewMyOrderAnalysis} from '@/api';
import { DateUtil } from '@/util';
import OptDetail from '../OptDetail.vue';
import {mapState} from 'vuex';
import ChartView from '../../ChartView';

export default {
  filters: {
    yesNo (val) {
      return val === true ? '是' : '否';
    }
  },
  components: {
    OptDetail,
    ChartView
  },
  props: {},
  created () {

  },
  mounted () {
    this.query();
  },
  watch: {},
  computed: {
    ...mapState({
      pathOpts: state =>state.pathList,
    })
  },
  data () {
    return {
      param: {
        startDate: DateUtil.getDayStr(-2),
        endDate: DateUtil.getDayStr(0),
        orderSources: [3, 4],
        pathIds: []
      },
      chartOpt: {
        title: {
          text: "查看订单趋势",
        },
        legend: {
          show: true,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
      },
      orderSourceOpts: [{label: '小程序', value: 3}, {label: 'APP', value: 4}],
      list: [],
      detailVisible: false,
    };
  },
  methods: {

    query () {
      viewMyOrderAnalysis(this.param).then(res=>{
        const {viewedList, daySummary, totalViewed, totalUnViewed} = res.data || {};
        this.list = viewedList;
        this.$refs.chart2.setData([{name: '未查看过', num: totalUnViewed}, {name: '查看过', num: totalViewed}]);

        const axis = [];
        const viewedSeries = {
          name: '查看过订单',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          stack: 'order',
          barWidth: 40,
          data: [], };
        const unViewedSeries = {
          name: '未查看过订单',
          type: 'bar',
          stack: 'order',
          barWidth: 40,
          emphasis: {
            focus: 'series'
          },
          data: [],
        };

        daySummary.forEach(item=>{
          axis.push(item.date);
          viewedSeries.data.push(item.viewed);
          unViewedSeries.data.push(item.unViewed);
        });

        const opt = {
          ...this.chartOpt,
          xAxis: {type: 'category', show: true, data: axis},
          yAxis: {
            show: true
          },
          series: [unViewedSeries, viewedSeries],

        };
        this.$refs.chart1.setOption(opt, true);
      });
    },

    go2OrderList (item) {
      const routeUrl = this.$router.resolve({
        path: `/order/list`,
        query: {tel: item.telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    go2User (item) {
      const routeUrl = this.$router.resolve({
        path: `/passenger/list`,
        query: {tel: item.telephone}
      });
      window.open(routeUrl.href, '_blank');
    },

    getUserOptDetail (item) {
      const param = {
        startTime: item.createTime,
        endTime: `${item.date} 23:59:59`,
        mobile: item.telephone
      };
      this.detailVisible = true;
      userActionDetail(param).then(res=>{
        this.$refs.detail.show(res.data, item);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.pie-box {
  display: flex;
  justify-content: space-around;
  border: 1px solid #ddd;
  margin: 40px 0;
}
</style>