<template>
  <div>
    <el-upload class="upload-box" :drag="uploadType === 'drag'" :http-request="uploadHandle" :limit="10" action=""
      :on-remove="clear" :show-file-list="false" :multiple="multiple" ref="uploader">
      <div v-loading="loading" v-if="uploadType === 'drag'">
        <slot>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </slot>
      </div>
      <el-button v-else size="small" type="primary" plain>{{ btnText }}</el-button>
    </el-upload>
    <div :class="isAllImg ? 'img-box' : 'file-list'">

      <div v-for="item in fileList" :key="item" :class="isAllImg ? 'img-item' : 'file-item'">
        <div v-if="isAllImg">
          <el-image :src="item" :preview-src-list="fileList">
          </el-image>
          <i class="el-icon-circle-close" @click="clear(item)"></i>
        </div>
        <template v-else>
          <span>{{ item | fileName }}</span> <i class="el-icon-close" @click="clear(item)"></i>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import OSS from "ali-oss";
import { getOssUploadRole } from "@/api";
import { Toolkit, FileUtil } from "@/util";

export default {
  components: {},
  filters: {
    fileName: function (value) {
      return typeof value === 'string' ? FileUtil.getFileName(value) : '';
    },
  },
  props: {
    onUploadSuccess: Function,
    value: [String, Array],
    btnText: {
      type: String,
      default: "点击上传",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    uploadType: {
      type: String,
      default: "drag",
    },
    bucket: {
      type: String,
      default: "yxmgt-private",
    },
    filePath: {
      type: String,
      default: "/driver/contract",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (this.multiple) {
          this.fileList = val || [];
        } else {
          this.fileList = val ? [val] : [];
        }
      },
      immediate: true,
    },
  },
  computed: {
    isAllImg() {
      return this.fileList.every((item) => {
        return FileUtil.isImage(item);
      });
    }
  },
  data() {
    return {
      stsCredentials: "",
      loading: false,
      fileList: [],
    };
  },
  methods: {
    clear(item) {
      if (item) {
        this.fileList.splice(this.fileList.indexOf(item), 1);
      }

      if (this.multiple) {
        this.$emit("input", this.fileList);
      } else {
        this.$emit("input", null);
      }

    },

    async uploadHandle(option) {
      const client = await this.getOssClient();
      const fileType = option.file.name.split(".")[1];
      const fullName = `${this.filePath}/${Toolkit.uuid()}.${fileType}`;
      this.loading = true;
      client
        .put(fullName, option.file)
        .then((res) => {
          this.loading = false;
          if (this.multiple) {
            this.fileList.push(res.url);
            this.$emit("input", this.fileList);
            if (this.onUploadSuccess) {
              this.onUploadSuccess(this.fileList);
            }
          } else {
            this.fileList = [res.url];
            this.$emit("input", res.url);
            if (this.onUploadSuccess) {
              this.onUploadSuccess(res.url);
            }
          }

        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },
    async getOssClient() {
      if (this.stsCredentials) {
        const {
          accessKeyId,
          accessKeySecret,
          expiration,
          securityToken,
        } = this.stsCredentials || {};
        const expireDate = new Date(expiration);
        // 如果过期时间大于3分钟，则无需替换凭证
        if (
          expireDate.getTime() - new Date().getTime() >
          3 * 60 * 1000
        ) {
          return new OSS({
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
            bucket: this.bucket,
          });
        }
      }

      const res = await getOssUploadRole();
      this.stsCredentials = res.data.credentials;
      const { accessKeyId, accessKeySecret, securityToken } =
        this.stsCredentials;
      return new OSS({
        accessKeyId,
        accessKeySecret,
        stsToken: securityToken,
        bucket: this.bucket,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.upload-box {
  display: block;
}


.file-list {
  margin-top: 10px;

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    background-color: #f5f7fa;

    .el-icon-close {
      cursor: pointer;
    }
  }


}

.img-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px 0 0;
  flex-wrap: wrap;

  .img-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    position: relative;

    .el-image {
      margin-bottom: 10px;
      width: 100px;
      height: 80px;
      padding: 10px 10px 0 0;
    }

    .el-icon-circle-close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
    }
  }


}
</style>