<template>
  <div>
    <div class="el-image-box" @click.stop v-if="src">
      <el-image
        ref="image"
        fit="contain"
        :src="src"
        :preview-src-list="[src]"
        :z-index="3000"
      ></el-image>

      <div class="opera" v-if="!disabled">
        <i class="el-icon-zoom-in" @click="showImg"></i>
        <i class="el-icon-circle-close" @click="clear"></i>
      </div>
    </div>

    <el-upload
      v-else
      class="upload-box"
      drag
      :http-request="uploadHandle"
      :limit="1"
      action=""
      :show-file-list="false"
      ref="uploader"
    >
      <div v-loading="loading">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import OSS from "ali-oss";
import { getOssUploadRole } from "@/api";
import { Toolkit } from "@/util";

export default {
  components: {},
  props: {
    onUploadSuccess: Function,
    value: String,
    bucket: {
      type: String,
      default: "yxmgt-private",
    },
    filePath: {
      type: String,
      default: "/driver/contract",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler (val) {
        this.src = val;

        if (!val) {
          this.clear();
        }
      },
      immediate: true,
    },
  },
  computed: {},
  data () {
    return {
      src: "",
      stsCredentials: "",
      loading: false,
    };
  },
  methods: {
    clear () {
      this.$refs.uploader && this.$refs.uploader.clearFiles();
      this.$emit("input", null);
    },
    showImg () {
      this.$refs.image.clickHandler();
    },
    async uploadHandle (option) {
      const client = await this.getOssClient();
      const fileType = option.file.name.split(".")[1];
      const fullName = `${this.filePath}/${Toolkit.uuid()}.${fileType}`;
      this.loading = true;
      client
        .put(fullName, option.file)
        .then((res) => {
          this.loading = false;
          this.src = res.url;
          this.$emit("input", this.src);
          if (this.onUploadSuccess) {
            this.onUploadSuccess(this.src);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },
    async getOssClient () {
      if (this.stsCredentials) {
        const { accessKeyId, accessKeySecret, expiration, securityToken } =
          this.stsCredentials || {};
        const expireDate = new Date(expiration);
        // 如果过期时间大于3分钟，则无需替换凭证
        if (expireDate.getTime() - new Date().getTime() > 3 * 60 * 1000) {

          console.log('use old credentials', this.bucket);
          return new OSS({
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
            bucket: this.bucket,
          });
        }
      }

      const res = await getOssUploadRole();
      this.stsCredentials = res.data.credentials;
      const { accessKeyId, accessKeySecret, securityToken } = this.stsCredentials;
      console.log('use old credentials', this.bucket);
      return new OSS({
        accessKeyId,
        accessKeySecret,
        stsToken: securityToken,
        bucket: this.bucket,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-image-box {
  position: relative;
  width: 360px;
  height: 180px;
  border: 1px #ddd dashed;

  /deep/ .el-image {
    width: 100%;
    height: 100%;
  }
  .opera {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 45px;
      margin: 0 40px;
    }
  }
}
</style>
