<template>
<div class="root-box">
  <el-radio-group v-model="pageName" style="margin-bottom: 10px;">
    <el-radio-button label="总概"></el-radio-button>
    <el-radio-button label="明细"></el-radio-button>
  </el-radio-group>
  <award-sum v-if="pageName == '总概'"/>
  <award-detail v-if="pageName == '明细'" />
</div>
</template>

<script>
import awardSum from "./summary";
import awardDetail from "./detail";
export default {
  components: {
    awardSum,
    awardDetail
  },
  data () {
    return {
      pageName: "总概",
    };
  },
  methods: {
  }
};
</script>

<style scoped>

</style>