<template>
  <div class="root-box">
    <el-tabs value="first">
      <el-tab-pane label="下载任务" name="first">
        <el-row class="condition">
          <el-form size="small" :inline="true">
            <el-form-item label="司机编号：">
              <el-input v-model="param.driverNo" clearable />
            </el-form-item>

            <el-form-item label="通道：">
              <el-select
                v-model="param.channels"
                style="width: 300px"
                placeholder="请选择"
                multiple
                clearable
              >
                <el-option
                  v-for="item in chnOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="开始时间：">
              <el-date-picker
                placeholder="选择时间"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="param.startTime"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="结束时间：">
              <el-date-picker
                placeholder="选择时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                v-model="param.endTime"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
                >查询</el-button
              >

              <el-button
                style="width: 100px; margin-right: 20px"
                type="primary"
                @click="openTaskModel()"
                >创建任务</el-button
              >
            </el-form-item>
          </el-form>
        </el-row>

        <div class="table">
          <el-table :data="list" stripe max-height="680" @row-click="showDetail">
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>

            <el-table-column prop="driverNo" label="司机编号" width="120"></el-table-column>
            <el-table-column prop="vehicleNo" label="车牌号" width="120"></el-table-column>

            <el-table-column prop="channels" label="通道" width="220">
              <template slot-scope="scope">
                {{ scope.row.channels | channelFilter }}
              </template>
            </el-table-column>

            <el-table-column prop="time" label="时间" width="400">
              <template slot-scope="scope">
                {{ scope.row.startTime }} - {{ scope.row.endTime }}
              </template>
            </el-table-column>

            <el-table-column prop="size" label="是否已上报服务器">
              <template slot-scope="scope">
                {{ scope.row.submitReq ? "是" : "否" }}
              </template>
            </el-table-column>

            <el-table-column prop="fileNum" label="文件个数"> </el-table-column>

            <el-table-column prop="remark" label="备注"> </el-table-column>

            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteTask(scope.row)"
                  >删除任务</el-button
                >

                <el-button type="text" size="small" @click="batchDownload">批量下载</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="detail" v-if="files.length">
          <el-table :data="files" stripe max-height="680" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              show-overflow-tooltip
              width="50"
            ></el-table-column>

            <el-table-column prop="chn" label="通道" width="100">
              <template slot-scope="scope"> CH{{ scope.row.chn + 1 }} </template>
            </el-table-column>

            <el-table-column prop="time" label="时间" width="340">
              <template slot-scope="scope">
                {{ scope.row.fbtm | timeFilter2 }} - {{ scope.row.fetm | timeFilter2 }}
              </template>
            </el-table-column>

            <el-table-column prop="size" label="文件大小">
              <template slot-scope="scope">
                {{ scope.row.len | fileSizeFilter }}
              </template>
            </el-table-column>

            <el-table-column prop="size" label="下载状态">
              <template slot-scope="scope">
                {{ scope.row.stu | enumTransfer(dowloadStatus) }}
              </template>
            </el-table-column>

            <el-table-column prop="size" label="下载">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :disabled="scope.row.stu !== 4"
                  di
                  :href="scope.row.downUrl"
                  target="_blank"
                  >直接下载</el-link
                >
              </template>
            </el-table-column>

            <el-table-column prop="chn" label="下载失败原因">
              <template slot-scope="scope"> {{ scope.row.err ? scope.row.err : "成功" }} </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="实时查询" name="second">
        <Query />
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      size="small"
      title="创建任务"
      :close-on-click-modal="false"
    >
      <el-form ref="form" size="small" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="司机：" prop="driverId">
          <el-select
            style="width: 300px"
            :size="size"
            v-model="form.driverId"
            filterable
            remote
            clearable
            placeholder="请输入关键词"
            :remote-method="getDriver"
            :loading="loading"
            @change="queryRoute"
          >
            <el-option
              v-for="item in driverOptions"
              :key="item.id"
              :label="`${item.name} ${item.no} ${item.vehicleNo}`"
              :value="item.id"
            >
              <div style="display: flex; justify-content: space-between">
                <span style="flex: 1">{{ item.name }}</span>
                <span style="flex: 1">{{ item.no }}</span>
                <span style="flex: 1">{{ item.vehicleNo }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="通道：" prop="channels">
          <el-select
            v-model="form.channels"
            style="width: 300px"
            placeholder="请选择"
            multiple
            clearable
          >
            <el-option
              v-for="item in chnOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间：" prop="startTime">
          <el-date-picker
            style="width: 300px"
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.startTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间：" prop="endTime">
          <el-date-picker
            style="width: 300px"
            placeholder="选择时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            v-model="form.endTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="createTask">保存</el-button>
        <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  videoMonitorLogin,
  getDownloadTaskList,
  addBackendDownloadTask,
  listDownloadTask,
  getDriverInfo,
  delBackendDownloadTask,
} from "@/api";
import { DateUtil } from "@/util";
import { Loading } from "element-ui";
import Query from "./Query";

const zero = (num) => (num < 10 ? `0${num}` : num);
const sencondsToTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  return `${zero(h)}:${zero(m)}:${zero(s)}`;
};

const options = {
  lock: true,
  text: "正在处理，请稍后",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.7)",
};

export default {
  components: {
    Query,
  },

  filters: {
    timeFilter(data) {
      const { year, mon, day, beg, end } = data;
      // 将一天的秒数转化成时间格式
      const stime = sencondsToTime(beg);
      const etime = sencondsToTime(end);
      return `${year}-${mon}-${day} ${stime} - ${etime}`;
    },

    timeFilter2(utcTime) {
      const date = new Date(utcTime);
      return DateUtil.format(date, "yyyy-MM-dd hh:mm:ss");
    },

    fileSizeFilter(size) {
      if (size < 1024) {
        return size + "B";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + "KB";
      } else if (size < 1024 * 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + "MB";
      } else {
        return (size / 1024 / 1024 / 1024).toFixed(2) + "GB";
      }
    },

    channelFilter(channel) {
      if (channel && channel.length) {
        return channel
          .map((item) => {
            return `通道${item + 1}`;
          })
          .join(",");
      }
      return `全部通道`;
    },
  },

  props: {},

  watch: {},

  created() {},

  mounted() {
    this.query();
    this.login();
  },

  computed: {},

  data() {
    return {
      size: "small",
      param: {
        no: "",
        startTime: DateUtil.format(new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: DateUtil.format(new Date(), "yyyy-MM-dd 23:59:59"),
      },
      token: null,
      carStatus: null,
      online: false,
      list: [],
      form: {},
      rules: {
        driverId: [{ required: true, message: "请选择司机", trigger: "blur" }],
        channels: [{ required: true, message: "请选择通道", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
      },
      dowloadStatus: [
        { value: 1, label: "未下载" },
        { value: 2, label: "下载中" },
        { value: 3, label: "下载失败" },
        { value: 4, label: "已下载" },
      ],
      chnOptions: [
        { value: 0, label: "通道1" },
        { value: 1, label: "通道2" },
        { value: 2, label: "通道3" },
        { value: 3, label: "通道4" },
      ],
      visible: false,
      driverOptions: [],
      files: [],
    };
  },

  methods: {
    login() {
      videoMonitorLogin()
        .then((res) => {
          this.token = res.jsession;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getDriver(key) {
      if (key && key.length > 0) {
        const param = { no: key, status: 1 };
        getDriverInfo(param).then((res) => {
          this.driverOptions = [res.data];
        });
      }
    },

    handleSelectionChange(val) {
      this.selectItems = val;
    },

    openTaskModel() {
      this.visible = true;
    },

    query() {
      listDownloadTask(this.param).then((res) => {
        this.list = res.data;
      });
    },

    createTask() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addBackendDownloadTask(this.form).then(() => {
            this.$message.success("创建成功");
            this.visible = false;
            this.query();
          });
        }
      });
    },

    deleteTask(row) {
      this.$confirm("是否删除该任务", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delBackendDownloadTask(row.id).then(() => {
            this.$message.success("删除成功");
            this.query();
          });
        })
        .catch(() => {});
    },

    showDetail(item) {
      const { videoId, startTime, endTime } = item;

      const loadingInstance = Loading.service(options);

      getDownloadTaskList(this.token, videoId, startTime, endTime).then((res) => {
        loadingInstance.close();
        this.files = res.infos;
      });
    },

    batchDownload() {
      if (this.selectItems && this.selectItems.length) {
        this.selectItems.forEach((item) => {
          if (item.stu === 4) {
            window.open(item.downUrl);
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.detail {
  margin-top: 20px;
}
</style>
