import { Http } from "@/util";

export function getDriverEvaluationSettingList(param) {
  return Http.post("/admin/driver/service/setting/list", param);
}

export function saveOrUpdateDriverEvaluationSetting(param) {
  return Http.post("/admin/driver/service/setting/save_or_update", param);
}

export function removeDriverEvaluationSetting(param) {
  return Http.post("/admin/driver/service/setting/del", param);
}

export function getServiceScoreSummary(param) {
  return Http.get("/admin/driver/service/summary", param);
}

export function getServiceScoreDetail(param) {
  return Http.get("/admin/driver/service/detail", param);
}

export function getServiceScoreTrend(param) {
  return Http.post("/admin/driver/service/summary/trend", param);
}

export function getOrderEvaluation(orderId) {
  return Http.get(`/admin/driver/service/get/order_evaluation`, { orderId });
}

export function getRouteDetail(orderId) {
  return Http.get(`/admin/driver/service/get/route_detail`, { orderId });
}

export function cancelEvaItem(param) {
  return Http.post(`/admin/driver/service/cancel/evaluation_item`, param);
}

export function getPassengerComplaint(orderId, driverId) {
  return Http.get(`/admin/driver/service/get/complaint`, { orderId, driverId });
}

export function getSafetyScoreList(param) {
  return Http.get(`/admin/driver/service/score/safety/list`, param);
}

export function getSafetyScoreDetail(param) {
  return Http.get(`/admin/driver/service/score/safety/detail`, param);
}

export function addViolationRecord(param) {
  return Http.post(`/admin/driver/service/add/safety/item`, param);
}

// 司机奖励相关接口

export function listAwardTaskTemplate(param) {
  return Http.post(`/admin/awardtask/template/list`, param);
}

export function saveAwardTaskTemplate(param) {
  return Http.post(`/admin/awardtask/template/save`, param);
}

export function findAwardTaskTemplate(id) {
  return Http.get(`/admin/awardtask/template/info/${id}`);
}

export function deleteAwardTaskTemplate(id) {
  return Http.get(`/admin/awardtask/template/delete/${id}`);
}

export function initCreatePage() {
  return Http.get(`/admin/awardtask/template/init`);
}

export function saveAwardTask(param) {
  return Http.post(`/admin/awardtask/savetask`, param);
}

export function listAwardTasks(param) {
  return Http.post(`/admin/awardtask/listtask`, param);
}

export function stopAwardTasks(id) {
  return Http.post(`/admin/awardtask/changetaskstatus`, { id, status: 3 });
}

export function deleteAwardTask(id) {
  return Http.get(`/admin/awardtask/delete/${id}`);
}

// 立即发送奖励任务
export function sendAwardTaskMsg(id) {
  return Http.get(`/admin/awardtask/sendmsg/${id}`);
}

export function findParticipateInfoByTaskId(taskId) {
  return Http.get(`/admin/awardtask/participate/info`, { taskId });
}

export function findDriverParticipateDetails(driverId, taskId) {
  return Http.get(`/admin/awardtask/participate_detail/list`, { driverId, taskId });
}

export function calcAwardRank(isFinishTask) {
  return Http.get(`/admin/awardtask/calc/rank`, { isFinishTask });
}

export function getDriverSpecialAwardRecords(date) {
  return Http.get(`/admin/awardtask/list/special_award`, { date });
}
