<template>
  <div>
    <div class="query-container space-between">
      <el-form size="small" :inline="true">
        <el-form-item label="查询时间：">
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="param.startTime"
            type="date"
            placeholder="选择日期"
            @change="query(1)"
            style="width: 200px"
          ></el-date-picker
        ></el-form-item>

        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="param.endTime"
            type="date"
            placeholder="选择日期"
            @change="query(1)"
            style="width: 200px"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="司机编号">
          <el-input v-model="param.driverNo" @change="query(1)" style="width: 140px" clearable>
          </el-input>
        </el-form-item>

        <el-form-item label="反馈类型">
          <el-select v-model="param.feedbackType" @change="query(1)" style="width: 200px" clearable>
            <el-option
              v-for="item in feedbackOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="反馈结果">
          <el-select v-model="param.state" @change="query(1)" style="width: 200px" clearable>
            <el-option
              v-for="item in talkStatusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="650" size="small">
        <el-table-column type="expand" show-overflow-tooltip>
          <template slot-scope="props">
            <div style="padding: 20px 160px 20px 80px">
              <el-table
                :data="props.row.talkRecordList"
                :show-header="false"
                :row-class-name="tableRowClassName"
              >
                <el-table-column prop="content" label="反馈内容"></el-table-column>
                <el-table-column prop="annexes" label="附件" width="300">
                  <template slot-scope="scope" v-if="scope.row.annexes">
                    <span v-for="(item, index) in scope.row.annexes" :key="item">
                      <el-image
                        v-if="isImage(item)"
                        style="width: 80px; height: 80px"
                        :src="item"
                        fit="contain"
                        :preview-src-list="scope.row.annexes"
                        :z-index="9999"
                      ></el-image>
                      <a v-else class="annex" :href="item" target="_blank"
                        >附件{{ item | fileNameTransfer(index + 1) }}</a
                      >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="反馈时间" width="160"></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="clientType" label="客户端类型">
          <template slot-scope="scope">
            {{ scope.row.talkSession.clientType }}
          </template>
        </el-table-column>

        <el-table-column prop="feedbackType" label="反馈类型">
          <template slot-scope="scope">
            {{ scope.row.talkSession.feedbackType | enumTransfer(feedbackOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="content" label="司机编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.talkSession.driverNo }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="content" label="ETC状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.talkSession.etcEnable">已绑定</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="suggestContent" label="反馈内容" width="300">
          <template slot-scope="scope">
            {{ scope.row.talkSession.suggestContent }}
          </template>
        </el-table-column>

        <el-table-column prop="amount" label="实际金额">
          <template slot-scope="scope">
            {{ scope.row.talkSession.amountStr || scope.row.talkSession.amount }}
          </template>
        </el-table-column>

        <el-table-column prop="annexes" label="附件" width="300">
          <template slot-scope="scope" v-if="scope.row.talkSession.annexes">
            <span v-for="(item, index) in scope.row.talkSession.annexes" :key="item">
              <el-image
                v-if="isImage(item)"
                style="width: 80px; height: 80px"
                :src="item"
                fit="contain"
                :preview-src-list="scope.row.talkSession.annexes"
                :z-index="9999"
              ></el-image>
              <a v-else class="annex" :href="item" target="_blank">{{
                item | fileNameTransfer(index + 1)
              }}</a>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="行程详情" width="120">
          <template slot-scope="scope">
            <div class="spe-box" v-if="scope.row.talkSession.routeId">
              <div>
                <el-button type="text" size="small" @click="showRoutePlan(scope.row.talkSession)"
                  >行程规划</el-button
                >
              </div>

              <div>
                <el-button type="text" size="small" @click="go2QcPage(scope.row.talkSession)"
                  ><span>质检详情</span></el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="反馈结果">
          <template slot-scope="scope">
            {{ scope.row.talkSession.state | enumTransfer(talkStatusOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="updateTime" label="提交时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.talkSession.updateTime || scope.row.talkSession.createTime }}
          </template>
        </el-table-column>

        <el-table-column prop="id" fixed="right" width="180" label="操作" align="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              :disabled="scope.row.talkSession.status === 3 || scope.row.talkSession.status === 4"
              @click="reply(scope.row.talkSession)"
              >回复</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="replyVisible"
      width="660px"
      max-height="600"
      :size="size"
      title="回复"
      :rules="rules"
      :close-on-click-modal="false"
    >
      <el-form :model="replyForm" :size="size" ref="replyForm">
        <el-form-item
          prop="result"
          label="反馈结果"
          v-if="selected && (selected.feedbackType === 5 || selected.feedbackType === 2)"
        >
          <el-select v-model="replyForm.result" clearable>
            <el-option
              v-for="item in feedbackResultOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="result" label="费用说明" v-if="selected && selected.feedbackType === 2">
          <el-select v-model="replyForm.feeRemark" clearable allow-create filterable>
            <el-option
              v-for="item in feeTypeOpts"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="result"
          label="实际金额"
          v-if="selected && (selected.feedbackType === 2 || selected.feedbackType === 5)"
        >
          <el-input-number
            v-model="replyForm.amountStr"
            clearable
            placeholder="实际金额"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item prop="content" label="回复内容">
          <el-input
            type="textarea"
            v-model="replyForm.content"
            :rows="5"
            style="width: 500px"
            placeholder="回复内容"
          ></el-input>
        </el-form-item>

        <el-form-item label="附件：" style="margin: 10px 0 20px">
          <div style="padding-left: 66px">
            <multiple-file-upload v-model="replyForm.fileList" filePath="/eva_item" />
          </div>
          <div class="annex-box" v-if="replyForm.fileList && replyForm.fileList.length">
            <span>附件下载：</span>
            <span v-for="item in replyForm.fileList" :key="item.url" style="margin-right: 24px">
              <a style="color: #409eff" :href="item.url" target="_blank">{{ item.name }}</a>
            </span>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button @click.stop="submitReply" type="primary" size="small">保 存</el-button>

        <el-button @click.stop="replyVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDriverFeedbackList, saveTalkRecord } from "@/api";
import { ParamUtil, FileUtil } from "@/util";
import { mapState } from "vuex";
import Router from "@/route";
import { SHOW_ROUTE_PLAN } from "@/core/const/global-event";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  mounted() {},
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      driverEvaOptTypeOpts: (state) => state.enumMap["DRIVER_EVA_OPT_TYPE"],
      driverEvaTypeOpts: (state) => state.enumMap["DRIVER_EVA_TYPE"],
      feedbackResultOpts: (state) =>
        state.enumMap["TALK_STATUS"].filter((item) => [5, 6].includes(item.value)),
      feedbackOpts: (state) => state.enumMap["DRIVER_SUGGEST_TYPE"],
      talkStatusOpts: (state) =>
        state.enumMap["TALK_STATUS"].filter((item) => ![3, 4].includes(item.value)),
    }),
  },

  data() {
    return {
      feeTypeOpts: ["柴暖补贴"],
      param: {},
      list: [],
      total: 0,
      currentPage: 1,
      replyVisible: false,
      replyForm: {
        fileList: [],
        amountStr: null,
      },
      rules: {
        content: [{ required: true, message: "请输入回复内容", trigger: "blur" }],
        result: [{ required: true, message: "请选择申诉结果", trigger: "blur" }],
      },
      userTypeOpts: [
        { label: "司机", value: "driver" },
        { label: "乘客", value: "passenger" },
      ],
      selected: null,
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: 10,
          orders: [
            { column: "update_time", asc: false },
            { column: "id", asc: false },
          ],
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: ParamUtil.filterEmptyData(this.param),
      };
      getDriverFeedbackList(ParamUtil.filterEmptyData(param)).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showRoutePlan(item) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, { id: item.routeId }, false);
    },

    go2QcPage(item) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId: item.routeId },
      });
      window.open(href, "_blank");
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    tableRowClassName({ row }) {
      return row.talkDirect ? "driver" : "admin";
    },

    reply(session) {
      this.selected = session;

      // 如果是过路费反馈和其他费用反馈，带入反馈金额
      if (session.feedbackType === 2 || session.feedbackType === 5) {
        this.replyForm = {
          fileList: [],
          amountStr: session.amountStr || session.amount,
        };
      } else {
        this.replyForm = {
          fileList: [],
        };
      }
      this.replyVisible = true;
    },

    submitReply() {
      this.$refs.replyForm.validate((valid) => {
        if (valid) {
          const { id, driverId } = this.selected || {};
          const param = {
            content: this.replyForm.content,
            sessionId: id,
            sessionType: 2,
            driverId,
            result: this.replyForm.result,
            feeRemark: this.replyForm.feeRemark,
            annexes: this.replyForm.fileList.map((t) => t.url),
            amountStr: this.replyForm.amountStr,
          };

          saveTalkRecord(param).then(() => {
            this.$message.success("回复成功");
            this.replyVisible = false;
            this.query(this.currentPage);
          });
        }
      });
    },

    isImage(src) {
      return FileUtil.isImage(src);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .driver td {
  background: #fafbff !important;
}

/deep/ .admin td {
  background: #e0eff1 !important;
}

.spe-box {
  .el-button--small {
    padding: 0;
  }
}

.content {
  display: flex;

  .user {
    width: 160px;
  }

  .text {
    flex: 1;
  }
}
</style>
