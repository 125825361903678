<template>
  <div class="lost-box">
    <div class="head">
      <div class="item">
        <el-select v-model="param.lostType">
          <el-option
            v-for="item in lostOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>

        <el-date-picker
          class="span"
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>
        至
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择日期"
        ></el-date-picker>

        <span v-show="param.lostType == 'lost'">
          {{ param.remainType === 1 ? "下过单" : "成交过" }}且截至到
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="param.lastDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
          未{{ param.remainType === 1 ? "下过单" : "成交过" }}

          <el-radio-group v-model="param.remainType" class="span">
            <el-radio-button :label="1">按下单</el-radio-button>
            <el-radio-button :label="2">按成交</el-radio-button>
          </el-radio-group>
        </span>

        <span v-show="param.lostType != 'lost'" class="span">
          <el-select v-model="chartType" placeholder="请选择">
            <el-option
              v-for="item in chartOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </span>

        <span class="span">
          <el-button type="primary" @click="queryData">查询</el-button>
        </span>
      </div>
    </div>

    <div class="table">
      <div class="item lost-container" v-if="param.lostType == 'lost'">
        <el-table :data="losts">
          <el-table-column prop="name" label="" width="80"> </el-table-column>
          <el-table-column prop="total" label="全部" width="80">
          </el-table-column>
          <el-table-column prop="wx" label="公众号" width="120">
          </el-table-column>
          <el-table-column prop="minapp" label="小程序" width="120">
          </el-table-column>
          <el-table-column prop="app" label="APP" width="80"> </el-table-column>
          <el-table-column prop="tel" label="电话" width="80">
          </el-table-column>
        </el-table>
        <chart-view
          style="margin-top: 10px"
          ref="chart"
          valueField="lost"
          nameField="order_source_name"
          title="流失用户分析"
          initChartType="pie"
        />
      </div>

      <div class="item" v-if="param.lostType != 'lost'">
        <div
          class="item"
          style="margin-bottom: 10px"
          v-show="chartType != 'pie'"
        >
          <el-select v-model="pOrN" placeholder="请选择">
            <el-option
              v-for="item in pOrNOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-radio-group v-model="chartGroupType" class="span">
            <el-radio-button label="日"></el-radio-button>
            <el-radio-button label="周"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="年"></el-radio-button>
          </el-radio-group>
        </div>
        <chart ref="chart" :option="chartOpt" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../ChartView';
export default {
  components: {ChartView},
  data () {
    return {
      losts: [],
      summary: [],
      lostOptions: [{label: "流失用户", value: "lost"}, {label: "取关用户", value: "unscribe"}],
      pOrNOptions: [{label: "裂变", value: "p"}, {label: "非裂变", value: "n"}],
      pOrN: "p",
      orderSourceMap: ["微信", "电话", "小程序", "APP"],
      chartOpt: {
        grid: {
          bottom: 30,
        },
        legend: {
          show: true,
        }
      },
      chartGroupType: '日',
      chartType: "pie",
      chartOptions: [{label: "饼状图", value: "pie"}, {label: "折线图", value: "line"}, {label: "柱状图", value: "bar"}],
      param: {
        startDate: "",
        endDate: "",
        lastDate: "",
        remainType: 1,
        lostType: "lost"
      }
    };
  },
  watch: {
    lostType (val) {
      if (val == "lost") {
        this.param.remainType = 1;
      } else {
        this.param.lastDate = "";
        this.param.remainType = 2;
      }
    },
    chartGroupType () {
      this.renderUnscribeChart();
    },
    chartType () {
      this.renderUnscribeChart();
    }
  },
  methods: {
    renderLostChart () {
      for (let i = 1; i <= 4; i ++) {
        let row ;
        for (let j = 0; j < this.summary.length; j ++) {
          if (this.summary[j].order_source == i) {
            row = this.summary[j];
          }
        }
        if (row) {
          row["order_source_name"] = this.orderSourceMap[i - 1];
          row["lost"] = row["total1"] - (row["total2"] || 0);
          row["rate"] = (row["lost"] * 100 / row["total1"]).toFixed(2);
        }
      }
      this.$refs.chart.setData(this.summary);
    },
    getNextDay (day) {
      let nextDay = new Date(new Date(day).getTime() + 3600 * 24 * 1000);
      return nextDay.getFullYear() + "-" + (nextDay.getMonth() < 9 ? "0" + (nextDay.getMonth() + 1) : (nextDay.getMonth() + 1)) + "-"
                + (nextDay.getDate() < 10 ? "0" + nextDay.getDate() : nextDay.getDate());
    },
    renderUnscribeChart () {
      var data = [];
      var option;
      if (this.chartType == "pie") {
        data.push({value: 0, name: "裂变"});
        data.push({value: 0, name: "非裂变"});
        for (var j = 0; j < this.summary.length; j ++) {
          if (this.summary[j].porn == 1) {
            data[0].value = data[0].value + this.summary[j].num;
          } else {
            data[1].value = data[1].value + this.summary[j].num;
          }
        }
        option = {
          series: [
            {
              name: '访问来源',
              type: 'pie', // 设置图表类型为饼图
              data: data
            }
          ]
        };
      } else {
        var axis = [];
        let dim = this.param.startDate;
        let week = 1;
        while (dim <= this.param.endDate) {
          let d = {};
          for (var j1 = 0; j1 < this.summary.length; j1 ++) {
            if (this.summary[j1].ctime == dim) {
              d = this.summary[j1];
              break;
            }
          }
          if (this.chartGroupType == "日") {
            axis.push(dim);
            data.push(d.num || 0);
          } else if (this.chartGroupType != "日") {
            let key;
            if (this.chartGroupType == "月") {
              key = dim.substring(0, 7);
            } else if (this.chartGroupType == "年") {
              key = dim.substring(0, 4);
            } else if (this.chartGroupType == "周") {
              if (new Date(dim).getDay() == 0) {
                key = week ++;
              } else {
                key = week;
              }
            }

            if (axis.indexOf(key) >= 0) {
              let pos = data.length - 1;
              data[pos] = data[pos] + (d.num || 0);
            } else {
              axis.push(key);
              data.push(d.num || 0);
            }
          }
          //next loop
          dim = this.getNextDay(dim);
        }
        option = {
          tooltip: {trigger: "axis"},
          xAxis: {type: 'category', show: true, data: axis},
          yAxis: {
            show: true
          },
          series: [{
            type: this.chartType,
            data: data
          }],
          ...this.chartOpt
        };
      }
      this.$refs.chart.setOption(option);
    },
    queryData () {
      //check param
      if (!this.param.startDate || !this.param.endDate) {
        this.$message.error("日期不能为空。");
        return;
      }
      if (this.param.startDate > this.param.endDate) {
        this.$message.error("起始日期不能大于截止日期。");
        return;
      }
      if (this.param.lostType == "lost") {
        if (!this.param.lastDate) {
          this.$message.error("日期不能为空。");
          return;
        }
        if (this.param.endDate > this.param.lastDate) {
          this.$message.error("起始日期不能大于截止日期。");
          return;
        }
      }

      //do query
      this.$http.post("/admin/analysis/passenger/lost", this.param).then(res => {
        this.summary = res.data || [];
        if (this.param.lostType == "lost") {
          const name = this.param.remainType === 1 ? '下单' : '成交';
          var all = {name, total: 0, wx: 0, app: 0, tel: 0, minapp: 0};
          var lost = {name: "留失", total: 0, wx: 0, app: 0, tel: 0, minapp: 0};
          var rate = {name: "留失率", total: 0, wx: 0, app: 0, tel: 0, minapp: 0};
          this.summary.forEach(itm => {
            all.total += (itm.total1 || 0);
            lost.total += ((itm.total1 || 0) - (itm.total2 || 0));
            if (itm.order_source == 1) {
              all.wx = (itm.total1 || 0);
              lost.wx = all.wx - (itm.total2 || 0);
              if (all.wx) {
                rate.wx = (lost.wx * 100 / all.wx).toFixed(2);
              }
            } else if (itm.order_source == 2) {
              all.tel = (itm.total1 || 0);
              lost.tel = all.tel - (itm.total2 || 0);
              if (all.tel) {
                rate.tel = (lost.tel * 100 / all.tel).toFixed(2);
              }
            } else if (itm.order_source == 3) {
              all.minapp = (itm.total1 || 0);
              lost.minapp = all.minapp - (itm.total2 || 0);
              if (all.minapp) {
                rate.minapp = (lost.minapp * 100 / all.minapp).toFixed(2);
              }
            } else if (itm.order_source == 4) {
              all.app = (itm.total1 || 0);
              lost.app = all.app - (itm.total2 || 0);
              if (all.app) {
                rate.app = (lost.app * 100 / all.app).toFixed(2);
              }
            }
          });
          if (all.total) {
            rate.total = (lost.total * 100 / all.total).toFixed(2);
          }
          this.losts = [];
          this.losts.push(all);
          this.losts.push(lost);
          this.losts.push(rate);

          this.renderLostChart();
        } else {
          this.renderUnscribeChart();
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.lost-box {
  min-width: 1200px;
  min-height: 780px;
  padding: 10px;
  background-color: #edf1f5;

  .el-date-editor--date {
    width: 150px !important;
  }
  .el-select {
    width: 150px !important;
  }

  .head {
    .span {
      margin-left: 16px;
    }
  }

  .table {
    margin-top: 24px;
    padding: 10px;
    border: 1px solid #ddd;

    .lost-container {
      margin: auto;
      width: 560px;
    }
  }
}
</style>