<template>
<div class="editor">
  <div class="query-container">
    <el-button type="primary" @click="showQueryArea=true,selectTemplate={code:''}">引用模板</el-button>
  </div>

  <el-table
          :data="tableData"
          style="width: 100%">
    <el-table-column
            prop="templateCode"
            label="模板编码"
            width="80">
    </el-table-column>
    <el-table-column
            prop="templateName"
            label="模板名称"
            width="180">
    </el-table-column>
    <el-table-column
            prop="title"
            label="模板标题">
    </el-table-column>
    <el-table-column
            prop="flCode"
            label="模板分类"
            width="180">
    </el-table-column>
    <el-table-column
            label="仅管理员发送"
            width="120">
      <template slot-scope="scope">
        {{scope.row.adminSend ? '是' : '否'}}
      </template>
    </el-table-column>
    <el-table-column
            prop="emergencyName"
            label="紧急程度"
            width="80">
    </el-table-column>
    <el-table-column
            prop="needConfirm"
            label="是否需要确认"
            width="120">
      <template slot-scope="scope">
        {{scope.row.needConfirm ? '是' : '否'}}
      </template>
    </el-table-column>
    <el-table-column
            prop="createTime"
            label="创建时间"
            width="180">
    </el-table-column>
    <el-table-column
            fixed="right"
            label="操作"
            width="300">
      <template slot-scope="scope">
        <el-button @click="editNotice(null, scope.row)" type="text" size="small">编辑</el-button>
        <el-button @click="deleteNotice(scope.row)" type="text" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
          :current-page="page.pageNo"
          :page-sizes="pageSizes"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          @size-change="onPageSizeChange"
          @current-change="onPageNoChange"
  />

  <el-dialog :visible.sync="showNoticeForm" v-if="refresh" title="发送消息">
    <NoticeForm :notice="currentNoticeTmp" :sendData="sendData" @closeWindow="finishSend"></NoticeForm>
  </el-dialog>

  <el-dialog :visible.sync="showQueryArea" title="引用模板">
    <el-row style="margin-bottom: 10px;">
      模板编号: <el-input placeholder="请输入模板编号" v-model="selectTemplate.code" style="width: 146px;" @input="queryTemplate"></el-input>
      模板名称: <el-input placeholder="模板名称" v-model="selectTemplate.name" style="width: 146px; " :disabled="true"></el-input>
      模板分类: <el-input placeholder="模板分类" v-model="selectTemplate.flCode" style="width: 146px; margin-left: 26px;" :disabled="true"></el-input>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      仅管理员: <el-input placeholder="仅管理员发送" v-model="selectTemplate.adminSendName" style="width: 146px; " :disabled="true"></el-input>
      紧急程度: <el-input :disabled="true" placeholder="紧急程度" v-model="selectTemplate.emergencyName" style="width: 146px;"></el-input>
      是否需要确认: <el-input :disabled="true" placeholder="是否需要确认" v-model="selectTemplate.needConfirmName" style="width: 146px;"></el-input>
    </el-row>
    <el-row>
      <el-button @click="showQueryArea=false">取消</el-button>
      <el-button :type="selectTemplate.id?'primary':''" :disabled="!selectTemplate.id" @click="addNotice">确认</el-button>
    </el-row>
  </el-dialog>
</div>
</template>

<script>
import NoticeForm from "./NoticeForm";
export default {
  name: "NoticeRecord",
  components: {
    NoticeForm
  },
  data () {
    return {
      refresh: false,
      showNoticeForm: false,
      currentNoticeTmp: {},
      sendData: {},
      showQueryArea: false,
      selectTemplate: {code: ""},
      setTimeoutFunc: null,
      tableData: [],
      pageSizes: [10, 30, 50],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      noticeTypes: [],
      emergencyObjs: [],
      flCodeOpts: []
    };
  },
  watch: {
    "selectTemplate.code" (code) {
      if (!code) {
        this.selectTemplate.id = "";
      }
    }
  },
  mounted () {
    this.initData();
    this.initPage();
  },
  methods: {
    initData () {
      this.emergencyObjs = window.emergencyObjs;
      this.flCodeOpts = window.flCodeOpts;
      this.noticeTypes = window.noticeTypes;
    },
    initPage () {
      this.noticeTypes = window.noticeTypes;
      this.$http.post("/admin/notice/listnotsending", this.page).then((res) => {
        this.tableData = res.data.records;
        this.tableData.forEach(template => {
          for (var i = 0; i < this.noticeTypes.length; i ++) {
            let noticeType = this.noticeTypes[i];
            if (template.templateCode == noticeType.code) {
              template.templateName = noticeType['displayName'];
            }
          }
          for (var j = 0; j < this.emergencyObjs.length; j ++) {
            let emergencyObj = this.emergencyObjs[j];
            if (template.emergency == emergencyObj.value) {
              template.emergencyName = emergencyObj.label;
            }
          }
        });
      });
    },
    onPageSizeChange (pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    onPageNoChange (pageNo) {
      this.page.pageNo = pageNo;
      this.initPage();
    },
    queryTemplate () {
      if (this.setTimeoutFunc) {
        clearTimeout(this.setTimeoutFunc);
      }
      this.setTimeoutFunc = setTimeout(()=> {
        let code = this.selectTemplate.code;
        this.$http.post("/admin/notice/findtemplatebycode", {"code": code}).then((res) => {
          this.selectTemplate = res.data || {};
          for (var j = 0; j < this.emergencyObjs.length; j ++) {
            let emergencyObj = this.emergencyObjs[j];
            if (this.selectTemplate.emergency == emergencyObj.value) {
              this.selectTemplate.emergencyName = emergencyObj.label;
            }
          }
          this.selectTemplate.needConfirmName = this.selectTemplate.needConfirm ? '是' : '否';
          this.selectTemplate.adminSendName = this.selectTemplate.adminSend ? '是' : '否';
        });
      }, 1500);
    },
    addNotice () {
      if (!this.selectTemplate.id) {
        return;
      }
      this.showQueryArea = false;
      this.editNotice(this.selectTemplate, {});
    },
    editNotice (template, notice) {
      this.showNoticeForm = true;
      this.refresh = true;
      if (!template) {
        // get template
        template = {
          id: notice.templateId,
          code: notice.templateCode,
          receiver: notice.receiver,
          receiverTeam: notice.receiverTeam
        };
      }
      this.currentNoticeTmp = template;
      this.sendData = notice;
    },
    finishSend (record) {
      this.showNoticeForm = false;
      this.refresh = false;
      if (!record.sendPostpone) {
        return;
      }
      let tableData = this.tableData || [];
      let exist = false;
      for (var i = 0; i < tableData.length; i ++) {
        if (tableData[i].id == record.id) {
          exist = true;
          break;
        }
      }
      if (!exist && record.id) {
        for (var k = 0; k < this.noticeTypes.length; k ++) {
          let noticeType = this.noticeTypes[k];
          if (record.templateCode == noticeType.code) {
            record.templateName = noticeType['displayName'];
          }
        }
        for (var j = 0; j < this.emergencyObjs.length; j ++) {
          let emergencyObj = this.emergencyObjs[j];
          if (record.emergency == emergencyObj.value) {
            record.emergencyName = emergencyObj.label;
          }
        }
        this.tableData.splice(0, 0, record);
      }
    },
    deleteNotice (record) {
      this.$confirm('此操作将永久删除待发送记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=> {
        this.$http.post("/admin/notice/delete/" + record.id, {}).then(() => {
          this.$message.success("删除成功。");
          let datas = this.tableData.filter((item) => item.id != record.id);
          this.tableData = datas;
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
  .editor {
    margin: 10px;
    width: 90%;
    margin-top: 30px;

    .bottom {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .el-dialog {
      width: 100% !important;
    }

    .query-container {
      padding-bottom: 20px;
      text-align: right;
    }
  }
</style>