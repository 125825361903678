<template>
  <div class="main">
    <el-card v-for="trainFile in trainFiles" :key="trainFile.id" class="card">
      <el-row class="card-type">{{ getFileTypeName(trainFile) }}</el-row>
      <el-row class="card-content" @click.native="viewTrainFile(trainFile)">{{
        trainFile.title
      }}</el-row>
      <el-row class="card-bottom">
        <el-col :span="8">
          <i class="el-icon-s-release" v-if="trainFile.isPub" @click="revokeFile(trainFile)"></i>
          <i class="el-icon-s-promotion" v-else @click="pubFile(trainFile)"></i>
          &nbsp;
          <i class="el-icon-delete" @click="delFile(trainFile)"></i>
        </el-col>
        <el-col :span="16" class="time">{{ trainFile.uptime }}</el-col>
        <el-col :span="24" style="text-align: right" @click.native="changeTeam(trainFile)">{{
          trainFile.teamStr
        }}</el-col>
      </el-row>
    </el-card>

    <el-card class="card add" @click.native="add">
      <el-row class="add-icon">
        <i class="el-icon-plus"></i>
      </el-row>
    </el-card>

    <el-dialog title="选择/修改车队" :visible.sync="showAddDialog" width="450px">
      <el-form>
        <el-form-item label="培训车队">
          <el-select
            v-model="chooseTeams"
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in teams"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文档编号">
            <el-input v-model="seqNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="addTrainFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TrainFileList",
  data () {
    return {
      trainFiles: [],
      category: [],
      trainTypes: [],
      teams: [],
      showAddDialog: false,
      currentFile: null,
      trainCategory: "",
      trainType: "",
      chooseTeams: [],
      seqNo: "",
    };
  },
  created () {},
  mounted () {
    this.initPage();
  },
  watch: {
    trainCategory: function () {
      this.category.forEach(c=>{
        if (c.id == this.trainCategory) {
          this.trainTypes = c.subcate || [];
        }
      });
    }
  },
  computed: {

  },
  methods: {
    getFileTypeName (file) {
      var displayName = "";
      this.category.forEach(c => {
        if (c.id == file.trainCategory) {
          c.subcate.forEach(sub => {
            if (sub.id == file.trainType) {
              displayName = sub.title;
            }
          });
        }
      });
      return displayName;
    },
    getFileTeamName (file) {
      var displayText = "";
      var text = file.isPub ? "已发布" : "未发布";
      if (!file.team || file.team.length != 1) {
        displayText = "通用车队" + text;
      } else {
        for (var i = 0; i < this.teams.length; i ++) {
          if (file.team[0] == this.teams[i].id) {
            displayText = this.teams[i].name + text;
            break;
          }
        }
      }
      this.$set(file, "teamStr", displayText);
    },
    initPage () {
      let url = "/admin/training/listfile";
      this.$http.post(url, {}).then((res)=>{
        if (res.code === 200) {
          this.trainFiles = res.data.files || [];
          this.category = res.data.category || [];
          this.teams = res.data.teams || [];
          this.trainFiles.forEach(f => {
            this.getFileTeamName(f);
          });
        }
      });
    },
    addTrainFile () {
      if (this.currentFile == null) {
        this.$router.push({name: "TrainFile", query: {category: this.trainCategory, trainType: this.trainType, teams: this.chooseTeams, seq: this.seqNo}});
      } else {
        // 修改车队
        var file = this.currentFile;
        var teams = this.chooseTeams || [];

        let url = "/admin/training/changeteam/" + file.id;
        this.$http.post(url, { teams: teams.join(","), seq: this.seqNo}).then((res)=>{
          if (res.code === 200) {
            this.showAddDialog = false;
            for (var i = 0; i < this.trainFiles.length; i ++) {
              if (this.trainFiles[i].id == file.id) {
                this.trainFiles[i].team = teams;
                this.trainFiles[i].seq = this.seqNo;
                this.getFileTeamName(this.trainFiles[i]);
                break;
              }
            }
            this.currentFile = null;
          }
        });
      }
    },
    viewTrainFile (trainFile) {
      this.$router.push({name: "TrainFile", query: {fileId: trainFile.id}});
    },
    add () {
      this.chooseTeams = null;
      this.seqNo = "";
      this.trainCategory = null;
      this.currentFile = null;
      this.trainType = null;
      this.showAddDialog = true;
    },
    revokeFile (file) {
      if (!file.isPub) {
        return;
      }
      this.$confirm('撤销发布培训文档， 用户将不可见， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = "/admin/training/revokefile/" + file.id;
        this.$http.get(url).then((res)=>{
          if (res.code === 200) {
            file.isPub = false;
            this.getFileTeamName(file);
            this.$message.success("撤销发布成功。");
          }
        });
      });
    },
    pubFile (file) {
      if (file.isPub) {
        return;
      }
      this.$confirm('发布培训文档, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = "/admin/training/pubfile/" + file.id;
        this.$http.get(url).then((res)=>{
          if (res.code === 200) {
            file.isPub = true;
            this.getFileTeamName(file);
            this.$message.success("发布成功。");
          }
        });
      });
    },
    delFile (file) {
      this.$confirm('删除培训文档将不可恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = "/admin/training/delfile/" + file.id;
        this.$http.get(url).then((res)=>{
          if (res.code === 200) {
            for (var i = 0; i < this.trainFiles.length; i ++) {
              if (this.trainFiles[i].id == file.id) {
                this.trainFiles.splice(i, 1);
              }
            }
            this.$message.success("删除成功。");
          }
        });
      });
    },
    changeTeam (file) {
      this.showAddDialog = true;
      this.currentFile = file;
      let teams = file.team || [];
      this.chooseTeams = teams.map(team => parseInt(team));
      this.seqNo = file.seq;
    }
  }
};
</script>

<style scoped>
.card {
  width: 300px;
  height: 175px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.card .add-icon {
  text-align: center;
  text-align: center;
  font-size: 32px;
  margin-top: 32px;
}
.card .card-content {
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  margin: 0 -20px;
}
.card .card-bottom {
  margin-top: 12px;
}
.card .time {
  text-align: right;
}
</style>