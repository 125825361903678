<template>
  <el-container class="container">
    <el-main>
      <div class="main">
        <div class="head-area" style="font-weight: bold">
          <div class="left">
            {{ noticeTemplate.code }} {{ noticeSendMsg.admin ? "人工通知" : "系统通知" }}
          </div>
          <div class="right">
            {{ noticeTemplate.emergencyName }}
          </div>
        </div>
        <div class="head-area" style="font-weight: bold">
          {{ noticeTemplate.flName }}
        </div>
        <div class="head-area">
          <div style="margin-right: 40px">
            <span class="title">标题：</span>
            <span class="content">{{ noticeTemplate.title }}</span>
          </div>
          <div>
            <span class="title">副标题： </span>
            <span class="content">{{ noticeTemplate.subTitle }}</span>
          </div>
        </div>

        <!-- 填充内容区 -->
        <div class="fill-area">
          <span class="title">发送内容： </span>
          <span class="content" v-html="noticeTemplate.content"></span>
        </div>
        <div v-for="p in contentHolders" :key="p" style="margin-bottom: 10px">
          <span class="title" style="display: inline-block; width: 76px"></span>
          {{ p }}:
          <el-input
            v-model="contentParams[p]"
            placeholder="填充占位符发送内容"
            @input="changeContent"
            style="width: 180px; display: inline-block"
          ></el-input>
        </div>

        <div class="fill-area" v-for="field in noticeTemplate.fields" :key="field.id">
          <template v-if="field.type == 'map'">
            <div :id="field.id" style="width: 100%; height: 178px"></div>
          </template>
          <template v-if="field.type == 'img' || field.type == 'vedio' || field.type == 'voice'">
            <file-upload
              ref="uploader"
              :onUploadSuccess="
                (src) => {
                  addfile(field, src);
                }
              "
              filePath="/wxkefu"
              v-model="noticeSendMsg[field.id].value"
            ></file-upload>
          </template>
          <template v-if="field.type == 'link'">
            <span>{{ noticeSendMsg[field.id].tip || field.tip }}： </span>
            <el-link type="primary">{{ noticeSendMsg[field.id].name || field.name }}</el-link>
            <div v-if="showOrNot[field.id]">
              <div style="margin-top: 8px">
                <span class="title">抬头文案： </span>
                <el-input v-model="noticeSendMsg[field.id].tip" style="width: 240px"></el-input>
              </div>
              <div style="margin-top: 8px">
                <span class="title">链接文字： </span>
                <el-input v-model="noticeSendMsg[field.id].name" style="width: 240px"></el-input>
              </div>
              <div style="margin-top: 8px">
                <span class="title">跳转地址： </span>
                <el-input v-model="noticeSendMsg[field.id].value" style="width: 240px"></el-input>
              </div>
              <div style="margin-top: 8px">
                <span class="title">链接参数： </span>
                <el-input v-model="noticeSendMsg[field.id].args" style="width: 240px"></el-input>
              </div>
            </div>
          </template>
          <template v-if="field.type == 'input'">
            <span>{{ noticeSendMsg[field.id].name || field.name }}</span>
            <el-input
              type="textarea"
              style="border-radius: 0px"
              :readonly="true"
              :placeholder="noticeSendMsg[field.id].tip || field.tip"
            ></el-input>
            <el-button style="width: 100%; border-radius: 0px; border-top: 0px">{{
              noticeSendMsg[field.id].btnName || field.btnName
            }}</el-button>
            <div v-if="showOrNot[field.id]">
              <div style="margin-top: 8px">
                <span class="title">抬头文案： </span>
                <el-input v-model="noticeSendMsg[field.id].name" style="width: 240px"></el-input>
              </div>
              <div style="margin-top: 8px">
                <span class="title">提示语： </span>
                <el-input v-model="noticeSendMsg[field.id].tip" style="width: 240px"></el-input>
              </div>
              <div style="margin-top: 8px">
                <span class="title">按钮文字： </span>
                <el-input v-model="noticeSendMsg[field.id].btnName" style="width: 240px"></el-input>
              </div>
            </div>
          </template>
        </div>

        <el-divider></el-divider>

        <div class="title-label">接收对象</div>
        <div>
          <div>选择车队</div>
          <el-select
            v-model="noticeSendMsg.receiverTeam"
            placeholder="请选择接收车队"
            clearable
            multiple
          >
            <el-option
              v-for="item in teamOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div style="margin-top: 8px">
          <div>选择司机</div>
          <el-input v-model="noticeSendMsg.receiver" placeholder="请输入司机编号"></el-input>
        </div>
      </div>

      <div class="title-label" style="margin-bottom: 8px">接收时间</div>
      <el-col :span="24" style="margin-bottom: 8px">
        <el-date-picker
          v-model="noticeSendMsg.postponeSendTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="延迟接收时间"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="24" v-if="noticeTemplate.emergency != '1'">
        <el-date-picker
          v-model="noticeSendMsg.lastReadTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="最迟确认时间"
        >
        </el-date-picker>
      </el-col>

      <div class="footer">
        <el-button @click="closeWindow">取消</el-button>
        <el-button type="primary" @click="sendNoticeMsg">发送</el-button>
      </div>
    </el-main>
    <el-aside width="200px" class="preview">
      <div class="title">通知预览</div>
      <div>会议类别： {{ noticeTemplate.flName }}</div>
      <div>标题： {{ noticeTemplate.title }}</div>
      <div>副标题： {{ noticeTemplate.subTitle }}</div>
      <div>通知内容： {{ noticeSendMsg.content }}</div>
      <div class="fill-area" v-for="field in noticeTemplate.fields" :key="field.id">
        <template v-if="field.type == 'map'">
          <div :id="field.id" style="width: 100%; height: 148px">
            <img src="../../../assets/image/map.png" style="width: 100%" />
          </div>
        </template>
        <template v-if="field.type == 'img'">
          <el-image
            style="width: 180px; height: 180px"
            :src="noticeSendMsg[field.id].value"
            fit="fit"
          ></el-image>
        </template>
        <template v-if="field.type == 'vedio' || field.type == 'voice'">
          <i class="el-icon-video-play"></i>
        </template>
        <template v-if="field.type == 'link'">
          <span>{{ noticeSendMsg[field.id].tip || field.tip }}：</span>
          <el-link type="primary">
            {{ noticeSendMsg[field.id].name || field.name }}
          </el-link>
        </template>
        <template v-if="field.type == 'input'">
          <div>{{ noticeSendMsg[field.id].name || field.name }}:</div>
          <el-input
            type="textarea"
            style="border-radius: 0px"
            :readonly="true"
            :placeholder="noticeSendMsg[field.id].tip || field.tip"
          ></el-input>
          <el-button style="width: 100%; border-radius: 0px; border-top: 0px">
            {{ noticeSendMsg[field.id].btnName || field.btnName }}
          </el-button>
        </template>
      </div>
      <div v-if="noticeTemplate.btns && noticeTemplate.btns.length">
        <el-button v-for="btn in noticeTemplate.btns" :key="btn.id">{{ btn.name }}</el-button>
      </div>
    </el-aside>
  </el-container>
</template>

<script>
const markIcon = require("@/assets/image/mark_b.png");
import { DateUtil } from "@/util/index";

export default {
  name: "NoticeForm",
  props: ["notice", "sendData"],
  data() {
    return {
      teamOpts: [],
      noticeTypeEnums: [],
      emergencyObjs: [],
      noticeTemplate: { typeObj: "", emergencyObj: "" },
      contentHolders: [],
      contentParams: {},
      noticeSendMsg: {
        id: null,
        receiverTeam: "",
        receiver: "",
        postponeSendTime: "",
        lastReadTime: "",
      },
      showOrNot: {},
    };
  },
  mounted() {
    this.noticeTypeEnums = window.noticeTypes;
    this.emergencyObjs = window.emergencyObjs;
    this.initPage();
  },
  methods: {
    initPage() {
      this.$http.post("/admin/notice/template/" + this.notice.id, {}).then((res) => {
        let template = res.data || {};
        this.noticeTypeEnums.forEach((emu) => {
          if (emu.code == template.code) {
            template.typeObj = emu.displayName;
            return false;
          }
        });
        this.emergencyObjs.forEach((emg) => {
          if (emg.value == template.emergency) {
            template.emergencyName = emg.label;
            return false;
          }
        });
        //FIXME flcode
        template.flName = "会议通知";
        this.noticeTemplate = template;
        this.initNoticeMsg();
        this.initMap();
      });
      this.$http.post("/admin/team/list", {}).then((res) => {
        let teams = res.data || [];
        let teamOpts = [];
        teams.forEach((team) => {
          teamOpts.push({ value: team.id, label: team.name });
        });
        this.teamOpts = teamOpts;
      });
    },
    initNoticeMsg() {
      let HolderReg = /#\{\[(.+?)\]\}/;
      let content = this.noticeTemplate.content || "";
      while (content) {
        let matchData = content.match(HolderReg);
        if (!matchData) {
          break;
        }
        if (matchData[1]) {
          this.contentHolders.push(matchData[1]);
          this.$set(this.contentParams, matchData[1], "");
        }
        content = content.substring(matchData.index + matchData[0].length);
      }

      if (this.sendData.contentHolders) {
        let contentHolders = JSON.parse(this.sendData.contentHolders);
        Object.assign(this.contentParams, contentHolders);
      }
      this.noticeSendMsg.id = this.sendData.id;
      let receiverTeamIds = this.sendData.receiverTeamId || this.notice.receiverTeamId;
      if (receiverTeamIds) {
        var ids = receiverTeamIds.split(",").map((id) => parseInt(id));
        this.noticeSendMsg.receiverTeam = ids;
      }
      this.noticeSendMsg.receiver = this.sendData.receiverNo || this.notice.receiverNo;
      this.noticeSendMsg.admin = this.sendData.admin;
      this.noticeSendMsg.content = this.sendData.content;
      this.noticeSendMsg.postponeSendTime = this.sendData.postponeSendTime;
      this.noticeSendMsg.lastReadTime = this.sendData.lastReadTime;
      this.noticeTemplate.fields.forEach((field) => {
        let valueObj = JSON.parse(JSON.stringify(field));
        if (this.sendData.fields) {
          for (var i = 0; i < this.sendData.fields.length; i++) {
            if (this.sendData.fields[i].id == field.id) {
              Object.assign(valueObj, this.sendData.fields[i]);
            }
          }
        }
        delete valueObj["id"];
        delete valueObj["type"];
        this.$set(this.noticeSendMsg, field.id, valueObj);
        Object.assign(field, valueObj);
        this.$set(this.showOrNot, field.id, false);
      });
    },
    initMap() {
      let fields = this.noticeTemplate.fields || [];
      fields.forEach((field) => {
        if (field.type == "map") {
          let divId = field.id;
          this.$nextTick(() => {
            AMap.plugin(["AMap.AutoComplete", "AMap.Geocoder"], () => {
              let lngAndLat = null;
              if (field.lng && field.lat) {
                lngAndLat = [field.lng, field.lat];
              } else {
                lngAndLat = [114.254822, 30.619296];
              }
              const map = new AMap.Map(divId, {
                center: lngAndLat,
                resizeEnable: true,
                zoom: 14,
              });
              map.setFitView();

              let markers = [];
              let removeMarkers = [];
              if (field.lng && field.lat) {
                let marker = new AMap.Marker({
                  icon: markIcon,
                  position: new AMap.LngLat(field.lng, field.lat),
                  draggable: true,
                  label: {
                    content: `<div class="car-info"></div>`,
                    direction: "top",
                  },
                });
                markers.push(marker);
                removeMarkers.push(marker);
                map.add(markers);
              }

              map.on("click", (e) => {
                if (removeMarkers.length) {
                  map.remove(removeMarkers);
                  removeMarkers = [];
                }
                markers = [];
                this.noticeSendMsg[field.id] = {
                  lng: e.lnglat.lng,
                  lat: e.lnglat.lat,
                };
                const marker = new AMap.Marker({
                  icon: markIcon,
                  position: new AMap.LngLat(e.lnglat.lng, e.lnglat.lat),
                  draggable: true,
                  label: {
                    content: `<div class="car-info"></div>`,
                    direction: "top",
                  },
                });
                markers.push(marker);
                removeMarkers.push(marker);
                map.add(markers);
              });
            });
          });
        }
      });
    },

    changeContent() {
      let content = this.noticeTemplate.content;
      for (var p in this.contentParams) {
        content = content.replace("#{[" + p + "]}", this.contentParams[p]);
      }
      this.noticeSendMsg.content = content;
    },

    addfile(field, src) {
      this.$set(this.noticeSendMsg[field.id], "value", src);
    },

    openSetting(field) {
      for (var p in this.showOrNot) {
        if (p != field.id) {
          this.showOrNot[p] = false;
        }
      }
      this.showOrNot[field.id] = !this.showOrNot[field.id];
    },

    sendNoticeMsg() {
      let url = "/admin/notice/sendmsg/" + this.noticeTemplate.code;
      let params = { attach: {} };
      let propNames = ["receiver", "receiverTeam", "lastReadTime", "postponeSendTime"];
      for (var p in this.noticeSendMsg) {
        if (propNames.indexOf(p) >= 0) {
          if (
            (p == "postponeSendTime" || p == "lastReadTime") &&
            this.noticeSendMsg[p] &&
            typeof this.noticeSendMsg[p] != "string"
          ) {
            params[p] = DateUtil.format(this.noticeSendMsg[p], "yyyy-MM-dd hh:mm:ss");
          } else {
            params[p] = this.noticeSendMsg[p];
          }
        } else if (Number(p) || p == "contentHolders") {
          params["attach"][p] = this.noticeSendMsg[p];
        }
      }
      params.attach.contentHolders = this.contentParams;
      params.id = this.noticeSendMsg.id;
      if (!params.receiver && (!params.receiverTeam || !params.receiverTeam.length)) {
        this.$message.error("请选择发送对象。");
        return;
      }
      this.$http.post(url, params).then((res) => {
        this.$message.success("提交成功");
        this.closeWindow(res.data);
      });
    },

    closeWindow(record) {
      this.$emit("closeWindow", record);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  border: 1px solid #e6e6e6;
  .left {
    position: absolute;
    left: 0;
  }
  .right {
    position: absolute;
    right: 0;
  }
  .head-area {
    div {
      display: inline-block;
    }
    line-height: 32px;
    height: 32px;
    position: relative;
    text-align: center;
  }
  .fill-area {
    margin-top: 8px;
    .title {
      display: inline-block;
      width: 80px;
    }
  }
  .set-area {
    display: inline-block;
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: #409eff;
  }
  .title-label {
    margin-top: 20px;
    background: #eee;
    padding: 10px;
    clear: both;
  }

  .footer {
    text-align: right;
    margin: 20px 0;
  }

  .preview {
    padding: 10px;
    border-left: 1px solid #e6e6e6;
    .title {
      margin-top: 20px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
