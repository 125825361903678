<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="金额">
          <el-input-number
            style="width: 200px"
            v-model="param.amount"
            @change="query()"
            clearable
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="openForm()"
            >新建优惠券模板</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column
          label="序号"
          type="index"
          show-overflow-tooltip
          width="50"
        ></el-table-column>

        <el-table-column prop="label" label="标签"></el-table-column>

        <el-table-column prop="amount" label="金额"></el-table-column>

        <el-table-column prop="pathIds" label="路线">
          <template slot-scope="scope">
            <div v-if="!scope.row.pathIds">全线路通用</div>
            <div v-else v-for="item in scope.row.pathIds" :key="item">
              {{ item | pathNameTransfer }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="有效时间">
          <template slot-scope="scope">
            <div>{{ scope.row.validOn }}</div>
            <div>{{ scope.row.validOff }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="validOff" label="是否过期" sortable>
          <template slot-scope="scope">
            <div>{{ scope.row.validOff | expired }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openForm(scope.row)"
              >修改</el-button
            >

            <el-button type="text" size="small" @click="delTemp(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="600"
      title="优惠券"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :size="size"
        :rules="rules"
      >
        <el-form-item label="优惠路线：">
          <el-select multiple v-model="form.pathIds" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间：" prop="validOn">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            value-format="yyyy-MM-dd"
            v-model="form.validOn"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="截止日期：" prop="validOff">
          <el-date-picker
            style="width: 100%"
            placeholder="选择时间"
            value-format="yyyy-MM-dd"
            v-model="form.validOff"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="优惠金额：" prop="amount">
          <el-input-number
            v-model="form.amount"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="标签备注：" prop="amount">
          <el-input v-model="form.label" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item>
          <span class="red">注意：路线不选择则为通用优惠券</span>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveOrUpdate"
          >保存</el-button
        >
        <el-button type="info" :size="size" @click="visible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {delSmsCoupon, getSmsCouponList, saveOrUpdateSmsCoupon} from '@/api';
import { Toolkit, DateUtil} from '@/util';

export default {
  components: {},
  props: {},
  filters: {
    expired (val) {
      const today = DateUtil.getDayStr(0);
      if (val < today) {
        return '已过期';
      } else {
        return '未过期';
      }
    }
  },
  created () {
    this.query();
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });

      }
    })
  },
  data () {
    return {
      rules: {
        content: [
          {required: true, message: '请输入短信内容', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入短信标题', trigger: 'blur'},
        ],
        smsIndex: [
          {required: true, message: '请输入短信序号', trigger: 'blur'},
        ],
      },
      visible: false,
      param: {},
      form: {},
      list: []
    };
  },
  methods: {

    query () {
      getSmsCouponList(Toolkit.filterEmpty(this.param)).then(res=>{
        this.list = res.data || [];
      });
    },

    openForm (item) {
      if (item) {
        this.form = {...item};
      } else {
        this.form = {};
      }
      this.visible = true;
    },

    saveOrUpdate () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = Toolkit.filterEmpty({...this.form});
        saveOrUpdateSmsCoupon(param).then(() => {
          this.$message.success('操作成功');
          this.visible = false;
          this.query();
        });
      });

    },


    delTemp (item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSmsCoupon({id: item.id}).then(()=>{
          this.$message.success('删除成功');
          this.query();
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
.sms-index {
  width: 50px;
  height: 50px;
  background-color: #409eff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
</style>