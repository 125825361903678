import { Http } from "@/util";

export function listEvaluations(param) {
  return Http.post("/admin/evaluation/list", param);
}

// 查询司机差评
export function getBadEvaluationList(param) {
  return Http.post("/admin/evaluation/get/bad/list", param);
}
