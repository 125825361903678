<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div @keyup.ctrl.enter="showInitBtn">
        <el-form size="small" :inline="true">
          <el-form-item label="路线：">
            <el-select v-model="param.pathId" @change="query()" placeholder="请选择">
              <el-option
                v-for="item in pathOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间">
            <el-date-picker
              @change="query()"
              v-model="param.date"
              align="left"
              type="date"
              :clearable="false"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="车型">
            <el-select
              v-model="param.vehicleTypeId"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in vehicleTypeOpts"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <!-- <el-switch v-model="stop" @change="changeStopStatua"> </el-switch> -->

        <span style="margin-right: 10px">
          <el-checkbox v-model="detailMode">详细模式</el-checkbox>
          <el-checkbox style="margin-right: 10px" v-model="allTickets">所有车票</el-checkbox>
          <el-tag v-if="stopReason" closable @close="handleClose">
            {{ stopReason }}
          </el-tag>
          <template v-else>
            <el-select
              size="small"
              v-if="selectVisible"
              :value="stopReason"
              style="width: 160px"
              placeholder="请选择停班原因"
              @change="changeStopStatua"
            >
              <el-option v-for="item in stopReasonOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-button v-else class="button-new-tag" size="small" @click="selectVisible = true"
              >+ 今日停班设置</el-button
            >
          </template>
        </span>

        <el-button type="primary" :size="size" @click="setRemark">批量设置</el-button>

        <el-button v-if="isManager" type="primary" :size="size" @click="changePrice"
          >调整票价</el-button
        >
        <el-button type="primary" v-if="initBtn" :size="size" @click="initAutoTicket()"
          >初始化排票</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="list"
        stripe
        ref="table"
        row-key="id"
        max-height="680"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="序号" type="index" width="50"> </el-table-column>
        <el-table-column label="序号" type="selection" width="60"> </el-table-column>

        <el-table-column prop="showTime" label="时间"></el-table-column>

        <el-table-column prop="price" label="基础/儿童/货物票价" v-if="!detailMode">
          <template slot-scope="scope">
            {{ scope.row.price }} / {{ scope.row.childrenPrice }} / {{ scope.row.goodsPrice }}
          </template>
        </el-table-column>

        <el-table-column
          prop="backupUsedNum"
          label="候补订票总数"
          v-if="!detailMode"
        ></el-table-column>

        <el-table-column
          prop="backupNum"
          label="候补票剩余数量"
          v-if="!detailMode"
        ></el-table-column>

        <el-table-column prop="usedNum" label="订票总数"></el-table-column>

        <el-table-column prop="num" label="余票张数"></el-table-column>

        <template v-if="detailMode">
          <el-table-column prop="exclusiveNum" label="独占车票">
            <template slot-scope="scope">
              <span> {{ scope.row.exclusiveNum }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="shareNum" label="共享车票"></el-table-column>

          <!-- <el-table-column prop="waitingNum" label="待分配数量"></el-table-column> -->
          <el-table-column prop="waitingCars" label="拟分配司机"></el-table-column>
          <el-table-column prop="shareCars" label="共享车辆"></el-table-column>

          <!-- <el-table-column prop="dispatchedNum" label="已分配数量"></el-table-column> -->
          <el-table-column prop="dispatchedCars" label="已分配司机"></el-table-column>
          <el-table-column prop="planCars" label="预计分配司机"></el-table-column>

          <el-table-column prop="idleCar" label="可包车类型"></el-table-column>

          <el-table-column prop="lateNum" label="晚点订单数"></el-table-column>
        </template>

        <el-table-column prop="remark" label="车票备注" v-if="!detailMode"></el-table-column>

        <el-table-column prop="id" label="操作" align="right" width="340">
          <template slot-scope="scope">
            <span class="operate-btns">
              <el-button type="text" size="small" @click="hideOrShow(scope.row)">{{
                scope.row.showTicket ? "隐藏" : "显示"
              }}</el-button>

              <el-button
                type="text"
                size="small"
                :style="{
                  color: scope.row.disableShareTicket ? 'red' : '#66b1ff',
                }"
                @click="closeShareTicket(scope.row)"
                >{{ scope.row.disableShareTicket ? "启用共享票" : "关闭共享票" }}</el-button
              >
              <el-button
                type="text"
                size="small"
                :class="{ red: scope.row.moreNum !== undefined }"
                @click="openEditModal(scope.row, scope.$index)"
                >手动控票</el-button
              >
              <!-- <el-button
                type="text"
                size="small"
                :style="{
                  color: scope.row.autoDispatch ? 'red' : '#66b1ff',
                }"
                @click="changeAutoDispatch(scope.row, !scope.row.autoDispatch)"
                >{{ scope.row.autoDispatch ? "关闭自动派单" : "开启自动派单" }}</el-button
              > -->

              <el-button
                type="text"
                size="small"
                :style="{
                  color: scope.row.closeCharter ? 'orange' : '#66b1ff',
                }"
                @click="changeCloseCharter(scope.row, !scope.row.closeCharter)"
                >{{ scope.row.closeCharter ? "开启包车" : "关闭包车" }}</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="600px"
      max-height="500"
      :before-close="closeDiag"
      :show-close="false"
      custom-class="ticket-map-modal"
      :close-on-click-modal="false"
    >
      <slot name="title">
        <div class="header">
          <div>修改车票信息： {{ form.date }} {{ form.showTime }}</div>
          <div></div>
        </div>
      </slot>

      <div style="margin: 20px 0 0 10px">
        <el-form ref="userForm" :model="form" :size="size">
          <el-form-item label="车票张数" prop="moreNum">
            <el-input-number v-model="form.moreNum" style="width: 350px"></el-input-number>
          </el-form-item>

          <!-- <el-form-item label="候补车票" prop="backupNum">
            <el-input-number v-model="form.backupNum" style="width: 350px"></el-input-number>
          </el-form-item> -->

          <el-form-item label="车票备注" prop="num">
            <el-select v-model="form.remark" style="width: 350px" placeholder="请选择" clearable>
              <el-option v-for="item in remarkOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button type="info" :size="size" @click="closeDiag">取消</el-button>
        <el-button type="warning" :size="size" @click="manualAdjust">保存</el-button>
        <el-button type="primary" :size="size" @click="reAuto">还原自动控票</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="remarkVisible"
      max-height="300"
      width="500px"
      title="提示"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref=" batchForm" :model="batchForm" :size="size">
          <el-form-item label="备注">
            <el-select
              size="small"
              v-model="batchForm.remark"
              style="width: 350px"
              placeholder="请选择"
              clearable
            >
              <el-option v-for="item in remarkOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否不可包车">
            <el-switch v-model="batchForm.closeCharter" active-color="#13ce66"> </el-switch>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button type="info" :size="size" @click="remarkVisible = false">取 消</el-button>
        <el-button type="primary" :size="size" @click="updateBatchTicket">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="priceVisible"
      width="1000px"
      title="调整票价"
      :close-on-click-modal="false"
      custom-class="edit-modal"
    >
      <el-form
        ref="priceForm"
        :model="priceForm"
        label-width="120px"
        :size="size"
        :rules="rules"
        :inline="true"
      >
        <el-form-item label="基础票价：" prop="price">
          <el-input-number v-model="priceForm.price" style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="货物票价：" prop="goodsPrice">
          <el-input-number v-model="priceForm.goodsPrice" style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="儿童票价：" prop="childrenPrice">
          <el-input-number v-model="priceForm.childrenPrice" style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="包车折扣：" prop="charterDiscount">
          <el-input-number
            v-model="priceForm.charterDiscount"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="最低价格：" prop="minPrice">
          <el-input-number v-model="priceForm.minPrice" style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="最高价格：" prop="maxPrice">
          <el-input-number v-model="priceForm.maxPrice" style="width: 100%"></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="saveChangePrice(true)"
          >保存并调整发车标准</el-button
        >
        <el-button type="primary" :size="size" @click="saveChangePrice(false)">保存</el-button>
        <el-button type="info" :size="size" @click="priceVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTicketList,
  manualAdjustTicket,
  initAutoTest,
  listSpecArea,
  isAutoTicket,
  batchUpdate,
  getUsableRemark,
  removeStopReason,
  addStopReason,
  batchUpdatePrice,
} from "@/api";
import { mapState } from "vuex";
import { Toolkit, DateUtil } from "@/util";
import _ from "lodash";
import moment from "moment";
import config from "@/config/config";

export default {
  created() {
    this.query();
    this.getRemarks();
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
      isManager: (state) => state.isManager,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
    }),
  },
  watch: {
    allTickets() {
      this.filterTicket();
    },
  },

  data() {
    return {
      priceVisible: false,
      remarkVisible: false,
      remarkOpts: [],
      stopReasonOpts: [],
      batchForm: {
        remark: "",
        closeCharter: false,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "后天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      param: {
        date: DateUtil.getDayStr(),
        pathId: config.initPath,
      },
      rules: {
        price: [
          {
            required: true,
            message: "请输入基础票价",
            trigger: "blur",
          },
        ],
        goodsPrice: [
          {
            required: true,
            message: "请输入货物票价",
            trigger: "blur",
          },
        ],
        minPrice: [
          {
            required: true,
            message: "请输入最低票价",
            trigger: "blur",
          },
        ],
        maxPrice: [
          {
            required: true,
            message: "请输入最高票价",
            trigger: "blur",
          },
        ],
        childrenPrice: [
          {
            required: true,
            message: "请输入儿童票价",
            trigger: "blur",
          },
        ],
        charterDiscount: [
          {
            required: true,
            message: "请输入包车优惠",
            trigger: "blur",
          },
        ],
      },
      list: [],
      startAreaSettings: [],
      endAreaSettings: [],
      visible: false,
      form: {},
      priceForm: {},
      item: {},
      autoTicket: true,
      initBtn: false,
      detailMode: false,
      stopReason: null,
      selectVisible: false,
      areaList: [],
      allTickets: false,
      index: 0,
    };
  },
  methods: {
    query() {
      this.$refs.table && this.$refs.table.clearSelection();
      getTicketList(Toolkit.filterEmpty(this.param)).then((res) => {
        const { list = [], stopReason } = res.data || {};
        this.stopReason = stopReason;
        this.oriList = list;
        this.filterTicket();
        if (!stopReason) {
          this.selectVisible = false;
        }
      });

      listSpecArea(this.param.pathId).then((res) => {
        const { startList = [], endList = [] } = res.data || {};
        let all = [];
        all = all.concat(startList);
        all = all.concat(endList);
        this.areaList = all;
      });
    },

    changeAutoDispatch(item, val) {
      const param = { ...item };
      param.autoDispatch = val;
      this.changeTicket(param);
    },

    changeCloseCharter(item, val) {
      const param = { ...item };
      param.closeCharter = val;
      this.changeTicket(param);
    },

    filterTicket() {
      if (this.allTickets) {
        this.list = this.oriList;
      } else {
        const now = moment().subtract(1, "hours").format("YYYY-MM-DD HH:00:00");
        this.list = this.oriList.filter((item) => item.dateTime >= now);
      }
    },

    changeStopStatua(val) {
      if (val) {
        this.$confirm(`确认停班吗！`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const param = {
              pathId: this.param.pathId,
              date: this.param.date,
              remark: val,
            };
            addStopReason(param).then(() => {
              this.query();
            });
          })
          .catch(() => {
            this.selectVisible = false;
          });
      }
    },

    handleClose() {
      this.$confirm(`确认取消停班吗！`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            pathId: this.param.pathId,
            date: this.param.date,
          };

          removeStopReason(param).then(() => {
            this.query();
          });
        })
        .catch(() => {
          this.selectVisible = false;
        });
    },

    getRemarks() {
      getUsableRemark().then((res) => {
        const { stopReasonOpts = [], remarkOpts = [] } = res.data || {};
        this.remarkOpts = remarkOpts;
        this.stopReasonOpts = stopReasonOpts;
      });
    },

    showInitBtn() {
      this.initBtn = !this.initBtn;
    },
    initAutoTicket() {
      initAutoTest(this.param.pathId).then(() => {
        this.query();
      });
    },
    handleSelectionChange(val) {
      this.selectTicket = val;
    },
    setRemark() {
      if (!this.selectTicket || !this.selectTicket.length) {
        this.$message.error("请先选择要设置的车票");
        return;
      }
      this.batchForm = {
        remark: "",
        closeCharter: false,
      };
      this.remarkVisible = true;
    },
    updateBatchTicket() {
      const ticketIds = this.selectTicket.map((item) => item.id);
      const param = {
        pathId: this.param.pathId,
        date: this.param.date,
        ...this.batchForm,
        ticketIds,
      };

      batchUpdate(param).then(() => {
        this.remarkVisible = false;
        this.query();
      });
    },

    tableRowClassName({ row }) {
      const now = moment().subtract(1, "hours").format("YYYY-MM-DD HH:00:00");

      const { num, lateNum, usedNum } = row;
      // 如果num < 0 ，则按照num的大小分级
      if (num < 0) {
        return "warn2";
      } else if (lateNum > 0 && usedNum > 0) {
        return "warn1";
      }

      if (row.showTicket && row.dateTime >= now) {
        return "";
      }
      return "hide-row";
    },

    closeDiag() {
      this.autoTicket = true;
      this.visible = false;
    },

    changePrice() {
      const { pathId, date } = this.param || {};
      if (this.list.length) {
        const demo = this.list[0];
        // 校验查询出的是当前查询条件下的车票
        if (demo.pathId !== pathId || demo.date !== date) {
          this.$message.warn("请等待车票查询出来再操作");
        } else {
          this.priceForm = _.cloneDeep(demo);
          this.priceVisible = true;
        }
      } else {
        this.$message.warn("请等待车票查询出来再操作");
      }
    },

    saveChangePrice(updateDepartStandard) {
      this.$refs.priceForm.validate((valid) => {
        if (valid) {
          batchUpdatePrice(this.priceForm, updateDepartStandard).then(() => {
            this.priceVisible = false;
            this.query();
            this.$message.success("操作成功");
          });
        }
      });
    },

    manualAdjust() {
      const form = this.form;
      const param = _.cloneDeep(this.item);
      param.moreNum = form.moreNum;

      if (form.remark) {
        param.remark = form.remark;
      }
      this.changeTicket(param);
    },

    reAuto() {
      const param = _.cloneDeep(this.item);
      param.moreNum = null;
      this.changeTicket(param);
    },

    changeTicket(param) {
      manualAdjustTicket(param).then((res) => {
        if (res.data) {
          this.$confirm(`确认修改车票数量吗？该操作将关闭自动排票！`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              manualAdjustTicket(param, false).then(() => {
                this.$message.success("修改成功");
                this.visible = false;
                this.query();
              });
            })
            .catch(() => {});
        } else {
          this.$message.success("修改成功");
          this.visible = false;
          this.query();
        }
      });
    },

    openEditModal(item, index) {
      this.visible = true;
      this.form = {
        num: item.num,
        remark: item.remark,
        time: item.time,
        showTime: item.showTime,
        date: item.date,
        moreNum: item.moreNum,
        backupNum: item.backupNum,
      };

      this.item = item;
      this.index = index;

      isAutoTicket(item).then((res) => {
        this.autoTicket = res.data;
      });
    },

    hideOrShow(item) {
      const param = _.cloneDeep(item);
      param.showTicket = !param.showTicket;
      this.changeTicket(param);
    },

    closeShareTicket(item) {
      const param = _.cloneDeep(item);
      param.disableShareTicket = !param.disableShareTicket;
      this.changeTicket(param);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table {
  .warn1 {
    background-color: #ffd666 !important;

    &:hover td {
      background-color: #ffd666 !important;
    }

    &.el-table__row--striped td {
      background-color: #ffd666 !important;
    }
  }

  .warn2 {
    background-color: #ff9798 !important;
    color: #fff;

    &:hover td {
      background-color: #ff9798 !important;
      color: #fff;
    }

    &.el-table__row--striped td {
      background-color: #ff9798 !important;
      color: #fff;
    }
  }

  .warn3 {
    background-color: #820014 !important;
    color: #fff;

    &:hover td {
      background-color: #820014 !important;
      color: #fff;
    }

    &.el-table__row--striped td {
      background-color: #820014 !important;
    }
  }
}

.btns {
  margin: 0 20px;
}

.remark {
  margin: 20px 0;
}

#ticketMap {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}

.areas {
  display: flex;

  .item {
    margin-right: 10px;
  }
}

.operate-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .item {
    margin-right: 10px;
  }
}

/deep/ .ticket-map-modal {
  margin-top: 10vh !important;

  .el-dialog__body {
    padding: 20px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 15px 10px;
    align-items: center;
    font-weight: bold;
  }
}
</style>
