<template>
  <div class="main">
    <el-card v-for="trainVedio in trainVedios"
             :key="trainVedio.id"
             class="card">
      <el-row class="card-type">{{ trainVedio.teams ? '通用视频': '车队视频'}}</el-row>
      <el-row class="card-content">{{ trainVedio.name }}</el-row>
      <el-row class="card-bottom">
        <el-col :span="8">
          <i class="el-icon-setting"
             @click="pubVedio(trainVedio)"></i>&nbsp;
          <i class="el-icon-delete"
             @click="delVedio(trainVedio)"></i>&nbsp;
          <i class="el-icon-video-play"
             @click="playVedio(trainVedio)"></i>
        </el-col>
        <el-col :span="16"
                class="time">{{ trainVedio.uptime }}</el-col>
        <el-col :span="24"
                style="text-align: right">{{
          trainVedio.teamStr
        }}</el-col>
      </el-row>
    </el-card>

    <el-card class="card add"
             @click.native="addVedio">
      <el-row class="add-icon">
        <i class="el-icon-plus"></i>
      </el-row>
    </el-card>

    <el-dialog :visible.sync="showPlayDialog"
               width="450px">
      <video :src="currentVedioObj.trainSrc"></video>
    </el-dialog>

    <el-dialog title="删除确认"
               :visible.sync="showDelDialog"
               width="450px">
      <el-col>确定要删除该培训视频吗？</el-col>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="showDelDialog = false">取 消</el-button>
        <el-button type="primary"
                   @click="doDelVedio">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置视频"
               :visible.sync="showAddDialog"
               width="450px">
      <el-form>
        <el-form-item label="视频名称">
          <el-input v-model="currentVedioObj.name"></el-input>
        </el-form-item>

        <el-form-item label="培训车队">
          <el-select v-model="currentVedioObj.teams"
                     multiple
                     placeholder="请选择"
                     style="width: 100%">
            <el-option v-for="item in teams"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选项视频">
          <file-upload v-model="currentVedioObj.trainSrc"
                       filePath="/driver_train/video" />
        </el-form-item>

        <el-form-item label="视频顺序">
          <el-input v-model="currentVedioObj.trainIdx"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary"
                   @click="saveVedio">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TrainVedio",
  data () {
    return {
      trainVedios: [],
      vedioTempObj: {
        id: "",
        name: "",
        trainCategory: "",
        trainType: "",
        teams: [],
        trainSrc: "",
        trainIdx: 1,
        isPub: 0,
        ctime: "",
        uptime: "",
      },
      currentVedioObj: {},
      category: [],
      trainTypes: [],
      teams: [],
      showAddDialog: false,
      showDelDialog: false,
      showPlayDialog: false,
      uploadUrl: "/admin/upload/file",
    };
  },
  created () {},
  mounted () {
    let url = "/admin/training/initvediopage";
    this.$http.post(url, {}).then((res) => {
      if (res.code === 200) {
        this.category = res.data.category || [];
        this.teams = res.data.teams || [];
        this.trainVedios = res.data.vedios || [];
        this.trainVedios.forEach((v) => {
          v.isPub = v.pub;
          this.getVedioTeamName(v);
        });
      }
    });
  },
  watch: {
    "currentVedioObj.trainCategory": function () {
      this.category.forEach((c) => {
        if (c.id == this.currentVedioObj.trainCategory) {
          this.trainTypes = c.subcate || [];
        }
      });
    },
  },
  methods: {
    getVedioTypeName (vedio) {
      var displayName = "";
      this.category.forEach((c) => {
        if (c.id == vedio.trainCategory) {
          c.subcate.forEach((sub) => {
            if (sub.id == vedio.trainType) {
              displayName = sub.title;
            }
          });
        }
      });
      return displayName;
    },
    getVedioTeamName (vedio) {
      var dispalyText = "";
      var text = vedio.isPub ? "已发布" : "未发布";
      if (!vedio.teams || vedio.teams.length != 1) {
        dispalyText = "通用车队" + text;
      }
      for (var i = 0; i < this.teams.length; i ++) {
        if (vedio.teams[0] == this.teams[i].id) {
          dispalyText = this.teams[i].name + text;
          break;
        }
      }
      this.$set(vedio, "teamStr", dispalyText);
    },
    addVedio () {
      this.currentVedioObj = Object.assign({}, this.vedioTempObj);
      this.showAddDialog = true;
    },
    saveVedio () {
      let url = "/admin/training/savevedio";
      this.$http.post(url, this.currentVedioObj).then((res) => {
        if (res.code === 200) {
          var vedio = res.data || {};
          this.push2Vedios(vedio);
        }
      });
      this.showAddDialog = false;
    },
    pubVedio (trainVedio) {
      if (trainVedio.isPub) {
        return;
      }
      let url = "/admin/training/pubvedio/" + trainVedio.id;
      this.$http.get(url).then((res) => {
        if (res.code === 200) {
          trainVedio.isPub = true;
          this.getVedioTeamName(trainVedio);
          this.$message.success("发布成功。");
        }
      });
    },
    delVedio (trainVedio) {
      this.currentVedioObj = trainVedio;
      this.showDelDialog = true;
    },
    doDelVedio () {
      var trainVedio = this.currentVedioObj;
      let url = "/admin/training/delvedio/" + trainVedio.id;
      this.$http.get(url).then((res) => {
        if (res.code === 200) {
          for (var i = 0; i < this.trainVedios.length; i ++) {
            if (this.trainVedios[i].id == trainVedio.id) {
              this.trainVedios.splice(i, 1);
            }
          }
          this.$message.success("删除成功。");
        }
      });
      this.showDelDialog = false;
    },
    playVedio (trainVedio) {
      this.currentVedioObj = trainVedio;
      this.showPlayDialog = true;
    },
    uploadSuccess (response) {
      if (response.code === 200) {
        let url = response.data;
        this.currentVedioObj.trainSrc = url;
      } else {
        this.$message.error("上传失败");
      }
    },
    push2Vedios (trainVedio) {
      var index = -1;
      for (var i = 0; i < this.trainVedios.length; i ++) {
        if (this.trainVedios[i].id == trainVedio.id) {
          index = i;
        }
      }
      if (index == -1) {
        this.trainVedios.push(trainVedio);
      } else {
        this.trainVedios[index] = trainVedio;
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.card.add {
  height: 175px;
}
.card .add-icon {
  text-align: center;
  text-align: center;
  font-size: 32px;
  margin-top: 42px;
}
.card .card-content {
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  margin: 0 -20px;
}
.card .card-bottom {
  margin-top: 12px;
}
.card .time {
  text-align: right;
}
</style>