<template>
  <div>
    <div class="query-container" style="padding-bottom: 20px;">
      <el-input v-model="params.driverNo" placeholder="司机编号" class="violateCommonElement"></el-input>
      <el-date-picker v-model="params.startDate" placeholder="起始日期" type="date" value-format="yyyy-MM-dd" class="violateCommonElement"></el-date-picker>-
      <el-date-picker v-model="params.endDate" placeholder="截止日期" type="date" value-format="yyyy-MM-dd" class="violateCommonElement"></el-date-picker>
      <el-select class="violateCommonElement" v-model="params.violateClassify1" clearable placeholder="违规大类">
        <el-option
                v-for="item in violateClassify1Opts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>
      <el-select class="violateCommonElement" v-model="params.violateClassify2" clearable placeholder="违规小类">
        <el-option
                v-for="item in violateClassify2Opts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="query">查询</el-button>
    </div>

    <el-radio-group v-model="params.classType" style="margin-bottom: 30px;">
      <el-radio-button label="1">按违规类别</el-radio-button>
      <el-radio-button label="4">按罚单来源</el-radio-button>
    </el-radio-group>

    <div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.totalBill }}次</div>
        <div class="label">总罚单</div>
      </div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.totalDriver }}位</div>
        <div class="label">总违规司机</div>
      </div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.totalAmount / 100 }}元</div>
        <div class="label">总罚金</div>
      </div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.totalScore }}分</div>
        <div class="label">总扣分</div>
      </div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.routePer }}%</div>
        <div class="label">行程违规率</div>
      </div>
      <div class="cnt-container">
        <div class="num">{{ cntInfo.billPer }}%</div>
        <div class="label">按单违规率</div>
      </div>
    </div>

    <div class="content">
      <chart ref="chart1" :option="option" :height="400" />
    </div>

    <div class="table">
      <div class="item">
        <chart-view
                ref="chart2"
                valueField="value"
                nameField="name"
                title=""
                initChartType="pie"
        ></chart-view>
      </div>
      <div class="item">
        <chart-view
                ref="chart3"
                valueField="value"
                nameField="name"
                title=""
                initChartType="pie"
        ></chart-view>
      </div>
      <div class="item">
        <chart-view
                ref="chart4"
                valueField="value"
                nameField="name"
                title=""
                initChartType="pie"
        ></chart-view>
      </div>
    </div>
  </div>
</template>

<script>
import ChartView from '../../Data/ChartView';
import DateUtil from "../../../util/date";
export default {
  name: "stateMx",
  components: { ChartView },
  data () {
    return {
      violateClassify1Opts: [],
      violateClassify2Opts: [],
      violateSrcOpts: [],
      params: {
        driverNo: null,
        startDate: null,
        endDate: null,
        violateClassify1: null,
        violateClassify2: null,
        classType: ""
      },
      cntInfo: {
        totalBill: 0,
        totalDriver: 0,
        totalAmount: 0,
        totalScore: 0,
        routePer: 0,
        billPer: 0,
        evidenceCnt: 0,
        confirmCnt: 0,
        appleadCnt: 0,
      },
      option: {
        legend: {
          show: true,
          bottom: 0,
        },
        xAxis: { type: 'category' },
        yAxis: {},
        tooltip: {
          show: true,
          trigger: 'axis'
        },
      }
    };
  },
  mounted () {
    this.initPage();
  },
  methods: {
    initPage () {
      this.violateClassify1Opts = window.violateClassify1Opts;
      this.violateClassify2Opts = window.violateClassify2Opts;
      this.violateSrcOpts = window.violateSrcOpts;
      this.params.startDate = DateUtil.format(DateUtil.getDay(-30), "yyyy-MM-dd");
      this.params.endDate = DateUtil.format(new Date(), "yyyy-MM-dd");
    },
    query () {
      if (!this.params.startDate) {
        this.$message.error("起始日期不能为空");
        return;
      }
      if (!this.params.endDate) {
        this.$message.error("截至日期不能为空");
        return;
      }
      if (!this.params.driverNo) {
        this.$message.error("司机编号不能为空");
        return;
      }
      let url = "/admin/driver/punishbill/overview";
      this.$http.post(url, this.params).then(res => {
        this.cntInfo = res.data.cntInfo || {};
        this.renderOrderChart(res.data.dataset);
      });
    },
    getDimensions () {
      let dims = [];
      if (this.params.classType == "1") {
        dims = dims.concat(this.violateClassify1Opts);
      } else if (this.params.classType == "4") {
        dims = dims.concat(this.violateSrcOpts);
      }
      dims.push({"id": "total", "name": "合计"});
      return dims;
    },
    getDataSet (data, dimensions) {
      let sources = [];
      let dims = [];
      let axis = [];
      for (var i = 0; i < dimensions.length; i ++) {
        let source = [];
        let day = DateUtil.str2Date(this.params.startDate);
        let lastDay = DateUtil.str2Date(this.params.endDate);
        while (day <= lastDay) {
          if (i == 0) {
            axis.push(DateUtil.format(day, "yyyy-MM-dd"));
          }
          let num = 0;
          for (var j = 0; j < data.length; j ++) {
            if (dimensions[i].id == 'total' && data[j].date == DateUtil.format(day, 'yyyy-MM-dd')) {
              num += data[j].num;
            } else if ((data[j].key == dimensions[i].id || data[j].key == dimensions[i].value) && data[j].date == DateUtil.format(day, 'yyyy-MM-dd')) {
              num = data[j].num;
              break;
            }
          }
          source.push(num);
          day = new Date(day.getTime() + 24 * 3600 * 1000);
        }
        sources.push(source);
        dims.push(dimensions[i].name || dimensions[i].label);
      }
      return {dimensions: dims, source: sources, axis: axis};
    },
    getSeries (dataset) {
      let series = [];
      for (var i = 0; i < dataset.dimensions.length; i ++) {
        series.push({
          name: dataset.dimensions[i],
          type: "line",
          data: dataset.source[i]
        });
      }
      return series;
    },
    renderOrderChart (data) {
      let dimensions = this.getDimensions();
      let dataset = this.getDataSet(data, dimensions);
      let series = this.getSeries(dataset);
      let option = {
        title: {
          text: "统计信息",
        },
        legend: {
          data: dataset.dimensions
        },
        xAxis: {type: 'category', show: true, data: dataset.axis},
        yAxis: {
          show: true
        },
        series: series,
        ...this.option
      };
      this.$refs.chart1.setOption(option, true);
      this.$refs.chart2.setData([{ name: "有证据", value: this.cntInfo.evidenceCnt }, { name: "无证据", value: this.cntInfo.totalBill - this.cntInfo.evidenceCnt }]);
      this.$refs.chart3.setData([{ name: "有申诉", value: this.cntInfo.appleadCnt }, { name: "无申诉", value: this.cntInfo.totalBill - this.cntInfo.appleadCnt }]);
      this.$refs.chart4.setData([{ name: "处罚确认", value: this.cntInfo.confirmCnt }, { name: "免责确认", value: this.cntInfo.totalBill - this.cntInfo.confirmCnt }]);
    },
  }
};
</script>

<style lang="less" scoped>
  .cnt-container {
    display: inline-block;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    width: 88px;
    height: 88px;
    margin-right: 10px;
    .num {
      margin-top: 20px;
    }
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .item {
    display: inline-block;
    width: 33%;
    height: 330px;
  }
</style>