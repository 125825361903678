<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="概况" name="ref1">
        <overview />
      </el-tab-pane>

      <el-tab-pane label="增长" name="ref2">
        <Incr />
      </el-tab-pane>

      <el-tab-pane label="行为" name="ref3">
        <behavior />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import Overview from './overview.vue';
import Incr from './Incr';
import Behavior from './Action';


export default {
  components: {
    Overview,
    Incr,
    Behavior
  },
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>