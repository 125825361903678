<template>
  <span>
    <i
      class="el-icon-warning"
      v-if="value === STATUS.NO_CALL"
      @click="setStatus"
    ></i>
    <i
      class="el-icon-success"
      v-if="value === STATUS.CALLED"
      @click="setStatus"
      style="color: green"
    ></i>
    <i
      class="el-icon-error"
      v-if="value === STATUS.CAll_FAIL"
      @click="setStatus"
      style="color: red"
    ></i>
  </span>
</template>

<script>

import {changeCallStatus} from '@/api';

const STATUS = {
  NO_CALL: 0,
  CALLED: 1,
  CAll_FAIL: 2
};
export default {
  props: {
    type: Number,
    orderId: Number
  },
  created () {
    this.value = this.type;
  },
  watch: {
    type () {
      this.value = this.type;
    }
  },
  data () {
    return {
      STATUS,
      value: STATUS.NO_CALL
    };
  },
  methods: {
    setStatus () {
      if (!this.value || this.value === STATUS.NO_CALL) {
        this.value = STATUS.CALLED;
      } else if (this.value === STATUS.CALLED) {
        this.value = STATUS.CAll_FAIL;
      } else if (this.value === STATUS.CAll_FAIL) {
        this.value = STATUS.NO_CALL;
      }

      changeCallStatus({
        orderId: this.orderId,
        status: this.value
      }).catch((err)=>{
        console.error(err);
      });
    }

  }

};
</script>

<style scoped>
i {
  font-size: 1.2em;
  margin: 0 10px;
  cursor: pointer;
}
</style>
