<template>
  <div class="root-box">
    <div class="query-container space-between">
      <div>
        <el-form size="small"
                 :inline="true">
          <el-form-item label="路线：">
            <el-select v-model="param.pathId"
                       placeholder="请选择"
                       @change="query()"
                       clearable>
              <el-option v-for="item in pathOpts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="有效区间：">
            <el-date-picker @change="query()"
                            v-model="param.range"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="有效期起"
                            end-placeholder="有效期止"></el-date-picker>
          </el-form-item>

          <el-form-item label="是否启用："
                        prop="state">
            <el-select v-model="param.state"
                       placeholder="请选择"
                       style="width: 100%"
                       @change="query()"
                       clearable>
              <el-option v-for="item in yesNoOpts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 100px; margin-right: 20px"
                       type="primary"
                       @click="query()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary"
                   :size="size"
                   @click="openAdd">新增车票模板</el-button>
      </div>
    </div>

    <div>
      <el-table :data="list"
                stripe
                @expand-change="handleRowExpand"
                row-key="id"
                :expand-row-keys="expandRow"
                max-height="680"
                row-class-name="expand"
                style="width: 99%">
        <el-table-column label="序号"
                         type="index"
                         width="50"></el-table-column>

        <el-table-column type="expand">
          <div class="expand-box">
            <el-table :data="detailList"
                      stripe
                      size="small"
                      max-height="400">
              <el-table-column prop="time"
                               label="时间"
                               width="150"></el-table-column>
              <el-table-column prop="num"
                               label="张数"
                               width="150">
                <template slot-scope="scope">
                  <el-input-number size="small"
                                   v-if="scope.row.edit"
                                   v-model="scope.row.num"></el-input-number>
                  <span v-else>{{ scope.row.num }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="remark"
                               label="备注">
                <template slot-scope="scope">
                  <el-input size="small"
                            :disabled="!scope.row.edit"
                            v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="showTicket"
                               label="是否显示"
                               width="200">
                <template slot-scope="scope">
                  <el-checkbox :disabled="!scope.row.edit"
                               v-model="scope.row.showTicket">显示</el-checkbox>
                </template>
              </el-table-column>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button v-if="!scope.row.edit"
                             type="text"
                             size="small"
                             @click="editDetail(scope.row, scope.$index)">编辑</el-button>
                  <el-button v-else
                             type="text"
                             size="small"
                             @click="saveDetail(scope.row, scope.$index)">保存</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-table-column>

        <!-- <el-table-column prop="name"
                         label="模板名称"></el-table-column> -->

        <el-table-column prop="path"
                         label="路线">
          <template slot-scope="scope">{{
            scope.row.pathId | enumTransfer(pathOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="price"
                         label="基础票价"></el-table-column>

        <el-table-column prop="goodsPrice"
                         label="货物票价"></el-table-column>

        <el-table-column prop="childrenPrice"
                         label="儿童票价"></el-table-column>

        <el-table-column prop="charterDiscount"
                         label="包车折扣"></el-table-column>

        <!-- <el-table-column prop="vehicleType" label="车型">
          <template slot-scope="scope">{{scope.row.vehicleTypeId | enumTransfer(vehicleTypeOpts,'id','content')}}</template>
        </el-table-column>-->

        <el-table-column prop="num"
                         label="车票张数"></el-table-column>

        <el-table-column prop="backupNum"
                         label="候补票张数"></el-table-column>

        <el-table-column prop="maxPrice"
                         label="最高价格"></el-table-column>

        <el-table-column prop="minPrice"
                         label="最低价格"></el-table-column>

        <el-table-column prop="maxShowNum"
                         label="最大显示票数"
                         width="150px"></el-table-column>

        <el-table-column prop="validOn"
                         label="有效期起止">

          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.validOn}}</div>
              <div>{{ scope.row.validOff}}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="state"
                         label="是否启用">
          <template slot-scope="scope">{{
            scope.row.state | enumTransfer(yesNoOpts)
          }}</template>
        </el-table-column>

        <el-table-column prop="createTime"
                         label="创建日期"
                         width="150"
                         show-overflow-tooltip></el-table-column>

        <el-table-column fixed="right"
                         prop="address"
                         width="100px"
                         label="操作">
          <template slot-scope="scope">
            <span>
              <el-button type="text"
                         v-if="!scope.row.state"
                         size="small"
                         @click="changeTemplateState(scope.row, 1)">启用</el-button>
              <el-button type="text"
                         v-if="scope.row.state"
                         size="small"
                         @click="changeTemplateState(scope.row, 0)">禁用</el-button>
              <el-button type="text"
                         size="small"
                         @click="openEdit(scope.row)">修改</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="visible"
               width="1000px"
               :title="isModify ? '修改模板' : '新增模板'"
               :close-on-click-modal="false"
               custom-class="edit-modal">
      <el-form ref="userForm"
               :model="form"
               label-width="120px"
               :size="size"
               :rules="rules"
               :inline="true">
        <el-form-item label="模板名称："
                      prop="name">
          <el-input v-model="form.name"
                    style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item label="路线："
                      prop="path">
          <el-select v-model="form.pathId"
                     placeholder="请选择"
                     style="width: 100%"
                     clearable>
            <el-option v-for="item in pathOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="默认票张数："
                      prop="num">
          <el-input-number v-model="form.num"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="候补票张数："
                      prop="num">
          <el-input-number v-model="form.backupNum"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="车型："
                      prop="vehicleType">
          <el-select v-model="form.vehicleTypeId"
                     placeholder="请选择"
                     style="width: 100%"
                     clearable>
            <el-option v-for="item in vehicleTypeOpts"
                       :key="item.id"
                       :label="item.content"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="基础票价："
                      prop="price">
          <el-input-number v-model="form.price"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="货物票价："
                      prop="goodsPrice">
          <el-input-number v-model="form.goodsPrice"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="儿童票价："
                      prop="childrenPrice">
          <el-input-number v-model="form.childrenPrice"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="包车折扣："
                      prop="charterDiscount">
          <el-input-number v-model="form.charterDiscount"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="最低价格："
                      prop="minPrice">
          <el-input-number v-model="form.minPrice"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="最高价格："
                      prop="maxPrice">
          <el-input-number v-model="form.maxPrice"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="有效期起："
                      prop="validOn">
          <el-col>
            <el-date-picker style="width: 100%"
                            placeholder="选择时间"
                            value-format="yyyy-MM-dd"
                            v-model="form.validOn"></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="有效期止："
                      prop="validOff">
          <el-date-picker style="width: 100%"
                          placeholder="选择时间"
                          value-format="yyyy-MM-dd"
                          v-model="form.validOff"></el-date-picker>
        </el-form-item>

        <el-form-item label="最大显示票数："
                      prop="maxShowNum">
          <el-input-number v-model="form.maxShowNum"
                           style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item label="是否启用："
                      prop="state">
          <el-select v-model="form.state"
                     placeholder="请选择"
                     style="width: 100%"
                     clearable>
            <el-option v-for="item in yesNoOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="danger"
                   :size="size"
                   @click="saveConfirm(true)">保存并应用于已生成车票</el-button>
        <el-button type="warning"
                   :size="size"
                   @click="saveConfirm(false)">保存</el-button>
        <el-button type="info"
                   :size="size"
                   @click="closeDiag">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTicketTemplate,
  modifyTicketTemplate,
  listTicketTemplate,
  listTicketTemplateDetail,
  modifyTicketTemplateDetail,
} from "@/api";
import { BaseDataService } from "@/service";
import { MODE } from "@/core/const/global-const";
import _ from "lodash";
import { mapState } from "vuex";
import { Toolkit } from "@/util";

export default {
  created () {
    this.query();
  },
  computed: {
    isModify () {
      return this.mode === MODE.EDIT;
    },
    ...mapState({
      size: (state) => state.btnSize,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),
  },
  data () {
    return {
      MODE,
      yesNoOpts: BaseDataService.yesNoOpts(),
      visible: false,
      form: {},
      param: {
        state: true,
      },
      list: [],
      rules: {
        path: [
          { required: true, message: "请选择路线", trigger: "blur" },
        ],
        num: [
          {
            required: true,
            message: "请输入默认票张数",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入基础票价",
            trigger: "blur",
          },
        ],
        goodsPrice: [
          {
            required: true,
            message: "请输入货物票价",
            trigger: "blur",
          },
        ],
        minPrice: [
          {
            required: true,
            message: "请输入最低票价",
            trigger: "blur",
          },
        ],
        maxPrice: [
          {
            required: true,
            message: "请输入最高票价",
            trigger: "blur",
          },
        ],
        childrenPrice: [
          {
            required: true,
            message: "请输入儿童票价",
            trigger: "blur",
          },
        ],
        charterDiscount: [
          {
            required: true,
            message: "请输入包车优惠",
            trigger: "blur",
          },
        ],
        validOn: [
          {
            required: true,
            message: "请选择有效期起",
            trigger: "blur",
          },
        ],
        validOff: [
          {
            required: true,
            message: "请选择有效期止",
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message: "请选择是否启用",
            trigger: "blur",
          },
        ],
        maxShowNum: [
          {
            required: true,
            message: "请输入最大显示票数",
            trigger: "blur",
          },
        ],
      },
      fareTypeOpts: [],
      detailList: [],
      expandRow: [],
      mode: MODE.ADD,
    };
  },
  methods: {
    closeDiag () {
      this.form = {};
      this.visible = false;
    },
    openAdd () {
      this.visible = true;
      this.mode = MODE.ADD;
      this.form = {};
    },
    openEdit (item) {
      this.visible = true;
      this.mode = MODE.EDIT;
      this.form = _.cloneDeep(item);
    },
    save (applyToCurrent) {
      const param = Toolkit.filterEmpty(this.form);
      if (this.mode === MODE.ADD) {
        addTicketTemplate(param, applyToCurrent).then(() => {
          this.$message.success("模板保存成功");
          this.visible = false;
          this.query();
        });
      } else if (this.mode === MODE.EDIT) {
        modifyTicketTemplate(param, applyToCurrent).then(() => {
          this.$message.success("模板修改成功");
          this.visible = false;
          this.query();
        });
      }
    },
    saveConfirm (applyToCurrent) {
      if (applyToCurrent) {
        this.$confirm("确认要调整现有车票价格吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.save(applyToCurrent);
        });
      } else {
        this.save(applyToCurrent);
      }
    },
    editDetail (item, index) {
      item.edit = true;
      this.$set(this.detailList, index, item);
    },
    saveDetail (item, index) {
      modifyTicketTemplateDetail([item]).then(() => {
        item.edit = false;
        this.$set(this.detailList, index, item);
        this.$message.success("修改成功");
      });
    },
    handleRowExpand (item) {
      if (this.expandRow.length && this.expandRow[0] === item.id) {
        this.expandRow = [];
      } else {
        this.expandRow = [];
        listTicketTemplateDetail({ templateId: item.id }).then(
          (res) => {
            this.detailList = (res.data || []).map((item) => {
              return {
                ...item,
                edit: false,
              };
            });
            this.expandRow = [item.id];
          }
        );
      }
    },

    query () {
      const { range, state, pathId } = this.param;
      const param = {
        state,
        pathId,
      };
      if (range) {
        param.validOn = range[0];
        param.validOff = range[1];
      }
      listTicketTemplate(Toolkit.filterEmpty(param)).then((res) => {
        this.list = res.data || [];
        this.expandRow = [];
      });
    },

    changeTemplateState (item, state) {
      const data = {
        ...item,
        state,
      };
      modifyTicketTemplate(data, false).then(() => {
        this.$message.success("操作成功");
        this.query();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.expand-box {
  padding: 10px 30px 10px 70px;
  width: 1200px;
  background: rgba(128, 132, 138, 0.2);
}

/deep/ .edit-modal {
  .el-form-item:nth-child(2n) {
    margin-left: 100px;
  }
  .el-form-item__content {
    width: 220px;
  }
}
</style>
