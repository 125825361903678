<template>
  <el-drawer
    :visible.sync="visible"
    direction="ttb"
    :with-header="false"
    custom-class="drawer-modal"
    @opened="showMap"
    :show-close="false"
    :before-close="beforeClose"
    :withHeader="false"
  >
    <div class="header">
      <span class="arrive-time" v-if="route && carRouteInfo">
        {{ route.driverNo }}距离分流点 {{ carRouteInfo.distance | mileFilter }} ，{{
          carRouteInfo.time | timeFilter
        }}，预计{{ carRouteInfo.time | dateTime }}到达
      </span>
      <span class="route-info" v-if="routeInfo">
        分流路程{{ routeInfo.distance | mileFilter }}，{{ routeInfo.time | timeFilter
        }}<span v-if="!isStart && carRouteInfo && routeInfo"
          >，预计{{ (carRouteInfo.time + routeInfo.time) | dateTime }}到达</span
        >
      </span>
    </div>

    <div id="shuntMap" class="map-b"></div>

    <div class="result setting right">
      <el-form>
        <el-form-item>
          <el-button type="primary" style="width: 48%" size="small" plain @click="addShunt(false)"
            >送人分流</el-button
          >

          <el-button type="primary" style="width: 48%" size="small" plain @click="addShunt(true)"
            >接人分流</el-button
          >
        </el-form-item>
        <el-table
          :data="shuntList"
          stripe
          size="small"
          :show-header="false"
          @row-click="showCurrent"
        >
          <el-table-column label="序号" type="index" width="20"></el-table-column>

          <el-table-column prop="isStartShunt" width="140">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>

          <el-table-column prop="id" label="操作" align="right" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.prinum" style="margin-right: 10px">
                <tel-record
                  :userTel="scope.row.passengerTel"
                  :prinum="scope.row.prinum"
                  :status="scope.row.prinumBindStatus"
                  :queryKey="`${scope.row.id}`"
                  :orderId="0"
                  :type="4"
                  :userType="1"
                  :isShuntStart="scope.row.isStartShunt"
                >
                  <el-button type="text" size="small">通话</el-button>
                </tel-record>
              </span>

              <el-button type="text" size="small" @click="editShunt(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="delShunt(scope.row)">删除</el-button>

              <el-button type="text" size="small" @click="showCurrent(scope.row)">显示</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>

    <div v-drag v-if="showShuntForm" class="shunt-container" :class="{ hideForm: hideForm }">
      <div class="shunt-header" @dblclick.stop="hideForm = !hideForm"></div>
      <div @mousedown.stop class="shunt-form" v-if="!hideForm">
        <el-form size="small" inline :rules="rules" ref="shuntForm" :model="form">
          <div class="order-list">
            <el-form-item>
              <el-input
                :disabled="form.status === 3"
                v-model="form.name"
                placeholder="输入计划名称"
              ></el-input>
            </el-form-item>
            <el-button
              :disabled="form.status === 3"
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="small"
              @click="addShuntPerson"
              >添加分流订单</el-button
            >
          </div>

          <div class="order-item-box">
            <div class="order-item" v-for="(orderId, index) in form.orderIds" :key="index">
              <el-form-item>
                <el-select
                  v-model="form.orderIds[index]"
                  size="small"
                  style="width: 280px"
                  :disabled="form.status === 3"
                >
                  <el-option
                    v-for="item in chooseOrders"
                    :key="item.id"
                    :label="`${item.orderIndex + 1}号单-${item.address}`"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-button
                v-if="form.orderIds.length > 1"
                type="text"
                plain
                icon="el-icon-remove-outline"
                size="small"
                @click="minusShuntPerson(index)"
                :disabled="form.status === 3"
                >去除</el-button
              >

              <el-button type="text" plain size="small" @click="genUrlLink(true, orderId)"
                >链接</el-button
              >

              <el-button type="text" plain size="small" @click="sendMsg(orderId)"
                >发送短信</el-button
              >

              <span
                style="font-size: 12px; margin-left: 4px"
                v-if="form.sendMsgOrderIds && form.sendMsgOrderIds.includes(orderId)"
                >已发送</span
              >
            </div>
          </div>

          <el-form-item>
            <el-autocomplete
              style="width: 350px"
              class="inline-input"
              v-model="form.shuntAddress"
              value-key="name"
              :fetch-suggestions="querySearch"
              placeholder="请选择或输入分流点"
              :trigger-on-focus="true"
              @select="handleSelect"
              clearable
              :disabled="form.status === 3"
            ></el-autocomplete>

            <el-button
              icon="el-icon-map-location"
              @click="triggerShuntMarkers"
              style="width: 40px; margin-left: 8px"
            >
            </el-button>

            <el-button type="text" plain size="small" @click="genUrlLink(false)">链接</el-button>
          </el-form-item>

          <el-form-item prop="callTel">
            <el-select
              v-model="form.callTel"
              :disabled="form.status === 3"
              style="width: 240px"
              placeholder="代叫人手机尾号"
              filterable
              allow-create
              default-first-option
            >
              <el-option v-for="item in callTels" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="form.carPlatform"
              size="small"
              placeholder="分流平台"
              style="width: 150px"
              clearable
              @change="handlePlatformChange"
              :disabled="form.status === 3"
            >
              <el-option v-for="item in carPlatformOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="form.vehicleType"
              size="small"
              placeholder="车型"
              style="width: 100px"
              clearable
              :disabled="form.status === 3"
            >
              <el-option v-for="item in vehicleTypeOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="form.vehicleColor"
              size="small"
              placeholder="车辆颜色"
              style="width: 100px"
              filterable
              allow-create
              clearable
              :disabled="form.status === 3"
            >
              <el-option v-for="item in vehicleColorOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="form.vehicleBrand"
              style="width: 180px"
              placeholder="车辆品牌"
              filterable
              allow-create
              default-first-option
            >
              <el-option v-for="item in vehicleBrandOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="form.vehicleNo"
              style="width: 130px"
              :disabled="form.status === 3"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="form.vehicleDriver"
              style="width: 80px"
              :disabled="form.status === 3"
            ></el-input>
            师傅
          </el-form-item>

          <el-form-item prop="driverTel">
            <el-input
              v-model="form.driverTel"
              placeholder="手机号"
              style="width: 140px"
              :disabled="form.status === 3"
            ></el-input>
          </el-form-item>

          <el-form-item>
            分流时间：
            <el-select v-model="form.hour" style="width: 120px" placeholder="小时">
              <el-option v-for="item in hourOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            :
            <el-select v-model="form.minute" style="width: 120px" placeholder="分钟">
              <el-option v-for="item in minuteOpts" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            分流价格：
            <el-input-number
              style="width: 130px"
              v-model="form.prePrice"
              placeholder="预估价格"
              :disabled="form.status === 3"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item>
            <el-input-number
              style="width: 140px"
              v-model="form.actPrice"
              placeholder="实际价格"
              :disabled="form.status === 3"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item>
            <el-input v-model="form.remark" placeholder="备注" style="width: 400px"></el-input>
          </el-form-item>

          <div class="footer">
            <el-form-item>
              <el-button type="" @click="showShuntForm = false">关闭</el-button>
              <el-button
                v-if="form.status !== 3"
                :type="form.status === 1 ? 'warning' : 'primary'"
                @click="saveShunt(1)"
                >{{ form.status === 1 ? "修改分流" : "确认分流" }}</el-button
              >
              <el-button type="success" v-if="form.status === 1" @click="saveShunt(3)"
                >确认费用</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { DateUtil } from "@/util";
import {
  getDriverLocation,
  getDriverInfo,
  saveRouteShunt,
  getRouteShunt,
  sortShuntOrder,
  delShuntOrder,
  listRecommendAddress,
  sendShuntMsg,
  genLocationUrlLink,
} from "@/api";
import { BaseDataService } from "@/service";
import TelRecord from "./TelCell/TelRecord";
import _ from "lodash";
import { mapState } from "vuex";

const markB = require("../assets/image/mark_b.png");
const markR = require("../assets/image/mark_r.png");
const markY = require("../assets/image/mark_y.png");
const carPng = require("../assets/image/car_blue.png");

export default {
  components: {
    TelRecord,
  },
  filters: {
    dateTime(val = 0) {
      const now = new Date().getTime();
      const thatTime = new Date(now + val * 1000);
      return DateUtil.format(thatTime, "yyyy-MM-dd hh:mm:ss");
    },
  },
  computed: {
    ...mapState({
      pathMaps: (state) => {
        const pathList = state.pathList;
        return _.keyBy(pathList, "id");
      },
    }),

    chooseOrders() {
      const items = this.orders.map((item) => {
        const add = this.isStart ? item.departure : item.destination;
        return {
          id: item.id,
          address: add,
          orderIndex: item.orderIndex,
          lat: this.isStart ? item.depLatitude : item.destLatitude,
          lng: this.isStart ? item.depLongitude : item.destLongitude,
        };
      });
      return items;
    },

    latlng() {
      if (this.form) {
        const { shuntLat, shuntLng, orderIds = [] } = this.form;
        const ids = [...orderIds].sort().toString();
        console.log(ids);
        return `${shuntLat}-${shuntLng}-${ids}`;
      }
      return null;
    },
  },

  created() {
    const autoOptions = {
      city: "027" || "全国", // city 限定城市，默认全国
      citylimit: false,
    };
    window.AMap.plugin(["AMap.AutoComplete", "AMap.Geocoder", "AMap.Driving"], () => {
      this.autoComplete = new AMap.AutoComplete(autoOptions);
      this.geocoder = new window.AMap.Geocoder();
    });
  },

  watch: {
    latlng(val) {
      if (val) {
        this.sortOrders();
      } else {
        this.clearShunt();
      }
    },
  },

  data() {
    return {
      visible: false,
      showShuntForm: false,
      isStart: null,
      orders: [],
      selectPassengers: [],
      routeInfo: null,
      carRouteInfo: null,
      orderIndex: [],
      isOngoing: false,
      sortMode: false,
      shuntList: [],
      form: null,
      route: null,
      rules: {
        driverTel: [{ pattern: /^1\d{10}$/, message: "请输入正确的手机号" }],
        callTel: [{ pattern: /^1\d{10}$/, message: "请输入正确的手机号" }],
      },
      vehicleTypeOpts: ["快车", "专车"],
      carPlatformOpts: ["滴滴", "高德", "城小班", "出租车"],
      vehicleColorOpts: BaseDataService.vehicleColorOpts(),
      vehicleBrandOpts: [
        "东风",
        "比亚迪",
        "别克",
        "吉利",
        "丰田",
        "出租车",
        "广汽",
        "雪铁龙",
        "哪吒",
        "国金",
        "启辰",
        "枫叶",
      ],
      hourOpts: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minuteOpts: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
      hideForm: false,
      shuntPointList: [],
      callTels: [
        "10000008185",
        "10000008768",
        "10000006274",
        "10000003314",
        "10000006552",
        "10000001670",
      ],
      showShuntPoints: false,
      shuntMarkers: [],
    };
  },
  methods: {
    show(route, orders) {
      this.route = route;
      this.orders = orders;
      this.visible = true;
    },

    genUrlLink(isOrder, orderId) {
      let lat, lng;
      if (isOrder) {
        const order = this.orders.find((t) => t.id === orderId);
        const { depLongitude, depLatitude, destLongitude, destLatitude } = order || {};
        lat = this.isStart ? depLatitude : destLatitude;
        lng = this.isStart ? depLongitude : destLongitude;
      } else {
        lat = this.form.shuntLat;
        lng = this.form.shuntLng;
      }

      if (!lat || !lng) {
        this.$message.error("没有位置信息");
        return;
      }

      genLocationUrlLink(lat, lng).then((res) => {
        this.$alert(`${res.data}`, "微信链接", {
          confirmButtonText: "确定",
          type: "success",
        });
      });
    },

    triggerShuntMarkers() {
      this.map.remove(this.shuntMarkers);
      this.showShuntPoints = !this.showShuntPoints;
      if (!this.showShuntPoints) {
        this.shuntMarkers = [];
        return;
      }
      const { pathId } = this.route || {};
      const routePath = this.pathMaps[pathId];
      const param = {
        areaId: this.isStart ? routePath.startArea : routePath.endArea,
        type: 5,
        _hideLoading: true,
      };

      listRecommendAddress(param).then((res) => {
        const list = res.data || [];
        list.forEach((item) => {
          const lat = +item.latitude;
          const lng = +item.longitude;
          const marker = new AMap.Marker({
            position: [lng, lat],
            draggable: false,
            raiseOnDrag: false,
            label: {
              content: "<div class='info'>" + item.name + "</div>",
              direction: "top",
            },
            icon: new AMap.Icon({
              image: markY,
            }),
            extData: item,
          });
          // 分流点拖动反向查询地址
          marker.on("dblclick", () => {
            const { name, latitude, longitude } = marker.getExtData();
            this.$set(this.form, "shuntLat", +latitude);
            this.$set(this.form, "shuntLng", +longitude);
            this.$set(this.form, "shuntAddress", name);
            this.createShuntMarker([+longitude, +latitude]);
            this.map.remove(this.shuntMarkers);
            this.showShuntPoints = false;
            this.shuntMarkers = [];
          });

          this.shuntMarkers.push(marker);
        });

        this.map.add(this.shuntMarkers);
      });
    },

    queryShuntList() {
      getRouteShunt(this.route.id).then((res) => {
        this.shuntList = res.data || [];
        if (!this.form) {
          if (this.shuntList.length > 0) {
            const item = this.shuntList[0];
            const _item = _.cloneDeep(item);
            if (_item.bookTime) {
              _item.hour = _item.bookTime.split(":")[0];
              _item.minute = _item.bookTime.split(":")[1];
            }
            this.form = _item;
            this.renderPlan(_item);
          } else {
            this.isStart = null;
            this.map.clearMap();
            this.showOrderMarkers();
            this.showDriverLocation();
            this.map.setFitView();
          }
        }
      });
    },

    renderPlan(data) {
      if (!(data.orderIds && data.orderIds.length && data.shuntLng && data.shuntLat)) {
        return;
      }

      this.map.clearMap();
      this.driving && this.driving.clear();
      this.isStart = data.isStartShunt;

      const shuntPoint = [data.shuntLng, data.shuntLat];

      // 显示订单点
      this.showOrderMarkers();
      // 显示司机位置
      this.showDriverLocation(shuntPoint);
      // 显示分流点
      this.createShuntMarker(shuntPoint);

      // 绘制规划路线
      const { orderIds = [] } = data;
      if (orderIds.length < 1) {
        return;
      }

      let destLocation,
        depLocation,
        waypoints = [];
      if (this.isStart) {
        destLocation = shuntPoint;
      } else {
        depLocation = shuntPoint;
      }

      for (let index = 0; index < orderIds.length; index++) {
        const id = orderIds[index];
        const order = this.orders.find((t) => t.id === id);
        const lat = data.isStartShunt ? order.depLatitude : order.destLatitude;
        const lng = data.isStartShunt ? order.depLongitude : order.destLongitude;
        // 接人起点是同一个
        if (this.isStart) {
          depLocation = [lng, lat];
        } else {
          if (index === orderIds.length - 1) {
            destLocation = [lng, lat];
          } else {
            waypoints.push([lng, lat]);
          }
        }
      }

      const driving = new AMap.Driving({
        // 驾车路线规划策略，考虑实时路况
        policy: AMap.DrivingPolicy.REAL_TRAFFIC,
        autoFitView: false,
        hideMarkers: false,
        map: this.map,
      });
      driving.search(depLocation, destLocation, { waypoints, map: this.map }, (status, result) => {
        if (status === "complete") {
          this.routeInfo = result.routes[0];
        }
      });

      this.driving = driving;
      this.map.setFitView();
    },

    clearShunt() {
      if (this.driving) {
        this.driving.clear();
      }
      if (this.shuntMarker) {
        this.map.remove(this.shuntMarker);
      }
    },

    sortOrders() {
      const { id, driverId } = this.route || {};

      const paramOrderIds = this.form.orderIds || [];
      // 是否新新增分流点（有空字符串表示有新增），如果是新增，则不用计算排序
      const isAdd = paramOrderIds.find((item) => !item);
      if (isAdd !== undefined || !this.form.shuntLat) {
        return;
      }
      // 分流订单排序
      const param = {
        ...this.form,
        routeId: id,
        driverId,
      };
      sortShuntOrder(param).then((res) => {
        const { orderIds } = res.data || {};
        if (orderIds && orderIds.length) {
          this.$set(this.form, "orderIds", orderIds);
          this.renderPlan(res.data);
        }
      });
    },

    showDriverLocation(shuntPoint) {
      const map = this.map;
      const driverId = this.route.driverId;
      getDriverLocation(driverId).then((res) => {
        const { driver, location = {} } = res.data || {};
        const { lat, lng, gpsTime = "", vendor } = location || {};
        const time = gpsTime.substr(5);
        const locationSource = vendor === "jimi" ? "GPS" : "手机";
        if (lat && lng) {
          const position = [lng, lat];
          const carMarker = new AMap.Marker({
            position,
            draggable: true,
            raiseOnDrag: false,
            label: {
              //修改label相对于maker的位置
              direction: "top",
              content:
                "<div class='info'>" + `${driver.no} | ${time} | ${locationSource}` + "</div>",
            },
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), //图标大小
              image: carPng,
              imageSize: new AMap.Size(40, 40),
            }),
          });

          if (this.carMarker) {
            map.remove(this.carMarker);
          }

          this.carMarker = carMarker;
          map.add(carMarker);

          // 计算到分流点距离
          if (shuntPoint && shuntPoint[0]) {
            const driving = new AMap.Driving({
              // 驾车路线规划策略，考虑实时路况
              policy: AMap.DrivingPolicy.REAL_TRAFFIC,
            });
            driving.search(position, shuntPoint, {}, (status, result) => {
              if (status === "complete") {
                this.carRouteInfo = result.routes[0];
              }
            });
          }
        }
      });
    },

    querySearch(key, cb) {
      if (!key) {
        cb(this.shuntPointList);
      } else {
        this.autoComplete.search(key, (status, result) => {
          if (status === "complete") {
            let res = result.tips || [];
            cb(this.transMapData(res));
          }
        });
      }
    },

    handlePlatformChange(item) {
      if (item === "自分流") {
        this.$prompt("请输入分流司机编号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          getDriverInfo({
            no: value,
            status: 1,
            _hideLoading: true,
          }).then((res) => {
            if (!res.data) {
              this.$message.error("不存在的司机");
              return;
            }
            const info = res.data;
            this.$set(this.form, "vehicleDriver", info.name.substr(0, 1));
            this.$set(this.form, "vehicleColor", info.vehicleColor);
            this.$set(this.form, "vehicleBrand", info.brand);
            this.$set(this.form, "driverTel", info.telephone);
            this.$set(this.form, "vehicleNo", info.vehicleNo);
          });
        });
      }
    },

    handleSelect(item) {
      this.$set(this.form, "shuntLat", item.lat);
      this.$set(this.form, "shuntLng", item.lng);
      this.createShuntMarker([item.lng, item.lat]);
    },

    createShuntMarker(latlng) {
      if (this.shuntMarker) {
        this.map.remove(this.shuntMarker);
      }
      this.shuntMarker = new AMap.Marker({
        position: latlng,
        draggable: true,
        raiseOnDrag: false,
        label: {
          content: "<div class='info'>分流点</div>",
          direction: "top",
        },
        icon: new AMap.Icon({
          image: markR,
        }),
      });

      // 分流点拖动反向查询地址
      this.shuntMarker.on("dragend", (e) => {
        const { lat, lng } = e.lnglat;
        this.$set(this.form, "shuntLat", lat);
        this.$set(this.form, "shuntLng", lng);
        this.geocoder.getAddress([lng, lat], (status, result) => {
          if (status === "complete" && result.regeocode) {
            const address = result.regeocode.formattedAddress;
            this.$set(this.form, "shuntAddress", address);
          } else {
            console.error("根据经纬度查询地址失败");
          }
        });
      });

      this.shuntMarker.on("rightclick", () => {
        setTimeout(() => {
          this.$confirm("确认更新分流点吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const status = this.form.status ? this.form.status : 0;
            this.saveShunt(status);
          });
        }, 0);
      });
      this.map.add(this.shuntMarker);
    },

    delShunt(item) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delShuntOrder(item).then(() => {
          this.form = null;
          this.$message.success("操作成功");
          this.queryShuntList();
        });
      });
    },

    transMapData(data = []) {
      const list = [];
      data.forEach((item) => {
        if (item.location && item.location.lng) {
          list.push({
            name: `${item.district}${item.name}`,
            lng: `${item.location.lng}`,
            lat: `${item.location.lat}`,
          });
        }
      });
      return list;
    },

    addShunt(isStartShunt) {
      this.isStart = isStartShunt;
      const { id } = this.route || {};
      this.queryShuntPoints();
      this.form = {
        orderIds: [""],
        name: this.isStart ? "接人分流" : "送人分流",
        passengerTel: "",
        vehicleNo: "鄂A",
        isStartShunt,
        routeId: id,
        carPlatform: "高德",
        vehicleType: "专车",
      };
      this.showShuntForm = true;
    },

    queryShuntPoints() {
      const { pathId } = this.route || {};
      const routePath = this.pathMaps[pathId];
      const param = {
        areaId: this.isStart ? routePath.startArea : routePath.endArea,
        type: 5,
        _hideLoading: true,
      };
      listRecommendAddress(param).then((res) => {
        this.shuntPointList = (res.data || []).map((item) => {
          return {
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
          };
        });
      });
    },

    editShunt(item) {
      this.form = _.cloneDeep(item);
      this.isStart = this.form.isStartShunt;
      this.queryShuntPoints();
      this.showShuntForm = true;
    },

    showCurrent(item) {
      const _item = _.cloneDeep(item);
      if (_item.bookTime) {
        _item.hour = _item.bookTime.split(":")[0];
        _item.minute = _item.bookTime.split(":")[1];
      }
      this.form = _item;
    },

    addShuntPerson() {
      if (this.form.isStartShunt) {
        this.$message.warning("接人分流请确保多人同一地点上车");
      }
      if (this.form.orderIds) {
        if (this.form.orderIds.length < this.orders.length) {
          this.form.orderIds.push("");
        }

        console.table(this.form.orderIds);
      } else {
        this.$set(this.form, "orderIds", [""]);
      }
    },

    minusShuntPerson(index) {
      this.form.orderIds.splice(index, 1);
    },

    saveShunt(status) {
      const param = { ...this.form, status };
      if (param.hour && param.minute) {
        param.bookTime = param.hour + ":" + param.minute;
      }
      if (status == 1) {
        this.$refs.shuntForm.validate((valid) => {
          if (valid) {
            saveRouteShunt(param).then((res) => {
              this.form = res.data;
              this.queryShuntList();
              this.showShuntForm = false;
              this.$message.success("操作成功");
            });
          }
        });
      } else {
        saveRouteShunt(param).then((res) => {
          this.form = res.data;
          this.queryShuntList();
          this.showShuntForm = false;
          this.$message.success("操作成功");
        });
      }
    },

    showMap() {
      const map = new AMap.Map("shuntMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });
      this.map = map;
      this.queryShuntList();
    },

    showOrderMarkers() {
      const orders = this.orders;
      let markers = [];
      // 显示订单点
      orders.forEach((item, index) => {
        const markerArr = this.createPassengerBaseInfoMarker(item, index);
        markers = markers.concat(markerArr);
      });

      this.markers = markers;
      this.map.add(markers);
    },

    createPassengerBaseInfoMarker(order, index) {
      const {
        depLongitude,
        depLatitude,
        departure,
        destination,
        destLongitude,
        destLatitude,
        showTime,
        telephone,
      } = order || {};
      const subTel = "尾号" + telephone.substr(7);
      const clazz = "info  pointer";

      // 获取行程状态
      const routeStatus = order.routeStatus;
      const routeDetailOpts = this.$store.state.enumMap["ROUTE_DETAIL_STATUS"];
      const routeStr = this.$options.filters.enumTransfer(routeStatus, routeDetailOpts);

      const smarker = new AMap.Marker({
        label: {
          content: `<div class="${clazz}"><div>${order.orderIndex + 1}、 ${showTime} （${
            order.number
          }人） ${subTel}</div><div>${departure} （${routeStr}）</div></div>`,
          direction: "top",
        },
        icon: new AMap.Icon({
          image: markB,
        }),
        position: [depLongitude, depLatitude],
        clickable: true,
        draggable: false,
        raiseOnDrag: false,
        extData: index,
      });
      const emarker = new AMap.Marker({
        label: {
          content: `<div class="${clazz}"><div>${order.orderIndex + 1}、 ${showTime} （${
            order.number
          }人） ${subTel}</div><div>${destination} （${routeStr}）</div></div>`,
          direction: "top",
        },
        icon: new AMap.Icon({
          image: markB,
        }),
        position: [destLongitude, destLatitude],
        clickable: true,
        draggable: false,
        raiseOnDrag: false,
        extData: index,
      });

      if (this.isStart == null) {
        return [smarker, emarker];
      } else if (this.isStart === true) {
        return [smarker];
      } else {
        return [emarker];
      }
    },

    beforeClose(done) {
      if (this.showShuntForm) {
        return;
      }

      if (this.map) {
        this.map.clearMap();
      }
      this.form = null;
      this.isStart = null;
      this.hideForm = false;
      done && done();
    },

    sendMsg(orderId) {
      if (this.form.id) {
        sendShuntMsg({ shuntId: this.form.id, orderId }).then((res) => {
          this.form = res.data;
          this.$message.success("发送成功");
        });
      } else {
        this.$message.warning("确认分流后才可以发送短信");
      }
    },

    showGaodePage(orderId) {
      if (!orderId) {
        this.$message.error("还未选择分流订单");
        return;
      }
      const { shuntLat, shuntLng, shuntAddress } = this.form || {};
      const order = this.chooseOrders.filter((t) => t.id === orderId)[0];
      const { lat, lng, address } = order || {};
      if (!shuntLat || !shuntLng) {
        this.$message.error("还未指定分流地点");
        return;
      }

      const url = this.isStart
        ? this.genGaodeUrl(lat, lng, address, shuntLat, shuntLng, shuntAddress)
        : this.genGaodeUrl(shuntLat, shuntLng, shuntAddress, lat, lng, address);
      window.open(url, "_blank");
    },

    genGaodeUrl(slat, slng, sname, dlat, dlng, dname) {
      return `https://ditu.amap.com/dir?from[name]=${sname}&from[lnglat]=${slng},${slat}&to[name]=${dname}&to[lnglat]=${dlng},${dlat}&type=car&policy=1`;
    },
  },
};
</script>

<style scoped lang="less">
.shunt-container {
  position: absolute;
  width: 500px;
  right: 0;
  bottom: 20px;
  z-index: 9999;
  border-radius: 5px;
  background-color: white;
  box-shadow: 3px 3px 8px #b5b0b0;
  max-height: 690px;
  user-select: none;
  &.hideForm {
    height: 50px;
  }
  .shunt-header {
    background: rgb(0, 122, 255);
    height: 50px;
    border-radius: 5px 5px 0 0;
  }

  .shunt-form {
    padding: 20px 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 640px;
    box-sizing: border-box;
    user-select: none;

    .order-item-box {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: 10px 0 0;
      margin-bottom: 20px;
    }

    .footer {
      margin-top: 30px;
      height: 50px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
/deep/ .drawer-modal {
  width: 100% !important;
  height: 90vh !important;
  position: relative;
}

.info {
  font-size: 18px;
  font-weight: bold;
  color: #ff5722;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  min-height: 200px;
  max-height: 90%;
  overflow-y: auto;
  top: 60px;
  right: 0;
  width: 350px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  &.setting {
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    min-height: auto;
  }

  &.right {
    right: 0;
    left: auto;
  }

  &.left {
    left: 0;
    right: auto;
  }

  .el-checkbox {
    color: #fff;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

#shuntMap {
  width: 100%;
  height: 85vh;
}

.header {
  margin: 0 10px;
  margin: 0 20px;
  height: 5vh;
  display: block;
  line-height: 5vh;
  text-align: center;

  .arrive-time {
    color: #ff5722;
    font-weight: bold;
    font-size: 16px;
  }

  .route-info {
    color: black;
    margin-left: 10px;
  }
}
</style>
