<template>
  <div class="query-container">
    <el-form size="small" :inline="true">
      <el-form-item label="时间">
        <el-date-picker
          style="width: 160px"
          value-format="yyyy-MM-dd"
          v-model="param.startDate"
          type="date"
          placeholder="选择开始日期"
          @change="query"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          style="width: 160px"
          value-format="yyyy-MM-dd"
          v-model="param.endDate"
          type="date"
          placeholder="选择结束日期"
          @change="query"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="路线" prop="pathId">
        <el-select
          v-model="param.pathIds"
          style="width: 200px"
          placeholder="请选择"
          clearable
          multiple
          @change="query"
        >
          <el-option
            v-for="item in pathOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="注册渠道" prop="regTypes">
        <el-select
          v-model="param.regTypes"
          style="width: 200px"
          placeholder="请选择"
          clearable
          multiple
          @change="query"
        >
          <el-option
            v-for="item in registerChannelOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="客户端" prop="orderSources">
        <el-select
          v-model="param.orderSources"
          style="width: 200px"
          placeholder="请选择"
          clearable
          multiple
          @change="query"
        >
          <el-option
            v-for="item in orderSourceOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="周期">
        <el-radio-group v-model="param.period" @change="query">
          <el-radio-button label="day">日</el-radio-button>
          <el-radio-button label="week">周</el-radio-button>
          <el-radio-button label="month">月</el-radio-button>
          <el-radio-button label="year">年</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="showType">
        <el-radio-group v-model="param.countType" @change="query">
          <el-radio-button label="amount">总额</el-radio-button>
          <el-radio-button label="order">单数</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { DateUtil } from '@/util';
import {mapState} from 'vuex';

export default {
  components: {},
  props: {
    queryFun: Function,
    showType: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  mounted () {},
  watch: {},
  computed: {
    ...mapState({
      size: state => state.btnSize,
      registerChannelOpts: state => state.enumMap['REG_CHANNEL'],
      pathOpts: state =>{
        const pathList = state.pathList;
        return pathList.map(item=>{
          return {
            label: item.name,
            value: item.id
          };
        });
      },
    })
  },
  data () {
    return {
      param: {
        period: 'day',
        countType: 'amount',
        startDate: DateUtil.getDayStr(-30),
        endDate: DateUtil.getDayStr(0),
      },
      orderSourceOpts: [{label: 'APP', value: 4}, {label: '小程序', value: 3}]
    };
  },
  methods: {
    query () {
      this.queryFun(this.param);
    },

    getParam () {
      return this.param;
    }
  },
};
</script>
<style lang="less" scoped>
</style>