<template>
  <div style="padding: 24px">
    <el-row class="query">
      <el-date-picker
        type="month"
        @change="query"
        v-model="monthDate"
        :clearable="false"
        placeholder="选择日期"
      >
      </el-date-picker>

      <el-button @click.native="query" style="margin-left: 8px">查询</el-button>
    </el-row>

    <el-row class="total-info">
      <el-col :span="4">
        <el-card shadow="always" class="cnt-card">
          累计请假{{ cntInfo.nums }}人/{{ cntInfo.times }}次
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always" class="cnt-card"> 累计请假{{ cntInfo.days }}天 </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always" class="cnt-card">
          临时事假{{ cntInfo.specNums }}人/{{ cntInfo.specTimes }}次
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always" class="cnt-card"> 累计超假{{ cntInfo.exceeds }}天 </el-card>
      </el-col>
      <el-col :span="8"> </el-col>
    </el-row>

    <el-table style="width: 1040px" :data="infos" @expand-change="view">
      <el-table-column sortable prop="carNo" label="班号" width="80"></el-table-column>
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="team" label="车队" width="100"></el-table-column>
      <el-table-column sortable prop="times" label="已请假次数" width="120"></el-table-column>
      <el-table-column sortable prop="days" label="已请假天数" width="120"></el-table-column>
      <el-table-column sortable prop="exceeds" label="超假天数" width="120"></el-table-column>
      <el-table-column sortable prop="emergdays" label="临时假" width="120"></el-table-column>
      <el-table-column sortable prop="comdays" label="普通假" width="120"></el-table-column>
      <el-table-column label="查看详情" type="expand" width="180">
        <template slot-scope="scope">
          <el-row
            style="background-color: #f2f3f4; padding: 10px; border-bottom: 1px solid #ebeef5"
            v-for="(child, i) in scope.row.children"
            :key="i"
          >
            <el-col :span="5"
              ><span style="margin-right: 16px">{{ i + 1 }}</span
              >{{
                child.leaveCauseName +
                "-" +
                (child.confirmDate ? (child.realDays == 0 ? "已取消" : "已销假") : "请假中")
              }}</el-col
            >
            <el-col :span="5">{{
              child.sdate + "至" + (child.confirmDate ? child.confirmDate.substring(0, 10) : "")
            }}</el-col>
            <el-col :span="2">实休{{ child.realDays }}天</el-col>

            <el-col :span="3">{{ child.confirmDate | dateFormat("hh:mm") }} 销假 </el-col>

            <el-col
              :span="4"
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >{{ child.causeBz }}</el-col
            >

            <el-col :span="2" v-if="child.leaveType === 'EMERGENCY'"
              >{{ child.agree === 1 ? "同意" : child == 0 ? "驳回" : "未审核" }} 销假
            </el-col>

            <el-col :span="2" v-html="child.attach"></el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { DateUtil, ParamUtil } from "@/util/index";
export default {
  name: "LeaveSum",
  props: {
    team: {
      type: Number,
    },
  },
  data() {
    return {
      monthDate: new Date(),
      cntInfo: { nums: 0, times: 0, days: 0, exceeds: 0, specNums: 0, specTimes: 0 },
      infos: [],
    };
  },
  methods: {
    view(row) {
      if (!row.children || !row.children.length) {
        let param = {
          sdate: DateUtil.format(this.monthDate, "yyyy-MM-dd"),
          driverId: row.driver_id,
        };
        this.$http
          .post("/admin/driver/leave/detail", ParamUtil.filterEmptyData(param))
          .then((res) => {
            let children = res.data || [];
            children.forEach((c) => {
              if (c.imgs) {
                let imgList = c.imgs.split(",");
                let hrefs = "";
                let i = 1;
                imgList.forEach((img) => {
                  hrefs += "<a href='" + img + "' target='_blank'>附件" + i++ + "</a>";
                  if (i <= imgList.length) {
                    hrefs += "&nbsp;&nbsp;";
                  }
                });
                c.attach = hrefs;
              } else {
                c.attach = "";
              }
            });
            row.children = children;
          });
      }
    },
    query() {
      let param = {
        teamId: this.team ? this.team : null,
        month: this.monthDate.getMonth() + 1,
        year: this.monthDate.getFullYear(),
      };
      this.$http.post("/admin/driver/leave/info", ParamUtil.filterEmptyData(param)).then((res) => {
        let infos = (res.data && res.data.infos) || [];
        infos.forEach((info) => {
          info.children = [];
        });
        this.infos = infos;
        if (res.data && res.data.cntInfo) {
          this.cntInfo = res.data.cntInfo;
        }
      });
    },
  },
};
</script>

<style scoped>
.total-info {
  margin: 24px 0px;
}

.cnt-card {
  width: 240px;
  height: 80px;
  line-height: 40px;
  text-align: center;
}
</style>
