<template>
  <div class="root-box">
    <div class="query-container space-between">
      <el-form size="small" :inline="true">
        <el-form-item label="查询时间：">
          <el-date-picker @change="query(1)" value-format="yyyy-MM-dd" v-model="param.startTime" type="date"
            placeholder="选择日期"></el-date-picker></el-form-item>

        <el-form-item>
          <el-date-picker @change="query(1)" value-format="yyyy-MM-dd" v-model="param.endTime" type="date"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-input v-model="param.driverNo" placeholder="司机编号" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-select v-model="param.status" @change="query(1)" clearable>
            <el-option v-for="item in talkStatusOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :data="list" stripe max-height="650" size="small">
        <el-table-column type="expand" show-overflow-tooltip>
          <template slot-scope="props">
            <div style="padding: 20px 160px 20px 80px">
              <el-table :data="props.row.talkRecordList" :show-header="false" :row-class-name="tableRowClassName">
                <el-table-column prop="talkerName" width="120">
                  <template slot-scope="scope">
                    <div class="content">
                      <div class="user">
                        {{ scope.row.talkDirect ? "司机" : "客服" }} ({{ scope.row.talkerName }})：
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column prop="annexes" label="附件" width="300">
                  <template slot-scope="scope" v-if="scope.row.annexes">
                    <span v-for="(item, index) in scope.row.annexes" :key="item">
                      <el-image v-if="isImage(item)" style="width: 80px; height: 80px" :src="item" fit="contain"
                        :preview-src-list="scope.row.annexes" :z-index="9999"></el-image>
                      <a v-else class="annex" :href="item" target="_blank">附件{{ item | fileNameTransfer(index + 1)
                        }}</a>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="时间" width="160"></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

        <el-table-column prop="content" label="司机">
          <template slot-scope="scope">
            <div>{{ scope.row.talkSession.driverName }}</div>
            <div>{{ scope.row.talkSession.driverNo }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="punishName" label="申诉类型">
          <template slot-scope="scope">
            {{ scope.row.talkSession.punishName }}
          </template>
        </el-table-column>

        <el-table-column prop="evaType" label="申诉订单">
          <template slot-scope="scope">
            <span>{{ scope.row.talkSession.orderDesc }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="content" label="申诉内容" width="300">
          <template slot-scope="scope">
            {{ scope.row.talkSession.content }}
          </template>
        </el-table-column>

        <el-table-column prop="annexes" label="附件" width="200">
          <template slot-scope="scope" v-if="scope.row.talkSession.annexes">
            <span v-for="(item, index) in scope.row.talkSession.annexes" :key="item">
              <el-image v-if="isImage(item)" style="width: 80px; height: 80px" :src="item" fit="contain"
                :preview-src-list="scope.row.talkSession.annexes" :z-index="9999"></el-image>
              <a v-else class="annex" :href="item" target="_blank">{{
                item | fileNameTransfer(index + 1)
              }}</a>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="申诉订单日期">
          <template slot-scope="scope">
            {{ scope.row.talkSession.date }}
          </template>
        </el-table-column>

        <el-table-column prop="status" label="申诉结果">
          <template slot-scope="scope">
            {{ scope.row.talkSession.status | enumTransfer(talkStatusOpts) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="提交/更新时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.talkSession.updateTime || scope.row.talkSession.createTime }}
          </template>
        </el-table-column>

        <el-table-column prop="date" label="行程详情" width="120">
          <template slot-scope="scope">
            <div class="spe-box">
              <!-- <div>
                <el-button type="text" size="small" @click="goRouteDetail(scope.row.talkSession.routeId)">行程详情</el-button>
              </div>
              <div>
                <el-button type="text" size="small" @click="goRouteReplay(scope.row.talkSession)">行程回放</el-button>
              </div> -->
              <div>
                <el-button type="text" size="small"
                  @click="showPunishBill(scope.row.talkSession)"><span>关联罚单</span></el-button>
              </div>
              <div v-if="scope.row.talkSession.orderId">
                <el-button type="text" size="small"><span
                    v-goRouteDetail="scope.row.talkSession.orderId">订单详情</span></el-button>
              </div>

              <div>
                <el-button type="text" size="small" @click="showRoutePlan(scope.row.talkSession)">行程规划</el-button>
              </div>

              <div>
                <el-button type="text" size="small" @click="showRouteList(scope.row.talkSession)">行程详情</el-button>
              </div>

              <div>
                <el-button type="text" size="small"
                  @click="go2QcPage(scope.row.talkSession)"><span>质检详情</span></el-button>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="id" fixed="right" width="180" label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="reply(scope.row.talkSession)">回复</el-button>

            <!-- <el-button
              type="text"
              size="small"
              @click="cancelEva(scope.row.talkSession)"
              >申诉成功</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="replyVisible" width="660px" max-height="600" :size="size" title="回复" :rules="rules"
      :close-on-click-modal="false">
      <el-form :model="replyForm" :size="size" ref="replyForm" label-width="130px">
        <el-form-item prop="result" label="申诉结果">
          <el-select v-model="replyForm.result" clearable>
            <el-option v-for="item in replyForm.resultOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>

          <span class="red" v-if="replyForm && replyForm.resultOpts && replyForm.resultOpts.length > 2"
            style="margin-left: 10px; font-size: 13px">可首次免责</span>
        </el-form-item>

        <el-form-item prop="content" label="回复内容">
          <el-input type="textarea" v-model="replyForm.content" :rows="5" style="width: 420px"
            placeholder="回复内容"></el-input>
        </el-form-item>

        <el-form-item label="附件：">
          <div>
            <multiple-file-upload v-model="replyForm.fileList" filePath="/eva_item" />
          </div>
          <div class="annex-box" v-if="replyForm.fileList && replyForm.fileList.length">
            <span>附件下载：</span>
            <span v-for="item in replyForm.fileList" :key="item.url" style="margin-right: 24px">
              <a style="color: #409eff" :href="item.url" target="_blank">{{ item.name }}</a>
            </span>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="footer">
        <el-button @click.stop="submitReply" type="primary" size="small">保 存</el-button>

        <el-button @click.stop="replyVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAppealList, saveTalkRecord, getPunishBill, punishedBefore } from "@/api";
import { DateUtil, ParamUtil, FileUtil } from "@/util";
import { mapState } from "vuex";
import Router from "@/route";
import { SHOW_PUNISH_DIAG, SHOW_ROUTE_PLAN } from "@/core/const/global-event";

export default {
  components: {},
  props: {},
  created() {
    this.query();
  },
  mounted() { },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      driverEvaOptTypeOpts: (state) => state.enumMap["DRIVER_EVA_OPT_TYPE"],
      driverEvaTypeOpts: (state) => state.enumMap["DRIVER_EVA_TYPE"],
      appealResultOpts: (state) => state.enumMap["TALK_STATUS"],
      talkStatusOpts: (state) =>
        state.enumMap["TALK_STATUS"].filter((item) => ![5, 6].includes(item.value)),
    }),
  },

  data() {
    return {
      param: {
        startTime: DateUtil.getDayStr(-30),
        endTime: DateUtil.getDayStr(1),
      },
      list: [],
      total: 0,
      currentPage: 1,
      replyVisible: false,
      replyForm: {
        fileList: [],
      },

      rules: {
        content: [{ required: true, message: "请输入回复内容", trigger: "blur" }],
        result: [{ required: true, message: "请选择申诉结果", trigger: "blur" }],
      },
    };
  },
  methods: {
    query(page = 1) {
      const param = {
        page: {
          current: page,
          size: 10,
          orders: [{ column: "id", asc: false }],
        },
        startTime: this.param.startTime,
        endTime: this.param.endTime,
        param: ParamUtil.filterEmptyData(this.param),
      };
      getAppealList(ParamUtil.filterEmptyData(param)).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });
    },

    showRouteList(item) {
      const { href } = Router.resolve({
        path: "/route/list",
        query: { routeId: item.routeId },
      });
      window.open(href, "_blank");
    },

    async showPunishBill(item) {
      const resp = await getPunishBill(item.punishId);
      this.$bus.$emit(SHOW_PUNISH_DIAG, { punishBill: resp.data, viewMode: true });
    },

    showRoutePlan(item) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, { id: item.routeId }, false);
    },

    go2QcPage(item) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId: item.routeId },
      });
      window.open(href, "_blank");
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    tableRowClassName({ row }) {
      return row.talkDirect ? "driver" : "admin";
    },

    goRouteReplay(appeal) {
      const { orderId, routeId } = appeal || {};
      if (routeId) {
        const { href } = Router.resolve({
          path: "/route/replay",
          query: { id: routeId, orderId },
        });
        window.open(href, "_blank");
      } else {
        this.$message.warning("此订单没有行程信息");
      }
    },

    goRouteDetail(routeId) {
      const { href } = Router.resolve({
        path: "/route/list",
        query: { routeId },
      });
      window.open(href, "_blank");
    },

    reply(session) {
      this.selected = session;
      punishedBefore(session.punishId).then((res) => {
        // 如果是首次违规，可以选择“首次免责”
        const resultOptItems = res.data ? [3, 4] : [3, 4, 7];
        this.replyForm = {
          fileList: [],
          resultOpts: this.appealResultOpts.filter((item) => resultOptItems.includes(item.value)),
        };
        this.replyVisible = true;
      });
    },

    submitReply() {
      this.$refs.replyForm.validate((valid) => {
        if (valid) {
          const { id, driverId } = this.selected || {};
          const param = {
            content: this.replyForm.content,
            sessionId: id,
            sessionType: 1,
            driverId,
            result: this.replyForm.result,
            annexes: this.replyForm.fileList.map((t) => t.url),
          };

          saveTalkRecord(param).then(() => {
            this.$message.success("回复成功");
            this.replyVisible = false;
            this.query(this.currentPage);
          });
        }
      });
    },

    isImage(src) {
      return FileUtil.isImage(src);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .driver td {
  background: #fafbff !important;
}

/deep/ .admin td {
  background: #e0eff1 !important;
}

.spe-box {
  .el-button--small {
    padding: 0px 15px;
  }
}

.content {
  display: flex;

  .user {
    width: 160px;
  }

  .text {
    flex: 1;
  }
}
</style>
