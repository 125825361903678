// 客服选择话务队列
export const CHOOSE_HW_QUEUE = 'hwQueue';
// 客服选择话机
export const CHOOSE_HW_PHONE = 'hwPhone';
// 用户信息
export const USER_INFO = 'userinfo';
// 用户信息
export const TOKEN = 'authToken';
// 所有客服信息
export const WAITER_INFO = 'waiter';
// 权限信息
export const PERMISSION = 'permission';
// 权限数组
export const PERMISSION_ARR = 'permissionArr';

export const MVB2000WEB_KEY = 'mvb2000webkey';

export const MODE = {
  ADD: 1,
  EDIT: 2
};
