<template>
    <el-drawer :visible.sync="visible" direction="ltr" :with-header="false" custom-class="drawer-modal" @opened="init">
        <div class="box">
            <div id="cmsv6flash"></div>
        </div>

        <div class="btns">
            <div>
                <el-button @click="play(0, 0)">车外视角</el-button>
            </div>
            <div>
                <el-button @click="play(1, 1)">副驾视角</el-button>
            </div>
            <div>
                <el-button @click="play(2, 2)">车内全景视角</el-button>
            </div>
            <div>
                <el-button @click="play(3, 3)">司机面部视角</el-button>
            </div>
        </div>

    </el-drawer>
</template>

<script>
import { getDriverDetail, videoMonitorLogin, isDeviceOnline, videoServerIp, getRealTimeLocation } from "@/api";
export default {
  components: {},
  props: {},
  created () { },
  mounted () { },
  watch: {},
  computed: {

  },
  data () {
    return {
      visible: false,
      deviceNo: null,
      token: null,
      locationInfo: null,
      online: false,
      driverId: null
    };
  },
  methods: {
    show (driverId) {

      if (driverId === this.driverId) {
        this.visible = true;
        return;
      }
      this.checkOnline(driverId);
    },

    async checkOnline (driverId) {
      const driverInfo = await getDriverDetail({ id: driverId });
      const { videoDeviceId } = driverInfo.data || {};
      this.deviceNo = videoDeviceId;
      this.deviceNo = '232114240026';
      const res = await videoMonitorLogin();
      this.token = res.jsession;
      const onlineRes = await isDeviceOnline(this.token, this.deviceNo);
      this.online = onlineRes.onlines[0].online;
      if (this.online) {
        this.visible = true;
      } else {
        this.$message.error("设备不在线");
        this.visible = false;
      }
    },


    init () {
      var options = {
        domId: "cmsv6flash",
        isVodMode: false,
        width: 1200,
        height: 800,
      };
      var swfobject = new Cmsv6Player(options);
      this.swfobject = swfobject;
      // 再配置当前的窗口数目
      swfobject.setWindowNum(4);
      swfobject.setServerInfo(videoServerIp, '6605');
      this.player = swfobject.getObjectById("cmsv6flash");
      this.getLocation();
    },

    getLocation () {
      getRealTimeLocation(this.token, this.deviceNo).then(res => {
        if (res.infos.length > 0) {
          this.locationInfo = res.infos[0];
        }
      });
    },

    play (chIndex, winIndex) {
      this.player.setVideoInfo(winIndex, `CH${chIndex + 1}`);
      this.player.startVideo(winIndex, this.token, this.deviceNo, chIndex, 1, true);
    },


    startTimer () {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
      }, 1000);
    }

  },
};
</script>
<style lang="less" scoped>
/deep/ .drawer-modal {
    width: 1600px !important;
}

.box {
    overflow: auto;
    position: relative;
}

.btns{
    display: flex;
}
</style>