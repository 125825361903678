<template>
  <div class="provider-detail-root">
    <div v-if="mode == 'view'">
      <div class="row">
        <div class="item" style="width: 220px">服务商名称：{{ currentProvider.name }}</div>
        <div class="item" style="width: 280px">服务商开通时间：{{ currentProvider.ctime }}</div>
        <div class="item" style="width: 480px">服务商地点：{{ currentProvider.address }}</div>
        <div class="item">
          服务商logo：<el-link
            type="primary"
            @click.native="showPhoto(currentProvider.logo)"
            v-if="existProvider"
            >{{ currentProvider.logo ? "（已上传）" : "（无）" }}</el-link
          >
        </div>
      </div>
      <div class="row">
        <div class="item" style="width: 220px">服务次数：{{ currentProvider.serviceCnt }}</div>
        <div class="item" style="width: 280px">
          已结算金额：{{ currentProvider.amountPayed ? currentProvider.amountPayed / 100 : 0 }}
        </div>
        <div class="item">
          待结算金额：{{ currentProvider.amountPaying ? currentProvider.amountPaying / 100 : 0 }}
        </div>
        <div class="item">
          账户状态：{{ currentProvider.status == 1 ? "正常" : "禁用"
          }}<i
            class="el-icon-edit"
            style="cursor: pointer"
            @click="stopPartner(currentProvider)"
            v-if="currentProvider.status == 1 && existProvider"
          ></i>
        </div>
      </div>
      <div class="row">
        <div class="item" style="width: 220px">结算银行：{{ currentProvider.bankName }}</div>
        <div class="item" style="width: 280px">账户名称：{{ currentProvider.bankAccountName }}</div>
        <div class="item" style="width: 480px">银行卡号：{{ currentProvider.bankCardNo }}</div>
        <div class="item">
          银行卡：<el-link
            type="primary"
            @click.native="showPhoto(currentProvider.urlBankCard)"
            v-if="existProvider"
            >{{ currentProvider.urlBankCard ? "（已上传）" : "（无）" }}</el-link
          >
        </div>
      </div>
      <div class="row">
        <div class="item" style="width: 220px">
          服务商类型：{{ transeferIdNames(currentProvider.svcScopes) }}
        </div>
        <div class="item">
          服务协议：<el-link
            type="primary"
            target="_blank"
            :href="currentProvider.agreement"
            v-if="existProvider"
            >{{ currentProvider.agreement ? "（已上传）" : "（无）" }}</el-link
          >
        </div>
      </div>
      <div class="row">
        <div class="item" style="width: 220px; vertical-align: top">
          服务商账户数：{{ currentProvider.staffCnt }}
          <el-link type="primary" @click.native="addStaff()" v-if="existProvider">增加账户</el-link>
        </div>
        <div class="item">
          <div class="staff-area" v-for="staff in staffs" :key="staff.id">
            <div class="item" style="width: 120px" v-if="staff.manager == 1">
              主账户：{{ staff.name }}
            </div>
            <div class="item" style="width: 120px" v-else>子账户：{{ staff.name }}</div>
            <div class="item" style="width: 180px">手机号码：{{ staff.telephone }}</div>
            <div class="item" style="width: 120px">
              照片：<el-link
                type="primary"
                @click.native="showPhoto(staff.avatar)"
                v-if="existProvider"
                >{{ staff.avatar ? "（已上传）" : "（无）" }}</el-link
              >
            </div>
            <div class="item" style="width: 140px">
              账户状态：
              <span class="status" :class="staff.status == 1 ? 'normal' : 'forbidden'">
                {{ staff.status == 1 ? "正常" : "禁用" }}
              </span>
              <i
                class="el-icon-edit"
                style="cursor: pointer"
                v-if="staff.status == 1"
                @click="stopStaff(staff)"
              ></i>
            </div>
            <div class="item">
              <el-link type="primary" style="margin-right: 5px" @click.native="editStaff(staff)"
                >编辑</el-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <el-form ref="form" :rules="rules" :model="currentProvider" label-width="180px">
        <el-form-item label="公司名称:" prop="name">
          <el-input v-model="currentProvider.name" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="公司logo:" prop="logo">
          <image-upload v-model="currentProvider.logo" filePath="/partner" bucket="yxmgt-public" />
        </el-form-item>
        <el-form-item label="联系方式:" prop="telephone">
          <el-input v-model="currentProvider.telephone" placeholder="请输入电话号码"></el-input>
        </el-form-item>
        <el-form-item label="经营时间:" prop="openTime">
          <el-input
            v-model="currentProvider.openTime"
            placeholder="经营起始时间"
            style="width: 120px"
          ></el-input>
          -
          <el-input
            v-model="currentProvider.closeTime"
            placeholder="经营截至时间"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务范围:" prop="svcScopes">
          <el-checkbox-group v-model="currentProvider.svcScopes">
            <el-checkbox :label="p.value" :key="p.value" v-for="p in serviceScopeObjs">{{
              p.label
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <el-input v-model="currentProvider.address" id="address" readonly></el-input>
          <div class="map-wrapper">
            <div class="map" id="map" style="margin-top: 10px; height: 350px; width: 100%"></div>
            <div class="info">
              <div class="input-item">
                <div class="input-item-prepend">
                  <div class="input-item-text" style="width: 96px">请输入关键字</div>
                </div>
                <input id="tipinput" type="text" />
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="结算银行:" prop="bankName">
          <el-input v-model="currentProvider.bankName" placeholder="结算银行名称"></el-input>
        </el-form-item>
        <el-form-item label="账户名称:" prop="bankAccountName">
          <el-input v-model="currentProvider.bankAccountName" placeholder="结算账户名称"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号:" prop="bankCardNo">
          <el-input v-model="currentProvider.bankCardNo" placeholder="结算银行卡号"></el-input>
        </el-form-item>
        <el-form-item
          label="洗车费用:"
          prop="washFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('wash') >= 0"
        >
          <el-input v-model="currentProvider.washFee" placeholder="洗车费用（单位：分）"></el-input>
        </el-form-item>
        <el-form-item
          label="车载费用(GPS):"
          prop="vmeGpsFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('vme') >= 0"
        >
          <el-input
            v-model="currentProvider.vmeGpsFee"
            placeholder="GPS车载费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="车载费用(ETC):"
          prop="vmeEtcFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('vme') >= 0"
        >
          <el-input
            v-model="currentProvider.vmeEtcFee"
            placeholder="ETC车载费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="车载费用(摄像头):"
          prop="vmeCameraFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('vme') >= 0"
        >
          <el-input
            v-model="currentProvider.vmeCameraFee"
            placeholder="摄像头车载费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="车载费用（智能钥匙）:"
          prop="vmeKeyFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('vme') >= 0"
        >
          <el-input
            v-model="currentProvider.vmeKeyFee"
            placeholder="智能钥匙车载费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="检查费用:"
          prop="inspectionFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('inspection') >= 0"
        >
          <el-input
            v-model="currentProvider.inspectionFee"
            placeholder="检查费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="定损费用:"
          prop="assessFee"
          v-if="currentProvider.svcScopes && currentProvider.svcScopes.indexOf('assess') >= 0"
        >
          <el-input
            v-model="currentProvider.assessFee"
            placeholder="定损费用（单位：分）"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡照片:" prop="urlBankCard">
          <image-upload
            v-model="currentProvider.urlBankCard"
            filePath="/partner"
            bucket="yxmgt-public"
          />
        </el-form-item>
        <el-form-item label="服务协议:" prop="agreement">
          <file-upload
            v-model="currentProvider.agreement"
            filePath="/partner"
            bucket="yxmgt-public"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="saveProvider">提交</el-button>
        <el-button @click.native="closeWin(false)">取消</el-button>
      </span>
    </div>

    <el-dialog
      :title="staff && staff.id ? '修改账户信息' : '新增账户'"
      :visible.sync="showStaffDialog"
      v-if="showStaffDialog"
    >
      <staff-panel :staff="staff" @staffchange="staffChange"></staff-panel>
    </el-dialog>

    <el-dialog title="预览" :visible.sync="showPhotoDialog">
      <el-image style="width: 500px; height: 500px" :src="photoUrl" fit="contain"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import {
  savePartnerProvider,
  stopPartnerProviderRelation,
  getPartnerStaffList,
  stopPartnerStaffRelation,
  deletePartnerStaff,
} from "@/api/partner_admin";
import staffPanel from "./staff";
export default {
  name: "provider-detail",
  components: {
    staffPanel,
  },
  props: {
    provider: {
      type: Object,
    },
    mode: {
      type: String,
      defalut: "view",
    },
  },
  data() {
    return {
      serviceScopeObjs: [],
      rules: {
        name: [{ required: true, message: "公司名称不能为空", trigger: "blur" }],
        telephone: [{ required: true, message: "联系电话不能为空", trigger: "blur" }],
        svcScopes: [{ required: true, message: "服务范围不能为空", trigger: "blur" }],
        address: [{ required: true, message: "详细地址不能为空", trigger: "blur" }],
      },
      map: null,
      marker: null,
      currentProvider: {},
      staffs: [],
      staff: null,
      showStaffDialog: false,
      showPhotoDialog: false,
      photoUrl: null,
    };
  },
  computed: {
    existProvider() {
      return !!this.currentProvider.id;
    },
  },
  mounted() {
    this.serviceScopeObjs = window.serviceScopeObjs || [];
    this.initPage();
    this.initMap();
  },
  methods: {
    initPage() {
      this.currentProvider = JSON.parse(JSON.stringify(this.provider));
      this.findStaffs();
    },
    transeferIdNames(svcScopes) {
      if (!svcScopes) {
        return "";
      }
      return svcScopes
        .map((ss) => {
          for (var i = 0; i < this.serviceScopeObjs.length; i++) {
            if (this.serviceScopeObjs[i].value == ss) {
              return this.serviceScopeObjs[i].label;
            }
          }
          return "";
        })
        .join();
    },
    initMap() {
      if (this.mode == "view") {
        return;
      }
      var addMarker = (lng, lat, geocoder, moved) => {
        if (this.marker) {
          this.marker.setMap(null);
        }
        this.marker = new AMap.Marker({
          icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          position: [lng, lat],
        });
        this.marker.setMap(this.map);
        if (moved) {
          this.map.panTo([lng, lat]);
        }

        geocoder &&
          geocoder.getAddress([lng, lat], (status, result) => {
            this.currentProvider.lat = lat;
            this.currentProvider.lng = lng;
            this.currentProvider.address = result.regeocode.formattedAddress;
            var addressInfo = result.regeocode.addressComponent;
            this.currentProvider.province = addressInfo.province;
            this.currentProvider.city = addressInfo.city;
            this.currentProvider.zip = addressInfo.district;
          });
      };

      if (!this.map) {
        const map = new AMap.Map("map", {
          resizeEnable: true,
          dragEnable: true,
          zoom: 14,
        });
        this.map = map;

        AMap.plugin(
          ["AMap.PlaceSearch", "AMap.AutoComplete", "AMap.Geocoder", "AMap.Scale"],
          () => {
            var geocoder = new AMap.Geocoder({});
            const autoOptions = {
              input: "tipinput",
            };
            const autoComplete = new AMap.AutoComplete(autoOptions);
            autoComplete.on("select", (e) => {
              var lng = e.poi.location.lng;
              var lat = e.poi.location.lat;
              addMarker(lng, lat, geocoder, true);
            });
            map.on("click", (e) => {
              var lng = e.lnglat.getLng();
              var lat = e.lnglat.getLat();
              addMarker(lng, lat, geocoder, false);
            });
          }
        );
      }

      if (this.currentProvider.lng && this.currentProvider.lat) {
        addMarker(this.currentProvider.lng, this.currentProvider.lat, null, true);
      } else if (this.marker) {
        this.marker.setMap(null);
      }
    },
    showPhoto(url) {
      if (!url) {
        return;
      }
      this.photoUrl = url;
      this.showPhotoDialog = true;
    },
    closeWin(changed, provider) {
      this.$emit("providerchange", provider || this.currentProvider, changed);
    },
    saveProvider() {
      if (
        !this.currentProvider.name ||
        !this.currentProvider.telephone ||
        !this.currentProvider.address ||
        !this.currentProvider.svcScopes
      ) {
        return;
      }
      if (this.currentProvider.svcScopes.indexOf("wash") > 0 && !this.currentProvider.washFee) {
        this.$message.error("请输入洗车费用。");
        return;
      }
      if (
        this.currentProvider.svcScopes.indexOf("inspection") > 0 &&
        !this.currentProvider.inspectionFee
      ) {
        this.$message.error("请输入检查费用。");
        return;
      }
      if (this.currentProvider.svcScopes.indexOf("assess") > 0 && !this.currentProvider.assessFee) {
        this.$message.error("请输入定损费用。");
        return;
      }
      if (this.currentProvider.svcScopes.indexOf("vme") > 0) {
        if (!this.currentProvider.vmeGpsFee) {
          this.$message.error("请输入GPS车载费用。");
          return;
        }
        if (!this.currentProvider.vmeEtcFee) {
          this.$message.error("请输入ETC车载费用。");
          return;
        }
        if (!this.currentProvider.vmeCameraFee) {
          this.$message.error("请输入摄像头车载费用。");
          return;
        }
        if (!this.currentProvider.vmeKeyFee) {
          this.$message.error("请输入智能钥匙车载费用。");
          return;
        }
      }
      savePartnerProvider(this.currentProvider).then((res) => {
        let provider = res.data;
        this.closeWin(true, provider);
        this.$message.success("保存成功。");
      });
    },
    stopPartner(partner) {
      let params = { id: partner.id, status: 0 };
      this.$confirm("确定要禁用服务商吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stopPartnerProviderRelation(params).then(() => {
          this.currentProvider.status = 0;
          this.$emit("providerchange", this.currentProvider, true);
          this.$message.success("禁用成功。");
        });
      });
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    findStaffs() {
      let id = this.currentProvider.id;
      if (!id) {
        return;
      }
      getPartnerStaffList(id, {}).then((res) => {
        let staffs = res.data || [];
        this.staffs = staffs;
        this.staffs.forEach((staff) => {
          if (staff.roles && staff.roles.indexOf("manager") >= 0) {
            staff.manager = 1;
          } else {
            staff.manager = 0;
          }
        });
        this.sortStaffs(this.staffs);
      });
    },
    sortStaffs(staffs) {
      if (staffs && staffs.length) {
        staffs.sort((a, b) => {
          if (a.manager == b.manager) {
            return a.id > b.id ? 1 : -1;
          } else {
            return a.manager > b.manager ? -1 : 1;
          }
        });
      }
    },
    addStaff() {
      this.staff = {
        id: "",
        name: "",
        avatar: "",
        manager: "0",
        providerId: this.currentProvider.id,
        providerName: this.currentProvider.name,
      };
      this.showStaffDialog = true;
    },
    editStaff(staff) {
      this.staff = staff;
      this.showStaffDialog = true;
    },
    deleteStaff(id) {
      this.$confirm("确定要删除账户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePartnerStaff(id).then(() => {
          this.staffs = this.staffs.filter((staff) => staff.id != id);
          this.$message.success("删除成功。");
        });
      });
    },
    staffChange(staff, changed) {
      if (changed) {
        let idx = -1;
        for (var i = 0; i < this.staffs.length; i++) {
          if (this.staffs[i].id == staff.id) {
            idx = i;
            break;
          }
        }
        if (idx != -1) {
          this.staffs.splice(idx, 1, staff);
        } else {
          this.staffs.push(staff);
        }
        this.sortStaffs(this.staffs);
        this.currentProvider.staffCnt = this.staffs.length;
        this.$emit("providerchange", this.currentProvider, true);
      }
      this.showStaffDialog = false;
    },
    stopStaff(staff) {
      let params = { id: staff.id, status: 0 };
      this.$confirm("确定要禁用吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stopPartnerStaffRelation(params).then(() => {
          staff.status = 0;
          this.$message.success("禁用成功。");
        });
      });
    },
  },
};
</script>

<style lang="less">
.provider-detail-root {
  .el-dialog {
    width: 550px !important;
    height: auto !important;
  }
  .row {
    margin-bottom: 10px;

    .item {
      display: inline-block;
      margin-right: 20px;
    }
  }
  .staff-area {
    margin-bottom: 10px;

    .normal {
      color: #67c23a;
    }
    .forbidden {
      color: #f56c6c;
    }
  }

  .map-wrapper {
    position: relative;
    width: 100%;
    height: 360px;

    .info {
      padding: 10px 16px;
      margin-bottom: 12px;
      border-radius: 4px;
      position: absolute;
      top: 12px;
      right: 12px;
      background-color: white;
      width: auto;
      min-width: 240px;
      border-width: 0;
      box-shadow: 0 2px 6px 0 rgb(114 124 245 / 50%);

      .input-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 36px;
      }

      .input-item-prepend {
        margin-right: -1px;
      }

      .input-item-text {
        width: 96px;
        text-align: justify;
        padding: 4px 8px;
        display: inline-block;
        text-justify: distribute-all-lines;
        text-align-last: justify;
        align-items: center;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 3px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      #tipinput {
        display: inline-block;
        height: 28px;
        box-sizing: border-box;
        border: 1px solid #ced4da;
        margin-top: 1px;
      }
    }
  }
}
</style>
