<template>
  <div>
    <div class="info">
      <span>时间：{{ data.createTime | dateFormat("hh:mm:ss") }}</span>

      <span>类型：{{ data.alarmType | alarmTypeFilter(alarmTypeMap) }}</span>
    </div>

    <div v-if="data.url" class="item">
      <el-image
        v-if="isImage(data.fileType)"
        :src="data.url"
        :lazy="false"
        fit="contain"
        :preview-src-list="previewSrcList"
      ></el-image>

      <video
        v-if="isVedio(data.fileType)"
        :src="data.url"
        class="video-box"
        controls
        autoplay
      ></video>
    </div>
    <div v-else class="item">未获取到图片:{{ data.url }}</div>
  </div>
</template>
<script>
import { FileUtil } from "@/util";
export default {
  components: {},

  filters: {
    alarmTypeFilter(value, alarmTypeMap) {
      return alarmTypeMap[value];
    },
  },

  props: {
    data: Object,
    imgList: Array,
  },

  watch: {},

  created() {},

  mounted() {},

  computed: {
    previewSrcList() {
      return this.imgList.filter((t) => this.isImage(t.fileType)).map((item) => item.url);
    },
  },

  data() {
    return {
      alarmTypeMap: {
        172: "闭眼警示开始",
        173: "闭眼警示结束",
        174: "手机警示开始",
        175: "手机警示结束",
        190: "打哈欠开始",
        191: "打哈欠结束",
        356: "疲劳驾驶报警",
        454: "不系安全带",
        471: "撞击行人",
        620: "接打电话报警1级",
        621: "接打电话报警2级",
        618: "疲劳驾驶报警1级",
        619: "疲劳驾驶报警2级",
      },
    };
  },

  methods: {
    isImage(src) {
      return FileUtil.isImage("." + src);
    },

    isVedio(src) {
      return FileUtil.isVedio("." + src);
    },

    isAudio(src) {
      return FileUtil.isAudio("." + src);
    },
  },
};
</script>
<style scoped lang="less">
.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item {
  width: 400px;
  height: 150px;

  .video-box {
    width: 86%;
    height: auto;
    margin-left: 6%;
  }
}
</style>
