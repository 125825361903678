<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form ref="form" :inline="true" size="small" class="form" label-width="90px">
        <el-form-item>
          <el-input placeholder="num" v-model="form.num" style="width: 60px" />
          <el-input placeholder="duration" v-model="form.duration" style="width: 80px" />
          <el-input placeholder="distance" v-model="form.distance" style="width: 100px" />
        </el-form-item>

        <el-form-item label="方案：">
          <el-select v-model="plan" placeholder="请选择" @change="query" style="width: 100px">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" @click="save">保存方案</el-button>
        </el-form-item>

        <el-form-item label="地址搜索">
          <input id="tipinput" type="text" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" @click="calc">执行派单计算</el-button>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" @click="showNotMatched"
            >显示不匹配的点</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" @click="triggerMarker">{{
            flag ? "隐藏" : "显示"
          }}</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-link type="primary" href="/admin/dump/sql" target="_blank">导出问题数据</el-link>
        </el-form-item>

        <div class="sum">总订单：{{ points.length }} 分配订单：{{ totalNum }}</div>
      </el-form>
    </el-row>
    <div class="map" id="map"></div>

    <div class="plan" v-if="show">
      <div @click="show = false" class="title">派单计划</div>
      <div class="box" v-for="(plan, index2) in routeKpi" :key="index2" :style="plan.style">
        <div class="item" v-for="(item, index) in plan.kpis" :key="item.orderId">
          <span>{{ item.name }} ({{ item.number }}人)</span>
          <span v-if="index !== plan.kpis.length - 1">-></span>
        </div>
        <div class="score">
          得分：{{ plan.score }} 平均绕行时间：{{ plan.avgMoreTime }} 平均绕行距离：{{
            plan.avgMoreDistance
          }}
          平均多花时间：{{ plan.avgExtraTime }} 行程总里程：{{ plan.totalDistance }} 行程总时间：{{
            plan.totalDuration
          }}
          <a @click="route(plan)">显示路线</a>
          <a>查看详情</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.initMap();
    this.query();
  },
  data() {
    return {
      plan: 1,
      show: false,
      flag: true,
      form: {
        num: 3,
        duration: 900,
        distance: 10000,
      },
      routeKpi: [],
      notMatched: [],
      statusOpts: [
        {
          label: "方案1",
          value: 1,
        },
        {
          label: "方案2",
          value: 2,
        },
        {
          label: "方案3",
          value: 3,
        },
        {
          label: "方案4",
          value: 4,
        },
        {
          label: "方案5",
          value: 5,
        },
        {
          label: "方案6",
          value: 6,
        },
        {
          label: "方案7",
          value: 7,
        },
        {
          label: "方案8",
          value: 8,
        },
        {
          label: "方案9",
          value: 9,
        },
        {
          label: "方案10",
          value: 10,
        },
      ],
      totalDistance: 0,
      totalDuration: 0,
      totalNum: 0,
      points: [],
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map.on("rightclick", this.rightclick);

      AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete", "AMap.Driving"], () => {
        const autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "tipinput",
          city: "武汉",
        };
        const autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.on("select", this.select);

        this.driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_TIME,
          map: this.map,
        });
      });
    },

    triggerMarker() {
      this.flag = !this.flag;
      if (this.flag) {
        this.map.add(this.markers);
      } else {
        this.map.remove(this.markers);
      }
    },

    select(info) {
      this.addMarker(info.poi.location, info.poi.name);
    },
    addMarker(position, title) {
      const marker = new AMap.Marker({
        position,
        title,
      });

      marker.on("rightclick", this.removeMarker);
      this.map.add(marker);
      this.map.setFitView();
    },
    rightclick(info) {
      setTimeout(() => {
        this.$prompt("请输入名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          this.addMarker(info.lnglat, value);
        });
      }, 0);
    },
    removeMarker(marker) {
      setTimeout(() => {
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.map.remove(marker.target);
        });
      }, 0);
    },
    calc() {
      const markers = this.map.getAllOverlays() || [];
      let i = 1;
      const points = markers.map((item) => {
        i++;
        return {
          orderId: i,
          name: item.getTitle(),
          lat: item.getPosition().lat,
          lon: item.getPosition().lng,
        };
      });

      const url = "/auto_dispatch/calc";
      const data = {
        points,
        ...this.form,
      };
      this.$http.post(url, data).then((res) => {
        const { kpi = [], dest } = res.data || {};
        this.dest = dest;
        const plans = kpi;
        // this.notMatched = notMatched;
        let totalDistance = 0;
        let totalDuration = 0;
        let totalNum = 0;
        this.routeKpi = plans.map((item) => {
          totalDistance += item.totalDistance;
          totalDuration += item.totalDuration;
          totalNum += item.kpis.length;

          const score = item.score;
          let backgroundColor = "#7cb305";
          if (score >= 80) {
            backgroundColor = "#7cb305";
          } else if (score >= 60) {
            backgroundColor = "#ff7875";
          } else if (score >= 40) {
            backgroundColor = "#ff7875";
          } else {
            backgroundColor = "#a8071a";
          }

          item.style = {
            backgroundColor,
          };
          item.kpis = item.kpis.map((item) => {
            return {
              ...item,
              ...item.orderInfo,
            };
          });
          return item;
        });
        this.show = true;
        this.totalDistance = totalDistance;
        this.totalDuration = totalDuration;
        this.totalNum = totalNum;
      });
    },
    query() {
      const url = "/auto_dispatch/query";
      const data = {
        no: this.plan,
      };
      this.$http.post(url, data).then((res) => {
        const points = res.data || [];
        this.points = points;
        const markers = points.map((item) => {
          const marker = new AMap.Marker({
            position: [item.lon, item.lat],
            title: item.name,
          });
          marker.on("rightclick", this.removeMarker);
          return marker;
        });

        if (markers.length) {
          this.map.clearMap();
          this.map.add(markers);
          this.map.setFitView();

          this.markers = markers;
        }
      });
    },
    showNotMatched() {
      const markers = this.notMatched.map((item) => {
        return new AMap.Marker({
          position: [item.lon, item.lat],
          title: item.name,
        });
      });

      if (markers.length) {
        this.map.clearMap();
        this.map.add(markers);
        this.map.setFitView();

        this.markers = markers;
      }
    },
    save() {
      const url = "/auto_dispatch/save";

      const markers = this.map.getAllOverlays() || [];
      let i = 1;
      const points = markers.map((item) => {
        i++;
        return {
          orderId: i,
          name: item.getTitle(),
          lat: item.getPosition().lat,
          lon: item.getPosition().lng,
        };
      });
      const data = {
        no: this.plan,
        points,
      };

      this.$http.post(url, data).then(() => {
        this.$message.success("保存成功");
      });
    },
    route(plan) {
      const points = plan.kpis.map((item) => {
        return [item.lon, item.lat];
      });

      const dest = this.dest;
      if (points.length > 1) {
        const start = points[0];
        const end = [dest.lon, dest.lat];
        const waypoints = points.slice(1, points.length);
        const opts = {
          // 途经点参数，最多支持传入16个途经点
          waypoints,
        };
        this.driving.search(start, end, opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status, result);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.map {
  height: 700px;
}

.root-box {
  position: relative;
  .plan {
    position: absolute;
    width: 35%;
    min-height: 200px;
    max-height: 650px;
    top: 90px;
    border: 1px solid #ddd;
    background-color: white;
    padding: 5px;
    overflow-y: auto;

    .score {
      font-size: 12px;
    }

    .box {
      border-bottom: 1px solid #ddd;
      .item {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }
      a {
        margin-left: 5px;
        color: #66b1ff;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }
    }
  }
}

.sum {
  width: 7 0%;
  position: absolute;
  right: 0;
  height: 50px;
  text-align: right;
  z-index: 2;
}

#tipinput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}
</style>
