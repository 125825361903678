import { Http } from "@/util";

// 发送未接电话短信
export function sendNoAnswerMsg(orderId) {
  return Http.get("/admin/dispatch/msg/no_answered", { orderId });
}

// 发送小程序推荐短信
export function sendRecommendMpTip(telephone, type) {
  return Http.get("/admin/dispatch/msg/mp_tip", { telephone, type });
}

// 设置司机已联系
export function setDriverCalled(orderId) {
  return Http.get("/admin/dispatch/driver/called", { orderId });
}

// 司机派单
export function dispatchOrders(param) {
  return Http.post("/admin/dispatch/orders", param);
}

// 查询已经分派的行程
export function getDispatchRouteList(param) {
  return Http.get("/admin/dispatch/route/list", param);
}

// 预派单展示给司机看
export function preDispatchShow2Driver(param) {
  return Http.get("/admin/dispatch/show_2_driver", param);
}

// 预派转正式派单
export function updatePreDispatch2Match(param) {
  return Http.get("/admin/dispatch/update/pre_2_match", param);
}

// 取消空行程
export function cancelEmptyRoute(routeId) {
  return Http.get("/admin/dispatch/cancel/empty_route", { routeId });
}

// 乘客超时未上车，关单
export function abnormalFinishOrder(orderId) {
  return Http.get("/admin/dispatch/abnormal/finish/order", { orderId });
}

// 行程备注
export function updateRouteRemark(param) {
  return Http.post("/admin/dispatch/update/route_remark", param);
}

// 催单司机
export function tipDriver(routeId) {
  return Http.get("/admin/dispatch/tip/hurry_up", { routeId });
}

// 撤回整个行程
export function rollbackRoute(routeId) {
  return Http.get("/admin/dispatch/route/rollback", { routeId });
}


// 查询待办列表
export function getToDoList() {
  return Http.get("/admin/dispatch/todo_list");
}

// 更新待办
export function updateToDoList(param) {
  return Http.post("/admin/dispatch/update/todo_list", param);
}
