import { Http } from "@/util";

export function searchDriver(param) {
  return Http.get("/admin/driver/search/list", param);
}

// 查询车队
export function getTeams(useCache = true) {
  return Http.post("/admin/team/list", { _useCache: useCache });
}

// 查询司机列表
export function getDriverList(param) {
  return Http.post("/admin/driver/list", param);
}

// 查询司机基本信息
export function getDriverInfo(param) {
  param._hideLoading = true;
  return Http.post("/admin/driver/base_info", param);
}

// 查询车队司机
export function getTeamDrivers(team) {
  return Http.get("/admin/team/driver/list", { team });
}

// 查询司机注册信息
export function getDriverRegisterList(param) {
  return Http.post("/admin/driver/register/list", param);
}

// 司机审核
export function auditDriver(param, isPass) {
  return Http.post(`/admin/driver/audit?isPass=${isPass}`, param);
}

// 更新注册司机信息
export function updatePrepareDriverInfo(param) {
  return Http.post("/admin/driver/update/info", param);
}

// 撤销驳回
export function revokeReject(param) {
  return Http.post(`/admin/driver/revoke/reject`, param);
}

// 封禁司机账号
export function banDriver(driverId) {
  return Http.get("/admin/driver/ban", { driverId });
}

// 司机转代理
export function transToPartner(driverId, screenId) {
  return Http.get("/admin/driver/to/partner", { driverId, screenId });
}

// 查询司机详情
export function getDriverDetail(param) {
  return Http.post("/admin/driver/detail", param);
}

// 更新司机详情
export function updateDriverDetailInfo(param) {
  return Http.post("/admin/driver/detail/modify", param);
}

// 司机账户解锁
export function unlockDriver(driverId) {
  return Http.get("/admin/driver/unlock", { driverId });
}

// 修改司机核心信息
export function modifyDriverCoreInfo(param) {
  return Http.post("/admin/driver/core/modify", param);
}

// 新增了司机合同
export function saveOrUpdateContract(param) {
  return Http.post("/admin/driver/save_update/contract", param);
}

// 更新了车辆信息
export function updateVehicle(param, driverId) {
  return Http.post(`/admin/driver/update/vehicle?driverId=${driverId}`, param);
}

// 统计司机端下载
export function countDriverApp() {
  return Http.get(`/admin/driver/app/install/count`);
}

// 更新非重要信息
export function updateUnImportInfo(param) {
  return Http.post(`/admin/driver/update/unimportant/info`, param);
}

// 筛选司机
export function filterDrivers(param) {
  return Http.post(`/admin/driver/filter_drivers`, param);
}

// 绑定车辆
export function bindVehicle(driverId, vehicleId, forceBind) {
  return Http.get(`/admin/driver/bind/vehicle`, { driverId, vehicleId, forceBind });
}

// 上报车辆数据
export function reportDriverData(driverId) {
  return Http.get(`/admin/driver/report_data`, { driverId });
}

// 重置司机密码
export function resetDriverPwd(driverId) {
  return Http.get(`/admin/driver/resetpwd`, { driverId });
}

// ETC运单上传
export function startWaybill(driverId) {
  return Http.get(`/admin/driver/start_waybill`, { driverId });
}

// 结束绑定
export function endWaybill(driverId) {
  return Http.get(`/admin/driver/end_waybill`, { driverId });
}

// 查询ETC通行记录
export function getDriverEtcRouteList(driverId) {
  return Http.get(`/admin/driver/query/etc_route_list`, { driverId });
}

// 查询ETC欠费记录
export function queryEtcOwFees(driverId) {
  return Http.get(`/admin/driver/query/etc_owe_fees`, { driverId });
}
