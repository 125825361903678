<template>
  <div class="root-box">
    <el-tabs v-model="tab">
      <el-tab-pane label="早价规则" name="ref1">
        <RuleAheadPrice />
      </el-tab-pane>

      <el-tab-pane label="送券规则" name="ref2">
        <RuleCoupon />
      </el-tab-pane>

      <el-tab-pane label="优惠券兑换规则" name="ref3">
        <RulePointsExchange />
      </el-tab-pane>

      <el-tab-pane label="退票规则" name="ref4">
        <RuleRefund />
      </el-tab-pane>

      <el-tab-pane label="规则说明文案" name="ref5">
        <RuleDesc />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import RuleCoupon from './RuleCoupon';
import RuleAheadPrice from './RuleAheadPrice';
import RuleRefund from './RuleRefund';
import RulePointsExchange from './RulePointsExchange';
import RuleDesc from './RuleDesc';
export default {
  components: {RuleCoupon, RuleRefund, RuleAheadPrice, RulePointsExchange, RuleDesc},
  props: {},
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  data () {
    return {
      tab: 'ref1'
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
</style>