import {Http} from '@/util';

// 查询登录日志
export function getLoginLog (param) {
  return Http.post('/admin/log/login/list', param);
}

// 查询操作日志
export function getOperateinLog (param) {
  return Http.post('/admin/log/oper/list', param);
}


// 查询客户端错误日志
export function getClientErrLog (param) {
  return Http.post('/admin/log/client_err/list', param);
}


// 派单日志
export function getDispatchLog (param) {
  return Http.post('/admin/log/query/dispatch', param);
}

// 查询交接班日志
export function getHandoverLog (date) {
  return Http.get('/admin/log/query/handover', {date});
}


// 保存交接班日志
export function saveHandoverLog (param) {
  return Http.post('/admin/log/save/handover', param);
}