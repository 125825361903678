import EventBus from "../event/event-bus";

export default {
  created () {
    if (this.$options.events) {
      Object.keys(this.$options.events).forEach(key => {
        let handler = this.$options.events[key];
        if (typeof handler === 'string') {
          handler = this[handler];
        }
        this[key + '::handler'] = handler.bind(this);
        EventBus.$on(key, this[key + '::handler']);
      });
    }
  },
  beforeDestroy () {
    if (this.$options.events) {
      Object.keys(this.$options.events).forEach(key => {
        EventBus.$off(key, this[key + '::handler']);
      });
    }
  }
};
